import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `No submission.
Sharing tacit information is one of the most challenging topics when it comes to corporate communication.
We hope that even if you did not have time to submit an answer here, that you managed to reflect on how you currently share it and how you could improve that. Don't forget that you can check our method cards for some ideas!`
    );
  }

  if (Z1) {
    ret.push(
      `Well done!
You not only have correctly identified a situation where a problem emerged because tacit information was not shared properly, but you have also thought of a good measure to avoid this happening again.`
    );
  }

  return ret;
};
