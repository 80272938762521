import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push("Thank you for stating when you completed the test.");
  }

  return ret;
};
