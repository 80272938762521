import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Great work! You have identified several insightful weak points in your business model and explained their potential impact. The ability to find gaps and foresee challenges in your Business Model is essential to improving and innovating your idea.`,
    )
  }

  return ret;
};
