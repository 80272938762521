import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13, A14, A15 } =
    getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "A9", text = "Intended impact clearly tackles the problem mentioned", points = 2},
    {id = "A10", text = "Intended impact may tackle the problem mentioned", points = 1},
    {id = "A11", text = "Intended impact does not seem to tackle the problem mentioned", points = 0.5},
    {id = "A12", text = "Is this really the Intended impact you want to have?", points = -0.5},
    */

  if ((A1 || A2) && (A5 || A6) && !A15) {
    ret.push(`Well done.
You have clearly stated which problem you are trying to address and what impact your idea should have on your users.
Later, when you are designing your experiment, it is vital that you keep this in mind.`);
  }

  if ((A1 || A2) && A7 && !A15) {
    ret.push(`Thank you for your submission.
While you have stated the problem you want to address very clearly, it seems to us that you have not focused enough on the impact you would like to have on your users.
Ideally, you should take some time now to write down clearly how your solution will impact your user and address this issue.`);
  }

  if ((A1 || A2) && A8 && !A15) {
    ret.push(`Thank you for your submission.
While you have stated the problem you want to address very clearly, it seems to us that you did not think about the direct impact you would like to have on your users.
Ideally, you should take some time now to write down clearly how your solution will impact your user and address this issue.`);
  }

  if (A3 && (A5 || A6) && !A15) {
    ret.push(`What problem exactly are you trying to address?
Your answer is not detailed enough for it to serve as an actionable guide for your next steps.
Please take some time to look back at your insights from Module 1 and try to define the problem you wish to tackle more clearly.

However, while your problem was not detailed enough, you did a good job describing the impact your solution should have on your users. Well done.`);
  }

  if (A4 && (A5 || A6) && !A15) {
    ret.push(`What problem exactly are you trying to address?
It seems like you did not define the problem you wish to tackle very well. Understanding well what problem you are aiming to solve is a vital guide for your next steps.
Therefore, please take some time to look back at your insights from Module 1 and try to define the problem you wish to tackle more clearly.

However, while your problem was not detailed enough, you did a good job describing the impact your solution should have on your users. Well done.`);
  }

  if ((A3 || A4) && (A7 || A8) && !A15) {
    ret.push(`Thank you for your answer.
However, it would have been better if both your problem and the impact your idea should have on your user were explained in more detail.
As these two points are central for the validation of your idea we ask you to take some time to define these points better.`);
  }

  if ((A10 || A11) && (A1 || A2) && (A5 || A6) && !A15) {
    ret.push(`On the other hand, are you sure that your solution will really have this impact on your user?
By reading your submission above we are unsure if this is really the case.
Therefore, please take a few minutes to go over your idea and analyze if this is really the case.`);
  }

  /*
  if ((A10 || A11) && !((A1 || A2) && A4 && (A5 || A6))) {
    ret.push(`But are you sure that your solution will really have this impact on your user?
By reading your submission above we are unsure if this is really the case.
Therefore, please take a few minutes to go over your idea and analyze if this is really the case.`);
  }

  if ((A10 || A11) && !((A1 || A2) && !A4 && (A5 || A6))) {
    ret.push(`Lastly, are you sure that your solution will really have this impact on your user?
By reading your submission above we are unsure if this is really the case.
Therefore, please take a few minutes to go over your idea and analyze if this is really the case.`);
  }

  if (A12 && !A10 && !A11 && !((A1 || A2) && A4 && (A5 || A6))) {
    ret.push(`But are you sure that this is really the impact you want have on your user?
By reading your submission above we are unsure if this should really be the case.
Therefore, please take a few minutes to go over your submission and think if you have defined your intended impact correctly.
Please keep in mind that your goal (and therefore the impact your idea should have) should address the pains identified during your user research.`);
  }

  if (A12 && !A10 && !A11 && !((A1 || A2) && !A4 && (A5 || A6))) {
    ret.push(`Lastly, are you sure that this is really the impact you want have on your user?
By reading your submission above we are unsure if this is really the case.
Therefore, please take a few minutes to go over your idea and analyze if this is really the case.
Please keep in mind that your goal (and therefore the impact your idea should have) should address the pains identified during your user research.`);
  }
*/

if (A15) {
  ret.push(`Your response highlights the importance of user-centric innovation and the need for R&D teams to empathize with their customers. However, it would be helpful to have a clearer understanding of the problem you are trying to solve.

What we mean with this is that it is not clear what the underlying painpoint you are trying to address is.
For example, is it that the R&D engineers currently miss this customer interaction? Or is it the customer facing employees that believe that R&D engineers are disconnected from real users (and thus want them more involved).
Making it very clear who your user is and what their painpoint is, is essential to identify your idea's key features.`);
}

  if (A14) {
    ret.push(
      `More importantly, you did not define well who your user is. As it is right now, you have different users which may perceive this problem more or less seriously. Your job is to identify those that suffer from it the most and focus on them (for now).`
    );
  }

  if (A13) {
    ret.push(`In your next project, make sure to answer the following questions:
- Who is your target user?
- What is the problem you are trying to solve for them?
- How will your idea solve this problem?
- If your idea works, what impact will it have on them? Is it the impact you want to have?`);
  }


  return ret;
};
