import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2 && A3) {
    ret.push(
      `Thank you stating and explaining your decision as well as including your next steps in your submission.
Your decision makes sense and we are looking forward to see how your project will be developed!`
    );
  }

  if (A1 && A2 && A3 === false && A4 === false) {
    ret.push(
      `Thank you stating and explaining your decision.
Your decision makes sense and we are looking forward to see how your project will be developed!`
    );
  }

  if (A1 && A2 === false && A3 === false && A4 === false) {
    ret.push(
      `Thank you stating your decision. Your decision makes sense and we are looking forward to see how your project will be developed.
However, while your decision is very clear, you did not state why exactly you decided that. Explaining why this decision was taken will help you evaluate if this is actually the reight decision. Therefore, we ask you to reflect on your decision and think of arguments why it is the right decision. If you can't think of any, then maybe you should reconsider.`
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `You did not explain why you decided to continue the way you did.
Sometimes you may realize that this is not the right direction to go just by reflecting on this. This is why we would advise you to think about this a little.`
    );
  }

  if (!Z0 && !Z1 && A3 === false && A4 === false) {
    ret.push(
      `You forgot to include your next steps.
It is important to think about that now before you forget the details of your testing. Ideally, you should also take notes on why you chose these next steps.`
    );
  }

  if (!Z0 && !Z1 && A4) {
    ret.push(
      `Thank you for stating and explaining your decision. Your decision makes sense and we are looking forward to seeing how your project will be developed!
However, while you did mention your next steps, you did not describe them in enough detail.
It is important to think about that now before you forget the details of your testing. Ideally, you should break them down into actionable tasks. Furthermore, you should also take notes on why you chose these next steps.`
    );
  }

  if (!Z0 && !Z1 && A5 === false) {
    ret.push(
      `You did not support your decision with insights and quotes.
You have invested a lot of time looking for such insights and findings. Now that you have them, make sure to use them to give your arguments more credibility.`
    );
  }

  return ret;
};
