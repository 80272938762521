import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 || A2) {
    ret.push(`For the amount of interviews you have conducted, you should have written down more learnings.
In general, learning notes should be an objective summary of all of your user research so far. They should include everything that you could observe, hear and quote from the interview.
These findings can be of extreme value and will serve as a base for all of your insights later on.
Try to rewatch some of your interviews or read your interview transcripts again to identify some more findings. As a rule of thumb, a good interview should generate around 1 learning note per minute.`);
  }

  if (A3 || A4) {
    ret.push(`While you did come up with a decent number of learning notes, for the amount of interviews you have conducted, you should have written down more learnings.
In general, learning notes should be an objective summary of all of your user research so far. They should include everything that you could observe, hear and quote from the interview.
These findings can be of extreme value and will serve as a base for all of your insights later on.
we advise you to rewatch some of your interviews or read your interview transcripts again to identify some more findings. As a rule of thumb, a good interview should generate around 1 learning note per minute.`);
  }

  if (A5 || A6) {
    ret.push(`Good job! You have come up with a good number of learning notes.
In general, learning notes should be an objective summary of all of your user research so far. They should include everything that you could observe, hear and quote from the interview.
These findings can be of extreme value and will serve as a base for all of your insights later on.`);
  }

  return ret;
};
