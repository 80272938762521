import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);
  /*
    { id = "A1", text = "He says there has never been such a situation", points = 0 },
    { id = "A2", text = "He names one pitfall from the course/method cards (Amplification effect, polarization effect, common information bias, anchoring effect, sunk-costs, confirming evidence, framing, estimating and forecasting error)", points = 1},
    { id = "A3", text = "The user explains a realistic situation that happened", points = 1 },
    { id = "A4", text = "Good answer", points = 1 },
    */

  if (!Z0 && !Z1 && !A1 && A2 && A3) {
    ret.push(
      `Very well done!
You have not only correctly identified a pitfall, but you have also described a realistic situation where it occurred. Being able to name the pitfalls will make it easier for you to identify and avoid such situations in the future.`
    );
  }

  if (!Z0 && !Z1 && !A1 && A2 && A3 === false) {
    ret.push(
      `Well done!
You have correcty named one of the pitfalls mentioned in the course.
However, you did not provide us with a situation where this pitfall has affcted you and your team. Please try to look back at your past experiences and try to identify such a situation. It is very unlikely that this has not happened to you yet.`
    );
  }

  if (!Z0 && !Z1 && !A1 && A2 === false && A3) {
    ret.push(
      `Thank you for sharing with us a situation where you and your team fell victim to one of the pitfalls mentioned in the course.
However, you did not name this pitfall as we required. Being able to name the pitfalls will make it easier for you to identify and avoid such situations in the future.`
    );
  }

  if (A1) {
    ret.push(
      `Are you sure that you and your team have never stepped into one of the pitfalls?
Try taking a better look at them again in our method cards or in the videos in M4. These are amplification effect, polarization effect, common information bias, anchoring effect, sunk-costs, confirming evidence, framing and estimating, and forecasting error. It may not be obvious, but it is unlikely that you have never been in such a situation.`
    );
  }

  return ret;
};
