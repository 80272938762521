import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for stating when you want to apply your decision criteria. Having a clear deadline will stop you from wasting time and continue or kill your project early on.`
    );
  }

  if (A2 && A1) {
    ret.push(
      `You have also added a good decision criteria for each of your milestones. Based on decision criteria you stated, you can decide to continue, pivot, or kill the project. Some additional examples could be: Is your problem worth solving (regarding the need and your companies strategy)? Is your solution clearly solving the problem you identified previously? Did the business model testing satisfy the success criteria you set in advance?`
    );
  }

  if (A2 && A1 === false) {
    ret.push(
      `You have added a good decision criteria for each of your milestones. Good job!.
Based on decision criteria you stated, you can decide to continue, pivot, or kill the project. Some additional examples could be: Is your problem worth solving (regarding the need and your companies strategy)? Is your solution clearly solving the problem you identified previously? Did the business model testing satisfy the success criteria you set in advance?`
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `Unfortunately, you did not submit the milestones for when you want to apply your decision criteria. Having a clear deadline will stop you from wasting time and continue or kill your project early on.`
    );
  }

  if (!Z0 && !Z1 && A1 === false && A2 === false) {
    ret.push(`Furthermore, your submission did not include a good decision criteria. Some examples could be: Is your problem worth solving (regarding the need and your companies strategy)? Is your solution clearly solving the problem you identified previously? Did the business model testing satisfy the success criteria you set in advance?
Based on these decision criteria, you can decide to continue, pivot, or kill the project.`);
  }

  if (!Z0 && !Z1 && A2 === false && A1) {
    ret.push(`Your submission did not include a good decision criteria. Some examples could be: Is your problem worth solving (regarding the need and your companies strategy)? Is your solution clearly solving the problem you identified previously? Did the business model testing satisfy the success criteria you set in advance?
Based on these decision criteria, you can decide to continue, pivot, or kill the project.`);
  }

  return ret;
};
