import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts, scoredPoints) => {
  const ret = [];
  const { D0, D1, D2, D3 } = getSelectedOptionsFlags(selectedOpts);

  if (D0) {
    ret.push(`No submission.`);
  }

  if (D1) {
    ret.push(
      `What motivates your persona?
You did not provide enough goals and details to fully answer this question. Similarly to needs, deeply understanding the aspirations of your users will help you create more value for them. Ultimately this should be your biggest goal. Therefore, we recommend you take a look at the user research you have already performed and identify more goals and aspirations.`
    );
  }

  if (D2) {
    ret.push(
      `What motivates your persona?
You did not provide enough goals and details to fully answer this question. Similarly to needs, deeply understanding the aspirations of your users will help you create more value for them. Ultimately this should be your biggest goal. Therefore, we recommend you take a look at the user research you have already performed and identify more goals and aspirations.`
    );
  }

  if (D3) {
    ret.push(
      `You have stated clear goals. Well done.
Similarly to needs, identifying understanding your users' goals will make it easier for you to create better, tailor-made services and products for them.`
    );
  }

  return ret;
};
