import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Perfect! You have listed all the people required to run and grow the project. You have also mentioned their value in terms of cost and the time commitment required by them. This is a great place to start and provides a good estimate for the direct costs involved in the project.`
    );
  }

  return ret;
};
