import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for sharing the agenda and screenplay. This is one of the most important planning steps for a successful workshop!`
    );
  }

  if (A2) {
    ret.push(
      `Well done, your screenplay includes detailed timing for each activity. Even though you might need to adapt slightly during the workshop, this will give you the needed structure to push your team to great outcomes!`
    );
  }

  if (A3) {
    ret.push(
      `Good job! Including an welcoming and introduction part is great to align everyone early about the relevance and the goals of the workshop, and manage their expectations.`
    );
  }

  if (A4) {
    ret.push(
      `You also included a energizer, well done! Energizers help any type of team to get into the right mindset.`
    );
  }

  if (A5) {
    ret.push(
      `Breaks are essential to keep everyone active and at their full cognitive potential. You should schedule at least 10 minutes of break after every 1.5h of workshop. Congrats! You successfully applied that rule.`
    );
  }
  if (A6) {
    ret.push(
      `Well done! The open-run-close principle is also relevant for the workshop as a whole, and included a time slot to close and conclude on your workshop results on!`
    );
  }
  if (A7) {
    ret.push(
      `All the activities you planned follow the open-run-close structure. Keep up the good work, your workshop will be a success!`
    );
  }
  if (A8) {
    ret.push(
      `Most of the activities you planned follow the open-run-close structure. Add a introduction and conclusion to the missing ones and you are ready for an amazing workshop!`
    );
  }

  if (A9) {
    ret.push(
      `The open-run-close principle means that every activity should be opened by explaining the relevance, the task at hand, and what is expected from the participants. After you run the activity, you close by summarizing the outputs, drawing conclusions, and defining next steps. You can improve your workshop and the outcomes by applying this structure to all activities you planned!`
    );
  }

  if (!Z0 && !Z1 && !Z2 && !A2) {
    ret.push(
      `Aim to include a more detailed timing into your screenplay. This can get down to even 2 min intervals! Even though you might need to adapt slightly during the workshop, this will give you the needed structure to push your team to great outcomes!`
    );
  }

  if (!Z0 && !Z1 && !Z2 && !A3) {
    ret.push(
      `The open-run-close principle is also relevant for the workshop as a whole. Think how you open it, and include a time slot to welcome participants and  align everyone early about the relevance and the goals of the workshop.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && !A4) {
    ret.push(
      `Please also include an energizer into your workshop. No matter how serious or senior the workshop participants are, or how high the motivation already is: Energizers put everyone in the right mindset for the tasks ahead.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && !A5) {
    ret.push(
      `Breaks are essential to keep everyone active and at their full cognitive potential. Remember to schedule at least 10 minutes of breaks after every 1.5h of workshop.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && !A6) {
    ret.push(
      `The open-run-close principle is also relevant for the workshop as a whole. Think how you close it, and include a time slot to conclude on your workshop results.`
    );
  }

  return ret;
};
