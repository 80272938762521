import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts, scoredPoints) => {
  const ret = [];
  const { Z0, Z1, Z2, Z3, Z4, C3, D2, D3, D4, D5, D6 } = getSelectedOptionsFlags(selectedOpts);

  if (!Z2 && !Z3 && !Z4 && !Z0 && !Z1 && D2 === false) {
    ret.push(
      `Your fourth feedback could have been more specific. While something like "Great work yesterday!" may seem like good feedback, it is essential to be always very clear about what you mean. In this example above, you may be implying that your coworker handled that meeting yesterday perfectly. However, she may have understood that you really liked her report on something. The same goes for constructive feedback. If you want someone to understand and improve, you should always give precise feedback so that they know exactly what went well, or waht should be improved.`
    );
  }

  if (!Z2 && !Z3 && !Z4 && !Z0 && !Z1 && D3 && D5 === false) {
    ret.push(
      `Your fourth feedback is not very actionable. Good constructive feedback is not only polite and specific, but it should also define the next steps or some action that could yield better results. For example, instead of saying, "Your work yesterday was good, but was missing a strong conclusion", you should add something like "Next time, try to make a summary of the main points discussed and emphasize your main statement again". By doing this, you not only bring attention to a potential improvement, but you already give a certain goal or possible next step.`
    );
  }

  if (D2 && (D3 || D4) && D5) {
    ret.push(
      `Great job! The fourth feedback you have submitted is very well stated! This is essential as being able to give great feedback will not only help your colleagues improve even further, but can also strengthen your relationship and trust. Keep giving feedback just like that, and you will be a valuable member of your team!`
    );
  }

  if (D4 && D5 === false) {
    ret.push(
      `Good job! Your fourth feedback is specific and will make it clear to your colleague what exactly he or she did very well. It is important to give positive feedback as well to improve your relationship with your team and make it easier for your colleagues to accept and listen to the inevitable constructive feedback that they will receive eventually.`
    );
  }

  if (D6 === false && C3) {
    ret.push(
      `One thing you could improve is to start your feedback by mentioning something the other person did well. This will make it easier to transition to something they could improve.
Most importantly, it will also make it more likely that the person listens to your critiques.`
    );
  }

  return ret;
};
