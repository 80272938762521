import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Nice job! You have stated multiple assumptions to be validated from a quantitative perspective. By now you should be familiar with how to frame assumptions. `
    );
  }

  if (A2) {
    ret.push(
      `You have stated not stated enough assumptions. It is good to think of several assumptions to validate about a user experience. Gathering quantitative and qualitative data can be time-consuming; hence it is better to test multiple assumptions at once. `
    );
  }

  if (A3) {
    ret.push(
      `There is a clear connection between your assumptions and the core experience that you want to create for your user. Good job!`
    );
  }

  if (A4) {
    ret.push(
      `Some assumptions do not seem to be relevant to your experience. Reformulate the assumptions to make the connection with your experience clearer. `
    );
  }

  if (A5) {
    ret.push(
      `What is the connection between this assumption and your experience? Ideally, you would want your experience to be able to validate/invalidate this assumption. 
  Please go over your submission again and change them so that this connection is clearer.`
    );
  }

  if (A6) {
    ret.push(
      `In your future answers, please try to be more detailed.
As it is right now, we have not been able to evaluate whether you fully are able to apply this concept.`
    );
  }

  return ret;
};
