import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission,`);
  }

  if (Z1) {
    ret.push(`Your Innovator's Hypothesis is well designed.
You have managed to take the assumptions behind your idea and transformed them systematically into a clear hypothesis.
More importantly, your statements are precise and you have thought of good metrics to evaluate if your idea was successful.
You should be well prepared to design your experiment in more detail now.
Good luck!`);
  }

  return ret;
};
