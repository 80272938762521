import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);
  /* A1 is not all features from 1.3 are listed. Please list all of them in the future.
   */
  if (A1) {
    ret.push(`Great job on reordering your features according to their relevance in achieving your intended impact. 
As we discussed in the course, it's important to prioritize features that are essential to the user experience. Your analogy of vitamins and pain-killers is spot on - users will always prioritize features that solve their immediate pain points. 
Moving forward, let's continue to keep the user at the center of our design process and focus on features that will have the greatest impact on their experience.`);
  }

  /*
  if (A1 && !A5) {
    ret.push(`Thank you for reordering your features as asked. However, it seems that you did not clearly classify your features into Painkillers and Vitamins.
As mentioned in the course, while some people remember to take their vitamins, no one forgets their pain-killers.
This is why we will focus on these features from now on.`);
  }

  if (!Z0 && !A1 && !A5) {
    ret.push(`You did not clearly classify your features into Painkillers and Vitamins.
As mentioned in the course, while some people remember to take their vitamins, no one forgets their pain-killers.
This is why you should identify and focus on these features from now on.`);
  }
  */

  if (!Z0 && !A4) {
    ret.push(`Are you sure that your top Painkillers are really triggers for the effect you would like to have on your users?
Looking at your submission, we believe that they may not have this effect.
Therefore, we ask you to re-evaluate your painkillers and double check if you agree to it.
If unsure how to do this, please go over the material in lesson 2.`);
  }

  if (!Z0 && !A2) {
    ret.push(`Did you also order your features from most relevant at the top to least relevant at the bottom?
Looking at your submission, it seems that you did not.
Please take a minute to go over your submission and make sure that you agree to the order displayed.`);
  }

  if (!Z0 && A3) {
    ret.push(`One thing that we would like to point out is that some of your features are not easy to read.
In the future, please make sure that all of the material you submit is clear and readable.
This will not only ensure that you are graded accordingly, but it will also help us give you more actionable feedback.`);
  }

  return ret;
};
