import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B1, B2, B3, B4, B5, B6, B7, B8 } = getSelectedOptionsFlags(selectedOpts);

  if (B1 && B2 && B3) {
    ret.push(`Thank you for your submission.
Your learnings notes are clearly visible in your submission and they seem to represent well the number or learnings you have stated.
Good job! `
    );
  }

  if (B1 && B2 && !B3) {
    ret.push(`Thank you for your submission.
Your learnings notes are clearly visible in your submission. However, they do not seem to represent well the number or learnings you have stated.
In the future, please ttry to capture all (or at least most) of your work when submitting pictures.`
    );
  }

  if (B1 && !B2) {
    ret.push(`Thank you for submitting this file.
However, your file does not display your learnings notes on post-its very clearly. Please make sure that your future submissions represent your work better.` 
    );
  }

  return ret;
};
