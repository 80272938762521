import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, Z4, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } =
    getSelectedOptionsFlags(selectedOpts);

  if (!Z2 && !Z3 && !Z4 && !Z0 && !Z1 && A1 === false) {
    ret.push(
      `You did not state the question you and your team discussed as requested. For the future, please make sure to include all the necessary information so that we can give you a better feedback.`
    );
  }

  if (!Z2 && !Z3 && !Z4 && !Z0 && !Z1 && A1 && A10 === false) {
    ret.push(
      `While you did submit an interesting and relevant question, the question you have stated was not related to actions as asked.
This is not a mistake, but we indicated topics for each question to make sure that you will cover different topics. Therefore, we kindly ask you to follow the instructions more closely in the future.`
    );
  }

  /*
  if (!Z0 && !Z1 && A1 && A2 === false) {
    ret.push(
      `Thank you for submitting the question discussed in your team. However, please make sure to include your personal answer to that question before talking about similarities and differences.`
    );
  }
  */

  if (A4) {
    ret.push(
      `Good job! The goal of this exercise was to check how aligned the members of your team are. There is no right or wrong answer, but it seems like your team members have, as expected, a mix of opinions. In some cases, you all agree. In others, you disagree. The goal now is to understand those differences and make good use of them. Just make sure to share your information, listen to each other and cooperate. By doing that, you can reduce this friction and increase the benefits of having diversity in your team. This will leverage your team's informational diversity and help you achieve better solutions.`
    );
  }

  if (A5) {
    ret.push(
      `Nicely done! The goal of this exercise was to check how aligned the members of your team are. There is no right or wrong answer, but it seems like all the members of your team have a similar opinion on these questions. This means that you will likely have little friction when deciding what to do or setting common goals. However, it can also mean that this is not a very informationally diverse team.`
    );
  }

  if (A6) {
    ret.push(
      `Well done! The goal of this exercise was to check how aligned the members of your team are. There is no right or wrong answer, but it seems like your team members have diverse opinions on these questions. This means that you will likely have some friction when deciding what to do and when setting your priorities. However, this can also mean that this team is highly informationally diverse, which means that you have the potential to leverage your differences to find better solutions! Just make sure to share your information, listen to each other and cooperate. By doing that, you can reduce this friction and increase the benefits of having diversity in your team.`
    );
  }

  if (A7) {
    ret.push(
      `You only stated one similarity between your and your team's answer. We would have loved to see if there were any additional similarities or differences in your team's answers! This would have helped us give you meaningful feedback and accelerate your learning process. Overall, this exercise's goal was to check how aligned the members of your team are. There is no right or wrong answer, but we would expect that you would all agree in some cases, and in others, you would all disagree. It is then essential to understand those differences and make good use of them. Just make sure to share your information, listen to each other and cooperate. By doing that, you can reduce any friction that might arise and increase the benefits of having diversity in your team. This can leverage your team's informational diversity and help you achieve better solutions.`
    );
  }

  if (A8) {
    ret.push(
      `You only stated one difference between your and your team's answer. We would have loved to see if there were any additional similarities or differences in your team's answers! This would have helped us give you meaningful feedback and accelerate your learning process. Overall, this exercise's goal was to check how aligned the members of your team are. There is no right or wrong answer, but we would expect that you would all agree in some cases, and in others, you would all disagree. It is then essential to understand those differences and make good use of them. Just make sure to share your information, listen to each other and cooperate. By doing that, you can reduce any friction that might arise and increase the benefits of having diversity in your team. This can leverage your team's informational diversity and help you achieve better solutions.`
    );
  }

  if (A9) {
    ret.push(
      `You did not submit the results of your team discussion. We would have loved to see if there were any similarities or differences in your team's answers! This would have helped us give you meaningful feedback and accelerate your learning process. Overall, this exercise's goal was to check how aligned the members of your team are. There is no right or wrong answer, but we would expect that you would all agree in some cases, and in others, you would all disagree. It is then essential to understand those differences and make good use of them. Just make sure to share your information, listen to each other and cooperate. By doing that, you can reduce any friction that might arise and increase the benefits of having diversity in your team. This can leverage your team's informational diversity and help you achieve better solutions.`
    );
  }
  return ret;
};
/*
  CRITERIA IN EX1
    { id = "A1", text = "A question (related to prejudices) is provided", points = 1 },
    { id = "A2", text = "Personal answer to the question is provided", points = 1 },
    { id = "A3", text = "Good submission overall", points = 1 },
    { id = "#6", text = "----- PICK ONE FROM THE OPTIONS BELOW ----- ----- ----- ----- -----", points = 0},
    { id = "A4", text = "2+ both SIMILARITIES AND DIFFERENCES stated", points = 1 },
    { id = "A5", text = "2+ only SIMILARITIES stated (No differences)", points = 1 },
    { id = "A6", text = "2+ only DIFFERENCES stated (No similarities)", points = 1 },
    { id = "A7", text = "ONLY ONE similarity stated in the answer (No differences)", points = 0.5 },
    { id = "A8", text = "ONLY ONE difference stated in the answer (No similarity)", points = 0.5 },
    { id = "A9", text = "NO similarity or difference stated", points = 0 },
*/
