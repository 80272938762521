import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2 && A3) {
    ret.push(
      `Great job!
Your conclusion section includes all the relevant parts of a good interview guideline!
Just don't forget to keep paying attention even after the interview is "officially" done, as it is possible that the participants might feel more at ease and remember one or two more things. `
    );
  }

  if (A1 === false && A2 && A3 && Z0 === false && Z1 === false) {
    ret.push(
      `Good job!
Your conclusion was well written and contains most of the relevant parts.
However, while you did ask for final questions or comments and mentioned a possible future cooperation, you did not include any indication of gratitude in your conclusion. As your interviewee took some time to meet you and answer your questions, you should always remember to thank him/her for his/her help! This also increases the chance that he/she might join you in the future for some follow-up questions or even to get some feedback on a possible prototype.`
    );
  }

  if (A1 && A2 === false && A3 && Z0 === false && Z1 === false) {
    ret.push(
      `Good job!
Your conclusion was well written and contains most of the relevant parts.
However, while you did thank your interviewee and opened the way for a future cooperation, you did not ask for final comments or thoughts at the end of the interview. This is important because there might be something relevant that you did not ask for.
Additionally, it often happens that after the interview is officially over, the participants feel more at ease and remember something of value. This is why you should always casually add a small question for final thoughts at the end.`
    );
  }

  if (A1 && A2 && A3 === false && Z0 === false && Z1 === false) {
    ret.push(
      `Good job!
Your conclusion was well written and contains most of the relevant parts.
However, while you did thank your interviewee and ask for final thoughts or questions, you did not ask your participant if some future collaboration is possible at the end of the interview. This is not super relevant, but doing so will make it easier to contact him again in the future in case you have some follow-up questions. Therefore, we recommend you always ask this at the end.`
    );
  }

  if (A1 === false && A2 && A3 === false && Z0 === false && Z1 === false) {
    ret.push(
      `Your conclusion was well written and you included a question for final thoughts or questions.
However, you did not include any indication of gratitude in your conclusion. As your interviewee took some time to meet you and answer your questions, you should always remember to thank him/her for his/her help! This also increases the chance that he/she might join you in the future for some follow-up questions or even to get some feedback on a possible prototype.
Additionally, you did not ask your participant if some future collaboration is possible at the end of the interview. This is not super relevant, but doing so will make it easier to contact him again in the future in case you have some follow-up questions. Therefore, we recommend you always ask this at the end of your interview.`
    );
  }

  if (A1 && A2 === false && A3 === false && Z0 === false && Z1 === false) {
    ret.push(
      `Your conclusion was well written and you did a good job remembering to thank your interviewee.
However, you did not ask for final comments or thoughts at the end of the interview. This is important because there might be something relevant that you did not ask for.
Additionally, it often happens that after the interview is officially over, the participants feel more at ease and remember something of value. This is why you should always casually add a small question for final thoughts at the end.
Lastly, you did not ask your participant if some future collaboration is possible at the end of the interview. This is not super relevant, but doing so will make it easier to contact him again in the future in case you have some follow-up questions. Therefore, we recommend you always ask this at the end of your interview.`
    );
  }

  if (A1 === false && A2 === false && A3 && Z0 === false && Z1 === false) {
    ret.push(
      `Your conclusion was well written and you remembered to ask your interviewee for some future collaboration or follow-up.
However, you did not ask for final comments or thoughts at the end of the interview. This is important because there might be something relevant that you did not ask for.
Additionally, you did not include any indication of gratitude in your conclusion. As your interviewee took some time to meet you and answer your questions, you should always remember to thank him/her for his/her help! This also increases the chance that he/she might join you in the future for some follow-up questions or even to get some feedback on a possible prototype.`
    );
  }

  if (A1 === false && A2 === false && A3 === false && Z0 === false && Z1 === false) {
    ret.push(
      `Thank you for your submission. However, your conclusion is missing some relevant parts.
First, you did not include any indication of gratitude in your conclusion. As your interviewee took some spare time to meet you and answer your questions, you should always remember to thank him/her for his/her help! This also increases the chance that he/she might join you in the future for some follow-up questions or even to get some feedback on a possible prototype.
Then, you did not ask for final comments or thoughts at the end of the interview. This is important because there might be something relevant that you did not ask for. Additionally, it often happens that after the interview is officially over, the participants feel more at ease and remember something of value. This is why you should always casually add a small question for final thoughts at the end.
Lastly, you did not ask your participant if some future collaboration is possible at the end of the interview. This is not super relevant, but doing so will make it easier to contact him again in the future in case you have some follow-up questions. Therefore, we recommend you to think about yor closing statements again and make sure to include these three points.`
    );
  }

  return ret;
};
