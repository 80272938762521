import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts, scoredPoints) => {
  const ret = [];
  const { Z0, Z1, D1, D2, D3, D4, D5, D6, D7, D8 } = getSelectedOptionsFlags(selectedOpts);

  if (D1) {
    ret.push(`Thank you for the additional submission.`);
  }

  return ret;
};
