import * as React from "react";
import { Box, Heading, Select, Text } from "theme-ui";
import { ReactComponent as Download } from "@sparkademy/app-common/materials/icons/download.svg";
import { ButtonGhostLink } from "@sparkademy/app-common/elements/ButtonGhost";
import { useAssignmentContext } from "@sparkademy/app-common/contexts/assignment-context";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { Assignment, ExerciseSubmission } from "@sparkademy/app-common/models/assignment";
import { useGradingContext } from "../contexts/grading-context";
import { ModuleId } from "@sparkademy/app-common/models/module";
import { ButtonGhostIconAnchor } from "@sparkademy/app-common/elements/ButtonGhostIcon";
import { GradingService } from "../services/grading";
import { useParams } from "react-router-dom";

const getSubmissionStatus = (
  submission: ExerciseSubmission
): "graded" | "grading" | "submitted" => {
  return submission.graded_at ? "graded" : submission.grading_locked_by ? "grading" : "submitted";
};

export const ParticipantsTab: React.FC<{ assignmentSubmissions: ExerciseSubmission[] }> = ({
  assignmentSubmissions,
}) => {
  const { currentUser } = useSessionContext();
  const {
    selectedModule,
    setSelectedModule,
    fetchModuleAssignment,
    assignment,
    loading: loadingGradingData,
  } = useGradingContext();
  const { cohortId } = useParams<{ cohortId: string }>();

  // grab the user id from the last submission to fetch the assignment structure (questions, exercises, etc)
  // that'so we use the same version learners used back then (v1, v2, etc)
  // usually everyone in the same cohort will use the same version of the assignment
  const lastSubmission = assignmentSubmissions[assignmentSubmissions.length - 1];
  const lastSubmissionUserId = lastSubmission?.user_id;

  React.useEffect(() => {
    if (!currentUser || !lastSubmissionUserId || loadingGradingData) {
      return;
    }

    fetchModuleAssignment(lastSubmissionUserId, selectedModule, cohortId!, false, currentUser);
  }, [
    selectedModule,
    currentUser,
    fetchModuleAssignment,
    assignment,
    cohortId,
    loadingGradingData,
    lastSubmissionUserId,
  ]);

  const onModuleChange = (value: string) => {
    setSelectedModule(value as ModuleId);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          textAlign: "left",
          borderTop: "1px solid",
          borderTopColor: "new.secondary.grey",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", my: "48px" }}>
          <Box sx={{ justifyContent: "flex-start" }}>
            <Heading as="h2" sx={{ fontSize: "24px", fontWeight: "700", mb: "32px" }}>
              Module
            </Heading>
            <Select
              sx={{
                width: "220px",
                height: "44px",
                border: "1px solid #BFBFBF",
                borderRadius: "5px",
                bg: "white",
              }}
              value={selectedModule}
              onChange={e => onModuleChange(e.target.value)}
            >
              <option value="m0">M0</option>
              <option value="m1">M1</option>
              <option value="m2">M2</option>
              <option value="m3">M3</option>
              <option value="m4">M4</option>
              <option value="a_businessmodel">A_BUSINESSMODEL</option>
              <option value="a_designsprints">A_DESIGNSPRINTS</option>
              <option value="a_innovationfinance">A_INNOVATIONFINANCE</option>
              <option value="a_uxdesign">A_UXDESIGN</option>
              <option value="a_projectwork">A_PROJECTWORK</option>
              <option value="demo">Demo</option>
            </Select>
          </Box>

          <Box sx={{ display: "flex" }}>
            <ButtonGhostIconAnchor
              icon={Download}
              iconPosition="right"
              sx={{ pl: 0, fontSize: "16px" }}
              href={GradingService.exportUrl(cohortId!, currentUser!)}
              target="_blank"
            >
              Download Results
            </ButtonGhostIconAnchor>
          </Box>
        </Box>

        <ParticipantsTable assignmentSubmissions={assignmentSubmissions} assignment={assignment} />
      </Box>
    </React.Fragment>
  );
};

const ParticipantsTable: React.FC<{
  assignment: Assignment;
  assignmentSubmissions: ExerciseSubmission[];
}> = ({ assignment, assignmentSubmissions }) => {
  const { selectedModule } = useGradingContext();
  const { currentUser } = useSessionContext();

  const submissionsByParticipant = assignmentSubmissions
    .filter(as => as.module_id === selectedModule)
    .reduce((acc, cur) => {
      if (!acc[cur.user_id]) {
        acc[cur.user_id] = {};
      }
      acc[cur.user_id]["name"] = cur.user_name;
      acc[cur.user_id][cur.exercise_id] = getSubmissionStatus(cur);
      return acc;
    }, {} as { [key: string]: any });

  const participantIds = Object.keys(submissionsByParticipant);
  const exerciseIds = assignment.exercises.map(e => e.id);

  const ExerciseCell = (userId: string, exerciseId: string) => {
    const exerciseStatus = submissionsByParticipant[userId][exerciseId];
    if (exerciseStatus === "graded") {
      const submission = assignmentSubmissions.find(
        as =>
          as.user_id === userId && as.exercise_id === exerciseId && as.module_id === selectedModule
      );
      return (
        <ButtonGhostLink
          to={`/feedback/${submission?.id}`}
          sx={{ fontSize: "16px", p: 0, minHeight: "unset" }}
        >
          Graded
        </ButtonGhostLink>
      );
    }
    if (exerciseStatus === "grading") {
      return <Text sx={{ fontSize: "16px", fontWeight: 700, color: "new.secondary.mint" }}>o</Text>;
    }
    return <Text sx={{ fontSize: "16px", fontWeight: 700, color: "new.secondary.grey" }}>x</Text>;
  };

  if (participantIds.length === 0) {
    return <Text>There is no data to show.</Text>;
  }

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: "new.secondary.grey",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          p: "24px 0 18px 48px",
          color: "white",
          bg: "black",
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
        }}
      >
        <Heading as="h4" sx={{ width: "200px" }}>
          {currentUser?.role === "admin" ? "Name" : "User ID"}
        </Heading>
        {exerciseIds.map(eid => (
          <Heading key={eid} as="h4" sx={{ width: "100px" }}>
            {eid}
          </Heading>
        ))}
      </Box>

      <Box
        sx={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          borderColor: "new.secondary.grey",
        }}
      >
        {participantIds.map((key, idx) => (
          <Box
            key={key}
            sx={{
              display: "flex",
              p: "14px 0 13px 48px",
              bg: idx % 2 === 0 ? "white" : "new.secondary.lightGrey",
              borderBottomLeftRadius: idx === participantIds.length - 1 ? "10px" : 0,
              borderBottomRightRadius: idx === participantIds.length - 1 ? "10px" : 0,
            }}
          >
            <Text sx={{ width: "200px" }}>
              {currentUser?.role === "admin" ? submissionsByParticipant[key]["name"] : key}
            </Text>
            {exerciseIds.map(eid => (
              <Box key={eid} sx={{ width: "100px" }}>
                {ExerciseCell(key, eid)}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
