import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Excellent work! You used trusted sources of inspiration and included a clear explanation of why they were valuable for your revised business model. You were thoughtful about how it was relevant to the different elements in your business model canvas such as user needs, the market environment, and the creation and capture of value. It's important that the inspiration you draw on connects to some parts of your business model while still pushing it to change and improve in ways that are outside of the box.`
    );
  }

  return ret;
};
