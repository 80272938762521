import * as React from "react";
import { Box, Heading, Select } from "theme-ui";
import { useParams, useHistory } from "react-router-dom";
import { Layout } from "../components/Layout";
import { useQuery } from "@sparkademy/app-common/utils/useQuery";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { Container } from "@sparkademy/app-common/elements/Container";
import { ButtonGhost } from "@sparkademy/app-common/elements/ButtonGhost";
import { useAssignmentContext } from "@sparkademy/app-common/contexts/assignment-context";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { updateQuery } from "@sparkademy/app-common/utils/updateQuery";
import { GradingTab } from "../components/GradingTab";
import { ParticipantsTab } from "../components/ParticipantsTab";
import { useGradingContext } from "../contexts/grading-context";

export const Index: React.FC = () => {
  const { currentUser } = useSessionContext();
  const hasGraderRole = currentUser?.data.role === "grader";

  const {
    loading: loadingSubmissions,
    assignmentSubmissions,
    fetchAssignmentSubmissions,
  } = useAssignmentContext();
  const { cohortId } = useParams<{ cohortId: string }>();
  const query = useQuery();
  const { cohorts, getCohorts } = useGradingContext();
  const history = useHistory();

  const currentTab = query.get("tab") || "grading";
  const [visibleTab, setVisibleTab] = React.useState<string>(currentTab);

  React.useEffect(() => {
    if (!currentUser || !cohortId) {
      return;
    }
    fetchAssignmentSubmissions(cohortId, currentUser);
  }, [cohortId, currentUser, fetchAssignmentSubmissions]);

  React.useEffect(() => {
    if (currentUser) {
      getCohorts(false, currentUser);
    }
  }, [getCohorts, currentUser]);

  if (loadingSubmissions || cohorts.length === 0) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  const notAllowedCohort = cohortId && !cohorts.find(c => c.id === cohortId);
  if (hasGraderRole && notAllowedCohort) {
    return (
      <Layout>
        <Box sx={{ display: "flex", justifyContent: "center", p: 10 }}>
          You don't have permission to access this cohort.
        </Box>
      </Layout>
    );
  }

  const onCohortChange = (cohortId: string) => {
    history.push(`/grading/${cohortId}`);
  };

  return (
    <Layout>
      <Container
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          py: "80px",
        }}
      >
        <Box sx={{ width: "100%", mb: "48px" }}>
          <Heading as="h2" sx={{ fontSize: "35px", fontWeight: "700", mb: "32px" }}>
            Cohort
          </Heading>
          <Select
            sx={{
              width: "220px",
              height: "44px",
              border: "1px solid #BFBFBF",
              borderRadius: "5px",
              bg: "white",
            }}
            value={cohortId}
            onChange={e => onCohortChange(e.target.value)}
          >
            <option value="">Select a cohort</option>
            <option value="courserun_7">courserun_7</option>
            {cohorts.map(c => (
              <option key={c.id} value={c.id}>
                {c.id}
              </option>
            ))}
          </Select>
        </Box>

        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <ButtonGhost
            sx={{
              fontSize: "16px",
              fontFamily: "inherit",
              p: 0,
              color: visibleTab === "grading" ? "primary" : "new.secondary.grey",
            }}
            onClick={() => {
              setVisibleTab("grading");
              updateQuery("tab", "grading");
            }}
          >
            Grading
          </ButtonGhost>
          <ButtonGhost
            sx={{
              fontSize: "16px",
              fontFamily: "inherit",
              p: 0,
              ml: "32px",
              color: visibleTab === "participants" ? "primary" : "new.secondary.grey",
            }}
            onClick={() => {
              setVisibleTab("participants");
              updateQuery("tab", "participants");
            }}
          >
            Participants
          </ButtonGhost>
        </Box>

        {visibleTab === "grading" && <GradingTab assignmentSubmissions={assignmentSubmissions} />}
        {visibleTab === "participants" && (
          <ParticipantsTab assignmentSubmissions={assignmentSubmissions} />
        )}
      </Container>
    </Layout>
  );
};
