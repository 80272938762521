import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  if (A4) {
    ret.push(
      `Great job stating your overarching question. The scope is just right! This will help you focus on the most important need of your user while not being too constrained in your ideation session. This is important because it will allow you to think of several relevant solutions.`
    );
  }

  if (A0 && A2 && A7 && A3 && A4 === false) {
    ret.push(
      `Very well done! Your problem statement is well stated and addresses an underlying need of your persona. This will help you focus on the most important need of your user while not being too constrained in your ideation session. This is important because it will allow you to think of several relevant solutions.`
    );
  }

  if (A1 && A0 === false) {
    ret.push(
      `While you did include an actor to your problem statement, you did not use the persona you have defined previously.
You have put so much work into identifying your users needs and behaviours and then representing them in a persona. Now you should make sure to use that persona!`
    );
  }

  if (A0 && A2 && A7 && A3 && A4) {
    ret.push(
      `Furthermore, your problem statement is well stated and addresses an underlying need of your persona. This will help you focus on the most important need of your user while not being too constrained in your ideation session. This is important because it will allow you to think of several relevant solutions.`
    );
  }

  if (A5) {
    ret.push(
      `Your problem statement is too specific. Try being a bit more general and giving lees details. Being too specific will reduce the amount of insights and innovations you can generate.`
    );
  }

  if (A6) {
    ret.push(
      `Your problem statement is too broad. A good problem statement should have a clear focus. Very broad statements make it hard for you to target a few key assumptions and issues.`
    );
  }

  if (!Z0 && !Z1 && A0 === false && A1 === false) {
    ret.push(
      `No actor was mentioned. Please make sure to always include an actor or user in your problem statement.
Ideally, you should use your persona as your actor. This is super important as the whole goal of design thinking is to really understand your user and create tailored solutions for him/her. Using your persona and focusing on their underlying needs will help you accomplish that.`
    );
  }

  if (!Z0 && !Z1 && A2 === false && A7 === false) {
    ret.push(
      `No need mentioned. To create a lot of value for your users, it is important to focus on a deep underlying need or issue they might have. This is the central part of your problem statement and should always be very clear. Therefore, please rewrite a new overarching question putting more emphasis on a relevant need identified in M1.`
    );
  }

  if (!Z0 && !Z1 && A2 && A7 === false) {
    ret.push(
      `While you did mention a need in your problem statement, your stated need is not a deep underlying need. Instead, it is something more superficial that is only a means to an end.
At this stage of the process you should focus more on the final goal.
It is a good idea to ask yourself if the need stated is really the final goal or only a means to an end.  Make sure to focus on the latter for now.
      `
    );
  }

  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `You did not include a relevant insight or learning in your problem statement. After doing research on your users, you must have learned something about their motivations and needs. Use this information now to create a more targeted problem statement that tackles these needs and frustrations. For this, we advise you to integrate your key finding into your problem statement.`
    );
  }

  return ret;
};
