import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Good job! Your metrics are measurable and actionable for your project. You successfully avoided the usage of vanity metrics and instead focused on metrics that will help you to make educated decisions throughout the project.`
    );
  }

  return ret;
};
