import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);
  /*
    { id = "A1", text = "User states how it felt to give feedback", points = 0.5 },
    { id = "A2", text = "User named one thing that he would do differently", points = 1 },
    { id = "A3", text = "Answer shows reflection and/or is very detailed and well worked", points = 2 },
    { id = "#7", text = "----- ----- ----- ----- Additional feedback ----- ----- ----- ----- ----- ", points = 0},
    { id = "A5", text = "Participant states that he struggles to give negative feedback", points = 0},
  */

  if (A1 && A2 && !A5) {
    ret.push(
      `It seems like you reflected well about this topic. Thank you.

One last tip we can give you is to always prepare your feedback sessions by thinking on what you would like to achieve with this feedback.
If you can not clearly visualize how this feedback session will help your colleague and your organization, then it may be better not to say anything.
However, once you have a clear objective, you should think about how to share your feedback in a way that helps you accomplish that.`
    );
  }

  if (A1 && A2 && A5) {
    ret.push(
      `It seems like you reflected well about this topic. Thank you.`
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `How did it feel to give feedback?
Taking these feelings into account before giving feedback will undoubtedly make the experience more comfortable and enjoyable for all parties.
That is why we hope that the next time you provide or are given feedback, that you will take the time to reflect on how it felt and how you could improve that experience.`
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `Now that you have reflected on how it felt to give and receive feedback, you can also try to find possible improvements to give better feedback in the future.
What could you change to create a better experience for all the parties involved?
Please take some time to reflect on this to make sure that you take the most out of this experience.`
    );
  }

  if (A4) {
    ret.push(
      `This exercise requires you to find a situation where you can share feedback and that is often not easy.
However, to work in teams effectively it is important that you are able to give honest and constructive feedback anytime.
If you feel like this is not the case yet, try to look back at this experience.
What could you have done differently to make this exchange be more comfortable and productive?`
    );
  }

  if (A5) {
    ret.push(
      `Regarding your nervousness giving constructive feedback, one thing that often helps is to really think about what you want to achieve with your feedback, before having the feedback session.
If your goal really is to help your colleague improve and learn, then you should find a way to state it in a positive and supportive manner.
If, however, you feel like maybe your are using your feedback as an excuse to let out steam or complain about something that is not easy to change (e.g. criticize personality instead of behaviour) then it is best to not say anything.`
    );
  }

  return ret;
};
