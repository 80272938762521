import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, B0, B1, B2, B3, B4, B5, B6, B7, B8, B9, B10, B11, B12, B13 } =
    getSelectedOptionsFlags(selectedOpts);
  /*
    { id = "B0", text = "User stated the goal", points = 0.5 },
    { id = "B1", text = "User says goal is SMART", points = 0.5 },
    { id = "B20", text = "User says goal is not SMART", points = 0.5 },
    {id = "#3", text = "----- ----- ----- ----- If user said goal is SMART ----- ----- ----- ----- ----- ", points = 0},
    { id = "B2", text = "User correctly states why it is specific", points = 0.5 },
    { id = "B3", text = "User correctly states why it is measurable", points = 0.5 },
    { id = "B4", text = "User correctly states why it is achievable and ambitious", points = 0.5 },
    { id = "B5", text = "User correctly states why it is relevant", points = 0.5 },
    { id = "B6", text = "User correctly states how it is time-bounded", points = 0.5 },
    {id = "#4", text = "----- ----- ----- ----- If user said goal is NOT SMART - Part 1 ----- ----- ----- ----- ----- ", points = 0},
    { id = "B7", text = "User rephrased goal and it is SMART now", points = 2.5 },
    { id = "B8", text = "User rephrased goal and it still is not SMART", points = 1 },
    {id = "#5", text = "----- ----- ----- ----- Pick what is missing for it to be SMART ----- ----- ----- ----- ----- ", points = 0},
    { id = "B9", text = "Still needs to be specific", points = 0 },
    { id = "B10", text = "Still needs to be measurable", points = 0 },
    #{ id = "B11", text = "Still needs to be achievable and ambitious", points = 0 },
    #{ id = "B12", text = "Still needs to be relevant", points = 0 },
    { id = "B13", text = "Still needs to be time-bounded", points = 0 },
    {id = "#6", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id = "B14", text = "Good submission", points = 1 },
    */

  if (B0 && B7 === false) {
    ret.push(`Thank you for stating the goal you will analyze in more depth.`);
  }

  if (!Z0 && !Z1 && B0 === false) {
    ret.push(
      `You did not state the goal you will analyze in more depth.
We asked you to state that because it helps us understand your submission better and allows us to give you more effective feedback.`
    );
  }

  if (B1 && B2 && B3 && B4 && B5 && B6) {
    ret.push(
      `Well done, you have correctly identified how this goal is SMART and explained why each aspect of this framework is correct.
Being able to distinguish regular goals frm SMART goals will help you understand and set better goals in the future.
Good job!`
    );
  }

  if (B1 && B2 === false && B0) {
    ret.push(
      `However, are you sure that this goal is specific enough?
We would have liked to see a brief explanation of why you believe this goal is specific. A SMART goal should always be very specific. Receiving specific goals reduce the chance of having misunderstandings in your team and will make it easier for them to be reached. For a summary on the specificity of goals, you can check the M4 method cards.`
    );
  }

  if (B1 && B2 === false && B0 === false) {
    ret.push(
      `Moreover, are you sure that this goal is specific enough?
We would have liked to see a brief explanation of why you believe this goal is specific. A SMART goal should always be very specific. Receiving specific goals reduce the chance of having misunderstandings in your team and will make it easier for them to be reached. For a summary on the specificity of goals, you can check the M4 method cards.`
    );
  }

  if (B1 && B3 === false && (B0 || B2)) {
    ret.push(
      `However, are you sure that this goal is measurable? What metric will you use to track its progress and confirm its termination?
Having measurable goals will help both the goal-setting party as well as the goal-achieving party to track their progress. Knowing how close or far one is from achieving a goal makes time management much more straightforward and is often a good incentive. The closer one is to finish a goal, the more energy he/she might have to achieve it. Next time you could try to explain how it is measurable.`
    );
  }

  if (B1 && B3 === false && (B0 === false || B2 === false)) {
    ret.push(
      `Furthermore, are you sure that this goal is measurable?
What metric will you use to track its progress and confirm its termination?
Having measurable goals will help both the goal-setting party as well as the goal-achieving party to track their progress. Knowing how close or far one is from achieving a goal makes time management much more straightforward and is often a good incentive. The closer one is to finish a goal, the more energy he/she might have to achieve it. Next time you could try to explain how it is measurable.`
    );
  }
  /*
  if (B1 && B4 === false) {
    ret.push(
      `Is this goal achievable (or not)? We would have liked to see some short explanation. In general, it is important to set high goals, as this motivates the team to work extra hard. However, setting goals that are certainly unachievable can be demotivating. That is why it is crucial to set achievable goals. An excellent way to ensure that they are achievable is to set them together with the person or team that has to achieve them. Their inputs will help set more realistic goals.`
    );
  }
  */
  if (B1 && B5 === false) {
    ret.push(
      `How is this goal relevant?
We would have liked to hear your explanation. Keeping the motivation in your team high is very important to achieve your goals efficiently. That is why it is essential to set relevant goals. Doing so will help keep your team motivated throughout the whole project. A good strategy to set relevant goals is to set them with the individuals who will have to achieve them later. Listening to their inputs and making sure that they understand your reasoning can make a big difference!`
    );
  }

  if (B1 && B6 === false) {
    ret.push(
      `You did not define a specific time frame for your goal.
Setting a time constraint to your goal is very important, as it makes time-management more efficient and helps keep your team motivated. Having too much time or no clear deadline makes this goal lose priority early on. However, it is important to set reasonable expectations, as giving too little time can also be harmful to your project.`
    );
  }

  if (B7) {
    ret.push(
      `Good job adapting your goal and stating it in a way that it is SMART!
From now on we strongly advise you to always reflect on the goals set in your team and evaluate if they are SMART or not.
Being able to transform normal goals into SMART goals will be a valuable skill for your team.
`
    );
  }

  if (B8) {
    ret.push(
      `Good job adapting your goal and stating it in a way that you believe would make it SMART.
However, based on what we read we believe that it still is missing some aspects for it to be fully SMART.`
    );
  }

  if (B9) {
    ret.push(
      `In order for your goal to be SMART it needs to be more specific.
Try to be more clear about what exactly has to be achieved. It is important that whoever is responsible for this goal will know exactly what is expected from him/her.`
    );
  }

  if (B10) {
    ret.push(
      `We believe that your goal is not clearly measurable.
According to Locke's research (See material), one of the key benefits of having measurable goals is the improvement in motivation due to the traceability of its metrics.
This is why it is important to add measurable metrics to your goals that can be tracked and compared before the goal is fully reached.
This is why goals with binary resolutions like "achieved" and "not achieved" are not really measurable.
Therefore, we recommend you include some measurable metric to your goal that can be tracked as you work on it from now on.`
    );
  }

  if (B13) {
    ret.push(
      `Your goal is stil not time-bound.
It is important to state very clearly how much time a team or colleague has to achieve this goal.
This will not only help motivate your colleagues, but will also help you plan ahead as the completion of your goals is clear and well defined.
Therefore, we advise you to go over your internal goals and state it in a way that amkes it very clear how long your team has to achieve this goal.`
    );
  }

  if (B9 || B10 || B13) {
    ret.push(
      `From now on we strongly advise you to always reflect on the goals set in your team and evaluate if they are SMART or not.
Being able to transform normal goals into SMART goals will be a valuable skill for your team.`
    );
  }

  return ret;
};
