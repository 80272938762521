import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `You have defined only one type of dot. We highly recommend you define more types of dots so that your ideas can be assessed along several dimensions. This will help you ensure that promising ideas that may lack in this one dimension you chose can still be considered due to its other qualities. This is super important to foster innovative solutions.`
    );
  }

  if (A2) {
    ret.push(
      `Good job defining more than only one type of dot! This will help you and your team assess your ideas along several dimensions instead of just one. This is important because, otherwise, you may discard ideas now, just because they failed this one criterion you used. `
    );
  }

  if (A3) {
    ret.push(
      `Good job defining more than only one type of dot! This will help you and your team assess your ideas along several dimensions instead of just one. This is important because, otherwise, you may discard ideas now, just because they failed this one criterion you used. `
    );
  }

  if ((A2 || A3) && A4) {
    ret.push(
      `Furthermore, thank you for stating very clearly what each type of dot signifies. This helps us evaluate your work later and shows that you have thought about this important detail. Well done!`
    );
  }

  if (A1 && A4) {
    ret.push(
      `On the other hand, thank you for stating very clearly what each type of dot signifies. This helps us evaluate your work later and shows that you have thought about this important detail. Well done!`
    );
  }

  if ((A2 || A3) && A5) {
    ret.push(`On the other hand, you did not make it very clear what each type of dot stands for.
Please make sure to write this down very clearly as it will help your participants distribute their votes more relibaly and with less effort.`);
  }

  if (A1 && A5) {
    ret.push(`Furthermore , you did not make it very clear what each type of dot stands for.
Please make sure to write this down very clearly as it will help your participants distribute their votes more relibaly and with less effort.`);
  }

  return ret;
};
