import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for your comment. We highly appreciate it!`);
  }

  if (A2) {
    ret.push(`Thank you for your comment. We highly appreciate it and would like to address the length of this module:
Module 1 is the most time-intensive module, so you should expect a lower workload from now on (not a huge difference).
However, we would also like to point out that it is important that you try to stick to the time recommended in the program. For example, if we ask you to take 5 minutes to discuss something in your team, you should try to use only 5 minutes to do so. It is very easy to extend team meetings and discussions for much longer than that. However, part of this methodology is to be scrappy and efficient. So it is crucial that you manage your times well and are able to stop a discussion when the time is over.

Our advice is that you try to time your next meetings to ensure that they do not go longer than necessary.

Still, we know that this module is long and we would like to assure you that we are working on it.`);
  }

  return ret;
};
