import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Very well done! You identified various potential improvements to your facilitation tactics. Your reflection proves that you truly understood what it takes to be a facilitator. And don't worry if you are not perfect yet - even the most experienced facilitators get into new situations and learn more after years of practice. Keep your learnings in mind for your next workshop, and you will facilitate a great session!`
    );
  }

  return ret;
};
