import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2 && A3 && !A6) {
    ret.push(
      `Your core element is well explained and is a good representation of your idea's main features.
Well done`
    );
  }

  if (A1 && !A2 && A5 && !A6) {
    ret.push(`Thank you for stating your core statement.`);
  }

  if (A1 && !A2 && !A5 && !A6) {
    ret.push(`Thank you for stating your core statement.
However, your submission would have been even better if you had explained why you believe this is the core element of your idea.`);
  }

  if (!Z0 && !Z1 && A2 === false && A5 && !A6) {
    ret.push(
      `However, your explanation of why this is the core of your idea was not clear.
The core element of your idea should represent its most essential feature. The thing that makes it valuable to your customers. Explaining what it is and why it is the core element will help you understand it better and help you evaluate if this is really its most essential feature. Therefore, please state your core feature again, this time making sure that it is very clear.`
    );
  }

  if (!Z0 && !Z1 && A3 === false && A2 && !A6) {
    ret.push(
      `Are you sure that this element is central to your idea?
The connection is not clear to us.
Please reflect on your idea and decide if this really is the core element. If it is, please change your submission so that this connection is clear. If it is not, please adapt your idea or change your stated core element.`
    );
  }

  if (!Z0 && !Z1 && A3 === false && A2 === false && !A6) {
    ret.push(
      `How is this core element related to your idea?
The connection is not clear to us.
Please reflect on your idea and decide if this really is the core element. If it is, please change your submission so that this connection is clear. If it is not, please adapt your idea or change your stated core element.`
    );
  }

  if (A4 && !A6) {
    ret.push(
      `However, while we appreciate the level of detail in your explanations, we would recommend you to write down your core element in a more concise manner.
If you cannot explain your the core element of your idea in one short statement, then maybe your idea is too complicated or not fully thought through yet.`
    );
  }

  if (A6) {
    ret.push(
      `It seems like you have submitted assumptions that are relevant and necessary for your idea.
However, the idea of this exercise was for you to simplify your idea as much as possible and identify only the key element of your idea.
Identifying this will help you validate your solution later.`
    );
  }

  return ret;
};
