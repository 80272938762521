import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);


    if (A5) {
      ret.push(
        `Thank you for sharing what strategy you would use moving forward. This strategy makes sense (given your business' maturity stage) and shows that you have done a good job identifying what is important for you to focus on. Great job!`
      );
    }
  
    if (A6) {
      ret.push(
        `Thank you for sharing what strategy you would use moving forward. This strategy makes sense (given your business' maturity stage), but we ask you to be more detailed in the future.
Reflecting on your current business model strengths and weaknesses, identifying what maturity stage you are in and using this information to come up with a meaningful strategy is crucial to launching new ventures.`
      );
    }
  
    if (A7) {
      ret.push(
        `Thank you for sharing what strategy you would use moving forward. However, we ask you to explain better why you believe this strategy to be right one for your business.
There is no need to resubmit this assignment, but reflecting on your current business model strengths and weaknesses, identifying what maturity stage you are in, and using this information to come up with a meaningful strategy for your business is crucial to launching new ventures (and deservers to be done with care).`
      );
    }
    
  if (A2) {
    ret.push(
      `You did not state a clear strategy that you can use to steer your business moving forward. 
Please review the content of this module and rethink what strategy you could use. Make sure to take into consideration your industry's life cycle phase. 
Doing so is an important first step to aligning your overall business model strategy with its industry.`
    );
  }

  if (A3) {
    ret.push(
      `Your suggested strategy is well aligned with key success factors for the lifecycle phase you stated for your industry. Good job!
You have demonstrated an understanding of your industry's lifecycle phase and its implications for the course of action needed as a business.`
    );
  }

  if (A4) {
    ret.push(
      `Your suggested strategy is not aligned with key success factors for the lifecycle phase you stated for your industry. 
Please review the content of this module and rethink what strategy you could use. Make sure to take into consideration your industry's life cycle phase.`
    );
  }

  

  return ret;
};
