import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } =
    getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "#13", text = "----- ----- ----- ----- Pick freely ----- ----- ----- ----- ----- ", points = 0},
    {id = "A1", text = "Overarching question has a user (type/group) included?", points = 1},
    {id = "A2", text = "Is the goal well defined? E.g., improve specific situation, product, experience, etc", points = 1},
    {id = "A3", text = "Need included", points = 1},
    {id = "A5", text = "Good submission (up to you)", points = 2},
    {id = "#15", text = "----- ----- ----- ----- Specific Feedback ----- ----- ----- ----- ----- ", points = 0},
    {id = "A8", text = "Not really an overarching question (e.g. they've submitted several sentences and questions, but not one clear overarching question", points = 0},
  */

  /* User */
  if (A1 && A2 === false) {
    ret.push(`Well done, you have made it very clear who your target user is.
As we are attempting to design human-centric products and services, it is very important to always make it clear who your user is so that you can focus on their needs and frustrations. Good job doing that!`);
  }

  if (Z0 && Z1 && A1 === false) {
    ret.push(`You did not state very clearly who your target user is.
As we are attempting to design a human-centric product or service, it is very important to always make it clear who your user is so that you can focus on their needs and frustrations.
At this stage of the process, we are trying to identify these user groups and their underlying needs. So we highly advise you to focus on your target user in your problem statement.`);
  }

  /* Objective */
  if (A2 && A1 === false) {
    ret.push(`Well done, you have made it very clear what your objective is.
However, You did not state very clearly who your target user is.
As we are attempting to design a human-centric product or service, it is very important to always make it clear who your user is so that you can focus on their needs and frustrations.
At this stage of the process, we are trying to identify these user groups and their underlying needs. So we highly advise you to focus on your target user in your problem statement.`);
  }

  if (A2 && A1) {
    ret.push(`Well done, you have made it very clear who your target user is, as well as stating very explicitly what your objective regarding them is.
As we are attempting to design human-centric products and services, it is very important to always make it clear who your user is so that you can focus on their needs and frustrations. Good job doing that!
    `);
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `On the other hand, while you have clearly stated your target users, you did not define your objective very clearly. It is important to be specific and clear about what it is that you want to achieve. Next time, you should try to state the goal of your overarching question as straightforward as possible.`
    );
  }

  /* Needs */
  if (A3) {
    ret.push(`Very well done! Your problem statement focuses on a cler need from your user.
Doing so will help you address relevant issues and, possibly, create a lot of value for your users.
This is certainly the most important part of your problem statement and we are glad to see that you have done it well!`);
  }

  if (!Z0 && !Z1 && !Z2 && A3 === false) {
    ret.push(`Your problem statement does not focus on a cler need from your user.
Doing so will help you address relevant issues and, possibly, create a lot of value for your users.
This is certainly the most important part of your problem statement and we are glad to see that you have done it well!`);
  }

  /* specific feedback */
  if (A8) {
    ret.push(
      `Your submission is not really an overarching question. An overarching question should be one question that sums up the goal of your research. It should include the actors or targets, their need or problem and ideally one of your assumptions or insights. Check the M2 Problem Statement method card for more details.`
    );
  }

  return ret;
};
