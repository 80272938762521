import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for your answer.
We appreciate you taking the time to reflect on this and are happy to see the learnings you and your team have made!`);
  }

  return ret;
};
