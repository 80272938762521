import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(`You have explained the problem your users have and how your idea can help solve that in a very simple and clear way. Well done!
More importantly, your solution focuses on a clear pain-point identified during your user research. As mentioned before, this is super relevant as it makes your solution much more desirable than a simple Vitamin.`);
  }

  return ret;
};
