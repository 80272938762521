import { Box } from "theme-ui";
import React from "react";
import { Layout } from "../components/Layout";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import { Container } from "@sparkademy/app-common/elements/Container";
import { useQuery } from "@sparkademy/app-common/utils/useQuery";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { User, UserTokenData } from "@sparkademy/app-common/models/user";
import { setCookie } from "@sparkademy/app-common/utils/cookie";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

enum STATES {
  IDLE = "IDLE",
  MAGIC_LOADING = "MAGIC_LOADING",
  MAGIC_LOADED = "MAGIC_LOADED",
  MAGIC_ERROR = "MAGIC_ERROR",
}

const MAGIC_LINK_KEY = "token";
const NEXT_KEY = "next";
const AUTH_COOKIE = "auth_token";

function authenticate(token: string): User {
  setCookie("auth_token", token, 180);

  try {
    const tokenData: UserTokenData = jwtDecode(token);

    setCookie("auth_token", token, 180);

    const user: User = {
      company: tokenData.company,
      role: tokenData.role,
      username: tokenData.firstName,
      jwt: token,
      exp: tokenData.exp,
      data: tokenData,
    };

    return user;
  } catch (ex) {
    const user: User = {
      company: "Test",
      role: "learner",
      username: "Alan",
      jwt: token,
      exp: new Date().getTime() / 1000 + 864000,
      data: {
        cohort_id: "unit_test",
        company: "",
        email: "",
        exp: new Date().getTime() / 1000 + 864000,
        firstName: "Alan",
        lastName: "",
        role: "learner",
        id: "ea0477b1-e4c8-40d3-9b36-b9d733a33ae5",
      },
    };
    return user;
  }
}

export const Login: React.FC = () => {
  const { currentUser, login, logout } = useSessionContext();
  const currentHistory = useHistory();
  const query = useQuery();
  const hasMagicLinkToken = query.has(MAGIC_LINK_KEY);
  const nextDirection = query.get(NEXT_KEY) || "/grading";
  const [state, setState] = React.useState<STATES>(STATES.IDLE);

  let token: string | undefined;

  function isDev() {
    return process.env.NODE_ENV === "development";
  }

  if (Cookies.get(AUTH_COOKIE)) {
    token = Cookies.get(AUTH_COOKIE)!;
  } else if (query.get(MAGIC_LINK_KEY)) {
    token = query.get(MAGIC_LINK_KEY)!;
  }

  const hasToken = hasMagicLinkToken || !!token;

  React.useEffect(() => {
    if (currentUser) {
      return;
    }
    if (!token) {
      return;
    }
    if (
      state === STATES.MAGIC_LOADING ||
      state === STATES.MAGIC_LOADED ||
      state === STATES.MAGIC_ERROR
    ) {
      return;
    }

    setState(STATES.MAGIC_LOADING);
    try {
      const currentUser = authenticate(token);
      if (currentUser) {
        login(currentUser);
        setState(STATES.MAGIC_LOADED);
      }
    } catch (ex) {
      console.log("ex", ex);
      logout();
    }
  }, [currentHistory, currentUser, hasMagicLinkToken, login, logout, state, token]);

  if (hasToken && currentUser && state === STATES.MAGIC_LOADED) {
    return <Redirect to={nextDirection} />;
  }

  if (currentUser && !hasToken) {
    return <Redirect to={nextDirection} />;
  }

  if (!hasToken) {
    const prod = "https://enablement.sparkademy.com";
    const dev = "http://local.sparkademy.com:8000";
    window.location.href = (isDev() ? dev : prod) + `/login?next=${window.location.href}`;
  }

  if (state === STATES.MAGIC_LOADING) {
    return (
      <Layout sx={{ bg: "new.primary.white" }}>
        <Loader />
      </Layout>
    );
  }

  return (
    <Layout sx={{ bg: "new.primary.white" }}>
      <Container
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 13,
        }}
      >
        <Box
          sx={{
            maxWidth: "90ch",
            textAlign: "center",
          }}
        >
          Wait while you're being redirected...
        </Box>
      </Container>
    </Layout>
  );
};
