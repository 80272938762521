import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Well done! You submitted a good number of strategic conversations for your workshop. `
    );
  }

  if (A2) {
    ret.push(
      `Defining your strategic conversations will help you to reach the desired outcomes one step at a time. The next step to plan the workshop is defining the open-run-close details of each strategic conversation. Keep up with the good work!`
    );
  }

  if (!Z0 && !Z1 && !A1) {
    ret.push(
      `You shared less than the three strategic conversations we asked for. Three is a good number to start a workshop. If you have less, this can have two reasons: You are missing a strategic conversation which is needed to reach your desired outcomes, or you can spit a strategic conversation into more sub-activities. `
    );
  }

  if (!Z0 && !Z1 && !A2) {
    ret.push(
      `To improve the planning of your workshop, go back to your desired outcomes and reflect whether you included all the strategic conversations necessary to reach them. `
    );
  }

  if (A6) {
    ret.push(
      `Great job! You added templates to the strategic conversations you are planning. Templates, no matter how simple they are, make sure the team stays focused on the conversation they need to have. Additionally, make sure that people fill out post it's instead of writing directly on the template. This encourages participants to write things down while still having the option to change everything.`
    );
  }

  if (A7) {
    ret.push(
      `Great job! Templates, no matter how simple they are, make sure the team stays focused on the conversation they need to have.
One last tip: make sure that people fill out post it's instead of writing directly on the template. This encourages participants to write things down while still having the option to change everything.`
    );
  }

  if (A8) {
    ret.push(
      `Templates, no matter how simple they are, make sure the team stays focused on the conversation they need to have. Therefore, try to define even more templates that you will be using during the workshops. As a rule of thumb, every strategic conversation gets at least one template. And it can be as simple as an empty page with a title and goal!`
    );
  }

  if (A9) {
    ret.push(
      `Thank you for sharing this template. Templates, no matter how simple they are, make sure the team stays focused on the conversation they need to have. Therefore, try to define even more template that you will be using during the workshops. As a rule of thumb, every strategic conversation gets at least one template. And it can be as simple as an empty page with a title and goal!`
    );
  }

  return ret;
};
