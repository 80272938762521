import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(`Very well done!
You have uploaded a good user journey focusing on one of your personas.
It is well structured and focuses on all the main pain poingts and experiences of your user.
Doing this will help you and your team visualize very well how users experience your solution and wi help you identify and address relevant points.

Furthermore, while we only asked you to perform a user journey for one of your personas, we highly advse you to create one for each of your personas. This will help you cover all their different neeeds and fristrations and make it more likely that your final solution covers the needs of a higher share of the market.`);
  }

  return ret;
};
