import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for sharing your experience! As you learned in the team dynamics module, the team building and alignment phase is critical for success.
To learn from those experiences and be able to build on the learnings from this sprint in the future, we recommend a detailed reflection of each phase. Which tools and activities did you use to align the team? How effective was each of them, and how could you improve this for the next sprint? And finally, do you know of any additional tools you want to try out next time?`);
  }

  return ret;
};
