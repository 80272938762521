import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
const ret: string[] = [];
const { Z0, Z1, B1, B2, B3, B4, B5, B6, B7, B8, B9, B10, A7, A8, B11, B12, B13, A14, B14, B15, B16 } =
getSelectedOptionsFlags(selectedOpts);
/*
{ id = "B1", text = "Feedback starts with something positive", points = 0.5},
{ id = "#3", text = "----- ----- ----- ----- FOR SAME FEEDBACK AS ABOVE ----- ----- ----- ----- ----- ", points = 0},
{ id = "B2", text = "Feedback is also specific and actionable", points = 2},
{ id = "B3", text = "Feedback is also specific but not actionable", points = 1},
{ id = "B4", text = "Feedback is also not specific nor actionable", points = 0},
{ id = "#4", text = "-----  ----- ----- ----- FOR DIFFERENT FEEDBACK ----- ----- ----- ----- ", points = 0},
{ id = "B5", text = "Unlike above, feedback is now specific and actionable", points = 2},
{ id = "B6", text = "Unlike above, feedback is now specific but not actionable", points = 1},
{ id = "B7", text = "Unlike above, feedback is now not specific nor actionable", points = 0},
*/

//For repeated Feedback

if (B10 && !B15 && !B16) {
ret.push(
`Again, it is hard to tell how well you shared this constructive feedback as the language you use can have a big impact.`
);
}

if (B2 && B8 === false && B9 === false && !B10 && !B15 && !B16) {
ret.push(
`Thank you for sharing your second feedback.
This feedback, just like the one before, is specific and actionable. Good job!
Now, you should make sure to keep giving feedback like that in the future!`
);
}

if (B3 && B8 === false && B9 === false && !B10 && !B15 && !B16) {
ret.push(
`Just like in your answer above, while this feedback is specific it is not very actionable.
In the future you should try to help your colleague by suggesting ways to improve.`
);
}

if (B4 && B8 === false && B9 === false && !B10 && !B15 && !B16) {
ret.push(
`Just like in your answer above, this feedback is not very specific nor actionable.
In the future, you should try to pin-point better what your colleague could improve and ideally even give a suggestion on how to do so.`
);
}

if (B13 && B8 === false && B9 === false && !B10 && !B15 && !B16) {
ret.push(
`Thank you for sharing your second piece of feedback.
However, like in your previous answer, we believe that your language may have been too direct.`
);
}

//For different Feedback

if (B5 && B8 === false && B9 === false && !B10 && !B15 && !B16) {
ret.push(
`Good job! Unlike your answer above, this time your feedback was more specific and actionable.
This will not only help your colleague understand what he/her did well and what he can improve, but it will also help him/her act on that.`
);
}

if (B6 && B8 === false && B9 === false && !B10 && !B15 && !B16) {
ret.push(
`Your feedback is specific but not really actionable.
In the future you should try to help your colleague by suggesting ways to improve.`
);
}

if (B7 && B8 === false && B9 === false && !B10 && !B15 && !B16) {
ret.push(
`Unlike your answer above, this feedback is not very specific nor really actionable.
In the future, you should try to pin-point better what your colleague could improve and ideally even give a suggestion on how to do so.`
);
}

//For both

if (
!Z0 &&
!Z1 &&
B1 === false &&
B8 === false &&
B9 === false &&
!B10 &&
!B2 &&
!B3 &&
!B4 &&
!B5 &&
!B6 &&
!B7
 && !B15 && !B16) {
ret.push(
`Lastly, next time you give feedback you should try to start your feedback by mentioning something the other person did well. This will make it easier to transition to something they could improve.
Most importantly, it will also make it more likely that the person listens to your feedback.`
);
}

if (B8 && !B10 && !B15 && !B16) {
ret.push(
`Your feedback is not really constructive.
While positive feedback is certainly valid and important (think of the 7:1 rule), we would have liked to see you share constructive feedback so that we can assess it.
In general, constructive feedback should be respectful, specific and actionable.`
);
}

if (B12 && !B10 && !A8 && !B15 && !B16) {
ret.push(`What you did especially well was to use "us" instead of "you vs. I" ("To ease your work load, could we perhaps...").
This highlights that you understand their position and are willing to work together to improve the situation.
Demonstrating empathy like this will ultimately strengthen your relationship and improve communication.
Well done!`);
}

if (B14 && !A14 && !B15 && !B16) {
    ret.push(`It seems to us that the first part of your feedback (what the the person did well) does not relate to what you said next.
If instead you had shared something related to what you believe they could do differently, it might make the transition even easier.`);
    }

if (B9 && !B10 && !B15 && !B16) {
ret.push(
`Thank you for sharing your final answer.
However, while the comment above is not really an example of constructive feedback.
Instead, it is merely suggesting your colleague to do something that you believe would help them.
Constructive feedback should address a behavior or deliverable connected to your colleague that you believe should be improved. In the comment above it seems like you are not doing that.

However, for the purpose of this assignment this is okay.`
);
}

if (B11 && !A7 && !B15 && !B16) {
ret.push(`One thing we would like to point out is that the language you use is very direct. Depending on your relationship with your colleague and your regional culture, this may not be a problem. But as a rule of thumb, it is a good idea to use "softer" language when sharing critical feedback.
One good approach is to use "we/us" instead of "you vs. me". This helps you focus on the problem and not on the individuals and helps you show that you are on this together.`);
}

if (B15) {
    ret.push(`Thank you for sharing your second piece of feedback.
However, once more it is only praising your colleague for good work and does not classify as constructive feedback.
This makes it impossible for us to evaluate how well you can give constructive feedback.`);
}

if (B16) {
    ret.push(`Thank you for sharing your second piece of feedback.
You did a great job highlighting your colleague's strengths and the positive impact they have had on the team. It's always important to acknowledge and appreciate the good work of our colleagues.
However, we were hoping to see how you would share constructive feedback with your colleagues instead of only a positive feedback.
Being able to share constructive feedback in a way that strengthens your relationship with your colleague is hard. This is why we were hoping to see how you do it.

In any case, here are some tips you can use in the future:
- Be specific, actionable and polite.
- Focus on the problem and not on the person. Do your best to separate the issue you would like to address from the person you are giving feedback to.
- Use a conciliatory language. Rather than saying "You did... and I think...", try to frame the situation using us/we. For example: "I've noticed that this happened, what if we... Do you think this could work?".`);
}

return ret;
};
