import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, E1, E2, E3 } = getSelectedOptionsFlags(selectedOpts);

  if (E1) {
    ret.push(
      `Thank you for you submission. You will find the feedback for your submission in part a).`
    );
  }

  return ret;
};
