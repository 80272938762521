import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `You have stated a course of action you and your team can take to address what you learned in this business model health check. A health check is a starting point to taking action to improve your business, and these ideas are an important first step.`
    );
  }

  if (A2) {
    ret.push(
      `You have explained and justified each of the actions you suggested based on what you learned in this business model health check. It's important to ensure your suggestions are suitable to address the areas for improvement you identified, so you can now move forward with the best course of action.`
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `You have failed to explain and justify each of the actions you suggested based on what you learned in this business model health check. It's important to ensure your suggestions are suitable to address the areas for improvement you identified, so you can move forward with the best course of action.`
    );
  }

  if (A3) {
    ret.push(
      `The actions you identified are relevant to the inconsistencies you identified and the maturity of the industry you analyzed. You have demonstrated an understanding of where your areas for growth are and how you can move forward from this assignment and test and implement effective changes to your business model.`
    );
  }

  if (A4) {
    ret.push(
      `The actions you identified are somewhat relevant to the inconsistencies you identified and the maturity of the industry you analyzed. Return to your answers to the previous questions and consider actions that relate to specific issues and strategies you identified. It's important to understand where your areas for growth are and how you can move forward from this assignment and test and implement effective changes to your business model.`
    );
  }

  if (A5) {
    ret.push(
      `The actions you identified are not relevant to the inconsistencies you identified and the maturity of the industry you analyzed. Return to your answers to the previous questions and consider actions that relate to specific issues and strategies you identified. It's important to understand where your areas for growth are and how you can move forward from this assignment and test and implement effective changes to your business model.`
    );
  }

  return ret;
};
