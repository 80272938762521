import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A2) {
    ret.push(
      `Thanks for stating and explaining your most relevant risky assumption and the associated risk in relation to your project. This selection is the first step of setting up a risk handling strategy. Furthermore, your description in relation to the project helps us to evaluate your answers in the following exercises.`
    );
  }

  if (A3 && !A2 && !A4) {
    ret.push(
      `Thanks for stating and explaining your most relevant risky assumption in relation to your project. However, the explanation of this risk in relation to your project could have been more detailed. We recommend taking the time to further consider the impact this risk could have on the activities and outcomes of your project. By thinking specifically about the potential impact of the risk, you can begin to develop a more effective risk-handling strategy.`
    );
  }

  if (A4&& !A2 && !A3) {
    ret.push(
      `Thanks for stating and explaining your most relevant risky assumption in relation to your project. This selection is the first step of setting up a risk handling strategy. Unfortunately, you lack a clear explanation of the risk associate with it how it relates to the project. This would have helped us to better understand and evaluate your answers in the following exercises.`
    );
  }
  
  /*
    OLD CRITERIA
    {id = "A1", text = "Risk stated and explained in relation to the project very well", points = 3},
    {id = "A2", text = "Risk stated and explained in relation to the project well", points = 2.5},
    {id = "A3", text = "Risk stated and explained in relation to the project acceptably", points = 2},
    {id = "A4", text = "Risk stated and explained in relation to the project poorly", points = 1},
    {id = "A5", text = "Risk stated but not explained", points = 0.5},
 

  if (A1) {
    ret.push(
      `Thanks for stating and explaining your most relevant risk in relation to your project. Identifying and evaluating your key risks is ne of the first steps when setting up an effective risk handling strategy.
Furthermore, your description in relation to the project helps us to evaluate your answers in the following exercises.`
    );
  }

  if (A2) {
    ret.push(
      `Thanks for stating and explaining your most relevant risk in relation to your project. Identifying and evaluating your key risks is ne of the first steps when setting up an effective risk handling strategy.
Furthermore, your description in relation to the project helps us to evaluate your answers in the following exercises.`
    );
  }

  if (A3) {
    ret.push(
      `Thanks for stating and explaining your most relevant risk in relation to your project.
However, the explanation of this risk in relation to your project could have been more detailed.
Identifying and evaluating your key risks is ne of the first steps when setting up an effective risk handling strategy.`
    );
  }

  if (A4) {
    ret.push(
      `Thanks for stating and explaining your most relevant risk in relation to your project.
However, the explanation of this risk in relation to your project should have been more detailed.
Identifying and evaluating your key risks is ne of the first steps when setting up an effective risk handling strategy.`
    );
  }

  if (A5) {
    ret.push(
      `Thanks for stating and explaining your most relevant risk in relation to your project. This selection is the first step of setting up a risk handling strategy. Unfortunately, you lack a clear explanation how it relates to the project. This would have helped us to better understand and evaluate your answers in the following exercises.`
    );
  }

   */

  return ret;
};
