import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Exceptional submission! We really appreciate the time you took to answer all the questions and are very happy to see how you reflected on and used your metrics to evaluate your assumptions. Very well done!`
    );
  }

  return ret;
};
