import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Excellent job! You chose a relevant pattern and clearly justified why it fits your business well and connected it to your value proposition.`
    );
  }

  if (Z2) {
    ret.push(
      `Excellent job! The pattern you chose connects well to your value proposition, and you thoughtfully articulated why it fits your business.`
    );
  }

  return ret;
};
