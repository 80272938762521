import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { D1, Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    /* selectedOpts.includes("A1.1") */
    ret.push(
      "No internal stakeholders submitted. However, we hope that you at least took some time to think about all the relevant stakeholders and what interest and influence they have on your project. It is especially important to consider the internal stakeholders, as they are closely related to your project and should therefore have a stronger influence on it. Doing so will help you prioritize their desires and needs and facilitate a smooth development for your project."
    );
  }

  if (A0) {
    ret.push(`You have identified only one internal stakeholder.
Internal stakeholders are very relevant to your project as they are often directly affected by your project and can also have a huge impact on how your project develops!.
Identifying all key stakeholders early on can help you take their needs into perspective and avoid possible roadblocks in the future.
Therefore, we advise you to take even more time to think of more internal stakeholders before proceeding.`);
  }

  if (A2) {
    /* selectedOpts.includes("A1.2") */
    ret.push(
      `You have included a decent number of internal stakeholders. However, we advise you to take even more time to think of more of them.
It is especially important to consider the internal stakeholders, as they are closely related to your project and should therefore have a stronger influence on it.
Doing so will help you prioritize their desires and needs and facilitate a smooth development for your project.`
    );
  }

  if (A3) {
    /* selectedOpts.includes("A3") */
    ret.push(
      "Well done! You have included a good number of internal stakeholders. It is especially important to consider the internal stakeholders, as they are closely related to your project and should therefore have a stronger influence on it. Doing so will help you prioritize their desires and needs and facilitate a smooth development for your project."
    );
  }

  if (A4 && !A5 && !A0) {
    ret.push(
      "While you did include a level of power to your internal stakeholders, you did not include a level of interest. It is important to think about the level of interest since highly interested stakeholders should be notified of the decisions and progress made. Of course, the degree of inclusion depends on the level of power as well. This is especially important for internal stakeholders as they are closer to the project and thus often more relevant."
    );
  }

  if (!A4 && A5 && !A0) {
    ret.push(
      "While you did include a level of interest to your internal stakeholders, you did not include a level of power. It is important to think about the level of power, since highly influential stakeholders can affect the outcome of your project greatly. Therefore, they should be included in the decisions and have at least some of their interests represented. Of course, the degree of inclusion depends on the level of interest as well. This is especially important for internal stakeholders as they are closer to the project and thus often more relevant."
    );
  }

  if (A3 && A4 && A5 && !A0) {
    ret.push(
      "Furthermore, all of your internal stakeholders seem to be relevant and include both a level of power as well as a level of interest. Identifying several relevant stakeholders is important, as it will allow you to pay attention to their needs and interests. "
    );
  }

  if (A3 === false && A4 && A5 && !A0) {
    ret.push(
      "On the other hand, all of your internal stakeholders seem to be relevant and include both a level of power as well as a level of interest. Identifying several relevant stakeholders is important, as it will allow you to pay attention to their needs and interests."
    );
  }

  if (!Z0 && !Z1 && !Z2 && !A4 && !A5 && !A0) {
    ret.push(
      `None of your internal stakeholders include a level of interest and power.
Thinking about these levels is especially important for internal stakeholders as these are often closely connected to your projects and are often more interested and influential. Understanding their individual levels of interest and power will help you assess how closely you should be working with them and what specific interests to take into account.
As a general rule of thumb, very influential stakeholders should be closely involved in your challenge (ask them for feedback, include them in brainstorms, and so on). Very interested stakeholders should be included in the loop (notify them of developments, milestones, and challenges).`
    );
  }

  if (!Z0 && !Z1 && !Z2 && !A7 && !A0) {
    ret.push(`Are you sure that all of the internal stakeholders you mentioned are really internal stakeholders? It seems to us that some of them might be connected or even external stakeholders.
All in all this is not so relevant, since, as long as you mention all of your key stakeholders and take into accounts their interests and levels of influence it does not really matter how you categorize them.
However, it is important to be able to distinguish these different groups as it will help you prioritize their needs. Therefore, we highly advise you to take a better look at our course information and the method cards so that this concept is very clear to you and your team. `);
  }

  return ret;
};
