import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Great work! You submitted a good number of strategic conversations for your workshop. Defining your strategic conversations will help you to reach the desired outcomes one step at a time. The next step to plan the workshop is defining the open-run-close details of each strategic conversation. Keep up with the good work!`
    );
  }

  return ret;
};
