import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 || A2) {
    ret.push(
      `Thank you for your reflection.
We appreciate your level of detail and are happy to see that you have  evaluated your experience critically.`
    );
  }

  if (A3) {
    ret.push(
      `Thank you for your reflection.
To make the most of such assignments, we advise you to always think back critically about how they progressed, what you did well and what could be improved.
Taking the time to doing so will help you get consistently better.`
    );
  }

  if (A4 || A5) {
    ret.push(
      `Your reflection was not detailed and critical enough.
To make the most of such assignments, we advise you to always think back critically about how they progressed, what you did well and what could be improved.
Taking the time to doing so will help you get consistently better.`
    );
  }

  return ret;
};
