import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A11, A12 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A3 && A5 && A7) {
    ret.push(
      `Well done.
It seems like your team has discussed this properly and come up with a simple and clear sheet summarising your main issue, your user and how your solution can create value for this user.`
    );
  }

  if (A9 && A3 && A5 && A7) {
    ret.push(`Furthermore, it seems to us that your key feature is a good trigger for the impact your are planning to achieve.
That is exactly what you should aim for at this stage.
Now, the important step will be to design an experiment that will bring your users into a decision point where you can test whether this key feature (e.g., sending a text message to your users) will bring cause your intended impact.`);
  }

  if (A9 && !(A3 && A5 && A7)) {
    ret.push(`Well done. It seems to us that your key feature is a good trigger for the impact your are planning to achieve.
That is exactly what you should aim for at this stage.
Now, the important step will be to design an experiment that will bring your users into a decision point where you can test whether this key feature (e.g., sending a text message to your users) will bring cause your intended impact.`);
  }
  /*
  if (A9 && !(A3 && A5 && A7)) {
    ret.push(`Well done. It seems to us that your key feature is a good trigger for the impact your are planning to achieve.
That is exactly what you should aim for at this stage.
Now, the important step will be to design an experiment that will bring your users into a decision point where you can test whether this key feature (e.g., sending a text message to your users) will bring cause your intended impact.`);
  }
  */

  if (!Z0 && !Z1 && !A9) {
    ret.push(`It seems to us that your key feature is not a good trigger for the impact your are planning to achieve.
We advise you to go over your notes and re-evaluate your key feature and your intended impact.
Later, the important step will be to design an experiment that will bring your users into a decision point where you can test whether this key feature (e.g., sending a text message to your users) will bring cause your intended impact.`);
  }
  /*
  if (!Z0 && !A9) {
    ret.push(`It seems to us that your key feature is not a good trigger for the impact your are planning to achieve.
We advise you to go over your notes and re-evaluate your key feature and your intended impact.
Later, the important step will be to design an experiment that will bring your users into a decision point where you can test whether this key feature (e.g., sending a text message to your users) will bring cause your intended impact.`);
  }
  */

  if (!Z0 && !A1 && A2) {
    ret.push(`You did not clearly state your idea.
Please make sure to do so before regrouping with your team for the next exercise.`);
  }

  if (!Z0 && !A2 && A1) {
    ret.push(`You did not give your solution a name.
Please make sure to do so before regrouping with your team for the next exercise.`);
  }

  /*
  if (!Z0 && !A2 && !A1) {
    ret.push(`You did not clearly state your idea nor did you name your idea.
Please make sure to do so before regrouping with your team for the next exercise.`);
  }
  */

  if (A12) {
    ret.push(`Is this really the final impact you wish to have on your user?
Is that by itself going to add value to your user?
It seems to us that your intended impact is only an intermediary goal.

We advise you to really think of the problem you have identified and how your solution can address that problem directly.`);
  }

  if (A11) {
    ret.push(`Lastly, we would like to point out that your target user is not the same as your persona.
As you have already put so much effort into defining your persona, it is a good idea to make the most out of it and use your persona whenever possible.`);
  }

  return ret;
};
