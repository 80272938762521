import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Thank you for submitting two idea napkins as asked. Both your napkins were appropriately filled out and had some sort of visualization. Well done. Doing this will make it easier for you to explain your idea to others.`
    );
  }

  return ret;
};
