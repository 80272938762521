import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for stating the company you will be using for this exercise!`);
  }

  if (A2) {
    ret.push(`Please include the name of the company you will be using for this exercise.`);
  }

  if (A3) {
    ret.push(
      `You have included a good description of the company's business model. 
It is essential to understand well how both businesses work in order to design a good integration strategy. This was a very good first step. Well done!`
    );
  }

  if (A4) {
    ret.push(
      `You did not describe the company's business model you will be using. Please research the company and provide a description of its business model. This is a necessary step to understanding how two business models can be run side by side.`
    );
  }

  return ret;
};
