import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `It seems like you learned a lot from your research! Thank you for stating the assumptions that were validated or invalidated by the user data you gathered.`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for stating an assumption that was validated or invalidated from your user research. However, we hoped you would have been able to test multiple assumptions with your research. To get more out of your research in the future, make sure your assumptions are relevant and your survey or interviews aim to directly test the assumptions.`
    );
  }

  if (A3) {
    ret.push(
      `Very well done including both quantitative and qualitative findings to support your explanation of whether your assumptions were validated. With these two forms of evidence, you have made your arguments much stronger. This is exactly the aim Qual/Quant research approach.`
    );
  }

  if (A4) {
    ret.push(
      `Good job including evidence from research in your explanation of whether your assumptions were validated. However, remember that combining both quantitative and qualitative findings provides the strongest arguments for your statements. We recommend considering how your qualitative findings are supported by the survey data you collected and vice versa to get the most out of the Qual/Quant approach.`
    );
  }

  if (A5) {
    ret.push(
      `However, you did not reference any evidence from your explanation of whether your assumptions were validated. Remember that combining both quantitative and qualitative findings provides the strongest arguments for your statements. We recommend considering how your qualitative findings are supported by the survey data you collected and vice versa to get the most out of the Qual/Quant approach.`
    );
  }

  if (A6) {
    ret.push(
      `Excellent work! It seems like you learned a lot from your user research. Thank you for stating the assumptions that were validated or invalidated by the user data you gathered. You did especially well to reference the specific qualitative and quantitative data that supports your conclusions. It made your arguments stronger, which is exactly the aim of the Qual/Quant approach.`
    );
  }

  if (A7) {
    ret.push(
      `Thank you for your answer. While it seems you learned a few things from your user research, we believe you could have gotten much more out of it. Next time, make sure to look closely at how both the qualitative and quantitative data you gathered sheds light on your assumptions. Or perhaps, you need to reconsider if your assumption and research methods matched.`
    );
  }

  return ret;
};
