import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Great submission! Your efforts gave you a broad overview of the topic and scope of the challenge you want to tackle. Hopefully, you wrote down everything what is in your head about the challenge. The scoping canvas helps you to do so, and align with your team and leadership.
It is clear and convincing why this challenge is important for the customer and your company. You stated a clear goal in your desired outcomes, and your success metric is measurable. A clear goal is helping you to keep your direction during the sprint, and communicating it with your management aligns the expectations. You also did a good job explaining the customer segment and their current situation. This helps to know who you want to contact during your research, and which needs and challenges you want to verify.
By stating the key assumptions and unknowns, you showcase a critical reflection on the challenge. What do you assume to know, what do you not know? Those are the topics which will become relevant during your sprint!
And finally, your challenge statement, also called problem statement, is stated clearly and includes a user, their need, and the challenge to solve this need. Although this is a guiding element of your research, don't hesitate to reformulate it if your research falsifies your assumptions or detects a more relevant challenge within the topic! `
    );
  }

  return ret;
};
