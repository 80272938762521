import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { B1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (B1) {
    ret.push(` Again we will break down our feedback for your introduction, your questions and your conclusion.
Introduction:
Like before your introduction is well written. It contains all the essential elements you should include and uses a clear and friendly language. We liked how this time you added even more starting questions (also asking about hobbies and favorite foods). This will certainly help make your interviewee more at ease and help you create a better conversation later.
However, this time your topic introduction is slightly less detailed and you also don’t state the expected interview duration (which is okay if you stated that when inviting your interviewee). In general we advise you to spend a little more time on the topic introduction. Doing so will give your interviewee better context and help him answer your questions.
Main questions:
We really liked you how prepared different questions for different types of users. This is a great approach and should be kept for your future interview guidelines.
Also, you once more did a great job structuring your questions and keeping a clear red line that makes it easer to follow your logic. Your questions are all very well stated and you are constantly asking why trying to understand why they do what they do. That is exactly what you should try to discover with your questions. Great job!
Conclusion:
You asked for final questions and thanked your participant just as you should.
However, your conclusion would be even better if you would add a little sentence asking your participant if he/she would be willing to participate down the line. Maybe by testing your prototype or just to answer some follow-up questions.
Doing this now takes very little time and will help you in the future.
Final comments from Sparkademy:
Overall your guideline is really good. You have used a clear and friendly language and have prepared many questions that help you truly understand your user. Very well done!`);
  }

  return ret;
};
