import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `You have stated several possible new directions you could take your business model in based on the epicenter you chose. These ideas that come from reframing your business model can act as valuable inspiration for business model reconfiguration and innovation. Having multiple directions can help you chose the most appropriate one for your goals at a given time in your business model innovation process.`
    );
  }

  if (A2) {
    ret.push(
      `It would be best if you tried to identify more alternatives to your business model based on the epicenter you have selected.
Therefore, we advise you to, together with your team, brainstorm for more ideas on new directions you could follow. You can use online sources like the business model navigator for inspiration. These ideas that come from reframing your business model can act as valuable inspiration for business model reconfiguration and innovation. Having multiple directions can also help you chose the most appropriate one for your goals at a given time in your business model innovation process.`
    );
  }

  if (A1 && A3 && A2 === false) {
    ret.push(
      `Furthermore, the changes in direction you suggested are thoughtful and creative. Your ideas have demonstrated a breadth of thinking that is instrumental in working towards business model innovation. It's important to consider out-of-the-box ideas for new business directions because that is where innovation starts!`
    );
  }

  if (A2 && A3 && A1 === false) {
    ret.push(
      `However, the change in direction you suggested is thoughtful and creative. Your idea demonstrates a depth of knowledge and breadth of thinking that is instrumental in working towards business model innovation. It's important to consider out-of-the-box ideas for new business directions because that is where innovation starts!`
    );
  }

  if (A4 && A1) {
    ret.push(
      `The new directions you suggested do not significantly change to your current business model. This is not necessarily a bad thing. However, since the whole goal of this program is to foster innovation, we would recommend you to try to look for more significant changes.
In your brainstorm, you should remain open to out-of-the-box ideas for new business directions because that is where innovation starts.`
    );
  }

  if (A4 && A2) {
    ret.push(
      `The new direction you suggested does not significantly change to your current business model. In your brainstorm, you should remain open to out-of-the-box ideas for new business directions because that is where innovation starts.`
    );
  }

  /* Needs to be turned in a positive text

  if (A5) {
    ret.push(
      `However, in the future, you should try to be more objective in your answers. What additional services are you planning to add? How will you adjust the ky activities to match the changes?
Try to give more concrete next steps. Thinking about this now might have very large effects down the road.`,
    )
  }
  */

  if (!Z0 && !Z1 && A5 === false) {
    ret.push(
      `However, in the future, you should try to be more objective in your answers. What additional services are you planning to add? How will you adjust the key activities to match the changes?
Try to give more concrete next steps. Thinking about this now might have very large effects down the road.`
    );
  }

  return ret;
};
