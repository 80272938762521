import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3 } = getSelectedOptionsFlags(selectedOpts);
  /*
    {id = "Z0", text = "No submission", points = 0},
    {id = "Z1", text = "Poor description given", points = 0},
    {id = "Z2", text = "Godd description submitted", points = 0},
    {id = "Z3", text = "Very good submission given", points = 0},
*/
  if (Z0) {
    ret.push(`No submission.`);
  }

  if (Z1) {
    ret.push(`Thank you for stating who you will be working with!`);
  }

  return ret;
};
