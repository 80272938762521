import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { B1, B2, B3, B4, B5, B6 } = getSelectedOptionsFlags(selectedOpts);
  /*
    {id = "#3", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ", points = 0},
    {id = "B0", text = "No comment submitted", points = 0},
    {id = "B1", text = "Comment submitted and file uploaded above", points = 0},
    {id = "B2", text = "Comment submitted and no file uploaded above", points = 0},
    {id = "#5", text = "----- ----- ----- ----- Pick all that apply ----- ----- ----- ----- ", points = 0},
    {id = "B3", text = "Assumption is good", points = 0.25},
    {id = "B4", text = "Test is good", points = 0.25},
    {id = "B5", text = "Metrics are good", points = 0.25},
    {id = "B6", text = "Criteria is good", points = 0.25},
  */
  if (B1 && B3 && B4 && B5 && B6) {
    ret.push(`Thank you for stating your hypothesis here as well.
It is well framed and includes a relevant assumption, a simple but effective test and a good choice of success criteria.
Well done!`);
  }

  if (B2 && B3 && B4 && B5 && B6) {
    ret.push(`Thank you for submitting your selected hypothesis.
It is well framed and includes a relevant assumption, a simple but effective test and a good choice of success criteria.
Well done!`);
  }

  if (B1 && !(B3 && B4 && B5 && B6)) {
    ret.push(`Thank you for stating your hypothesis here as well.
However, we would like bring attention to the points below:`);
  }

  if (B2 && !(B3 && B4 && B5 && B6)) {
    ret.push(`Thank you for submitting your selected hypothesis.
However, we would like bring attention to the points below:`);
  }

  if ((B1 || B2) && !B3) {
    ret.push(`We believe that your assumption could have been stated better.
Try to answer why (or why not) your user would like or use this product.
Focusing on the why might lead to a more relevant assumption`);
  }

  if ((B1 || B2) && !B4) {
    ret.push(`Your test might not be well suited to test your assumption.
Please make sure to improve your test so that it helps you validate your key assumption.
Your test should be as simple as possible, but still allow your participants to interact with it.`);
  }

  if ((B1 || B2) && !B5) {
    ret.push(`Are you sure that these are the best metrics to track during your test?
Please keep in mind that good criteria should be quantifiable, easy to measure and in line with your prototype and test.`);
  }

  if ((B1 || B2) && !B6) {
    ret.push(`Your success criteria could be improved.
A good criteria should be clear and unique (not several different metrics).
Furthermore, it should allow you and your team to compare different performances and easily (in)validate your assumption.`);
  }

  return ret;
};
