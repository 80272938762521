import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A5 && (A1 || A2)) {
    ret.push(
      `Well done! You have submitted a good number of protocols and more importantly, they are all detailed and clear. Doing so will help you access this data in the future with ease and save you and your team time, as well as ensuring that no relevant information is lost.`
    );
  }

  if (A6 && (A1 || A2)) {
    ret.push(`Good job. You have submitted a good number of protocols and, overall, they are quite detailed and clear.
Having very clear and detailed protocols or reports will help you access this data in the future with ease and save you and your team time, as well as ensuring that no relevant information is lost.
This is why, we advise you to put a little more time into reporting your interviews in the future. It can help a lot in case you decide later on to change the direction of your idea entirely.`);
  }

  if (!A5 && !A6 && (A1 || A2)) {
    ret.push(`While you have submitted a good number of protocols, they are not very detailed or clear.
Having very clear and detailed protocols or reports will help you access this data in the future with ease and save you and your team time, as well as ensuring that no relevant information is lost.
This is why, we advise you to put a little more time into reporting your interviews in the future. It can help a lot in case you decide later on to change the direction of your idea entirely.`);
  }

  if (A5 && (A3 || A4)) {
    ret.push(`While your reports are all detailed and clear, you did not submit enough of them for us to give you effective feedback.
In general, having very clear and detailed protocols or reports will help you access this data in the future with ease and save you and your team time, as well as ensuring that no relevant information is lost.
Having all of your interviews stored like this can help a lot in case you decide later on to change the direction of your idea entirely.`);
  }

  if (A6 && (A3 || A4)) {
    ret.push(`You did not submit several reports as we would have liked, but it seems like your reports are not detailed enough.
Having very clear and detailed protocols or reports will help you access this data in the future with ease and save you and your team time, as well as ensuring that no relevant information is lost.
This is why, we advise you to put a little more time into reporting your interviews in the future. It can help a lot in case you decide later on to change the direction of your idea entirely.`);
  }

  if ((!A5 || !A6) && (A3 || A4)) {
    ret.push(`You did not submit several reports as we would have liked, but it seems like your reports are not detailed enough.
Having very clear and detailed protocols or reports will help you access this data in the future with ease and save you and your team time, as well as ensuring that no relevant information is lost.
This is why, we advise you to put a little more time into reporting your interviews in the future. It can help a lot in case you decide later on to change the direction of your idea entirely.`);
  }

  return ret;
};
