import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && !A3 && !A7) {
    ret.push(`Thank you for sharing this file.
The mapping of your ideas in your impact-effort matrix is clearly visible and seems to make sense and be well-thought.`);
  }

  if (A3 && !A1 && !A7) {
    ret.push(
      `Thank you for sharing this file.
Your dot-voting process is very clear shows how your team has tackled this problem correctly.`
    );
  }

  if (A3 && A1 && !A7) {
    ret.push(
      `Thank you for sharing your impact-effort-matrix and how your team mapped your ideas in it.
Your mapping makes sense and seems to be well-thought. Also your dot-voting process is very clear shows how your team has tackled this problem correctly.
Good job!`
    );
  }

  if (!Z0 && !Z1 && A1 === false && !A7) {
    ret.push(
      `However, we cannot see your ideas mapped along an impact-effort matrix as asked.
Mapping your ideas will help you identify and select the most promising ideas your ideation session came up with.`
    );
  }

  if (!Z0 && !Z1 && A2 === false && A1 && !A7) {
    ret.push(
      `You did not label your impact-effort matrix clearly.
This may seem unimportant, but it is important to record all your data and how it was analyzed so that your decision will be easy to follow in the future. Any matrix without labels will be useless in the future if you forget what your evaluation criteria were.`
    );
  }

  if (!Z0 && !Z1 && A3 === false && !A7) {
    ret.push(
      `However, the image you provided us with does not show the dot-voting process very clearly.
We would have liked to see all your ideas mapped on your 2x2 impact-effort matrix and then be able to see which dots (and how many) each idea received.`
    );
  }

  if (A7) {
    ret.push(
      `Thank you for sharing this file.
However, it seems to us that you did not map your ideas along an impact effort matrix as asked.

We recommended you to do so because this simple process is a very efficient way to not only identify and select your most promising ideas, but it also allows you to store your results for future use.
This is important because, after testing and validating one of your ideas in M3, you may realize that your first selected idea is not really desired by your users. 
Keeping this matrix will be important in that case since it will allow you to look back at your findings and select another idea to experiment without having to spend to much time re-evaluating all of your ideas.
You can then also use your newly acquired insights to quickly update this matrix.

Therefore, we strongly advise you to map all of your ideas along an impact.effort-matrix before continuing.
(There is no need to submit it, but it is a good practice and will help you in the next steps of your project.)`
    );
  }

  if (!Z0 && !Z1 && !A7) {
    ret.push(
      `This simple process is a very efficient way to not only identify and select your most promising ideas, but it also allows you to store your results for future use.
This is important because, after testing and validating one of your ideas in M3, you may realize that your first selected idea is not really desired by your users. 
Keeping this matrix will be important in that case since it will allow you to look back at your findings and select another idea to experiment without having to spend to much time re-evaluating all of your ideas.
You can then also use your newly acquired insights to update this matrix.`
    );
  }

  return ret;
};
