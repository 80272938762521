import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && !A7) {
    ret.push(
      `You have defined only one type of dot.
We highly recommend you define more types of dots so that your ideas can be assessed along several dimensions. This will help you ensure that promising ideas that may lack in this one dimension you chose can still be considered due to its other qualities. This is super important to foster innovative solutions.`
    );
  }

  if (A2 && !A7) {
    ret.push(
      `Good job defining more than only one type of dot.
This will help you and your team assess your ideas along several dimensions instead of just one. This is important because, otherwise, you may discard ideas now, just because they failed this one criterion you used. `
    );
  }

  if (A3 && !A7) {
    ret.push(
      `Good job defining more than only one type of dot.
This will help you and your team assess your ideas along several dimensions instead of just one. This is important because, otherwise, you may discard ideas now, just because they failed this one criterion you used. `
    );
  }

  if ((A2 || A3) && A4 && !A7) {
    ret.push(
      `Furthermore, thank you for stating clearly what each type of dot signifies.
This helps us evaluate your work later and shows that you have thought about this important detail.`
    );
  }

  if (A1 && A4 && !A7) {
    ret.push(
      `On the other hand, thank you for stating clearly what each type of dot signifies.
This helps us evaluate your work later and shows that you have thought about this important detail.`
    );
  }

  if ((A2 || A3) && A5 && !A7) {
    ret.push(`On the other hand, you did not make it very clear what each type of dot stands for.
Please make sure to write this down clearly as it will help your participants distribute their votes more reliably and with less effort.`);
  }

  if (A1 && A5 && !A7) {
    ret.push(`Furthermore , you did not make it very clear what each type of dot stands for.
Please make sure to write this down clearly as it will help your participants distribute their votes more reliably and with less effort.`);
  }

  if (A6 && !A7) {
    ret.push(`However, while you did define different types of criteria for your dots, you did not explain how you will differentiate each type of dot (e.g. through color or shape).
This is not wrong, as you did the most important part.
However, we advise you to always make it very clear beforehand how you will visually differentiate these dots. For in-person workshops this might help you remember to bring the necessary materials.`);
  }

  if (A7) {
    ret.push(`Thank you for your answer.

We noticed how you are using colors to differentiate between your participants, but not between different type of criteria.
Our advise to you would be to come up with different types of dots as well (e.g. most promising idea, most valuable to user, most innovative and so on).
Doing this will help you keep more types of answers in the loop.`);
  }

  return ret;
};
