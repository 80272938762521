import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B1, B2, B3, B4, B5, B6, B7, B8, B11, B12, B13, B14, B15, B16, B17 } =
    getSelectedOptionsFlags(selectedOpts);

  if (B3 && !B12 && !B13 && !B14 && !B15 && !B16 && !B17) {
    ret.push(`Thank you for stating the implications you expect this idea to have on your persona.
We are happy to see that you are using your persona instead of just citing a general type of users.
You have put a lot of work into creating these personas. Now it is important to make the most of them.
Well done!`);
  }
  /*
  if (B4) {
    ret.push(`Thank you for stating the implications you expect this idea to have on these two personas.
Still, you should have stated how this idea would affect at least three of the personas you have stated.
In the future please make sure to evaluate how the ideas you keep might influence all of your personas (and ideally key stakeholders as well). `);
  }

  if (B5) {
    ret.push(`Thank you for stating the implications you expect this idea to have on your personas.
Evaluating how the ideas you keep might influence all of your personas (and ideally key stakeholders as well) will help you realize if this idea is really worth pursuing and might show you some issues that will need to be addressed. `);
  }
*/
  if (B1 && !B12 && !B13 && !B14 && !B15 && !B16 && !B17) {
    ret.push(`You stated how this idea would affect a general type of user, instead of using the specific persona you have created earlier.
We strongly advise you to use your personas in the future as they represent different customer segments, their behaviors and needs. Therefore, using them will help you evaluate this idea on a broader range of behaviour and needs (compared to a general user that is less detailed).`);
  }
  /*
  if (B2) {
    ret.push(`While you did state how this idea would affect different types of users, you did not use the specific personas you have created earlier.
We strongly advise you to use your personas in the future as they represent different customer segments, their behaviors and needs. Therefore, using them will help you evaluate this idea on a broader range of behaviour and needs (compared to a general user that is less detailed).
In the future please make sure to evaluate how the ideas you keep might influence all of your personas (and ideally key stakeholders as well).`);
  }
*/
  if (B6 && B3 && !B12 && !B13 && !B14 && !B15 && !B16 && !B17) {
    ret.push(`However, your implications are not detailed enough.
It is important to think carefully about how this idea might affect the experience of each of your personas. Furthermore, you should focus on the emotions your user might experience and if this solution addresses the underlying needs identified in your user research.
Doing this will allow you to evaluate your ideas better and increase the chance that this idea adds real value to your users.`);
  }

  if (B6 && B1 && !B12 && !B13 && !B14 && !B15 && !B16 && !B17) {
    ret.push(`Furthermore, your you could have described your implications with more detail.
It is important to think carefully about how this idea might affect the experience of each of your personas. Furthermore, you should focus on the emotions your user might experience and if this solution addresses the underlying needs identified in your user research.
Doing this will allow you to evaluate your ideas better and increase the chance that this idea adds real value to your users.`);
  }

  if (B7 && B3 && !B12 && !B13 && !B14 && !B15 && !B16 && !B17) {
    ret.push(`However, while your implications are quite detailed, you did not mention how this solution would make your Persona feel and what emotions they might experience.
When working in user-centric projects, it is important to think carefully about how your solutions might affect the experience of your users. Furthermore, you should focus on whether this solution addresses the underlying needs identified in your user research.
Doing this will allow you to evaluate your ideas better and increase the chance that this idea adds real value to your users.`);
  }

  if (B7 && B1 && !B12 && !B13 && !B14 && !B15 && !B16 && !B17) {
    ret.push(`Furthermore, while our implications are quite detailed, you did not mention how this solution would make your Persona feel and what emotions they might experience.
When working in user-centric projects, it is important to think carefully about how your solutions might affect the experience of your users. Furthermore, you should focus on whether this solution addresses the underlying needs identified in your user research.
Doing this will allow you to evaluate your ideas better and increase the chance that this idea adds real value to your users.`);
  }

  if (B8 && B3 && !B12 && !B13 && !B14 && !B15 && !B16 && !B17) {
    ret.push(`More importantly, when talking about the implications this idea could have on your persona, you focused on their feelings and experience instead of just the obvious consequences.
This is the correct approach and doing so will allow you to evaluate your ideas better and increase the chance that this idea adds real value to your users.
Well done!`);
  }

  if (B8 && B1 && !B12 && !B13 && !B14 && !B15 && !B16 && !B17) {
    ret.push(`However, what you did well (and what is more important) is that, when talking about the implications this idea could have on your persona, you focused on their feelings and experience instead of just the obvious consequences.
This is the correct approach and doing so will allow you to evaluate your ideas better and increase the chance that this idea adds real value to your users.
Well done!`);
  }

  if (B11 && B3 && !B12 && !B13 && !B14 && !B15 && !B16 && !B17) {
    ret.push(`Are you sure that the implications you have stated are a direct result of implementing this idea?
Based on what we read in your submission, this relationship is not clear.
Please reflect if this idea will really have the implications stated above.
In the future, please make sure to explain why a certain relationship is true. This will allow us to give you more effective feedback.`);
  }

  if (B11 && B1 && !B12 && !B13 && !B14 && !B15 && !B16) {
    ret.push(`Are you sure that the implications you have stated are a direct result of implementing this idea?
Based on what we read in your submission, this relationship is not clear.
Please reflect if this idea will really have the implications stated above.
In the future, please make sure to explain why a certain relationship is true. This will allow us to give you more effective feedback.`);
  }

  /*
    {id = "#11", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "B1", text= "Very general implications for one user (not their persona but a general user)", points= 0.5 },
    #{ id= "B2", text= "Very general implications for more than one user (not their persona but a general user)", points= 0.5 },
    { id= "B3", text= "Implications for their persona", points= 1 },
    #{ id= "B4", text= "Implication for 2 personas", points= 1 },
    #{ id= "B5", text= "Implication for 3+ personas", points= 1.5 },
    {id = "#12", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "B6", text= "Implications not detailed", points= 0 },
    { id= "B7", text= "Implications somewhat detailed", points= 1 },
    { id= "B8", text= "Implications very detailed", points= 2 },
    {id = "#13", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "B11", text= "Implication does not make sense", points= 0 },
    {id = "#28", text = "----- ----- ----- ----- For SAME feedback as 3.4.a) PICK ONE ----- ----- ----- ----- ----- ", points = 0},
    { id= "B12", text= "Persona included and very detailed - like in point a)", points= 3 },
    { id= "B17", text= "Persona included and quite detailed - like in point a)", points= 2 },
    { id= "B13", text= "Persona included and not detailed - like in point a)", points= 1 },
    { id= "B14", text= "Persona missing but detailed like in point a)", points= 1.5},
    { id= "B15", text= "Persona missing and not detailed like in point a)", points= 0.5},
    {id = "#29", text = "----- ----- ----- ----- For SIMILAR feedback as 3.4.a) PICK ONE ----- ----- ----- ----- ----- ", points = 0},
    { id= "B16", text= "Persona included BUT this time it is more detailed", points= 2.5},
  */

  if (B12 || B17) {
    ret.push(`Thanks for sharing your team's thoughts again. Your implications for the persona are well thought out and detailed, as in your previous answer. Keep up the good work!`);
  }

  if (B13) {
    ret.push(`Thank you for your answer.
Just like in your answer above, you have remembered to use your persona instead of a general user but could have described these implications with more details (e.g. How would it affect certain behaviors or help achieve specific goals).`);
  }

  if (B14) {
    ret.push(`Thank you for your submission.
However, as mentioned in the exercise above, while your implications are quite detailed, your answer would have been even better if you had used your persona to evaluate the implications.`);
  }

  if (B15) {
    ret.push(`Thank you for your answer.
However, as mentioned above, in order for you to make the most of this exercise and correctly evaluate how this idea can affect your users, it would have been better if you had used your persona and your user journey to evaluate your idea.
This would help you be more detailed in your description as you could directly address the different touchpoints stated in your user journey or needs and behaviors from you persona.`);
  }

  if (B16) {
    ret.push(`Again, thank you for stating the implications you expect this idea to have on your persona.

This time however, your implications were more detailed and focused more on your users feelings and emotions.
Doing so will allow you to evaluate your ideas better and increase the chance that this idea adds real value to your users.
Good job!`);
  }

  return ret;
};
