import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for submitting the name of your team members.
All of them will receive the same grade as you, but will not be able to see the feedback.
So it is your job to share your feedback with your team later on.`);
  }

  return ret;
};
