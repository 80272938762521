import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `You have only submitted one "should-have" criteria. Continue the good work and add more! `
    );
  }

  if (A2) {
    ret.push(`You submitted some "should-have" criteria. Continue the good work and add more!`);
  }

  if (A3) {
    ret.push(`Well done! You submitted a number of "should-have" criteria.`);
  }

  if (A4) {
    ret.push(
      `Your criteria are clearly formulated and convincing, and will help you to align with your team and leadership, and share a vision throughout the sprint.`
    );
  }

  if (A5) {
    ret.push(
      `To align your team and leadership with one vision and agree on a set of boundaries, you could formulate your criteria even more detailed and understandable. `
    );
  }

  if (A6) {
    ret.push(
      `Unfortunately, your criteria lacks some depth and detail to make them understandable and shareable. A clear formulated set of criteria helps to align not only your team, but also include the leadership in the sprint vision.`
    );
  }

  return ret;
};
