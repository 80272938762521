import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Great work! Your answer shows that you though about various returns and valuable outcomes of your project, tangible as well as non-tangible ones.`
    );
  }

  if (A2) {
    ret.push(
      `You idenfified a projected return of your project. However, we think that there is a greater variety of possible value of the project. Try to think in different directions and add more tangible, as well as non-tangible returns. `
    );
  }

  if (A3) {
    ret.push(`Your argumentation is well formulated and convincing.`);
  }

  if (A4) {
    ret.push(
      `Thank you for stating an explanation about the possible returns of the project and their value, and described how you came up with them. To convince someone even more about the value of your project, your argumentation could have some more detail and substance.`
    );
  }

  if (A5) {
    ret.push(
      `Your answer lacks a clear description that connects the possible return you stated with a value.`
    );
  }

  return ret;
};
