import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Great job in sending out the survey to a large number of people that you have identified as users. Ths should get you a decent number of responses to a quantitative analysis.`
    );
  }

  if (A2) {
    ret.push(
      `Good job in sending out the survey to a decent number of people. The response rate is always lower than what you expect and hence try sending it out to a larger set to get more responses.`
    );
  }

  if (A3) {
    ret.push(
      `The survey has been sent to a considerably low number of people. Remember that the response rate is generally 3-5%. So to get a decent number of responses to do a proper quantitative analysis, try sending it out to a larger set of users.`
    );
  }

  if (A4) {
    ret.push(`This is a good number to start with for conducting a quantitative analysis `);
  }

  if (A5) {
    ret.push(
      `This number seems decent but not ideal enough to start a quantitative analysis. Wait till you get more responses or send it to a larger group of people to fill the survey. `
    );
  }

  if (A6) {
    ret.push(
      `This number seems low and will not be possible to do any quantitative analysis yet. Wait till you get more responses or send it to a larger group of people to fill the survey. `
    );
  }

  return ret;
};
