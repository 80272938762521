import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B1, B2, B3, B4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (B1) {
    ret.push(`You have set a good goal for the amount of ideas you want to pick for now.
Depending on the idea and project we would recommend picking something between 1 and 3 ideas for now.
It is possible that after prototyping and testing all three ideas are deemed unsuitable. In that case you may decide to adapt those ideas or come back to other ideas presented here.
Therefore, please make sure to keep all of your ideas saved for now.`);
  }

  if (B2) {
    ret.push(`There is no ideal answer to this question. However, we think that you may be planning to pick too many ideas, which will make it harder for you to focus on each one of them.
It is important that you don't pick too many ideas for now. This will ensure that you and your team can focus on just the, for now, most promising ideas.
Therefore, we advise you to maybe reduce the number of ideas you wish to select during your session later.

Also, it is possible that after prototyping and testing your selected ideas, they are all deemed unsuitable. In that case you may need to adapt those ideas or come back to other ideas generated during your brainstorming session.
This is why it is important to keep all of your ideas saved for now. `);
  }

  if (B3) {
    ret.push(`There is no ideal answer to this question. However, we think that you may be planning to pick too few ideas for now.
Selecting more ideas will help you and your team have more flexibility later and help you speed up your process and get to a good solution faster.
Therefore, we advise you to maybe increase the number of ideas you wish to select during your session later.

Also, it is possible that after prototyping and testing your selected ideas, they are all deemed unsuitable. In that case you may need to adapt those ideas or come back to other ideas generated during your brainstorming session.
This is why it is important to keep all of your ideas saved for now. `);
  }

  if (B4) {
    ret.push(`Also, thank you for stating why you decided on the number of ideas you did.
This helps us to understand your thought process and give you better feedback.`);
  }

  if (true) {
    ret.push(`We would also like to remind you that the dot-voting should not be used to blindly select the best ideas.
Instead, this is a method to help each participants select their preferred ideas.
Once the dot-voting is done, it is a good idea to ask the participants why they selected their ideas and discuss as a team until you can select the ones that you would like to test.
Just make sure to keep your discussions brief! It is important to have a good time management at this stage of your session.`);
  }
  /*
  if (!Z0 && !Z1 && B4 === false) {
    ret.push(`Why did you pick this number of ideas to be selected?
Answering this helps us to understand your thought process and give you better feedback.

Furthermore, it may also help you reflect whether or not this is actually the right number of ideas to move forward. Often, just by reflecting on why you decided on something, you are able to realise issues or possible improvements.
This is why we advise you to keep asking yourself "why" after each decision you and your team make from now on.
`);
  }
*/
  return ret;
};
