import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for stating the project that you will be working on. This will help align you to the rest of the exercises and give us some context when giving feedback to your assignment.
However, to learn the most out of this exercise, it would be best if you gave more details about the project you will be working on.`);
  }

  if (A2) {
    ret.push(
      `Thank you for stating the project that you will be working on with so much depth. This will help us give better feedback to your assignment later on and will help you visualize better your idea.`
    );
  }

  if (A3) {
    ret.push(`Thank you for stating the project that you will be working on. This will help align you to the rest of the exercises and give us some context when giving feedback to your assignment.
However, to help us understand better what your project is and consequently give you better feedback in the following exercises, it would have been best if you had stated very clearly what exactly your product or service is.`);
  }

  if (A4) {
    ret.push(
      `We just ask you to avoid using too many abbreviations or technical terms in the future as well as making sure to explain any topic that may be very specific to your industry or work. This will also make it easier for us to understand better your project and help us give you more actionable feedback.`
    );
  }

  return ret;
};
