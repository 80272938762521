import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Amazing! You stated your team, consisting of your sprint lead who is responsible for execution, 2-3team members, and a team sponsor who is involved in final decisions. `
    );
  }

  return ret;
};
