import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`No file uploaded`);
  }

  if (A3 && A4 === false) {
    ret.push(
      `Well done! Your prototype focuses only on the most important features and functionalities. This is exactly what a prototype should be like! However, it seems to us like your prototype is not very scrappy. Ideally, a prototype should also take very little time and resources to create, and it seems to us that your prototype is a bit too complex. In your future prototyping work, you should try to minimize the resources used to create prototypes.`
    );
  }

  if (A3 === false && A4) {
    ret.push(
      `Well done! Your prototype is very simple and should have taken little time to make. This is exactly what a prototype should be like! However, ideally, your prototype should also focus only on the most important features and ignore details that add little value. In your future prototyping work, you should try to implement only the most essential features.`
    );
  }

  if (A2 && A3 === false && A4 === false) {
    ret.push(
      `Your prototype is more complex than it has to be. Ideally, a prototype should focus only on the most essential features and use up the least amount of resources possible. It seems to us that your prototype included features that are not essential and therefore took more time than it would have to. In the future, make sure to invest as little resources as possible.`
    );
  }

  if (A3 && A4) {
    ret.push(
      `Very well done! Your prototype addresses only the most important features and should have taken you little time and resources to make. That is exactly how a good prototype should be made! `
    );
  }

  if (A5) {
    ret.push(
      `More importantly, your prototype is very well designed to test your idea and will help you get valuable feedback from your users. Great job!`
    );
  }

  if (A6) {
    ret.push(
      `However, in order to get the most out of this exercise, it would have been better if your prototype would aid your test more.
Ideally, you could make a wireframe that allows the users to see the how each page of your app is connected and test it out.
It should not take much more time to prepare, but would give you much more actionable feedback.`
    );
  }

  if (A7) {
    ret.push(
      `It seems like you had already developed this prototype prior to this exercise.
However, we are happy to say that you managed to adapt and use your existing resources very successfully.
This prototype should help you get valuable comments and validate your ideas.
Well done!

Still, we would like to remind you that in the future you may have to create new prototypes when testing new ideas.
And when you do that, it is super important that you focus only on the most relevant properties and keep your prototype as simple as possible.
The goal is that your users can interact with your prototype and test one one of your idea's key features.`
    );
  }

  if (A8) {
    ret.push(
      `It seems like you had already developed this prior to this exercise.
While there is nothing wrong with that, in fact, we believe that adapting and reusing existing resources is a great idea, we think that your prototype will not allow your users to really interct with your idea to test one of its core features.
Your prototype might be used to explain or visualize your idea better, but in order to make the most out of your test it is super important that they can interact with it somehow.
For this you should focus only on the most relevant properties and keep your prototype as simple as possible.

For the future, we advise you to try this out as it will help you validate key ideas with very little resources!`
    );
  }

  return ret;
};
