import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for describing the risk handling strategy you are choosing. `);
  }

  if (A2) {
    ret.push(
      `You have provided a good explanation on why you decided for this strategy in particular. `
    );
  }

  if (A3) {
    ret.push(
      `And last, you have stated and explained the remaining risk after introducing your avoid/reduce or transfer strategy. `
    );
  }

  if (A4) {
    ret.push(
      `Your explanation showcases a deep understanding of the topic of risk handling in genereal. Well done!`
    );
  }

  if (A5) {
    ret.push(
      `Your explanation shows that you understood the basics of risk handling. Even though there is some small potential for improvement, you delivered good results.`
    );
  }

  if (A6) {
    ret.push(
      `Your explanation you stated lacks some understanding of risk handling in genereal. You can do better for sure! Rewatch the videos or have a look at the method cards again.`
    );
  }

  if (!Z0 && !A2) {
    ret.push(
      `Unfortunatley, you did not provide a good explanation why you are choosing this risk. There is no general right or wrong, but to understand whether your decision is a good one, you must understand the reason behind it.`
    );
  }

  if (!Z0 && !A3) {
    ret.push(
      `In your answer, you do not explain the remaining risk. It is rare that you can avoid a risk completly, more likely you will have some remaining risk. Getting aware of this remaining risk is for both your team and your manager to understand the environment in which you operate.`
    );
  }

  return ret;
};
