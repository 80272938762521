import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
const ret: string[] = [];
const { Z0, Z1, A2, A3, A4, A5, A6, A7, A8, A9, A14, A15 } = getSelectedOptionsFlags(selectedOpts);
/*
    { id = "A6", text = "Feedback starts with something positive", points = 0.5},
    { id = "A2", text = "Feedback is specific. It should not be something like 'Good work yesterday!'. It should explain exactly why it was good or bad. -> What can you improve?", points = 1 },
    { id = "A1", text = "Feedback mentions something they should improve", points = 0.5 },
    { id = "A5", text = "Feedback is actionable. Does it define next steps, actions, set goals, defines consequences or rewards if goals are not met, specify time for follow-up?", points = 1},
    { id = "#8", text = "----- ----- ----- ----- Additional feedback ----- ----- ----- ----- ----- ", points = 0},
    { id = "A3", text = "Did not write feedback in first person (I told him...)", points = -0.5},
    { id = "A4", text = "Feedback is not constructive", points = 0},
    { id = "A7", text = "Language too direct", points = -0.25},
    { id = "A8", text = "Used 'us/we' instead of 'you vs. I (good)'--- Please select only once!", points = 0.25},
*/

if (A3 && !A15) {
ret.push(
`Thank you for sharing your first piece of feedback!
However, it seems that you did not share your feedback in the first person as asked.

We asked you to do so because this allows us to see exactly what wording you used and how you structured your feedback.
This, in turn, would have allowed us to give you a better feedback.
Still, below you will find our feedback based on your submission.`
);
}

if (A2 && A5 && !A4 && !A15) {
ret.push(
`Thank you for sharing your first piece of feedback! Your feedback is well structured and is specific and actionable. This will make it easier for your colleague to understand what he or she is doing well and what he/she could improve.`
);
}

if (A2 && A5 === false && !A4 && !A15) {
ret.push(
`Thank you for sharing your first piece of feedback.
You have done a good job making sure that your feedback pinpoints exactly what your colleague did well and what they could improve.
However, as it is framed, your feedback does not give actionable steps your colleague could take to improve. Good constructive feedback should be not only polite and specific, but it should also pinpoint what can be improved and define clear steps or actions that could be taken to generate better results. 
For example, instead of saying, "Your work yesterday was good, but was missing a strong conclusion", you could add something like "Next time, try to make a summary of the main points discussed and emphasize your main statement again". 
By doing this, you not only bring attention to a potential improvement, but you already give a certain goal or possible next step.`
);
}

if (A2 && A5 === false && A4 && !A15) {
ret.push(
`Thank you for sharing your first piece of feedback.
However, it seems that you are giving your colleague a suggestion of something they could do which you believe would help them, instead of giving them a piece of constructive feedback as asked.
While this is certainly something worth sharing, our goal with this assignment was to evaluate how you frame constructive feedback (supportive feedback given to individuals to help identify solutions to areas of weakness they may have) so that we can maybe share some tips on how to do it even better.`
);
}

if (!Z0 && !Z1 && A2 === false && !A4 && !A15) {
ret.push(
`To be effective, your feedback should have been more specific and point out in more detail what your colleague did well and what they should improve at. 
For example, while something like "Great work yesterday!" may seem like good feedback, it is essential to be always very clear about what you mean. In this example above, you may be implying that your coworker handled that meeting yesterday perfectly. 
However, she may have understood that you really liked her report on something. The same goes for constructive feedback. If you want someone to understand and improve, you should always give precise feedback so that they know exactly what went well, or what should be improved.`
);
}

if (!Z0 && !Z1 && A2 === false && A4 && !A15) {
ret.push(
`Your feedback is not constructive and it could have been more specific. Good constructive feedback is not only polite and specific, but it should also state at least one thing your colleague could improve or do differently.
These are the hardest pieces of feedback to give.
In the future, whenever you are give constructive feedback to someone, you should try to start with something positive and show understanding for their situation. It is important to state your critics in a way that your peer does not feel personally attacked.`
);
}

/*
if (!Z0 && !Z1 && A5 === false && !A15) {
ret.push(
`The feedback you submitted is not very actionable. Good constructive feedback is not only polite and specific, but it should also define the next steps or some action that could yield better results. For example, instead of saying, "Your work yesterday was good, but was missing a strong conclusion", you should add something like "Next time, try to make a summary of the main points discussed and emphasize your main statement again". By doing this, you not only bring attention to a potential improvement, but you already give a certain goal or possible next step.`
);
}
*/

if (A14 && !A15) {
    ret.push(`It seems to us that the first part of your feedback (what the the person did well) does not relate to what you said next.
If instead you had shared something related to what you believe they could do differently, it might make the transition even easier.`);
    }

if (A8 && !A15) {
ret.push(`What you did especially well was to use "us" instead of "you vs. I" ("To ease your work load, could we perhaps...").
This highlights that you understand their position and are willing to work together to improve the situation.
Demonstrating empathy like this will ultimately strengthen your relationship and improve communication.
Well done!`);
}

if (!Z0 && !Z1 && A6 === false && !A4 && !A14 && !A15) {
ret.push(
`One thing you could improve is to start your feedback by mentioning something the other person did well. This will make it easier to transition to something they could improve.
Most importantly, it will also make it more likely that the person listens to your feedback.`
);
}

if (A7 && (A6 || A4) && !A15) {
ret.push(`One thing we would like to point out is that the language used might have been a little too direct. 
Depending on your relationship with your colleague and your regional culture, this may not be a problem. But as a rule of thumb, it is a good idea to use "softer" language when sharing constructive feedback.
One good approach is to use "we/us" instead of "you vs. me". This helps you focus on the problem and not on the individuals and helps you show that you are on this together.`);
}

if (A7 && !A4 && !A6 && !A15) {
    ret.push(`Another thing we would like to point out is that the language used might have been a little too direct. 
Depending on your relationship with your colleague and your regional culture, this may not be a problem. But as a rule of thumb, it is a good idea to use "softer" language when sharing constructive feedback.
One good approach is to use "we/us" instead of "you vs. me". This helps you focus on the problem and not on the individuals and helps you show that you are on this together.`);
    }

if (A15) {
    ret.push(`Thank you for sharing your feedback with us. You did a great job highlighting your colleague's strengths and the positive impact they have had on the team. It's always important to acknowledge and appreciate the good work of our colleagues.
However, we were hoping to see how you would share constructive feedback with your colleagues instead of only a positive feedback.
Being able to share constructive feedback in a way that strengthens your relationship with your colleague is hard. This is why we were hoping to see how you do it.

In any case, here are some tips you can use in the future:
- Be specific, actionable and polite.
- Focus on the problem and not on the person. Do your best to separate the issue you would like to address from the person you are giving feedback to.
- Use a conciliatory language. Rather than saying "You did... and I think...", try to frame the situation using us/we. For example: "I've noticed that this happened, what if we... Do you think this could work?".
`);
    }

return ret;
};
