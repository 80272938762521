import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, C1, C2, C3, C4, C5, C6, C7, C8, C9, C10, C11, C12, C13, C14, A13, B13, C16 } =
    getSelectedOptionsFlags(selectedOpts);

  if (C7) {
    ret.push(
      `Again, good job identifying that your goal is SMART.
Having SMART goals should help your team achieve them better.
Well done!`
    );
  }

  if (C6) {
    ret.push(
      `You did not go over each aspect of a SMART goal again.
It may seem unnecessary to do so, but going over each aspect might help you identify issues with your goal and frame it better.
It is a short exercise that is worth its time. We advise you to briefly go over each aspect in the future.`
    );
  }

  if (C8) {
    ret.push(
      `Again, good job identifying that your goal was not SMART.
Your adapted goal is now more clear and should help your team achieve it better.
Well done!`
    );
  }

  if (C9) {
    ret.push(
      `Thank you for submitting your third goal.
You have correctly identified that your goal is not SMART and have tried to adapt it so that it is.
However, we believe your reformulated goal is still missing some of the criteria of a SMART goal.`
    );
  }

  if (C1) {
    ret.push(
      `Thank you for sharing your goal once more.
However, it seems like your goal is once more not SMART.
Below you will find what must still be improved in the formulation of your goal so that it is:`
    );
  }

  if (C2) {
    ret.push(
      `Once more, thank you for sharing your goal.
It seems to us that again your original goal was already SMART.
Still, the improved goal you shared below certainly is SMART.
So well done!

We advise you to keep evaluating the goals you receive or set in the future so that they are always SMART.`
    );
  }

  if (C3) {
    ret.push(
      `Thank you for sharing this goal again.
It seems to us that once more your original goal was already SMART and your adapted goal is not.
Below you will find what must still be improved in the formulation of your goal so that it is:`
    );
  }

  if (C4) {
    ret.push(
      `Thank you for sharing this goal again.
Once more, it seems to us that your original goal was already SMART.
Again, if you really think that it isn't SMART, then we would have liked to see how you would adapt it so that it is.

Lastly, if you are still unsure how to set SMART goals, it may be a good idea for you to go over our SMART goal content once more.
You can also reach out to us in the community in case you have any questions. Please do not hesitate to do so!`
    );
  }

  if (C10) {
    ret.push(
      `We would have liked to see an explanation of why you believe this goal is specific.
For us, its formulation is still too broad. This makes it hard to pin-point exactly what must be achieved to complete this goal.
Receiving specific goals reduce the chance of having misunderstandings and make it easier for them to be reached. For a summary on the specificity of goals, you can check the M4 method cards.`
    );
  }

  if (C11) {
    ret.push(
      `What metric will you use to track its progress and confirm its termination?
It seems to us that, as stated right now, your goal does not have any clearly trackable metrics that will help you assess your progress.`
    );
  }

  if (C12) {
    ret.push(
      `You did not define a specific time frame for your goal.
Setting a time constraint to your goal is very important, as it makes time-management more efficient and helps keep your team motivated.
Having too much time or no clear deadline makes this goal lose priority early on. However, it is important to set reasonable expectations, as giving too little time can also be harmful to your project.`
    );
  }

  if (C13) {
    ret.push(
      `Also, we would like to thank you for the good explanations and details in this exercise and part .b and .c as well. We are happy to see that you have thought about this thoroughly.`
    );
  }

  if (C14 && !A13 && !B13) {
    ret.push(
      `Thank you for stating your goal.
You have correctly identified that your original goal is not SMART.
However, you did not submit an improved version of this goal. This makes it hard for us to evaluate how well you can frame SMART goals. `
    );
  }

  if (C14 && (A13 || B13)) {
    ret.push(
      `Once more you have you have correctly identified that your original goal is not SMART.
However, you did not submit an improved version of this goal again.`
    );
  }

  if (C16) {
    ret.push(
      `Thank you for sharing your third goal.
However, again, we ask you to include all the information you require to ensure that your goal is SMART in the goal's formulation.`
    );
  }

  if (true) {
    ret.push(
      `We hope you have seen the value of going through your goals in detail and evaluating if they are SMART.
Doing so is especially relevant whenever you receive or set a new goal. Make sure to discuss and redefine your goals with your manager/subordinate in the future if necessary.`
    );
  }

  return ret;
};
