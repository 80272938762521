import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A10, A11, A12 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Not enough metrics submitted. It is a good idea to think of several metrics that measure different criteria for your test. This is important as a good idea should add value along several dimensions and not just one. For example, if we would rate cars only by their emissions, we would probably still ride horses to work. This is why we suggest you come up with several metrics to measure all significant features you can think of.`
    );
  }

  if (A2) {
    ret.push(
      `Not enough metrics submitted. It is a good idea to think of several metrics that measure different criteria for your test. This is important as a good idea should add value along several dimensions and not just one. For example, if we would rate cars only by their emissions, we would probably still ride horses to work. This is why we suggest you come up with several metrics to measure all significant features you can think of.`
    );
  }

  if (A3) {
    ret.push(
      `You have submitted an acceptable number of metrics. However, it is a good idea to think of more metrics that measure different criteria for your test. This is important as a good idea should add value along several dimensions and not just one. For example, if we would rate cars only by their emissions, we would probably still ride horses to work. This is why we suggest you come up with several metrics to measure all significant features you can think of.`
    );
  }

  if (A4) {
    ret.push(
      `Good job! You have thought of a good number of metrics. This is super important as a good idea should add value along several dimensions and not just one. For example, if we would rate cars only by their emissions, we would probably still ride horses to work.`
    );
  }

  if (A5) {
    ret.push(
      `Good job! You have thought of a good number of metrics. This is super important as a good idea should add value along several dimensions and not just one. For example, if we would rate cars only by their emissions, we would probably still ride horses to work.`
    );
  }

  if (A6 && (A1 || A2)) {
    ret.push(
      `However, all of your metrics are reliably measurable. This may seem redundant, but it is common to find metrics that are not very measurable.`
    );
  }

  if (A6 && A1) {
    ret.push(
      `However, the metric stated can be measured very well. This may seem redundant, but it is common to find metrics that are not very measurable.`
    );
  }

  if (A6 && (A2 || A3 || A4 || A5)) {
    ret.push(
      `More importantly, all of your metrics are reliably measurable. This may seem redundant, but it is common to find metrics that are not very measurable.`
    );
  }

  if (A7) {
    ret.push(
      `However, some of your metrics cannot be measured very well. In order for your tests to be reliable, it is important that the metrics you use to determine whether the test was a success or not are reliable as well. This means that they can be easily determined and compared with some critical values. Please make sure to adapt your criteria so that this is the case.`
    );
  }

  if (A10) {
    ret.push(
      `Additionally, while these questions can be used to measure the acceptance and success of your test, are you sure that they are the best metrics you can use?
These yes/no questions might be effective now, but imagine that you do the second iteration of your idea in the future and you try to compare the feedbacks. These binary answers will not give you any information regarding improvements.
In order for you to have useful metrics it is important that they are continuous (like a rate from 1-10 or a time measurement). This will allow you to track progress and consequently improve your idea more effectively`
    );
  }

  if (A11) {
    ret.push(
      `Furthermore, you are trying to use qualitative inputs to measure your progress and success.
We understand that for some ideas this sometimes seems like a good approach, but doing so will make it very hard for you to reliably assess the effectiveness and progress of your idea.
This is why we strongly advise you to try to think of several quantitative measures that will help you track and confirm your progress.
`
    );
  }

  if (A12) {
    ret.push(
      `Also, how will you measure satisfaction?
  You did no<t make this clear.
  It is important to find ways to quantify it systematically so that you can compare results later.
  For example a very simple way to do that is to measure the NPS of your prototype. `
    );
  }

  return ret;
};
