import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B1, B2, B3, B4, B5, B6, B7, B8, B9, B10, B11, B12, B13, B14, B15, A9 } =
    getSelectedOptionsFlags(selectedOpts);

  /*
    { id= "B1", text= "Idea stated", points= 0.5 },
    {id = "#6", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "B2", text= "No argumentation", points= 0 },
    { id= "B3", text= "Brief argumentation (1 or two small sentences)", points= 0.5 },
    { id= "B4", text= "Detailed argumentation", points= 1 },
    { id= "B5", text= "Exceptional argumentation", points= 1.5 },
    {id = "#7", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "B7", text= "Arguments not supported", points= 0 },
    { id= "B8", text= "Arguments supported by findings/quotes/observations", points= 2 },
    {id = "#21", text = "----- ----- ----- ----- For same feedback as 3.4.a) - PICK ONE ----- ----- ----- ----- ----- ", points = 0},
    { id= "B9", text= "Everything correct like in point a) --> Give points with options above", points= 0 },
    { id= "B10", text= "Idea not named like in point a) --> Give points with options above", points= 0},
    { id= "B11", text= "Arguments not supported like in point a) --> Give points with options above", points= 0 },
  */

  if (B9 && !B15 && !B14) {
    ret.push(`Well done again!
Just like in your answer above, you have not only argued well in defense of this idea, but have also supported your arguments with findings from your previous user research. `);
  }

  if (B10 && !B15 && !B14) {
    ret.push(`Thank you for your submission.
However, just like in your submission above, you did not state exactly which idea you have picked.
In the future, please make sure to answer all parts of the exercises in the practical assignment. Doing this will help you take all the necessary steps and allow us to give you better feedback.`);
  }

  if (B11 && !B15 && !B14) {
    ret.push(`Thank you for your answer.
However, just like in the answer above, you did not support your arguments with findings from your user research.`);
  }

  if (B12 && !B15 && !B14) {
    ret.push(`Thank you for your answer. It is very detailed and you have given very good arguments again.
However, just like in the answer above, you did not support your arguments with findings from your user research.`);
  }

  if (B1) {
    ret.push(`No second idea submitted`);
  }

  if (B2 && !B9 && !B10 && !B11 && !B12 && !B15 && !B14) {
    ret.push(`However, you did not give any arguments as to why your team decided to try out this idea.
Ideally, you should not only state why this idea is relevant for your business, but also make use of findings to back up your arguments.`);
  }

  if (B3 && !B9 && !B10 && !B11 && !B12 && !B15 && !B14) {
    ret.push(`However, your argumentation as to why your team decided to pick this idea is too brief.
You and your team have spent a lot of time working to arrive at these ideas. Therefore, you should make sure that your argumentation as to why you want to try this idea should be very good.
Ideally, you should not only state why this idea is relevant for your business, but also make use of findings to back up your arguments.`);
  }

  if (B4 && (B8 || B13) && !B9 && !B10 && !B11 && !B12 && !B15 && !B14) {
    ret.push(`You have given good and detailed arguments to explain why you and your team decided to continue with this idea.
More importantly you have also backed your arguments with findings from your user research.
This will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.`);
  }

  if (B4 && B7 && !B9 && !B10 && !B11 && !B12 && !B15 && !B14) {
    ret.push(`You have given good and detailed arguments to explain why you and your team decided to continue with this idea.
However, you did not support your arguments with findings from your user research.
Doing this will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.`);
  }

  if (B5 && (B8 || B13) && !B9 && !B10 && !B11 && !B12 && !B15 && !B14) {
    ret.push(`You have given very good arguments to explain why you and your team decided to continue with this idea.
More importantly you have also backed your arguments with findings from your user research.
This will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.
Very well done!`);
  }

  if (B5 && B7 && !B9 && !B10 && !B11 && !B12 && !B15 && !B14) {
    ret.push(`You have given very good arguments to explain why you and your team decided to continue with this idea.
However, you did not support your arguments with findings from your user research.
Doing this will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.`);
  }

  if (B7 && !B4 && !B5 && !B9 && !B10 && !B11 && !B12 && !B15 && !B14) {
    ret.push(`Lastly, you did not back up your arguments with findings from your user research.
Doing this will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.`);
  }

  if ((B8 || B13) && !B9 && !B10 && !B11 && !B12 && !B15 && !B14) {
    ret.push(`Well done! You have backed your arguments with findings from your user research.
This will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.`);
  }

  if (B15 && !A9 && !B14) {
    ret.push(`Your argumentation as to why you chose this idea is not great.
It seems that you focused more on the practicability and easiness-to-implement than on your User Research and the needs and pain-points discovered.

At this stage you should always focus on the most relevant idea according to the pain-points identified in module 1.
In our next module you will worry about prototyping this idea and validating its desirability.
You should only worry about viability and feasibility after that. If an idea is not desirable, then you can just scrap or pivot it and you won't even get to waste time thinking on its feasibility.
If, however, during your prototyping phase you realise that this idea is extremely valuable, then, and only then, you can start to think about the how.

I can assure you that if you come up with a great solution you will find a way to bring it to market in one way or another.`);
  }

  if (B14 && A9) {
    ret.push(`As in your answer above, you have again focused to much on the technical feasibility and business viability of your idea.
In the future, please make sure to select your main idea based on the findings from your User Research.`);
  }

  return ret;
};
