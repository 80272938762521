import { Exercise, GradingCriteriaAppliedMap } from "@sparkademy/app-common/models/assignment";

export function getAppliedCriteriaScore(
  evaluations: { [evaluationItemId: string]: string[] },
  exercise: Exercise
): GradingCriteriaAppliedMap {
  const ret: GradingCriteriaAppliedMap = {};

  for (const section of exercise.sections) {
    const criteriaToPointsLookup = section.questions
      .flatMap(q => q.grading_criteria_sets.flatMap(g => g.options))
      .concat(section.grading_criteria_options)
      .reduce((acc, cur) => {
        acc[cur.grading_id] = cur.points;
        return acc;
      }, {} as { [k: string]: number });

    const checkedCriteria = evaluations[section.id];
    const checkedCriteriaPoints = checkedCriteria?.map(c => ({
      [c]: criteriaToPointsLookup[c],
    }));

    ret[section.id] = checkedCriteriaPoints || [];
  }

  return ret;
}

export const getSelectedOptionsFlags = (selectedOptions: string[]) => {
  return {
    Z0: selectedOptions.includes("Z0"),
    Z1: selectedOptions.includes("Z1"),
    Z2: selectedOptions.includes("Z2"),
    Z3: selectedOptions.includes("Z3"),
    Z4: selectedOptions.includes("Z4"),
    Z5: selectedOptions.includes("Z5"),
    Z6: selectedOptions.includes("Z6"),
    Z7: selectedOptions.includes("Z7"),
    Z8: selectedOptions.includes("Z8"),
    Z9: selectedOptions.includes("Z9"),
    Z10: selectedOptions.includes("Z10"),
    Z11: selectedOptions.includes("Z11"),
    Z12: selectedOptions.includes("Z12"),
    Z13: selectedOptions.includes("Z13"),
    Z14: selectedOptions.includes("Z14"),
    Z15: selectedOptions.includes("Z15"),
    Z16: selectedOptions.includes("Z16"),
    Z17: selectedOptions.includes("Z17"),
    Z18: selectedOptions.includes("Z18"),
    Z19: selectedOptions.includes("Z19"),
    Z20: selectedOptions.includes("Z20"),
    A0: selectedOptions.includes("A0"),
    A1: selectedOptions.includes("A1"),
    A2: selectedOptions.includes("A2"),
    A3: selectedOptions.includes("A3"),
    A4: selectedOptions.includes("A4"),
    A5: selectedOptions.includes("A5"),
    A6: selectedOptions.includes("A6"),
    A7: selectedOptions.includes("A7"),
    A8: selectedOptions.includes("A8"),
    A9: selectedOptions.includes("A9"),
    A10: selectedOptions.includes("A10"),
    A11: selectedOptions.includes("A11"),
    A12: selectedOptions.includes("A12"),
    A13: selectedOptions.includes("A13"),
    A14: selectedOptions.includes("A14"),
    A15: selectedOptions.includes("A15"),
    A16: selectedOptions.includes("A16"),
    A17: selectedOptions.includes("A17"),
    A18: selectedOptions.includes("A18"),
    A19: selectedOptions.includes("A19"),
    A20: selectedOptions.includes("A20"),
    A21: selectedOptions.includes("A21"),
    A22: selectedOptions.includes("A22"),
    A23: selectedOptions.includes("A23"),
    A24: selectedOptions.includes("A24"),
    A25: selectedOptions.includes("A25"),
    A26: selectedOptions.includes("A26"),
    A27: selectedOptions.includes("A27"),
    A28: selectedOptions.includes("A28"),
    A29: selectedOptions.includes("A29"),
    A30: selectedOptions.includes("A30"),
    A31: selectedOptions.includes("A31"),
    A32: selectedOptions.includes("A32"),
    A33: selectedOptions.includes("A33"),
    A34: selectedOptions.includes("A34"),
    A35: selectedOptions.includes("A35"),
    B0: selectedOptions.includes("B0"),
    B1: selectedOptions.includes("B1"),
    B2: selectedOptions.includes("B2"),
    B3: selectedOptions.includes("B3"),
    B4: selectedOptions.includes("B4"),
    B5: selectedOptions.includes("B5"),
    B6: selectedOptions.includes("B6"),
    B7: selectedOptions.includes("B7"),
    B8: selectedOptions.includes("B8"),
    B9: selectedOptions.includes("B9"),
    B10: selectedOptions.includes("B10"),
    B11: selectedOptions.includes("B11"),
    B12: selectedOptions.includes("B12"),
    B13: selectedOptions.includes("B13"),
    B14: selectedOptions.includes("B14"),
    B15: selectedOptions.includes("B15"),
    B16: selectedOptions.includes("B16"),
    B17: selectedOptions.includes("B17"),
    B18: selectedOptions.includes("B18"),
    B19: selectedOptions.includes("B19"),
    B20: selectedOptions.includes("B20"),
    B21: selectedOptions.includes("B21"),
    B22: selectedOptions.includes("B22"),
    B23: selectedOptions.includes("B23"),
    B24: selectedOptions.includes("B24"),
    B25: selectedOptions.includes("B25"),
    B26: selectedOptions.includes("B26"),
    B27: selectedOptions.includes("B27"),
    B28: selectedOptions.includes("B28"),
    B29: selectedOptions.includes("B29"),
    B30: selectedOptions.includes("B30"),
    C0: selectedOptions.includes("C0"),
    C1: selectedOptions.includes("C1"),
    C2: selectedOptions.includes("C2"),
    C3: selectedOptions.includes("C3"),
    C4: selectedOptions.includes("C4"),
    C5: selectedOptions.includes("C5"),
    C6: selectedOptions.includes("C6"),
    C7: selectedOptions.includes("C7"),
    C8: selectedOptions.includes("C8"),
    C9: selectedOptions.includes("C9"),
    C10: selectedOptions.includes("C10"),
    C11: selectedOptions.includes("C11"),
    C12: selectedOptions.includes("C12"),
    C13: selectedOptions.includes("C13"),
    C14: selectedOptions.includes("C14"),
    C15: selectedOptions.includes("C15"),
    C16: selectedOptions.includes("C16"),
    C17: selectedOptions.includes("C17"),
    C18: selectedOptions.includes("C18"),
    C19: selectedOptions.includes("C19"),
    C20: selectedOptions.includes("C20"),
    C21: selectedOptions.includes("C21"),
    C22: selectedOptions.includes("C22"),
    C23: selectedOptions.includes("C23"),
    C24: selectedOptions.includes("C24"),
    C25: selectedOptions.includes("C25"),
    C26: selectedOptions.includes("C26"),
    C27: selectedOptions.includes("C27"),
    C28: selectedOptions.includes("C28"),
    C29: selectedOptions.includes("C29"),
    C30: selectedOptions.includes("C30"),
    D0: selectedOptions.includes("D0"),
    D1: selectedOptions.includes("D1"),
    D2: selectedOptions.includes("D2"),
    D3: selectedOptions.includes("D3"),
    D4: selectedOptions.includes("D4"),
    D5: selectedOptions.includes("D5"),
    D6: selectedOptions.includes("D6"),
    D7: selectedOptions.includes("D7"),
    D8: selectedOptions.includes("D8"),
    D9: selectedOptions.includes("D9"),
    D10: selectedOptions.includes("D10"),
    D11: selectedOptions.includes("D11"),
    D12: selectedOptions.includes("D12"),
    D13: selectedOptions.includes("D13"),
    D14: selectedOptions.includes("D14"),
    D15: selectedOptions.includes("D15"),
    D16: selectedOptions.includes("D16"),
    D17: selectedOptions.includes("D17"),
    D18: selectedOptions.includes("D18"),
    D19: selectedOptions.includes("D19"),
    D20: selectedOptions.includes("D20"),
    E0: selectedOptions.includes("E0"),
    E1: selectedOptions.includes("E1"),
    E2: selectedOptions.includes("E2"),
    E3: selectedOptions.includes("E3"),
    E4: selectedOptions.includes("E4"),
    E5: selectedOptions.includes("E5"),
    E6: selectedOptions.includes("E6"),
    E7: selectedOptions.includes("E7"),
    E8: selectedOptions.includes("E8"),
    E9: selectedOptions.includes("E9"),
    E10: selectedOptions.includes("E10"),
    E11: selectedOptions.includes("E11"),
    E12: selectedOptions.includes("E12"),
    E13: selectedOptions.includes("E13"),
    E14: selectedOptions.includes("E14"),
    E15: selectedOptions.includes("E15"),
    E16: selectedOptions.includes("E16"),
    E17: selectedOptions.includes("E17"),
    E18: selectedOptions.includes("E18"),
    E19: selectedOptions.includes("E19"),
    E20: selectedOptions.includes("E20"),
    F0: selectedOptions.includes("F0"),
    F1: selectedOptions.includes("F1"),
    F2: selectedOptions.includes("F2"),
    F3: selectedOptions.includes("F3"),
    F4: selectedOptions.includes("F4"),
    F5: selectedOptions.includes("F5"),
    F6: selectedOptions.includes("F6"),
    F7: selectedOptions.includes("F7"),
    F8: selectedOptions.includes("F8"),
    F9: selectedOptions.includes("F9"),
    F10: selectedOptions.includes("F10"),
    F11: selectedOptions.includes("F11"),
    F12: selectedOptions.includes("F12"),
    F13: selectedOptions.includes("F13"),
    F14: selectedOptions.includes("F14"),
    F15: selectedOptions.includes("F15"),
    F16: selectedOptions.includes("F16"),
    F17: selectedOptions.includes("F17"),
    F18: selectedOptions.includes("F18"),
    F19: selectedOptions.includes("F19"),
    F20: selectedOptions.includes("F20"),
    G0: selectedOptions.includes("G0"),
    G1: selectedOptions.includes("G1"),
    G2: selectedOptions.includes("G2"),
    G3: selectedOptions.includes("G3"),
    G4: selectedOptions.includes("G4"),
    G5: selectedOptions.includes("G5"),
    G6: selectedOptions.includes("G6"),
    G7: selectedOptions.includes("G7"),
    G8: selectedOptions.includes("G8"),
    G9: selectedOptions.includes("G9"),
    G10: selectedOptions.includes("G10"),
    G11: selectedOptions.includes("G11"),
    G12: selectedOptions.includes("G12"),
    G13: selectedOptions.includes("G13"),
    G14: selectedOptions.includes("G14"),
    G15: selectedOptions.includes("G15"),
    G16: selectedOptions.includes("G16"),
    G17: selectedOptions.includes("G17"),
    G18: selectedOptions.includes("G18"),
    G19: selectedOptions.includes("G19"),
    G20: selectedOptions.includes("G20"),
    H0: selectedOptions.includes("H0"),
    H1: selectedOptions.includes("H1"),
    H2: selectedOptions.includes("H2"),
    H3: selectedOptions.includes("H3"),
    H4: selectedOptions.includes("H4"),
    H5: selectedOptions.includes("H5"),
    H6: selectedOptions.includes("H6"),
    H7: selectedOptions.includes("H7"),
    H8: selectedOptions.includes("H8"),
    H9: selectedOptions.includes("H9"),
    H10: selectedOptions.includes("H10"),
    H11: selectedOptions.includes("H11"),
    H12: selectedOptions.includes("H12"),
    H13: selectedOptions.includes("H13"),
    H14: selectedOptions.includes("H14"),
    H15: selectedOptions.includes("H15"),
    H16: selectedOptions.includes("H16"),
    H17: selectedOptions.includes("H17"),
    H18: selectedOptions.includes("H18"),
    H19: selectedOptions.includes("H19"),
    H20: selectedOptions.includes("H20"),
  };
};
