import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6 } = getSelectedOptionsFlags(selectedOpts);

  /*
    { id= "A0", text= "ALWAYS SELECT THIS!", points= 2 },
    { id= "A1", text= "less than 10 learning notes", points= -0.5 },
    { id= "A2", text= "Some learning notes have no participant codes (on learning notes and picture)", points= -0.5 },
    { id= "A3", text= "Some learnings have more than one finding", points= -0.5 },
    { id= "A4", text= "Some learnings seem subjective (feels like this is an interpretation)", points= -0.5 },
    { id= "A5", text= "Some learnings seem subjective (feels like this is an interpretation)", points= -0.5 },

*/

  if (scoredPoints < 2 && !A1) {
    ret.push(`Thank you for submitting your most interesting learning notes as requested.
However, we would like to point down below what you could do to improve your notes in the future.`);
  }

  if (A1 && scoredPoints > 1.4) {
    ret.push(
      `Thank you for submitting your most interesting learning notes as requested.
However, you did not submit 10 learnings notes as asked.
In the future please make sure that you submission meets all required criteria.
This will allow us to understand your work better and give you more effective feedback.`
    );
  }

  if (A1 && scoredPoints < 1.4) {
    ret.push(
      `Thank you for submitting your most interesting learning notes as requested.
However, you did not submit 10 learnings notes as asked.
In the future please make sure that you submission meets all required criteria.
This will allow us to understand your work better and give you more effective feedback.
Below you will found another thing you could improve in your submission
`
    );
  }

  if (A6) {
    ret.push(
      `Looking at your learning notes in the previously submitted file we can see that all your notes are clear and concise, seem to be objective findings and are clearly attributable to your different users. Well done!`
    );
  }

  if (A2) {
    ret.push(
      `You did not include a participant code to any of your learning notes.
This is less important when you only conduct one or two interviews as you can usually remember which finding is from which interview.
However, if you have more interviews (what you should for a more in-depth user research), then it is important since you want to keep track of who said what in case you have some follow-up questions.
Additionally, it also makes it easier to share your notes if you use a code instead of the full name since this removes all confidential information. Therefore, we recommend you add participant codes to all of your learning notes in the future.`
    );
  }

  if (A3) {
    ret.push(
      `Some of your learning notes include more than one finding per note.
This is not necessarily wrong, however, having more than one finding per note makes it harder to cluster them later and reduces the clarity of the note. It is much easier to share ideas that are very clear and short.
Therefore, we recommend you to pay attention in the future so that all of your notes include only one finding and are as short as possible.`
    );
  }

  if (A4) {
    ret.push(
      `It seems to us that some of your learning notes are not objective representations of your interviews.
We may be wrong, but we would just like to point out that a learning note should include only the raw information that you extracted from the interview (e.g. something that you heard or observed during your qualitative research). It should not include your opinion yet as this can bias your data.
This finding can usually be a quote, an observation, a question, or any other factual statement.
For the next time, you should try to keep all of your learning notes impartial (If you did really already include your opinion already).`
    );
  }

  if (A5) {
    ret.push(
      `Some of your learning notes may have too little text on them.
While it may be clear to you what each of your learning notes mean right now, you will see that adding too little text (only one or two words) will make it hard for you to remember exactly what your participants said and will make it easier for you to add your own bias and interpretations onto those learning notes later.
Therefore, we recommend that in the future you always ensure that your learning notes have enough written on them so that they are clear and well defined.`
    );
  }

  if (A6) {
    ret.push(
      `Looking at your learning notes in the previously submitted file we can see that all your notes are clear and concise, seem to be objective findings and are clearly attributable to your different users. Well done!`
    );
  }

  return ret;
};
