import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Great work! You critically observed the workshop and identified how the difficult situations you found yourself in emerged. `
    );
  }

  if (A2) {
    ret.push(
      `Thanks you for sharing your experiences during the workshop. Observation and reflection is always valuable to improve your facilitation skills. Try to keep up and get even more attentive in your next workshop!`
    );
  }

  if (A3) {
    ret.push(
      `And don't worry if your reactions were not perfect. Being aware of them and reflecting on what you did already brings you one step further to being a better facilitator. `
    );
  }

  if (A4) {
    ret.push(
      `How you react to difficult situations is the second element you should aim to observe and reflect on. There is still some room for improvement. On the next workshop, you could even record yourself and analyze your behavior afterwards.`
    );
  }

  return ret;
};
