import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  /*
  { id = "#4", text = "----- PICK ONE ----- ----- ----- ----- -----", points = 0},
    { id = "A1", text = "No actions stated", points = 0 },
    { id = "A2", text = "Only one action stated", points = 0.5 },
    { id = "A3", text = "Two actions stated", points = 1 },
    { id = "A4", text = "Three actions stated", points = 1.5 },
    { id = "A5", text = "More than three actions stated", points = 2 }, #Half an extra point
    { id = "#5", text = "----- PICK ONE ----- ----- ----- ----- -----", points = 0},
    { id = "A6", text = "Answer is not detailed enough", points = 0 },
    { id = "A7", text = "Answer is somewhat detailed", points = 1 },
    { id = "A8", text = "Answer is very detailed", points = 2 },
    { id = "#6", text = "----- pick freely ----- ----- ----- ----- -----", points = 0},
    { id = "A9", text = "Answer shows relevant changes", points = 2 },
            A10          Answer shows  some relevant change,  points = 1
    */

  if (A1) {
    ret.push(
      `It seems you did not state any direct change or learning you could use to improve your team dynamics.
In order to make the most out of this exercise, we ask you to reflect on your team session once more and try to identify clear measures you could take to improve your team dynamics.`
    );
  }

  if (A2) {
    ret.push(
      `It seems like you have only stated one direct change or learning that you could use to improve your team dynamics.
In order to make the most out of this exercise, we ask you to reflect on your team session once more and try to identify more measures you could take.`
    );
  }

  if (A3) {
    ret.push(
      `It seems like you have only stated two direct changes or learnings that you could use to improve your team dynamics.
In order to make the most out of this exercise, we ask you to reflect on your team session once more and try to identify more measures you could take.`
    );
  }

  if (A4) {
    ret.push(
      `Thank you for stating three actions as asked.
You should now work on making sure to implement these tasks on your day-to-day work.
Until these are natural for you, you will have to actively remind yourself of them.`
    );
  }

  if (A5) {
    ret.push(
      `We appreciate you sharing even more actions than just the three we asked for.
Now, you should work on making sure to implement these tasks on your day-to-day work.
Until these are natural for you, you will have to actively remind yourself of them.`
    );
  }

  if (A6) {
    ret.push(
      `Lastly, to make sure that you can turn your previous reflections into actionable steps that you can take to be a better communicator and foster a honest and open-communication culture in your teams, we advise to define the actions you will take and stop taking in more detail.
This might seem unnecessary, but not only will writing these steps down help you reflect on them, but it will also ensure that you can still look back at them 6 months from now and know exactly what you meant by it.
Therefore, in the future, please try to submit your answers as detailed as possible.`
    );
  }

  /*
  if (A7) {
    ret.push(
      `Your answer is already quite detailed, but to show your work better, it would have been better if it were even more detailed.
Please make sure to reflect well on this topic before proceeding.`
    );
  }


  if (!Z0 && !Z1 && A9 === false) {
    ret.push(
      `It seems like some of your actions are not very effective and might not help you improve your team skills.
We ask you to go over your notes and assess whether these are really the most effective actions or not.
If not, please try to reflect more on your session so that you can come up with more effective actions.`
    );
  }
*/
  return ret;
};
