import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A0, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "A0", text = "Test is clear", points = 0.5 },
    {id = "A1", text = "Test is reproducible (e.g. there is a system in place so that the results are not random)", points = 0.5},
    {id = "A2", text = "Test actually helps to validate assumption/test core function", points = 1.5},
    {id = "A3", text = "Test makes sense (not just a random task, but something that might happen at work or at home for example)", points = 0.5},
    {id = "A4", text = "Test is detailed", points = 1 },
    {id = "#3", text = "----- ----- ----- ----- Alternative feedback (Give points with criteria above) ----- ----- ----- ----- ----- ", points = 0},
    {id = "A5", text = "Described a prototype instead of a test", points = 0 },
    {id = "A6", text = "Test requires the solution to be implemented", points = 0 },
*/

  if (A1 && A2 && A4 && !A5 && !A6 && !A7) {
    ret.push(
      `Your testing procedure is detailed and well designed to test your hypothesis.
It is also reproducible, which means that you are able to perform the same test under different conditions and should get similar answers from similar participants.
All of this combined should help you make many learnings from your tests!`
    );
  }

  if (A1 && A2 && A4 === false && !A5 && !A6 && !A7) {
    ret.push(
      `You did not describe your test in enough details for us to give you an actionable feedback.
Your testing procedure seems well designed to test your hypothesis and seems to be reproducible as well, which means that you are able to perform the same test under different conditions and should get similar answers from similar participants.
Still, we advise you to write down your plan with more details before conducting your tests. This will not only make it easier for you to conduct the testing later, but it will also allow you to identify any issues with it.`
    );
  }

  if (A1 && A2 === false && A4 && !A5 && !A6 && !A7) {
    ret.push(
      `While your test is detailed and reproducible (you are able to perform the same test under different conditions and should get similar answers from similar participants), we wonder if this is really the right test to test your assumption.
It seems like it does not really address your core assumptions stated earlier.
Therefore, we ask you to go over your testing procedure and adapt it so that it tests better the assumption and core elements you have stated earlier.`
    );
  }

  if (A1 && A2 === false && A4 === false && !A5 && !A6 && !A7) {
    ret.push(
      `While your test is reproducible (you are able to perform the same test under different conditions and should get similar answers from similar participants), we wonder if this is really a good test to test your assumption.
It seems like this test does not really address your core assumptions stated earlier.
Furthermore, your test is not detailed enough. Having it clearly written down will not only make it easier for you to conduct the testing later, but it will also allow you to identify any issues with it.
Therefore, we ask you to go over your testing procedure and adapt it so that it tests better the assumption and core elements you have stated earlier.`
    );
  }

  if (A1 === false && A2 && A4 && !A5 && !A6 && !A7) {
    ret.push(
      `You have designed a detailed and clear testing procedure that should help you to validate or invalidate your assumption.
However, it seems to us that your testing procedure is not very reliable and depends heavily on your participants and the testing situation. We recommend you think about how you can change your testing procedure so that it is more consistent. Maybe develop a testing system or some protocol that should be followed.
This will help you standardize your tests and make the results more comparable and easier to aggregate.`
    );
  }

  if (A1 === false && A2 && A4 === false && !A5 && !A6 && !A7) {
    ret.push(
      `You have designed a good testing procedure that might help you to validate or invalidate your assumption.
However, you did not describe your test in enough details for us to give you a reliable feedback.
We advise you to write down your plan with more details before conducting your tests. This will not only make it easier for you to conduct the testing later, but it will also allow you to identify any issues with it.

Furthermore, it seems to us that your testing procedure is not very reliable and depends heavily on your participants and the testing situation.
We recommend you think about how you can change your testing procedure so that it is more consistent. Maybe develop a testing system or some protocol that should be followed. This will help you standardize your tests and make the results more comparable and easier to aggregate.`
    );
  }

  if (
    !Z0 &&
    !Z1 &&
    !Z2 &&
    A0 &&
    A1 === false &&
    A2 === false &&
    A4 === false &&
    !A5 &&
    !A6 &&
    !A7
  ) {
    ret.push(
      `Thank you for sharing your testing procedure.
However, you did not describe your test in enough details for us to give you a reliable feedback. Doing so will not only make it easier for you to conduct the testing later, but it will also allow you to identify any issues with it.

Furthermore, we have noticed that your testing procedure is not very reliable and depends strongly on your participants and the testing situation.
We recommend you think about how you can change your testing procedure so that it is more consistent.

Lastly, we wonder if this is really the best test to test your assumption. It seems like this test does not really address your core assumptions stated earlier.
Therefore, we ask you to go over your testing procedure and adapt it so that it is more consistent and can tests better the assumption and key elements you have stated earlier.`
    );
  }

  if (A5 && !A6 && !A7) {
    ret.push(
      `It seems like you did not describe a test that will help you validate your assumptions stated above.
Instead, you have described the prototype you are planning to build to help you validate these ideas.

This is not wrong, but a prototype alone is not enough.
The goal here is to come up with a simple test that you can run with your user to validate one of your key assumptions.
Before you proceed,  please make sure to develop such a test.`
    );
  }
  if (A6) {
    ret.push(
      `The test you just described already requires a, maybe slightly simpler, working version of your final idea.
What if after spending a lot of time and resources in this idea you realize that the idea is not good?
This idea will have consumed many resources just to be discontinued in the end.

Therefore, you should try to simplify your test and reduce the effort spent in making it.
For example, if your solution requires a working app, it might be a good idea to start with a simple wireframe to test its design or logic.
This will greatly reduce the effort put in, but will still allow you to gather feedback and validate some of your assumptions.`
    );
  }

  if (A7) {
    ret.push(
      `For the test described above, it is not clear if you are planning to use a simple prototype to validate your hypothesis or if you are planning to develop a working solution already.
Please keep in mind that the idea behind these tests is to validate your hypothesis with as little effort as possible.
Therefore, you should try to build a very simple and cheap prototype to test it.

In the future, please make sure to describe your answers better so that we can give you better feedback.`
    );
  }

  return ret;
};
