import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B1, B2, B3, B5, B6, B7, B8 } = getSelectedOptionsFlags(selectedOpts);

  if (B1) {
    ret.push(`Thank you for this additional file.`);
  }

  if (B2 && !B3) {
    ret.push(
      `Thank you for submitting the list of all the topics you and your team have thought of.`
    );
  }

  if (B3) {
    ret.push(
      `Thank you for sharing your assumptions mapped in relation to the topics mentioned above.`
    );
  }

  return ret;
};
