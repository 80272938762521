import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const {
    Z0,
    Z1,
    A0,
    A1,
    A2,
    A3,
    A4,
    A5,
    A6,
    A7,
    A8,
    A9,
    A10,
    A11,
    A12,
    A13,
    A14,
    A15,
    A16,
    A17,
    A18,
    A19,
    A20
  } = getSelectedOptionsFlags(selectedOpts);

  /* New Criteria
    {id = "A1", text = "KP: Not enough key partners", points = -0.75},
    {id = "A2", text = "KP: Only stated who your key partners are, but not what value they bring to your BM", points = -0.75},
    {id = "A3", text = "KA: Not enough key activities stated", points = -0.75},
    {id = "A4", text = "KA: Key activities not detailed enough", points = -0.75},
    {id = "A5", text = "VP: Value Proposition not stated for all customers ", points = -0.75},
    {id = "A6", text = "VP: VP does not highlight the value generated clear enough", points = -0.75},
    {id = "A7", text = "CR: Customer Relationships not detailed enough", points = -0.75},
    {id = "A8", text = "CR: Mixed CR with Channels", points = -0.75},
    {id = "A9", text = "CS: Not enough customer segments (must be at least the three in the case)", points = -0.75},
    {id = "A10", text = "CS: CS not enough customer characteristics", points = -0.75},
    {id = "A11", text = "KR: Not enough key resources stated", points = -0.75},
    {id = "A12", text = "KR: KR not detailed enough", points = -0.75},
    {id = "A13", text = "CH: Not enough channels stated", points = -0.75},
    {id = "A14", text = "CH: Channels not stated for different users", points = -0.75},
    {id = "A15", text = "Cost: Not enough costs included", points = -0.75},
    {id = "A16", text = "Cost: Costs are too simplistic", points = -0.75},
    {id = "A17", text = "RS: Not enough revenue streams", points = -0.75},
    {id = "A18", text = "RS: Revenue streams not detailed enough", points = -0.75},
  */

  if ((scoredPoints === 8) && !Z0 && !Z1) {
    ret.push(
      `You have thoughtfully chosen each element in your business model canvas and connected them to make a comprehensive business model.
More importantly, you have managed to include enough details to all your entries and have given a good focus to you key activities and customer segments (which are the most important parts of your BM canvas).
Well done!`
    );
  }

  if ((scoredPoints === 7.25) && !Z0 && !Z1) {
    ret.push(
      `Overall you have done a good job with your Business Model Canvas. You have chosen well each element in your business model canvas and done a good job merging them into one comprehensive business model.
However, there is one thing you can do to improve your canvas.`
    );
  }

  if ((scoredPoints === 6.5) && !Z0 && !Z1) {
    ret.push(
      `Overall you have done a good job with your Business Model Canvas. You have chosen well each element in your business model canvas and done a good job merging them into one comprehensive business model.
However, there are two things that you could improve in your canvas.`
    );
  }

  if ((scoredPoints < 6.5) && !Z0 && !Z1) {
    ret.push(
      `Overall you have done a good job with your Business Model Canvas. You have designed well most individual elements of your business model canvas and have done a good job ensuring that they fit into one comprehensive business model.
However, there are a still few things you could change in your canvas to make your business model more clear and robust.`
    );
  }

  if (A0) {
    ret.push(
      `You did not include all of your key customers to several of the aspects of your business model canvas.
Every time you see clear differences between different user segments, you should make sure that all of them are covered by your BM canvas.
This is especially important in two-sided marketplaces.
Therefore, please go over your canvas and re-assess if you should not include more entries to different types of users.`
    );
  }

  if (A1) {
    ret.push(
      `You have not stated enough key partners.
Try to look around in your ecosystem and understand who your suppliers, your complementors, and other ecosystem participants are. What value do they bring to your business model? And what kinds of partnerships do you have with these people?
You will not find all of them in the case you received from us. Please feel free to make some educated assumptions and try to come up with a few more.
Doing so will give you a much better overview of the stakeholders involved in your project`
    );
  }

  if (A2 && !A1) {
    ret.push(
      `You have not emphasized enough the value your key partners bring to your business model.
Instead of just listing who your key partners are, try to pinpoint what exactly they do for you.
This will help you assess how relevant they are to your business and consequently help you focus on only the most relevant ones for now.`
    );
  }

  if (A2 && A1) {
    ret.push(
      `Furthermore, you have not emphasized enough the value your key partners bring to your business model.
Instead of just listing who your key partners are, try to pinpoint what exactly they do for you.
This will help you assess how relevant they are to your business and consequently help you focus on only the most relevant ones for now.`
    );
  }

  if (A3 && !A20) {
    ret.push(
      `You did not state enough key activities.
Your key activities are central to your business model. They are all the "things" you do to create, deliver and capture value.
While we don't expect you to list all of them yet, we strongly advise you to take some time to evaluate this activities.
You will see that the more clear these activities are for you, the easier it will be to evaluate the other parts of your canvas.`
    );
  }

  if (A4 && !A3 && !A20) {
    ret.push(
      `Your key activities are not detailed enough.
Key activities are central to your business model. They are all the "things" you do to create, deliver and capture value.
While we don't expect you to list all of them yet, we strongly advise you to take some time to evaluate this activities.
You will see that the more clear these activities are for you, the easier it will be to evaluate the other parts of your canvas.`
    );
  }

  if (A20) {
    ret.push(
      `It seems that you misunderstood what was meant with Key Activities. These are not the activities (or tasks) your team must perform to launch this business. Instead, it is meant to be the key activities that will be required to run this business once it is launched. It should be the key activities that help you create and deliver value to all of your stakeholders.
In the case of such a marketplace, some of these activities could be "Facilitating payment for the services provided", "Offering insurance for any damage caused by service providers", or "Allowing user to use an app to find and contact junk removal companies".
For a company like AirBnB, these would be:
- Platform Development and Management: Running and improving their online platform where guests find accommodations and hosts list properties.
- Customer Service: Resolving customer queries, complaints, and disputes to ensure a smooth experience for both hosts and guests.
- Marketing and Brand Management: Attracting hosts and guests to the platform through various marketing strategies and maintaining a positive brand image.
- Quality Control and Trust: Verifying listings, enforcing policies, and managing the reviews system to ensure a trusted marketplace.
- Legal and Regulatory Compliance: Navigating legal and regulatory environments across different regions to ensure the platform operates within the law.
- Payment Processing: Handling secure financial transactions between hosts and guests.
- Community Building and Engagement: Nurturing a community of hosts and travelers through events, resources, and online engagement.
- Data Management and Analytics: Collecting and analyzing data to make informed business decisions and improve the platform's features.`
    );
  }

  if (A5) {
    ret.push(
      `You did not state the Value Proposition (VP) for all of your key customers.
Very often a business will have more than one type of user. To make the most of your idea it is vital that you focus on all of your key users.
Doing so from the very beginning will ensure that your business model allows a creation of value for all of your users. This will in turn make your business much easier to grow and sustain on the long run.
If, however, some key customer are neglected in the beginning, it may be necessary to redesign and adapt your business model later (which is much harder and more costly).
Therefore, please take some time to evaluate all of your users and redefine your VP so that it addresses all of them.
`
    );
  }

  if (A6 && !A5) {
    ret.push(
      `Your Value Proposition (VP) is not detailed enough.
The VP is the most important part of your business model. If you cannot clearly and easily state how you generate value for all your key customers, then maybe your idea is not as valuable after all.
Therefore, please spend some minutes re-evaluating your VP and make sure that the value you create to each of your users is very clear and obvious.`
    );
  }

  if (A7) {
    ret.push(`Your Customer Relationship is not detailed enough.
Try to visualize how you will interact with your users and try to use that to set your solution apart.
For example: At Starbucks, baristas are meant to shout out “Good morning” or something equally cheery when a new user enters the shop. That arguably improves their customer relationship in comparison with other coffee stops and forms part of the experience that Starbucks is trying to create.`);
  }

  if (A8) {
    ret.push(`It seems like you overlapped Customer Relationship (CR) with your Distribution Channels (DC).
The CR is about the experience you give to your user. It is mostly about the direct interaction you have while they are using your product/service.
The DC are the means you use to reach your user.
Please go over your Business Model Canvas once more and make sure that both terms are not overlapping.`);
  }

  if (A9) {
    ret.push(`You have not identified enough customer segments.
In the case provided by us, you are given 3 personas representing three different user segments.
We would expect you to name at least those three in your business model canvas.`);
  }

  if (A10 && !A9) {
    ret.push(`Your customer segments don't have enough characteristics.
What sets these segments apart? How are those differences important to you?
Try to clearly set them apart so that you can focus on each one of them better.`);
  }

  if (A10 && A9) {
    ret.push(`Furthermore, your customer segments don't have enough characteristics.
What sets these segments apart? How are those differences important to you?
Try to clearly set them apart so that you can focus on each one of them better.`);
  }

  if (A11) {
    ret.push(`You did not state enough Key Resources.
Please take a few more minutes to think about the marketplace you are trying to build.
What are all the assets you will need to make that happen? You should try to come up with at least a couple more.
Doing so will help you identify weaknesses with your business model later.`);
  }

  if (A12 && !A11) {
    ret.push(`Your Key Resources are not detailed enough.
Please take a few more minutes to elaborate more on the assets you will need to launch this marketplace.
These can be special connections, capabilities, skills, intellectual or physical assets like patents.`);
  }

  if (A13 && !A14) {
    ret.push(`How will you reach your customers?
It is a good idea to try to think of more channels that you could use to reach out to your different customer segments.
Also, it is likely that different user segments require different channels!`);
  }

  if (A14) {
    ret.push(`How will you reach your different Customer Segments.
It seems like you are planning to use the same channels to reach out to all of your Customer Segments.
However, often it is a good approach to look for specific channels for specific customer segments.
Therefore, please try to think of concrete channels your could use for your different users.
Doing so will make your distribution more specific and effective.`);
  }

  if (A15) {
    ret.push(`Are you sure that these are all of your projected costs?
We believe that there are a other relevant costs that you should pay attention to.
Spending time on this now will make it much easier for you to predict your first cash-flows and allow you to make corrections to your business model early on.`);
  }

  if (A16 && !A15) {
    ret.push(`Your cost structures are a bit too simple.
Please take some time to define your projected costs better. This may seem like a lot of effort, but spending time on this now will make it much easier for you to predict your first cash-flows and allow you to make corrections to your business model early on.`);
  }

  if (A17 && !A18) {
    ret.push(`Try to find more revenue streams.
Even though we are at the early stages of your business model design, we strongly advise you to look for more possible revenue streams.
You may even drop one of your streams later, but identifying many streams in detail now, will make easier for you to define the best business model to launch later.
Also, identifying these streams early on may also allow you to tap into secondary revenue streams that will make your business model more robust and reliable.`);
  }

  if (A18) {
    ret.push(`Your revenue streams are a bit too simple.
Even though we are at the early stages of your business model design, we strongly advise you to think about your revenue streams carefully.
You may even drop one of your streams later, but identifying many streams in detail now, will make easier for you to define the best business model to launch later.
Also, identifying these streams early on may also allow you to tap into secondary revenue streams that will make your business model more robust and reliable.`);
  }

  /* Old criteria
    {id = "A1", text = "All elements of the BM canvas are present", points = 1},
    {id = "A2", text = "There are multiple ideas present for at least half of the elements. E.g., not just one key resource, one key partner or one distribution channel. Not all elements need ideas", points = 2},
    {id = "A4", text = "Customer segment connect to the value proposition stated in 1.1", points = 1.5},
    {id = "A5", text = "Costs and Revenues are related to key activities, resources and partners", points = 1.5},
    {id = "A6", text = "Content demonstrates a strong understanding of what each element is ", points = 2},
    {id = "#5", text = "----- ----- ----- ----- Pick freely below----- ----- ----- ----- ----- ", points = 0},
    {id = "A7", text = "Some parts of the canvas are unclear", points = 0},
    {id = "A8", text = "Some elements could be more detailed", points = 0},
    {id = "A9", text = "Submission not readable", points = 0},
    {id = "A10", text = "Submission has low resolution (still readable but not good)", points = 0},

  Old feedback
  if (A2) {
    ret.push(
      `You have generated lots of ideas and thought about different possibilities for different elements of your business model canvas. It's good to cover all the possible options for each element to ensure you are thoroughly accounting for all of the parts of your business model.`
    );
  }

  if (A4) {
    ret.push(
      `The customer segment(s) you included on your Business Model Canvas fit with the value proposition you wrote in 1.1. Having a strong value proposition that is directly connected to the customers you serve is an essential foundation for a strong business model.`
    );
  }

  if (A5) {
    ret.push(
      `Your financial structure aligns with your key activities, resources, and partners, which shows a key understanding of how the elements of a business model need to be related. Costs and revenues informed by the key activities, resources, and partners reduce the possibility for gaps in your business model.`
    );
  }

  if (A7) {
    ret.push(
      `Some elements on your canvas were lacking details or were unclear.
  It's important that you think carefully about all the parts of your canvas and ther interrelations in a concrete way, because a functioning business model's parts should be all interconnected.
  Go back through each element on the canvas you filled out and ask yourself what role it plays in the business and how it may enable other functions of the business. Also add those details and little thoughts, and if you don't have a clear answer for any element, revise what you wrote down.`
    );
  }

  if (A8) {
    ret.push(
      `Some elements on your canvas are not specific enough to make sense in your business plan. The business model canvas is a tool used to communicate with those outside your team, so make sure you are clear in what you write down, such that someone else can read the canvas and understand your business model. `
    );
  }

  if (A9) {
    ret.push(
      `It seems like you generated lots of ideas and thought about different possibilities for several elements of your business model canvas. However, the file you submitted is not readable.
Therefore, we can not give you any useful feedback for your submission.
Please make sure that future submissions are readable by us.`
    );
  }

  if (A10) {
    ret.push(
      `The file you submitted was not easy to read due to its low resolution. In the future we would appreciate it if you exported it as an excel instead of taking a screenshot of it. This will improve the quality of the image and make it easier for us to grade and give you better feedback.`
    );
  }

  if (Z0 === false && !Z1 && A1 === false) {
    ret.push(
      `A strong business model needs to includes every element on the business model canvas. Make sure that you have filled out every element on the canvas and understand the purpose of it in your business model.`
    );
  }

  if (Z0 === false && !Z1 && A2 === false) {
    ret.push(
      `Usually, a functional business model is more complex than just one item of each element on the business model canvas. Go back through your canvas, especially sections like key activities, resources and partners and consider if there you need to include more items in order to ensure your business model doesn't have gaps and covers all its needs to be successful.`
    );
  }

  if (!Z0 && !Z1 && A4 === false) {
    ret.push(
      `The customer segment you included in your business model canvas doesn't fit with the value propositions you wrote in exercise 1.1. A strong value proposition should be directly connected to what your customer segment is, which together help shape the rest of your business model. Go back to the value proposition you wrote and brainstrom customer segments that it would most closely apply to.`
    );
  }

  if (!Z0 && !Z1 && A5 === false) {
    ret.push(
      `There seem to be some gaps between your financial structure and the key activities, resources and partners you included in your business model canvas. Go back to your costs and revenues and check if they make sense given key activities, resources, and partners.`
    );
  }

  if (!Z0 && !Z1 && A6 === false) {
    ret.push(
      `Some elements on your canvas were unclear what you meant or how they fit into your business plan. It's important that you think carefully about all the parts of your canvas because a functioning business model's parts are all interconnected. Go back through each element on the canvas you filled out and ask yourself what role it plays in the business and how it may enable other functions of the business. If you don't have a clear answer for any element, revise what you wrote down.`
    );
  }

  */

  return ret;
};
