import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A2, A3, A4, A5, A7, A9, A10, A11, A12, A14, A16, A17, A18, A19 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A2 && A3 && A4 && A5 && A9 && A10 && A11 && A12 && A16 && A17 && A18 && A19) {
    ret.push(
      `Perfect! All of your insights are relevant to your overarching question, they are informative, they are motivating, they are memorable, and they are a combination of some of your findings and your own interpretation. Very well done!`
    );
  }

  if (A2 === false && Z0 === false && (A7 || A9) && (A14 || A16) && Z1 === false) {
    ret.push(
      `How is your first insight informative? An informative insight should bring new information to your team that will help you truly understand your users. Why do they act the way that they do? What causes them to take the decisions they take? Insights should help you answer these questions.
For example, "Customers are willing to pay more for high-quality products" is not an informative insight even though it has useful information. The problem is that this statement above does not help you truly understand the underlying needs of your users. On the other hand, if we change that to "Customers are willing to pay more for a high-quality car because they are afraid that the car might break down when they need it the most (e.g., driving his injured kid to the hospital or commuting to an important meeting)". This is now an informative insight. It shows us the true reason behind the user's decision. It's not quality that is important for them. It is the fear of feeling powerless.
Now you and your team can tackle this fear and find innovative ways to address it. Therefore, we advise you to analyze your first statement again and rephrase it or change it so that it brings new, non-obvious information.`
    );
  }

  if (A3 === false && Z0 === false && (A7 || A10) && (A14 || A17) && Z1 === false) {
    ret.push(
      `What makes your first insight motivating? A good insight should be inspiring. It should not already offer a solution but instead include a revealing interpretation of the research that makes you want to act on it. We believe that you should look closely at your first insight and change it to meet these requirements.`
    );
  }

  if (A4 === false && Z0 === false && (A7 || A11) && (A14 || A18) && Z1 === false) {
    ret.push(
      `Are you sure that your first insight is written in a memorable way? An insight should always be stated very clearly so that anyone hearing it will immediately understand it. For that, it is a good idea to use only short sentences and clear statements.`
    );
  }

  if (A5 === false && Z0 === false && (A7 || A12) && (A14 || A19) && Z1 === false) {
    ret.push(
      `Your first insight is not a combination of one of your findings and your interpretation. While a finding is just some interesting piece of information gathered during your user research, an insight should go beyond that. An insight should take this finding and build on it. You can ask yourself: "why is this the case?" or " what are the consequences of this?". The goal here is to come up with an interpretation for that finding. It does not necessarily mean that your interpretation is correct (this will ahve to be tested again), but the idea is to take your key findings and transform them into meaningful insights that can be used to further develop your idea or project.`
    );
  }

  if (A9 === false && A7 === false && Z0 === false && Z1 === false && A2 && (A16 || A14)) {
    ret.push(
      `How is your second insight informative? An informative insight should bring new information to your team that will help you truly understand your users. Why do they act the way that they do? What causes them to take the decisions they take? Insights should help you answer these questions.
For example, "Customers are willing to pay more for high-quality products" is not an informative insight even though it has useful information. The problem is that this statement above does not help you truly understand the underlying needs of your users. On the other hand, if we change that to "Customers are willing to pay more for a high-quality car because they are afraid that the car might break down when they need it the most (e.g., driving his injured kid to the hospital or commuting to an important meeting)". This is now an informative insight. It shows us the true reason behind the user's decision. It's not quality that is important for them. It is the fear of feeling powerless.
Now you and your team can tackle this fear and find innovative ways to address it. Therefore, we advise you to analyze your first statement again and rephrase it or change it so that it brings new, non-obvious information.`
    );
  }

  if (A10 === false && A7 === false && Z0 === false && Z1 === false && A3 && (A17 || A14)) {
    ret.push(
      `What makes your second insight motivating? A good insight should be inspiring. It should not already offer a solution but instead contain a revealing interpretation of the research that makes you want to act on it. We believe that you should look closer at your second insight and change it to meet these requirements.`
    );
  }

  if (A11 === false && A7 === false && Z0 === false && Z1 === false && A4 && (A18 || A14)) {
    ret.push(
      `Are you sure that your second insight is written in a memorable way? An insight should always be stated very clearly so that anyone hearing it will immediately understand it. For that, it is a good idea to use only short sentences and clear statements.`
    );
  }

  if (A12 === false && A7 === false && Z0 === false && Z1 === false && A5 && (A19 || A14)) {
    ret.push(
      `Your second insight is not a combination of one of your findings and your interpretation. While a finding is just some interesting piece of information gathered during your user research, an insight should go beyond that. An insight should take this finding and build on it. You can ask yourself: "why is this the case?" or " how did this come to happen". The goal here is to come up with an interpretation for that finding. It does not necessarily mean that your interpretation is correct (this will have to be tested again), but the idea is to take your key findings and transform them into meaningful insights that can be used to further develop your idea or project.`
    );
  }

  if (A16 === false && Z0 === false && Z1 === false && A2 && (A7 || A9)) {
    ret.push(
      `How is your third insight informative? An informative insight should bring new information to your team that will help you truly understand your users. Why do they act the way that they do? What causes them to take the decisions they take? Insights should help you answer these questions.
For example, "Customers are willing to pay more for high-quality products" is not an informative insight even though it has useful information. The problem is that this statement above does not help you truly understand the underlying needs of your users. On the other hand, if we change that to "Customers are willing to pay more for a high-quality car because they are afraid that the car might break down when they need it the most (e.g., driving his injured kid to the hospital or commuting to an important meeting)". This is now an informative insight. It shows us the true reason behind the user's decision. It's not quality that is important for them. It is the fear of feeling powerless.
Now you and your team can tackle this fear and find innovative ways to address it. Therefore, we advise you to analyze your first statement again and rephrase it or change it so that it brings new, non-obvious information.`
    );
  }

  if (A17 === false && Z0 === false && Z1 === false && A3 && (A7 || A10)) {
    ret.push(
      `What makes your third insight motivating? A good insight should be inspiring. It should not already offer a solution but instead contain a revealing interpretation of the research that makes you want to act on it. We believe that you should look closer at your third insight and change it to meet these requirements.`
    );
  }

  if (A18 === false && Z0 === false && Z1 === false && A4 && (A7 || A11)) {
    ret.push(
      `Are you sure that your third insight is written in a memorable way? An insight should always be stated very clearly so that anyone hearing it will immediately understand it. For that, it is a good idea to use only short sentences and clear statements.`
    );
  }

  if (A19 === false && Z0 === false && Z1 === false && A5 && (A7 || A12)) {
    ret.push(
      `Your third insight is not a combination of one of your findings and your interpretation. While a finding is just some interesting piece of information gathered during your user research, an insight should go beyond that. An insight should take this finding and build on it. You can ask yourself: "why is this the case?" or " how did this come to happen". The goal here is to come up with an interpretation for that finding. It does not necessarily mean that your interpretation is correct (this will have to be tested again), but the idea is to take your key findings and transform them into meaningful insights that can be used to further develop your idea or project.`
    );
  }

  /* Mixed terms. So if two Insight have the same mistake, they will be combined */

  if (
    A2 === false &&
    Z0 === false &&
    A9 === false &&
    A7 === false &&
    (A14 || A16) &&
    Z1 === false
  ) {
    ret.push(
      `How are your first and second insights informative?An informative insight should bring new information to your team that will help you truly understand your users. Why do they act the way that they do? What causes them to take the decisions they take? Insights should help you answer these questions.
For example, "Customers are willing to pay more for high-quality products" is not an informative insight even though it has useful information. The problem is that this statement above does not help you truly understand the underlying needs of your users. On the other hand, if we change that to "Customers are willing to pay more for a high-quality car because they are afraid that the car might break down when they need it the most (e.g., driving his injured kid to the hospital or commuting to an important meeting)". This is now an informative insight. It shows us the true reason behind the user's decision. It's not quality that is important for them. It is the fear of feeling powerless.
Now you and your team can tackle this fear and find innovative ways to address it. Therefore, we advise you to analyze your first statement again and rephrase it or change it so that it brings new, non-obvious information.`
    );
  }

  if (
    A2 === false &&
    Z0 === false &&
    A16 === false &&
    A14 === false &&
    (A7 || A9) &&
    Z1 === false
  ) {
    ret.push(
      `How are your first and third insights informative? An informative insight should bring new information to your team that will help you truly understand your users. Why do they act the way that they do? What causes them to take the decisions they take? Insights should help you answer these questions.
For example, "Customers are willing to pay more for high-quality products" is not an informative insight even though it has useful information. The problem is that this statement above does not help you truly understand the underlying needs of your users. On the other hand, if we change that to "Customers are willing to pay more for a high-quality car because they are afraid that the car might break down when they need it the most (e.g., driving his injured kid to the hospital or commuting to an important meeting)". This is now an informative insight. It shows us the true reason behind the user's decision. It's not quality that is important for them. It is the fear of feeling powerless.
Now you and your team can tackle this fear and find innovative ways to address it. Therefore, we advise you to analyze your first statement again and rephrase it or change it so that it brings new, non-obvious information.`
    );
  }

  if (
    A7 === false &&
    A9 === false &&
    Z0 === false &&
    A14 === false &&
    A16 === false &&
    A2 &&
    Z1 === false
  ) {
    ret.push(
      `How are your second and third insights informative? An informative insight should bring new information to your team that will help you truly understand your users. Why do they act the way that they do? What causes them to take the decisions they take? Insights should help you answer these questions.
For example, "Customers are willing to pay more for high-quality products" is not an informative insight even though it has useful information. The problem is that this statement above does not help you truly understand the underlying needs of your users. On the other hand, if we change that to "Customers are willing to pay more for a high-quality car because they are afraid that the car might break down when they need it the most (e.g., driving his injured kid to the hospital or commuting to an important meeting)". This is now an informative insight. It shows us the true reason behind the user's decision. It's not quality that is important for them. It is the fear of feeling powerless.
Now you and your team can tackle this fear and find innovative ways to address it. Therefore, we advise you to analyze your first statement again and rephrase it or change it so that it brings new, non-obvious information.`
    );
  }

  if (
    A2 === false &&
    Z0 === false &&
    A7 === false &&
    A9 === false &&
    A16 === false &&
    A14 === false &&
    Z1 === false
  ) {
    ret.push(
      `All of your three insights are not informative. An informative insight should bring new information to your team that will help you truly understand your users. Why do they act the way that they do? What causes them to take the decisions they take? Insights should help you answer these questions.
For example, "Customers are willing to pay more for high-quality products" is not an informative insight even though it has useful information. The problem is that this statement above does not help you truly understand the underlying needs of your users. On the other hand, if we change that to "Customers are willing to pay more for a high-quality car because they are afraid that the car might break down when they need it the most (e.g., driving his injured kid to the hospital or commuting to an important meeting)". This is now an informative insight. It shows us the true reason behind the user's decision. It's not quality that is important for them. It is the fear of feeling powerless.
Now you and your team can tackle this fear and find innovative ways to address it. Therefore, we advise you to analyze your first statement again and rephrase it or change it so that it brings new, non-obvious information.`
    );
  }

  if (
    A3 === false &&
    Z0 === false &&
    A10 === false &&
    A7 === false &&
    (A14 || A17) &&
    Z1 === false
  ) {
    ret.push(
      `What makes your first and second insights motivating? A good insight should be inspiring. It should not already offer a solution but instead include a revealing interpretation of your research that makes you want to act on it. We believe that you should look closely at your first and second insights and change them to meet these requirements.`
    );
  }

  if (
    A3 === false &&
    Z0 === false &&
    A17 === false &&
    A14 === false &&
    (A7 || A10) &&
    Z1 === false
  ) {
    ret.push(
      `What makes your first and third insights motivating? A good insight should be inspiring. It should not already offer a solution but instead include a revealing interpretation of your research that makes you want to act on it. We believe that you should look closely at your first and third insights and change them to meet these requirements.`
    );
  }

  if (
    A7 === false &&
    A10 === false &&
    Z0 === false &&
    A14 === false &&
    A17 === false &&
    A3 &&
    Z1 === false
  ) {
    ret.push(
      `What makes your second and third insights motivating? A good insight should be inspiring. It should not already offer a solution but instead include a revealing interpretation of your research that makes you want to act on it. We believe that you should look closely at your second and third insights and change them to meet these requirements.`
    );
  }

  if (
    A3 === false &&
    Z0 === false &&
    A7 === false &&
    A10 === false &&
    A17 === false &&
    A14 === false &&
    Z1 === false
  ) {
    ret.push(
      `All three of your insights somewhat lack the motivational aspect. A good insight should be inspiring. It should not already offer a solution but instead include a revealing interpretation of your research that makes you want to act on it. We believe that you should look closely at your insights and change them to meet these requirements.`
    );
  }

  if (
    A4 === false &&
    Z0 === false &&
    Z1 === false &&
    A11 === false &&
    A7 === false &&
    (A14 || A18)
  ) {
    ret.push(
      `Are you sure that your first and second insights are written in a memorable way? An insight should always be stated very clearly so that anyone hearing it will immediately understand it. For that, it is a good idea to use only short sentences and clear statements. Therefore, we suggest that you take a better look at those insights and think of ways to make them more memorable.`
    );
  }

  if (
    A4 === false &&
    Z0 === false &&
    Z1 === false &&
    A18 === false &&
    A14 === false &&
    (A7 || A11)
  ) {
    ret.push(
      `Are you sure that your first and third insights are written in a memorable way? An insight should always be stated very clearly so that anyone hearing it will immediately understand it. For that, it is a good idea to use only short sentences and clear statements. Therefore, we suggest that you take a better look at those insights and think of ways to make them more memorable.`
    );
  }

  if (
    A7 === false &&
    A11 === false &&
    Z0 === false &&
    Z1 === false &&
    A14 === false &&
    A18 === false &&
    A4
  ) {
    ret.push(
      `Are you sure that your second and third insights are written in a memorable way? An insight should always be stated very clearly so that anyone hearing it will immediately understand it. For that, it is a good idea to use only short sentences and clear statements. Therefore, we suggest that you take a better look at those insights and think of ways to make them more memorable.`
    );
  }

  if (
    A4 === false &&
    Z0 === false &&
    Z1 === false &&
    A7 === false &&
    A11 === false &&
    A18 === false &&
    A14 === false
  ) {
    ret.push(
      `Are you sure that your three insights are written in a memorable way? An insight should always be stated very clearly so that anyone hearing it will immediately understand it. For that, it is a good idea to use only short sentences and clear statements. Therefore, we suggest that you take a better look at all of your insights and think of ways to make them more memorable.`
    );
  }

  if (
    A5 === false &&
    Z0 === false &&
    Z1 === false &&
    A12 === false &&
    A7 === false &&
    (A14 || A19)
  ) {
    ret.push(
      `Your first two insights are not a combination of one of your findings and your interpretation. While a finding is just some interesting piece of information gathered during your user research, an insight should go beyond that. An insight should take this finding and build on it. You can ask yourself: "why is this the case?" or " what are the consequences of this?". The goal here is to come up with an interpretation for that finding. It does not necessarily mean that your interpretation is correct (this will have to be tested again), but the idea is to take your key findings and transform them into meaningful insights that can be used to develop your idea or project further.
To illustrate better what we mean, let us take the following finding: "Cyclists like to be physically separated from cars" (E.g., via a bicycle lane). This is a very important finding. However, it does not say anything about the cyclists. Why do they like that? What is the reason for that?
We can then add our interpretation (using data gathered during the user discovery) and generate the following insight: "Being physically separated from cars and other dangers make cyclists feel less vulnerable."
This statement now allows us to understand our users better and serves as a base for us to look for human-centered solutions to this problem. One such solution would be to reduce their vulnerability or at least their sense of vulnerability with better gear, technologies, or maybe even better medical assistance. Now that we have this insight, we can start to collect ideas and prototype them (which is exactly what you will do in M2 and M3).`
    );
  }

  if (
    A5 === false &&
    Z0 === false &&
    Z1 === false &&
    A19 === false &&
    A14 === false &&
    (A7 || A12)
  ) {
    ret.push(
      `Your first and third insights are not a combination of one of your findings and your interpretation. While a finding is just some interesting piece of information gathered during your user research, an insight should go beyond that. An insight should take this finding and build on it. You can ask yourself: "why is this the case?" or " what are the consequences of this?". The goal here is to come up with an interpretation for that finding. It does not necessarily mean that your interpretation is correct (this will have to be tested again), but the idea is to take your key findings and transform them into meaningful insights that can be used to develop your idea or project further.
To illustrate better what we mean, let us take the following finding: "Cyclists like to be physically separated from cars" (E.g., via a bicycle lane). This is a very important finding. However, it does not say anything about the cyclists. Why do they like that? What is the reason for that?
We can then add our interpretation (using data gathered during the user discovery) and generate the following insight: "Being physically separated from cars and other dangers make cyclists feel less vulnerable."
This statement now allows us to understand our users better and serves as a base for us to look for human-centered solutions to this problem. One such solution would be to reduce their vulnerability or at least their sense of vulnerability with better gear, technologies, or maybe even better medical assistance. Now that we have this insight, we can start to collect ideas and prototype them (which is exactly what you will do in M2 and M3).`
    );
  }

  if (
    A7 === false &&
    A12 === false &&
    Z0 === false &&
    Z1 === false &&
    A14 === false &&
    A19 === false &&
    A5
  ) {
    ret.push(
      `Your second and third insights are not a combination of one of your findings and your interpretation. While a finding is just some interesting piece of information gathered during your user research, an insight should go beyond that. An insight should take this finding and build on it. You can ask yourself: "why is this the case?" or " what are the consequences of this?". The goal here is to come up with an interpretation for that finding. It does not necessarily mean that your interpretation is correct (this will have to be tested again), but the idea is to take your key findings and transform them into meaningful insights that can be used to develop your idea or project further.
To illustrate better what we mean, let us take the following finding: "Cyclists like to be physically separated from cars" (E.g., via a bicycle lane). This is a very important finding. However, it does not say anything about the cyclists. Why do they like that? What is the reason for that?
We can then add our interpretation (using data gathered during the user discovery) and generate the following insight: "Being physically separated from cars and other dangers make cyclists feel less vulnerable."
This statement now allows us to understand our users better and serves as a base for us to look for human-centered solutions to this problem. One such solution would be to reduce their vulnerability or at least their sense of vulnerability with better gear, technologies, or maybe even better medical assistance. Now that we have this insight, we can start to collect ideas and prototype them (which is exactly what you will do in M2 and M3).`
    );
  }

  if (
    A5 === false &&
    Z0 === false &&
    Z1 === false &&
    A7 === false &&
    A12 === false &&
    A19 === false &&
    A14 === false
  ) {
    ret.push(
      `All three of your insights are not a combination of one of your findings and your interpretation. While a finding is just some interesting piece of information gathered during your user research, an insight should go beyond that. An insight should take this finding and build on it. You can ask yourself: "why is this the case?" or " what are the consequences of this?". The goal here is to come up with an interpretation for that finding. It does not necessarily mean that your interpretation is correct (this will have to be tested again), but the idea is to take your key findings and transform them into meaningful insights that can be used to develop your idea or project further.
To illustrate better what we mean, let us take the following finding: "Cyclists like to be physically separated from cars" (E.g., via a bicycle lane). This is a very important finding. However, it does not say anything about the cyclists. Why do they like that? What is the reason for that?
We can then add our interpretation (using data gathered during the user discovery) and generate the following insight: "Being physically separated from cars and other dangers make cyclists feel less vulnerable."
This statement now allows us to understand our users better and serves as a base for us to look for human-centered solutions to this problem. One such solution would be to reduce their vulnerability or at least their sense of vulnerability with better gear, technologies, or maybe even better medical assistance. Now that we have this insight, we can start to collect ideas and prototype them (which is exactly what you will do in M2 and M3).`
    );
  }

  return ret;
};
