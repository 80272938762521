import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13, A14, A15, A16, A17, A18 } = getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "#1", text = "------------------------ Pick one ------------------------", points = 0},
    {id = "A0", text = "6 or more human resources mentioned", points = 4},
    {id = "A1", text = "4 - 5 human resources mentioned", points = 3},
    {id = "A2", text = "2 - 3 human resources mentioned", points = 1.5},
    {id = "A14", text = "Only 1 human resources mentioned", points = 0},
    {id = "#2", text = "------------------------ Pick ALL that apply ------------------------", points = 0},
    {id = "A3", text = "Their cost per unit mentioned", points = 1},
    {id = "A4", text = "Time involvement mentioned", points = 1},
    {id = "#3", text = "------------------------ Pick ALL that apply ------------------------", points = 0},
    {id = "A5", text = "Materials and their cost mentioned ", points = 1},
    {id = "A6", text = "Acquired services and their cost mentioned", points = 1},
    {id = "A7", text = "Additional items included with their cost", points = 0},
    {id = "#4", text = "------------------------ Pick one ------------------------", points = 0},
    {id = "A8", text = "More than 6 Resources listed", points = 4},
    {id = "A9", text = "3 - 6 items listed ", points = 2},
    {id = "A10", text = "Less than 3 items listed", points = 0},
    {id = "#5", text = "------------------------ Pick one ------------------------", points = 0},
    {id = "A11", text = "Project cost estimate included and looks correct", points = 1},
    {id = "A12", text = "Project cost estimate stated but does not look correct", points = 0},
    {id = "A13", text = "No cost estimate included", points = -1},
    {id = "#6", text = "------------------------ Pick one ------------------------", points = 0},
    {id = "A15", text = "Very good submission overall", points = 3},
    {id = "A16", text = "Good submission overall", points = 2},
    {id = "A17", text = "Acceptable submission overall", points = 1},
    {id = "A18", text = "Poor submission", points = 0},
  */

/* A1 to A4 Criteria */

if ((A1 || A0) && A3 === false && A4 === false) {
ret.push(
`Good job identifying several stakeholders (both in your core team and possibly outside) which you will need to complete this project. Identifying them early and planning how much of their time you will need will make the development of this project much easier.
However, it is also essential to reflect on their cost and time involvement, so that you can come up with an estimate of the direct costs for budgetary purposes. Without these two elements, this exercise is not very useful to budget your project. `
);
}

if ((A1 || A0) && A3 === false && A4) {
ret.push(
`Good job identifying several stakeholders (both in your core team and possibly outside) which you will need to complete this project. Identifying them early and planning how much of their time you will need will make the development of this project much easier.
However, it is also important to reflect on their cost per unit of time, so that you can come up with an estimate of the direct costs for budgetary purposes. Without these two elements, this exercise is not very useful to budget your project. However, you did include the expected time involvement for all of your stakeholders. Good job!`
);
}

if ((A1 || A0) && A3 && A4 === false) {
ret.push(
`Good job identifying several stakeholders (both in your core team and possibly outside) which you will need to complete this project. Identifying them early and planning how much of their time you will need will make the development of this project much easier.
However, it is also essential to come up with an expected time involvement for all of your stakeholders. This will help you estimate the direct costs for budgetary purposes. However, you did include the cost per unit of time for all of your stakeholders. Good job!`
);
}

if ((A2 || A14) && A3 === false && A4 === false) {
ret.push(
`Try to think of more people required for the completion of your project. It seems to us, that there are several stakeholders that are relevant which were not included. 
For example, try to include individuals outside of your core team, which may need to be involved ad-hoc for small steps.
Furthermore, it is also essential to reflect on their cost and time involvement, so that you can come up with an estimate of the direct costs for budgetary purposes. Without these two elements, this exercise is not very useful to budget your project. `
);
}

if ((A2 || A14) && A3 === false && A4) {
ret.push(
`Try to think of more people required for the completion of your project. It seems to us, that there are several stakeholders that are relevant which were not included. 
For example, try to include individuals outside of your core team, which may need to be involved ad-hoc for small steps.
Furthermore, it is also important to reflect on their cost per unit of time, so that you can come up with an estimate of the direct costs for budgetary purposes. Without these two elements, this exercise is not very useful to budget your project. However, you did include the expected time involvement for all of your stakeholders. Good job!`
);
}

if ((A2 || A14) && A3 && A4 === false) {
ret.push(
`Try to think of more people required for the completion of your project. It seems to us, that there are several stakeholders that are relevant which were not included. 
For example, try to include individuals outside of your core team, which may need to be involved ad-hoc for small steps.
Furthermore, it is also essential to come up with an expected time involvement for all of your stakeholders. This will help you estimate the direct costs for budgetary purposes. However, you did include the cost per unit of time for all of your stakeholders. Good job!`
);
}




if (A5) {
ret.push(
`Good job in stating all the material and their costs that are required for the project. `
);
}

if (A6) {
ret.push(
`You have identified all the acquired services and their costs that are relevant to your project `
);
}

if (A7) {
ret.push(
`Great job in identifying additional costs that are relevant and are required for your innovation project to run. `
);
}

if (A8) {
ret.push(
`You created a thorough list of resources that add to the direct cost of your project. This will give you a more accurate idea of the budgetary estimate and a sense of feasibility of your project.`
);
}

if (A9) {
ret.push(
`You have identified a good number of additional resources but try to do an extensive and more detailed research on the items that will be required. These costs add to the direct cost of your project. This will give you a good idea on the budgetary estimate and a sense of feasibility of your project. `
);
}

if (A10) {
ret.push(
`However, you listed a very low number of additional resources and services. Try to do more detailed research on the items that will be required. Additional services and resources can range from consultant services, programs you need, costs for running your website or hosting an app? Try to think about costs in every direction! Is there additional knowledge you need e.g. going to a conference or paying for research? Do you have a running subscriptions? Do you need to travel? These costs add to the direct cost of your project. This will give you a good idea on the budgetary estimate and a sense of feasibility of your project. `
);
}

if (!Z0 && !Z1 && !Z2 && !A5) {
ret.push(
`You have not listed any materials or forgot to mention their costs. Materials can range all the way from prototyping materials, your first MVP, an order you take with a specialized supplier, up to simple everyday things like whiteboards or post-its to hold your workshop. `
);
}

if (!Z0 && !Z1 && !Z2 && !A6) {
ret.push(
`You have not listed any additional services or forgot to mention their costs. Acquired services can range from consultant services, programs you need, costs for running your website or hosting an app?`
);
}

if (!Z0 && !Z1 && !Z2 && !A7) {
ret.push(
`Additionally, try to think about costs in every direction! Is there additional knowledge you need e.g. going to a conference or paying for research? Do you have a running subscriptions? Do you need to travel?`
);
}

if (A11) {
ret.push(
`You now have a total cost estimate for your innovation project which will help you budget and manage the project. Keep in mind, there will likely be  unforeseen costs as you project progresses, so it is important to consider  room for error in a magnitude analysis.`
);
}

if (A12) {
ret.push(
`Thank you for stating the total cost estimate for your project. However, your total amount doesn't seem to match the estimations you submitted above for people and resources. We ask that you take note of any additional costs you may have factored in and double check your math. If you have not already downloaded it, we also recommend using our Bottom-Up Cost Estimation Excel template.`
);
}

if (A13) {
ret.push(
`You did not state the total cost estimate for your project. It's important to come up with this final number because this captures most of the estimated costs you will use to create a budget for your project. Please look back at your estimates for people and resources to get to a total cost estimate.`
);
}

  return ret;
};
