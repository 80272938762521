import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B1, B2, B3, B4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (B1) {
    ret.push(`Well done.
You have set a good goal for the amount of ideas you want to pick for now.
Depending on the idea and project we would recommend picking something between 1 and 3 ideas for now.
It is possible that after prototyping and testing all three ideas are deemed unsuitable. In that case you may decid to adapt those ideas or come back to other ideas presented here.
Therefore, please make sure to keep all of your ideas saved for now.`);
  }

  if (B2) {
    ret.push(`There is no ideal answer to this question. However, it is important that you don't pick too many ideas for now.
  This will ensure that you and your team can focus on just the, for now, most promising ideas.
  Therefore, we advise you to reduce the number of ideas selected during your session later.

  It is possible that after prototyping and testing all three ideas are deemed unsuitable. In that case you may decide to adapt those ideas or come back to other ideas presented here.
  Therefore, please make sure to keep all of your ideas saved for now.`);
  }

  if (B3) {
    ret.push(`There is no ideal answer to this question. However, it is important that you don't pick too few ideas for now.
  This will ensure that you and your team have enough flexibility later to test more than one option. This will help you speed up your process and get to a good solution faster.
  Therefore, we advise you to increase slightly the number of ideas selected during your session later.

  It is possible that after prototyping and testing all three ideas are deemed unsuitable. In that case you may decide to adapt those ideas or come back to other ideas presented here.
  Therefore, please make sure to keep all of your ideas saved for now.`);
  }

  if (B4) {
    ret.push(`Lastly, thank you for stating why you decided on the number of ideas you did.
This helps us to understand your thought process and give you better feedback.

Furthermore, it may also help you reflect whether or not this is actually the right number of ideas to move forward. Often, just by reflecting on why you decided on something, you are able to realise issues or possible improvements.
This is why we advise you to keep asking yourself "why" after each decision you and your team make from now on.`);
  }

  if (!Z0 && !Z1 && B4 === false) {
    ret.push(`Why did you pick this number of ideas to be selected?
Answering this helps us to understand your thought process and give you better feedback.

Furthermore, it may also help you reflect whether or not this is actually the right number of ideas to move forward. Often, just by reflecting on why you decided on something, you are able to realise issues or possible improvements.
This is why we advise you to keep asking yourself "why" after each decision you and your team make from now on.
`);
  }

  return ret;
};
