import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A1 && A3 && A5 && A7 && A9) {
    ret.push(
      `Well done! The main body of your interview guideline contains only excellent questions. They are all open-ended, non-leading, only ask for one thing at a time, do not ask for solutions, and are all clear and easy to understand. Good job!`
    );
  }

  if (Z0 === false && Z1 === false && scoredPoints < 5) {
    ret.push(
      `Good job. Your core interview questions are, in general, very good. There are only some questions that could be changed or at least rephrased. You will find more details on what to improve below.`
    );
  }

  if ((A2 || (Z1 === false && A1 === false)) && Z0 === false) {
    ret.push(
      `The core section of your interview guideline is the most important part. It is where you will ask all the relevant questions to discover the interviewee's needs and pain points. You should make sure to write down a lot of questions. Even if you don't think you will have time to ask all of them, having several questions on standby will allow you to steer the interview towards a direction that suits you and can help when you do go through all of your main questions (otherwise you would have to end the interview or think of good questions on the spot). That is why we recommend you to write down at least ten good questions here, although more are even better.`
    );
  }

  if ((A4 || (Z1 === false && A3 === false)) && Z0 === false) {
    ret.push(
      `Not all of your questions are open-ended. Even if you have follow-up questions ready, you should, as a rule of thumb, avoid closed-ended questions. These questions will reduce the length of the answers you receive and make it harder for you to make many findings. Consequently, we recommend you to only prepare clear open-ended questions.`
    );
  }

  if ((A6 || (Z1 === false && A5 === false)) && Z0 === false) {
    ret.push(
      `Some of your questions are somewhat suggesting a certain answer. It is super important that all the questions you ask are biased-free. This is one of the main reasons why we even prepare an interview guideline. To make sure that the questions we ask are not biased. The goal of the user research is to extract the needs and pain points of your users. Leading questions can severely affect your findings and bias your results. Therefore, we recommend you to take a look at your questions and rewrite the ones that lead the user to answer in a certain way. An example of such a question would be: Do you also dislike this feature? This can be transformed into: How do you feel about this feature? `
    );
  }

  if ((A8 || (Z1 === false && A7 === false)) && Z0 === false) {
    ret.push(
      `Some of your questions ask more than one thing. Questions that ask for several things are usually more complicated and can be harder to understand. This could give you poor answers, as maybe someone would have answered differently if he had understood all parts of the question. These questions, therefore, not only give results that are not reliable it also damages the flow of the conversation. A good interview should be very causal so that the participant doesn't think too much before he answers. Hard questions can remove the interviewees from that state. Thus, we advise you to break down these more complex questions into a few simple and clear ones.`
    );
  }

  if ((A10 || (Z1 === false && A9 === false)) && Z0 === false) {
    ret.push(
      `Some of your questions require your participants to brainstorm or to come up with solutions. This is not a good approach, as the answers you will get are not reliable. You could ask the same participant such a question, and depending on his mood or state, he might give two completely different answers. Furthermore, the solution you may get might be ideal for this participant, but not for others. This is why you should try to identify only needs and pain points instead. Leave the brainstorming for your team.`
    );
  }

  return ret;
};
