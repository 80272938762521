import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A19, A20 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 3.1.
We think your explanation is reasonable and will give you the points for that exercise`);
  }

  if (A2) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 3.1.
We think your explanation is acceptable but not great and will give you half the points for that exercise`);
  }

  if (A3) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 3.2.
We think your explanation is reasonable and will give you the points for that exercise`);
  }

  if (A4) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 3.2.
    We think your explanation is acceptable but not great and will give you half the points for that exercise`);
  }

  if (A5) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 3.3.
We think your explanation is reasonable and will give you the points for that exercise`);
  }

  if (A6) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 3.3.
    We think your explanation is acceptable but not great and will give you half the points for that exercise`);
  }

  if (A7) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 3.4.
We think your explanation is reasonable and will give you the points for that exercise`);
  }

  if (A8) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 3.4.
We think your explanation is acceptable but not great and will give you half the points for that exercise`);
  }

  if (A9) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 3.5.
We think your explanation is reasonable and will give you the points for that exercise`);
  }

  if (A10) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 3.5.
    We think your explanation is acceptable but not great and will give you half the points for that exercise`);
  }

  if (A11) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 3.6.
We think your explanation is reasonable and will give you the points for that exercise`);
  }

  if (A12) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 3.6.
    We think your explanation is acceptable but not great and will give you half the points for that exercise`);
  }

  if (A19) {
    ret.push(`We are sorry but we do not agree with your explanation as to why you did not submit this exercise. Please make sure to go over it and perform it with your team.
This is important as it will make the following tasks easier to perform and might help you achieve better insights at the end of your user need discovery.`);
  }

  if (A20) {
    ret.push(`We are sorry but we do not agree with your explanation as to why you did not submit those exercises. Please make sure to go over them and perform them with your team.
This is important as they will make the following tasks easier to perform and might help you achieve better insights at the end of your user need discovery.`);
  }

  return ret;
};
