import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, C0, C1, C2, C3, C4, C5, C6, C7, C8 } = getSelectedOptionsFlags(selectedOpts);

  if (C0) {
    ret.push(
      `It seems like you did not state how this insight will affect your next steps. How will you change or adapt your project to take your insight into consideration? This is a helpful question to aid your project development from now on.`,
    )
  }

  if (C1) {
    ret.push(
      `You did briefly state how this insight will affect your next steps. However, you did not go into too much detail and didn't provide any concrete examples. Answering this question in detail will help you plan your next steps. `,
    )
  }

  if (C2) {
    ret.push(
      `You gave a very good answer to how this insight will affect your next steps. Well done!`,
    )
  }

  if (C3) {
    ret.push(
      `Your answer regarding how this insight will affect your next steps was exceptional! Your explanations were excellent! Taking some time now to think about this will help you plan and organize your journey's next steps. Well done!`,
    )
  }

  return ret;
};
