import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`);
  }

  if (Z1) {
    ret.push(
      `Well done!
The picture uploaded clearly shows your ideas mapped on an impact-effort matrix and how you and your team used the dot-voting method to evaluate and select the most promising/interesting ideas.

Now, it is important to keep in mind that, after prototyping and testing your ideas in M3, you may realize that they are not desired by your users. Keeping this matrix will be important in that case since it will allow you to look back at your findings and select another idea to experiment.`
    );
  }

  return ret;
};
