import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, C0, C1, C2, C3, C4, C5, C6, C7, C8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (C0) {
    ret.push(
      `It seems like you did not state how this insight will affect your next steps.
How will you change or adapt your project to take your insight into consideration?
This is a helpful question to aid your project development from now on.`
    );
  }

  if (C1) {
    ret.push(
      `Thank you for your answer.
You gave a general idea of how this insight might help you move on.
However, you did not go into too much detail and didn't provide any actionable suggestions or ideas.
Answering this question in detail will help you plan your next steps and facilitate your processes in M2. `
    );
  }

  if (C2) {
    ret.push(
      `You gave a quite detailed answer of how this insight might affect your next steps.
However, in order to help you plan ahead, it is important that you think of actionable next steps already.
Therefore, we advise you to reflect on your learnings so far and try to come up with clear and actionable next steps before starting the next module. `
    );
  }

  if (C3) {
    ret.push(
      `Your answer has made it clear how this insight can help you move forward and will allow you to define actionable further action. Well done!

Taking some time to think about this now will help you plan and organize your journey's next steps.`
    );
  }

  if (C4) {
    ret.push(
      `Very well done!
Your answer has made it very clear how this insight can help you move forward and will allow you to define actionable further action.

Taking some time to think about this now will help you plan and organize your journey's next steps. Well done again!`
    );
  }

  return ret;
};
