import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `You have successfully identified problems that removing the core would create. It's important to be able to foresee challenges should a part of your business model fail because it helps you find solutions and change your business model before problems arise.`
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `You didn't clearly identify problems that removing the core element would cause in your business model. Consider how the gap may affect how effectively you could create, deliver, or capture value. It's important to be able to foresee challenges should a part of your business model fail because it helps you find solutions and change your business model before problems arise.`
    );
  }

  if (A2) {
    ret.push(
      `Nice work explaining the problems you identified that could arise in your business model. You demonstrated an understanding of how the creation, delivery, and capture of value would be affected by removing the core element which is important to understand how you can address the issues.`
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `You didn't back up the problems you identified with an explanation. Consider how the gap may affect how effectively you could create, deliver, or capture value. It's important to be able to foresee challenges should a part of your business model fail because it helps you find solutions and change your business model before problems arise.`
    );
  }

  return ret;
};
