import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A2 === false && A3) {
    ret.push(
      `Good choice of energizer. It will undoubtedly help you stimulate your participants before the ideation session.`
    );
  }

  if (A1 && A2 === false) {
    ret.push(
      `While you did submit an energizer as stated, you did not describe it very well. We asked you to do this so that you can assess better if this is the best energizer for your ideation session. Furthermore, it will also help you understand it better.`
    );
  }

  if (A2 && A3) {
    ret.push(
      `Great choice of energizer! It seems to be a good fit for your participants and will help you motivate them and make sure that they are in a very active and creative state.`
    );
  }

  if (!Z0 && !Z1 && A1 && A3 === false) {
    ret.push(
      `Are you sure this energizer is the best choice for your ideation session? It seems to us that it is not very stimulating. We advise you to reconsider or at least adapt your energizer to make sure that it will help put your participants in the right mood. Don' forget that the goal of any energizer is to motivate and put your participants in a state of high creativity before your brainstorming sessions.`
    );
  }

  return ret;
};
