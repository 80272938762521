import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A0 === false) {
    ret.push(`No picture uploaded.`);
  }

  if (A0 && A1 === false && A2 === false) {
    ret.push(`Thank you for uploading your cluster-wall`);
  }

  if (A1 === false && A0) {
    ret.push(
      `While you did upload a picture of your wall, we could not identify your clusters' structure. Ideally, we would have liked to see your learning notes (either in paper or digital form) clustered together by topic or finding. Ensuring that the clustering is very clear, will make it easier for your whole team (and even outsiders) to understand your process.`
    );
  }

  if (A0 && A1 && A2) {
    ret.push(
      `Thank you for uploading a picture of your cluster wall. The clustering of your findings is very clear, and we can see that the cluster titles and summaries were included directly on your wall. Well done!`
    );
  }

  if (A0 && A1 && A2 === false) {
    ret.push(
      `Thank you for uploading a picture of your cluster wall. The clustering of your findings is very clear and logical. However, we could not see the cluster titles and summaries for each of your clusters. We recommend you to write those directly at your cluster wall (and next to the cluster itself) from now on. This makes the final solution very clear for all your team and ensures that everyone agrees on the clusters and their summaries.`
    );
  }

  return ret;
};
