import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Great job in stating all the users that will be part of this experience. When there are multiple users part of an experience, it's important to consider the experiences of all users and not just one to ensure experience design is effective.`
    );
  }

  if (A2) {
    ret.push(
      `Nice work identifying two users for the experience. While it's suitable to consider two users, it's helpful to consider further if there are secondary users that also take part in the experience. There may be multiple people involved who play a small role but still contribute to the experience. In the future, especially with larger experience, we recommend taking some time to identify any additional users that may be part of the experience.`
    );
  }

  if (A3) {
    ret.push(
      `Good job in identifying the user for the experience. Although you have stated only one user, I would like you to rethink if your user interacts with others throughout the experience. While focusing on a single user may seem like a good idea, it may not provide a complete view of the customer experience.  It may be helpful to broaden your focus and gather input from a diverse group of customers to get a more comprehensive view of the journey. This will lead to more well-rounded and effective solutions.`
    );
  }

  if (A5) {
    ret.push(
      `Some of the users do not seem to be relevant to the experience. They might be interacting with other users before or after the experience but are not directly relevant during it.`
    );
  }

  if (A6) {
    ret.push(
      `Unfortunately, none of the users seem to be relevant to the experience. They might be interacting with other users before or after the experience but are not directly relevant during it.`
    );
  }

  return ret;
};
