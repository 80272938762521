import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { B1 } = getSelectedOptionsFlags(selectedOpts);

  if (B1) {
    ret.push("Thank you for sharing this additional file with us.");
  }

  return ret;
};
