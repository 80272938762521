import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`We could not find any submission for this exercise. However, we still think it is important for you to conduct this exercise and understand why it is relevant.
An assumption is something that you or your team believe to be correct and can therefore be used to take decisions. Often, however, these assumptions are wrong and lead to poorly designed services or products. This is why we invest so much time into extracting all these assumptions you have and deciding which ones should be validated through user research.
Writing down the most relevant assumptions is important because it helps you focus on the ones that have the highest impact. The validation difficulty is useful because it allows you to distinguish between those that are easily verifiable and those that should be central to your user research (as they are much harder to validate). Therefore, it is important to select at least five relevant assumptions from all the ones you came up with within exercise 1.3 and state their validation difficulty.`);
  }

  if (Z1) {
    ret.push(`All your assumptions are relevant, correctly stated  and include a validation difficulty. Good job!
Identifying your assumptions this way will help you and your team understand what you know for certain and what is still unknown regarding your project. This will help you define a good problem statement and therefore design a better user research experiment.
More importantly, adding a validation difficulty is useful because it allows you to distinguish between those assumptions that are easily verifiable (and should therefore be verified through easier processes like surveys or literature research) and those that should be central to your user research (as they are much harder to validate). `);
  }

  if (Z2) {
    ret.push(`Great submission! Your solution went beyond the scope of what we expected for this exercise. All your assumption were well explained, included a difficulty to validate and were correctly stated.
Identifying your assumptions now will help you and your team understand what you know for certain and what is still unknown regarding your project. This will help you define a good problem statement and therefore design a better user research experiment. `);
  }

  return ret;
};
