import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for your comments!
We are happy to hear how you enjoyed this experience and has made so many learnings! We hope that you will enjoy the next modules even more.`
    );
  }

  if (A2) {
    ret.push(`Thank you for your thoughts about this whole experience!
We really appreciate you taking the time to explain what you did not enjoy so much.
We have taken your comments to heart and will try to act on them so that future cohorts have a better learning journey with us.
We truly hope that you will enjoy the next modules more and thank you again for your help and honesty!`
    );
  }

  if (A3) {
    ret.push(`Thank you for your thoughts about this whole experience!
We really appreciate you taking the time to explain both what you enjoyed as well as what you did not enjoy so much.
We have taken your comments to heart and will try to act on them so that future cohorts have a better learning journey with us.
We truly hope that you will enjoy the next modules more and thank you again for your help and honesty!`
    );
  }


  /*
    { id= "A1", text= "Only positive comments", points= 0 },
    { id= "A2", text= "Only negative comments", points= 0 },
    { id= "A3", text= "Mix of comments", points= 0 },
    { id= "A4", text= "Poor reflection", points= 1},
    { id= "A5", text= "Good reflection", points= 2},
    { id= "A6", text= "Great reflection", points= 4},
  */

  return ret;
};
