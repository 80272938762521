import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Good work investigating both of the business models and clearly describing the possible conflict you discovered may arise with consideration of how different elements of the business models may overlap or competition for resources. You demonstrated in-depth understanding of both business models and the conflicts which is important to devise a strategy to effectively manage the two business models in tandem.`
    );
  }

  return ret;
};
