import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`);
  }

  if (Z1) {
    ret.push(
      `Well done!
You have submitted a good number of protocols and more importantly, they are all detailed and clear.
Doing so will help you access this data in the future with ease and save you and your team time, as well as ensuring that no relevant information is lost.`
    );
  }

  return ret;
};
