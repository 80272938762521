import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  /*
{id = "A1", text = "3 or more risky assumptions stated", points = 2},
{id = "A2", text = "1 or 2 risky assumptions stated", points = 1},
{id = "A3", text = "0 assumptions stated", points = 0},
{id = "#2", text = "----------------------- Additional stuff (optional) ---------------------------", points = 0},
{id = "A4", text = "Assumptions are not really assumptions", points = -0.5},
{id = "A5", text = "Assumptions are not well stated", points = -0.5},
*/
  if (A1) {
    ret.push(
      `Good work identifying several risky assumptions involved in your project. Identifying risky assumptions will help you build a robust risk management and decision-making strategy.`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for identifying those risky assumptions. We recommend you try to identify even more assumptions to ensure you can build a robust risk management strategy. Identifying and validating assumptions is essential to de-risking decision in innovation`
    );
  }

  if (A3) {
    ret.push(
      `We recommend you try to identify assumptions to ensure you can build a robust risk management strategy. Identifying and validating assumptions is essential to de-risking decisions in innovation.`
    );
  }

  if (A4 && A1) {
    ret.push(`However, your assumptions are not stated as assumptions. An assumption is something that you believe to be correct and can therefore be used to take decisions. Often a good way to define an assumption is to infer some relationship between two objects. $
Here are a few examples of such assumptions:
- "If we increase our marketing budget, our sales will rise"
- "These new features added value for our clients"
- "Producing more sustainable products will reduce our profit margin"

Try to write assumptions in the style seen above in te future.`);
  }

  if (A4 && (A2 || A3)) {
    ret.push(`Furthermore, your assumptions are not stated as assumptions. An assumption is something that you believe to be correct and can therefore be used to take decisions. Often a good way to define an assumption is to infer some relationship between two objects. $
Here are a few examples of such assumptions:
- "If we increase our marketing budget, our sales will rise"
- "These new features added value for our clients"
- "Producing more sustainable products will reduce our profit margin"

Try to write assumptions in the style seen above in te future.`);
  }

  if (A5 && (A4 || A2 || A3)) {
    ret.push(
      `Additionally, your assumptions are not well stated. They do not imply a relevant dependency that, if validated, would help you make decisions in the future.`
    );
  }

  if (A5 && A1) {
    ret.push(
      `However, your assumptions are not well stated. They do not imply a relevant dependency that, if validated, would help you make decisions in the future.`
    );
  }

  return ret;
};
