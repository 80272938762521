import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`To get your listeners interested directly, you started with a bang.`);
  }

  if (A2) {
    ret.push(`You have included and described the user of your solution.`);
  }

  if (A9) {
    ret.push(`The problem is clear and understandable.`);
  }

  if (A3) {
    ret.push(`You explained the solution to tackle the problem.`);
  }

  if (A4) {
    ret.push(`Additionaly, you shared the value your soultion creates.`);
  }

  if (A5) {
    ret.push(`You end up with a clear and actionable call to action to the audience. Well done!`);
  }

  if (A7) {
    ret.push(`Well done! Your pitch is coherent and convincing!`);
  }

  if (A8) {
    ret.push(`It is easy to understand as it avoids confusing or unfamiliar industry jargon.`);
  }

  if (Z0 === false && Z1 === false && !A6) {
    ret.push(
      `Overall, your pitch ended up being quite long. Do you see any possibilities to shorten it and stay within 100 words? `
    );
  }

  if (Z0 === false && Z1 === false && !A7) {
    ret.push(
      `Unfortunatly, your pitch is not very convincing overall. This can have several reasons - like a missing storyline, your choice of vocaulary, or the content itself. Try it out with different poeple and ask them to give you feedback!`
    );
  }

  if (Z0 === false && Z1 === false && !A8) {
    ret.push(
      `Your pitch might be difficult to understand to an outsider, as it uses unfamiliar industry jargon. Although this might not be an issue for certain audiences, language like this should be avoided if it involves external listeners.`
    );
  }

  if (Z0 === false && Z1 === false && A1 === false) {
    ret.push(
      `Your pitch has a weak start, and your "bang" does not create attention. Can you improve it? You could start with an interesting fact, a questions, a situation your listener can relate to, etc. Get creative!`
    );
  }

  if (Z0 === false && Z1 === false && !A2) {
    ret.push(
      `From your description, it is not exaclty clear who your user it. Try to include it or be more specific!`
    );
  }

  if (Z0 === false && Z1 === false && !A9) {
    ret.push(
      `The pitch does not introduce a problem that is understandable. To improve, share your problem statement with any person and try out different versions until they are convinced.`
    );
  }

  if (Z0 === false && Z1 === false && !A3) {
    ret.push(
      `How will you solve the problem you introduced. This is currently not clear to the audience. This does not mean that there is no solution, but that you can rephrase it and communicate is clearer. `
    );
  }

  if (Z0 === false && Z1 === false && !A4) {
    ret.push(`From your pitch, it is not clear what value you provide through your solution. `);
  }

  if (Z0 === false && Z1 === false && !A5) {
    ret.push(
      `A pitch can act as a tool to get things moving. Give your audience a clear call to action based on what you expect from them.`
    );
  }

  return ret;
};
