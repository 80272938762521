import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "A0", text = "Feedback not submitted", points = 0},
    {id = "#0", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ", points = 0},
    {id = "A1", text = "Feedback not detailed", points = 0.25},
    {id = "A2", text = "Feedback somwhat detailed", points = 0.75},
    {id = "A3", text = "Feedback is detailed", points = 1.5},
    {id = "#0", text = "----- -----  If necessary, Pick things that must be improved ----- ----- ", points = 0},
    {id = "A4", text = "Not enough comments about what worked well", points = -0.1},
    {id = "A5", text = "Not enough comments about what could be improved", points = -0.1},
    {id = "A6", text = "Not enough comments about open questions", points = -0.1},
    {id = "A7", text = "Not a single new ideas/changes", points = -0.1},
    */
  if (A0) {
    ret.push(`No feedback submitted.`);
  }

  if (A1) {
    ret.push(
      `Thank you for sharing the summary of your first feedback session.
However, your submission is not detailed enough.
To design customer-centric solutions it is essential to work closely with your users and note down their feedback as your idea progresses.
The learnings and insights learned in these sessions can help you build truly valuable solutions.
Therefore, we ask you to spend more time gathering and recording your feedback in the future.
Below you will find some tips on what to improve.`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for sharing the summary of your first feedback session.
However, to make the most of these feedback sessions, you should try to record the feedback received with more detail.

To design customer-centric solutions it is essential to work closely with your users and note down their feedback as your idea progresses.
The learnings and insights learned in these sessions can help you build truly valuable solutions.
Therefore, we ask you to spend more time gathering and recording your feedback in the future.
Below you will find some tips on what to improve.`
    );
  }

  if (A3) {
    ret.push(
      `We are happy to see that you have made several notes stating what worked, what could be improved, and open questions that popped up!
These learnings and insights learned in these sessions will help you build truly valuable solutions.
Now it is important to sit down and use these findings to change and improve your idea.
Well done!`
    );
  }

  if (!Z0 && !Z1 && A4) {
    ret.push(
      `You did not include enough notes regarding what worked well.
Was the whole prototype a failure?
Try to recall your session and think if there isn't at least one thing that went well. Identifying this will help you keep good elements and focus on those that must change.`
    );
  }

  if (!Z0 && !Z1 && A5) {
    ret.push(
      `Aren't there any improvements that you could make?
Your report includes very few issues or possible improvements.
Please take some time to reflect again on the feedback session you had (if possible, go over your notes or recording) and think if there isn't anything you could do to improve the prototype in its next iteration according to this user.
If there really wasn't any negative comments, then is it possible that your test was not designed well enough?
In this case you should try to redesign your test so that you can focus on another key element of your idea.`
    );
  }

  if (!Z0 && !Z1 && A6) {
    ret.push(
      `Weren't there any open questions after your feedback session?
Maybe some functionality that was unclear or something that might be superfluous in your prototype.
You don't have to have open questions after your sessions, but it is very common that you may have an idea or hypothesis that still needs to be validated. And it is a good idea always to write those down, as they can help you build even better products. Therefore, we advise you to go over your notes/recordings and think if there is anything you might have missed.`
    );
  }

  if (!Z0 && !Z1 && A7) {
    ret.push(
      `You did not write down any possible new features or ideas.
This is not a necessity, but it is common that people have new ideas when they are confronted with constructive feedback. To make sure that these are not lost after the feedback session, it is always a good idea to write them down immediately. Therefore, we would just like to remind you to pay attention to these ideas as they may disappear just as fast as they show up. `
    );
  }

  return ret;
};
