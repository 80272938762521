import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A0) {
    ret.push(
      `You have identified very few assumptions and should try to identify many more (at least 20).
We are not trying to be annoying here, but in order to innovate it is important to extract and understand all the things you believe to be true (aka your assumptions).
Most of them are most likely true, however, some of them might be false. Identifying and validating these false assumptions (what you will do in module 3) can help help you come up with new solutions that create more value to your users!
This is why it is important to extract as many assumptions as possible at this stage.`
    );
  }
  if (A1) {
    ret.push(
      `You have identified very few assumptions and should try to identify many more (at least 20).
We are not trying to be annoying here, but in order to innovate it is important to extract and understand all the things you believe to be true (aka your assumptions).
Most of them are most likely true, however, some of them might be false. Identifying and validating these false assumptions (what you will do in module 3) can help help you come up with new solutions that create more value to your users!
This is> why it is important to extract as many assumptions as possible at this stage.`
    );
  }

  if (A2) {
    ret.push(
      `You have identified a decent number of assumptions.
However, to make the most out of your projects, we advise you to look for even more!

We are not trying to be annoying here, but in order to innovate it is important to extract and understand all the things you believe to be true (aka your assumptions).
Most of them are most likely true, however, some of them might be false. Identifying and validating these false assumptions (what you will do in module 3) can help help you come up with new solutions that create more value to your users!
This is why it is important to extract as many assumptions as possible at this stage.`
    );
  }

  if (A3) {
    ret.push(`Good job! You have identified a good number of assumptions.

In case you are wondering why we asked you to do this, here is a brief explanation:
In order to innovate it is important to extract and understand all the things you believe to be true (aka your assumptions).
Most of them are most likely true, however, some of them might be false. Identifying and validating these false assumptions (what you will do in module 3) can help help you come up with new solutions that create more value to your users!
This is why it is important to extract as many assumptions as possible at this stage.`);
  }

  if (A4) {
    ret.push(
      `Well done! You have identified a great number of assumptions!

In case you are wondering why we asked you to do this, here is a brief explanation:
In order to innovate it is important to extract and understand all the things you believe to be true (aka your assumptions).
Most of them are most likely true, however, some of them might be false. Identifying and validating these false assumptions (what you will do in module 3) can help help you come up with new solutions that create more value to your users!
This is why it is important to extract as many assumptions as possible at this stage.`
    );
  }

  if (A5 && (A3 || A4)) {
    ret.push(
      `Lastly, thank you for submitting all of your assumptions!
We appreciate you taking the time to do so instead of just stating the number of assumptions generated as asked.
Doing so helps us understand and analyze your work better.`
    );
  }

  if (A5 && (A0 || A1 || A2)) {
    ret.push(
      `However, thank you for submitting all of your assumptions!
We appreciate you taking the time to do so instead of just stating the number of assumptions generated as asked.
Doing so helps us understand and analyze your work better.`
    );
  }

  return ret;
};
