import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Exceptional work! You went above and beyond in crafting an implementation plan that is thoughtful, realistic, and includes measures for success that draw on the concepts and insights from the modules. You created an implementation plan that reduces risk by effectively anticipating and responding to challenges. You demonstrated an in-depth understanding not only of the strategy you chose to pursue in managing your business models, but also a firm grasp on concepts from the modules that shows you proficient.`
    );
  }

  return ret;
};
