import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B0, B1, B2, B3, B4, B5, B6, B7, B8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (B0) {
    ret.push(
      `You did not answer why this insight is relevant for your project.
This question will help you assess how relevant this insight is for your project.`
    );
  }

  if (B1) {
    ret.push(
      `While you did briefly mention that this insight is relevant, you did not elaborate on that.
Why exactly is this insight relevant?
Try to give possible examples of how it can affect your project or business model. `
    );
  }

  if (B2) {
    ret.push(
      `Your answer makes it clear why this insight is relevant.
Ideally, you could also give concrete examples of what effects this insight could have (make sure they are actionable).
However, your submission is already good.`
    );
  }

  if (B3) {
    ret.push(
      `Your argumentation as to why this insight is relevant is detailed and clear. Well done!
Being able to showcase the value of your insights in a simple and clear way will help you win over any stakeholders that might doubt the effectiveness of such a deep user research.`
    );
  }

  if (B4) {
    ret.push(
      `Well done! Your argumentation as to why this insight is relevant is very detailed and clear.
You gave a thorough explanation and included examples to illustrate how this insight could affect your project.
Being able to showcase the value of your insights in a simple and clear way will help you win over any stakeholders that might doubt the effectiveness of such a deep user research.`
    );
  }

  return ret;
};
