import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13 } =
    getSelectedOptionsFlags(selectedOpts);
  /*
    { id= "A0", text= "No submission", points= 0 },
    { id= "A1", text= "Feedback for first idea stated", points= 0.5 },
    { id= "A2", text= "Feedback is well described and clear", points= 1 },
    { id= "A3", text= "Good submission", points= 1 },
*/
  if (A0 && !A12) {
    ret.push(`No feedback submitted.`);
  }

  if (A1 && !A12) {
    ret.push(`Thank you for submitting this summary of the feedback you received from your first user.
However, to ensure that all important details are captured, it would be beneficial to provide a more comprehensive description in the future. It is important to record all learnings from direct user interactions accurately.`);
  }

  if (A2 && !A12) {
    ret.push(`Thank you for submitting this summary of the feedback you received from your first user.
Your feedback is clear and concise and should help you summarize most of your learnings from this session.
However, your answer could have been more detailed and include more learnings from your user feedback sessions.
The insights and comments shared by your users at this stage are vital for the further development of your ideas. This is why it is important to record and summarize them well.`);
  }

  if (A3 && !A12) {
    ret.push(`Well done! You have recorded your learnings and summarized your feedback session very well.
The insights and comments shared by your users at this stage are vital for the further development of your ideas.
Now it will be your job to address these learnings before you start prototyping. `);
  }

  if (A12) {
    ret.push(`Thank you for submitting a piece of the feedback you received from your first user.
However, ideally, after talking to your participant for several minutes, we would expect you to make more learnings than just those included in this quote.

In the future, you should try to include more details and learnings from your user feedback sessions.
The insights and comments shared by your users at this stage are vital for the further development of your ideas. This is why it is important to record and summarize them well.`);
  }

  if (A10 && !A11) {
    ret.push(`Furthermore, it is important to note that feedback for both of your ideas was requested, but you have only provided feedback for one. As we have not yet selected any of the ideas, it is crucial to gather feedback for all of them at this stage. Please keep this in mind moving forward.`);
  }

  if (A13 && !A12) {
    ret.push(`However, in the way it is stated it is hard to evaluate how each idea was perceived by this user.
In the future, make sure to clearly differentiate the feedback for each of your ideas. This will also help you ensure no information is lost.`);
  }

  return ret;
};
