import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const {
    Z0,
    Z1,
    Z2,
    Z3,
    A0,
    A1,
    A2,
    A3,
    A4,
    A5,
    A6,
    A7,
    A8,
    A9,
    A10,
    A11,
    A12,
    A13,
    A14,
    A19,
    A20,
    A21,
    A22,
    A23,
    A24,
    A25,
  } = getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "A0", text= "ALWAYS CHECK THIS BOX", points= 8 },
    {id = "#11", text = "----- ----- ----- ----- user ----- ----- ----- ----- ----- ", points = 0},
    {id = "A1", text= "User clear", points= 0 },
    {id = "A2", text= "User not detailed enough", points= -1 },
    {id = "A3", text= "User not included", points= -1.5 },
    {id = "#12", text = "----- ----- ----- ----- problem ----- ----- ----- ----- ----- ", points = 0},
    {id = "A4", text= "Problem clear", points= 0 },
    {id = "A5", text= "Problem not detailed enough", points= -1 },
    {id = "A24", text= "Problem feels too generic", points= -1 },
    {id = "A23", text= "Included several smaller problems in the problem statement", points= -1 },
    {id = "A11", text = "BIKING challenge: Problem statement tackles a superficial need (not the end goal. E.g.: 'How can we help our users prepare better for their ride?')", points = -1.5},
    {id = "A19", text = "COMMUNICATION challenge: Problem statement tackles a superficial need (not the end goal. E.g.: 'How can we help our users define their tasks better?')", points = -1.5},
    {id = "A12", text = "Problem addressed does not seem very relevant", points = -1.5},
    {id = "A6", text= "Problem not included", points= -1.5 },
    {id = "#13", text = "----- ----- ----- ----- scope ----- ----- ----- ----- ----- ", points = 0},
    {id = "A7", text = "Scope of the overarching question is good", points = 0},
    {id = "A8", text = "Scope too broad", points = -0.75},
    {id = "A9", text = "Scope too specific (answer suggested)", points = -0.75},
    {id = "#10", text = "----- ----- ----- ----- Insight ----- ----- ----- ----- ----- ", points = 0},
    {id = "A13", text = "No insight/constrained included", points = -1},
    {id = "A14", text = "Insight doesn't really fit or add value", points = -1},
    {id = "#21", text = "----- ----- ----- ----- Include an example if they did not do great ----- ----- ----- ----- ----- ", points = 0},
    {id = "A20", text = "Example for the R&D challenge", points = 0},
    {id = "A21", text = "Example for the Bicycle challenge", points = 0},
    {id = "A22", text = "Example for the Expense Management challenge", points = 0},
    {id = "#17", text = "----- ----- ----- ----- Correct score ----- ----- ----- ----- ----- ", points = 0},
    {id = "A15", text = "Subtract 1 point", points = -1},
    {id = "A16", text = "Subtract 1 point", points = -1},
    {id = "A17", text = "Subtract half a point", points = -0.5},
    {id = "A18", text = "Add 1 point", points = 1},
    */

  /* Negative feedback */

  if (scoredPoints < 8 && !Z0 && !Z1 && !Z2) {
    ret.push(
      `Thank you for submitting your problem statement.
This is one of the trickier concepts to master, but also one of the most important steps to define your problem before moving on to ideation. Therefore, we ask you to rework your problem statement taking into account our feedback below.`
    );
  }

  if (A2) {
    ret.push(
      `Your target user should be more specific. Rather than a general user, we suggest using the persona you created earlier in this assignment as your user.
The more specific your user is, the easier it will be to develop effective solutions for their unique problems. `
    );
  }

  if (A25) {
    ret.push(
      `Your target user should be more specific. 
Rather than focusing on your two main user segments (R&D and Sales/Marketing teams), it would be better to focus on one of these teams only (the one that suffers the most from this poor communication according to your interviews).
On top of that, we also advise you to then also focus on specific individuals instead of the whole team. Ideally, you should try to use the persona you've created above.
This is because the more specific your user is, the easier it will be to develop effective solutions for their unique problems. It will also make all the subsequent steps in your project much more effective.`
    );
  }

  if (A3) {
    ret.push(
      `You did not include a target user to your problem statement.
A good problem statement should always include your target user. Rather than a general user, we suggest using the persona you created earlier in this assignment as your user.
The more specific your user is, the easier it will be to develop effective solutions for their unique problems.`
    );
  }

  if (A5 || A6) {
    ret.push(
      `You did not made it clear enough what problem you are going to tackle.
Focus is key when it comes to innovation. If you can't pinpoint what problem you want to tackle, then it will be hard to find solutions for it.
In the future, make sure to state the problem you want to tackle more clearly.`
    );
  }

  if (A24) { /* Problem feels too generic */
    ret.push(
      `We must say that the problem shared in your problem statement seems slightly too generic.
After conducting your interviews, learning the realities of several users and other stakeholders and creating personas that represent these different archetypes, you should be able to define a very tangible and real problem for one of your personas.
This problem should be very specific and focused on your persona.
If you can't pinpoint what problem you want to tackle, then it will be hard to find effective (and desirable) solutions for it.
Therefore, in your future projects, make sure to define the problem you want to tackle in a way that is very focused and objective. The more practical it is, the better.`
    );
  }

  if (A12) { /* Problem addressed does not seem very relevant */
    ret.push(
      `Is the problem presented in your problem statement really the most pressing issue you have identified in your user research?
The problem statement is the main guide for the next stages of your innovation project. If the problem you are planning to tackle is not a truly relevant painpoint to your users, then you should not pursue it further.
However, because we did not participate in your interviews and team meetings, we can't know if this is the case. 
If you truly believe that this is the most relevant user need identified in your research, then please disregard our comment above and go on. If, on the other hand, you believe that we may be correct, then please take some time to re-evaluate your problem statement.
One simple way to investigate if your problem statement is addressing a relevant need, is to ask yourself (or a colleague) if they would be willing to invest in a company that has a solution to this problem.
If the answer is not a resounding yes, then you should go back to your user discovery and look for a more relevant problem.`
    );
  }

  if (A11 && !A5 && !A6 && !A23 && !A24) { /* BIKING challenge: Problem statement tackles a superficial need */
    ret.push(
      `The problem you are addressing in your problem statement seems to be a superficial need.
After conducting your interviews, learning the realities of several users and other stakeholders and creating personas that represent these different archetypes, you should be able to define a very tangible and real problem for one of your personas.
This problem should be very specific and focused on your persona.

Here is one way you can determine what the true problem behind your problem statement is:
Ask yourself if the problem you are addressing is really the final goal of your user or if it is just an enabler for something else.
For example, something like "How might we help our users prepare better for their ride" is not really focusing on an underlying need. What effect should 'being better prepared' have? It could be that they currently don't feel safe or that they are afraid of having technical issues during their bike rides (malfunctioning breaks or punctured tyres).
Make sure to focus on the ultimate effect you want to have.`
    );
  }

  if (A19 && !A5 && !A6 && !A23 && !A24) { /* COMMUNICATION challenge: Problem statement tackles a superficial need */
    ret.push(
      `The problem you are addressing in your problem statement seems to be too generic.
After conducting your interviews, learning the realities of several users and other stakeholders and creating personas that represent these different archetypes, you should be able to define a very tangible and real problem for one of your personas.
This problem should be very specific and focused on your persona.

Here is one way you can determine what the true problem behind your problem statement is:
Ask yourself if the problem you are addressing is really the final goal of your user or if it is just an enabler for something else.
For example, something like "How can we improve the communication" is not really focusing on an underlying need. What problem is this "poor communication" causing? Did your users miss a deadline because of that? Or have relevant user insights been ignored during the development of a solution?
Make sure to focus on a clear problem that was identified in your user research.`
    );
  }

  if (A23) { /* Included several smaller problems in the problem statement */
    ret.push(
      `Additionally, it seems that you have included several problems that you would like to address in your problem statement.
While this is not necessarily wrong, we advise you to focus on the main painpoint identified in your user research. If you can't pinpoint what problem you want to tackle, then it will be hard to find effective solutions for it.`
    );
  }

  if (A8) {
    ret.push(
      `You should narrow the scope of your problem. Right now, it is too wide and abstract, which will make it hard to focus on while ideating.
We suggest looking back at the user needs you identified for your persona and choosing one of them to focus on. You could also look at a specific part of the user journey.
Just be careful not to make it too specific. Your problem statement should not include a solution already!`
    );
  }

  if (A9) {
    ret.push(
      `Your problem statement is built around a solution.
The most important criteria for a  problem statement is that it should be centered around the main problem you discovered during your user research. Therefore, please make sure that your problem statement focuses on a problem and not on a solution. Including a possible solution now will narrow and constrain your ability to come up with different solutions.
Because of this, please make sure to adapt your problem statement before continuing. You don't have to submit it again, just make sure to focus on the problem you discovered instead of thinking of possible solutions. You will have plenty of time to think of solutions later.`
    );
  }

  if (A13) {
    ret.push(
      `Additionally, your problem statement should include a specific insight from your user research.
What have you learned about this problem that makes it less obvious? What was a specific pain point your user shared that you would like to address?
You have spent a decent amount of time researching this problem and your users, you should have something that makes this problem unique to them.

A helpful way to evaluate a problem statement is "Could I have come up with this statement without talking to users?" – If the answer is yes, you should rework your problem statement. Go back to the insights you identified from user research, choose the most interesting or important one, and build your problem statement around that.`
    );
  }

  if (A14) {
    ret.push(
      `Additionally, the insight you included does not really build onto the central problem of your problem statement.
How does this insight help you understand and tackle the problem you just mentioned? It seems to us that they are unrelated.
You have spent a good amount of time researching this problem and your users. Make sure that the insight you include in your problem statement adds significant value and helps you understand this problem better.`
    );
  }

  if (A0 && scoredPoints < 7.5 && !Z0 && !Z1 && !Z2) {
    ret.push(
      `Otherwise you have done a great job including all the necessary elements.

For future reference, here are some tips on how to define great problem statements.
A great problem statement should make it very clear what problem you want to tackle, who currently struggles with this problem (your target user) and why this problem is relevant or why it can't be solved easily (your insight/constraint).
Furthermore, it is important to pay attention to the scope of your problem statement. It needs to be focused, but it should not point towards a possible solution already.
Lastly, it is vital that your problem statement tackles a relevant problem. Imagine you are an investor, would you invest in a company that solves the problem you are presenting here? The answer should be a definite yes. If not, go back to your notes and look for a more relevant problem.`
    );
  }

  if (A0 && (scoredPoints === 8 || scoredPoints > 8) && !Z0 && !Z1 && !Z2) {
    ret.push(
      `This is a clear and well written problem statement.
You have included all the necessary parts (persona, need, constraint) and set a good scope (not too broad, which makes it hard to focus on your issue; nor too specific, which can constrain the possible solutions).
Well done!

Still, here is a brief refresher on how to write good problem statements, just in case:
A great problem statement should make it very clear what problem you want to tackle, who currently struggles with this problem (your target user) and why this problem is relevant or why it can't be solved easily (your insight/constraint).
Furthermore, it is important to pay attention to the scope of your problem statement. It needs to be focused, but it should not point towards a possible solution already.
Lastly, it is vital that your problem statement tackles a relevant problem. Imagine you are an investor, would you invest in a company that solves the problem you are presenting here? The answer should be a definite yes. If not, go back to your notes and look for a more relevant problem.`
    );
  }

  if (A20) {
    ret.push(
      `Here's an example of a great problem statement for a similar challenge:
"How might we help Sam the Top Seller, to feel motivated to communicate valuable insights from sales calls to R&D without him thinking that 'they will just get lost and nothing will change.'"
It follows the format: "How might we help" + [Persona] + "to" + [Need identified in user research] + [Specific pain point/insight identified in user research]
Using this format can be helpful for narrowing your scope and making sure to stay focused on your users.`
    );
  }

  if (A21) {
    ret.push(
      `Here's an example of a great problem statement for a similar challenge:
"How might we help Dan the Daily Commuter, to turn his daily commute to the office from something that has to be done to one of the highlights of his day, even though he lives in a large city and is 'often worried about his own safety while riding next to moving cars'?
It follows the format: "How might we help" + [Persona] + "to" + [Need identified in user research] + [Specific pain point/insight identified in user research]
Using this format can be helpful for narrowing your scope and making sure to stay focused on your users.`
    );
  }

  if (A22) {
    ret.push(
      `Here's an example of a great problem statement for a similar challenge:
"How might we help Charlie the consultant, to get her expenses approved without having to 'fill out useless forms that feel like a waste of time?'"
It follows the format: "How might we help" + [Persona] + "to" + [Need identified in user research] + [Specific pain point/insight identified in user research]
Using this format can be helpful for narrowing your scope and making sure to stay focused on your users.`
    );
  }

  /* BELLOW YOU WILL FIND THE OLD CRITERIA AND THE OLD FEEDBACK

{id = "#11", text = "----- ----- ----- ----- Pick all that apply ----- ----- ----- ----- ----- ", points = 0},
    {id = "A1", text= "User very clear", points= 1 },
    {id = "A2", text = "Problem is well defined", points = 1},
    {id = "A4", text = "Scope of the overarching question is good", points = 1},
    {id = "A9", text = "Scope too broad (Only if A4 is false)", points = -0.5},
    {id = "A10", text = "Scope too specific (Only if A4 is false)", points = -0.5},
    {id = "#9", text = "----- ----- ----- ----- Pick One below ----- ----- ----- ----- ----- ", points = 0},
    {id = "A6", text = "Problem statement focuses on a relevant need", points = 1},
    {id = "A7", text = "Problem statement focuses on a 'superficial' need", points = 0.5},
    {id = "#10", text = "----- ----- ----- ----- Specific Feedback ----- ----- ----- ----- ----- ", points = 0},
    {id = "A14", text = "No insight/constrained included", points = -1},
    {id = "A11", text = "Insight doesn't really fit or add value", points = -1},
    {id = "A12", text = "More than one problem statement submitted", points = 0.1},
    {id = "#12", text = "----- ----- ----- ----- Alternative Feedback (Cancels all other feedback. Please give points with criteria above) ----- ----- ----- ----- ----- ", points = 0},
    {id = "A13", text = "Good Problem statement but did not use persona. Used a generic user instead (how can we help workers...)", points = -0.25},


  if (A12 && !A13) {
    ret.push(
      `You have stated more than just one problem statement.
It is important to pick one as you move on, so that you can focus your work towards this one clear problem.
Still, below you will find our feedback for your problem statements.`
    );
  }


  if (A1 && A2 === false && !A13) {
    ret.push(`You have made it clear who your target user is.
As we are attempting to design human-centric products and services, it is important to always make it clear who your user is so that you can focus on their needs and frustrations. Good job doing that!`);
  }

  if (!Z0 && !Z1 && A1 === false && !A13) {
    ret.push(`You did not state clearly who your target user is.
As we are attempting to design a human-centric product or service, it is important to always make it clear who your user is so that you can focus on their needs and frustrations.
At this stage of the process, we are trying to identify these user groups and their underlying needs. So we highly advise you to focus on your target user in your problem statement.`);
  }


  if (A2 && A1 === false && !A13) {
    ret.push(`You have made it clear what your objective is.
However, you did not state clearly who your target user is.
As we are attempting to design a human-centric product or service, it is important to always make it clear who your user is so that you can focus on their needs and frustrations.
At this stage of the process, we are trying to identify these user groups and their underlying needs. So we highly advise you to focus on your target user in your problem statement.`);
  }

  if (A2 && A1 && !A13) {
    ret.push(`You have made your target user clear and have stated what your objective regarding them is.
As we are attempting to design human-centric products and services, it is important to always make it clear who your user is so that you can focus on their needs and frustrations.
Good job doing that!`);
  }

  if (!Z0 && !Z1 && A1 && A2 === false && !A13) {
    ret.push(
      `On the other hand, while you have clearly stated your target users, you did not define your objective clearly.
It is important to be specific and clear about what it is that you want to achieve. Next time, you should try to state the goal of your problem statement as straightforward as possible.`
    );
  }

  if (!Z0 && !Z1 && A1 && A2 === false && !A13) {
    ret.push(
      `Furthermore, while you have clearly stated your target users, you did not define your objective clearly.
It is important to be specific and clear about what it is that you want to achieve. Next time, you should try to state the goal of your problem statement as straightforward as possible.`
    );
  }



  if (A4 && (A1 || A2) && !A13) {
    ret.push(`Furthermore, you have given your problem statement a good scope.
It is neither too broad (this makes it hard to focus on a specific issue and can hurt the effectiveness of your user research, nor too narrow (This constrains your user research too much and can restrict the number of insights found.
Good job!`);
  }

  if (A4 && !A1 && !A2 && !A13) {
    ret.push(`On the other hand, you have given your problem statement a good scope.
It is neither too broad (this makes it hard to focus on a specific issue and can hurt the effectiveness of your user research, nor too narrow (This constrains your user research too much and can restrict the number of insights found.
  Good job!`);
  }


  if (A6 && (A2 || A1) && !A13) {
    ret.push(`Moreover, your problem statement focuses on a clear need identified in your user discovery. Good job!
Doing so will help you address relevant issues and, possibly, create a lot of value for your users.
This is certainly the most important part of your problem statement and we are glad to see that you have done it well!`);
  }

  if (A6 && !A2 && !A1 && A4 && !A13) {
    ret.push(`Moreover, your problem statement focuses on a a clear need identified in your user discovery. Doing so will help you address relevant issues and, possibly, create a lot of value for your users.
This is certainly the most important part of your problem statement and we are glad to see that you have done it well!`);
  }

  if (A6 && !A2 && !A1 && !A4 && !A13) {
    ret.push(`However, your problem statement focuses on a a clear need identified in your user discovery. Doing so will help you address relevant issues and, possibly, create a lot of value for your users.
This is certainly the most important part of your problem statement and we are glad to see that you have done it well!`);
  }

  if (A7 && !A13 && !A11 && !A14) {

    ret.push(`However, are you sure that the need you are addressing in your problem statement is really the most relevant need identified in your user discovery?
It seems to us like there may be more relevant needs you could tackle in this project.
A good step to identify a need for your problem statement is to ask yourself if, whatever it is you want to achieve with your problem statement, is really a final goal for your user or just an enabler for something else.
For example, something like "How might we help our users prepare better for their ride" is not really focusing on an underlying need.
Instead, you could focus on their need to feel and be safe or to conciliate their work commute with exercises.
Of course, this depends completely on your user discovery. If after all your interviews this was really the most relevant need, then please continue with this problem statement. Otherwise, we ask you to change your problem statement before you continue (no need to change your submission).`);
  }

  if (Z0 && Z1 && !A6 && !A7 && !A13) {

    ret.push(`What need are you addressing in your problem statement?
It seems to us like state a relevant need identified in your user discovery that you want to tackle in this project.

A good step to identify a need for your problem statement is to ask yourself if, whatever it is you want to achieve with your problem statement, is really a final goal for your user or just an enabler for something else.
For example, something like "How might we help our users prepare better for their ride" is not really focusing on an underlying need.
Instead, you could focus on their need to feel and be safe or to conciliate their work commute with exercises.
Of course, this depends completely on your user discovery. If after all your interviews this was really the most relevant need, then please continue with this problem statement. Otherwise, we ask you to change your problem statement before you continue (no need to change your submission).`);
  }

  if (A8 && !A13) {
    ret.push(
      `Your submission is not really an overarching question.
An overarching question should be one question that sums up the goal of your research. It should include the actors or targets, their need or problem and ideally one of your assumptions or insights. Check the M2 Problem Statement method card for more details.`
    );
  }

  if (!A4 && A9 && !A13) {
    ret.push(
      `However, the scope of your overarching question is a bit too broad.
This makes it hard to focus on a specific issue and can hurt the effectiveness of your user research.
Try to reformulate your goal, but this time you should be a little more specific.
Ideally, you should include one of your insights learned in M1, so that you can understand better the needs, issues or barriers your users may have.`
    );
  }

  if (!A4 && A10 && !A13) {
    ret.push(
      `Your problem statement is already suggesting a way to solve the problem identified.
Our suggestion is that you reformulate your problem statement and focus only on the key problem identified and your key insights generated in M1. Do not suggest how to tackle this problem yet.
This will give you more freedom to find innovative solutions during your ideation session!`
    );
  }

  if (A11 && !A14 && !A13 && (A9 || A10)) {
    ret.push(
      `Additionally, it seems to us that the last part of your problem statement is not adding much value.
The insight or constraint (learned during your User Research in M1) included at the end of your Problem Statement should explain why this is an issue or why it is not easy to solve.
As it is right now, your insights does not really do that. It is simply an additional information that doesn't really help you solve that problem.
Therefore, before continuing, please make sure to adapt your problem statement so that it includes some relevant and non-obvious learnings from your User Research.`
    );
  }

  if (A11 && !A14 && !A13) {
    ret.push(
      `However, it seems to us that the last part of your problem statement is not adding much value.
The insight or constraint (learned during your User Research in M1) included at the end of your Problem Statement should explain why this is an issue or why it is not easy to solve.
As it is right now, your insights does not really do that. It is simply an additional information that doesn't really help you solve that problem.
Therefore, before continuing, please make sure to adapt your problem statement so that it includes some relevant and non-obvious learnings from your User Research.`
    );
  }

  if (A14 && !A11 && !A13 && (A9 || A10 || A11)) {
    ret.push(
      `Moreover, it seems to us that you did not add any insight or relevant constraint from your user research to your problem statement.
You should include some explanation as to why this is a problem for your user or why it is so hard to solve this problem.
Therefore, before continuing, please make sure to adapt your problem statement so that it includes some relevant and non-obvious learnings from your user research.`
    );
  }

  if (A14 && !A11 && !A13) {
    ret.push(
      `However, it seems to us that you did not add any insight or relevant constraint from your user research to your problem statement.
You should include some explanation as to why this is a problem for your user or why it is so hard to solve this problem.
Therefore, before continuing, please make sure to adapt your problem statement so that it includes some relevant and non-obvious learnings from your user research.`
    );
  }

  if (A13) {
    ret.push(
      `Thank you for your answer.
All in all your problem statement is well stated and includes all the necessary information you should include in it. The scope of the question is good. You did not make too specific (which can already indicate a certain type of solutions and restrict innovation) nor were you too broad (which makes it hard to focus on a key issue).

However, you did not use your persona as the key user and instead stated a more generic user.
Ideally, it would make sense for you to use your persona as the problem statement is targeted at a need embedded in this persona.`
    );
  }

*/

  return ret;
};
