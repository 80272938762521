import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts, scoredPoints) => {
  const ret = [];
  const { Z0, Z1, D0, D1, D2 } = getSelectedOptionsFlags(selectedOpts);

  if (D0) {
    ret.push(`No feedback submitted`);
  }

  if (D1 && D2 === false) {
    ret.push(`Thank you for submitting this feedback.
However, we believe that you should have been a little bit more detailed to ensure that no information is lost.`);
  }

  if (D2) {
    ret.push(`Thank you for submitting the feedback received from your first user.
You have stated it very clearly.`);
  }

  return ret;
};
