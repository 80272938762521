import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Great job!
You have made it very clear what your idea is all about, for whom you are developing it, and managed to explain it in a very clear way.`
    );
  }

  /*

  Vou deixar essas condiçoes pq nao sei se vai funcionar no 1.2.a.ts sem o !ZX...)

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `Good Answer.`
    )
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `Good Answer.`
    )
  }

  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `Good Answer.`
    )
  }

  */

  return ret;
};
