import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission. 
While identifying themes may look irrelevant, this short and straightforward process can help you identify more assumptions later. It is, therefore, a good idea to take the time to think of at least a few themes. This can lead to more diverse and better insights at the end of your user needfinding research.`
    );
  }

  if (Z1) {
    ret.push(`Great job! You have submitted enough themes and gave a good explanation as to why they are relevant. This is very important as these themes will help you identify several assumptions later on. Additionally, if you later realize through your interviews that a certain theme is not that relevant, or maybe much more relevant than you thought, you will be able to take a look at your notes and see why you rated that theme the way you did.`
    );
  }

  return ret;
};