import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `Giving feedback properly is essential to ensure good team dynamics. Even if you didn't have time for that right now, you should always remember the critical aspects of constructive feedback. Don't forget to check the method cards for a short refreshment every now and then.`
    );
  }

  if (Z1) {
    ret.push(
      `Perfect! All of the pieces of feedback you have submitted are very well written. This is essential as being able to give great feedback will not only help your colleagues improve even further, but can also strengthen your relationship and trust. Keep giving feedback just like that, and you will be a valuable member of your team.`
    );
  }

  return ret;
};
