import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for sharing this piece of feedback.
We will discuss your comments in our team and think of how to best address it.`);
  }

  if (A2) {
    ret.push(`Thank you for your comment.
We appreciate you taking  the time to share this with us.`);
  }

  if (A3) {
    ret.push(`Thank you for your suggestion.
We will discuss your comments in our team and think of how to best address it.`);
  }

  if (A4) {
    ret.push(`Thank you for sharing your honest opinion and notifying us of this problem.
We will discuss your comments in our team and look for ways to improve.`);
  }

  return ret;
};
