import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13, A14, A15, A16} =
    getSelectedOptionsFlags(selectedOpts);
  /*
    { id = "A1", text = "User stated the goal", points = 0.5 },
    { id = "A2", text = "No submission", points = 0.5 },
    { id = "#12", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id = "A3", text = "User correctly says goal is SMART", points = 3 },
    { id = "A4", text = "User correctly says goal is NOT SMART and gives a new SMART goal", points = 3 },
    { id = "A5", text = "User correctly says goal is NOT SMART and gives new NOT SMART goal ", points = 1 },
    { id = "A6", text = "User incorrectly says goal is SMART", points = 1 },
    { id = "A7", text = "User incorrectly says goal is NOT SMART and gives a new SMART goal (both goals are smart)", points = 2 },
    { id = "A8", text = "User incorrectly says goal is NOT SMART but gives new NOT SMART goal", points = 1 },
    { id = "A9", text = "User incorrectly says goal is NOT SMART but does not share new improved goal", points = 1 },
    { id = "A16", text = "User say goal is SMART, but it is only smart because they added elements to each criteria below. As it is stated the goal is not SMART", points = 2 },
    { id = "#13", text = "----- ----- ----- ----- Select what was wrong (if something was wrong) ----- ----- ----- ----- ----- ", points = 0},
    { id = "A10", text = "(new) Goal is not specific", points = 0.5 },
    { id = "A11", text = "(new) Goal is not measurable", points = 0.5 },
    { id = "A12", text = "(new) Goal is not time-bounded", points = 0.5 },
  */

  if (A2) {
    ret.push(`No submission.`);
  }

  if (A1) {
    ret.push(
      `Thank you for your submission.
However, you did not go over each aspect of a SMART goal to explain why it is or isn't SMART
Even if you think it is, we would have appreciated if you had gone over each aspect.`
    );
  }

  if (A3) {
    ret.push(
      `Thank you for stating your goal.
You have correctly identified all elements of a SMART goal in your submission.
We advise you to keep evaluating if your goals meet all the SMART criteria every time you receive or set a new goal.
This will make them more motivating, easier to achieve (due to specificity) and to control (due to clear deadline and metrics).`
    );
  }

  if (A4) {
    ret.push(
      `Thank you for stating your goal.
You have correctly identified that your original goal was not SMART and have managed to transform that goal into a SMART goal. Good job!
We advise you to keep evaluating the goals you receive or set in the future so that they are always SMART.
This will make them more motivating, easier to achieve (due to specificity) and to control (due to clear deadline and metrics).`
    );
  }

  if (A15) {
    ret.push(
      `Thank you for stating your goal.
You have correctly identified all elements of a SMART goal in your submission.
We advise you to keep evaluating if your goals meet all the SMART criteria every time you receive or set a new goal.
This will make them more motivating, easier to achieve (due to specificity) and to control (due to clear deadline and metrics).

The only thing we would like to point out is that while this goal is measurable, it has only a binary output.
By the time you reach your deadline, either it is achieved, or it isn't.
In other words, this "metric" does allow you to objectively understand if the goal was achieved, but it does not help you evaluate your progress until it is achieved.
Ideally, you should try to include another metric in your goal that allows you to track your progress throughout the entire process. This will help you identify delays early on and make it less likely that the goal is not achieved in the end.`
    );
  }

  if (A5) {
    ret.push(
      `Thank you for stating one of your goals as requested.
You have correctly identified that your original goal is not SMART.
However, it seems like the improved goal you shared is also not SMART.
Below you will find what must still be improved in the formulation of your goal:`
    );
  }

  if (A6) {
    ret.push(
      `Thank you for stating one of your goals as requested and sharing why you believe it to be SMART.
While we agree to most of your comments, we believe that your goal is not properly SMART yet.
Below you will find what must still be improved in the formulation of your goal so that it is:`
    );
  }

  if (A7) {
    ret.push(
      `Thank you for stating one of your goals as requested.
However, it seems to us that your original goal was already SMART.
Still, the improved goal you shared below certainly is SMART.
So well done!

We advise you to keep evaluating the goals you receive or set in the future so that they are always SMART.
This will make them more motivating, easier to achieve (due to specificity) and to control (due to clear deadline and metrics)`
    );
  }

  if (A8) {
    ret.push(
      `Thank you for stating one of your goals as requested.
However, it seems to us that your original goal was already SMART.
Furthermore, the adapted goal you shared later does not seem to be SMART to us.
Below you will find what must still be improved in the formulation of your goal so that it is:`
    );
  }

  if (A9) {
    ret.push(
      `Thank you for sharing this goal.
However, it seems to us that your original goal was already SMART.
If you really think that it isn't SMART, then we would have liked to see how you would adapt it so that it is.

Lastly, if you are still unsure how to set SMART goals, it may be a good idea for you to go over our SMART goal content once more.
You can also reach out to us in the community in case you have any questions. Please do not hesitate to do so!`
    );
  }

  if (A13) {
    ret.push(
      `Thank you for stating your goal.
You have correctly identified that your original goal is not SMART.
However, you did not submit an improved version of this goal. This makes it hard for us to evaluate how well you can frame SMART goals. `
    );
  }

  if (A14) {
    ret.push(
      `Thank you for stating your goal and for improving it even though it was already acceptable as is.
We advise you to keep checking if your goals really are SMART every time you receive or set a new goal.
This will make them more motivating, easier to achieve (due to specificity) and to control (due to clear deadline and metrics).`
    );
  }

  if (A10) {
    ret.push(
      `We would have liked to see an explanation of why you believe this goal is specific.
For us, its formulation is still too broad. This makes it hard to pin-point exactly what must be achieved to complete this goal.
Receiving specific goals reduce the chance of having misunderstandings and make it easier for them to be reached. For a summary on the specificity of goals, you can check the M4 method cards.`
    );
  }

  if (A11) {
    ret.push(
      `What metric will you use to track its progress and confirm its termination?
It seems to us that, as stated right now, your goal does not have any clearly trackable metrics that will help you assess your progress.`
    );
  }

  if (A12) {
    ret.push(
      `You did not define a specific time frame for your goal.
Setting a time constraint to your goal is very important, as it makes time-management more efficient and helps keep your team motivated.
Having too much time or no clear deadline makes this goal lose priority early on. However, it is important to set reasonable expectations, as giving too little time can also be harmful to your project.`
    );
  }

  if (A16) {
    ret.push(
      `Thank you for sharing your first goal with us.
We must say that if we include your comments to each of the SMART criteria, then we agree that this goal is SMART:
However, if we only look at your goal as it was formulated, it is not.
Therefore, we ask you to in the future ensure that your goals are formulated in a SMART way without the need to add additional comments to it below.
This will ensure that all the relevant information you require is included in the goal itself.`
    );
  }

  return ret;

};
