import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const {
    Z0,
    Z1,
    Z2,
    Z3,
    B1,
    B2,
    B3,
    B4,
    B5,
    B6,
    B7,
    B8,
    B9,
    B10,
    B11,
    B12,
    B13,
    B14,
    B15,
    B16,
    B17,
  } = getSelectedOptionsFlags(selectedOpts);

  /*
{id = "#23", text = "----- ----- ----- ----- For the same feedback as for part a) ----- ----- ----- ----- ----- ", points = 0},
{ id= "B10", text= "Good answer as in point a)", points= 1.25},
{ id= "B11", text= "Not constraining as in point a)", points= 0.75},
{ id= "B12", text= "Not 'Outside-of-the-box' as in point a)", points= 0.75},
{ id= "B13", text= "Again asks 'How would XYZ solve this problem' (e.g., "How would Elon Musk or Apple solve this problem?")", points= 1 },
{ id= "B14", text= "Again, not a constraint, but something stimulating (e.g., you have all the money in the world)", points= 1 },
{ id= "B15", text= "Again ss asking participants for answers. E.g 'How would you do it?'", points= 0.5},
{ id= "B16", text= "Again constraint points to a solution (How can we use VR to...)", points= 0.6},
{ id= "B17", text= "Again we don't understand the constraint", points= 0.3},
{id = "#32", text = "----- ----- ----- ----- For a Different feedback as part a) ----- ----- ----- ----- ----- ", points = 0},
{ id= "B1", text= "Good constraint that pushes participants to think outside the box", points= 1.25},
{ id= "B2", text= "Is a constraint but does not push participants to think outside the box", points= 0.75 },
{ id= "B3", text= "Statement is not a constraint", points= 0.25 },
{ id= "B5", text= "How would XYZ solve this problem (e.g., "How would Elon Musk or Apple solve this problem?")", points= 1 },
{ id= "B6", text= "Not a constraint, but something stimulating (e.g., you have all the money in the world)", points= 1 },
{ id= "B7", text= "Is asking participants for answers. E.g 'How would you do it?'", points= 0.5},
{ id= "B8", text= "Constraint points to a solution (How can we use VR to...)", points= 0.6},
{ id= "B9", text= "We don't understand the constraint", points= 0.3},
*/

  if (B1) {
    ret.push(
      `Nice choice on the constraint! It is a good fit for the problem at hand and will help your participants focus on what really matters for your users!
Additionally, by narrowing down the possible solutions, it will help your participant to think outside the box and come up with fresh new ideas. Good job!`
    );
  }

  if (B2) {
    ret.push(
      `While your constraint does take away some of the possible solutions to your problem, it does not really push your participants to think outside the box.
In order to get the most out of your constrained brainstorming you should try to find constraints that force your participants to think of alternative solutions that go beyond "normal" approaches.
This will foster innovation by pushing them to think outside the box.

A good way to accomplish this is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible.
For example, if many of your solutions require an app to function, your first constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (B3) {
    /* Not sure if I should leave !A3 here */
    ret.push(
      `Thank you for your answer.
However, this is not really a constraint as it cannot be used to "eliminate" certain solutions.
In order to get the most out of your constrained brainstorming you should try to find constraints that force your participants to think of alternative solutions that go beyond "normal" approaches.
This will foster innovation by pushing them to think outside the box.

A good way to accomplish this is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible.
For example, if many of your solutions require an app to function, your first constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (B5) {
    ret.push(
      `Thank you for stating the second constraint you would use to stimulate your participants.
Although this is not really a constraint (as it does not limit the ideas you can come up with) it can increase your participant's creativity by getting them to look at the problem through someone else's perspective.
You can therefore use this as your opening 'constraint', just make sure to also include actual constraints later.

A good way to accomplish this is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible.
For example, if many of your solutions require an app to function, your first constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (B6) {
    ret.push(
      `Thank you for stating the second constraint you would use to stimulate your participants.
Although this is not really a constraint (as it does not limit the ideas you can come up with) it can increase your participant's creativity by removing obstacles and helping them focus on what really matters.
You can therefore use this as your opening 'constraint', just make sure to also include actual constraints later.

A good way to accomplish this is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible.
For example, if many of your solutions require an app to function, your first constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (B7) {
    ret.push(
      `Thank you for stating the second constraint you would use to stimulate your participants.
Unfortunately, we need to point out that this is not really a constraint, as it does not limit the solutions you can come up with. Instead, you are simply asking your participants for suggestions and answers to your problem.

This is not the goal of a constraint brainstorming. The goal is to create additional requirements to your solutions that exclude very common and obvious solutions. Thus pushing them to be creative and think outside the box.
A good way to come up with effective constraints is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible. This is a constraint you could use in your workshop!
For example, if many of your solutions require an app to function, your second constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (B8) {
    ret.push(
      `Thank you for stating the second constraint you would use to stimulate your participants.
However, while this constraint does limit the scope of the solutions your participants can come up with, it is limiting the breadth of possibilities towards one type solution. This in turn, might restrict your users too much and take away the possibility for alternative solutions to be discussed.
Therefore, we recommend you to use your constraints to push your participants to think creatively instead.

A good way to accomplish this is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible.
For example, if many of your solutions require an app to function, your first constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (B9) {
    ret.push(
      `We are not sure if we understood your constraint correctly.
A constraint should be a clear requirement that restricts the type of solutions your participants can present.

In other words, the goal of a constrained brainstorm is to create additional requirements to your solutions that exclude very common and obvious solutions. Thus pushing them to be creative and think outside the box.
A good way to come up with effective constraints is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible. This is a constraint you could use in your workshop!
For example, if many of your solutions require an app to function, your second constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (B10) {
    ret.push(
      `Thanks for sharing your second constraint! Just like the last one, it is clear and easy to understand.
It will definitely get your participants thinking creatively and coming up with unique solutions.

Good job!`
    );
  }

  if (B11) {
    ret.push(
      `Thank you for your second constraint.
However, again, the statement you shared is not a proper constraint as it does not create any clear requirement the ideas your participants may think of.
To get the most out of constrained brainstorming, you should define constraints that encourage participants to think beyond typical solutions. See our feedback above for an example.`
    );
  }

  if (B12) {
    ret.push(
      `Thanks for sharing your second constraint. However, again, while it is limiting potential solutions, it may not be pushing your participants to think creatively.
To maximize the benefits of constrained brainstorming, consider constraints that challenge participants to think beyond typical solutions.
For more guidance, refer to the feedback provided earlier.`
    );
  }

  if (B13) {
    ret.push(
      `Thank you for stating the next constraint you would use to stimulate your participants.
However, again this is not a proper constraint as it doesn't limit the ideas that can be generated. Just like in your previous answer, this statement can enhance their creativity by prompting them to see the problem from a different perspective.
Because you have already used one "stimulating constraint" in your answer above, we ask you to come up with an actual constraint for your workshop this time.
Please check our feedback above for an example.`
    );
  }

  if (B14) {
    ret.push(
      `Thank you for stating the next constraint you would use to stimulate your participants.
However, again this is not a proper constraint as it doesn't limit the ideas that can be generated. Just like in your previous answer, this statement can enhance their creativity by removing obstacles and helping them think of alternative solutions.
Because you have already used one "stimulating constraint" in your answer above, we ask you to come up with an actual constraint for your workshop this time.
Please check our feedback above for an example.`
    );
  }

  if (B15) {
    ret.push(`Thank you for sharing the next constraint you plan to use to stimulate your participants.
However, it is not a proper constraint as it does not restrict the solutions that can be generated. Instead, it is asking your participants for suggestions and answers to your problem.
To achieve the goals of a constraint brainstorming session, it is important to establish additional requirements for solutions that eliminate typical and obvious answers and encourage more out-of-the-box thinking`);
  }

  if (B16) {
    ret.push(
      `Thank you for your answer.
However, again you have used constraints to focus on one type of solution.
Like mentioned above, please make sure to avoid doing that in the future as it restricts your participants to think of only one type of solution. (Please check our feedback above for more details).`
    );
  }

  if (B17) {
    ret.push(`Once more we are not certain if we understood your constraint correctly.
A constraint should be a clear and specific limitation that guides the solutions your participants can present.
The purpose of constrained brainstorming is to add additional requirements to solutions that eliminate typical and obvious answers, encouraging more out-of-the-box thinking.

Please check our feedback in part above for more details.`);
  }

  return ret;
};
