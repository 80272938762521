import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, A0, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for stating what industry maturity stage your business is in! 
Understanding the business phase your industry is in, is a very important information that will help you set the right priorities and develop best strategies.`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for stating what industry lifecycle your business is in and for differentiating between different markets!
Understanding the business phase your industry is in, is a very important information that will help you set the right priorities and develop best strategies.`
    );
  }

  if (A0) {
    ret.push(
      `It seems like you did not state clearly what the maturity stage of the industry your business is in.
Understanding the business phase your industry is in, is a very important information that will help you set the right priorities and develop best strategies.`
    );
  }

  return ret;
};
