import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Good job. The mapping of your ideas in an impact-effort matrix is clearly visible.`);
  }

  if (A3) {
    ret.push(
      `Your dot-voting process is very clear and visible in the image you have provided. Well done!`
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `We cannot see your ideas mapped along an impact-effort matrix as asked. Mapping your ideas will help you classify and select the most promising ideas your ideation session came up with.`
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `You did not label your impact-effort matrix clearly. This may seem unimportant, but it is important to record all your data and how it was analyzed so that your decision will be easy to follow in the future. Any matrix without labels will be useless in the future if you forget what your evaluation criteria were.`
    );
  }

  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `The image you provided us with does not show the dot-voting process very clearly. We would have liked to see all your ideas mapped on your 2x2 impact-effort matrix and then be able to see which dots (and how many) each idea received.`
    );
  }

  return ret;
};
