import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (scoredPoints > 0 && !A5 && !A6 && !A0) {
    ret.push(
      `Thank you for your reflection!
It was interesting to read about your experience and your thoughts!`
    );
  }

  if (scoredPoints > 0 && !A5 && !A6 && A0) {
    ret.push(
      `Thank you for your reflection.
However, please write down your answers in more detail in the future.
Doing so will help us evaluate your learnings better.`
    );
  }

  if (A5 && !A6) {
    ret.push(
      `Thank you for your reflection!
It was interesting to read about your experience and your thoughts!
We appreciate the level of detail employed and are happy to hear that you enjoyed this process so much!

We hope you will enjoy the next module just as much.`
    );
  }

  if (A6) {
    ret.push(
      `Thank you for your reflection!
It was interesting to read about your experience and your thoughts!

We liked that you mentioned how it is hard to break up good discussions during ideation session (or sessions in general).
This is a common issue that even a more experienced facilitator has and there is no clear rule on how to proceed.
In general, you should simply keep in mind that design thinking is an iterative approach.
No one expects your team to come up with the next big thing on your first try.
Instead, it is much more efficient to work fast and fail fast. You can always go back to the discussions and try out something new.`
    );
  }

  if (A7) {
    ret.push(
      `Regarding how hard it is to define the problem:
This is normal and we do not expect you to perfectly define the problem at the beginning of your project.
Most likely, as you develop your idea, talk to users and learn more about your challenge, you will realize that you must adapt (or maybe even completely change) your problem.
These iterative aspect of the design thinking process is unavoidable.
So don't worry if you are not 100% happy with the problem you defined. Just make sure to keep improving it based on your learnings.`
    );
  }

  return ret;
};
