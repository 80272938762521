import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Great work! You have submitted a good number of boundaries to your project. Your criteria are clearly formulated and convincing. This will not only help to align with your team and share a vision throughout the sprint, but set clear boundaries when your leadership wants to add additional elements to the sprint.`
    );
  }

  return ret;
};
