import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A4) {
    ret.push(
      `Very well done!
We are happy to see how you did more than just what was required in this assignment!
Just keep in mind that, while we asked you to perform only two interviews for the purpose of practicing it, in reality, even two interviews would have already been too few interviews for an effective user research.
Depending on the project you are working on we would often suggest at least 10 to 15 interviews with normal users, extreme user, non-users and other interesting stakeholders.`
    );
  }

  if (A3) {
    ret.push(
      `Good job conducting these two interviews.
Don't forget that we asked you to perform only two, just so you could practice it, but in reality, even two interviews would have already been too few interviews for an effective user research. Depending on the project you are working on we would often suggest at least 10 to 15 interviews with normal users, extreme user, non-users and other interesting stakeholders.`
    );
  }

  if (A1) {
    ret.push(`No interviews conducted`);
  }

  if (A2) {
    ret.push(
      `Only one interview conducted.
Next time you should time to conduct more interviews. Here we asked you to perform two, just so you could practice it, but in reality, even two interviews would have already been too few interviews for an effective user research. Depeninding on the project you are working on we would often suggest at least 10 to 15 interviews with normal users, extreme user, non-users and other interesting stakeholders. `
    );
  }

  return ret;
};
