import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Amazing! You stated a great method of communication that actively involves the project team and keeps them updates. `
    );
  }

  if (Z2) {
    ret.push(
      `Thank you for your submission! You method of communication sounds good, but we are lacking further information to assess if and how team members are actively involved. Some general examples are: shared and discussed in meetings, being represented on a board or a overview page which team members look at daily, send them via e-mail weekly, etc.`
    );
  }

  if (Z3) {
    ret.push(
      `Thank you for your submission! You method of communication is very passive and if team member want to be on track, they have to search for this information. Your KPIs should be communicated easily, e.g. shared and discussed in meetings, being represented on a board or a overview page which team members look at daily, send them via e-mail weekly, etc.`
    );
  }

  return ret;
};
