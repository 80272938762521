import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 === false) {
    ret.push(
      `From your submission, it is unclear what the overall goal of your project is. What is the project aiming to achieve? Why would your organization invest in it? Please take more time consider what this overall goal is. To communicate it more clearly, it helps to share your goal with someone outside the team and see whether they understand what you want to achieve.`
    );
  }

  if (A1 && A2) {
    ret.push(
      `Good work! You stated the overall goal and purpose of the project and provided a clear explanation on how it aligns with the company's mission. This is a great way to evaluate and communicate a project's relevance in an organization.`
    );
  }

  if (A1 && !A2) {
    ret.push(
      `Thank you for sharing the overall goal and purpose of the project. Unfortunately, we were missing part of your answer. How is this goal important to the company and aligns with its mission? This is an important first step in evaluating and communicating a project's relevance in an organization.`
    );
  }

  return ret;
};
