import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);
  /*
{id = "#1", text = "----- ----- ----- ----- Select option below! ----- ----- ----- ----- ----- ", points = 0},
{ id= "A1", text= "Idea napkin submitted - You must select this", points= 2 },
{id = "#2", text = "----- ----- ----- ----- Problems ----- ----- ----- ----- ----- ", points = 0},
{ id= "A2", text= "Idea is not visualized (No sketch, image, drawing or similar)", points= -0.6 },
{ id= "A3", text= "Idea is too broad (new system for... New process for...", points= -0.6 },
{ id= "A4", text= "Problem not clear", points= -1 },
{ id= "A5", text= "User not clear", points= -1 },
{ id= "A6", text= "Relevance not clear", points= -0.6 },
{ id= "A7", text= "How it works not clear", points= -0.6 },

*/

  if (!A2 && !A3 && !A4 && !A5 && !A6 && !A7 && !A8 && scoredPoints === 2) {
    ret.push(`Well done! Your idea napkin is well designed and is a good and simple visualization of your first idea.
It should help you share and explain your ideas key elements like who does it target, what problem does it solve for them and why is it relevant.

Being able to explain and visualize ideas in a simple manner is a very valuable skill which you should continue to practice after completing this program. `);
  }

  // if (scoredPoints === 1.4) {
  // ret.push(`Thank you for sharing your idea napkin.
  // Being able to explain and visualize ideas in a simple manner is a very valuable skill which you should continue to practice after completing this program.
  // However, while you managed to depict most of your idea's elements, there is still one thing you could do to improve further.`);
  // }

  // if (scoredPoints < 1.4) {
  // ret.push(`Thank you for sharing your idea napkin.
  // Being able to explain and visualize ideas in a simple manner is a very valuable skill which you should continue to practice after completing this program.
  // However, while you managed to depict most of your idea's elements, there are still a few things you could do to improve further.
  // These improvements are depicted below: `);
  // }

  if (A2) {
    ret.push(
      `Although you have effectively depicted the different elements of your idea, you have not included a visualization. Adding a simple sketch or drawing can make your idea more tangible and easier to understand. We suggest that you always try to incorporate a visualization in your future idea napkins.`
    );
  }

  if (A4) {
    ret.push(
      `You did not make it very clear what problem your solution is trying to solve (bottom left of the idea napkin).
Being able to describe the pain-point you want to address in a simple and relatable way is the best way to highlight your idea's strengths. When doing so it is important to stay user-centred and focus on the underlying problems you've heard and identified during your interviews.
Therefore, in the future, please try to highlight better what problem or need you are trying to tackle.`
    );
  }

  if (A5) {
    ret.push(
      `You did not define your target user well enough.
We advise you to change this section of your idea napkin to highlight better who your user is and how yur solution fits this person's habits and needs.`
    );
  }

  if (A6) {
    ret.push(
      `You did not highlight well enough why this idea is relevant.
Try to showcase better the impacts your idea could have on your user and their surroundings.
Or mention more tangible consequences of launching your idea.
Including this on your idea napkin will help your participant understand the full scope of your idea.`
    );
  }

  if (A7) {
    ret.push(
      `How exactly does your idea work?
In the way it is described here, this is not clear enough.
In order to receive valuable feedback we advise you to explain better what your idea is and how it will work.
The more tangible and objective you are, the easier it will be for your colleague to grasp the full scope of your idea.
In general we recommend you avoid terms like system, process or solution. These tend to be very abstract and hard to really pin down. `
    );
  }

  if (A8) {
    ret.push(
      `Also, your idea napkin is not detailed enough.
Imagine you would just share your napkin with your interviewee. Would it contain all the key information so that they can truly understand why this idea is so great? It seems to us like this is not the case.
Therefore, please be more detailed in the future.`
    );
  }

  if (A3) {
    ret.push(
      `While you have filled out all the elements of this template, we believe that your idea is still too broad.
In order to get effective feedback from your users, it is important to have a simple depiction of the exact problem you would like to solve and how your idea might do that.
The more focused you are when describing these two things, the better.

So our recommendation to you is that you take a few minutes to define your idea better.
What problem does it solve? To whom? How would your idea work? Why is it better than the current alternative?
Your idea napkin should answer these questions.`
    );
  }

  return ret;
};
