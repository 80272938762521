import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2 && A3 && A4 && A5 && A6 && A7 && A8) {
    ret.push(
      `Good job! It was very interesting to read your reflection. Thank you for sharing! Just keep in mind that, while there is no right or wrong, reflecting on your interviews' outcome can be extremely useful as it helps you identify things that should be maintained or changed for the next time you conduct an interview.`
    );
  }

  if (
    Z0 === false &&
    Z1 === false &&
    Z2 === false &&
    (A1 === false || A3 === false || A5 === false || A7 === false)
  ) {
    ret.push(
      `You did not submit four interesting aspects as asked. We would have liked to read more about your experience. Just keep in mind that, while there is no right or wrong, reflecting on your interviews' outcome can be extremely useful as it helps you identify things that should be maintained or changed for the next time you conduct an interview.`
    );
  }

  if (A1 && A3 && A5 && A7 && (A2 === false || A4 === false || A6 === false || A8 === false)) {
    ret.push(
      `Thank you for submitting four interesting aspects as requested. We hope that this reflection will help you prepare better learnings later and improve your interviewing skills even further.`
    );
  }

  return ret;
};
