import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, Z4 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Nice work! You have identified a key user in your customer journey mapping efforts and provided a well-thought explanation for why they are considered important. Being able to explain why a user is central to an experience is an important first step to building empathy, and it also provides valuable context for others to understand your approach to the experience design.`
    );
  }

  if (Z2) {
    ret.push(
      `You have identified a key user in your customer journey mapping efforts and provided an explanation for why they are considered important. Being able to explain why a user is central to an experience is an important first step to building empathy, and it also provides valuable context for others to understand your approach to the experience design.`
    );
  }

  if (Z3) {
    ret.push(
      `It's great that you have identified a key user in your customer journey mapping efforts. However, it's equally important to understand the reasons why this user is considered key. Providing a clear explanation as to why you have chosen this user will help others understand your thought process and ensure that everyone is on the same page. It will also provide valuable context and ensure that the mapping effort stays focused and targeted. Consider adding a brief explanation to your presentation or discussion to provide additional clarity and demonstrate your understanding of the customer journey.`
    );
  }

  if (Z4) {
    ret.push(
      `It's important to choose a user who is representative of the customer experience in your customer journey mapping efforts. However, it appears that the user you have chosen may not be the best fit for this task. To ensure that your mapping is effective, it's important to choose a user who is representative of the target customer and whose journey provides valuable insights. Consider reassessing your choice of user and conducting further research to identify the most relevant user to focus on. This will ensure that your customer journey mapping efforts are targeted, effective, and provide valuable insights for driving innovation.`
    );
  }

  if (true) {
    ret.push(
      `While we asked you to focus on one user in this experience, keep in mind that in a full-scale project, considering multiple users in customer journey mapping is important because it provides a more comprehensive view of the customer experience. Each customer may have unique experiences, needs, and perspectives, and by considering multiple users, you can gain a well-rounded understanding of the customer journey and design more effective solutions.`
    );
  }

  return ret;
};
