import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, C1, C2, C3, C4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (C1 && C2 && C3) {
    ret.push(
      `Good choice of constraint!
It is a relevant constraint that helps your participants focus on a certain issue regarding some of your user types.
More importantly it does also reduce the space of possible solutions significantly which forces your participants to look for alternative solutions and therefore helps bring forth innovation.
Well done!`
    );
  }

  if (C2 && !C3) {
    ret.push(
      `While your constraint touches a relevant criteria for your idea, it does not really constrain the solution space that much.
In order to get the most out of your constrained brainstorming you should try to find constrains that force your participants to think of alternative solutions that go beyond "normal" approaches.
This will foster innovation by pushing them to think outside the box.`
    );
  }

  if (!C2 && C3) {
    ret.push(
      `While your constraint certainly forces your participants to think outside the box, it may not be the most relevant constraint possible.
Ideally, you should try to think of constraints that push your participant to focus on very relevant assumptions or concepts related to your idea or personas.
Doing this will help you get the most out of this session.`
    );
  }

  if (!Z0 && !Z1 && !C2 && !C3) {
    ret.push(
      `This contraint does not constrain the solution space that much.
In order to get the most out of your constrained brainstorming you should try to find constrains that force your participants to think of alternative solutions that go beyond "normal" approaches.
This will foster innovation by pushing them to think outside the box.

Furthermore, it may not be the most relevant constraint possible.
Ideally, you should try to think of constraints that push your participant to focus on very relevant assumptions or concepts related to your idea or personas.
Doing this will help you get the most out of this session.`
    );
  }

  if (C4) {
    ret.push(
      `It seems to us that you did not understand what we meant by constraint. A good constraint should be a statement that reduces the set of possible solutions. For example, something like "the solution must be good" is not a good constraint because it does not force you to solve any additional issues. On the other hand, something like "The solution can not weigh more than 2kg" is an excellent constraint because it reduces the number of possible solutions and forces your team to solve an additional issue that might affect some of your users.`
    );
  }

  return ret;
};
