import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (scoredPoints < 5 && A1) {
    ret.push(
      `Thank you for submitting your sprint execution plan. Here are some aspects you can improve on in order to have more learnings and better outputs of your sprint:`
    );
  }

  if (A2) {
    ret.push(
      `Don't forget to plan a scoping phase together with the whole team! Even if you as a team leader already agreed on a scope with other stakeholders, it is still important to onboard the whole sprint team.`
    );
  }

  if (A3) {
    ret.push(
      `A customer discovery phase is essential to truly understand the needs and jobs-to-be-done of your target customer. Even if this was covered in a previous sprint, we still recommend having a brief phase to validate your latest assumption and have everybody in the team hear about the needs directly from the user.`
    );
  }

  if (A4) {
    ret.push(
      `You did not include an ideation phase. An innovation sprint should never be used to execute a predefined solution, but to come up with and test a variety of assumptions and solutions. The only exception can be a sprint that directly follows on a previous sprint, and the team has already had an ideation phase on this challenge.`
    );
  }

  if (A5) {
    ret.push(
      `You did not include a phase for the problem-solution fit or business model fit.
Even if your project is still in the early stages and you are mostly focused on identifying your key user and problem statement, we ask you to include a phase to investigate what ideas could tackle this problem and, maybe, how this solution could be validated and integrated into your current business model.
It is essential to think about this things during your innovation projects.`
    );
  }

  if (A1 && !A2 && !A3 && !A4 && !A5) {
    ret.push(`Great work!
A sprint execution plan is essential to a Sprint, as you need to know when you are going to do what. You included all the relevant phases for your sprint. Planning them allows to better align the team, include stakeholders, and define decision criteria and deadlines. You will continue to add more details to the sprint plan in the next assignments.`);
  }

  return ret;
};
