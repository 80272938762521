import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13 } =
    getSelectedOptionsFlags(selectedOpts);

    if (true) {
      ret.push(
        `Thank you for sharing your filled out scoping template.
We hope that you have written down everything what is in your head about the challenge as a proper scoping canvas should help you extract all the relevant starting information and give you a clear overview of the topic and scope of the challenge you want to tackle. `
      );
    }
  
    if (A1) {
    ret.push(
      `Aim to fill out even more elements of the scoping canvas! It might seem obvious to you in your head, but you never know unless you communicate it clearly to others. The scoping canvas helps you to become more aware of the challenge, and align with your team and leadership.`
    );
  }

  if (A2) {
    ret.push(
      `You are on a good path! Aim to fill out even more elements of the scoping canvas. It might seem obvious to you in your head, but you never know unless you communicate it clearly to others. The scoping canvas helps you to do so, and align with your team and leadership.`
    );
  }

  if (A4) {
    ret.push(
      `Your efforts gave you a broad overview of the topic and scope of the challenge you want to tackle. It is clear and convincing why this challenge is important for the customer and your company. Hopefully, you wrote down everything what is in your head about the challenge. The scoping canvas helps you to do so, and align with your team and leadership.`
    );
  }

  if (A5) {
    ret.push(
      `Unfortunately, the reason why you want to tackle the challenge is not convincing, as stated in both the background and the current situation (pains and opportunities). If you can't formulate a clear reason for your project, this is a huge red flag!`
    );
  }

  if (A6) {
    ret.push(
      `Your challenge statement, or also called problem statement, is stated clearly and includes a user, their need, and the challenge to solve this need. Although this is a guiding element of your research, don't hesitate to reformulate it if your research falsifies your assumptions or detects a more relevant challenge within the topic! `
    );
  }

  if (A7) {
    ret.push(`Your challenge statement, or also called problem statement, is not stated clearly or misses important elements. It should always include a user, their need, and the challenge to solve this need. Dare to be specific! Although this is a guiding element of your research, you can still reformulate it if your research falsifies your assumptions or detects a more relevant challenge within the topic!
The problem statement should be focusing on a problem, not solution, and had a user instead of company focus. The customer segment you aim to put at the center of your sprint could be explained better. This helps to know who you want to contact during your research, and which needs and challenges you want to verify. Remember: Stating a customer segment like SMEs, our current customers, mums, etc. is way to broad and will not help you to direct your research.`);
  }

  if (A8) {
    ret.push(
      `You have stated clear goals in your desired outcomes. 
Clear goals will help you to keep your focus during the sprint, and communicating it with your management will help you set their expectations.`
    );
  }

  if (A9) {
    ret.push(
      `A clear goal is helping you to keep your direction during the sprint, and communicating it with your management aligns the expectations. Having a measurable success criteria makes this goal even more tangible. Here, you have some room for improvement. Try to define a clear goal and measurable success criteria.`
    );
  }

  if (A10 && A13) {
    ret.push(
      `To allow for a smooth execution of the sprint, you should define a sprint lead, who plans and acts as a facilitator during the sprint.`
    );
  }

  if (A11 && A13) {
    ret.push(
      `You defined more or less than 2-3 team members, additional to the other roles. A core team which is too big will waste time on discussion, and communication becomes inefficient. However, you can always invite additional people for brainstorming sessions, ask for their input in interviews or test your prototypes with them!`
    );
  }

  if (!A13) {
    ret.push(
      `Unfortunately, you did not include the team you would work with on this sprint.
Therefore, we would like to advise you to in the future always think about 2-3 team members that you believe would be valuable additions to your sprint.
A core team which is too big will waste time on discussion, and communication becomes inefficient. 
Still, you should keep in mind that you can always invite additional people for brainstorming sessions, ask for their input in interviews or test your prototypes with them!`
    );
  }

  if (A12 && A13) {
    ret.push(
      `Unfortunately, you forgot to included a team sponsor as final decision maker. Involving this leadership roles at important decision points will not only make sure that your sprint will produce a valuable output for the company, bit will also ensure that it has the support it needs to thrive.`
    );
  }

  return ret;
};
