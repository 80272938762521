import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, B1, B2, B3, B4, B5, B6, B7, B8, B9, B10, B11, B12, B13, A13, B16 } =
    getSelectedOptionsFlags(selectedOpts);

  /*
    { id = "B1", text = "User incorrectly says goal is SMART", points = 1.5 },
    { id = "B2", text = "User incorrectly says goal is NOT SMART and gives a new SMART goal (both goals are smart)", points =  1.5},
    { id = "B3", text = "User incorrectly says goal is NOT SMART but gives new NOT SMART goal (only original goal is smart)", points = 1 },
    { id = "B4", text = "User incorrectly says goal is NOT SMART but does not share new improved goal", points = 0.5 },
    */

  if (B6) {
    ret.push(
      `Once more, you did not go over each aspect of a SMART goal to explain why it is or isn't SMART.`
    );
  }

  if (B7) {
    ret.push(
      `Well done once more.
You have correctly identified all the elements of a SMART goal in your submission.`
    );
  }

  if (B8) {
    ret.push(
      `Well done once more.
You have correctly identified that your goal is not SMART and have adapted that goal so that it is.`
    );
  }

  if (B9) {
    ret.push(
      `You have again correctly identified that your goal is not SMART and have tried to adapt it so that it is.
However, we believe your reformulated goal is still missing some of the criteria of a SMART goal.`
    );
  }

  if (B1) {
    ret.push(
      `Thank you for sharing your goal again.
It seems like your goal is once more not SMART.
Below you will find what must still be improved in the formulation of your goal so that it is:`
    );
  }

  if (B2) {
    ret.push(
      `Thank you for sharing your goal again.
But it seems to us that once more your original goal was already SMART.
Still, the improved goal you shared below certainly is SMART.
So well done!

We advise you to keep evaluating the goals you receive or set in the future so that they are always SMART.`
    );
  }

  if (B3) {
    ret.push(
      `Thank you for sharing this goal again.
It seems to us that once more your original goal was already SMART and your adapted goal is not.
Below you will find what must still be improved in the formulation of your goal so that it is:`
    );
  }

  if (B4) {
    ret.push(
      `Thank you for sharing this goal again.
Once more, it seems to us that your original goal was already SMART.
Again, if you really think that it isn't SMART, then we would have liked to see how you would adapt it so that it is.

Lastly, if you are still unsure how to set SMART goals, it may be a good idea for you to go over our SMART goal content once more.
You can also reach out to us in the community in case you have any questions. Please do not hesitate to do so!`
    );
  }

  if (B10) {
    ret.push(
      `We would have liked to see an explanation of why you believe this goal is specific.
For us, its formulation is still too broad. This makes it hard to pin-point exactly what must be achieved to complete this goal.
Receiving specific goals reduce the chance of having misunderstandings and make it easier for them to be reached. For a summary on the specificity of goals, you can check the M4 method cards.`
    );
  }

  if (B11) {
    ret.push(
      `What metric will you use to track its progress and confirm its termination?
It seems to us that, as stated right now, your goal does not have any clearly trackable metrics that will help you assess your progress.`
    );
  }

  if (B12) {
    ret.push(
      `You did not define a specific time frame for your goal.
Setting a time constraint to your goal is very important, as it makes time-management more efficient and helps keep your team motivated.
Having too much time or no clear deadline makes this goal lose priority early on. However, it is important to set reasonable expectations, as giving too little time can also be harmful to your project.`
    );
  }

  if (B13 && !A13) {
    ret.push(
      `Thank you for stating your goal.
You have correctly identified that your original goal is not SMART.
However, you did not submit an improved version of this goal. This makes it hard for us to evaluate how well you can frame SMART goals. `
    );
  }

  if (B13 || A13) {
    ret.push(
      `While you did identify that your goal is not SMART again, you did not submit an improved version of your goal.`
    );
  }

  if (B16) {
    ret.push(
      `Thank you for sharing your second goal with us.
However, once more, if we analyze your goal as it was formulated (without your comments below), it is not SMART.
In the future, please ensure that all the relevant information you require is included in the goal itself.`
    );
  }

  return ret;
};
