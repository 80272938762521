import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, C0, C1, C2, C3 } = getSelectedOptionsFlags(selectedOpts);

  if (C0) {
    ret.push("No explanation submitted.");
  }

  if (C1 && C2 && C3) {
    ret.push(`Thank you for your explanation.
The hypothesis you and your team selected seems relevant for your idea and we agree that it is important to validate it.`);
  }

  if (C1 && C2 && C3 === false) {
    ret.push(`Thank you for your submission.
While the hypothesis you and your team selected seems relevant for your idea and should certainly be tested, we believe that you could have explained better why this is the case.
This will help align your team around it and ensure that everyone in your teams run their tests more effectively.`);
  }

  if (!Z0 && !Z1 && C2 === false) {
    ret.push(`We don't think that this assumption is the most relevant to be tested.
We ask you to, together with your team, go over your idea again and look for the one assumption that is crucial to it.
This assumption should be not only relevant for your idea, but it should also be somewhat uncertain.
Identifying such an assumption now will ensure that you make the most out of these tests.`);
  }

  return ret;
};
