import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `You have not submitted any customers.
We ask you to still take some to reflect on who your key customers are as they are the central part of your business model.
The more customers your business serves, the more robust your business can be as it will allow you to tap into more (and more diverse) revenue streams.
Therefore, we advise you to look for at least three key customers you could serve.`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for sharing your business model's key customer. 
However, we would like to remind you that the case given to you included three distinct personas, all of which had different needs. We advise you to, in the future, focus on all of your personas when evaluating the golden triangle of your business model canvas.
The more customers your business serves, the more robust your business can be as it will allow you to tap into more (and more diverse) revenue streams.`
    );
  }

  if (A3) {
    ret.push(
      `Thank you for sharing your business model's two key customers. 
However, we would like to remind you that the case given to you included three distinct personas, all of which had different needs. We advise you to, in the future, focus on all of your personas when evaluating the golden triangle of your business model canvas.
The more customers your business serves, the more robust your business can be as it will allow you to tap into more (and more diverse) revenue streams.`
    );
  }

  if (A4 || A5) {
    ret.push(
      `Thank you for sharing and describing your business model's key customers. We are happy to see that you have several distinct customers.
This is important, because the more customers your business serves, the more robust your business can be as it will allow you to tap into more (and more diverse) revenue streams.`
    );
  }

  return ret;
};
