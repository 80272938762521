import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`This sounds like a strong and reasoned decision process. As said before, you have a great variety of ideas, and with the two prioritised ones, you are going into different directions (and hopefully testing both of them moving on).
A roasting session is a great tool to get a lot of feedback in a short amount of time, and I heard it has been used more and more within Cargill. Well done!`);
  }

  return ret;
};
