import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3 } = getSelectedOptionsFlags(selectedOpts);

  if (!Z0 && !Z1 && !Z2 && !A1 && !A2 && !A3) {
    ret.push(
      `Thank you for defining the success criteria and failure boundary for your metrics. This will help you to assess whether your project is on track, is sucCeeding your expectations, or falling behind. Clearly defined success criteria and failure boundaries will also help you make data-driven decisions and communicate them with your team.`
    );
  }

  if (A1 || A2 || A3) {
    ret.push(`Thank you for sharing your answer.
Being able to set clear criteria and success/failure boundaries for your metrics is a very important step before committing too many resources into a project.

While your answer shows that you are on the right track, there are still some things that we would like to highlight. These are stated below:`);
  }

  if (A1) {
    ret.push(
      `You did not include success criteria for all of your metrics. It is important to define success criteria clearly early on to help you understand when you are close or far from success as your project is under way. This will enable you to make decisions about the project and communicate with your team`
    );
  }

  if (A2) {
    ret.push(
      `You did not include failure boundaries for all of your metrics. Failure boundaries serve an important role in helping you decide when to kill a project. This is a difficult but important decision to make in order to avoiding wasting valuable resources.`
    );
  }

  if (A3) {
    ret.push(
      `Right now your success criteria and failure boundaries are too vague. This means it will be hard for you to know when you've reached success or you failure boundary, which impedes your ability to make decisions. 
To improve your answer, we suggest that you work with your team to refine your success criteria and risk boundaries to make them more specific and measurable. This will help you to better evaluate the progress of your project and make informed decisions about whether to persevere or kill the project.`
    );
  }

  return ret;
};
