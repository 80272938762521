import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
const ret: string[] = [];
const { Z0, Z1, C1, C2, C3, C4, C5, C6, C7, C8, C9, C10, A7, B11, C11, A8, B12, C12, C13, A14, B14, C14, C15, C16 } =
getSelectedOptionsFlags(selectedOpts);
/*
{ id = "C1", text = "Feedback starts with something positive", points = 0.5},
{ id = "#3", text = "----- ----- ----- ----- FOR SAME FEEDBACK AS ABOVE ----- ----- ----- ----- ----- ", points = 0},
{ id = "C2", text = "Feedback is also specific and actionable", points = 2},
{ id = "C3", text = "Feedback is also specific but not actionable", points = 1},
{ id = "C4", text = "Feedback is also not specific nor actionable", points = 0},
{ id = "#4", text = "-----  ----- ----- ----- FOR DIFFERENT FEEDBACK ----- ----- ----- ----- ", points = 0},
{ id = "C5", text = "Unlike above, feedback is now specific and actionable", points = 2},
{ id = "C6", text = "Unlike above, feedback is now specific but not actionable", points = 1},
{ id = "C7", text = "Unlike above, feedback is now not specific nor actionable", points = 0},
*/

//For repeated Feedback

if (C10 && !C15 && !C16) {
ret.push(
`Again we are unable to give you feedback as we don't know how your feedback was framed.`
);
}

if (C2 && C8 === false && C9 === false && !C10 && !C15 && !C16) {
ret.push(`Nicely done. Again your feedback is specific and actionable.`);
}

if (C3 && C8 === false && C9 === false && !C10 && !C15 && !C16) {
ret.push(
`Once more, while this feedback is specific it is not very actionable.
Try to help your colleagues by suggesting ways to improve in the future.`
);
}

if (C4 && C8 === false && C9 === false && !C10 && !C15 && !C16) {
ret.push(
`Once more your feedback is not very specific nor actionable.
Since giving proper feedback is so important, we ask you to re-watch our material on this topic.
Please do not hesitate to reach out if something is unclear to you.`
);
}

if (C13 && C8 === false && C9 === false && !C10 && !C15 && !C16) {
ret.push(
`Thank you for your final answer.
However, once more, while this feedback should help your colleague improve, it may be too direct.
You can make it easier for your colleague to listen to your suggestions by focusing more on the problem and less on the person itself.
Also, as mentioned earlier, you can also invite your colleague to work together to tackle this issue. For example, you could end your feedback with: "What if we worked together to [...]?"`
);
}

//For different Feedback

if (C5 && C8 === false && C9 === false && !C10 && !C15 && !C16) {
ret.push(
`Nicely done! Differently than before, this feedback was more specific and actionable.`
);
}

if (C6 && C8 === false && C9 === false && !C10 && !C15 && !C16) {
ret.push(
`Your feedback is specific but not really actionable.
To give even better feedback in the future, you should try to suggest ways to improve once you mention something that could be better.`
);
}

if (C7 && C8 === false && C9 === false && !C10 && !C15 && !C16) {
ret.push(
`Differently than in your previous answer, this feedback is not very specific nor actionable.
Your feedback would have been better if you had focused more on what exactly went well/poor and how that could be improved.`
);
}

//For both

if (
!Z0 &&
!Z1 &&
C1 === false &&
C8 === false &&
C9 === false &&
!C10 &&
!C2 &&
!C3 &&
!C4 &&
!C5 &&
!C6 &&
!C7
 && !C15 && !C16) {
ret.push(
`To finish off, next time you give feedback you should try to start your feedback by mentioning something the other person did well. This will make it easier to transition to something they could improve.
Most importantly, it will also make it more likely that the person listens to your feedback.`
);
}

if (C8 && !C10 && !C15 && !C16) {
ret.push(`Once more, your feedback is not really constructive.`);
}

if (C12 && !C10 && !A8 && !B12 && !C15 && !C16) {
ret.push(`What you did especially well was to use "us" instead of "you vs. I" ("To ease your work load, could we perhaps...").
This highlights that you understand their position and are willing to work together to improve the situation.
Demonstrating empathy like this will ultimately strengthen your relationship and improve communication.
Well done!`);
}

if (C9 && !C10 && !C15 && !C16) {
ret.push(
`Thank you for sharing your final answer.
However, while the comment above is not really an example of constructive feedback.
Instead, it is merely suggesting your colleague to do something that you believe would help them.
Constructive feedback should address a behavior or deliverable connected to your colleague that you believe should be improved. In the comment above it seems like you are not doing that.

Still, for the purpose of this assignment this is okay.`
);
}

if (C14 && !A14 && !B14 && !C15 && !C16) {
    ret.push(`It seems to us that the first part of your feedback (what the the person did well) does not relate to what you said next.
If instead you had shared something related to what you believe they could do differently, it might make the transition even easier.`);
    }

if (C11 && !A7 && !B11 && !C15 && !C16) {
ret.push(`One thing we would like to point out is that the language you use is very direct. Depending on your relationship with your colleague and your regional culture, this may not be a problem. But as a rule of thumb, it is a good idea to use "softer" language when sharing critical feedback.
One good approach is to use "we/us" instead of "you vs. me". This helps you focus on the problem and not on the individuals and helps you show that you are on this together.`);
}

if (C15) {
    ret.push(`Thank you for sharing your third piece of feedback.
However, once more your feedback is not constructive. Because of this we cannot evaluate how you give constructive feedback.`);
}

if (C16) {
    ret.push(`Thank you for sharing your third piece of feedback.
You did a great job highlighting your colleague's strengths and the positive impact they have had on the team. It's always important to acknowledge and appreciate the good work of our colleagues.
However, we were hoping to see how you would share constructive feedback with your colleagues instead of only a positive feedback.
Being able to share constructive feedback in a way that strengthens your relationship with your colleague is hard. This is why we were hoping to see how you do it.

In any case, here are some tips you can use in the future:
- Be specific, actionable and polite.
- Focus on the problem and not on the person. Do your best to separate the issue you would like to address from the person you are giving feedback to.
- Use a conciliatory language. Rather than saying "You did... and I think...", try to frame the situation using us/we. For example: "I've noticed that this happened, what if we... Do you think this could work?".`);
}

if (!Z0 && !Z1) {
    ret.push(`Overall, you're doing a great job with your feedback. Now you should make sure to keep up the good work, and continue to focus on being specific, actionable, and positive in your approach.
Good luck!`);
}


return ret;
};
