import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for stating your team.`);
  }

  if (A2) {
    ret.push(
      `Well done! You added a sprint lead who will be responsible for the sprint execution.`
    );
  }

  if (A3) {
    ret.push(
      `You defined the perfect amount of 2-3 team members, additional to the other roles. Remember, you can always invite additional people for brainstorming sessions, ask for their input in interviews or test your prototypes with them!`
    );
  }

  if (A4) {
    ret.push(
      `You also included a team sponsor as final decision maker. That's great! Involving this leadership roles at important decision points makes sure that your sprint will produce valuable output for the company.`
    );
  }

  if (A5) {
    ret.push(
      `Good job! You already went ahead and thought of other supporting people for the sprint!`
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `To allow for a smooth execution of the sprint, you should define a sprint lead, who plans and acts as a facilitator during the sprint.`
    );
  }

  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `You defined more or less than 2-3 team members, additional to the other roles. A core team which is too big will waste time on discussion, and communication becomes inefficient. However, you can always invite additional people for brainstorming sessions, ask for their input in interviews or test your prototypes with them!`
    );
  }

  if (!Z0 && !Z1 && A4 === false) {
    ret.push(
      `Unfortunately, you forgot to included a team sponsor as final decision maker. Involving this leadership roles at important decision points makes sure that your sprint will produce valuable output for the company.`
    );
  }

  return ret;
};
