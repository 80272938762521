import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `No submission.

We would have liked to see what learnings and insights came up during your discussion.
We can only advise you to try your best and submit all of your results. This will allow us to give you meaningful feedback and accelerate your learning process.

The goal of this exercise was to check how aligned the members of your team are. There is no right or wrong answer, but we would expect that in some cases, you would all agree, and in others, you would disagree.
The goal is then to understand those differences and make good use of them. Just make sure to share your information, listen to each other and cooperate.
By doing that, you can reduce any friction that might arise and increase the benefits of having diversity in your team. This can leverage your team's informational diversity and help you achieve better solutions.`
    );
  }

  if (Z1) {
    ret.push(
      `Thank you for sharing some of the learnings made during these discussions with your team.
Hopefully, this has made you realize how different cultures and backgrounds can lead to different interpretations of the same situation.
Now it is important for you to understand those differences and make good use of them. Just make sure to share your information, listen to each other and cooperate. This should help strengthen your team working and leadership skills.`
    );
  }

  if (Z2) {
    ret.push(
      `Thank you for sharing some of the learnings made during these discussions with your team.
Hopefully, this has made you realize how different cultures and backgrounds can lead to different interpretations of the same situation.
Now it is important for you to understand those differences and make good use of them. Just make sure to share your information, listen to each other and cooperate. This should help strengthen your team working and leadership skills.`
    );
  }

  return ret;
};
