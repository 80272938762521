import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Your idea to improve the user experience and the explanation of how it will create a desired emotional shift for the user is excellent. Your understanding of the connection between the user experience and emotions highlights your commitment to creating a positive impact for the customer. The focus on creating an emotional shift demonstrates a deep understanding of the customer and their needs, and will lead to more effective and meaningful solutions. Your idea has the potential to drive innovation and create a truly impactful user experience. Well done!`
    );
  }

  return ret;
};
