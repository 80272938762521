import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A2) {
    ret.push(
      `Thank you for stating and explaining your most relevant risk in relation to your project. This is the first step of setting up a risk handling strategy. Furthermore, your description in relation to the project helps us to evaluate your answers in the following exercises.`
    );
  }

  if (A1 && A2 === false) {
    ret.push(
      `Thank you for stating and explaining your most relevant risk in relation to your project. This is the first step of setting up a risk handling strategy. Unfortunately, you lack a clear explanation how it relates to the project. This would have helped us to better understand and evaluate your answers in the following exercises.`
    );
  }

  if (A3) {
    ret.push(
      `However, please make sure to explain your risk better. Why would this stuation take place? Understanding this will help you come up with better risk handling manoeuvres and us to give you better feedback.`
    );
  }

  return ret;
};
