import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A0, A1, A2, A3 } = getSelectedOptionsFlags(selectedOpts);

  if (A0) {
    ret.push(
      `It was not clear to us what your epicenter is. You should have stated an epicenter from your business model canvas to use in this exercise. This is the first step to reframing how you think about an element in your business model canvas that can spark business model innovation.`
    );
  }

  if (A1 && A2 === false) {
    ret.push(
      `You stated an epicenter from your business model canvas to use in this exercise. This is the first step to reframing how you think about an element in your business model canvas that can spark business model innovation.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A1 === false && A0 === false) {
    ret.push(
      `You failed to state an epicenter that you would like to work with in this exercise. Go back to your business model canvas and consider an element that would be interesting for your to further develop or innovate around. This is the first step to reframing how you think about an element in your business model canvas that can spark business model innovation.`
    );
  }

  if (A1 && A2) {
    ret.push(
      `Great job! You have picked a good element as your epicenter and made it very clear why you chose this element specifically.`
    );
  }

  if (A3) {
    ret.push(
      `You did not include a sufficiently good explanation as to why you chose the epicenter that you did. Please reflect on why this element is interesting and how it might be useful to consider as your epicenter. It's important to think critically about the significance of the element in your business model to begin to understand how you might innovate around the element and spark new and relevant ideas.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A0 === false && A2 === false && A3 === false) {
    ret.push(
      `However, you did not include a sufficient explanation as to why you chose the epicenter that you did. Please reflect on why this element is interesting and how it might be useful to consider as your epicenter. It's important to think critically about the significance of the element in your business model to begin to understand how you might innovate around the element and spark new and relevant ideas.`
    );
  }

  return ret;
};
