import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.

Still, we would like to remind you that, while we only asked you to create one persona and one user journey, in a more comprehensive study you should create multiple personas and create a user journey for each of these personas.
This will help you cover different needs and frustrations and make it more likely that your final solution addresses different types of users and covers the needs of a higher share of the market.`);
  }

  if (Z1) {
    ret.push(`Well done.
You have uploaded a detailed user journey focusing on your persona submitted earlier.
It is well structured and focuses on all the main pain points and experiences of your user.
Doing this will help you and your team visualize very well how users experience your solution and wi help you identify and address relevant points.

Furthermore, while we have asked you to create only one persona and one user journey, in a more comprehensive study you would create multiple personas and create a user journey for each of these personas.
This would help you cover different needs and frustrations and make it more likely that your final solution addresses different types of users and covers the needs of a higher share of the market.`);
  }

  return ret;
};
