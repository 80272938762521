import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for sharing all the data extraction and clustering your team went through. By looking at your Mural samples, it's very clear and easy to follow your team's process and how your learnings led to your Personas. Well done!`
    );
  }

  return ret;
};
