import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (scoredPoints > 0) {
    ret.push(
      `Thank you for your reflection! It was interesting to read about your experience and your thoughts!`
    );
  }

  return ret;
};
