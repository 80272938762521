import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, E0, E1, E2, E3, E4, E5, E6, E7, E8, E9, E10 } =
    getSelectedOptionsFlags(selectedOpts);

  /*
 {id = "#6", text = "----- ----- ----- ----- If textboxes above submitted ----- ----- ----- ----- ----- ", points = 0},
    {id = "E0", text = "Submitted persona and filled all textboxes above", points = 0.5},
    {id = "E10", text = "Did not submit persona but filled all textboxes above", points = 0},
    {id = "#7", text = "----- ----- ----- ----- If only persona uploaded ----- ----- ----- ----- ----- ", points = 0},
    {id = "E1", text = "Persona's description is included", points = 0.5},
    {id = "E2", text = "Good Narrative (you feel like you know this person a little after reading this)", points = 1},
    {id = "E3", text = "Good amount of behaviours included", points =0.5},
    {id = "E4", text = "Good amount of needs included", points = 0.5},
    {id = "E5", text = "Good amount of goals included", points = 0.5},
    {id = "E6", text = "Includes some illustration/Sketch", points = 0.5},
    {id = "E7", text = "File is clear and readable", points = 0.5},
    {id = "E8", text = "Personas needs/goals/beehaviours relate somewhat to the business idea", points = 0.5},
  */
  if (E0) {
    ret.push(
      `Thank for submitting this persona even though you had already filled out the textboxes above!`
    );
  }

  if (E10) {
    ret.push(`No submission.`);
  }

  if (E3 && E4 && E5 && E7 && E8) {
    ret.push(
      `Good job!
You have given your persona a clear identity by including a good amount of needs, behaviors and goals.
This will improve your personas identity and make it easier for you to understand their routine and step into their shoes.
Ultimately, this will allow you to design better products and services.`
    );
  }

  if (E3 && E4 && !E5 && E7 && E8) {
    ret.push(
      `Good job!
You have given your persona a good identity by including a good amount of needs and behaviors.
This will improve your persona's identity and make it easier for you to understand their routine and step into their shoes.
However, you could have put more emphasis on their goals and aspirations as well. Right now, these are not described well enough.
Ultimately, improving your persona will allow you to design better products and services as it will help you aggregate all the relevant user data from your user research.`
    );
  }

  if (E3 && !E4 && E5 && E7 && E8) {
    ret.push(
      `Good job!
You have given your persona a good identity by including a good amount of goals and behaviors.
This will improve your personas identity and make it easier for you to understand their routine and aspirations.
However, you could have put more emphasis on their needs as well. Right now, these are not described as well as they should.
Ultimately, improving your persona will allow you to design better products and services as it will help you aggregate all the relevant user data from your user research.`
    );
  }

  if (E3 && !E4 && !E5 && E7 && E8) {
    ret.push(
      `You have described your personas relevant behaviors quite well.
This will improve your personas identity and make it easier for you to step into their shoes.
However, you should put more emphasis on their goals and needs.
These are crucial as they will help you focus on your users current issues and what is important to them.
Ultimately, doing this will allow you to design better products and services for your users`
    );
  }

  if (!E3 && E4 && E5 && E7 && E8) {
    ret.push(
      `Good job!
You have given and described your persona's needs and goals quite well.
These are very relevant as they will help you address your users's issues and focus on what is important for them today and in the future.
However, you could have put more emphasis on their behaviors as well. Right now, these are not described well enough.
This will help you construct your persona's identity and make it easier for you to understand their routine and step into their shoes.
Ultimately, improving your persona will allow you to design better products and services as it will help you aggregate all the relevant user data from your user research.`
    );
  }

  if (!E3 && E4 && !E5 && E7 && E8) {
    ret.push(
      `You have given and described your persona's needs quite well.
This is relevant as it will help you address your users's issues and focus on what is important for them today.
However, you could have put more emphasis on their behaviors and goals as well. Right now, these are not described well enough.
This will help you construct your persona's identity and make it easier for you to understand their routine and aspirations.
Ultimately, improving your persona will allow you to design better products and services as it will help you aggregate all the relevant user data from your user research.`
    );
  }

  if (!E3 && !E4 && E5 && E7 && E8) {
    ret.push(
      `You have given your persona a good set of goals and aspirations.
This will help you visualize what is important to your users and help you focus on big picture issues for them.
However, you could have put more emphasis on their needs and behaviors as well. Right now, these are not described as well as they should.
This will help you construct your persona's identity and make it easier for you to understand their routine and current issues.
Ultimately, improving your persona will allow you to design better products and services as it will help you aggregate all the relevant user data from your user research.`
    );
  }

  if (
    E2 &&
    E7 &&
    E8 &&
    ((E3 && E4 && E5) || (E3 && E4 && !E5) || (E3 && !E4 && E5) || (!E3 && E4 && E5))
  ) {
    ret.push(
      `Furthermore, the description of your persona is very convincing.
You have managed to create a narrative that makes the reader feel like he knows this persona a little after reading your description.
This is very good as it will make it easier for you and your team to step into your persona's shoes and identify needs, frustrations and possible solutions. Well done!`
    );
  }

  if (!Z0 && !Z1 && !E3 && !E4 && !E5 && E7 && !E10) {
    ret.push(
      `We believe you should write out the needs, behaviors and goals of your persona with more detail.
Doing so will assist you to construct your persona's identity and make it easier for you to understand their routine, priorities and current issues.
Ultimately, this will help you address your users's issues and focus on what is important for them today and in the future.
Please make sure to improve your persona by adding more details and learnings from your user research before continuing your work.
There will be no need to resubmit this exercise now, but this information will help you as you proceed.`
    );
  }

  if (
    E2 &&
    E7 &&
    E8 &&
    ((!E3 && !E4 && E5) || (!E3 && E4 && !E5) || (E3 && !E4 && !E5) || (!E3 && !E4 && !E5))
  ) {
    ret.push(
      `On the other hand, the description of your persona is quite convincing. Good job
You have managed to create a narrative that makes the reader feel like he knows this persona a little after reading your description.
This is very good as it will make it easier for you and your team to step into your persona's shoes and identify needs, frustrations and possible solutions.`
    );
  }

  /*
  if (E3 && E4 === false && E5 === false && !E2) {
    ret.push(
      `Good job naming so many different behaviors.
This will improve your Personas identity and make it easier for you to understand their routine and step into their shoes.`
    );
  }
  if (E4 && E3 === false && E5 === false && !E2 && !E3) {
    ret.push(
      `Good job stating these many needs!
These should represent well the needs of the users you are focusing on. This is the most important part of your persona, as these are the issues you must tackle to create the most value for your users.`
    );
  }
  if (E5 && E4 === false && E3 === false && !E2 && !E3 && !E4) {
    ret.push(
      `Good job naming so many different goals for your persona.
This will help you focus on what really matters for your users and, therefore, design better products and services.`
    );
  }
  if (E3 && E4 && E5 === false) {
    ret.push(
      `Good job naming so many different behaviors and needs for your persona!
This will improve your personas identity and make it easier for you to understand their routine and step into their shoes.
Ultimately, this will allow you to design better products and services.`
    );
  }
  if (E3 && E5 && E4 === false) {
    ret.push(
      `Good job naming so many different behaviors and goals for your persona!
This will improve your personas identity and make it easier for you to understand their routine and step into their shoes. Furthermore, understanding their goals will help you focus on what really matters for your users.
Ultimately, these things will allow you to design better products and services.`
    );
  }
  if (E5 && E4 && E3 === false) {
    ret.push(
      `Good job naming so many different goals and needs for your persona!
These will help you focus on the issues and aspirations identified in M1.
Ultimately, this is the most important part of your persona, as these are the issues you must tackle to create the most value for your users.`
    );
  }
*/

  /*
  if (E8) {
    ret.push(`Well done.
The connection and relevance of your persona in relation to your research question is very clear.`);
  }
*/

  /*
  if (!Z0 && !Z1 && E4 === false) {
    ret.push(`You did not include enough needs in your submission and they could have been more detailed as well.
Needs can be interpreted as the requirements your product or service will have to address. It is, therefore, a good idea to think of several needs that might be relevant for your persona. Taking several needs into account will help you design more human-centered products and services.
Thus, it would be best if you tried to come up with at least three needs for your persona.`);
  }
  if (!Z0 && !Z1 && E5 === false) {
    ret.push(
      `What motivates your persona? You did not provide enough goals and details to fully answer this question. Similarly to needs, deeply understanding the aspirations of your users will help you create more value for them. Ultimately this should be your biggest goal. Therefore, we recommend you take a look at the user research you have already performed and identify more goals and aspirations.`
    );
  }
*/

  if (!Z0 && !Z1 && E8 === false && (E1 || E2 || E3 || E4 || E5 || E6 || E7)) {
    ret.push(`It seems to us that your persona does not fit your research question that well. Ideally, you should try to aggregate the most relevant traits discovered during your user reserach into these personas. They should represent relevant customer or stakeholder segments.
This will allow you to make the most of your personas later.`);
  }
  /*
  if (!Z0 && !Z1 && E3 === false) {
    ret.push(
      `You did not state enough typical behaviors for your persona. Stating several behaviors you have identified during your user research will improve your Personas identity and make it easier for you to understand their routine and step into their shoes. This, in turn, will help you identify more needs and goals and consequently design better products and services. This is why we advise you to analyze your findings more M1 again and look for more typical behaviors that you could include in the description of your persona.`
    );
  }
*/
  if (!Z0 && !Z1 && E1 === false && (E1 || E2 || E3 || E4 || E5 || E6 || E8)) {
    ret.push(`The description of your persona is missing.
Ideally, you should try to create a narrative that makes the reader feel like he knows this persona a little after reading your description.
This will make it easier for you and your team to step into your persona's shoes and identify needs, frustrations and possible solutions.`);
  }

  if (
    !Z0 &&
    !Z1 &&
    (E1 || E2 || E3 || E4 || E5 || E6 || E7 || E8) &&
    E2 === false &&
    ((!E3 && !E4 && E5) || (!E3 && E4 && !E5) || (E3 && !E4 && !E5) || (!E3 && !E4 && !E5))
  ) {
    ret.push(
      `Furthermore, the description of your persona is not very convincing.
The goal of a good description is to create a narrative that will make the reader feel like he knows this persona a little after reading it. This is very important as it will make it easier for you and your team to step into your persona's shoes and identify needs, frustrations and possible solutions.`
    );
  }

  if (
    !Z0 &&
    !Z1 &&
    E2 === false &&
    (E1 || E2 || E3 || E4 || E5 || E6 || E7) &&
    ((E3 && E4 && E5) || (E3 && E4 && !E5) || (E3 && !E4 && E5) || (!E3 && E4 && E5))
  ) {
    ret.push(
      `On the other hand, the description of your persona is not very convincing.
The goal of a good description is to create a narrative that will make the reader feel like he knows this persona a little after reading it. This is very important as it will make it easier for you and your team to step into your persona's shoes and identify needs, frustrations and possible solutions.`
    );
  }
  /*
  if (E6) {
    ret.push(
      `Lastly, good job including an illustration.
This will help you create a better identity for your persona, which in turn will help you assess your products better through its perspective.`
    );
  }
*/
  if (
    !Z0 &&
    !Z1 &&
    E6 === false &&
    (E1 || E2 || E3 || E4 || E5 || E6 || E7) &&
    scoredPoints !== 4
  ) {
    ret.push(
      `Lastly, your persona would have been even better if you had included an illustration or sketch.
This will help you create a better identity for your persona, which in turn will help you assess your products better through its perspective.`
    );
  }

  if (
    !Z0 &&
    !Z1 &&
    E6 === false &&
    (E1 || E2 || E3 || E4 || E5 || E6 || E7) &&
    scoredPoints === 4
  ) {
    ret.push(
      `On the other hand, your persona would have been even better if you had included an illustration or sketch.
This will help you create a better identity for your persona, which in turn will help you assess your products better through its perspective.`
    );
  }

  return ret;
};
