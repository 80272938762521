import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } =
    getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "#16", text = "----- ----- ----- ----- ----- Full points (Pick only this!)----- ----- ----- ----- ", points = 0},
    {id = "A9", text = "Select only this for perfect submission (Full points)", points = 4.5},
    {id = "#14", text = "----- ----- ----- ----- ----- Alternative Criteria (Pick all that apply)----- ----- ----- ----- ", points = 0},
    {id = "A1", text = "Persona's description is not detailed enough", points = -0.5},
    {id = "A2", text = "Good persona's description", points = 0.5},
    {id = "A3", text = "Good amount of behaviours included", points = 0.66},
    {id = "A4", text = "Good amount of needs included", points = 0.66},
    {id = "A5", text = "Good amount of goals included", points = 0.67},
    {id = "A6", text = "Includes some illustration/Sketch", points = 0.5},
    {id = "#18", text = "----- ----- ----- ----- ----- Select level of detail of entire ----- ----- ----- ----- ", points = 0},
    {id = "A15", text = "Very detailed and well thought Persona", points = 1.5},
    {id = "A11", text = "Detailed and well thought Persona", points = 1.2},
    {id = "A12", text = "Acceptably detailed Persona", points = 0.9},
    {id = "A13", text = "NOT enough details", points = 0.4},
    {id = "A14", text = "Very poor level of detail", points = 0},
    {id = "#15", text = "----- ----- ----- ----- ----- Additional stuff----- ----- ----- ----- ", points = 0},
    {id = "A10", text = "More than one persona submitted (Additional points)", points = 0.5},
  */

  if (!A1 && A3 && A4 && A5 && !A9) {
    ret.push(
      `You have given your persona a clear identity by including a good amount of needs, behaviors and goals.
This will improve your personas identity and make it easier for you to understand their routine and step into their shoes.
Ultimately, this will allow you to design better products and services. Good job!`
    );
  }

  if (!A1 && A3 && A4 && !A5 && !A9) {
    ret.push(
      `You have given your persona a good identity by including a good amount of needs and behaviors.
This will improve your persona's identity and make it easier for you to understand their routine and step into their shoes.
However, you should have put more emphasis on their goals and aspirations as well. Right now, these are not described well enough.
Ultimately, improving your persona will allow you to design better products and services as it will help you aggregate all the relevant user data from your user research.`
    );
  }

  if (!A1 && A3 && !A4 && A5  && !A9) {
    ret.push(
      `You have given your persona a good identity by including a good amount of goals and behaviors.
This will improve your personas identity and make it easier for you to understand their routine and aspirations.
However, you should have put more emphasis on their needs as well. Right now, these are not described as well as they should.
Ultimately, improving your persona will allow you to design better products and services as it will help you aggregate all the relevant user data from your user research.`
    );
  }

  if (!A1 && A3 && !A4 && !A5  && !A9) {
    ret.push(
      `You have described your personas relevant behaviors quite well.
This will improve your personas identity and make it easier for you to step into their shoes.
However, you should put more emphasis on their goals and needs.
These are crucial as they will help you focus on your users current issues and what is important to them.
Ultimately, doing so will allow you to design better products and services for your users`
    );
  }

  if (!A1 && !A3 && A4 && A5  && !A9) {
    ret.push(
      `You have given and described your persona's needs and goals quite well.
These are relevant as they will help you address your users's issues and focus on what is important for them today and in the future.
However, you should have put more emphasis on their behaviors as well. Right now, these are not described well enough.
This will help you construct your persona's identity and make it easier for you to understand their routine and step into their shoes.
Ultimately, improving your persona will allow you to design better products and services as it will help you aggregate all the relevant user data from your user research.`
    );
  }

  if (!A1 && !A3 && A4 && !A5  && !A9) {
    ret.push(
      `You have given and described your persona's needs quite well.
This is relevant as it will help you address your users's issues and focus on what is important for them today.
However, you should have put more emphasis on their behaviors and goals as well. Right now, these are not described well enough.
Doing so will help you construct your persona's identity and make it easier for you to understand their routine and aspirations.
Ultimately, improving your persona will allow you to design better products and services as it will help you aggregate all the relevant user data from your user research.`
    );
  }

  if (!A1 && !A3 && !A4 && A5  && !A9) {
    ret.push(
      `You have given your persona a good set of goals and aspirations.
This will help you visualize what is important to your users and help you focus on big picture issues for them.
However, you should have put more emphasis on their needs and behaviors as well. Right now, these are not described as well as they should.
Doing so will help you construct your persona's identity and make it easier for you to understand their routine and current issues.
Ultimately, improving your persona will allow you to design better products and services as it will help you aggregate all the relevant user data from your user research.`
    );
  }

  if (!Z0 && !Z1 && !A3 && !A4 && !A5 && !A9) {
    ret.push(
      `Thank you for your submission.
However, we believe that you should write out the needs, behaviors and goals of your persona in more detail.
Doing so will allow you to construct your persona's identity and make it easier for you to understand their routine, priorities and current issues.

Ultimately, this will help you address your users's issues and focus on what is important for them today and in the future.
Therefore, please make sure to improve your persona by adding more details and learnings from your user research before continuing your work.
There will be no need to resubmit this exercise now, but this information will help you as you proceed.`
    );
  }

  if (
    A1 
    && (
      (!A3 && !A4 && A5 ) ||
      (!A3 && A4 && !A5 ) ||
      (!A3 && A4 && A5 ) ||
      (A3 && !A4 && !A5 ) ||
      (A3 && !A4 && A5 ) ||
      (A3 && A4 && !A5)) && !A9 ) {
    ret.push(
      `The description of your persona is not detailed enough.
To make sure that your persona fits a proper demographic, it is important that the Name&Sketch section has enough information on your users.
Make sure to include not only information like age, gender, job, role and responsibilities bit also some more personal information like likes&dislikes, hobbies and habits.
Doing so will make it easier for you and your team to step into your persona's shoes and identify needs, frustrations and possible solutions.`
    );
  }

  if (
    A1 &&
    !(
      (!A3 && !A4 && A5 ) ||
      (!A3 && A4 && !A5 ) ||
      (!A3 && A4 && A5 ) ||
      (A3 && !A4 && !A5 ) ||
      (A3 && !A4 && A5 ) ||
      (A3 && A4 && !A5 )) && !A9 ) {
    ret.push(
      `On the other hand, the description of your persona is not detailed enough.
To make sure that your persona fits a proper demographic, it is important that the Name&Sketch section has enough information on your users.
Make sure to include information like age, gender, job, role, responsibilities and so on.
Doing so will make it easier for you and your team to step into your persona's shoes and identify needs, frustrations and possible solutions.`
    );
  }

  if (A10) {
    ret.push(`Thank you for uploading more than one persona!
In your future projects you should certainly try to identify more than one. However, to keep this course a bit simpler, we ask you to pick and focus on one of your personas for the next exercises.`);
  }

  if (!Z0 && !Z1 && A6 === false && scoredPoints !== 4 && !A9) {
    ret.push(
      `Lastly, you did not include any illustrations or sketches to visualize your persona better.
Doing so will help you create a better identity for your persona, which in turn will help you assess your products better through its perspective.`
    );
  }

  if (!Z0 && !Z1 && A6 === false && scoredPoints === 4 && !A9) {
    ret.push(
      `On the other hand, you did not include any illustrations or sketches to visualize your persona better.
Doing so will help you create a better identity for your persona, which in turn will help you assess your products better through its perspective.`
    );
  }

  if (A9) {
    ret.push(
      `You have given your persona a clear identity by including a good amount of needs, behaviors and goals.
This will improve your personas identity and make it easier for you to understand their routine and step into their shoes.
Ultimately, this will allow you to design better products and services.`
    );
  }

  return ret;
};
