import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `No submission. The overarching question is central to your user research. All the following steps will be based on it. Therefore, it is very important that you consider your stakeholders, the relevant topics for your challenge, and come up with a relevant question that you would like to work with. Don't forget this question is not final. As you progress and learn more, you might (and should) adjust it to reflect better the needs of your participants.`
    );
  }

  if (Z1) {
    ret.push(
      `Very well done! Your overarching question is well defined, has the proper scope (neither too broad nor too narrow), and addresses some of your main assumptions and themes. This question will now serve as a guide for all the following steps in your user needs research. Just don't forget that this overarching question is not final! It can (and should) be adapted as you progress and learn more about your participant's needs.`
    );
  }

  return ret;
};
