import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { C0, C1, C2, C3 } = getSelectedOptionsFlags(selectedOpts);

  if (C0) {
    ret.push(`No submission.`);
  }

  if (C1) {
    ret.push(
      `Needs can be interpreted as the requirements your product or service will have to address. It is, therefore, a good idea to think of several needs that might be relevant for your persona. Taking several needs into account will help you design more human-centered products and services.
It would be best if you tried to come up with at least three needs for your persona.`
    );
  }

  if (C2) {
    ret.push(
      `Needs can be interpreted as the requirements your product or service will have to address. It is, therefore, a good idea to think of several needs that might be relevant for your persona. Taking several needs into account will help you design more human-centered products and services.
It would be best if you tried to come up with at least three needs for your persona.`
    );
  }

  if (C3) {
    ret.push(
      `You have stated a good amount of needs.
These should represent well the needs of the users you are focusing on. This is the most important part of your persona, as these are the issues you must tackle to create the most value for your users.`
    );
  }

  return ret;
};
