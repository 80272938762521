import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, B1, B2, B3, B4, B5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (scoredPoints < 5 && B1) {
    ret.push(
      `Thank you for submitting your sprint execution plan. Here are some aspects you can improve on in order to have more learnings and better outputs of your sprint:`
    );
  }

  if (B2) {
    ret.push(
      `From your submission it seems to us that you have not focused enough on running a proper challenge and stakeholder scoping during your first week.`
    );
  }

  if (B3) {
    ret.push(
      `A customer discovery phase is essential to truly understand the needs and jobs-to-be-done of your target customer. Even if this was covered in a previous sprint, we still recommend having a brief phase to validate your latest assumption and have everybody in the team hear about the needs directly from the user.`
    );
  }

  if (B4) {
    ret.push(
      `You did not include an ideation phase. An innovation sprint should never be used to execute a predefined solution, but to come up with and test a variety of assumptions and solutions. The only exception can be a sprint that directly follows on a previous sprint, and the team has already had an ideation phase on this challenge.`
    );
  }

  if (B1 && !B2 && !B3 && !B4 && !B5) {
    ret.push(`Great work!
A sprint execution plan is essential to a Sprint, as you need to know when you are going to do what. You included all the relevant phases for your sprint. Planning them allows to better align the team, include stakeholders, and define decision criteria and deadlines. You will continue to add more details to the sprint plan in the next assignments.`);
  }

  return ret;
};
