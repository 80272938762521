/** @jsx jsx */
import { jsx, Text } from "theme-ui";
import * as React from "react";
import { ReactComponent as Logo } from "@sparkademy/app-common/materials/logos/logo-sideways.svg";
import { Link } from "react-router-dom";
import { Container } from "@sparkademy/app-common/elements/Container";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { ButtonGhost } from "@sparkademy/app-common/elements/ButtonGhost";

export const Header: React.FC = () => {
  const { currentUser, logout } = useSessionContext();

  return (
    <header
      sx={{
        bg: "new.primary.white",
        borderBottom: theme => `1px solid ${theme.colors.new.secondary.grey}`,
      }}
    >
      <Container
        sx={{
          minHeight: 96,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          to="/"
          aria-label="homepage"
          sx={{
            display: "block",
            svg: {
              display: "block",
            },
          }}
        >
          <Logo height={21} aria-hidden="true" focusable="false" />
        </Link>

        {currentUser && (
          <div
            sx={{
              display: "flex",
              alignItems: "baseline",
              fontSize: "18px",
            }}
          >
            <Text
              as="span"
              sx={{
                ml: 2,
                fontWeight: 500,
              }}
            >
              {currentUser.username}
            </Text>
            <span aria-hidden="true" sx={{ mx: 2 }}>
              |
            </span>
            <ButtonGhost
              sx={{ px: 0, fontSize: "18px", fontFamily: "inherit" }}
              onClick={() => logout()}
            >
              Logout
            </ButtonGhost>
          </div>
        )}
      </Container>
    </header>
  );
};
