import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`);
  }

  if (Z1) {
    ret.push(`Good selection of participants! You have selected a good number of diverse participants and gave a good description of their backgrounds and specialties. Well done!
Having a diverse team will make it more likely for your team to perform well in such divergent tasks and come up with several (and possibly innovative) solutions.`);
  }

  return ret;
};
