import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A0) {
    ret.push(`No submission`);
  }

  if (A4) {
    ret.push(`You did not make it very clear what insight you chose.
In similar exercises in the future, we ask you to repete what insight was chosen.
This makes it easier to understand your answer and give you more effective feedback.`);
  }

  if (A1) {
    ret.push(`Very well done.
You have given very good arguments to explain how this insight is supported by evidence collected during your user research.
The evidence you gave is also direct evidence (quotes, findings, observations, etc), which makes your logic more credible. Good job!`);
  }

  if (A2) {
    ret.push(`Good job. While you did give good arguments to explain how this insight is supported by evidence collected during your user research, you dd not give enough direct evidence to support that (quotes, findings, observations, etc).
Ideally, you could try to quote some of your participants or mention how often a certain need/issue/desire was mentioned during your interviews.
Doing so would give your logic even more credibility!`);
  }

  if (A3) {
    ret.push(`We feel like you did not argument well enough to explain how this insight is supported by evidence collected during your user research, you dd not give enough direct evidence to support that (quotes, findings, observations, etc).
Furthermore, you also did not really give any direct evidence to support your statements.
Ideally, you should try to quote some of your participants or mention how often a certain need/issue/desire was mentioned during your interviews.
Doing so would give your logic even more credibility!`);
  }

  /*

    { id= "A0", text= "No submission", points= 0 },
    { id= "A1", text= "Very good arguments with direct support (quotes, findings...)", points= 3 },
    { id= "A2", text= "Good arguments but no direct support ()", points= 1.5 },
    { id= "A3", text= "Bad argumetation with no support ()", points= 0.5 },

  */

  return ret;
};
