import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Well done! You correctly identified the total needed investment to run your project for 12 month. `
    );
  }

  if (A2) {
    ret.push(
      `You planned ahead well and will not end up with a negative ending cash balance throughout the project. Good job!`
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `The total investment you stated does not align with the cash flow forecast you uploaded. You total investment should be the sum off all investments over the next 12 month.`
    );
  }

  if (!Z0 && !Z1 && A2 === false && A4 === false) {
    ret.push(
      `Unfortunatly, you will end up with a negative ending cash balance in some month. This means you might require a higher investment or need to plan the time to receive the investment more strategically. `
    );
  }

  if (A3) {
    ret.push(
      `Thank you for your submission.
While you did identify your total expenditures correctly (for these twelve months), this is not the necessary investment (or funding) to run your business as you will already make some profit by then.
You will only need to fund yourself in months where your expenditures is higher than your incomes. Which is common on the first months (or sometimes years of your business).
Therefore, the necessary investment to run your project is just the sum of the negative operating cash flows in the beginning of your project.
Once your profitable, then you don't need further investments.`
    );
  }

  if (A4) {
    ret.push(
      `Well done. You have calculated the correct investment that is necessary to fund your project through its early days.
However, you did not include this value in the "investment" section of your financial plan, which led to the negative cash balance for some of your months.
In the future, please add this necessary funding to your financial statement to avoid a negative cash flow. This way the company will always account for enough cash to pay for the running project.`
    );
  }

  return ret;
};
