import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `No submission. A good introduction should contain all the following information: a self-introduction, a topic introduction, a statement of voluntary nature, and you should try to build rapport by ensuring that there are no right or wrong answers and by including some introductory questions to break the ice. All of this will increase the chance that your interviewee will feel comfortable and your interview will be insightful.`
    );
  }

  if (Z1) {
    ret.push(
      `Great job! Your introduction contains all the relevant information such as a self-introduction, a topic introduction, a statement of voluntary nature, and you made sure to build rapport by ensuring that there are no right or wrong answers and by including some introductory questions to break the ice. All of this will increase the chance that your interviewee will feel comfortable and your interview will be insightful. Well done again!`
    );
  }

  return ret;
};
