import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13 } =
    getSelectedOptionsFlags(selectedOpts);

    /*
    {id = "#8", text = "--------------------- Pick One -----------------------------", points = 0},
    {id = "A1", text = "More than 4 actions stated ", points = 2.5},
    {id = "A2", text = "2-4 action stated", points = 1.5},
    {id = "A3", text = "1 action stated", points = 0.5},
    {id = "A0", text = "No actions stated", points = 0},
    {id = "#9", text = "----------------------- Pick One ---------------------------", points = 0},
    {id = "A4", text = "Actions address all inconsistencies previously stated", points = 2.5},
    {id = "A5", text = "Actions address some inconsistencies previously stated", points = 1.5},
    {id = "A6", text = "Actions DO NOT address any inconsistencies previously stated", points = 0},
    {id = "#10", text = "------------------------ Additional Stuff --------------------------", points = 0},
    {id = "A7", text = "Actions relate to team activities and processes ", points = 0.5},
    {id = "A10", text = "Actions relate to business model assessment or changes ", points = 0.5},
    */

  if (A1 || A2) {
    ret.push(
      `Good job listing multiple actions you can take to address inconsistencies in your business model. 
It's important to have several courses of action, so that you can experiment to find the ones that works best.`
    );
  }

  if (A3) {
    ret.push(
      `You have only listed one action to address inconsistencies in your business model. 
In order to experiment and find an effective course of action to improve your business model, you should brainstorm a few more.`
    );
  }

  if (A0) {
    ret.push(
      `You did not list any actions you can take to address the inconsistencies in your business model.  
It's important to have several courses of action, so that you can experiment to find one that works best. Therefore, please make sure to look for several actions you can take in your future Business Model Health Checks`
    );
  }

  if (A4) {
    ret.push(
      `Great job addressing the inconsistencies you have identified in the previous question. It seems that the actions listed here should help you address all your business model's weak points. 
Identifying and addressing inconsistencies and weak points in your current business model is a very simple, but effective way to ensure that what you do still makes sense and is relevant for your users.
We strongly advise you to keep doing this exercise in the future!`
    );
  }

  if (A5) {
    ret.push(
      `It seems that these actions stated here only cover some of the weak-spots and inconsistencies identified earlier. 
Ideally, you should try to look for more actions you can take so that you can address all your previously stated inconsistencies.

Identifying and addressing inconsistencies and weak points in your current business model is a very simple, but effective way to ensure that what you do still makes sense and is relevant for your users.
We strongly advise you to keep doing this exercise in the future!`
    );
  }

  if (A6) {
    ret.push(
      `It seems that you did not address the inconsistencies stated in the previous question with the actions you submitted here. 
Identifying and addressing inconsistencies and weak points in your current business model is a very simple, but effective way to ensure that what you do still makes sense and is relevant for your users.
We strongly advise you to keep doing this exercise in the future!`
    );
  }

  if (A7) {
    ret.push(
      `Nice job incorporating team and collaborative activities into your course of action ideas. Collaboration and getting insights from your team are key to ensuring the changes you make to your business model will be effective.`
    );
  }

  if (A8) {
    ret.push(
      `Well done including actions that use business model innovation techniques to address the inconsistencies in your business model. Business model innovation methods are proven ways to create not only a more effective but also innovative business to deliver and capture value.`
    );
  }

  return ret;
};
