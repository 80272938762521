import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Well done! You have stated great ways to communicate your metrics to your team. 
Your channels and tools of communication are not only very clear and well implemented in the day-to-day activities, but will also help your team stay up-to-date to your progress and allow them to be actively involved in the further development of the project.`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for your submission! Your methods of communication seem to be appropriate to deliver the data you needs to track the progress of your KPIs, but we are lacking further information to assess if and how your team members are involved in this process. Ideally, you should make it very clear how this information will be shared.
Some general examples are: shared and discussed in meetings, being represented on a board or an overview page which team members look at daily, send them via e-mail weekly, etc.`
    );
  }

  if (A3) {
    ret.push(
      `Thank you for your submission! You method of communication is very passiv and if team member want to be on track, they have to search for this information. Your KPIs should be communicated easily, e.g. shared and discussed in meetings, being represented on a board or a overview page which team members look at daily, send them via e-mail weekly, etc.`
    );
  }

  return ret;
};
