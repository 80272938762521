import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } =
    getSelectedOptionsFlags(selectedOpts);

  /*
    { id= "A1", text= "Idea stated", points= 0.5 },
    {id = "#4", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "A2", text= "No argumentation", points= 0 },
    { id= "A3", text= "Brief argumentation (1 or two small sentences)", points= 0.5 },
    { id= "A4", text= "Detailed argumentation", points= 1 },
    { id= "A5", text= "Exceptional argumentation", points= 1.5 },
    {id = "#5", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "A7", text= "Arguments not supported", points= 0 },
    { id= "A8", text= "Arguments supported by findings/quotes/observations", points= 2 },
  */

  if ((A8 || A10) && !((A4 && (A8 || A10)) || (A4 && A7) || (A5 && (A8 || A10))) && !A9) {
    ret.push(`Well done! You have backed your arguments with findings from your user research.
This will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.`);
  }

  if (!A1 && scoredPoints < 2 && !A9) {
    ret.push(`Thank you for stating which idea you would like to explain in more detail.`);
  }

  if (A1) {
    ret.push(`No idea submitted`);
  }

  if (A2 && !A9) {
    ret.push(`However, you did not give any arguments as to why your team decided to try out this idea.
Ideally, you should not only state why this idea is relevant for your business, but also make use of findings to back up your arguments.`);
  }

  if (A3 && !A9) {
    ret.push(`However, your argumentation as to why your team decided to pick this idea is too brief.
You and your team have spent a lot of time working to arrive at these ideas. Therefore, you should make sure that your argumentation as to why you want to try this idea should be very good.
Ideally, you should not only state why this idea is relevant for your business, but also make use of findings to back up your arguments.`);
  }

  if (A4 && (A8 || A10) && !A9) {
    ret.push(`You have given good and detailed arguments to explain why you and your team decided to continue with this idea. More importantly you have also backed your arguments with findings from your user research.
This will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.
Good job!`);
  }

  if (A4 && A7 && !A9) {
    ret.push(`You have given good and detailed arguments to explain why you and your team decided to continue with this idea.
However, you did not support your arguments with findings from your user research.
Doing this will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.`);
  }

  if (A5 && (A8 || A10) && !A9) {
    ret.push(`You have given very good arguments to explain why you and your team decided to continue with this idea.
More importantly you have also backed your arguments with tangible findings from your user research.
This will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.
Very well done!`);
  }

  if (A5 && A7 && !A9) {
    ret.push(`You have given very good arguments to explain why you and your team decided to continue with this idea.
However, you did not support your arguments with findings from your user research.
Doing this will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.`);
  }

  if (A7 && !A4 && !A5 && !A9) {
    ret.push(`Lastly, you did not back up your arguments with findings from your user research.
Doing this will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.`);
  }
  /*
  if ((A8 || A10) && ((A4 && (A8 || A10)) || (A4 && A7) || (A5 && (A8 || A10))) && !A9) {
    ret.push(`Lastly, you have done a good job backing your arguments with findings from your user research.
This will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.`);
  }
*/
  /* Alternative Feedback */
  if (A9) {
    ret.push(`Your argumentation as to why you chose this idea is not great.
It seems that you focused more on the practicability and easiness-to-implement than on your User Research and the needs and pain-points discovered.

At this stage you should always focus on the most relevant idea according to the pain-points identified in module 1.
In our next module you will worry about prototyping this idea and validating its desirability.
You should only worry about viability and feasibility after that. If an idea is not desirable, then you can just scrap or pivot it and you won't even get to waste time thinking on its feasibility.
If, however, during your prototyping phase you realise that this idea is extremely valuable, then, and only then, you can start to think about the how.

I can assure you that if you come up with a great solution you will find a way to bring it to market in one way or another.`);
  }

  return ret;
};
