import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { B1, B2 } = getSelectedOptionsFlags(selectedOpts);

  if (B1) {
    ret.push(`Thank you for reflecting on this.
We are happy to hear that you should have no (planned) events or holidays stopping you from collaborating with your team!`);
  }

  if (B2) {
    ret.push(`Thank you for reflecting on this.
As you have already identified some conflicting events, we suggest that you inform your team to ensure that this does not affect your collaboration.`);
  }

  return ret;
};
