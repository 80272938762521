import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for your submission.  Aim to fill out even more elements of the scoping canvas! It might seem obvious to you in your head, but you never know unless you communicate it clearly to others. The scoping canvas helps you to become more aware of the challenge, and align with your team and leadership.`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for your submission. You are on a good path! Aim to fill out even more elements of the scoping canvas. It might seem obvious to you in your head, but you never know unless you communicate it clearly to others. The scoping canvas helps you to do so, and align with your team and leadership.`
    );
  }

  if (A3) {
    ret.push(
      `Great submission! Your efforts gave you a broad overview of the topic and scope of the challenge you want to tackle. Hopefully, you wrote down everything what is in your head about the challenge. The scoping canvas helps you to do so, and align with your team and leadership.`
    );
  }

  if (A4) {
    ret.push(
      `It is clear and convincing why this challenge is important for the customer and your company. `
    );
  }

  if (!Z0 && !Z1 && A4 === false) {
    ret.push(
      `Unfortunately, the reason why you want to tackle the challenge is not convincing, as stated in both the background and the current situation (pains and opportunities). If you can't formulate a clear reason for your project, this is a huge red flag!`
    );
  }

  if (A5) {
    ret.push(
      `You stated a clear goal in your desired outcomes, and your success metric is measurable. A clear goal is helping you to keep your direction during the sprint, and communicating it with your management aligns the expectations.`
    );
  }

  if (!Z0 && !Z1 && A5 === false) {
    ret.push(
      `A clear goal is helping you to keep your direction during the sprint, and communicating it with your management aligns the expectations. Having a measurable success criteria makes this goal even more tangible. Here, you have some room for improvemnet. Try to define a clear goal and measurable success criteria.`
    );
  }

  if (A6) {
    ret.push(
      `You did a good job explaining the customer segment and their current situation. This helps to know who you want to contact during your research, and which needs and challenges you want to verify.`
    );
  }

  if (!Z0 && !Z1 && A6 === false) {
    ret.push(
      `The customer segment you aim to put at the center of your sprint could be explained better. This helps to know who you want to contact during your research, and which needs and challenges you want to verify. Remember: Stating a customer segment like SMEs, our current customers, mums, etc. is way to broad and will not help you to direct your research.`
    );
  }

  if (A7) {
    ret.push(
      `By stating the key assumptions and unknowns, you showcase a critical reflection on the challenge. What do you assume to know, what do you not know? Those are the topics which will become relevant during your sprint!`
    );
  }

  if (!Z0 && !Z1 && A7 === false) {
    ret.push(
      `You never start a sprint knowing everything alredy, or it wouldn't be a sprint. Embrace the opportunities and think more about what you assume to know and what you don't know? Those are the topics which will become relevant during your sprint!`
    );
  }

  if (A8) {
    ret.push(
      `Your challenge statement, or also called problem statement, is stated clearly and includes a user, their need, and the challenge to solve this need. Although this is a guiding element of your research, don't hesitate to reformulate it if your research falsifies your assumptions or detects a more relevant challenge within the topic! `
    );
  }

  if (!Z0 && !Z1 && A8 === false) {
    ret.push(
      `Your challenge statement, or also called problem statement, is not stated clearly or misses important elements. It should always inlcude a user, their need, and the challenge to solve this need. Dare to be specific! Although this is a guiding element of your research, you cacn stil reformulate it if your research falsifies your assumptions or detects a more relevant challenge within the topic! `
    );
  }

  return ret;
};
