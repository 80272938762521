import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } =
    getSelectedOptionsFlags(selectedOpts);

  /* Quantity */

  if (A1) {
    ret.push(
      `You have submitted less than six themes.
There is nothing special with the number 6. However, it should not be too hard to find several topics that are relevant to your project. We asked you to think of six themes to push you to think about it and maybe recognize one or two topics that are not that obvious. Ideally, it would be best if you tried to think of as many as possible, as this might help you generate more assumptions and consequently, better insights later. `
    );
  }

  if (A2) {
    ret.push(
      `Well done! You have submitted 6 themes as requested.
Taking this many themes into account might help identify more assumptions and consequently define a better overarching question.`
    );
  }

  if (A3) {
    ret.push(
      `Well done! You have submitted even more themes than requested.
Taking this many themes into account might help identify more assumptions and consequently define a better overarching question. Keep up the good work!`
    );
  }

  /* Clarity */

  if (!Z0 && !Z1 && !Z2 && A4 === false && A5 === false) {
    ret.push(
      `Most of your explanations are a bit unclear. Even after reading them, it was still hard to understand how this topic is relevant to your project. After your interview rounds, you may realize that some of the themes you focused on or some of the assumptions you had were not relevant. It will then be very good if you can go back to your notes and understand why exactly you chose that theme or came up with a certain assumption. This is why it is a good idea always to state clearly why they are relevant.`
    );
  }

  if (A5) {
    ret.push(
      `Some of your explanations are a bit unclear. Even after reading them, it was still hard to understand how this topic is relevant to your project. After your interview rounds, you may realize that some of the themes you focused on or some of the assumptions you had were not relevant. It will then be very good if you can go back to your notes and understand why exactly you chose that theme or came up with a certain assumption. This is why it is a good idea always to state clearly why they are relevant.`
    );
  }

  /* Explanations */

  if (A6 && (A2 || A3) && A4) {
    ret.push(
      `Furthermore, all of your themes include a good explanation. It may seem unnecessary to describe them, but doing so will not only make it easier for you to trace back your thoughts, but it will also help people who did not take part in this project to understand them. Therefore, since the effort of writing down a brief explanation of why it is relevant is very small, it is always a good idea to do that.`
    );
  }

  if (A6 && A1) {
    ret.push(
      `While you have not submitted six themes as requested, all the themes you did submit, are very clear and well explained.
Therefore, our only feedback for you is that you try to look for more themes. By understanding all the topics that affect and are affected by your project, you will be able to identify more assumptions that you might have. This is why investing a little bit of time now to look for more themes is a good idea.`
    );
  }

  if (A7) {
    ret.push(
      `Some of your themes do not include an explanation.
It may seem unnecessary to describe every one of them, but doing so will not only make it easier for you to trace back your thoughts, but it will also help people who did not take part in this project to understand how it was developed.
Therefore, since the effort of writing down a brief explanation of why it is relevant is very small, it is a good idea always to do that.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && (A2 || A3) && A4 && A6 === false && A7 === false && A10 === false) {
    ret.push(
      `On the other hand, none of your themes include an explanation.
It may seem unnecessary to describe them, but doing so will not only make it easier for you to trace back your thoughts, but it will also help people who did not take part in this project to understand it.
Therefore, since the effort of writing down a brief explanation of why it is relevant is very small, it is always a good idea to do that.`
    );
  }

  if (
    !Z0 &&
    !Z1 &&
    !Z2 &&
    A2 === false &&
    A3 === false &&
    A6 === false &&
    A7 === false &&
    A10 === false
  ) {
    ret.push(
      `Furthermore, none of your themes include an explanation.
It may seem unnecessary to describe them, but doing so will not only make it easier for you to trace back your thoughts, but it will also help people who did not take part in this project to understand it.
Therefore, since the effort of writing down a brief explanation of why it is relevant is very small, it is always a good idea to do that.`
    );
  }
  /*
  if (A8) {
    ret.push(
      `Lastly, while all the themes you have suggested are really good, we advise you to always include 'Desirability' as well.
This will help you identify assumptions related to
`
    );
  }

  if (A9) {
    ret.push(
      `Well done! You have submitted 6 themes as requested. Taking this many themes into account might help identify more assumptions and consequently define a better overarching question. Keep up the good work!`
    );
  }
*/

  if (A10 && (A2 || A3) && A4) {
    ret.push(
      `On the other hand, you did not give an explanation to every theme individually as requested, but instead gave a general summary for all of them together.
It may seem unnecessary to describe them individually, but doing so will not only make it easier for you to trace back your thoughts, but it will also help people who did not take part in this project to understand it.
Therefore, since the effort of writing down a brief explanation of why each theme is relevant is very small, it is always a good idea to do that.`
    );
  }
  return ret;
};
