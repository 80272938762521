import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2 && A3 && (A4 || A10) && A5 && A6 === false && A7 === false && A8 === false) {
    ret.push(
      `Great job!
Your introduction contains all the relevant information such as a self-introduction, a topic introduction, a statement of voluntary nature, and you made sure to build rapport by ensuring that there are no right or wrong answers and by including some introductory questions to break the ice.
All of this will increase the chance that your interviewee will feel comfortable and your interview will be insightful. Well done again!`
    );
  }

  if (A1 && A2 && A3 && (A4 || A10) && A5 && (A6 || A6 || A8)) {
    ret.push(
      `Good job!
Your introduction contains all the relevant information such as a self-introduction, a topic introduction, a statement of voluntary nature, and you made sure to build rapport by ensuring that there are no right or wrong answers and by including some introductory questions to break the ice.
All of this will increase the chance that your interviewee will feel comfortable and your interview will be insightful.`
    );
  }
  /* I think this next exercise relates to another exercise*/
  if (Z0 === false && Z1 === false && ((scoredPoints < 5 && !A12) || (scoredPoints < 5.1 && A12))) {
    ret.push(
      `Good job. Overall your introduction was quite good.
However, there are a few things you could improve even further to make the most out of this experience:`
    );
  }

  if (Z0 === false && Z1 === false && A1 === false && A11 === false && A6 === false) {
    ret.push(
      `You did not include a short self-introduction.
This is important as it helps you build rapport, thus making the participant feel more comfortable and ensuring better answers and participation.
Next time you should try to briefly say who you are, what you are doing, and maybe even some more stuff depending on how formal the interview is.`
    );
  }

  if (Z0 === false && Z1 === false && A1 === false && A11 && A6 === false) {
    ret.push(
      `You did not include a proper short self-introduction.
This is important as it helps you build rapport, thus making the participant feel more comfortable and ensuring better answers and participation.
Next time you should try to briefly say who you are, what you are doing, why you are doing this (This all depends on the context of the interview of course).`
    );
  }

  if (A6) {
    ret.push(
      `You only mentioned that you would make a self-introduction but did not say exactly what and how you would say it.
This might seem less important, but writing down exactly what you are going to say will help you remember it (making it more natural and easy)  and can also help you realize any mistakes or poor phrasings.
Therefore, you should rewrite this section and state exactly what you will say and ask.`
    );
  }

  if (Z0 === false && Z1 === false && A2 === false && A13 === false && A7 === false) {
    ret.push(
      `You did not introduce the topic of the interview.
Even though the participant is likely to know it already (from the scheduling or any prior conversation), it is always a good idea to repeat that since they may have misunderstood the topic or goal. This will also help you transition from the introduction of the interview to the main part more naturally. `
    );
  }

  if (A13) {
    ret.push(
      `You did not introduce the topic of the interview that well.
Even though the participant is likely to know it already (from the scheduling or any prior conversation), it is always a good idea to repeat that since they may have misunderstood the topic or goal. This will also help you transition from the introduction of the interview to the main part more naturally. `
    );
  }

  if (A7) {
    ret.push(
      `Your topic introduction was not explicit.
It is important to write down exactly how you would introduce it as this will make it easier for you during the interview.
Additionally, this will help you choose a good wording and avoid any repetitions or even mistakes when introducing the topic of the interview. So make sure to always write out exactly what you are going to say when making an interview guideline in the future.`
    );
  }

  if (Z0 === false && Z1 === false && A3 === false && A8 === false) {
    ret.push(
      `You did not make it very clear to the participant that there are no right or wrong answers and that they can say the first thing that comes to their mind.
This may seem unnecessary, but it is important as it makes the participants feel more comfortable and can make your interview flow better.`
    );
  }

  if (Z0 === false && Z1 === false && A4 === false && A8 === false && A10 === false) {
    ret.push(
      `There are no easy questions included to act as ice-breakers and facilitate the conversation.
Next time you should try to ask small things like what their background is and then follow up on something that might be interesting for them.`
    );
  }

  if (Z0 === false && Z1 === false && A5 === false) {
    ret.push(
      `You did not say what this information will be used for and if your interviewee agrees to this use of his/her answers.
It is good practice to include something like that at the beginning of your interview so that the participants know how their answers will be used.`
    );
  }

  if (A9 && !(A1 && A2 && A3 && (A4 || A10) && A5)) {
    ret.push(
      `Lastly, we noticed how you are still inviting the participant to your interview in your introduction.
This is not the idea behind this introduction.
The idea is, once you have identified and invited the participants, to start your interview with a standard statement that includes all the necessary information.
This will help you make sure that all participants have the same initial knowledge about you and your project before they start to answer your questions.
It will also make it easier for you to remember to say everything you wanted to say in your introduction.`
    );
  }

  if (A9 && A1 && A2 && A3 && (A4 || A10) && A5) {
    ret.push(
      `However, we noticed how you are still inviting the participant to your interview in your introduction.
This is not the idea behind this introduction.
The idea is, once you have identified and invited the participants, to start your interview with a standard statement that includes all the necessary information.
This will help you make sure that all participants have the same initial knowledge about you and your project before they start to answer your questions.
It will also make it easier for you to remember to say everything you wanted to say in your introduction.`
    );
  }

  return ret;
};
