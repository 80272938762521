import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(`Good job! Both your ideas were well explained and address the issues mentioned in your problem statement.
More importantly, you have also made it clear why you chose these ideas and have backed up your argumentation with findings from your user research.`);
  }

  if (Z2) {
    ret.push(`Very well done! Both your ideas are very well explained and tackle fundamental issues addressed in your overarching question.
More importantly, you have also made it clear why you chose these ideas and have backed up your argumentation with findings from your user research.`);
  }

  return ret;
};
