import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `This is a clear and well written problem statement.
You have included all the necessary parts (persona, need, constraint) and set a good scope (not too broad, which makes it hard to focus on your issue; nor too specific, which can constrain the possible solutions).
Well done!

Still, here is a brief summary on how to write a great problem statement as a refresher:
A great problem statement should make it very clear what problem you want to tackle, who currently struggles with this problem (your target user) and why this problem is relevant or why it can't be solved easily (your insight/constraint).
Furthermore, it is important to pay attention to the scope of your problem statement. It needs to be focused, but it should not point towards a possible solution already.
Lastly, it is vital that your problem statement tackles a relevant problem. Imagine you are a investor, would you invest in a company that solves the problem you are presenting here? The answer should be a definite yes. If not, go back to your notes and look for a more relevant problem.`
    );
  }

  return ret;
};
