import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`I'm looking at the business models here that you submitted at ex. 4.1. You are mapping the relationships between the different business model elements considering many different aspects. Great choice to use different colors to illustrate the different customer segments at your Vegg+ Business Model.

What we are missing is a certain level of detail and numbers. How many customers do you expect (I know you did some market research)? What do you expect the price point in production and sales to be? How many products can you sell e.g. per month? For your resources: How many people does this involve? How often?
Yes, those numbers will only be estimations at this point. But they can reveal new critical assumptions or a need to change your idea, as the viability of the business model is not given.`);
  }

  return ret;
};
