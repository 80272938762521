import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`
    );
  }

  if (Z1) {
    ret.push(`Well done! The main body of your interview guideline contains only excellent questions. They are all open-ended, non-leading, only ask for one thing at a time, do not ask for solutions, and are all clear and easy to understand. Good job!`
    );
  }

  return ret;
};