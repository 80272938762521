import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for your answer. You have given very good arguments to make it clear why your business is in your suggested business stage. `
    );
  }

  if (A2) {
    ret.push(
      `Thank you for your answer. You have given good arguments to make it clear why your business is in your suggested business stage.`
    );
  }

  if (A3) {
    ret.push(
      `Thank you for your answer. You have made it clear why your business is in your suggested business stage.`
    );
  }

  if (A4 || A5) {
    ret.push(
      `Thank you for your answer. However, we ask you to go over your answer once more to look for ways to make it more clear why your business is in your suggested business stage.
This was not clear enough in your current answer.
Being able to explain this more clearly will help you identify whether or not this is the right business stage.`
    );
  }

  if (A10) {
    ret.push(
      `Based on your argumentation, your business stage seems accurate. Good job.`
    );
  }

  if (A11) {
    ret.push(
      `Based on your argumentation, your business stage seems somewhat accurate.
You can leave this submission as is, but we advise you to explain your decision better in your next assignments. We feel like your argumentation here could have been better.`
    );
  }

  if (A12) {
    ret.push(
      `Based on your argumentation, it is hard to judge if you have identified the right business stage.
We advise you to explain your decision better in your next assignments. We feel like your argumentation here could have been significantly better.`
    );
  }

  return ret;
};
