import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const {
    Z0,
    Z1,
    Z2,
    Z3,
    A1,
    A2,
    A3,
    A4,
    A5,
    A6,
    A7,
    A8,
    A9,
    A10,
    A11,
    A12,
    A13,
    A14,
    A15,
    A16,
    A21,
    A22,
  } = getSelectedOptionsFlags(selectedOpts);

  if ((A5 || A6 || A22) && (A1 || A2) && !A12 && A14) {
    ret.push(
      `Very well done!
Your protocols are all well structured, clear and include several useful findings. You have recorded your learnings exceptionally well!
Recording your findings like this will help you access this data in the future with ease and save you and your team time, as well as ensuring that no relevant information is lost.`
    );
  }

  if ((A5 || A6 || A22) && (A1 || A2) && !A12 && !A14) {
    ret.push(
      `Well done!
Your protocols are all well structured, clear and include several useful findings! Recording your findings like this will help you access this data in the future with ease and save you and your team time, as well as ensuring that no relevant information is lost.`
    );
  }
  /*
if (A6 && (A1 || A2) && !A12) {
ret.push(`You have submitted a good number of protocols and, overall, they are quite detailed and clear.
Having very clear and detailed protocols or reports will help you access this data with ease in the future and save you and your team time, as well as ensuring that no relevant information is lost.
This is why, we advise you to put a little more time into reporting your interviews in the future. It can help a lot in case you decide later on to change the direction of your idea entirely.`);
}
*/
  if (A12) {
    ret.push(
      `Thank you for submitting your interview transcripts.
It seems like your interviews were interesting and fruitful!

However, please keep in mind that a transcript by itself is not a full interview protocol.
A transcript is great because it keeps a clear and bias-free record of everything that was said during your interviews. But in order to extract all the learnings, you should also also (right after the interview or during it) write down some observations as well (e.g., the person took 5s to answer question XYZ; The interviewee became defensive when talking about ABC...). This information can be relevant later and may not be fully captured in your transcript.

Furthermore, what about your personal impressions on the interview and the interviewee? It is a good idea to always add a section to your protocol where you add your thoughts and impressions (just make sure to not mix that with your objective observations).

Lastly, we also recommend you to summarize your transcript before continuing. This must be done anyways before you meet up with your team to share your learnings.
Preparing these short bullet-points beforehand will ensure that your meeting goes by faster and more efficiently.

Take a look at the protocol template we shared on our method kit. It contains a good structure that you could use in your future interviews.`
    );
  }

  if ((A7||A21) && A8 && A9 && A10) {
    ret.push(
      `What you did especially well was to distinguish your personal comments and thoughts (the subjective bits) from your observations and quotes (the objective bits).
This is crucial as it allows you to store the "raw data" that can be re-evaluated in the future in case you decide to pivot or completely change your idea.
Another thing you did well is to give your interviewee a clear description. This will not only help you look for patterns between different types of users, but it will also allow you to create better personas later.
Lastly, we were also happy to see how you kept a clear section for a follow-up.`
    );
  }

  if (A7 && !A12 && !A21 && !(A7 && A8 && A9 && A10)) {
    ret.push(`We liked how you added a good description to your interviewees.
For large datasets this will allow you look for patterns and will later help you create better personas.
Furthermore, it will also help you remember the interview better.`);
  }
/*

  All of the three conditions below work well. However, they would generate too much text so I removed then.

  if (A8 && A11 === false && !A12 && !(A7 && A21 && A8 && A9 && A10)) {
    ret.push(`Good job including so many observations and quotes!
It is very important to record all these learnings without any bias.
This will help you ensure that the data gathered can be used in the future without compromising the validity of your research.`);
  }

  if (A9 && !A12 && !(A7 && A21 && A8 && A9 && A10)) {
    ret.push(`You did well to include a space where you can write your personal comments and impression.
While this information is more subjective, it is certainly valuable as well and should be recorded for all your interviews.`);
  }

  if (A10 && !A12 && !(A7 && A21 && A8 && A9 && A10)) {
    ret.push(`We are happy to see that you included a follow-up section!
This will not only make your learnings more actionable, but will also remind you later to follow through with any promises made or investigate any open questions.
Well done!`);
  }
*/
  if (A11 && !A12) {
    ret.push(`We noticed that your interview reports are mostly just all of your questions and how each interviewee answered them.
This is certainly the most important part of such a report, but in order to make the most of each interview it is important to write down some additional information as well.
You will find these below:`);
  }

  if (A8 === false && !Z0 && !Z1 && !Z2 && !A12) {
    ret.push(`Your protocol should include a space where you can write down your bias-free observations and learnings.
It is very important to record all these learnings without any bias during your interview.
This will help you ensure that the data gathered can be used in the future without compromising the validity of your research.`);
  }

  if (A9 === false && !Z0 && !Z1 && !Z2 && !A12) {
    ret.push(`It would have been best if you had included a section where you can write down your personal comments and feelings.
This will help you separate your objective learnings from your personal opinion.
Remember, both are valuable and should be included in your protocol, but they should not be mixed as this can  bias your results.
This is why it is very important to separate both.`);
  }

  if ((A15 || A16) && (A1 || A2) && !A12) {
    ret.push(`While you have submitted a good number of protocols, they are not as detailed as they should be.
Having very clear and detailed protocols or reports will help you access this data in the future with ease and save you and your team time, as well as ensuring that no relevant information is lost.
This is why, we advise you to put a little more time into reporting your interviews in the future. It can help a lot in case you decide later on to change the direction of your idea entirely.`);
  }

  if ((A5 || A6 || A22) && A3 && !A12) {
    ret.push(`While your report is detailed and clear, you did not submit enough of them for us to give you effective feedback.
In general, having very clear and detailed protocols or reports will help you access this data in the future with ease and save you and your team time, as well as ensuring that no relevant information is lost.
Having all of your interviews stored like this can help a lot in case you decide later on to change the direction of your idea entirely.`);
  }

  if (A6 && A3 && !A12) {
    ret.push(`You did not submit several reports as we would have liked, and it seems like your reports are not detailed enough.
Having very clear and detailed protocols or reports will help you access this data in the future with ease and save you and your team time, as well as ensuring that no relevant information is lost.
This is why, we advise you to put a little more time into reporting your interviews in the future. It can help a lot in case you decide later on to change the direction of your idea entirely.`);
  }

  if ((A15 || A16) && A3 && !A12) {
    ret.push(`You did not submit enough reports and it seems like your reports are not detailed enough.
Having very clear and detailed protocols or reports will help you access this data in the future with ease and save you and your team time, as well as ensuring that no relevant information is lost.
This is why, we advise you to put a little more time into reporting your interviews in the future. It can help a lot in case you decide later on to change the direction of your idea entirely.`);
  }
  /*
{ id= "A7", text= "Protocols includes user description", points= 1 },
{ id= "A8", text= "Protocols includes several Observations/learnings/quotes", points= 1 },
{ id= "A9", text= "Protocols includes personal comments from the participants", points= 1 },
{ id= "A10", text= "Protocols includes next steps/follow up", points= 1 },
*/

  if (A7 === false && A21 === false&& !Z0 && !Z1 && !Z2 && !A12) {
    ret.push(`We could not find a clear section with the description of your interviewees.
For large datasets this will allow you look for patterns and will later help you create better personas as well as help you remember the interview better.
This is why we advise you to always include such a section in the future.`);
  }

  if (A21 && !Z0 && !Z1 && !Z2 && !A12) {
    ret.push(`The description of your interviewees is not detailed enough.
When working in projects that require several interviews, having a detailed interviewee description will make it easier (maybe even possible) for you to identify patterns in your interviewees and will later help you create better personas.
This is why we advise you to always include a detailed interviewee description in the future.`);
  }

  if (A10 === false && !Z0 && !Z1 && !Z2 && !A12) {
    ret.push(`We advise you to add a "follow-up" section, where you can write down any open question or tasks that must be performed after the interview.
This will not only make your learnings more actionable, but will also remind you later to follow through with any promises made or investigate any open questions (e.g., send them a follow-up email with a certain clarification).`);
  }

  if (A7 && A8 && A9 && A10) {
    ret.push(
      `Another tip we would like to give you before proceeding is to remind you to always instigate story telling during your interviewees. If you ask something and then notice how suddenly your participant becomes very involved or emotional, it might be a good idea to break out from your prepared questions and follow this topic (always respecting your interviewee of course).
Make sure to ask why and to understand the underlying reasons why this topic is so relevant for them. This is how you uncover true needs and issues!`
    );
  }

  if (A13) {
    ret.push(`To finish up, we would like to point out that your protocol should have more learnings for the length of this interview.
There is no rule on the number of learning you should try to extract, but a good goal to aim for is 1 learning (i.e., observation, quote, comment) per minute.`);
  }

  return ret;
};
