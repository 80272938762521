import * as React from "react";
import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@sparkademy/app-common/elements/Alert";
import { Box, Text } from "theme-ui";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as IllustrationQuestion } from "@sparkademy/app-common/materials/illustrations/question.svg";
import { ButtonOutline } from "@sparkademy/app-common/elements/ButtonOutline";

export const SubmitConfirmationDialog: React.FC<{
  onClose: () => void;
  onSubmit: () => void;
}> = ({ onClose, onSubmit }) => {
  const cancelRef = React.useRef<HTMLButtonElement>();

  return (
    <AlertDialogOverlay
      // @ts-ignore
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogContent>
        <Box sx={{ maxWidth: "600px" }}>
          <AlertDialogLabel></AlertDialogLabel>
          <AlertDialogDescription>
            <Box
              sx={{
                margin: "auto",
                width: 170,
                height: 170,
                svg: {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <IllustrationQuestion />
            </Box>
            <Text as="p" sx={{ textAlign: "center", fontWeight: 700, fontSize: "25px" }}>
              Are you sure you want to send the feedback?
            </Text>
            <Text as="p" sx={{ textAlign: "center", fontWeight: 700, fontSize: "25px" }}>
              You won’t be able to undo this action.
            </Text>
          </AlertDialogDescription>
        </Box>
        <Box sx={{ mt: 11 }} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "350px",
          }}
        >
          <ButtonOutline onClick={() => onClose()} sx={{ fontSize: "20px" }}>
            Back
          </ButtonOutline>
          <Button type="submit" onClick={() => onSubmit()} sx={{ fontSize: "20px" }}>
            Submit
          </Button>
        </Box>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
};
