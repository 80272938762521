import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(`Well done! You have submitted several unique ideas that are very clear and well described.
Now it is important to select the ones you would like to test and get some user feedback before deciding how to proceed.
It is possible, if not likely, that some of the ideas you select will be discarded later. In that case you may want to go back to this list and test another.
This is why it is important to keep this list stored somewhere safe.`);
  }

  if (Z2) {
    ret.push(`Exceptional submission! All of your ideas are very well described and well thought. We really appreciate you taking the time to submit your findings in this much detail!
Now it is important to select the ones you would like to test and get some user feedback before deciding how to proceed.
It is possible, if not likely, that some of the ideas you select will be discarded later. In that case you may want to go back to this list and test another.
This is why it is important to keep this list stored somewhere safe.`);
  }

  return ret;
};
