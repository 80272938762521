import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A3 === false && A4 === false) {
    ret.push(
      `Good job! You have considered several people required in the journey of this project to take it forward and make it successful. But it is also essential to reflect on their cost and time involvement, so that you can come up with an estimate of the direct costs for budgetary purposes.
Without these two elements, this exercise is not very useful to budget your project.`
    );
  }

  if (A1 && A3 === false && A4) {
    ret.push(
      `Good job! You have considered several people required for the completion of this project as well as considered how much time each of them will spend on it.
However, it is also essential to reflect on their cost per unit of time (day, hour, etc) so that you can come up with an estimate of the direct costs (that you will need to budget your project).
Without this final element, this exercise is not very useful to budget your project.`
    );
  }

  if (A1 && A3 && A4 === false) {
    ret.push(
      `Good job! You have considered several people required for the completion of this project as well as considered how much they will cost you per unit of time.
However, it is also essential to come up with an estimate for the amount of time each stakeholder will need until the completion of the project.
Without this final element, this exercise is not very useful to budget your project.`
    );
  }

  if (A1 && A3 && A4 && A7 === false) {
    ret.push(
      `Perfect! You have listed all the people required to run and grow the project. You have also mentioned their value in terms of cost and the time commitment required by them. This is a great place to start and provides a good estimate for the direct costs involved in the project.`
    );
  }

  if (A2 && A3 === false && A4 === false) {
    ret.push(
      `Try to think of more people required for the completion of your project. It is essential to have a list with all the relevant stakeholders at this stage.
Furthermore, it is also important to reflect on how much they will cost you per day or hour, and how long each one of them will need to work for the completion of your project. This will help you come up with an estimate of the direct costs that you will need to budget your project.
Without these two elements, this exercise is not very useful to budget your project. `
    );
  }

  if (A2 && A3 === false && A4) {
    ret.push(
      `Try to think of more people required for the completion of your project. It is essential to have a list with all the relevant stakeholders at this stage.
Furthermore, it is also important to reflect on their cost per unit of time, so that you can come up with an estimate of the direct costs for budgetary purposes. Without these two elements, this exercise is not very useful to budget your project.
However, you did include the expected time involvement for all of your stakeholders. Good job!`
    );
  }

  if (A2 && A3 && A4 === false) {
    ret.push(
      `Try to think of more people required for the completion of your project. It is essential to have a list with all the relevant stakeholders at this stage.
Furthermore, it is also important to come up with an expected time involvement for all of your stakeholders. This will help you estimate the direct costs for budgetary purposes.
However, you did include the cost per unit of time for all of your stakeholders. Good job!`
    );
  }

  if (A2 && A3 && A4) {
    ret.push(
      `Thank you for stating both the time involvement and the cost per unit of time for the personel stated. These informations, even if just estimates, are key to generate a first budget and will help you assess the viability of your project.
As you develop you your idea further, you will be able to refine your estimates and come up with more precise budgets.
However, we would advise you to think of more people required for the completion of your project. It is essential to have a list with all the relevant stakeholders at this stage.
This will not only help you budget your project better, but it will also help you ensure a smooth project development. Otherwise, you may only realise that you forgot to include someone relevant early on when it is too late and costly to bring them in.`
    );
  }

  if (A6) {
    ret.push(
      `However, even if these internal employees are already being paid and won't cost you any additional amount directly, if they start to work on your project, it will be at the expense of work they used to do in another area. In order to keep that old work going, other people will have to be hired to fill in those gaps. This, in turn, would generate new costs to your company.
Therefore, be careful when employing internal workers. Just because you won't have to hire external services, it does not mean that there are no costs associated with it.`
    );
  }

  if (A7) {
    ret.push(
      `While you have considered several people required for the completion of this project as well as considered how much time each of them will spend on it, in order to come up with good cost estimates it is a good idea to not only define the total number of individuals needed, but also their specific roles and jobs. E.g. One full stack developer, full time, 95k a year; One person for marketing for... and so on.`
    );
  }

  return ret;
};
