import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A4, A5, A6, A7, A8, A9, A10 } =
    getSelectedOptionsFlags(selectedOpts);

  /* User */
  if (A1 && A2 === false) {
    ret.push(`Well done, you have made it very clear who your target user is.
As we are attempting to design human-centric products and services, it is very important to always make it clear who your user is so that you can focus on their needs and frustrations. Good job doing that!`);
  }

  /* Objective */
  if (A2 && A1 === false) {
    ret.push(`Well done, you have made it very clear what your objective is.
However, You did not state very clearly who your target user is.
As we are attempting to design a human-centric product or service, it is very important to always make it clear who your user is so that you can focus on their needs and frustrations.
At this stage of the process, we are trying to identify these user groups and their underlying needs. So we highly advise you to focus on your target user in your problem statement.`);
  }

  if (A2 && A1 && !A7) {
    ret.push(`Well done, you have made it very clear who your target user is, as well as stating very explicitly what your objective regarding them is.
As we are attempting to design human-centric products and services, it is very important to always make it clear who your user is so that you can focus on their needs and frustrations. Good job doing that!`);
  }

  if (A4 && A1 && !A2 && !A7) {
    ret.push(`You have also given your problem statement a good scope.
It is neither too broad (this makes it hard to focus on a specific issue and can hurt the effectiveness of your user research), nor too narrow (This constrains your user research too much and can restrict the number of insights found).
Good job!`);
  }

  if (!Z0 && !Z1 && A2 === false && !A7) {
    ret.push(
      `On the other hand, while you have clearly stated your target users, you did not define your objective very clearly. It is important to be specific and clear about what it is that you want to achieve. Next time, you should try to state the goal of your overarching question as straightforward as possible.`
    );
  }

  if (A7 && (!A1 || !A2)) {
    ret.push(
      `It seems to us that your overarching question is something that you will ask one of your users directly.
This is not the idea behind an overarching question.

An overarching question should be used internally to give your team a certain direction (who is our user and what is our objective). It should not be a question that you ask your users directly but a 'high-level' question that guides your research.

If this concept is still not clear to you, then please take a second look at our course material.`
    );
  }

  if (A7 && A1 && A2) {
    ret.push(
      `Your overarching question covers all the necessary aspects of a good submission. Well done.
However, it seems to us that you have prepared a question that you would ask one of your users.
This is not the idea behind your overarching question.

This question should be used internally to give your team a certain direction (who is our user and what is our objective). It should not be a question that you ask your users directly but a 'high-level' question that guides your research.

If this concept is still not clear to you, then please take a second look at our course material.`
    );
  }

  /* scope appropriate(A3) / Good(A4) */

  if (A2 && A1 && A4 && !A7) {
    ret.push(`Furthermore, you have given your problem statement a good scope.
It is neither too broad (this makes it hard to focus on a specific issue and can hurt the effectiveness of your user research), nor too narrow (This constrains your user research too much and can restrict the number of insights found).
Good job!`);
  }

  /* specific feedback */
  if (A8 && !A7) {
    ret.push(
      `Your submission is not really an overarching question. An overarching question should be one question that sums up the goal of your research. It should include the actors or targets, their need or problem and ideally one of your assumptions or insights. Check the M2 Problem Statement method card for more details.`
    );
  }

  if (!A4 && A9 && !A7) {
    ret.push(
      `The scope of your overarching question is a bit too broad. This makes it hard to focus on a specific issue and can hurt the effectiveness of your user research. Try to reformulate your goal, but this time you should be a little more specific.`
    );
  }

  if (!A4 && A10 && !A7) {
    ret.push(
      `The scope of your overarching question is a bit too narrow. This constrains your user research too much and can restrict the number of insights found. Our suggestion is that you try to reformulate your overarching question into something a bit broader. This will give you more freedom to find the best solution instead of already signaling a solution.`
    );
  }

  if (Z0 && Z1 && A1 === false && !A7) {
    ret.push(`You did not state very clearly who your target user is.
As we are attempting to design a human-centric product or service, it is very important to always make it clear who your user is so that you can focus on their needs and frustrations.
At this stage of the process, we are trying to identify these user groups and their underlying needs. So we highly advise you to focus on your target user in your problem statement.`);
  }

  if (A6) {
    ret.push(`Lastly, thank you for stating more than one overarching question. We are happy to see how you did more than just what was asked in this exercise!
However, it is important that you pick only one of your overarching questions to guide your research.
Having two overarching question might make it more difficult to your team to focus on only one user and one need.`);
  }

  return ret;
};
