import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Fantastic work! You came up with several thoughtful and creative ideas to address the inconsistencies you identified in the previous question. By suggesting actions you can take to address the weak points in your business model, you can launch into prototyping and testing changes to your business model.`
    );
  }

  return ret;
};
