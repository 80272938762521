import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Very well done!
You have done a good job organizing your questions by sections and preparing several follow-up questions to each of your topics.
More importantly, all of your questions are well stated and should not bias your interview.
You did a good job avoiding asking your participants to imagine a certain situation and sharing what they would do.

One thing we would like to point out again is to focus on stories and experiences as well as asking why whenever you feel like there is more to your interviewees answers.
It is always a good idea to ask your participant to share something that happened to them that might be relevant. Once people start to tell you story, it becomes much easier to identify their emotions and extract valuable insights.`
    );
  }

  return ret;
};
