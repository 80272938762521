import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Great job! You explained clearly why the core element you chose is relevant and important to your business model. In both your choice and explanation, you demonstrated an understanding of how the elements work together to create, deliver, and capture value, which is essential to evaluating and reconfiguring your business model.`
    );
  }

  return ret;
};
