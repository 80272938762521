import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`);
  }

  if (Z1) {
    ret.push(`Perfect submission! You've managed to give excellent reasons as to how your qualitative research supports your insight. This is important because it gives credibility to your findings. This insight is not just your opinion or gut feeling. It is now based on results from your research.
Moreover, you also answered very well why this insight is relevant for your project. It is essential to understand this relevance very well, as this will help you stay on track and convince other people to accept your project.
Finally, you also made it very clear how this insight will affect your next steps. This is useful because it will help you plan and execute your following challenges.
Very well done!`);
  }

  return ret;
};
