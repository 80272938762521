import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`);
  }

  if (Z1) {
    ret.push(
      `Good job! It was very interesting to read your reflection. Thank you for sharing! Just keep in mind that, while there is no right or wrong, reflecting on your interviews' outcome can be extremely useful as it helps you identify things that should be maintained or changed for the next time you conduct an interview.`
    );
  }

  if (Z2) {
    ret.push(
      `Thank you for this exceptional submission! We enjoyed reading about your ups and downs when conducting your interview. Reflecting on your interviews' outcome can be extremely useful as it helps you identify things that should be adapted or kept for the next time you conduct an interview.`
    );
  }

  return ret;
};
