import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, B0, B1, B2, B3, B4, B5 } = getSelectedOptionsFlags(selectedOpts);

  if (B0) {
    ret.push(`No second improvement stated.`);
  }

  if (B1 && B3 && B4) {
    ret.push(`Very well done!
Your second suggested improvement was not only very clear and easy to understand, but it is also very detailed and actionable. This is very important as it makes it clear for you, your team and anyone involved what exactly has to be improved and how you can do that.`);
  }

  if (B1 && !B3 && B4) {
    ret.push(`Good job! Overall your second improvement is well stated.
It is clear and easy to understand and actionable, which means that it lays out a clear plan that can be followed to be implemented.
However, we feel like you could have been a little more specific when pinpointing the exact issue you want to tackle.
Doing so will make it more likely that your improvement is implemented correctly and effectively.`);
  }

  if (B1 && B3 && !B4) {
    ret.push(`Good job! Overall your second improvement is very well stated.
It is clear and easy to understand and specific, which means that it pinpoints the exact issue you want to tackle.
However, we feel like you could have made your suggestion a bit more actionable. Ideally, you should have laid out an action plan that can be followed to esure that this improvement is implemented efficiently.
Doing so will make it more likely that your improvement will be well implemented.`);
  }

  return ret;
};
