import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `No submission.
Even if you did not have time to submit your answer, we can only recommend that you study this topic. Understanding what SMART goals are and knowing how to set them will be very useful in your career. Don't forget that you have a clear and concise summary of SMART goals in our method cards.`
    );
  }

  if (Z1) {
    ret.push(
      `Well done! You have stated 3 goals as asked and identified correctly whether or not each goal is SMART and why it is (or isn't) SMART. Being able to set SMART goals will be a valuable skill in your career as it will motivate your colleagues, make it easier to achieve the goal and possibly improve the results.`
    );
  }

  return ret;
};
