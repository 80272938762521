import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Very well done! You identified various potential improvements to your facilitation tactics. Keep them in mind for your next workshop, and you will facilitate a great session!`
    );
  }

  if (A2) {
    ret.push(
      `Well done! You identified several potential improvements. Are there any other aspects you could improve? Did you think of your opening, how you introduced the activity, how you used physical and technical props, how to react to challenging situations, how to conclude the results, etc? `
    );
  }

  if (A3) {
    ret.push(
      `Good start. You identified one way to facilitate a workshop better next time. Are there any other aspects you could improve? Did you think of your opening, how you introduced the activity, how you used physical and technical props, how to react to challenging situations, how to conclude the results, etc? `
    );
  }

  if (A4) {
    ret.push(
      `Great work!  Your reflection proves that you truly understood what it takes to be a facilitator. And don't worry if you are not perfect yet - even the most experienced facilitators get into new situations and learn more after years of practice. `
    );
  }

  if (A5) {
    ret.push(
      `Keep going! Becoming aware of your lessons learned is the first step to implement them next time. And don't worry if you are not perfect yet - even the most experienced facilitators get into new situations and learn more after years of practice. `
    );
  }

  if (A6) {
    ret.push(
      `Try to make your plans for improvement even more actionable and detailed. Otherwise, you might not remember what you meant and how to change if you facilitate your next workshop.`
    );
  }

  return ret;
};
