import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B1, B2, B3, B4, B5, B6, B7, B8 } = getSelectedOptionsFlags(selectedOpts);

  /*
    { id= "B1", text= "Idea named", points= 0.5 },
    {id = "#4", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "B2", text= "No argumentation", points= 0 },
    { id= "B3", text= "Pooor argumentation", points= 0.5 },
    { id= "B4", text= "Acceptable argumentation", points= 1 },
    { id= "B5", text= "Good argumentation", points= 1.5 },
    { id= "B6", text= "Very good argumentation", points= 2 },
    {id = "#5", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "B7", text= "Arguments not supported", points= 0 },
    { id= "B8", text= "Arguments supported by findings/quotes/observations", points= 2 },
  */
  if (B1) {
    ret.push(`Thank you for stating which idea you would like to explain in more detail.`);
  }

  if (B2) {
    ret.push(`However, you did not give any arguments as to why your team decided to try out this idea.
Ideally, you should not only state why this idea is relevant for your business, but also make use findings to back up your arguments.`);
  }

  if (B3) {
    ret.push(`However, you did not give convincing arguments as to why your team decided to try out this idea.
Ideally, you should not only state why this idea is relevant for your business, but also make use findings to back up your arguments.`);
  }

  if (B4) {
    ret.push(`You gave acceptable arguments as to why your team decided to continue with this idea.
However, ideally, you should not only state why this idea is relevant for your business, but also make use findings to back up your arguments.`);
  }

  if (B5 && B8) {
    ret.push(`You have given good arguments to explain why you and your team decided to continue with this idea.
More importantly you have also backed your arguments with findings from your user research.
This will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.
Good job!`);
  }

  if (B5 && B7) {
    ret.push(`You have given good arguments to explain why you and your team decided to continue with this idea.
However, you did not support your arguments with findings from your user research.
Doing this will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.`);
  }

  if (B6 && B8) {
    ret.push(`You have given very good arguments to explain why you and your team decided to continue with this idea.
More importantly you have also backed your arguments with findings from your user research.
This will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.
Good job!`);
  }

  if (B6 && B7) {
    ret.push(`You have given very good arguments to explain why you and your team decided to continue with this idea.
However, you did not support your arguments with findings from your user research.
Doing this will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.`);
  }

  if (B7 && !B5 && !B6) {
    ret.push(`Lastly, you did not back up your arguments with findings from your user research.
Doing this will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.`);
  }

  if (B8) {
    ret.push(`Well done! You have backed your arguments with findings from your user research.
This will not only make your arguments more convincing, but it will also ensure that these idea is really user-centric.`);
  }

  return ret;
};
