import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2} = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `You did not state the industry lifecycle your business is in. This is a very important information that will help you set priorities and develop best strategies. Please take some time to reflect about your business and what lifecycle it is in.`
    );
  }

  if (Z1) {
    ret.push(
      `Thank you for stating what industry lifecycle your business is in! Understanding the business phase you are in is a very important information that will help you set priorities and develop best strategies.`      );
  }

  if (Z2) {
    ret.push(
      `Thank you for stating what industry lifecycle your business is in and for differentiating between different markets! 
Understanding the business phase you are in is a very important information that will help you set priorities and develop best strategies.`
    );
  }

  return ret;
};
