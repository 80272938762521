import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Looking at the experiment plan you submitted in 4.3, you conducted about 10 different experiments, mostly regarding the desirability of your ideas.
Unfortunately, the success criteria in your experiment plan is not readable, but I hope you did set clear expectations that were actually met. It's a common trap to set a criteria and later consider the assumption as true, because the criteria was "almost met" or "it's still a good result". So that's something to look out for and avoid!

In the mid-stage presentation, you shared clear metrics of your experimentation and used a lot of qualitative and quantitative data to support your argument. Good job!`);
  }

  return ret;
};
