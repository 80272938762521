import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Wow, that’s a great response rate! It seems like you did a good job of reaching out to people and making it appealing for them to finish your survey. Keep in mind that for most user experience projects, response rates are much lower, so you often have to cast a wider net.`);
  }

  if (A2) {
    ret.push(
      `That seems like a good response rate. Remember, in large scale user experience projects, the response rate can be even lower, so it’s important to send the survey to many people to get a suitable number of responses for quantitative analysis.`
    );
  }

  if (A3) {
    ret.push(
      `Your response rate seems typical for user experience research projects. If you did not get as many responses as you had hoped, you can continue sending the survey to more people.`
    );
  }

  if (A4) {
    ret.push(
      `You should have sent the survey out to many more people than you did. In order to extract meaningful insights from you data, you need a larger sample size.`
    );
  }

  return ret;
};
