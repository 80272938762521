import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A4, A5, A6, A7, A8, A9, A10 } =
    getSelectedOptionsFlags(selectedOpts);

  /* User */
  if (A1 && A2 === false) {
    ret.push(`Well done, you have made it very clear who your target user is.
As we are attempting to design human-centric products and services, it is very important to always make it clear who your user is so that you can focus on their needs and frustrations. Good job doing that!`);
  }

  if (Z0 && Z1 && A1 === false) {
    ret.push(`You did not state very clearly who your target user is.
As we are attempting to design a human-centric product or service, it is very important to always make it clear who your user is so that you can focus on their needs and frustrations.
At this stage of the process, we are trying to identify these user groups and their underlying needs. So we highly advise you to focus on your target user in your problem statement.`);
  }

  /* Objective */
  if (A2 && A1 === false) {
    ret.push(`Well done, you have made it very clear what your objective is.
However, You did not state very clearly who your target user is.
As we are attempting to design a human-centric product or service, it is very important to always make it clear who your user is so that you can focus on their needs and frustrations.
At this stage of the process, we are trying to identify these user groups and their underlying needs. So we highly advise you to focus on your target user in your problem statement.`);
  }

  if (A2 && A1) {
    ret.push(`Well done, you have made it very clear who your target user is, as well as stating very explicitly what your objective regarding them is.
As we are attempting to design human-centric products and services, it is very important to always make it clear who your user is so that you can focus on their needs and frustrations. Good job doing that!
    `);
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `On the other hand, while you have clearly stated your target users, you did not define your objective very clearly. It is important to be specific and clear about what it is that you want to achieve. Next time, you should try to state the goal of your overarching question as straightforward as possible.`
    );
  }

  /* scope appropriate(A3) / Good(A4) */

  if (A4) {
    ret.push(`You have given your problem statement a good scope.
It is neither too broad (this makes it hard to focus on a specific issue and can hurt the effectiveness of your user research. Try to reformulate your goal, but this time you should be a little more specific) , nor too narrow (This constrains your user research too much and can restrict the number of insights found. Our suggestion is that you try to reformulate your overarching question into something a bit broader. This will give you more freedom to find the best solution instead of already signalling a solution).
Good job!`);
  }

  /* Needs */
  if (A6) {
    ret.push(`Very well done! Your problem statement focuses on a deep underlying need from your user. Doing so will help you address relevant issues and, possibly, create a lot of value for your users.
This is certainly the most important part of your problem statement and we are glad to see that you have done it well!`);
  }

  if (A7) {
    ret.push(`Good job! Your problem statement focuses somewhat on an underlying need from your user. Ideally, you should have focuses 100% on something that effectively affects the life of your user.
For this, it is important to ask yourself if, whatever it is you want to deliver, is a final goal or just an enabler for something else.
For example: focusing on something like "How might we help reduce the time our persona takes to get to work" is not really focusing on a underlying need. Your persona does not want to minimize the time to work. Instead, she or he wants to have more free time to spend with their families or doing something they like for example. Reducing their commuting time might help that, but there may be other means to do so.
This is why you should really try to focus on the deep underlying needs. Asking yourself if this is the final goal of your persona is a good way to identify if you are on the right track.`);
  }

  if (Z0 && Z1 && !A6 && !A7) {
    ret.push(`Your problem statement does not really focus on an underlying need from your user. Ideally, you should have focuses 100% on something that effectively affects the life of your user.
For this, it is important to ask yourself if, whatever it is you want to deliver, is a final goal or just an enabler for something else.
For example: focusing on somthing like "How might we help reduce the time our persona takes to get to work" is not really focusing on a undrlying need. Your persona does not want to minimize the time to work. Instead, she or he wants to have more free time to spend with their families or doing something they like for example. Reducing their commuting time might help that, but there may be other means to do so.
This is why you should really try to focus on the deep underlying needs. Asking yourself if this is the final goal of your persona is a good way to identify if you are on the right track.`);
  }
  /* specific feedback */
  if (A8) {
    ret.push(
      `Your submission is not really an overarching question. An overarching question should be one question that sums up the goal of your research. It should include the actors or targets, their need or problem and ideally one of your assumptions or insights. Check the M2 Problem Statement method card for more details.`
    );
  }

  if (!A4 && A9) {
    ret.push(
      `The scope of your overarching question is a bit too broad. This makes it hard to focus on a specific issue and can hurt the effectiveness of your user research. Try to reformulate your goal, but this time you should be a little more specific.`
    );
  }

  if (!A4 && A10) {
    ret.push(
      `The scope of your overarching question is a bit too narrow. This constrains your user research too much and can restrict the number of insights found. Our suggestion is that you try to reformulate your overarching question into something a bit broader. This will give you more freedom to find the best solution instead of already signaling a solution.`
    );
  }

  return ret;
};
