import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A1 && A7 && A0) {
    ret.push(
      `Good job stating a material that is required for the completion of your project as well as an estimate of how much you will spend on that.`
    );
  }

  if (A1 && A7 && A10) {
    ret.push(
      `Good job stating several materials that are required for the completion of your project as well as an estimate of how much you will spend on these materials. `
    );
  }

  if (A3 && A1 === false && A2 === false) {
    ret.push(
      `Great job identifying additional costs that are relevant and are required for your innovation project to run. `
    );
  }

  if (A1 && A2 && A8 === false) {
    ret.push(
      `However, while you have identified several relevant services that you will need to complete your project, you did not state their expected costs for the first twelve months of your business.`
    );
  }

  if (A1 === false && A2) {
    ret.push(
      `Good job! You have identified several relevant services that you will need to use (and pay for), as well as stated their expected costs for the first twelve months of your business. `
    );
  }

  if (A4) {
    ret.push(
      `You have identified a good number of items that add direct costs to your project. This will give you a good idea on what costs you can expect, which will help you estimate the necessary budget for your project and consequently help you assess the feasibility of your project. `
    );
  }

  if (A5) {
    ret.push(
      `Additionally, you have identified a good number of items that will increase your expenses. However, you should try to do an even more extensive and detailed research on possible operational expenses that might be required for the duration of your project. This will give you a good idea on the necessary budget to develop your project and help you get a sense of how feasible your project currently is.`
    );
  }

  if (A1 && A2 && A8 && A11) {
    ret.push(
      `Furthermore, you have identified only one relevant service that you will need to use (and pay for). Again, we strongly advise you to think of more services that you will need. Identifying these costs now will facilitate the development of your idea down the line as it might reduce the number of unexpected costs.
  However, you did a good job stating it's expected cost for the first twelve months of your business. `
    );
  }

  if (A1 && A2 && A8 && A12) {
    ret.push(
      `Furthermore, you have identified several relevant services that you will need to use (and pay for), as well as stated their expected costs for the first twelve months of your business.`
    );
  }

  if (A1 && A2 && A8 === false && A11) {
    ret.push(
      `However, while you have identified one relevant service that you will need to complete your project, you did not state it's expected cost for the first twelve months of your business.
  We strongly advise you to think of more services that you will need to develop your idea. Identifying these costs now will facilitate the development of your idea down the line as it might reduce the number of unexpected costs.`
    );
  }

  if (A1 && A2 && A8 === false && A12) {
    ret.push(
      `However, while you have identified several relevant services that you will need to complete your project, you did not state their expected costs for the first twelve months of your business.`
    );
  }

  if (A3 && (A1 || A2)) {
    ret.push(
      `Moreover, great job in identifying additional costs that are relevant and are required for your innovation project to run. `
    );
  }

  if (A6) {
    ret.push(
      `You listed a very low number of items specified are not enough. Try to do an extensive and more detailed research on the items that will be required. These costs add to the direct cost of your project. This will give you a good idea on the budgetary estimate and a sense of feasibility of your project. `
    );
  }

  if (!Z0 && !Z1 && !Z2 && A1 === false) {
    ret.push(
      `You have not listed the specific materials you believe are needed for the completion of this project. Materials can range all the way from prototyping materials, your first MVP, an order you take with a specialized supplier, up to simple everyday things like whiteboards or post-its to hold your workshop.
It is very important to think about this now, as this will help you budget your expenses more effectively.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A1 && A7 === false) {
    ret.push(
      `While you have listed the specific materials you believe are needed for the completion of this project you did not give an estimate for their costs. Materials can range all the way from prototyping materials, your first MVP, an order you take with a specialized supplier, up to simple everyday things like whiteboards or post-its to hold your workshop.
  It is very important to think about this now, as this will help you budget your expenses more effectively.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A2 === false) {
    ret.push(
      `You have not listed any additional services you believe are necessary for the comnpletion of your project. Aquired services can range from consultant services, programs you need, costs for running your website or hosting an app?
Since it will be necessary to budget your project before you can proceed, it is very important that you try to estimate these costs now. These estimates will then be improved and validated throughout the execution of your project.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A3 === false) {
    ret.push(
      `Lastly, you should try to think about even more types of costs that may occur in further! Is there additional knowledge you need e.g. going to a conference or paying for research? Do you have a running subscriptions? Do you need to travel?
Again, identifying these additional costs early on will make your budget much more reliable and reduce any possible cost barriers in the future.`
    );
  }

  return ret;
};
