import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, B1, B2, B3, B4 } = getSelectedOptionsFlags(selectedOpts);

  if (B2) {
    ret.push(
      `There is a clear connection between your assumptions and the core element of your idea stated earlier. Good job!`
    );
  }

  if (!Z0 && !Z1 && B2 === false) {
    ret.push(
      `What is the connection between these assumptions and your idea? Ideally, you would want your idea to internalize these assumption. For example, let's pretend that one of your assumption is: "People would use their bicycle more often if they were not afraid of getting it stolen." and your idea is to tackle this issue by developing a tracker that is integrated into their bicycles and automatically calls the police if a bike is moved without your phone in the proximity. In this case, the core element of your idea is the automatic call to the police in case of theft. The connection between the assumption and your core element is very obvious.`
    );
  }

  if (!Z0 && !Z1 && B3 === false) {
    ret.push(
      `Some of your assumptions are not formulated correctly. An assumption should always infer a relationship between two objects. Additionally, this relationship should not be a certainty but only probable. For example, "I like big houses" does connect two objects (Me and big houses), but it is not an assumption as this is my opinion, and at least for me, my opinion is correct. On the other hand, the statement "People like big houses" is a valid assumption since it connects two objects and is not a certainty.
Hence, please go over your submission and change it into a valid assumption.`
    );
  }

  if (!Z0 && !Z1 && B4 === false) {
    ret.push(
      `Are these really the main assumptions behind your ideas?
Think about the desirability (most important), feasibility and viability of your idea.
Use these topics to come up with more relevant assumptions. These are the assumptions you must test to validate your idea later.`
    );
  }

  return ret;
};
