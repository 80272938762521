import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.
Even though you did not share your experiment, you should that one short experiment will not be enough to validate your entire solution. And this is okay. 
The idea behind these experiments is that they are a really fast and efficient way to learn a lot about your users. We start with our key features because, if, after a few simple experiments you still can not identify any user-niche that is interested in your solution, then you may need to think of an alternative solution.
However, should there be (at least some) interest for your solution, then you can run a second version of your experiment, this time focusing on something else (maybe another key feature you are uncertain about). The beauty here is that you only invest more time and energy if your solution is worth it.
At the same time, because you have not invested much time into your first experiments in the first place, even if you discover that there is no real need for it, you have likely learned a lot more about your users and, if you asked why enough, you may have new insights to design a better solution.
This iterative approach will eventually lead you the right solution and will allow you to validate its elements one a time.`);
  }

  return ret;
};
