import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`);
  }

  if (Z1) {
    ret.push(
      `Thank you for sharing your top three insights.
All of them are well framed and should help you and your organisation understand your users and their needs better.
In other words, they are all informative, motivating,  memorable, and are a combination of some of your findings and your own interpretation. Great job!.
Because of this, they do not only summarise some of your key learnings, but they will also allow you to share these learnings with your organisation in an easy and effective manner.

Framing insights that check all these boxes above is not easy.
Very well done!`
    );
  }

  return ret;
};
