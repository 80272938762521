import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, C0, C1, C2, C3, C4, C5, C7, C8, C9, C10, C11 } =
    getSelectedOptionsFlags(selectedOpts);

  if (C1 && !C0 && !C8 && !C9 && !C10 && !C11) {
    ret.push(
      `No external stakeholders submitted.
However, we hope that you at least took some time to think about all the relevant stakeholders and what interest and influence they have on your project.
Even though external stakeholders are often the least relevant stakeholders in your project, it is a good idea to identify most of them early on to see if they could become a barrier to your project or maybe even an aid it somehow.`
    );
  }

  if (C0) {
    ret.push(`You have identified only one external stakeholder.
We advise you to take even more time to think of more external stakeholders.
Even though external stakeholders are often the least relevant stakeholders in your project, it is a good idea to identify most of them early on to see if they could become a barrier to your project or maybe even an aid it somehow.`);
  }

  if (C8) {
    ret.push(
      `Well done once more!
Just like in part a), you have identified some relevant external stakeholders and gave them a good description.

Taking all these stakeholders into consideration at the beginning of your project should help you avoid roadblocks later on.
Therefore, it is important to keep the most relevant stakeholders in the loop as you proceed (either by including them when taking relevant decisions or at least by informing them periodically of your progress).`
    );
  }

  if (C9) {
    ret.push(
      `Just like in your answer for part a), you did not include the levels of power and interest for your stakeholders as asked.
In the future, please make sure to submit all the features asked in our questions.`
    );
  }

  if (C10) {
    ret.push(
      `Well done once more!

Just like in part b), you have identified some relevant external stakeholders and gave them a good description.

Taking all these stakeholders into consideration at the beginning of your project should help you avoid roadblocks later on.
Therefore, it is important to keep the most relevant stakeholders in the loop as you proceed (either by including them when taking relevant decisions or at least by informing them periodically of your progress).`
    );
  }

  if (C11) {
    ret.push(
      `Just like in your answer for part b), you did not include the levels of power and interest for your stakeholders as asked.
In the future, please make sure to submit all the features asked in our questions.`
    );
  }

  if (C2 && !C0 && !C8 && !C9 && !C10 && !C11) {
    ret.push(
      `You have included a decent number of external stakeholders.
However, we advise you to take more time to think of additional external stakeholders.
Even though external stakeholders are often the least relevant stakeholders in your project, it is a good idea to identify most of them early on to see if they could become a barrier to your project or maybe even an aid it somehow.`
    );
  }

  if (C3 && !C0 && !C8 && !C9 && !C10 && !C11) {
    ret.push(
      `Well done! You have included a good number of external stakeholders.
Even though external stakeholders are often the least relevant stakeholders in your project, it is a good idea to identify most of them early on to see if they could become a barrier to your project or maybe even an aid it somehow.`
    );
  }

  if (C4 && !C5 && !C0 && !C8 && !C9 && !C10 && !C11) {
    ret.push(
      `While you did include a level of power to your external stakeholders, you did not include a level of interest.
Even though external stakeholders are often the least relevant stakeholders in your project, it is a good idea to identify most of them early on to see if they could become a barrier to your project or maybe even an aid it somehow.`
    );
  }

  if (!C4 && C5 && !C0 && !C8 && !C9 && !C10 && !C11) {
    ret.push(
      `While you did include a level of interest to your external stakeholders, you did not include a level of power.
It is important to think about the level of power, since highly influential stakeholders can affect the outcome of your project greatly.
Therefore, they should be included in the decisions and have at least some of their interests represented. Of course, the degree of inclusion depends on the level of interest as well.`
    );
  }

  if (C4 && C5 && C0 === false && !C8 && !C9 && !C10 && !C11 && C3) {
    ret.push(
      `Furthermore, all of your external stakeholders seem to be relevant and include both a level of power as well as a level of interest.
Identifying several relevant stakeholders is important, as it will allow you to pay attention to their needs and interests.`
    );
  }

  if (C4 && C5 && C0 === false && !C8 && !C9 && !C10 && !C11 && (C1 || C2)) {
    ret.push(
      `On the other hand, all of your external stakeholders seem to be relevant and include both a level of power as well as a level of interest.
Identifying several relevant stakeholders is important, as it will allow you to pay attention to their needs and interests.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && !C4 && !C5 && !C0 && !C8 && !C9 && !C10 && !C11) {
    ret.push(
      `Not all of your external stakeholders include a level of interest nor a level of power.
Thinking about these levels is especially important for external stakeholders as these are often closely external to your projects and are often more interested and influential.
Understanding their levels of interest and power will help you assess how closely you should be working with them and what interests to take into account.
As a general rule of thumb, very influential stakeholders should be closely involved in your challenge (ask them for feedback, include them in brainstorms, and so on). Very interested stakeholders should be included in the loop (notify them of developments, milestones, and challenges).`
    );
  }

  if (!Z0 && !Z1 && !Z2 && !C7 && !C0 && !C8 && !C9 && !C10 && !C11) {
    ret.push(`Lastly, are you sure that all of the external stakeholders you mentioned are really external stakeholders?
It seems to us that some of them might be internal or connected stakeholders.
All in all this is not so relevant, since, as long as you mention all of your key stakeholders and take into accounts their interests and levels of influence it does not really matter how you categorize them.
However, it is important to be able to distinguish these different groups as it will help you prioritize their needs. Therefore, we highly advise you to take a better look at our course information and the method cards so that this concept is very clear to you and your team. `);
  }

  return ret;
};
