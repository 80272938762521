import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Excellent work identifying and explaining relevant problems that removing the core could cause in your business model. It's important to be able to foresee challenges should a part of your business model fail because it helps you find solutions and change your business model before problems arise.`
    );
  }

  return ret;
};
