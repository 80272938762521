import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Very well done! You have stated a good amount of questions, and all of them are open-ended, do not suggest or give hints about how to use the prototype and relate to his life and work. These questions will certainly help you understand your user better without biasing the testing procedure.`
    );
  }

  return ret;
};
