import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, Z4, Z5 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`Idea not stated.
In order for us to improve your learning experience as much as possible, it is important that you give us detailed descriptions and explanation in exercises like this in the future.
This will help us understand your perspective better and give more specific and actionable feedback later`);
  }

  if (Z1) {
    ret.push(`Thank you for stating the challenge chosen by your team.
This will help us understand your perspective better and give more specific and actionable feedback later.

We wish you the best of luck on your challenge and look forward to seeing your first user research insights!`);
  }

  if (Z2) {
    ret.push(`Thank you for stating and describing the challenge chosen by your team.
This will help us understand your perspective better and give more specific and actionable feedback later.

We wish you the best of luck on your challenge and look forward to seeing your first user research insights!`);
  }

  if (Z3) {
    ret.push(`Thank you for stating and describing your challenge.
This will help us understand your product as well as your team's perspective and will allow us to give you more specific and actionable feedback later.

Thanks again and good luck with your challenge. We look forward to seeing your first user research insights!`);
  }

  if (Z4) {
    ret.push(`Thank you far sharing which challenge you would like to tackle.

However, we ask you to select one of the three challenges proposed by us.
While we understand that working on something closer to your day-to-day activities might be more motivating, we have learned from previous cohorts that for learning purposes, it is better to work on one of our challenges.`);
  }

  if (Z5) {
    ret.push(`Thank you for stating the challenge chosen by your team.
This will help us understand your perspective better and give more specific and actionable feedback later.

Just a reminder, this module has already started, so we recommend you to quickly review the practical assignment to see what still needs to be done before the end of the module. Additionally, it's crucial that you schedule your interviews as soon as possible as they are an important part of this module. If you need any assistance, please don't hesitate to reach out to us.

We wish you the best of luck on your challenge and can't wait to see the insights you gather from your user research!`);
  }
  return ret;
};
