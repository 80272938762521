import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Fantastic work identifying relevant and creative solutions to address the potential problems you identified and explaining how they support the other elements in your business model canvas and ultimately can support the creation, delivery, and capture in your business model. You demonstrated a strong understanding of how to ensure that solutions to potential problems can bolster the overall strength of your business model. By rethinking your business model in this way, you were able to see solutions that can address major potential barriers in your business. These solutions can be used not just to address the problems if they arise, but also as inspiration for a business model reconfiguration.`
    );
  }

  return ret;
};
