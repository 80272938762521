import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A2) {
    ret.push(
      `Thank you for stating the current status for all of the metrics mentioned earlier. Knowing where to find this information and constantly keeping track of it will enable you to detect any deviations and trends and allow you to correct them early on, before they lead to expensive mistakes.`
    );
  }

  if (A2 === false) {
    ret.push(
      `You did not talk about the current status of the metrics.
The whole point of developing and using metrics is to help you assess how well (or poor) you are performing. Therefore, it is super iomportant to periodically check your metrics and discover if you are on track or not.
Doing this early on will allow you to change direction before it is too late and costly.`
    );
  }

  if (A1) {
    ret.push(
      `Good job! You have identified KPIs that require actions from your side to be improved. Keeping constant track of what KPIs need your attention will help you achieve your goals and perform better,`
    );
  }

  if (A1 === false) {
    ret.push(
      `However, you did not clearly state which of these metrics, if any, needs to be addressed the most to ensure that you and your team will achieve your objective stated earlier.`
    );
  }

  if (A4) {
    ret.push(
      `Good job! Your answer shows depth of thought and you have stated a clear plan on how to improve the KPIs you are trying to measure.`
    );
  }

  if (A5) {
    ret.push(
      `The actions and strategies on how to improve your KPIs you have stated are lacking depth. Please make sure to describe them in more detail in the future. Thinking about them in detail will help you assess if these strategies are really effective.`
    );
  }

  return ret;
};
