import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `Even if you did not have the time to submit an answer, you should look back at your own experiences and try to identify one situation in which your team entered on of the pitfalls found in the method cards (Amplification effect, polarization effect, common information bias, anchoring effect, sunk-costs, confirming evidence, framing and estimating and forecasting error). It may not be obvious, but it is unlikely that you have never been in such a situation. Then it is essential to ask yourself what could be done to avoid these pitfalls.`
    );
  }

  if (Z1) {
    ret.push(
      `Well done! You have given a good suggestion on how to avoid this pitfall in the future!
As it is very easy to enter such a pitfall, one should always think of ways to avoid it. Try thinking of even more ways if possible and don't forget to check our method cards for some inspiration!`
    );
  }

  return ret;
};
