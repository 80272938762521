import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A2 && A4 && A6 && A8 && A9 && !A10) {
    ret.push(`Your Innovator's Hypothesis is well designed.
You have managed to take the assumptions behind your idea and transformed them systematically into a clear hypothesis.
More importantly, your statements are precise and you have thought of good metrics to evaluate if your idea was successful.
You should be well prepared to design your experiment in more detail now.
Good luck!`);
  }

  if (!Z0 && !Z1 && (!A2 || !A4 || !A6 || !A8 || !A9) && !A10) {
    ret.push(`Thank you for submitting your Innovator's Hypothesis.
All in all you have done a good job identifying your target user, making it clear what their main painpoint is and stating how your solution will create value for these users.
However, there are still things that could be improved.`);
  }

  if (!Z0 && !Z1 && !A2 && !A10) {
    ret.push(`You did not define your target user well enough.
Most of the characteristics you stated are demographic (age, gender, job), however, in order to design good solutions you should focus on psychographic information (e.g., interest, activities and opinions) as well.
Therefore, please make sure to include this information before continuing your work.
Doing so will help you understand your target users better, thus allowing you to create better solutions for them.`);
  }

  if (!Z0 && !Z1 && !A4 && !A10) {
    ret.push(`Are you sure that the pain point you defined above is really a relevant issue for your users?
We don't know how your interviews went, so we could be wrong, but as it is framed, this issue does not seem to be a big pain for your users.
Therefore, we would like to give you a small mental exercise.

Imagine you are an investor looking for your next investment. You are approached by a team who brings you the hypothesis above exactly as you phrased it.
Would you invest in this idea?

If not, how could you change it so that you might?`);
  }

  if (!Z0 && !Z1 && !A6 && !A10) {
    ret.push(`It seems to us that you killer feature does not really address the pain point you defined earlier.
Are you sure that this feature will trigger you users to react in a way that will help you validate desirability of your solution?`);
  }

  if (!Z0 && !Z1 && !A8 && !A10) {
    ret.push(`The metrics used by you to verify the success of your experiment are not easily measurable.
Please re-write your metrics so that they can be determined clearly or think of other metrics if necessary.`);
  }

  if (!Z0 && !Z1 && !A8 && !A9 && !A10) {
    ret.push(`Furthermore, are you sure that these metrics are enough to tell you if the desirability of your solutions was validated?
Please go over your experiment once more and make sure to change or add more metrics to help you determine the success of your experiment more reliably.`);
  }

  if (!Z0 && !Z1 && A8 && !A9 && !A10) {
    ret.push(`Furthermore, are you sure that these metrics are enough to tell you if the desirability of your solutions was validated?
Please go over your experiment once more and make sure to change or add more metrics to help you determine the success of your experiment more reliably.`);
  }

  /* Still need to finish this last example */
  if (A10) {
    ret.push(`Your hypothesis is not focused enough.
The goal of this exercise is you to pick a clear target user who struggles the most with the problem you are trying to solve. Then, you should for this specific user, pinpoint what exactly their main problem is, what feature in your solution tackles this issue and come up with a clear 2h experiment to validate this idea. One important part of your experiment should be what metrics you will  track `);
  }

  return ret;
};
