import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for your reflection.
It looks like there were many takeaways and learnings from this module for you.
We hope that you are able to implement them in your work as well and are looking forward to reading your learnings from M4! `);
  }

  if (A2) {
    ret.push(`Thank you for mentioning this.
Yes, the testing would have been more effective if you had something more tangible to present.
Hopefully, if you ever run another prototyping session, you will be able to allocate more time to it and develop a more comprehensive prototype.`);
  }

  return ret;
};
