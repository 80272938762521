import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3 } = getSelectedOptionsFlags(selectedOpts);
  /*
    {id = "Z0", text = "No submission", points = 0},
    {id = "Z1", text = "Poor description given", points = 0},
    {id = "Z2", text = "Godd description submitted", points = 0},
    {id = "Z3", text = "Very good submission given", points = 0},
*/
  if (Z0) {
    ret.push(`Idea not stated.
In order for us to improve your learning experience as much as possible, it is important that you give us detailed descriptions and explanatios in exercises like this in the future.
This will help us understand your perspective better and give more specific and actionable feedback later`);
  }

  if (Z1) {
    ret.push(`Thank you for your business idea description.
This will help us understand your perspective better and give more specific and actionable feedback later.

Thanks again and good luck with the coming assignments!`);
  }

  if (Z2) {
    ret.push(`Thank you for the great description of your business idea!
This will help us understand your product as well as your team's perspective and will allow us to give you more specific and actionable feedback later.

Thanks again and good luck with the coming assignments!`);
  }

  if (Z3) {
    ret.push(`Thank you for describing your business idea in so much detail!
This will help us understand your product as well as your team's perspective and will allow us to give you more specific and actionable feedback later.

Thanks again and good luck with the coming assignments!`);
  }
  return ret;
};
