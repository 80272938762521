import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A0 && !A8) {
    ret.push(`No submission`);
  }

  if (A1 && !A8) {
    ret.push(
      `While you did mention a good place to perform your ideation session, you did not state well enough what has to be prepared for you to have a successful ideation session.
Please make sure to write down all the things you will need to prepare beforehand so that your ideation session runs smoothly.`
    );
  }

  if (A2 && !A8) {
    ret.push(
      `While you did mention a good tool to perform your ideation session virtually, you did not state well enough what has to be prepared for you to have a successful ideation session.
Please make sure to write down all the things you will need to prepare beforehand so that your ideation session runs smoothly.`
    );
  }

  if (A3 && !A5 && !A8) {
    ret.push(
      `You have chosen a good place to make your ideation session happen and have made all the necessary preparations for it.
This will increase the probability that your ideation session will run smoothly.
Great job.`
    );
  }

  if (A4 && !A5 && !A8) {
    ret.push(
      `You have chosen a good digital tool to make your ideation session happen virtually and have made all the necessary preparations for it.
This will increase the probability that your ideation session will run smoothly.
Great job.`
    );
  }

  if (A3 && A5 && !A8) {
    ret.push(
      `You have chosen a good place to make your ideation session happen and have made all the necessary preparations for it.
You answer is very detailed and shows how you have thought about how to use the setting to stimulate your participants and make the most of this session.
This will increase the probability that your ideation session will run smoothly. Well done again!`
    );
  }

  if (A4 && A5 && !A8) {
    ret.push(
      `You have chosen a good digital tool to make your ideation session happen virtually and have made all the necessary preparations for it.
You answer is very detailed and shows how you have thought about how to use the setting to stimulate your participants and make the most of this session.
This will increase the probability that your ideation session will run smoothly. Well done again!`
    );
  }

  if (A6 && !A8) {
    ret.push(
      `Thank you for stating both what you would do if your ideation session were to be held physically and how you would hold it virtually.
We hope that for your next project you will be able to hold it physically, but are also happy to see how well you have prepared your digital session.`
    );
  }

  if (A7 && !A8) {
    ret.push(
      `Thank you for your answer.
However, it seems like you did not pick a place for your ideation yet.
Instead, you have only mentioned possible locations.
The goal of this exercise if for you to prepare the ideation session you will conduct soon. Therefore, it is important that you already pick, and possibly reserve, a place to hold it.
Please make sure to find a place before holding your ideation session.`
    );
  }

  if (A8) {
    ret.push(`Your answer is not detailed enough.
We advise you to think more carefully about how you will conduct your session and what you need to prepare.
Especially if this is your first session, it can happen that you are simplifying some steps too much.`);
  }

  return ret;
};
