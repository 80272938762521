import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8, A10, A11 } =
    getSelectedOptionsFlags(selectedOpts);

  /*
  { id= "A1", text= "No submission", points= 0 },
    { id= "A2", text= "Improvement makes sense and is very well described", points= 2 },
    { id= "A3", text= "Improvement makes sense and is well described", points= 1.5 },
    { id= "A4", text= "Improvement makes sense but is poorly described described", points= 1 },
    { id= "A5", text= "Improvement does not seem like an improvement", points= 0.75 },
    { id= "A6", text= "Person says there is nothing to improve", points= 0.75 },
    { id= "A7", text= "Improvement related to program, not to general interviewing skills (e.g. would have scheduled interviews earlier)", points= 1.2 },

  */

  if (A1 && !A10 && !A11) {
    ret.push(`No submission.`);
  }

  if (A2 && !A10 && !A11) {
    ret.push(`Thank you for your answer.
Your suggested improvement makes sense and is very well described.
We advise you to keep doing this simple reflection after your interviews in the future. Doing so will help you improve your overall interviewing skills and habits in the future.`);
  }

  if (A3 && !A10 && !A11) {
    ret.push(`Thank you for your answer.
Your suggested improvement makes sense and is well described.
We advise you to keep doing such a simple reflection after your interviews in the future. Doing so will help you improve your overall interviewing skills and habits in the future.`);
  }

  if (A4 && !A10 && !A11) {
    ret.push(`Thank you for your answer.
While your suggested improvement makes sense, we ask you to be more detailed in the future. Not only will this help us evaluate your answers better, but it will also help you find ways to improve further.
Lastly, we advise you to keep doing these simple reflection after your interviews in the future.`);
  }

  if (A5 && !A10 && !A11) {
    ret.push(`Thank you for your answer.
However, it is not clear to us if this would really help you conduct better interviews in the future.
Therefore, please take another minute to reflect over your experience and try to identify other ways in which you could make your user interviews even more valuable in your next user discovery projects.`);
  }

  if (A6 && !A10 && !A11) {
    ret.push(`Wasn't there really anything that you felt could have been better in your interviews?
Did you manage to ask all the questions you wanted to ask and were you able to dig deep and understand why your users think and behave the way they do?
We invite you to take a minute and reflect more about your experience and to identify possible ways in which you can improve your overall interviewing skills and habits in the future. It is possible that maybe you did everything perfectly, but that is rarely the case (even for experienced interviewers).`);
  }

  if (A7 && !A10 && !A11) {
    ret.push(`Thank you for your answer.
However, it seems like your improvement is only related to this program instead of your general interviewing skills and will not, unless you retake this module, help you in the future.
This is why we advise you to reflect more about this experience and try to identify possible ways in which you can improve your overall interviewing skills and habits in the future.`);
  }

  if (A8 && !A10 && !A11) {
    ret.push(`Thank you for sharing one thing that did not go well in one of your interviews.
But what would you do to make sure this does not happen again in the future?
Could you think of some additional preparation or measure you could take during your interviews to improve the overall experience?

We invite you to take a minute to reflect about possible ways in which you can improve your overall interviewing skills and habits in the future.`);
  }

  if (A10) {
    ret.push(`This is something we hear quite often.
Being comfortable with the silence and pauses is certainly something that feels daunting in the beginning.
But as long as you actively remind yourself to use these pauses, you will see that it will get easier and easier the more you practice it.`);
  }

  if (A11) {
    ret.push(`Thank you for your answer.
We know how hard it is to take notes during your interviews.
We can say that this will get easier the more interviews you conduct. However, another way to get rid of this problem is to record your interviews and use that recoding to make notes afterwards.`);
  }

  return ret;
};
