import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, E0, E1, E2, E3, E4, E5, E6, E7 } = getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "D1", text = "Good additional feedback", points = 0.5},
    {id = "D2", text = "Poor additional feedback", points = 0.1},
  */

  if (E1 || E2) {
    ret.push(`Thank you for this additional feedback.
We are happy to see how you are validating you hypothesis with more than just the required number of users!`);
  }

  return ret;
};
