import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, C1, C2, C3, C4, C5, C6, C7, C8, C9, C10 } = getSelectedOptionsFlags(selectedOpts);

  if (C1 || C2) {
    ret.push(
      `You have described well how you will capture a part of the value you create to all of your users. Well done!
This will help you maximize the potential and robustness of your business model.

We recommend you to periodically reassess your Golden Triangle in the future, as doing so may help you identify and tap into new revenue sources as market conditions change and your business becomes (partly) outdated.`
    );
  }

  if (C3) {
    ret.push(
      `Thank you for describing how you will build revenue streams to your different customer segments. However, to make the most of this exercise, it would have been better if you had described your value capture in more detail.
Having a clear and well defined value proposition to all of your users will help you maximize the potential and robustness of your business model.

Lastly, we recommend you to periodically reassess your Golden Triangle in the future, as doing so may help you identify and tap into new revenue sources as market conditions change and your business becomes (partly) outdated.`
    );
  }

  if (C4 || C5) {
    ret.push(
      `Your description of how you will build revenue streams to your different customer segments is not detailed enough. To make the most of this exercise, please make sure to describe your value capture with more details in the future.
Having a clear and well defined value proposition to all of your users will help you maximize the potential and robustness of your business model.

Lastly, we recommend you to periodically reassess your Golden Triangle in the future, as doing so may help you identify and tap into new revenue sources as market conditions change and your business becomes (partly) outdated.`
    );
  }

  /* Support for arguments */

  if (C6 || C7) {
    ret.push(`You have described well how you will capture a part of the value you create for some of your users.
However, to make sure that your business model is as efficient as possible, you should look for ways to capture value from all of your user segments (must not be monetary value).

Lastly, we recommend you to periodically reassess your Golden Triangle in the future, as doing so may help you identify and tap into new revenue sources as market conditions change and your business becomes (partly) outdated.`);
  }

  if (C8) {
    ret.push(`Thank you for describing how you will capture a part of the value you create for some of your users. However, to make sure that your business model is as efficient as possible, you should look for ways to capture value from all of your user segments (must not be monetary value).
Furthermore, to make sure that this value-capture is well defined, we advise you to describe your value capture in more detail.

Lastly, we recommend you to periodically reassess your Golden Triangle in the future, as doing so may help you identify and tap into new revenue sources as market conditions change and your business becomes (partly) outdated.`);
  }

  if (C9 || C10) {
    ret.push(`Your description of how you will capture a part of the value you create for users is not detailed enough. To make sure that this value-capture is well defined, we advise you to describe your value capture in more detail.
Furthermore, we advise you to look for ways to capture value from all of your user segments (not necessarily monetary value), instead of through just some of them. This will help you maximize the potential and robustness of your business model.

Lastly, we recommend you to periodically reassess your Golden Triangle in the future, as doing so may help you identify and tap into new revenue sources as market conditions change and your business becomes (partly) outdated.`);
  }

  /*
    {id = "C1", text = "Very good description of how each user will generate revenues", points = 2},
    {id = "C2", text = "Good description of how each user will generate revenues", points = 1.5},
    {id = "C3", text = "Acceptable description of how each user will generate revenues", points = 1},
    {id = "C4", text = "Poor description of how each user will generate revenues", points = 0.5},
    {id = "C5", text = "Very poor description of how each user will generate revenues", points = 0},
    {id = "#15", text = "------------------------ For some users --------------------------", points = 0},
    {id = "C6", text = "Very good description of how some user will generate revenues", points = 1.6},
    {id = "C7", text = "Good description of how some user will generate revenues", points = 1.2},
    {id = "C8", text = "Acceptable description of how some user will generate revenues", points = 0.8},
    {id = "C9", text = "Poor description of how some user will generate revenues", points = 0.4},
    {id = "C10", text = "Very poor description of how some user will generate revenues", points =
*/
  return ret;
};
