import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2) {
    ret.push(
      `Well done. You have made it very clear how you will work with your team and where you will meet. Defining this now will make it easier for you and your team to organize and make it happen properly.`
    );
  }

  if (!Z0 && !Z1 && A1 === false && A2 === false) {
    ret.push(
      `You did not state very clearly how you will work and where you will meet with your team as asked. Thinking anbout this now and defining a very clear system will make it easier to insure lateer that your team work well together later.`
    );
  }

  if (A1 === false && A2) {
    ret.push(
      `While you did state it clearly where you and your team will meet, you did not say how you will work. Thinking anbout this now and defining a very clear system will make it easier to insure lateer that your team work well together later.`
    );
  }

  if (A2 && A1 === false) {
    ret.push(
      `While you did state it very clearly how your team will work in the future, you did not make it very clear where you will meet. Thinking anbout this now and defining a very clear system will make it easier to insure lateer that your team work well together later.`
    );
  }

  return ret;
};
