import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for stating a core element you believe is central to your business model. This is the first step to understanding how your business model works and ways to reconfigure it.`
    );
  }

  if (A2) {
    ret.push(
      `Good job choosing a core element that is relevant and central to your business model. Your choice demonstrated an understanding of how the element works with others in your business model which is essential to understanding how to reconfigure it.`
    );
  }

  if (A3) {
    ret.push(
      `Moreover, great job clearly explaining your core element choice and why it is central to your business model. In your explanation, you demonstrated an understanding of how the element works with others in your business model which is essential to understanding how to reconfigure it.`
    );
  }

  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `However, you didn't include a relevant explanation to support your choice of the core element. Try to answer why the element is so central to your business model in relation to how it supports the creation, delivery, and capture of value. Understanding this is an essential step to reconfiguring your business model.`
    );
  }

  return ret;
};
