import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A0, A1, A2, A3, A4 } = getSelectedOptionsFlags(selectedOpts);

  if (A0) {
    ret.push(`No submission.`);
  }

  if (A2) {
    ret.push(
      `The description of your persona is well described.
You have managed to create a narrative that makes the reader feel like he knows this persona a little after reading your description. This is very good as it will make it easier for you and your team to step into your persona's shoes and identify needs, frustrations and possible solutions. Well done!`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A2 === false && (A1 || A2 || A3 || A4)) {
    ret.push(
      `The description of your persona is not very convincing.
The goal of a good description is to create a narrative that will make the reader feel like he knows this persona a little after reading it. This is very important as it will make it easier for you and your team to step into your persona's shoes and identify needs, frustrations and possible solutions.`
    );
  }

  return ret;
};
