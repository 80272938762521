import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `No submission.
Sharing tacit information is one of the most challenging topics when it comes to corporate communication.
We hope that even if you did not have time to submit an answer here, that you managed to reflect on how you currently share it and how you could improve that.
Don't forget that you can check our method cards for some ideas!`
    );
  }

  if (Z1) {
    ret.push(
      `Very well done!
You have made it very clear how tacit information is currently shared in your team and have expressed your concerns on this topic.
More importantly, you have shown thoughtful ways to improve the current sharing of tacit knowledge.
We hope that you will keep working on this in the future so that you and your team keep sharing tacit knowledge and avoiding problems that could arise from not sharing it.`
    );
  }

  return ret;
};
