import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for the submission. You have identified a good variety of different types of value your project can create. Well done! Thinking about these types of value is important for understanding how you can best account for value generated throughout the project. `
    );
  }

  if (A2) {
    ret.push(
      `Thank you for the submission. You have identified a variety of different types of value your project can create. Thinking about these types of value is important for understanding how you can best account for value generated throughout the project. `
    );
  }

  if (A3) {
    ret.push(
      `Thank you for the submission. However, you identified less than 3 different possible types of value generated. There are surely more ways that your project can generate value, otherwise it wouldn't be worth working on. We recommend taking a look at the method card Tangible vs. Intangible value again and continuing to brainstorm ways your project creates value for your company. Doing this will help you accurately account for and communicate the value your project creates.`
    );
  }

  if (A4) {
    ret.push(
      `However, you did not state whether the value you listed is tangible or intangible. It's important to consider this because tangible and intangible value generators are handled differently both when considering financial accounting as well as project management and communication of value.`
    );
  }

  if (A5) {
    ret.push(
      `However, you only stated tangible value creators. While it is not wrong to only have one type of value, the purpose of this exercise is to push yourself to think beyond traditional value creators. Try to think of more intangible value creators the project can produce. For instance, even if you are manufacturing a physical product, learnings about the best materials to use in the process or relationships built with manufacturers could be examples of intangible value.`
    );
  }

  if (A6) {
    ret.push(
      `However, while this is not wrong, you have only stated intangible values that could be generated by this project. For the purpose of this exercise, we encourage you to also think of tangible values that this project can create. 
Innovation projects often struggle to get investment early on because managers might not immediately see the tangible value that can be generated (like cost savings or revenue growth). Therefore, being able to highlight this type of value as well can be very beneficial!`
    );
  }

  if (A7) {
    ret.push(
      `However, some of the values that you indicated as tangible seem to be better categorized as intangible value. Tangible value creators should be directly connected to measurable monetary or physical value (i.e. can be counted concreted) while intangible value creators are indirect contributors to monetary or physical value increases. `
    );
  }

  return ret;
};
