import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for stating if your assumptions were validated or not. It is important to ask yourself this after every quantitative method of collecting data . Doing so will help you evaluate better what metrics actually are relevant and which ones are not. In the long run, this will help you create better surveys.`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for stating the metrics for all the assumptions. Without metrics, it is hard to come to a conclusion if your assumption was validated or not. `
    );
  }

  if (A3) {
    ret.push(
      `You stated metrics for some of your assumptions. It is important to state metrics for all the assumptions.  Without metrics, it is hard to come to a conclusion if your assumption was validated or not. `
    );
  }

  if (A4) {
    ret.push(
      `It is important to state metrics for all the assumptions.  Without metrics, it is hard to come to a conclusion if your assumption was validated or not.  Please take some time and think of some metrics that will be suitable for your assumptions. `
    );
  }

  if (A8) {
    ret.push(
      `However, given the very low number of participants you sent your survey to, we advise you against assuming if your assumptions are correct or incorrect.
It may be the case that they are true for this small set of participants, but do not assume that it is also true for the large majority of users. You would have to get much more data to reliably validate or invalidate your hypotheses.`
    );
  }

  return ret;
};
