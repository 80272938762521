import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Perfect! You have listed all the material, acquired services and also considered other expenses that you expect in the next 12 months to run and grow the project. This along with the human costs calculated in the previous exercise will give you an estimate of the the total direct costs that will occur in your innovaton project.`
    );
  }

  if (Z2) {
    ret.push(
      `Exceptional work! You have listed all the material, acquired services and also considered other expenses that you expect in the next 12 months to run and grow the project in detail. You have covered all costs thoroughly and have a deep undertanding of this topic. These costs along with the human costs calculated in the previous exercise will give you an estimate of the the total direct costs that will occur in your innovaton project.`
    );
  }

  return ret;
};
