import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Well done! You collected valuable feedback from your team. Don't stop here! Collecting feedback after each workshop should become a standard practice of all your future workshops.`
    );
  }

  if (A2) {
    ret.push(
      `Keep it up! You collected some feedback, but there could have been more. Remember the participants after each workshop that their feedback is highly valued. By giving a structure, e.g. through the "I like, I wish, What if" format, you encourage people to share both positive and constructive feedback.`
    );
  }

  if (A3) {
    ret.push(` `);
  }

  if (A4) {
    ret.push(
      `Feedback should always be actionable, and include both positive aspects and opportunities for improvement. It seems like there is some room for improvement on the quality of the feedback. If participants give feedback which is not actionable, do not hesitate to ask for clarification!`
    );
  }

  return ret;
};
