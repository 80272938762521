import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A11 } =
    getSelectedOptionsFlags(selectedOpts);
  /*
    {id = "#8", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "A1", text= "Very general implications for one user(not their persona but a general user)", points= 0.5 },
    { id= "A3", text= "Implications for their persona", points= 1 },
    {id = "#9", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "A6", text= "Implications not detailed", points= 0 },
    { id= "A7", text= "Implications somewhat detailed", points= 1 },
    { id= "A8", text= "Implications very detailed", points= 2 },
    {id = "#23", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "A11", text= "Implication does not make sense", points= 0 },
  */

  if (A3) {
    ret.push(`Thank you for stating the implications you expect this idea to have on your persona.
We are happy to see that you are using your persona instead of just citing a general type of users.
You have put a lot of work into creating these personas. Now it is important to make the most of them.`);
  }
  /*
  if (A4) {
    ret.push(`Thank you for stating the implications you expect this idea to have on these two personas.
Still, you should have stated how this idea would affect at least three of the personas you have stated.
In the future please make sure to evaluate how the ideas you keep might influence all of your personas (and ideally key stakeholders as well). `);
  }

  if (A5) {
    ret.push(`Thank you for stating the implications you expect this idea to have on your personas.
Evaluating how the ideas you keep might influence all of your personas (and ideally key stakeholders as well) will help you realize if this idea is really worth pursuing and might show you some issues that will need to be addressed. `);
  }
*/
  if (A1) {
    ret.push(`You stated how this idea would affect a general type of user, instead of using the specific persona you have created earlier.
We strongly advise you to use your personas in the future as they represent different customer segments, their behaviors and needs. Therefore, using them will help you evaluate this idea on a broader range of behaviour and needs (compared to a general user that is less detailed).`);
  }
  /*
  if (A2) {
    ret.push(`While you did state how this idea would affect different types of users, you did not use the specific personas you have created earlier.
We strongly advise you to use your personas in the future as they represent different customer segments, their behaviors and needs. Therefore, using them will help you evaluate this idea on a broader range of behaviour and needs (compared to a general user that is less detailed).
In the future please make sure to evaluate how the ideas you keep might influence all of your personas (and ideally key stakeholders as well).`);
  }
*/
  if (A6 && A1) {
    ret.push(`Furthermore, your you could have described your implications with more detail.
It is important to think carefully about how this idea might affect the experience of each of your personas. Furthermore, you should focus on the emotions your user might experience and if this solution addresses the underlying needs identified in your user research.
Doing this will allow you to evaluate your ideas better and increase the chance that this idea adds real value to your users.`);
  }

  if (A6 && A3) {
    ret.push(`However, your implications are not detailed enough.
It is important to think carefully about how this idea might affect the experience of each of your personas. Furthermore, you should focus on the emotions your user might experience and if this solution addresses the underlying needs identified in your user research.
Doing this will allow you to evaluate your ideas better and increase the chance that this idea adds real value to your users.`);
  }

  if (A7 && A3) {
    ret.push(`However, while your implications are quite detailed, you did not mention how this solution would make your Persona feel and what emotions they might experience.
When working in user-centric projects, it is important to think carefully about how your solutions might affect the experience of your users. Furthermore, you should focus on whether this solution addresses the underlying needs identified in your user research.
Doing this will allow you to evaluate your ideas better and increase the chance that this idea adds real value to your users.`);
  }

  if (A7 && A1) {
    ret.push(`Furthermore, while our implications are quite detailed, you did not mention how this solution would make your Persona feel and what emotions they might experience.
When working in user-centric projects, it is important to think carefully about how your solutions might affect the experience of your users. Furthermore, you should focus on whether this solution addresses the underlying needs identified in your user research.
Doing this will allow you to evaluate your ideas better and increase the chance that this idea adds real value to your users.`);
  }

  if (A8 && A3) {
    ret.push(`More importantly, when talking about the implications this idea could have on your persona, you focused on their feelings and experience instead of just the obvious consequences.
This is the correct approach and doing so will allow you to evaluate your ideas better and increase the chance that this idea adds real value to your users.
Well done!`);
  }

  if (A8 && A1) {
    ret.push(`However, what you did well (and what is more important) is that, when talking about the implications this idea could have on your persona, you focused on their feelings and experience instead of just the obvious consequences.
This is the correct approach and doing so will allow you to evaluate your ideas better and increase the chance that this idea adds real value to your users.
Well done!`);
  }

  if (A11) {
    ret.push(`Are you sure that the implications you have stated are a direct result of implementing this idea?
Based on what we read in your submission, this relationship is not clear.
Please reflect if this idea will really have the implications stated above.
In the future, please make sure to explain why a certain relationship is true. This will allow us to give you more effective feedback.`);
  }

  return ret;
};
