import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Good job in summarizing and scoping the sprint using the scoping canvas.
It is clear and convincing why this challenge is important for the customer and your company. You stated a clear goal in your desired outcomes, and your success metric is measurable. A clear goal is helping you to keep your direction during the sprint, and communicating it with your management aligns the expectations.

To align everyone and already have a clear direction at the beginning, aim to be more specific on the customer segment, the current situation, and key assumptions. What homeowners do you target? Why do you assume taking care of the home to be a headache?
Including this information makes it 1. easier to get a project approved by management and 2. allows you to get started full speed at the project start, as you already know who to reach out to.

Great job, especially in defining the challenge and resources very clearly.

However, it seems like there is already a solution included, the "AI solution to provide USA homeowners with a digital trusted home advisor". An innovation sprint should never be a simple execution project but have the flexibility to come up with or reject any solution. Try to ask yourself and the sprint sponsor:
- is this constraint needed, or are we open to identify completely different solutions?
- if we test the suggested solution, the outcome can be that the solution gets invalidated and the project killed. Are stakeholders ready for this? Opening up the solution space, makes it more likely to have a validated outcome at the end.
- if the constraint is wanted and killing the project or coming up with other solutions is not an option, suggest running an execution project instead!`);
  }

  return ret;
};
