import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A2 && A3) {
    ret.push(`Well done! You have done a good job mapping your assumptions on your assumption map.
Furthermore, you have also clearly indicated both axes of your matrix which will allow you or any other individual to understand this figure easily.`);
  }

  if (A2 && A3 === false) {
    ret.push(`Good job! While you did map all of your assumptions on a assumptions map, you did not clearly define both axes of your matrix.
This may seem unnecessary for you and your team right now, but in order to keep all of your decision process clear (so that you can understand why you took the decisions you took in the future) as well as make it easier for peopl outside your team to understand your work, it is a good idea to make all of your work very clear by labelling your figures as well as possible!`);
  }

  if (!Z0 && A2 === false) {
    ret.push(`Thank you for your submission. However, we can not clearly identify your assumptions mapping there.
The goal of this exercise was for you and your team to write down each assumption identified on a post-it note and map them according to their easiness to validate and relevance.
This will then help you assess what assumptions are most relevant for your idea and should be validated in your user discovery and need finding research.`);
  }

  if (A4 === false) {
    ret.push(
      `One thing that you could improve, however, is to include the direction in which your two dimensions (relevance and easiness to validate) grow. While it may look obvious to you that they grow as you move up and right, this does not have to be always the case and could lead to unnecessary confusion.`
    );
  }

  return ret;
};
