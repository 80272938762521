import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Thank you for the submission. You have identified a variety of different possible risks. You can never be sure that you included every possible risk around your project, but you probably detected the most urgent ones. Those risks were plotted in the risk evaluation matrix. Additionaly, you set a protection target. This will help you to decide which risks need a handling strategy most urgently, and which ones you tolerate for now. `
    );
  }

  return ret;
};
