import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for your comment. We highly appreciate it!`);
  }

  if (A2) {
    ret.push(`Thank you for your comment.
These insights might be useful for you in M2.

We are looking forward to see which solutions you will come up to address the needs of your users!`);
  }

  return ret;
};
