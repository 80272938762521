import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A6, A7, A8, A9, A10, A11, A12, A13, A14, A15, A16, A17 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A9) {
    ret.push(
      `Well done!
All your learning notes include only one finding per note. This is very important as it makes it easier to cluster and organize them later. Additionally, this keeps your notes short and clear so that the learnings can be shared more easily. Good job!`
    );
  }

  if ((A6 || A17) && A9) {
    ret.push(
      `Furthermore, you have included a participant code in all your learning notes. Good job!
This is less important when you only conduct one or two interviews as you can usually remember which finding is from which interview.
However, if you have more interviews (what you should for a more in-depth user research), then it is important since you want to keep track of who said what in case you have some follow-up questions.
Additionally, it also makes it easier to share your notes if you use a code instead of the full name since this removes all confidential information. So good job doing that!`
    );
  }

  if ((A6 || A17) && A9 === false) {
    ret.push(
      `You have included a participant code in all your learning notes. Good job!
This is less important when you only conduct one or two interviews as you can usually remember which finding is from which interview.
However, if you have more interviews (what you should for a more in-depth user research), then it is important since you want to keep track of who said what in case you have some follow-up questions.
Additionally, it also makes it easier to share your notes if you use a code instead of the full name since this removes all confidential information. So good job doing that!`
    );
  }

  if (A13) {
    ret.push(
      `More importantly, all of your learning notes are objective and do not contain your personal opinion.
This is important as your learning notes should summarise all the key findings from your qualitative research. Keeping them impartial will help you ensure that your findings are not biased before coming up with relevant insights.`
    );
  }

  if (!Z0 && !Z1 && A1 === false && (A6 || A17 || A9 || A13)) {
    ret.push(
      `However, you did not submit 10 learnings notes as asked.
In the future please make sure that you submission meets all required criteria.
This will allow us to understand your work better and give you more effective feedback.`
    );
  }

  if (!Z0 && !Z1 && A1 === false && !A6 && !A9 && !A13) {
    ret.push(
      `You did not submit 10 learnings notes as asked.
In the future please make sure that you submission meets all required criteria.
This will allow us to understand your work better and give you more effective feedback.`
    );
  }

  if ((A7 || A8) && A1 && (A6 || A9 || A13)) {
    ret.push(
      `However, you did not include a participant code to any of your learning notes.
This is less important when you only conduct one or two interviews as you can usually remember which finding is from which interview.
However, if you have more interviews (what you should for a more in-depth user research), then it is important since you want to keep track of who said what in case you have some follow-up questions.
Additionally, it also makes it easier to share your notes if you use a code instead of the full name since this removes all confidential information. Therefore, we recommend you add participant codes to all of your learning notes in the future.`
    );
  }

  if ((A7 || A8) && !A6 && !A9 && !A13 && A1) {
    ret.push(
      `A participant code is not included in some of your learning notes.
This is less important when you only conduct one or two interviews as you can usually remember which finding is from which interview.
However, if you have more interviews (what you should for a more in-depth user research), then it is important since you want to keep track of who said what in case you have some follow-up questions.
Additionally, it also makes it easier to share your notes if you use a code instead of the full name since this removes all confidential information. Therefore, we recommend you add participant codes to all of your learning notes in the future.`
    );
  }

  if ((A7 || A8) && !A6 && !A9 && !A13 && !A1) {
    ret.push(
      `Furthermore, you did not include a participant code to any of your learning notes.
This is less important when you only conduct one or two interviews as you can usually remember which finding is from which interview.
However, if you have more interviews (what you should for a more in-depth user research), then it is important since you want to keep track of who said what in case you have some follow-up questions.
Additionally, it also makes it easier to share your notes if you use a code instead of the full name since this removes all confidential information. Therefore, we recommend you add participant codes to all of your learning notes in the future.`
    );
  }

  if ((A10 || A11 || A12) && !((A7 || A8) && !A6 && !A9 && !A13 && A1)) {
    ret.push(
      `However, some of your learning notes include more than one finding per note.
This is not necessarily wrong, however, having more than one finding per note makes it harder to cluster them later and reduces the clarity of the note. It is much easier to share ideas that are very clear and short.
Therefore, we recommend you to pay attention in the future so that all of your notes include only one finding and are as short as possible.`
    );
  }

  if ((A10 || A11 || A12) && (A7 || A8) && !A6 && !A9 && !A13 && A1) {
    ret.push(
      `Furthermore, some of your learning notes include more than one finding per note.
This is not necessarily wrong, however, having more than one finding per note makes it harder to cluster them later and reduces the clarity of the note. It is much easier to share ideas that are very clear and short.
Therefore, we recommend you to pay attention in the future so that all of your notes include only one finding and are as short as possible.`
    );
  }

  if ((A14 || A15 || A16) && (A10 || A11 || A12)) {
    ret.push(
      `Moreover, it seems to us that some of your learning notes are not objective representations of your interviews.
We may be wrong, but we would just like to point out that a learning note should include only the raw information that you extracted from the interview (e.g. something that you heard or observed during your qualitative research). It should not include your opinion yet as this can bias your data.
This finding can usually be a quote, an observation, a question, or any other factual statement.
For the next time, you should try to keep all of your learning notes impartial (If you did really already include your opinion already).`
    );
  }

  if ((A14 || A15 || A16) && !(A10 || A11 || A12)) {
    ret.push(
      `However, it seems to us that some of your learning notes are not objective representations of your interviews.
We may be wrong, but we would just like to point out that a learning note should include only the raw information that you extracted from the interview (e.g. something that you heard or observed during your qualitative research). It should not include your opinion yet as this can bias your data.
This finding can usually be a quote, an observation, a question, or any other factual statement.
For the next time, you should try to keep all of your learning notes impartial (If you did really already include your opinion already).`
    );
  }

  return ret;
};
