import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A0 } = getSelectedOptionsFlags(selectedOpts);

  if (A0) {
    ret.push(`Thank you for sharing with us who your target user is and what problem you are trying to solve for that user.
It is essential to keep this in mind during innovation projects`);
  }

  return ret;
};
