import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  /*
    { id = "A1", text = "Reflection is about prejudices", points = 0.5 },
    { id = "A2", text = "Shares at least one learning", points = 0.5 },
    { id = "#1", text = "----- ----- ----- PICK ONE FROM THE OPTIONS BELOW ----- ----- -----", points = 0},
    { id = "A3", text = "Reflection is not detailed enough (Bad)", points = 0 },
    { id = "A4", text = "Reflection is somewhat detailed (Bad)", points = 1 },
    { id = "A5", text = "Reflection is quite detailed (okay)", points = 2 },
    { id = "A6", text = "Reflection is very detailed (good)", points = 3 },
*/

  if (A3) {
    ret.push(
      `Thank you for sharing your learnings.
However, from the briefness of your answer, it seems to us that you may have invested little time looking back at this discussion.  If this was really the case, please take a few more minutes to reflect on what was discussed.

The goal of this exercise was to give you an opportunity to evaluate how different people perceive certain situations. There is no right or wrong answer, but we would expect that in some cases, you would all agree, and in others, you would disagree.
The objective is then to understand those differences and make sure to share your information, listen to each other and cooperate. By doing that, you can reduce any friction that might arise and increase the benefits of having diversity in your team.`
    );
  }

  if (A4) {
    ret.push(
      `Thank you for sharing your learnings.
However, from the briefness of your answer, it seems to us that you may have invested little time looking back at this discussion.  If this was really the case, please take a few more minutes to reflect on what was discussed.

The goal of this exercise was to give you an opportunity to evaluate how different people perceive certain situations.
There is no right or wrong answer, but we would expect that in some cases, you would all agree, and in others, you would disagree.
The goal is then to understand those differences and make sure to share your information, listen to each other and cooperate.
By doing that, you can reduce any friction that might arise and increase the benefits of having diversity in your team.`
    );
  }

  if (A5) {
    ret.push(
      `Thank you for your answer. Your reflection and findings are quite detailed.

The goal of this exercise was to give you an opportunity to evaluate how different people perceive certain situations.
There is no right or wrong answer, but we would expect that in some cases, you would all agree, and in others, you would disagree.
The goal is then to understand those differences and make sure to share your information, listen to each other and cooperate.
By doing that, you can reduce any friction that might arise and increase the benefits of having diversity in your team.`
    );
  }

  if (A6) {
    ret.push(
      `Thank you for your answer. Your reflection and findings are very detailed!
We are happy to see that you took the time to think about what you heard there. Hopefully, this will help you remember that different people have different backgrounds, culture and perspectives.
Therefore, it is important to approach certain discussions with an open mind and foster a strong communication to avoid potential misunderstandings or issues.`
    );
  }

  //Saved for the future. Should not be usable anymore.
  /*
  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `You did not state the question you and your team discussed as requested. For the future, please make sure to include all the necessary information so that we can give you a better feedback.`
    );
  }

  if (!Z0 && !Z1 && A1 && A10 === false) {
    ret.push(
      `While you did submit an interesting and relevant question, the question you have stated was not related to prejudices as asked.
This is not a mistake, but we indicated topics for each question to make sure that you will cover different topics. Therefore, we kindly ask you to follow the instructions more closely in the future.`
    );
  }

  if (!Z0 && !Z1 && A1 && A2 === false) {
    ret.push(
      `Thank you for submitting the question discussed in your team. However, please make sure to include your personal answer to that question before talking about similarities and differences.`
    );
  }

  if (A4) {
    ret.push(
      `Good job! The goal of this exercise was to check how aligned the members of your team are. There is no right or wrong answer, but it seems like your team members have, as expected, a mix of opinions. In some cases, you all agree. In others, you disagree. The goal now is to understand those differences and make good use of them. Just make sure to share your information, listen to each other and cooperate. By doing that, you can reduce this friction and increase the benefits of having diversity in your team. This will leverage your team's informational diversity and help you achieve better solutions.`
    );
  }

  if (A5) {
    ret.push(
      `Nicely done! The goal of this exercise was to check how aligned the members of your team are. There is no right or wrong answer, but it seems like all the members of your team have a similar opinion on these questions. This means that you will likely have little friction when deciding what to do or setting common goals. However, it can also mean that this is not a very informationally diverse team.`
    );
  }

  if (A6) {
    ret.push(
      `Well done! The goal of this exercise was to check how aligned the members of your team are. There is no right or wrong answer, but it seems like your team members have diverse opinions on these questions. This means that you will likely have some friction when deciding what to do and when setting your priorities. However, this can also mean that this team is highly informationally diverse, which means that you have the potential to leverage your differences to find better solutions! Just make sure to share your information, listen to each other and cooperate. By doing that, you can reduce this friction and increase the benefits of having diversity in your team.`
    );
  }

  if (A7) {
    ret.push(
      `You only stated one similarity between your and your team's answer. We would have loved to see if there were any additional similarities or differences in your team's answers! This would have helped us give you meaningful feedback and accelerate your learning process. Overall, this exercise's goal was to check how aligned the members of your team are. There is no right or wrong answer, but we would expect that you would all agree in some cases, and in others, you would all disagree. It is then essential to understand those differences and make good use of them. Just make sure to share your information, listen to each other and cooperate. By doing that, you can reduce any friction that might arise and increase the benefits of having diversity in your team. This can leverage your team's informational diversity and help you achieve better solutions.`
    );
  }

  if (A8) {
    ret.push(
      `You only stated one difference between your and your team's answer. We would have loved to see if there were any additional similarities or differences in your team's answers! This would have helped us give you meaningful feedback and accelerate your learning process. Overall, this exercise's goal was to check how aligned the members of your team are. There is no right or wrong answer, but we would expect that you would all agree in some cases, and in others, you would all disagree. It is then essential to understand those differences and make good use of them. Just make sure to share your information, listen to each other and cooperate. By doing that, you can reduce any friction that might arise and increase the benefits of having diversity in your team. This can leverage your team's informational diversity and help you achieve better solutions.`
    );
  }

  if (A9) {
    ret.push(
      `You did not submit the results of your team discussion. We would have loved to see if there were any similarities or differences in your team's answers! This would have helped us give you meaningful feedback and accelerate your learning process. Overall, this exercise's goal was to check how aligned the members of your team are. There is no right or wrong answer, but we would expect that you would all agree in some cases, and in others, you would all disagree. It is then essential to understand those differences and make good use of them. Just make sure to share your information, listen to each other and cooperate. By doing that, you can reduce any friction that might arise and increase the benefits of having diversity in your team. This can leverage your team's informational diversity and help you achieve better solutions.`
    );
  }
  */

  return ret;
};
