import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, C1, C2, C3, D1, C4, C5, C6, C7, C8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (C1 && !D1) {
    ret.push(`Again, our feedback to your third feedback is the same as the two above.
Still, we are happy to see that you did more than just 2 interviews as asked.
Well done!`);
  }
  if (C1 && D1) {
    ret.push(`Again, our feedback to your third feedback is the same as the two above.`);
  }

  if (C2) {
    ret.push(`Thank you for submitting your third protocol here.
Again, you did a very good job highlighting what made each interview unique and have done a great job organizing and formatting your notes after the interview.
This high quality record of your interview will help ensure that no (or as little as possible) information gets lost.
Well done once more!`);
  }

  if (C3) {
    ret.push(`Thank you for this additional protocol.
As the other two above, this protocol is very well structured and would become a similar feedback as the one shared above.`);
  }

  return ret;
};
