import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for submitting the notes from your user journey research!`);
  }

  if (A2) {
    ret.push(
      `You notes are well-organized, which shows you put work into both preparing beforehand and processing the session afterwards. This is essential for extracting learnings from qualitative research.`
    );
  }

  if (A3) {
    ret.push(
      `However, your notes do not seem to have a clear organization to them. We recommend using a notetaking guide similar to the one we have in the Conduct an Interview method card. It is important to set up an organization before are at least after while processing your learnings to get as much as possible out of the qualitative research session.`
    );
  }

  if (A4) {
    ret.push(
      `Your notes do a great job capturing what happened in the session. They are detailed and show that there were many learnings you gained. Good work!`
    );
  }

  if (A5) {
    ret.push(
      `Your notes are not detailed enough to convey all that you should have learned in the research session. A rule of thumb is to have at least 1 learning note per minute of your research. This way you can look back on your session or show your notes to other and be able to understand new insights about the user journey.`
    );
  }

  return ret;
};
