import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`
    );
  }

  if (Z1) {
    ret.push(`Perfect! All of your insights are relevant to your overarching question, they are informative, they are motivating, they are memorable, and they are a combination of some of your findings and your own interpretation. Very well done!`
    );
  }

  return ret;
};