import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Thank you for submitting two idea napkins as asked.
Both your napkins were filled out with relevant information and included a clear visualization. Well done!

Doing this (especially the sketch) will make it easier for you to explain your ideas to others during your feedback session.`
    );
  }

  if (Z2) {
    ret.push(
      `Thank you for submitting two idea napkins as asked.
Both your napkins were filled out with many relevant pieces of information and included a clear visualization. Well done!
However, please keep in mind that the goal of an idea napkin is to briefly and in a simple way share the key aspects of our idea. Often when you are trying to share an idea with a stakeholder we share too much information and make this interaction more complex than it has to be. This is why it may better to have less information on. your idea napkin (depending on who you are presenting the idea to).
Doing this will make it easier for you to explain your ideas to others.`
    );
  }

  return ret;
};
