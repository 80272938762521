import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B1, B2, B3, B4, B5, B6, B7, B8 } = getSelectedOptionsFlags(selectedOpts);

  if (B1) {
    ret.push(`Thank you for uploading this additional file.`);
  }

  return ret;
};
