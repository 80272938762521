import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  /*
  if (A1) {
    ret.push(
      "Well done! Your value proposition connects to a real customer need which is the most essential aspect of good value proposition and the foundation of a strong business model."
    );
  }

  if (A1 && A2) {
    ret.push(
      "Furthermore, your value proposition is well written and makes it clear why a customer would choose your business. This is one of the main purposes of the value proposition and a must-have for any product or service going to market."
    );
  }

  */

  if (A1 === false && A2) {
    ret.push(
      "Well done! Your value proposition is well written and makes it clear why a customer would choose your business. This is one of the main purposes of the value proposition and a must-have for any product or service going to market."
    );
  }

  if (Z0 === false && Z1 === false && Z2 === false && A1 === false && A2 && A3) {
    ret.push(
      `You did a good job of communicating why a customer would choose your product over a competitor's, however, it didn't connect closely enough to a customer need. In order to deliver real value to your customer, you should explain why your product can address a need that you uncovered through interviewing and observing your customers. Return to your customer insights and choose the core need that you seek to address. Incorporate the need into your value proposition statement to make it stronger.`
    );
  }

  if (Z0 === false && Z1 === false && Z2 === false && A2 === false && A1 && A3) {
    ret.push(
      `You did a great job of connecting your value proposition to a customer need and mentioning how it stands out from your competitors, however, you should try to make it even more clear why the customer should choose your product. A value proposition should articulate why your product/service is worth spending money on. Revise your value proposition and use language that clearly communicates this to customers.`
    );
  }

  if (Z0 === false && Z1 === false && Z2 === false && A3 === false && A1 && A2) {
    ret.push(
      `You did a great job of connecting your value proposition to a customer need and why the customer should choose your product, however, you did not make it clear enough how it stands out from competitors. A value proposition is not complete without articulating why the value your product/service delivers is unique compared to competitors. Revise your value proposition and use language that clearly communicates this to customers.`
    );
  }

  if (Z0 === false && Z1 === false && Z2 === false && A1 === false && A2 === false && A3) {
    ret.push(
      `You clearly stated why your product/service is unique compared to competitors but your value proposition did not connect to a customer need or explain why a customer should by your product. It's essential that the value proposition communicates the value that you will deliver to the customer and end user based on their needs. Return to you customer insights and choose the core need that you seek to address. Incorporate the need into your value proposition statement to make it stronger and use language that clearly communicates why your product/service is worth spending money on.`
    );
  }

  if (Z0 === false && Z1 === false && Z2 === false && A1 === false && A2 && A3 === false) {
    ret.push(
      `You explained why a customer should choose your product/service, but you did not connect this to a concrete customer need, nor did you explain why your product/service is unique compared to competitors. It's essential that the value proposition communicates the unique value that you will deliver to the customer and end user based on their needs and emphasize why they will not get the same value from others. Return to you customer insights and choose the core need that you seek to address. Incorporate the need into your value proposition statement to make it stronger and use language that clearly communicates why your product/service stands out from the competition.`
    );
  }

  if (Z0 === false && Z1 === false && Z2 === false && A2 === false && A1 && A3 === false) {
    ret.push(
      `You did a good job of connecting your value proposition to a customer need, however, you are missing other important elements like communicating why a customer should choose your product and why it stands out from competitors. A value proposition should articulate why your product/service in particular is worth spending money on and the unique value it delivers compared to competitors. Revise your value proposition and use a language that clearly communicates this to customers.`
    );
  }

  if ((A1 && A3) || (A2 && A3)) {
    ret.push(
      "Additionally, your value proposition clearly highlights what sets your product/service apart from the competition."
    );
  }

  if (
    Z0 === false &&
    Z1 === false &&
    Z2 === false &&
    A1 === false &&
    A2 === false &&
    A3 === false
  ) {
    ret.push(
      `Your value proposition is missing several important elements like a connection to a customer need, communicating why a customer should choose your product and why it stands out from competitors. Return to you customer insights and choose the core need that you seek to address. Incorporate the need into your value proposition statement to make it stronger. A value proposition should articulate why your product/service in particular is worth spending money on and the unique value it delivers compared to competitors. Revise your value proposition and use a language that clearly communicates this to customers.`
    );
  }

  if (A5) {
    ret.push(
      `You have the foundation of your value proposition, but it was not clear enough. A value proposition needs to be clear and precise, so that one can fully understand the value your product or service generates to all of its stakeholders. Consider including more details about the specific customer needs that your value proposition addresses or highlight what sets your product/service apart from the competition.`
    );
  }

  if (A7) {
    ret.push(
      `Great job differentiating your value proposition for different stakeholders. Doing so will help you ensure to address relevant needs of different users.`
    );
  }

  if (A6) {
    ret.push(
      `Your submission doesn't seem to match the concept of a value proposition as it was explained in the module. A value proposition clearly articulates the value that paying customers or end users can get from your product or service. Revise your value proposition so that it connects to a customer need, states why someone should buy your product, and how your product/service stands out from competitors.`
    );
  }

  if (A8) {
    ret.push(
      `Furthermore, a value proposition is not something you tell your clients directly like an advertising. Instead it is a summary of how you will generate value for all of your users.`
    );
  }

  if (A9) {
    ret.push(
      `Since you have more than one key customer, it is very important that you differentiate between them and create tailor-made value propositions for each of them.
Doing so will help you address the relevant needs of all your different users and be more effective at creating value for large customer segments.`
    );
  }

  return ret;
};
