import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B0, B1, B2, B3, B4, B5, B7, B8, B9 } =
    getSelectedOptionsFlags(selectedOpts);

  if (B0) {
    ret.push(`You have identified only one connected stakeholder.
Connected stakeholders are also fundamental to your project as they can represent great bottlenecks and threats, but also opportunities.
Therefore, we advise you to take even more time to think of more connected stakeholders before proceeding.`);
  }

  if (B8) {
    ret.push(
      `Well done again!
Just like in your answer above, you have managed to identify several relevant connected stakeholders and gave each of them a good description.`
    );
  }

  if (B9) {
    ret.push(
      `Just like in your answer above, you did not include the levels of power and interest for these stakeholders as asked.
In the future, please make sure to submit all the features asked in our questions.`
    );
  }

  if (B1 && !B0 && !B8 && !B9) {
    ret.push(
      `No connected stakeholders submitted.
However, we hope that you at least took some time to think about all the relevant stakeholders and what interest and influence they have on your project. Connected stakeholders are also fundamental to your project as they can represent great bottlenecks and threats, but also opportunities.
Doing so will help you prioritize their desires and needs and facilitate a smooth development for your project.`
    );
  }

  if (B2 && !B0 && !B8 && !B9) {
    ret.push(
      `You have included a decent number of connected stakeholders.
However, we advise you to take even more time to think of several connected stakeholders. Connected stakeholders are also fundamental to your project as they can represent great bottlenecks and threats, but also opportunities.
Doing so will help you prioritize their desires and needs and facilitate a smooth development for your project.`
    );
  }

  if (B3 && !B0 && !B8 && !B9) {
    ret.push(
      `Well done! You have included a good number of connected stakeholders.
Connected stakeholders are also fundamental to your project as they can represent great bottlenecks and threats, but also opportunities.
Doing so will help you prioritize their desires and needs and facilitate a smooth development for your project.`
    );
  }

  if (B4 && !B5 && !B0 && !B8 && !B9) {
    ret.push(
      `While you did include a level of power to your connected stakeholders, you did not include a level of interest.
Connected stakeholders are also fundamental to your project as they can represent great bottlenecks and threats, but also opportunities.
Therefore, it is important to think about their level of interest since highly interested stakeholders should be notified of the decisions and progress made. Of course, the degree of inclusion depends on the level of power as well.`
    );
  }

  if (!B4 && B5 && !B0 && !B8 && !B9) {
    ret.push(
      `While you did include a level of interest to your connected stakeholders, you did not include a level of power.
It is important to think about the level of power, since highly influential stakeholders can affect the outcome of your project greatly.
Therefore, they should be included in the decisions and have at least some of their interests represented. Of course, the degree of inclusion depends on the level of interest as well.`
    );
  }

  if (B4 && B5 && !B0 && !B8 && !B9 && B3) {
    ret.push(
      `Furthermore, all of your connected stakeholders seem to be relevant and include both a level of power as well as a level of interest.
Identifying several relevant stakeholders is important, as it will allow you to pay attention to their needs and interests.
Additionally, thinking about their level of interest and power will help you assess how relevant their needs and wants are for your project. To put it simply, very interested stakeholders should be informed of your progress and milestones, whereas very influential stakeholders should be involved in the development of your project (E.g. get feedback from them or involve them in the ideation phase).
Doing this early on might reduce any conflicts in the future and help ensure that your project runs smoothly.`
    );
  }

  if (B4 && B5 && !B0 && !B8 && !B9 && (B1 || B2)) {
    ret.push(
      `On the other hand, all of your connected stakeholders seem to be relevant and include both a level of power as well as a level of interest.
Identifying several relevant stakeholders is important, as it will allow you to pay attention to their needs and interests.
Additionally, thinking about their level of interest and power will help you assess how relevant their needs and wants are for your project. To put it simply, very interested stakeholders should be informed of your progress and milestones, whereas very influential stakeholders should be involved in the development of your project (E.g. get feedback from them or involve them in the ideation phase).
Doing this early on might reduce any conflicts in the future and help ensure that your project runs smoothly.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && !B4 && !B5 && !B0 && !B8 && !B9) {
    ret.push(
      `Not all of your connected stakeholders include a level of interest nor a level of power.
Thinking about these levels is especially important for connected stakeholders as these are often closely connected to your projects and are often more interested and influential.
Understanding their levels of interest and power will help you assess how closely you should be working with them and what interests to take into account.
As a general rule of thumb, very influential stakeholders should be closely involved in your challenge (ask them for feedback, include them in brainstorms, and so on).
Very interested stakeholders should be included in the loop (notify them of developments, milestones, and challenges).`
    );
  }

  if (!Z0 && !Z1 && !Z2 && !B7 && !B0 && !B8 && !B9) {
    ret.push(`Are you sure that all of the connected stakeholders you mentioned are really connected stakeholders? It seems to us that some of them might be internal or even external stakeholders.
All in all this is not so relevant, since, as long as you mention all of your key stakeholders and take into accounts their interests and levels of influence it does not really matter how you categorize them.
However, it is important to be able to distinguish these different groups as it will help you prioritize their needs. Therefore, we highly advise you to take a better look at our course information and the method cards so that this concept is very clear to you and your team. `);
  }

  return ret;
};
