import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Thank you for defining the success criteria and failure boundary for your metrics. This will help you to assess whether your project is on track, is suceeding your expectations, or falling behind. Clearly defined success criteria and failure boundaries will also help you make data-driven decisions and communicate them with your team.`
    );
  }

  if (Z2) {
    ret.push(
      `Great work defining the success criteria and failure boundary for your metrics. You did a good job of making them specific and measurable. This will help you to assess whether your project is on track, is suceeding your expectations, or falling behind. Clearly defined success criteria and failure boundaries will also help you make data-driven decisions and communicate them with your team.`
    );
  }

  return ret;
};
