import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(`Thank you for stating the experience that you will be working on. Nice job choosing a specific experience. 

    Please use this experience for the upcoming exercises.`);
  }

  if (Z2) {
    ret.push(`Thank you for stating the experience that you will be working on. Unfortunately, the chosen experience seems to be rather broad. Try to narrow it down and concentrate on a specific and relevant part of the overall experience.
    
Please revise and use this experience for the rest of the exercises.`);
  }

  return ret;
};
