import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A1 && !A9) {
    ret.push(
      `Thank you for your well-thought and detailed reflection.
Taking the time to look back at your work and critically evaluate how it transgressed will help you learn more from your experiences.
We advise you to keep doing so in the future.`
    );
  }

  if (A2 && !A9) {
    ret.push(
      `Thank you for your reflection.
Taking the time to look back at your work and critically evaluate how it transgressed will help you learn more from your experiences.
We advise you to keep doing so in the future.`
    );
  }

  if (A3 && !A9) {
    ret.push(
      `Thank you for your reflection.
While your reflection was within the scope of what we expected, we advise you to think about such experiences in even more detail in the future.
Taking the time to look back at your work and critically evaluate how it transgressed will help you learn more from your experiences.
This is why we advise you to do so in your next design thinking sprints (or any relevant projects for that matter).`
    );
  }

  if ((A4 || A5) && !A9) {
    ret.push(
      `Thank you for your answer.
However, your reflection (as stated above) was not detailed enough. We advise you to think about such experiences in even more detail in the future.
Taking the time to look back at your work and critically evaluate how it transgressed will help you learn more from your experiences.
This is why we advise you to do so in your next design thinking sprints (or any relevant projects for that matter).`
    );
  }

  if (A9) {
    ret.push(
      `Thank you for your submission.
The goal of this exercise was for you to reflect on your experience interviewing your users and write down at least four thing that were interesting or went well.
However, it seems to us that you have instead submitted some of the learnings made during your interviews.

This is okay and you can leave your submission like this, but we ask you to still reflect on your experience so far and think of things that went well in your interviews.
Doing this might help you keep certain behaviors that led to these good outcomes and will help you become better and better at interviewing.`
    );
  }

  if (A10) {
    ret.push(
      `Lastly, we also liked how wrote down different aspects for different interviews.
This is not only a better way to document your experience, but it will also help you remember each interview better in the future.
Well done!`
    );
  }

  return ret;
};
