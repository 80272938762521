import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A0) {
    ret.push(
      `Thank you for your answer.
You have identified very few assumptions and should try to identify many more (we recommend at least 20).

We are not trying to be annoying here, but in order to innovate it is useful to identify all the key assumptions you believe to be true. Most of them are most likely true, however, some of them might not. Identifying and validating these false assumptions (what you will do in module 3) can help help you think of new solutions that go against what is currently established.
This is why it is important to extract as many assumptions as possible at this stage. It will give you ideas of what you could try to do differently later!`
    );
  }
  if (A1) {
    ret.push(
      `Thank you for your answer.
You have not identified enough assumptions and should try to identify some more (we recommend at least 20).

We are not trying to be annoying here, but in order to innovate it is useful to identify all the key assumptions you believe to be true. Most of them are most likely true, however, some of them might not. Identifying and validating these false assumptions (what you will do in module 3) can help help you think of new solutions that go against what is currently established.
This is why it is important to extract as many assumptions as possible at this stage. It will give you ideas of what you could try to do differently later!`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for your answer.
You have identified an acceptable number of assumptions.
However, to make the most out of your projects, we advise you to look for even more!

We are not trying to be annoying here, but in order to innovate it is useful to identify all the key assumptions you believe to be true. Most of them are most likely true, however, some of them might not. Identifying and validating these false assumptions (what you will do in module 3) can help help you think of new solutions that go against what is currently established.
This is why it is important to extract as many assumptions as possible at this stage. It will give you ideas of what you could try to do differently later!`
    );
  }

  if (A3) {
    ret.push(`Thank you for your answer.
You have identified a good number of assumptions.

In case you are wondering why we asked you to do this, here is a brief explanation:
In order to innovate it is useful to identify all the key assumptions you believe to be true. Most of them are most likely true, however, some of them might not. Identifying and validating these false assumptions (what you will do in module 3) can help help you think of new solutions that go against what is currently established.
This is why it is important to extract as many assumptions as possible at this stage. It will give you ideas of what you could try to do differently later!`);
  }

  if (A4) {
    ret.push(
      `You have identified a great number of assumptions. Well done!

In case you are wondering why we asked you to do this, here is a brief explanation:
In order to innovate it is useful to identify all the key assumptions you believe to be true. Most of them are most likely true, however, some of them might not. Identifying and validating these false assumptions (what you will do in module 3) can help help you think of new solutions that go against what is currently established.
This is why it is important to extract as many assumptions as possible at this stage. It will give you ideas of what you could try to do differently later!`
    );
  }

  if (A5 && (A3 || A4)) {
    ret.push(
      `Lastly, thank you for submitting all of your assumptions!
We appreciate you taking the time to do so instead of just stating the number of assumptions generated as asked.
Doing so helps us understand and analyze your work better.`
    );
  }

  if (A5 && (A0 || A1 || A2)) {
    ret.push(
      `However, thank you for submitting all of your assumptions!
We appreciate you taking the time to do so instead of just stating the number of assumptions generated as asked.
Doing so helps us understand and analyze your work better.`
    );
  }

  return ret;
};
