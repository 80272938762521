import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);
  /*
    {id = "#1", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id = "A0", text = "Several goals stated - 7+", points = 2 },
    { id = "A1", text = "Acceptable number of goals stated - 4 to 6", points = 1 },
    { id = "A2", text = "Too few goals stated - 3 or less", points = 0 },
    {id = "#2", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id = "A3", text = "Goals are clear", points = 1 },
    { id = "A4", text = "Good submission", points = 1 },
    */

  if (A0) {
    ret.push(
      `Thank you for submitting several goals as asked.
Some of these will be analysed better in the next exercise.
However, while we will only ask you to work with two in more detail, we advise you to go over all of your relevant goals and make sure that all of them are SMART.`
    );
  }

  if (A1) {
    ret.push(
      `Thank you for submitting some goals as asked.
Some of these will be analysed better in the next exercise.
However, while we will only ask you to work with two in more detail, we advise you to go over all of your relevant goals and make sure that all of them are SMART.`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for submitting some goals as asked.
Some of these will be analysed better in the next exercise.
However, while we will only ask you to work with two in more detail, we advise you to go over all of your relevant goals and make sure that all of them are SMART.`
    );
  }

  return ret;
};
