import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B0, B1, B2, B3, B4, B5, B6, B7, B8, B9, B10 } =
    getSelectedOptionsFlags(selectedOpts);

  
    if (B0) {
      ret.push(`Unfortunately you have not submitted any file for us to evaluate.`);
    }
    
    if (B1 && B2 && B3 && !B6 && !B7 && !B9) {
    ret.push(`Thank you for your submission.
Your learnings notes are clearly visible in your submission and they seem to represent well the number or learnings you have stated.

Now you should be ready to meet with your team one last time and share your results.
Together you will look for patterns and recurring themes that you can use to generate relevant insights on your users.
Good luck!`);
  }
  
  if (B1 && B2 && B3 && B9) {
    ret.push(`Thank you for your submission.
Your learnings notes are clearly visible in your submission and they seem to represent well the number or learnings you have stated.
However, we advise you to move these notes to Miro or Mural (whichever your team is using). This will save you time during your last team session where you will join your learnings with the ones from your team.

Once you have done that you will be ready to meet with your team and share your results.
Together you will look for patterns and recurring themes that you can use to generate relevant insights on your users.
Good luck!`);
  }

  if (B6) {
    ret.push(`We are impressed by your submission.
Your learnings notes are clearly visible in your submission and they seem to represent well the number or learnings you have stated.
This extraction of the learning notes is important as it will allow you to share your learnings with your team later and cluster them to generate relevant insights.

Now you should be ready to meet with your team one last time and share your results.
Together you will look for patterns and recurring themes that you can use to generate relevant insights on your users.
Good luck!`);
  }

  if (B1 && B2 && !B3 && !B6 && !B7) {
    ret.push(`Thank you for your submission.
Your learnings notes are clearly visible in your submission. However, they do not seem to represent well the number or learnings you have stated.
In the future, please try to capture all (or at least most) of your work when submitting pictures.

Also, make sure you are ready to meet with your team one last time and share your results.
Together you will join your findings and use them to look for patterns and recurring themes that you can use to generate relevant insights on your users.
Good luck!`);
  }

  if (B1 && !B2 && !B6 && !B7) {
    ret.push(`Thank you for submitting this file.
This helps us visualize better how you and your team worked.

However, we noticed how your learning notes are not written down in a way that they can be moved around easily (e.g virtual or paper post-its).
This is not necessarily mandatory, but since you will have to evaluate and cluster your learnings together with your team later, it is useful to write them down in post-its (In paper or digitally in Miro/Mural).
This will allow you to move them around more easily during your team session later.`);
  }

  if (B4 && B2 && !B6 && !B7) {
    ret.push(`Additionally, we liked how you have already grouped similar learnings!
This is especially important when working in teams as many of the learnings show up several times.
In these cases you can even add a small counter to each learning so that later, when you are clustering the reduced set of learnings (after discarding the repeated ones), you still know how common they were.`);
  }

  if (B4 && !B2 && !B6 && !B7) {
    ret.push(`On the other hand, we liked how you have already grouped similar learnings.
This is especially important when working in teams as many of the learnings show up several times.
In these cases you can even add a small counter to each learning so that later, when you are clustering the reduced set of learnings (after discarding the repeated ones), you still know how common they were.`);
  }

  if (B5) {
    ret.push(`Lastly, we really liked the sketches included in your learning notes.
These will not only make it more enjoyable to cluster and assess these learnings, but can also help convey more information.
Well done and thank you for taking the time to include such sketches!`);
  }

  if (B7) {
    ret.push(`It seems to us that you skipped this part of the process, going from the interview directly to the insights and hypothesis.

For future work, it is important to first document your learnings exactly as you extracted them (without any opinion or suggestions). This will make sure that no data used later is biased or lost.
Then, if you realize during your tests that your assumptions where wrong or that you need to try out a new solution, you will still have your notes from your interviews and can use them to come up with new solutions and hypothesis.
If you don't have these unbiased learnings, you may have to go through your interview recordings again or may even need to interview new people!`);
  }

  if (B8) {
    ret.push(`However, we would like to point out that some of your learning notes have too much text on them.
While we understand that it is hard to reduce the number of words without losing information, having so much text on your findings will make the clustering in the next stage of this assignment much harder.
This will happen because every time someone tries to move one of your notes, they will have to read the entire text. This makes the process much slower than it has to be. Therefore, you should try to make your learnings notes as short as possible. If necessary, feel free to break them down into multiple independent learnings notes.`);
  }

  if (B9 && (!B1 || !B2 || !B3)) {
    ret.push(
      `However, we advise you to move these notes to Miro or Mural (whichever your team is using). This will save you time during your last team session where you will join your learnings with the ones from your team.`
    );
  }

  if (B10 && !B9 && !B8) {
    ret.push(`However, we would like to point out that some of your learning notes may have too little text on them.
While it may be clear to you know what you ach of your learning notes mean, you will see that adding too little text (only a few words) will make it hard for you to remember exactly what your participants said and will make oit easier for you to add your own bias and interpretations ot that learning note.
Therefore, we recommend that in the future you to always ensure that your learning notes have enough written on them so that they are clear.`);
  }

  if (B10 && (B9 || B8)) {
    ret.push(`Furthermore, we would like to point out that some of your learning notes may have too little text on them.
While it may be clear to you know what you ach of your learning notes mean, you will see that adding too little text (only a few words) will make it hard for you to remember exactly what your participants said and will make oit easier for you to add your own bias and interpretations ot that learning note.
Therefore, we recommend that in the future you to always ensure that your learning notes have enough written on them so that they are clear.`);
  }

  return ret;
};
