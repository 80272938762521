import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `No submission.

Sharing tacit (implicit) information is one of the most challenging topics when it comes to corporate communication.
We hope that even if you did not have time to submit an answer here, that you understood this concept well.
If you are not sure, please go over our material as this is a relevant topic.`
    );
  }

  if (Z1) {
    ret.push(
      `Well done. It seems like you understand this topic quite well!
You have given a clear explanation of it and have given a good example as well.`
    );
  }

  return ret;
};
