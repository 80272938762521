import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A5 } = getSelectedOptionsFlags(selectedOpts);
  /*
    { id = "A1", text = "Does the user state how it is shared? ", points = 1 },
    { id = "A2", text = "Does the user share his opinion on the current state how tacit information is shared? ", points = 1 },
    { id = "A3", text = "Does the user state how to improve the sharing of tacit knowledge?", points = 1 },
    { id = "A4", text = "Was the answer good? Does the user show reflection? For example, this could mean that he adds some sort of explanation to why he likes/dislikes the current process, or how it could be improved. It's up to you to decide if he/she showed enough reflection.", points = 2 },
  */
  if (A1 && A2 && A3) {
    ret.push(
      `Very well done!
You have made it very clear how tacit information is currently shared in your team and have expressed your concerns on this topic.
More importantly, you have shown thoughtful ways to improve the current sharing of tacit knowledge.
We hope that you will keep working on this in the future so that you and your team keep sharing tacit knowledge and avoiding problems that could arise from not sharing it.`
    );
  }

  if (A1 && (!A2 || !A3)) {
    ret.push(
      `Thank you for sharing how tacit information is currently being shared in your team.
It is important to keep thinking about this as your team grows in the future.
Most of the issues companies face often happen at least partly because someone along the chain did not know something that was crucial for the successful execution of their work.`
    );
  }

  if (!A3 && A1) {
    ret.push(
      `You have given a good suggestion on how to improve the sharing of tacit knowledge in the future.
Well done!`
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `You did not make it clear how tacit information is currently being shared in your team.
It is important to keep thinking about this now and as your team grows in the future.
Most of the issues companies face often happen at least partly because someone along the chain did not know something that was crucial for the successful execution of their work.`
    );
  }

  if (!Z0 && !Z1 && A1 && A2 === false) {
    ret.push(
      `You haven't given any suggestion on how to improve the way you and your team currently share tacit information.
It is very important to think of a systematic approach to do so to ensure that as little information as possible is kept from some team members.
Furhermore, it is a good idea to start to implement this system now, since you still have a rather small team. Once your team grows substantially it will be much harder to change their culture in a way that ensures good information management.`
    );
  }

  if (A5) {
    ret.push(
      `In your case, as you are still a small team, your current solution might work. But we advise you to start thinking how you can ensure this for the future as well.`
    );
  }

  return ret;
};
