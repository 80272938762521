import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A0 } = getSelectedOptionsFlags(selectedOpts);

  if (A0) {
    ret.push(
      `Thank you for describing what experiment you would run and how you would run it!`
    );
  }

  return ret;
};
