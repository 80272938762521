import * as React from "react";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router-dom";
import { AssignmentContextProvider } from "@sparkademy/app-common/contexts/assignment-context";
import { AssignmentAnswersContextProvider } from "@sparkademy/app-common/contexts/assignment-answers-context";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { ContentContextProvider } from "@sparkademy/app-common/contexts/content-context";
import { ProtectedRoute, ProtectedRouteProps } from "@sparkademy/app-common/utils/ProtectedRoute";
import { Index } from "./pages/Index";
import { Evaluation } from "./pages/Evaluation";
import { Feedback } from "./pages/Feedback";
import { Login } from "./pages/Login";
import { GradingContextProvider } from "./contexts/grading-context";
import { FlaggedSubmissions } from "./pages/FlaggedSubmissions";

type AppProps = RouteComponentProps;

export const App: React.FC<AppProps> = props => {
  const { redirectPathOnAuthentication, isAuthenticated, loaded, currentUser } =
    useSessionContext();

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated,
    authenticationPath: "/login",
    redirectPathOnAuthentication,
    loaded,
    ...(currentUser ? { currentUser } : {}),
  };

  return (
    <ContentContextProvider>
      <AssignmentContextProvider>
        <AssignmentAnswersContextProvider localAnswers={new Map()} updateAnswer={() => null}>
          <GradingContextProvider>
            <Switch>
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                exact
                path="/grading/:cohortId"
                component={Index}
              />
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                exact
                path="/grading"
                component={Index}
              />
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                exact
                path="/flagged"
                component={FlaggedSubmissions}
              />
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                exact
                path="/submission/:assignmentExerciseId"
                component={Evaluation}
              />
              <ProtectedRoute
                {...defaultProtectedRouteProps}
                exact
                path="/feedback/:assignmentExerciseId"
                component={Feedback}
              />
              <Route exact path="/login" component={Login} />
              <Route exact path="/">
                <Redirect to="/grading" />
              </Route>
            </Switch>
          </GradingContextProvider>
        </AssignmentAnswersContextProvider>
      </AssignmentContextProvider>
    </ContentContextProvider>
  );
};
