import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 2.1.
We think your explanation is reasonable and will give you the points for that exercise`);
  }

  if (A2) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 2.2.
We think your explanation is reasonable and will give you the points for that exercise`);
  }

  if (A3) {
    ret.push(`Thank you for clarifying the reason why you did not submit your answer for exercise 2.3.
We think your explanation is reasonable and will give you the points for that exercise`);
  }

  if (A4) {
    ret.push(`We are sorry but we do not agree with your explanation as to why you did not submit this exercise. Please make sure to go over it and perform it with your team.
This is important as it will make the following tasks easier to perform and might help you achieve better insights at the end of your user need discovery.`);
  }

  if (A5) {
    ret.push(`We are sorry but we do not agree with your explanation as to why you did not submit those exercises. Please make sure to go over them and perform them with your team.
This is important as they will make the following tasks easier to perform and might help you achieve better insights at the end of your user need discovery.`);
  }

  if (A6) {
    ret.push(`
Thank you for clarifying the reason why you did not submit your answer for this exercise.

However, we would like to point out that while your online survey includes several relevant questions and will help you to "interview" more participants and gather much more data than you would in a real interview, it does not provide nearly as much depth as you would in a real interview.

The whole goal of this empathising methodology is to discover the underlying needs and frustration of your users. For this it is essential that you sit down with at least a few of them and write down not only what they say (what you get. in your online survey), but also what you observe (pauses, body language, tone).
Furthermore, we also highly advise you to interact with your or even step into their shoes.

Would you share a care to a friend? Try it. How did it feel? What made you nervous? What could have helped make that experience better?

We cannot emphasise just how relevant this user research is for the development of a great service.
    `);
  }

  return ret;
};
