import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A0, A1, A2, A3, A4 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2 && A4) {
    ret.push(
      `Well done! Assuming that your submissions in 2.1 and 2.2 above are correct, your testing procedure is very clear and well designed to test your hypothesis.
Additionally, it is also reproducible, which means that you are able to perform the same test under different conditions and should get similar answers from similar participants.`
    );
  }

  if (A1 && A2 === false) {
    ret.push(
      `Good job! While your test is reproducible (you are able to perform the same test under different conditions and should get similar answers from similar participants), we wonder if this is really a good test to test your assumption. Therefore, we ask you to go over your testing procedure and adapt it so that it tests better the assumption and key elements you have stated earlier.`
    );
  }

  if (A1 === false && A2) {
    ret.push(
      `Good Job! You have designed a very good testing procedure that should help you to validate or invalidate your assumption. However, it seems to us that your testing procedure is not very reliable and depends heavily on your participants and the testing situation. We recommend you think about how you can change your testing procedure so that it is more consistent. Maybe develop a testing system or some protocol that should be followed. `
    );
  }

  if (!Z0 && !Z1 && !Z2 && A1 === false && A2 === false) {
    ret.push(
      `Thank you for sharing your testing procedure. We have noticed that your testing procedure is not very reliable and depends heavily on your participants and the testing situation. We recommend you think about how you can change your testing procedure so that it is more consistent.
Additionally, we wonder if this is really a good test to test your assumption. Therefore, we ask you to go over your testing procedure and adapt it so that it is more consistent and can tests better the assumption and key elements you have stated earlier.`
    );
  }

  if (A4 === false) {
    ret.push(
      `Your submission is not detailed enough.
The idea behind this exercise was for you to go over how you are planning to test your idea in detail so that we can help you make any changes if necessary.
The overall structure of your test might even be good, but it is not clear enough for us to give you actionable feedback.`
    );
  }

  return ret;
};
