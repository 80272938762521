import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { B0, B1, B2, B3 } = getSelectedOptionsFlags(selectedOpts);

  if (B0) {
    ret.push(`No submission`);
  }

  if (B1) {
    ret.push(
      `You did not state enough typical behaviors for your persona.
Stating several behaviors you have identified during your user research will improve your Personas identity and make it easier for you to understand their routine and step into their shoes. This, in turn, will help you identify more needs and goals and consequently design better products and services. This is why we advise you to analyze your findings more M1 again and look for more typical behaviors that you could include in the description of your persona.`
    );
  }

  if (B2) {
    ret.push(
      `You should try to state more typical behaviors of your persona.
Stating several behaviors you have identified during your user research will improve your Personas identity and make it easier for you to understand their routine and step into their shoes. This, in turn, will help you identify more needs and goals and consequently design better products and services. This is why we advise you to analyze your findings more M1 again and look for more typical behaviors that you could include in the description of your persona.`
    );
  }

  if (B3) {
    ret.push(
      `Good job naming so many different behaviors.
This will improve your Personas identity and make it easier for you to understand their routine and step into their shoes.`
    );
  }

  return ret;
};
