import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for your comment. We appreciate it!`);
  }

  if (A2) {
    ret.push(`We agree that 30min is a very short interview.
However, because the focus of this program is on the learning and applying of the methods and concepts taught, we decided to ask for shorter (and fewer) interviews to reduce the effort to the participants.

As mentioned in the course and in our feedback, usually we would advise researchers to conduct at least 10 interviews of 1h each (really depends on the challenge and stakeholders).

We hope that while the time was short, you still managed to get the full experience of preparing, holding and summarizing your interviews.
If you disagree, please leave a more detailed feedback below.
We would love to hear your thoughts!

In any case, thank you for leaving an additional comment and good luck on your final tasks!`);
  }

  return ret;
};
