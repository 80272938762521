import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `You have stated a strategy that your business can use moving forward. This is an important first step to aligning your overall business model strategy with its industry.`
    );
  }

  if (A2) {
    ret.push(
      `You did not state a strategy that your business can use moving forward. Return to the module content and consider a strategy that works best based on your industry's life cycle phase. This is an important first step to aligning your overall business model strategy with its industry.`
    );
  }

  if (A3) {
    ret.push(
      `Your suggested strategy is well aligned with key success factors for the lifecycle phase your industry is in. You have demonstrated an understanding of your industry's lifecycle phase and its implications for the course of action needed as a business.`
    );
  }

  if (A4) {
    ret.push(
      `Your suggested strategy is not aligned with key success factors for the lifecycle phase your industry is in. Return to the module content to better understand your industry's lifecycle phase and its implications for the course of action needed as a business.`
    );
  }

  if (A5) {
    ret.push(
      `You have provided multiple reasons why the strategy you suggested is well aligned with your industry. You carefully considered why the strategy is well aligned with your industry and business which ensures that the strategy is worth moving forward with.`
    );
  }

  if (A6) {
    ret.push(
      `You only included one justification for why the strategy you suggested is well aligned with your industry. It's important to more thoroughly consider why a strategy aligns with your industry lifecycle and your business so that you can ensure the strategy is worth moving forward.`
    );
  }

  if (A7) {
    ret.push(
      `You did not include any justification for the strategy you suggested. It's important to carefully consider why a strategy aligns with your industry lifecycle and your business so that you can ensure the strategy is worth moving forward.`
    );
  }

  return ret;
};
