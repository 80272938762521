import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B0, B1, B2, B3, B4, B5, B7, B8, B9 } =
    getSelectedOptionsFlags(selectedOpts);

  

  if (B1) {
    ret.push(
      `Thank you for submitting your Stakeholder Map here. 
You will find our feedback for it under part a) above.`
    );
  }

  if (B2) {
    ret.push(
      `Thank you for submitting your Stakeholder Map here. 
You will find our feedback for it under part a) above.`
    );
  }

  if (B3) {
    ret.push(
      `Thank you for submitting this additional file here.`
    );
  }

  return ret;
};
