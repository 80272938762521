import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A11, A12, A13 } =
    getSelectedOptionsFlags(selectedOpts);

    /*
    {id = "#5", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    {id = "A3", text = "More than 10 features", points = 1},
    {id = "A4", text = "10 or 9 features", points = 0.8},
    {id = "A5", text = "8 or 7 features", points = 0.5},
    {id = "A6", text = "6 or less features.", points = 0},
    {id = "#14", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    {id = "A10", text = "All features are really features", points = 0},
    {id = "A11", text = "Only some features are really features", points = -0.5},
    {id = "A12", text = "Features are not features (Person didn't understand what features are)", points = -1},
    {id = "A13", text = "Solution is not focused enough, so key features are too broad.", points = -1},
    {id = "#12", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    {id = "A7", text = "Seems like most relevant features have been identified", points = 1},
    {id = "A8", text = "Seems like enough relevant features have been identified", points = 0.5},
    {id = "A9", text = "Some key features are missing (You can think of more relevant features)", points = 0}, 
    */


  if ((A3 || A5 || A4) && (A7 || A8) && !A13) {
    ret.push(`You have successfully broken down your idea into a list of features and functions needed for it to work.
We advise you to keep a good record of this list as you may need to re-evaluate your features after your first experiment.`);
  }

  if ((A3 || A5 || A4) && A9 && !A13) {
    ret.push(`While you have identified a good amount of features and functions from your idea, it seems to us that you have not extracted some relevant ones.
We ask you to go over your idea once more and think about what part of your idea might trigger the effect or action you would like see happening.
If you are unsure what we mean, we advise you to check our material on lecture 1 and 2 of this module once again.`);
  }

  if ((A6) && (A7 || A8) && !A13) {
    ret.push(`While your features are well thought (You seem to have extracted relevant features and functions), It seems to use that there may be some relevant features that were not included here.
There is not a minimum number of features that should be identified, but there are still many key aspects of your idea missing. Therefore, we ask you to try to identify some more.
We believe that by doing so you might find one or two more relevant features that could end up being your key feature.`);
  }

  if ((A6) && A9 && !A13) {
    ret.push(`You have not identified enough features in this exercise.
While there is no minimum number of features needed, we believe that you would benefit by spending more time on this exercise.
Thinking about your solution and identifying the right features now might save you a lot of time later (i.e., by having to conduct less experiments needed).
Therefore, we ask you to look for more features and focus on what part of your idea could trigger the effect you are looking to cause on your users.`);
  }

  if (!A13 && (A11 || A12)) {
    ret.push(`However, some of the things you defined as features are not really features of your idea.
Features should be specific elements of your idea that can be interacted with or at least have a direct impact on the user experience. It is also important that they are only small elements of your idea.
For example, "Cost efficient" is not really a specific feature (this should always be one of your design constraints).
Instead, you could have defined some features as "Includes handlebar for support" or "made out of titanium".`);
  }

  /* A13 cancels all feedback above */
  if (A13) {
    ret.push(`Thank you for sharing some of the features you believe are central to your solution.
However, it seems to us that you have not focused enough on your solution and have shared features that are too broad. Rather than to focus on such a broad solution, we advise you to focus on the core element of your idea and identify the key features necessary to simulate this element.
Doing so will allow you to identify more concrete features that will be easier to isolate and validate.`);
  }

  return ret;
};
