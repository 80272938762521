import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Good job! You have defined all the relevant systems for your sprint. They are stated clearly, and communicated well for the team. The systems are also coherent with each other and are a clear enabler for your sprint's success.`
    );
  }

  return ret;
};
