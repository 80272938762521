import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Good job! You have thought of a good number of metrics.
This is important as a good idea should add value along several dimensions and not just one. For example, if we would rate cars only by their emissions, we would probably still ride horses to work.`
    );
  }

  return ret;
};
