import * as React from "react";
import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@sparkademy/app-common/elements/Alert";
import { Box, Text, Textarea } from "theme-ui";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as IllustrationQuestion } from "@sparkademy/app-common/materials/illustrations/question.svg";
import { ButtonOutline } from "@sparkademy/app-common/elements/ButtonOutline";

export const FlagForReviewDialog: React.FC<{
  onClose: () => void;
  onSubmit: (comment: string) => void;
}> = ({ onClose, onSubmit }) => {
  const cancelRef = React.useRef<HTMLButtonElement>();
  const [comment, setComment] = React.useState("");

  const submitButtonClick = () => {
    if (!comment.trim()) {
      window.alert("please enter a comment explaining why you are flagging for review");
      return;
    }
    onSubmit(comment);
  };

  return (
    <AlertDialogOverlay
      // @ts-ignore
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogContent>
        <Box sx={{ maxWidth: "600px" }}>
          <AlertDialogLabel></AlertDialogLabel>
          <AlertDialogDescription>
            <Box
              sx={{
                margin: "auto",
                width: 170,
                height: 170,
                svg: {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <IllustrationQuestion />
            </Box>
            <Text as="p" sx={{ textAlign: "center", fontWeight: 700, fontSize: "25px" }}>
              Are you sure you want to flag for review?
            </Text>
            <Text as="p" sx={{ textAlign: "center", mt: "15px", fontSize: "1.2em" }}>
              Please leave a comment explaining why.
            </Text>
            <Textarea
              sx={{
                border: "2px solid",
                borderColor: "new.secondary.grey",
                fontFamily: "inherit",
                fontSize: "16px",
                lineHeight: "22px",
                mb: "32px",
                mt: "32px",
                py: "32px",
                px: "48px",
                maxWidth: "550px",
                outline: "none",
                ":focus": {
                  borderColor: "primary",
                },
              }}
              rows={5}
              data-min-rows="5"
              value={comment}
              onChange={e => setComment(e.target.value)}
            />
          </AlertDialogDescription>
        </Box>
        <Box sx={{ mt: 11 }} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "350px",
          }}
        >
          <ButtonOutline onClick={() => onClose()} sx={{ fontSize: "20px" }}>
            Back
          </ButtonOutline>
          <Button type="submit" onClick={submitButtonClick} sx={{ fontSize: "20px" }}>
            Submit
          </Button>
        </Box>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
};
