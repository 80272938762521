import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `No answer submitted. We hope that even if you didn't have the time to submit your solution, that you manage to reflect on how it feels to give and receive feedback. Taking these feelings into consideration before giving feedback will certainly help you provide even better feedback!`
    );
  }

  if (Z1) {
    ret.push(
      `Great job! It seems like you reflected well on this topic. Hopefully, this has helped you better understand what effects feedback can have on both the giver and the receiver.`
    );
  }

  return ret;
};
