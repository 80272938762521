import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A2 && A1 && !A5) {
    ret.push(
      `Your suggested changes make sense and show that you have reflected on the feedback you have received.
Well done.`
    );
  }

  if (!Z0 && A1 === false && A3 === false && !A5) {
    ret.push(
      `Some of the things you would change about your idea are not very clear.
In the future, please make sure to describe what you would change in a simple way so that anyone you might explain this to can effortlessly understand it.
This may seem unnecessary right now (as the feedback you received is still fresh in your head), but you will see that in a few weeks you will not remember that anymore and these notes will be all you have to understand how to proceed.
It is therefore a good idea to take a few minutes more and record it properly.`
    );
  }

  if (!Z0 && A2 === false && A1 && !A5) {
    ret.push(
      `Your conclusion does not seem to agree to the feedback you shared above.
Most of your users pointed out aspects of your idea that may need to be adapted (e.g., "third parties save money", "retraining for new system might not be worth it for users used to the old system", "very complicated and expensive development".

It is every important to not fall in love with your solution.
Therefore, always take your users feedback seriously and if your idea does nt seem good, you need to be able to change or adapt i.`
    );
  }

  if (A3 && !A5) {
    ret.push(
      `Are you sure that there was nothing mentioned in your feedback session that made you want to change or abandon this idea?
This is quite uncommon as there is always something to improve or change.
Therefore, we would advise you to go over your notes from each feedback session and assess again if there was nothing to be learned.`
    );
  }

  if (A4 && !A5) {
    ret.push(
      `Thank you for your answer.
However, it seems like you did not state how you would change the core of your idea.
Instead, you submitted some minor improvements like being more detailed or specific.
What we were asking here, was how you could change your the fundaments of your idea to address the learnings made in your feedback session. `
    );
  }

  if (A5) {
    ret.push(
      `Your suggested changes make sense and show that you have reflected on the feedback you have received.
However, please be more detailed in the future as recording well what you learned, what you would like to change and why you would like to change it will be valuable down the line (This info might be obvious to you now, but in a few weeks you will see that this might not be the case anymore).`
    );
  }

  return ret;
};
