import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(``);
  }

  return ret;
};
