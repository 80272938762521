import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No feedback`);
  }

  if (Z1) {
    ret.push(
      `Great work!
Your persona is well described and contains several needs, behaviors, goals, and characteristics. This makes it easy to step into its shoes and experience the world through its perspective.
Ultimately this will help you create tailor-made products and services that generate a lot of value for some of your users (the ones represented by this persona) by addressing their different needs and frustrations.`
    );
  }

  return ret;
};
