import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B1, B2, B3, B4, B5, B6, B7, B8 } = getSelectedOptionsFlags(selectedOpts);

  if (B1 && B2 && B3 && !B6 && !B7) {
    ret.push(`Thank you for your submission.
Your learnings notes are clearly visible in your submission and they seem to represent well the number or learnings you have stated.
Good job! `);
  }

  if (B6) {
    ret.push(`We are impressed by your submission.
Your learnings notes are clearly visible in your submission and they seem to represent well the number or learnings you have stated.
This extraction of the learning notes is important as it will allow you to share your learnings with your team later and cluster them to generate relevant insights.`);
  }

  if (B1 && B2 && !B3 && !B6 && !B7) {
    ret.push(`Thank you for your submission.
Your learnings notes are clearly visible in your submission. However, they do not seem to represent well the number or learnings you have stated.
In the future, please try to capture all (or at least most) of your work when submitting pictures.`);
  }

  if (B1 && !B2 && !B6 && !B7) {
    ret.push(`Thank you for submitting this file.
This helps us visualize better how you and your team worked.

However, we noticed how your learning notes are not written down in a way that they can be moved around easily (e.g virtual or paper post-its).
This is not necessarily mandatory, but since you will have to evaluate and cluster your learnings later, it is useful to write them down in post-its (paper or virtual).
This will allow you to move them around more easily later.`);
  }

  if (B4 && B2 && !B6 && !B7) {
    ret.push(`Additionally, we liked how you have already grouped similar learnings!
This is especially important when working in teams as many of the learnings show up several times.
In these cases you can even add a small counter to each learning so that later, when you are clustering the reduced set of learnings (after discarding the repeated ones), you still know how common they were.`);
  }

  if (B4 && !B2 && !B6 && !B7) {
    ret.push(`On the other hand, we liked how you have already grouped similar learnings.
This is especially important when working in teams as many of the learnings show up several times.
In these cases you can even add a small counter to each learning so that later, when you are clustering the reduced set of learnings (after discarding the repeated ones), you still know how common they were.`);
  }

  if (B5) {
    ret.push(`Lastly, we really liked the sketches included in your learning notes.
These will not only make it more enjoyable to cluster and assess these learnings, but can also help convey more information.
Well done and thank you for taking the time to include such sketches!`);
  }

  if (B7) {
    ret.push(`It seems to us that you skipped this part of the process, going from the interview directly to the insights and hypothesis.

For future work, it is important to first document your learnings exactly as you extracted them (without any opinion or suggestions). This will make sure that no data used later is biased or lost.
Then, if you realize during your tests that your assumptions where wrong or that you need to try out a new solution, you will still have your notes from your interviews and can use them to come up with new solutions and hypothesis.
If you don't have these unbiased learnings, you may have to go through your interview recordings again or may even need to interview new people!`);
  }

  if (B8) {
    ret.push(`However, we would like to point out that your learning notes have too much text on them.
While we understand that it is hard to reduce the number of words without losing information, having so much text on your findings will make the clustering in the next stage of this assignment much harder.
This will happen because every time someone tries to move one of your notes, they will have to read the entire text. This makes the process much slower than it has to be.

This is why we advise you to always minimize the amount of information stated on your learning notes. If possible, you should also try to use mostly keywords or even illustrations.`);
  }

  return ret;
};
