import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No partner or team submitted.`);
  }

  if (Z1) {
    ret.push(`Thank you for stating your partner for this exercise.`);
  }

  if (Z2) {
    ret.push(`Thank you for stating your partner for this exercise.
We are happy to hear that you met up with someone from outside this program! This will make this exercise much more meaningful.`);
  }


  return ret;
};
