import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts, scoredPoints) => {
  const ret = [];
  const { Z0, Z1, D0, D1, D2 } = getSelectedOptionsFlags(selectedOpts);

  if (D1) {
    ret.push(`Thank you for submitting this additional feedback!
We are happy to see that you have presented your idea to more users than requested by us.
Talking to more users might help you identify missing opportunities or any issues with your ideas.
And identifying this now will save you a lot of work down the road.
Well done!`);
  }

  return ret;
};
