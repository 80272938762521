import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Thank you for sharing the agenda and screenplay. This is one of the most important planning steps for a successful workshop!`
    );
  }

  if (Z2) {
    ret.push(
      `Thank you for submitting your agenda. Share it with the participants of your workshop to manage their expectations. Nevertheless, please prepare a detailed screenplay additionally. This in only for you, and will allow you to focus on the content rather than the organization while running the workshop.`
    );
  }

  return ret;
};
