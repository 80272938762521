import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A6, A7, A8, A10, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A6) {
    ret.push(
      `Thank you for defining the success criteria for your metrics. This will help you to assess whether your project is on track, is succeeding your expectations, or falling behind. All of your KPIs can be measured and assessed on a regular basis.`
    );
  }

  if (A7) {
    ret.push(
      `Thank you for defining the success criteria for your metrics. This will help you to assess whether your project is on track, is succeeding your expectations, or falling behind. Some of your KPIs can be difficult to assess on a regular basis. The reason can be that the KPI is not well defined and is not directly measurable.`
    );
  }

  if (A8) {
    ret.push(
      `Thank you for defining the succcess criteria for your metrics. The goal of this exercise is to help you assess whether your project is on track, is succeeding your expectations, or falling behind. Unfortunately, your KPIs can be difficult to assess on a regular basis because the success is not well defined or the KPI is not measurable. `
    );
  }

  if (scoredPoints > 5 && scoredPoints < 10) {
    ret.push(
      `Well done! You have aimed to avoid the usage of vanity metrics and tried to focus on metrics that will help you to make educated decisions throughout the project. `
    );
  }

  if (scoredPoints > 9) {
    ret.push(
      `Good job! Your metrics are predictive, actionable, and relevant for your project. You have successfully avoided the usage of vanity metrics and instead focused on metrics that will help you to make educated decisions throughout the project.`
    );
  }

  if (A10) {
    ret.push(
      `Unfortunatly, you did not submitt a goal or success criteria for 5 key performance indicators as stated in the exercise.`
    );
  }

  return ret;
};
