import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A11, A12, A13 } =
    getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "A1", text = "says they must improve the experiment", points = 0},
    {id = "A2", text = "says they must focus on a different user", points = 0},
    {id = "A3", text = "says they would tackle a different key feature", points = 0},
    {id = "A4", text = "says they would change the idea completely", points = 0},

    {id = "A11", text = "says they must understand why solutions wasn't good by reaching out to participants", points = 0},
    {id = "A12", text = "says they must improve the solution a little", points = 0},
  */

  if (A1) {
    ret.push(`Thank you for sharing how you would continue.
We are happy to hear that you and your team have evaluated your experiment critically and noticed that it is necessary to adapt and improve your experiment.
As Design Thinking is an iterative approach, this is normal and should be expected.`);
  }

  if (A13) {
    ret.push(`Thank you for sharing how you would continue.
We are happy to hear that you and your team have evaluated your experiment critically and decided to run your experiment a second time with some improved parameters.
As Design Thinking is an iterative approach, this is normal and should be expected.`);
  }

  if (A2) {
    ret.push(`Thank you for sharing how you would continue.
We are happy to hear that you and your team have evaluated your experiment critically and noticed that your experiment must focus on a different target user to be effective.
As Design Thinking is an iterative approach, this is normal and should be expected.`);
  }

  if (A3) {
    ret.push(`Thank you for sharing how you would continue.
We are happy to hear that you and your team have evaluated your experiment critically and noticed that you may need to reassess your idea's key features and conduct another experiment focussing on a different one.
As Design Thinking is an iterative approach, this is normal and should be expected.`);
  }

  if (A11) {
    ret.push(`Thank you for sharing how you would continue.
We are happy to hear that you and your team have evaluated your experiment critically and noticed that you may need to reach out to your participants to understand why your solutions wasn't so appealing.
As mentioned in the course, running these experiments with users and understanding why they liked or disliked your solution is one of the best ways to gather valuable insights about your users.`);
  }

  if (A12) {
    ret.push(`Thank you for sharing how you would continue.
We are happy to hear that you and your team have evaluated your experiment critically and noticed that you may need to improve your solution a little.
We advise you to follow-up with your participants and understand what they liked and disliked about your solution (make sure to ask why!). Doing so will help you uncover further insights and help you run more effective experiments later.`);
  }

  if (A4) {
    ret.push(`Thank you for sharing how you would continue.
We are happy to hear that you and your team have evaluated your experiment critically and noticed that it is necessary to change your idea entirely.
As Design Thinking is an iterative approach, this is normal and should be expected.`);
  }

  if (A5) {
    ret.push(`Thank you for sharing how you would continue.
We are happy to hear that you and your team have evaluated your experiment critically and decided that it was a success and must not be adapted
However, we would just like to point out that this is not usually the case and that you should not expect to always manage to validate the desirability of your idea on your firs try.`);
  }

  if (A8) {
    ret.push(`One thing we would like to point out.
Your answer would have been better if it had been more detailed.
In the future, please make sure to explain your though process better when sharing your team decision.
This will not only allow us to give you better feedback, but will also ensure that you have all of your key decisions documented properly.`);
  }

  if (scoredPoints > 0) {
    ret.push(`The last thing we would like to point out is that your goal during your first experiments should not only be to validate your hypothesis. You should also use this opportunity to make more learnings and new insights that will help you understand your users even better.
To do this, keep asking why until you understand why user A behaves positively and user B does not.
This way, even if you do not get enough information to validate/invalidate your assumptions, you can still use the information gathered on your users to improve your next experiment.`);
  }

  return ret;
};
