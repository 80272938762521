import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank your for describing the workshop you are planning. This helps us to evaluate and give feedback on the following exercises. `
    );
  }

  if (!Z0 && !Z1 && A1 && A2 === false) {
    ret.push(
      `However, you should have added some more details to your answer to make it very clear.
For example, at what time during your sprint is this workshop taking place?
Alternatively you could also look for other ways to give even more context. `
    );
  }

  return ret;
};
