import * as React from "react";
import {
  Assignment,
  Exercise,
  ExerciseStatusType,
  ExerciseSubmission,
  ExerciseTeamMap,
  GradingFeedbackMap,
  Question,
} from "@sparkademy/app-common/models/assignment";
import { ModuleId, ModuleInfo } from "@sparkademy/app-common/models/module";
import { AssignmentService } from "@sparkademy/app-common/services/assignment-service";
import { useAssignmentContext } from "@sparkademy/app-common/contexts/assignment-context";
import { useAssignmentAnswersContext } from "@sparkademy/app-common/contexts/assignment-answers-context";
import { generateFeedback } from "../services/feedback";
import { User } from "@sparkademy/app-common/models/user";
import _ from "lodash";
import { API_URL } from "@sparkademy/app-common/constants";
import { getWithRetry } from "@sparkademy/app-common/utils/httpRetry";
import { GradingService } from "../services/grading";

type GradingContextShape = {
  currentSubmission: ExerciseSubmission | null;
  setSubmission: (e: ExerciseSubmission) => void;
  evaluations: EvaluationState;
  setEvaluationData: (sectionId: string, opts: string[]) => void;
  clearEvaluationData: () => void;
  generateFeedbackData: (e: Exercise) => void;
  selectedModule: ModuleId;
  setSelectedModule: (m: ModuleId) => void;
  fetchSubmission: (submissionId: number, user: User) => void;
  gradingStartTime: Date | null;
  cohorts: CohortInfo[];
  getCohorts: (onlyActive: boolean, user: User) => void;
  fetchTeamsForUser: (userId: string, moduleId: string, grader: User) => void;
  teams: ExerciseTeamMap;
  fetchModuleAssignment: (
    userId: string,
    moduleId: string,
    cohortId: string,
    retry: boolean | null,
    grader: User
  ) => void;
  assignment: Assignment;
  loading: boolean;
};

type EvaluationState = {
  [evaluationItemId: string]: string[];
};

type CohortInfo = {
  id: string;
  name: string;
};

async function getCohortList(onlyActive: boolean = false, user: User): Promise<CohortInfo[]> {
  const url = onlyActive
    ? `${API_URL}/grading/cohorts?token=${user.jwt}`
    : `${API_URL}/grading/cohorts?all=1&token=${user.jwt}`;
  const resp = await getWithRetry(url);
  return (await resp.json()) as Promise<ModuleInfo[]>;
}

export const GradingContext = React.createContext<GradingContextShape>({
  currentSubmission: null,
  setSubmission: () => null,
  evaluations: {},
  setEvaluationData: () => null,
  clearEvaluationData: () => null,
  generateFeedbackData: () => null,
  selectedModule: "m1",
  setSelectedModule: () => null,
  fetchSubmission: () => null,
  gradingStartTime: null,
  cohorts: [],
  getCohorts: () => null,
  fetchTeamsForUser: () => null,
  teams: {},
  fetchModuleAssignment: () => null,
  assignment: {} as Assignment,
  loading: false,
});

export const useGradingContext = () => React.useContext(GradingContext);

const blankAssignment = { id: "", module_id: "", cohort_id: "", exercises: [] };

export const GradingContextProvider: React.FC = ({ children }) => {
  const [currentSubmission, setCurrentSubmission] = React.useState<ExerciseSubmission | null>(null);
  const evaluationsRef = React.useRef<EvaluationState>({});
  const gradingStartTimeRef = React.useRef<Date | null>(null);
  const [assignment, setAssignment] = React.useState<Assignment>(blankAssignment);
  const [loading, setLoading] = React.useState(false);

  // used to keep track of merged feedback for questions, so we perform a merge only once.
  // we merge feedback when a reviewer of a flagged submission changes the original selected grading criteria thus producing a new feedback.
  // if the revision of a flagged submission does not change the original selected grading criteria, we keep the original feedback.
  const mergedFeedbackLogRef = React.useRef<string[]>([]);

  const { setAssignmentStatus } = useAssignmentContext();
  const { addFeedback, setScore, savedFeedback, savedCriteriaApplied } =
    useAssignmentAnswersContext();
  const [selectedModule, setSelectedModule] = React.useState<ModuleId>("m1");
  const [cohorts, setCohorts] = React.useState<CohortInfo[]>([]);
  const [teams, setTeams] = React.useState<ExerciseTeamMap>({});

  const setEvaluationData = (sectionId: string, opts: string[]) => {
    evaluationsRef.current[sectionId] = opts;
  };

  const clearEvaluationData = () => {
    evaluationsRef.current = {};
    setCurrentSubmission(null);
  };

  const updateExerciseStatus = React.useCallback(
    (submission: ExerciseSubmission) => {
      setAssignmentStatus([
        {
          id: submission.id,
          exercise_id: submission.exercise_id,
          status:
            !!submission.graded_at && !submission.needs_grading_review
              ? ExerciseStatusType.GRADED
              : ExerciseStatusType.SUBMITTED,
          is_retry: submission.is_retry,
          grader_rating_status: !!submission.graded_at ? "pending" : "ungraded",
        },
      ]);
    },
    [setAssignmentStatus]
  );

  const setSubmission = React.useCallback(
    (submission: ExerciseSubmission) => {
      gradingStartTimeRef.current = new Date();
      setCurrentSubmission(submission);
      updateExerciseStatus(submission);
    },
    [updateExerciseStatus]
  );

  const fetchSubmission = React.useCallback(
    async (submissionId: number, user: User) => {
      const submission = await AssignmentService.getAssignmentSubmission(submissionId, user);
      setSubmission(submission);
    },
    [setSubmission]
  );

  const generateFeedbackData = (currentExercise: Exercise) => {
    const mergedFeedbackLog = mergedFeedbackLogRef.current;

    // recover saved feedback from session storage
    let parsedSavedFeedback = {} as GradingFeedbackMap;
    const feedbackSessionKey = `${currentSubmission?.id}-feedback`;
    if (sessionStorage.getItem(feedbackSessionKey)) {
      parsedSavedFeedback = JSON.parse(
        sessionStorage.getItem(feedbackSessionKey)!
      ) as GradingFeedbackMap;
      sessionStorage.removeItem(feedbackSessionKey);
    }

    const evaluations = evaluationsRef.current;

    for (const section of currentExercise.sections) {
      const savedSelectedOpts = JSON.parse(
        sessionStorage.getItem(`${currentSubmission?.id}-${section.id}`) || "[]"
      ) as string[];
      const selectedOpts = evaluations[section.id] || savedSelectedOpts;

      const optsSessionKey = `${currentSubmission?.id}-${section.id}`;
      const sessionSavedOpts =
        sessionStorage.getItem(optsSessionKey) !== null
          ? JSON.parse(sessionStorage.getItem(optsSessionKey)!)
          : [];
      const dbSavedOpts = (savedCriteriaApplied[section.id] || []).flatMap(opt => Object.keys(opt));

      let totalScoreForSection = 0;

      // get number of points and feedback for section
      if (selectedOpts.length > 0) {
        totalScoreForSection += section.grading_criteria_options.reduce(
          (acc, cur) => acc + (selectedOpts.includes(cur.grading_id) ? cur.points : 0),
          0
        );

        const currentSessionFeedback =
          parsedSavedFeedback[section.id] && parsedSavedFeedback[section.id][0];

        if (savedFeedback[section.id] && _.isEqual(dbSavedOpts.sort(), selectedOpts.sort())) {
          // use previously saved feedback from the db should grading criteria stay the same
          addFeedback(section.id, savedFeedback[section.id]);
        } else if (
          currentSessionFeedback &&
          _.isEqual(sessionSavedOpts.sort(), selectedOpts.sort())
        ) {
          // use previously saved feedback for this session should grading criteria stay the same
          addFeedback(section.id, [currentSessionFeedback]);
        } else {
          // generate new feedback
          const feedbackList = generateFeedback(
            currentSubmission!.cohort_id,
            currentSubmission!.module_id,
            section.id,
            selectedOpts,
            totalScoreForSection
          );
          // combine feedback list into a single feedback text
          const singleFeedback = feedbackList.join("\n\n");
          addFeedback(section.id, singleFeedback ? [singleFeedback] : []);
        }
      }

      // get number of points and feedback for questions
      for (const question of section.questions) {
        if (selectedOpts.length > 0) {
          const scoredPoints = question.grading_criteria_sets
            .flatMap(set => set.options)
            .reduce(
              (acc, cur) => acc + (selectedOpts.includes(cur.grading_id) ? cur.points : 0),
              0
            );
          totalScoreForSection += scoredPoints;

          const currentSessionFeedback =
            parsedSavedFeedback[question.id] && parsedSavedFeedback[question.id][0];

          const dbOptsForQuestion = filterOptsByQuestion(question, dbSavedOpts);
          const selectedOptsForQuestion = filterOptsByQuestion(question, selectedOpts);
          const sessionOptsForQuestion = filterOptsByQuestion(question, sessionSavedOpts);

          const hasSavedFeedback =
            savedFeedback[question.id] && savedFeedback[question.id].length > 0;

          if (hasSavedFeedback && _.isEqual(dbOptsForQuestion, selectedOptsForQuestion)) {
            // use previously saved feedback from the db should grading criteria stay the same
            addFeedback(question.id, savedFeedback[question.id]);
          } else if (
            currentSessionFeedback &&
            _.isEqual(sessionOptsForQuestion, selectedOptsForQuestion)
          ) {
            // use previously saved feedback for this session should grading criteria stay the same
            addFeedback(question.id, [currentSessionFeedback]);
          } else {
            const feedbackList = generateFeedback(
              currentSubmission!.cohort_id,
              currentSubmission!.module_id,
              question.id,
              selectedOpts,
              scoredPoints
            );
            // combine feedback list into a single feedback text

            if (hasSavedFeedback && !mergedFeedbackLog.includes(question.id)) {
              mergedFeedbackLog.push(question.id);
              // if there is already saved feedback, we need to append the new feedback to the saved feedback
              const existingFeedback = savedFeedback[question.id];
              const combinedFeedback = [
                ...existingFeedback,
                "==== PREVIOUS FEEDBACK ABOVE ====",
                ...feedbackList,
              ];
              addFeedback(question.id, [combinedFeedback.join("\n\n")]);
            } else {
              const singleFeedback = feedbackList.join("\n\n");
              addFeedback(question.id, singleFeedback ? [singleFeedback] : []);
            }
          }
          setScore(question.id, scoredPoints);
        }
      }

      setScore(section.id, totalScoreForSection);
      sessionStorage.setItem(optsSessionKey, JSON.stringify(selectedOpts));
    }
  };

  const getCohorts = React.useCallback((onlyActive: boolean, user: User) => {
    getCohortList(onlyActive, user)
      .then(res => {
        setCohorts(res);
      })
      .catch(console.error);
  }, []);

  const fetchTeamsForUser = React.useCallback((userId: string, moduleId: string, grader: User) => {
    GradingService.getTeamsForUser(userId, moduleId, grader)
      .then(res => {
        const teamsMap = res.reduce((acc, cur) => {
          acc[cur.exercise_id] = cur;
          return acc;
        }, {} as ExerciseTeamMap);
        setTeams(teamsMap);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  const fetchModuleAssignment = React.useCallback(
    (
      userId: string,
      moduleId: string,
      cohortId: string,
      retry: boolean | null = false,
      grader: User
    ) => {
      if ((assignment.module_id === moduleId && assignment.cohort_id === cohortId) || loading) {
        return;
      }

      setLoading(true);
      GradingService.getModuleAssignment(userId, moduleId, retry, grader)
        .then(res => {
          // set the cohort here so the early return above work when it should
          // usually PA's have a blank cohort id so it serves all cohorts
          res.cohort_id = cohortId;
          setAssignment(res);
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
          // set an empty assignment to avoid remaking this very same request
          setAssignment({ id: "", module_id: moduleId, cohort_id: cohortId, exercises: [] });
          setLoading(false);
        });
    },
    [assignment.module_id, assignment.cohort_id, loading]
  );

  return (
    <GradingContext.Provider
      value={{
        currentSubmission,
        setSubmission,
        evaluations: evaluationsRef.current,
        setEvaluationData,
        clearEvaluationData,
        generateFeedbackData,
        selectedModule,
        setSelectedModule,
        fetchSubmission,
        gradingStartTime: gradingStartTimeRef.current,
        cohorts,
        getCohorts,
        fetchTeamsForUser,
        teams,
        fetchModuleAssignment,
        assignment,
        loading,
      }}
    >
      {children}
    </GradingContext.Provider>
  );
};

function filterOptsByQuestion(question: Question, optsArr: string[]): string[] {
  const questionGradingCriteriaOptions = question.grading_criteria_sets.flatMap(set => set.options);
  return optsArr
    .filter(opt => questionGradingCriteriaOptions.find(gc => gc.grading_id === opt))
    .sort();
}
