import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Very well done! Your agenda includes all the relevant and necessary information. This will not only ensure that your participants are motivated and aligned, but it will also make it easier for you to conduct your ideation session later. `
    );
  }

  return ret;
};
