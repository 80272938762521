import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2) {
    ret.push(
      `Well done! The metric you have stated is clearly connected to the overall goal of the project mentioned before and is therefore well suited to track your project's further development and success.
Furthermore, your metric can also be measured very easily and consistently which is essential when picking reliable metrics to track your progress.`
    );
  }

  if (A3) {
    ret.push(
      `The metric you chose is not suitable to measure the how close your project is coming to the goal you identified previously.`
    );
  }

  if (A4) {
    ret.push(`No metric submitted.`);
  }

  if (A5) {
    ret.push(
      `The metric you choose is measureable and therefore fullfills one of the most important criteria of a good metric.`
    );
  }

  if (A6) {
    ret.push(`Unfortunately, it is unclear how you will be able to measure this metric. `);
  }

  return ret;
};
