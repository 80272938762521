import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2 && A3) {
    ret.push(
      `Well done, thank you! You identified various innovation metrics which your company is using to monitor its innovativeness. You also added the current value of these metrics and researched the company goals.`
    );
  }

  if (A1 && A2 === false && A3 === false) {
    ret.push(
      `Well done, thank you! You identified various innovation metrics which your company is using to monitor its innovativeness. Unfortunately, you did not mention a current value or company goals. We can imagine that this is difficult to find, but we are sure that you could find at least some information.`
    );
  }

  if (A1 && A2 === false && A3) {
    ret.push(
      `Well done, thank you! You identified various innovation metrics your company is using to monitor its innovativeness as well as some long term goals. However, you did not share the current value of these metrics. If you struggled to find information on that, the reason could be that the company only recently started to monitor those metrics, or does a bad job in communicating them.`
    );
  }

  if (A1 && A2 && A3 === false) {
    ret.push(
      `Well done, thank you! You identified various innovation metrics which your company is using to monitor its innovativeness. Your answer does not share a goal for these metrics. This might be out of your control, as the company has not defined or is not sharing this information. Remember: to make a metric actionable, it is helpful to have a goal to compare with the current status.`
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `You did not submit three innovation metrics your company is currently using. If you struggled to find information on that, the reason could be that the company only recently started to monitor those metrics, or does a bad job in communicating them. It is therefore a good idea to think if this is the case, and if it is, what you can do to improve the current situation.`
    );
  }

  return ret;
};
