import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13, A14, A15 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A3 && A4 === false) {
    ret.push(
      `Well done. You have included a good amount of breaks. This is important as it will give your participants some time to rest and recharge every now and then. This, in turn, will make it easier to get their full attention during the brainstorming session.`
    );
  }

  if (A3 === false && A4) {
    ret.push(
      `While you did include breaks in your agenda, you did not include enough of them. It is important to give your participants enough time to rest and recharge every now and then. This, in turn, will make it easier to get their full attention during the brainstorming session. This is why we advise you to give your participants breaks of at least 10 minutes for every 90 minutes of work (ideally after the first 40 minutes).`
    );
  }

  if (A5) {
    ret.push(
      ` Good job! You've remembered to include a conclusion section to your agenda. It is important to debrief your participants at the end of the session, as this might give you additional insights and help you learn and improve your hosting skills.`
    );
  }

  if (A11) {
    ret.push(
      `Good job! You've included a section to talk about your desired outcome for all of your activities. This is very important as it will help you align all participants and give them clarity about what exactly is expected from them. This, in turn, can also boost their motivation since they know what to do and why it is relevant.`
    );
  }

  if (A12) {
    ret.push(
      `Good job! You've included a section to talk about your desired outcome for all of your activities. This is very important as it will help you align all participants and give them clarity about what exactly is expected from them. This, in turn, can also boost their motivation since they know what to do and why it is relevant.`
    );
  }

  if (A13) {
    ret.push(
      `You did not include a section to talk about your desired outcome for all of your activities. This is very important as it will help you align all participants and give them clarity about what exactly is expected from them. This, in turn, can also boost their motivation since they know what to do and why it is relevant.  So please ensure that all your activities have a desired outcome defined before you start your ideation session.`
    );
  }

  if (A14) {
    ret.push(
      `You did not include a section to talk about your desired outcome for most of your activities. This is very important as it will help you align all participants and give them clarity about what exactly is expected from them. This, in turn, can also boost their motivation since they know what to do and why it is relevant.  So please ensure that all your activities have a desired outcome defined before you start your ideation session.`
    );
  }

  if (A15) {
    ret.push(
      `You did not include a section to talk about your desired outcome for any of your activities. This is very important as it will help you align all participants and give them clarity about what exactly is expected from them. This, in turn, can also boost their motivation since they know what to do and why it is relevant. So please ensure that all your activities have a desired outcome defined before you start your ideation session.`
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `No introduction included. Whenever you are working with a group on something that may be new for them, it is always very important to start with a simple introduction where you explain what you will be going to do, what your goal is, and any other relevant information. Please make sure to include that before you start your ideation session.`
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `You did not reserve time to talk about your challenge and your problem statement. As the whole goal of the ideation session is to address the issues on your overarching question, it is a good idea to reserve some time to explain this issue in detail. This will help align the priorities of your team and ensure a better workflow throughout your brainstorming session.`
    );
  }

  if (!Z0 && !Z1 && A3 === false && A4 === false) {
    ret.push(
      `You did not include breaks in your agenda. It is important to include them since it will allow your participants to focus more during the work, as they know that a break will be coming soon. Furthermore, enough breaks will also give your participants some time to rest and recharge every now and then. This, in turn, will make it easier to get their full attention during the brainstorming session. Make sure to give your participants at least 10 minutes of breaks every 90 minutes of work.`
    );
  }

  if (!Z0 && !Z1 && A5 === false) {
    ret.push(
      `You did not include a conclusion section to your agenda. It is important to debrief your participants at the end of the session, as this might give you additional insights and will also help you learn and improve your hosting skills.`
    );
  }

  if (!Z0 && !Z1 && A6 === false) {
    ret.push(
      `You did not include an energizer in your agenda. Make sure to state which energizer(s) you will conduct with your participants in your agenda. Also include some information about what your desired outcome is from that activity.`
    );
  }

  if (!Z0 && !Z1 && A7 === false && A8 === false) {
    ret.push(
      `You haven't included any brainstorming method in your agenda. The more brainstorming methods you include, the more ideas you will generate with your team. This is why we would advise you to pick at least two brainstorming methods.`
    );
  }

  if (!Z0 && !Z1 && A9 === false) {
    ret.push(
      `You did not include an impact-effort analysis in your agenda. This process of clustering and classifying ideas is essential for your ideation session and should always be included in it.`
    );
  }

  if (!Z0 && !Z1 && A10 === false) {
    ret.push(
      `You did not include the dot-voting method in your agenda. The whole purpose of an ideation session is for your team to develop some new ideas and solutions to tackle a relevant problem you've identified earlier. Since you will generate many ideas in your session, it is important to include some sort of convergent process to identify the most promising and interesting ideas in the end. It does not necessarily have to be a dot-voting method, but please make sure to include something to help you reduce the number of ideas you will analyze further.`
    );
  }

  return ret;
};
