import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`As mentioned above, reflection and clear decision points are important to make good decisions in a sprint and not lose focus. Does the selected business model allow you to build a sustainable business? To answer this question, you need to add the numbers to the business model as mentioned above.
I found some cost and pricing estimations in the Mid-stage gate report, but to honestly discuss the viability of your idea, I recommend you to collect all this information in one spot, e.g. a business model canvas.`);
  }

  return ret;
};
