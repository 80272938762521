import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for stating the experience you are focusing on in this assignment. Your description will help us evaluate the rest of your answers.`
    );
  }

  if (A3) {
    ret.push(
      `Thank you for also including the main user you are considering in your user journey map.`
    );
  }

  if (A2) {
    ret.push(
      `It is not clear to us from your answer above what exactly your experience entails. 
We ask you to be more detailed in the future. The more context we are given, the better we are able to evaluate your assignment. 
Therefore, we also ask you to always provide explanations for any specific jargon or abbreviations that are specific to your organization in your answers (or avoiding them altogether).`
    );
  }

  if (!Z0 && !A3) {
    ret.push(
      `You did not state the main user you are considering in your user journey map. This would be helpful context for us.`
    );
  }

  

  return ret;
};
