import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A2) {
    ret.push(
      `You have generated lots of ideas and thought about different possibilities for different elements of your business model canvas. It's good to cover all the possible options for each element to ensure you are thoroughly accounting for all of the parts of your business model.`
    );
  }

  if (A4) {
    ret.push(
      `The customer segment(s) you included on your Business Model Canvas fit with the value proposition you wrote in 1.1. Having a strong value proposition that is directly connected to the customers you serve is an essential foundation for a strong business model.`
    );
  }

  if (A5) {
    ret.push(
      `Your financial structure aligns with your key activities, resources, and partners, which shows a key understanding of how the elements of a business model need to be related. Costs and revenues informed by the key activities, resources, and partners reduce the possibility for gaps in your business model.`
    );
  }

  if (A7) {
    ret.push(
      `Some elements on your canvas were unclear what you meant or how they fit into your business plan. It's important that you think carefully about all the parts of your canvas because a functioning business model's parts are all interconnected. Go back through each element on the canvas you filled out and ask yourself what role it plays in the business and how it may enable other functions of the business. If you don't have a clear answer for any element, revise what you wrote down.`
    );
  }

  if (A8) {
    ret.push(
      `Some elements on your canvas are not specific enough to make sense in your business plan. The business model canvas is a tool used to communicate with those outside your team, so make sure you are clear in what you write down, such that someone else can read the canvas and understand your business model. `
    );
  }

  if (A9) {
    ret.push(
      `It seems like you generated lots of ideas and thought about different possibilities for several elements of your business model canvas. However, the file you submitted is not readable.
Therefore, we can not give you any useful feedback for your submission.
Please make sure that future submissions are readable by us.`
    );
  }

  if (A10) {
    ret.push(
      `The file you submitted was not easy to read due to its low resolution. In the future we would appreciate it if you exported it as an excel instead of taking a screenshot of it. This will improve the quality of the image and make it easier for us to grade and give you better feedback.`
    );
  }

  if (Z0 === false && !Z1 && A1 === false) {
    ret.push(
      `A strong business model needs to includes every element on the business model canvas. Make sure that you have filled out every element on the canvas and understand the purpose of it in your business model.`
    );
  }

  if (Z0 === false && !Z1 && A2 === false) {
    ret.push(
      `Usually, a functional business model is more complex than just one item of each element on the business model canvas. Go back through your canvas, especially sections like key activities, resources and partners and consider if there you need to include more items in order to ensure your business model doesn't have gaps and covers all its needs to be successful.`
    );
  }

  if (!Z0 && !Z1 && A4 === false) {
    ret.push(
      `The customer segment you included in your business model canvas doesn't fit with the value propositions you wrote in exercise 1.1. A strong value proposition should be directly connected to what your customer segment is, which together help shape the rest of your business model. Go back to the value proposition you wrote and brainstrom customer segments that it would most closely apply to.`
    );
  }

  if (!Z0 && !Z1 && A5 === false) {
    ret.push(
      `There seem to be some gaps between your financial structure and the key activities, resources and partners you included in your business model canvas. Go back to your costs and revenues and check if they make sense given key activities, resources, and partners.`
    );
  }

  if (!Z0 && !Z1 && A6 === false) {
    ret.push(
      `Some elements on your canvas were unclear what you meant or how they fit into your business plan. It's important that you think carefully about all the parts of your canvas because a functioning business model's parts are all interconnected. Go back through each element on the canvas you filled out and ask yourself what role it plays in the business and how it may enable other functions of the business. If you don't have a clear answer for any element, revise what you wrote down.`
    );
  }

  return ret;
};
