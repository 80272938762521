import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A5) {
    ret.push(
      `Good job. You have written down five relevant assumptions as requested and has included a validation difficulty to all of them as requested. Adding this simple characteristic will help you distinguish between assumptions that are super easy to validate (these should not serve as a base for your user research) and those that are harder to validate (which you should tackle in your research).`
    );
  }

  if (A1 && A6) {
    ret.push(
      `While you managed to write down five relevant assumptions, some of them did not include a validation difficulty as asked. Adding this simple characteristic will help you distinguish between assumptions that are super easy to validate (these ones should not serve as a base for your user research) and those that are harder to validate. Your user research should address these harder ones.`
    );
  }

  if (A1 && A5 === false && A6 === false) {
    ret.push(
      `While you managed to write down five relevant assumptions, none of them included a validation difficulty as asked. Adding this simple characteristic will help you distinguish between assumptions that are super easy to validate (these ones should not serve as a base for your user research) and those that are harder to validate. Your user research should address these harder ones.`
    );
  }

  if (A2 && A5) {
    ret.push(
      `While all of your assumptions are relevant and contain a validation difficulty, you did not submit five different assumptions. Writing down the most relevant assumptions is important because it helps you focus on the ones that have the highest impact. The validation difficulty is useful because it allows you to distinguish between those that are easily verifiable and those that should be central to your user research (as they are much harder to validate). Therefore, it is important to select at least five relevant assumptions from all the ones you came up with within exercise 1.3`
    );
  }

  if (A2 && A6) {
    ret.push(
      `You did not select the five most relevant assumptions as requested. Additionally, not all the assumptions you did identify, include the validation difficulty. Writing down the most relevant assumptions is important because it helps you focus on the ones that have the highest impact. The validation difficulty is useful because it allows you to distinguish between those that are easily verifiable and those that should be central to your user research (as they are much harder to validate). Therefore, it is important to select at least five relevant assumptions from all the ones you came up with within exercise 1.3 and state their validation difficulty.`
    );
  }

  if (A2 && A5 === false && A6 === false) {
    ret.push(
      `You did not select the five most relevant assumptions as requested. Additionally, none of the assumptions you did identify, include the validation difficulty. Writing down the most relevant assumptions is important because it helps you focus on the ones that have the highest impact. The validation difficulty is useful because it allows you to distinguish between those that are easily verifiable and those that should be central to your user research (as they are much harder to validate). Therefore, it is important to select at least five relevant assumptions from all the ones you came up with within exercise 1.3 and state their validation difficulty.`
    );
  }

  if (A3 && A1 && A5) {
    ret.push(
      `Furthermore, all of your assumptions were also correctly stated like an assumption. This is less relevant than the two criteria mentioned above, but it shows us that you and your team understand well what an assumption is and how to state them. Well done!`
    );
  }

  if (A3 && (!A1 || !A5)) {
    ret.push(
      `However, all of your assumptions were also correctly stated like an assumption. This is less relevant than the two criteria mentioned above, but it shows us that you and your team understand well what an assumption is and how to state them. Well done!`
    );
  }

  if (A4 && A1 && A5) {
    ret.push(`However, only some of your assumptions were also correctly stated like an assumption. This is less relevant than the two criteria mentioned above, but we would like to make it clear what an assumption is. An assumption is a relationship between two objects that you believe to be true, but does not have to be so.
It is important to keep this in mind because most of our decisions are based on assumptions we have on our head. Most of them are probably also correct so that the conclusions, and therefore the products and services we develop based on these conclusions, are also good.
However, every now and then these assumptions might be incorrect, meaning that the conclusions we take from them are wrong as well.
This is why it is super important to tackle these underlying assumptions whenever you are trying to come up with innovative products or services.`);
  }

  if (A4 && (!A1 || !A5)) {
    ret.push(`Furthermore, only some of your assumptions were also correctly stated like an assumption. This is less relevant than the two criteria mentioned above, but we would like to make it clear what an assumption is. An assumption is a relationship between two objects that you believe to be true, but does not have to be so.
It is important to keep this in mind because most of our decisions are based on assumptions we have on our head. Most of them are probably also correct so that the conclusions, and therefore the products and services we develop based on these conclusions, are also good.
However, every now and then these assumptions might be incorrect, meaning that the conclusions we take from them are wrong as well.
This is why it is super important to tackle these underlying assumptions whenever you are trying to come up with innovative products or services.`);
  }

  return ret;
};
