import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `You have included only one argument to support your proposed industry lifecycle. To demonstrate a deeper understanding of your industry and the lifecycle phases, you should provide further arguments that support your answer.`
    );
  }

  if (A2) {
    ret.push(
      `You have provided only a couple of arguments to support your proposed industry lifecycle. To demonstrate a deep understanding of your industry and the lifecycle phases, you should provide further arguments that support your answer.`
    );
  }

  if (A3) {
    ret.push(
      `You have provided robust arguments as to why your proposed industry lifecycle phase makes sense. This demonstrates a good understanding of your industry and the lifecycle phase it finds itself. Good job!`
    );
  }

  if (A4) {
    ret.push(
      `Only one of the arguments you made for your answer was supported by specific evidence. It's important to thoroughly research your industry to fully understand where it is in the industry lifecycle and what the markers of maturity and growth are in your industry.`
    );
  }

  if ((A3 && A4) || (A3 && A5) || (A2 && A4)) {
    ret.push(
      `Only a couple of the arguments you made for your answer were supported by specific evidence. It's important to thoroughly research your industry to fully understand where it is in the industry lifecycle and what the markers of maturity and growth are in your industry.`
    );
  }

  if (A6 || (A1 && A4) || (A2 && A5)) {
    ret.push(
      `All of the arguments you made for your answer were supported by specific evidence. Well done! Doing this will help you understand at which stage of the industry lifecycle your business is in and what the markers of maturity and growth are in your industry.`
    );
  }

  if (A7) {
    ret.push(
      `The industry lifecycle phase you chose is accurate and fits your industry as stated.`
    );
  }

  if (A8) {
    ret.push(
      `The industry lifecycle phase you chose is somewhat accurate. Research the markers of growth and maturity in your industry, and return to the characteristics of each industry lifecycle phase and consider which better fits your industry.`
    );
  }

  if (A9) {
    ret.push(
      `The industry lifecycle phase you chose is not accurate. Research the markers of growth and maturity in your industry, and return to the characteristics of each industry lifecycle phase and consider which better fits your industry.`
    );
  }

  return ret;
};
