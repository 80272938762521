export const generateFeedback = (
  cohortId: string,
  moduleId: string,
  evaluationItemId: string,
  selectedOpts: string[],
  scoredPoints: number
): string[] => {
  try {
    // try the cohort feedback files first
    const {
      buildFeedback,
    } = require(`../data/feedback/${cohortId}/${moduleId}/${evaluationItemId}`);
    return buildFeedback(selectedOpts, scoredPoints);
  } catch {
    // if there's no specific feedback for cohort use the regular one
    try {
      const { buildFeedback } = require(`../data/feedback/${moduleId}/${evaluationItemId}`);
      return buildFeedback(selectedOpts, scoredPoints);
    } catch {
      return [];
    }
  }
};
