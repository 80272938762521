import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Great job! You added templates to the strategic conversations you are planning. Templates, no matter how simple they are, make sure the team stays focused on the conversation they need to have. Additionaly, make sure that people fill out post it's instead of writing directly on the template. This encourages participants to write things down while still having the option to change everything.`
    );
  }

  if (A2) {
    ret.push(
      `Well done! Templates, no matter how simple they are, make sure the team stays focused on the conversation they need to have. Therefore, try to define even more template that you will be using suring the workshops. As a rule of thumb, every strategic conversation gets at least one template. And it can be as simple as an empty page with a title and goal!`
    );
  }

  if (A3) {
    ret.push(
      `Thank you for sharing this template. Templates, no matter how simple they are, make sure the team stays focused on the conversation they need to have. Therefore, try to define even more template that you will be using suring the workshops. As a rule of thumb, every strategic conversation gets at least one template. And it can be as simple as an empty page with a title and goal!`
    );
  }

  if (A4) {
    ret.push(`Great job! Templates, no matter how simple they are, make sure the team stays focused on the conversation they need to have.
One last tip: make sure that people fill out post it's instead of writing directly on the template. This encourages participants to write things down while still having the option to change everything.`);
  }

  return ret;
};
