import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts, scoredPoints) => {
  const ret = [];
  const { D1 } = getSelectedOptionsFlags(selectedOpts);

  if (D1) {
    ret.push(`That's a great choice!
We look forward to seeing what you will decide with your team and are anxious to seeing your first findings!`);
  }

  return ret;
};
