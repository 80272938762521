import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for sharing the main goal behind your participation in this program.
We look forward to hearing your feedback and seeing if you have noticed a tangible progress in these skills during the course of our program.`);
  }

  return ret;
};
