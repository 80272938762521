import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Your criteria are clearly formulated and convincing. This will not only help to align with your team and share a vision throughout the sprint, but set clear expectations and boundaries when your leadership wants to add additional elements to the sprint.`
    );
  }

  if (A2) {
    ret.push(
      `To align your team and leadership with one vision and agree on a set of boundaries, you could formulate your criteria even more detailed and understandable. This is also a great opportunity to manage additional leadership requests before even starting the sprint.`
    );
  }

  if (A3) {
    ret.push(
      `Unfortunately, your criteria lack some depth and detail to make the vision understandable and shareable. A clear formulated set of criteria will not only help to align with your team and share a vision throughout the sprint, but set clear boundaries when your leadership wants to add additional elements to the sprint. This makes it easier to make informed decisions throughout the sprint. Add some more criteria before getting started!`
    );
  }

  if (A4) {
    ret.push(
      `We also noticed that you only named technical criteria. Use this vision at the beginning of the sprint to also agree on deliverables. How many ideas should be identified and tested? How detailed will the prototypes be? You can manage the expectations with your leadership to not build a full functioning MVP, but focus on testing the assumptions regarding the selected idea. How many experiments do you plan as a minimum? How many business models?
Many elements might be clear for you, but not your team or leadership.`
    );
  }

  return ret;
};
