import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(`Well done.
It seems like your team has discussed this properly and come up with a simple and clear sheet summarising your main issue, your user and how your solution can create value for this user.
Furthermore, it seems to us that your key feature is a good trigger for the impact your are planning to achieve.
That is exactly what you should aim for at this stage.
Now, the important step will be to design an experiment that will bring your users into a decision point where you can test whether this key feature (e.g., sending a text message to your users) will bring cause your intended impact.`);
  }

  return ret;
};
