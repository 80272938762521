import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } =
    getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "#6", text = "----------------------- Always select this ---------------------------", points = 0},
    {id = "A0", text = "Starting points", points = 15},
    {id = "#7", text = "----------------------- For first metric ---------------------------", points = 0},
    {id = "A1", text = "Metric is not measurable", points = -2},
    {id = "A2", text = "Metric is not actionable", points = -2},
    {id = "#8", text = "----------------------- For second metric ---------------------------", points = 0},
    {id = "A3", text = "No second metric", points = -5},
    {id = "A4", text = "Metric is not measurable", points = -2},
    {id = "A5", text = "Metric is not actionable", points = -2},
    {id = "#9", text = "----------------------- For third metric ---------------------------", points = 0},
    {id = "A6", text = "No third metric", points = -5},
    {id = "A7", text = "Metric is not measurable", points = -2},
    {id = "A8", text = "Metric is not actionable", points = -2},
    {id = "#10", text = "----------------------- For all Metrics ---------------------------", points = 0},
    {id = "A9", text = "All metrics are leading metrics", points = 0},
    {id = "A10", text = "Some metrics are lagging metrics", points = -3},
  */

  if (A1 && !A4 && !A7) {
    /* Must correct this with A1, A4 and A7 */
    ret.push(
      `It is unclear how you will be able to measure your first metric. A good metric should always be measurable. We recommend being specific in what observable actions or outcomes are representative of your goals. Having clear measures will help you set success criteria and boundaries that will guide your decision-making in the future.`
    );
  }

  if (A2 && !A5 && !A8) {
    ret.push(
      `Unfortunately, your first metric is not actionable. This will make it hard for you to take relevant (and data-driven) decisions during the next phases of your project.
To improve this, try to think about how you would use your current metric to take such a decision. Now, how can you reformulate this metric to make it easier, and make you feel more confident at taking this same decision? `
    );
  }

  if (A3) {
    ret.push(
      `You only submitted one metric. This is not sufficient for measuring project success. We suggest you come up with at least two more actionable metrics you can measure.`
    );
  }

  if (A4 && !A1 && !A7) {
    ret.push(
      `It is unclear how you will be able to measure your second metric. A good metric should always be measurable. We recommend being specific in what observable actions or outcomes are representative of your goals. Having clear measures will help you set success criteria and boundaries that will guide your decision-making in the future`
    );
  }

  if (A4 && A1 && !A7) {
    ret.push(
      `It is unclear how you will be able to measure your first and second metrics. A good metric should always be measurable. We recommend being specific in what observable actions or outcomes are representative of your goals. Having clear measures will help you set success criteria and boundaries that will guide your decision-making in the future`
    );
  }

  if (A5 && !A2 && !A8) {
    ret.push(
      `Unfortunately, your second metric is not actionable. This will make it hard for you to take relevant (and data-driven) decisions during the next phases of your project.
To improve this, try to think about how you would use your current metric to take such a decision. Now, how can you reformulate this metric to make it easier, and make you feel more confident at taking this same decision? `
    );
  }

  if (A5 && A2 && !A8) {
    ret.push(
      `Unfortunately, your first and second metrics are not actionable and will make it hard for you to take relevant (and data-driven) decisions during the next phases of your project.
To improve this, try to think about how you would use your current metrics to take these decisions. Now, how can you reformulate your metrics to make it easier (and make you feel more confident) about taking these same decisions? `
    );
  }

  if (A6) {
    ret.push(
      `You only submitted two metrics. This is not sufficient for getting a full picture of your project's success. We suggest you come up with at least one more actionable metric you can measure.`
    );
  }

  if (A7 && !A4 && !A1) {
    ret.push(
      `It is unclear how you will be able to measure your third metric. A good metric should always be measurable. We recommend being specific in what observable actions or outcomes are representative of your goals. Having clear measures will help you set success criteria and boundaries that will guide your decision-making in the future`
    );
  }

  if (A7 && A4 && !A1) {
    ret.push(
      `It is unclear how you will be able to measure your second and third metrics. A good metric should always be measurable. We recommend being specific in what observable actions or outcomes are representative of your goals. Having clear measures will help you set success criteria and boundaries that will guide your decision-making in the future`
    );
  }

  if (A7 && !A4 && A1) {
    ret.push(
      `It is unclear how you will be able to measure your first and third metrics. A good metric should always be measurable. We recommend being specific in what observable actions or outcomes are representative of your goals. Having clear measures will help you set success criteria and boundaries that will guide your decision-making in the future`
    );
  }

  if (A7 && A4 && A1) {
    ret.push(
      `It is unclear how you will be able to measure all of you three metrics. A good metric should always be measurable. We recommend being specific in what observable actions or outcomes are representative of your goals. Having clear measures will help you set success criteria and boundaries that will guide your decision-making in the future`
    );
  }

  if (A8 && !A5 && !A2) {
    ret.push(
      `Unfortunately, your third metric is not actionable. This will make it hard for you to take relevant (and data-driven) decisions during the next phases of your project.
To improve this, try to think about how you would use your current metric to take such a decision. Now, how can you reformulate this metric to make it easier, and make you feel more confident at taking this same decision?`
    );
  }

  if (A8 && A5 && !A2) {
    ret.push(
      `Unfortunately, your second and third metrics are not actionable and will make it hard for you to take relevant (and data-driven) decisions during the next phases of your project.
To improve this, try to think about how you would use your current metrics to take these decisions. Now, how can you reformulate your metrics to make it easier (and make you feel more confident) about taking these same decisions?`
    );
  }

  if (A8 && !A5 && A2) {
    ret.push(
      `Unfortunately, your first and third metrics are not actionable and will make it hard for you to take relevant (and data-driven) decisions during the next phases of your project.
To improve this, try to think about how you would use your current metrics to take these decisions. Now, how can you reformulate your metrics to make it easier (and make you feel more confident) about taking these same decisions? `
    );
  }

  if (A8 && A5 && A2) {
    ret.push(
      `Unfortunately, all of your three metrics are not actionable and will make it hard for you to take relevant (and data-driven) decisions during the next phases of your project.
To improve this, try to think about how you would use your current metrics to take these decisions. Now, how can you reformulate your metrics to make it easier (and make you feel more confident) about taking these same decisions?`
    );
  }

  if (A9) {
    ret.push(
      `Also, all of your metrics are leading metrics. Well done! Leading metrics should be predictive of how successful your project will be in the future. This means you have data to support decisions throughout the project, rather than waiting to review what happened in the past.`
    );
  }

  if (A10) {
    ret.push(
      `However, some of the metrics you chose are lagging metrics. This doesn't necessarily make it a bad metric to measure, but it will make it harder for you to monitor frequently and make decisions throughout your project. We recommend the majority of your project metrics are leading metrics. Consider how you could get similar information earlier on in the project or on an ongoing basis.`
    );
  }

  return ret;
};
