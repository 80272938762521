import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A3) {
    ret.push(
      `Good choice of energizer! It will certainly help you stimulate your participants before the ideation session.`
    );
  }

  if (A1 && A2 === false) {
    ret.push(
      `While you did submit an energizer as stated, you did not describe it very well. We asked you to do this for your own planning purposes, so make sure you know what you will be doing during the workshop :) `
    );
  }

  if (A2 && A3) {
    ret.push(
      `Great choice of energizer! It seems to be a good fit for your participants and will help you motivate them and make sure that they are in a very active and creative state.`
    );
  }

  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `Are you sure this energizer is the best choice for your ideation session? It seems to us that it is not very stimulating. We advise you to reconsider or  adapt your energizer to make sure it will put your participants in the right mindset.`
    );
  }

  return ret;
};
