import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`);
  }

  if (Z1) {
    ret.push(
      `Well done.
You have defined different types of dots and made it very clear how many of each your participants will get.
This will help you and your team assess your ideas along several dimensions instead of just one. This is important because, otherwise, you may discard ideas now, just because they failed this one criterion you used. `
    );
  }

  return ret;
};
