import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Well done! Your invitation states why the sprint has to happen, why now, and why the team is the right team to tackle the challenge. This empathizes the relevance of the sprint, creates a sense of urgency, and motivates and inspires the team early on. Well done!`
    );
  }

  return ret;
};
