import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Well done! Thank you for stating when you want to apply your decision criteria. Having a clear deadline will stop you from wasting time and continue or kill your project early on. Based on decision criteria you stated, you can decide to continue, pivot, or kill the project. Some additional examples could be: Is your problem worth solving (regarding the need and your companies strategy)? Is your solution clearly solving the problem you identified previously? Did the business model testing satisfy the success criteria you set in advance?`
    );
  }

  return ret;
};
