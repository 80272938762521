import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Excellent work! You have defined and justified several actions you and your team can take to address what you learned in this business model health check. A health check is a starting point to understanding what your business can do to improve its model, and you explain why the actions you stated will be right for your business.`,
    )
  }

  return ret;
};
