import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Nice work! You have included a Business Model pattern from the Business Model Navigator that could potentially work for your business.`
    );
  }

  if (A2) {
    ret.push(
      `Furthermore, you have clearly explained why you chose this pattern from the Business Model Navigator.`
    );
  }

  if (A4) {
    ret.push(
      `You have included more than one Business Model pattern in your answer. It's great you identified multiple that worked, but for this exercise you need to focus on just one that aligns the most closely to your value proposition. Try to narrow it down to one and explain why it is the best one for your business and fits the value proposition you wrote.`
    );
  }

  if (A5) {
    ret.push(
      `However, are you sure that this business model is the correct one for your idea?
It seems to us that you did not fully understand the business model you stated above.
We ask you to take a better look at the business model navigator and go over this business model again, as well as look if there isn't another business model you could use to leverage your value proposition.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A1 === false) {
    ret.push(
      `You didn't include a pattern from the Business Model Navigator in your answer. The Business Model Navigator is a valuable tool to get inspiration for creating your own business model. Go through the Navigator website and choose one pattern that makes sense for your business and fits the value proposition you wrote.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A2 === false) {
    ret.push(
      `However, you did not include an explanation as to why you chose this pattern from the Business Model navigator over all others. You should express why the pattern you chose suits your business and aligns with the value proposition you wrote in order to verify that the pattern is relevant. Doing so will help assess if this is really the best business model for your business.`
    );
  }

  return ret;
};
