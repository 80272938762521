import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for sharing your identified opportunities.
Your identified opportunities are promising, demonstrating proactive curiosity and the design thinking mindset. But identifying opportunities is just step one. Action is key. 

Start with a simple discovery process—conduct interviews, validate your assumptions, and test your idea. $
Remember, design thinking is iterative: learn, adjust, refine. This journey paves the way for potent ideas to transform into beneficial solutions for your organization. 

Good luck on your innovative journey ahead!`);
  }

  return ret;
};
