import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`);
  }

  if (Z1) {
    ret.push(
      `Good job! Your conclusion section includes all the relevant parts of a good interview guideline.
Just don't forget to keep paying attention even after the interview is "officially" done, as it is possible that the participants might feel more at ease and remember one or two more things.`
    );
  }

  if (Z0 || Z1) {
    ret.push(
      `
Before you continue, we would like to give you some final tips on how to conduct your interviews:
- Try to write down some relevant quotes from your interviews. These can be then directly used as "learning notes" in exercise 4.
- Don't be afraid of pauses. If your interviewee does not answer your question right away, give him/her some time. Avoid explaining what you meant right away or worse, giving suggestions of what they could say.
- Don't worry if you don't get to ask all your questions. Make sure to ask your most important ones and feel free to explore new directions you had not foreseen.
- Have fun! Being charismatic and energetic is a great way to ensure your interviews flow naturally.

Good luck!`
    );
  }

  return ret;
};
