import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`I reviewed both your discovery persona process as well as the final personas shared in the discovery report.
Great job defining your personas!
They are clearly based on your research, and include details on the pains, needs, and characteristics you identified. By adding more details, you made them more relatable for you as a researcher.
The user persona you identifies and are using for further research is the strongest, well done!`);
  }

  return ret;
};
