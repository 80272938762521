import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, F1, F2, F3 } = getSelectedOptionsFlags(selectedOpts);

  if (F1) {
    ret.push(`Thank you for uploading this additional persona`);
  }

  return ret;
};
