import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Perfect!
You have submitted a realsitic data source for all 5 KPIs. This will allow you and your team to track these KPI's as you progress and make adjustments to your project if necessary.`
    );
  }

  return ret;
};
