import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Good job in implementing a starting page for the survey. `);
  }

  if (A2) {
    ret.push(
      `The participants are aligned about your project and it gives them an overview of the survey.`
    );
  }

  if (A3) {
    ret.push(
      `Good job on adding this section to the survey. This will help you avoid any legalities and also keeps the process transparent with the participants.`
    );
  }

  if (A4) {
    ret.push(
      `Good job at keeping all the questions closed ended. This will make it easy for you to analyze and quantify the data. Number of people that will fill out the survey will also be higher.`
    );
  }

  if (A5) {
    ret.push(
      `Good job in framing the questions but it looks like some of the questions are open ended. This will make it harder for you to analyze and quantify the data. We recommend that all the questions must be closed ended for a survey. `
    );
  }

  if (A6) {
    ret.push(
      `Congratulations on framing a straight forward survey. You have avoided the use of jargon, advanced concepts, or abbreviations  and made it easy for the participants to understand. `
    );
  }

  if (A7) {
    ret.push(
      `Good attempt in framing the questions. But there are some questions that use  jargon, advanced concepts, or abbreviations  that may not be easy for the participants to understand. Try to reformulate the questions to keep it simple and straightforward`
    );
  }

  if (A8) {
    ret.push(
      `Great job in keeping the questions unbiased and non leading. This will give you true quantitative data which can be used. `
    );
  }

  if (A9) {
    ret.push(
      `Some of your questions seem to be leading and creates a bias amongst the participants. Please rework on those questions, to gather true quantitative data. `
    );
  }

  if (A10) {
    ret.push(
      `Good job! Your survey is short and precise. This will make more participants fill the survey. `
    );
  }

  if (A11) {
    ret.push(
      `Your survey seems to be going a bit longer. A good rule to remember is 30 secs per question for closed ended questions. To keep the survey not longer than 7-8 minutes, total of 15 questions or less should be suitable. `
    );
  }

  if (A12) {
    ret.push(`Good job in implementing an ending page for the survey. `);
  }

  if (A13) {
    ret.push(
      `It is always a nice gesture to include a thank you note for the participants, which you have included. `
    );
  }
  return ret;
};
