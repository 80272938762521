import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for including the inspiration for your revised business model canvas. You used valuable sources to reconfigure your business model. Often inspiration is key to shedding new light on your existing business model and generating new ideas for business model innovation.`
    );
  }

  if (A2) {
    ret.push(
      `You did not include the inspiration you used for your revised business model. Even if it was not a specific real-world business, please include what sources or ideas inspired the changes you made.`
    );
  }

  if (A3) {
    ret.push(
      `You have provided a clear explanation as to why you used the inspiration that you did. You were thoughtful about how it was relevant to different elements in your business model canvas such as user needs, the market environment, and the creation and capture of value. It's important that the inspiration you draw on connects to some parts of your business model while still pushing it to change and improve in ways that are outside of the box.`
    );
  }

  if (A4) {
    ret.push(
      `You did not provide a clear explanation as to why you used the inspiration that you did. Consider explaining how it is relevant to different elements in your business model canvas such as user needs, the market environment, and the creation and capture of value. It's important that the inspiration you draw on connects to some parts of your business model while still pushing it to change and improve in ways that are outside of the box.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A3 === false && A4 === false) {
    ret.push(
      `You did not provide a clear explanation as to why you used the inspiration that you did. Consider explaining how it is relevant to different elements in your business model canvas such as user needs, the market environment, and the creation and capture of value. It's important that the inspiration you draw on connects to some parts of your business model while still pushing it to change and improve in ways that are outside of the box.`
    );
  }

  return ret;
};
