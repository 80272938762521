import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  /*
     {id = "#2", text = "----------------------- Pick one ---------------------------", points = 0},
    {id = "A1", text = "Most metrics ARE connected to the overall goals and value creation of the project", points = 2},
    {id = "A2", text = "Most metrics are NOT connected to the overall goals and value creation of  the project", points = 1},
    {id = "#3", text = "----------------------- Pick one ---------------------------", points = 0},
    {id = "A3", text = "More than 5 metrics submitted", points = 2.5},
    {id = "A4", text = "5 metrics submitted", points = 2},
    {id = "A5", text = "3 or 4 metrics submitted", points = 1.5},
    {id = "A6", text = "1 or 2 metrics submitted", points = 0.75},
    {id = "#4", text = "----------------------- Pick one ---------------------------", points = 0},
    {id = "A7", text = "Metrics are well thought", points = 3},
    {id = "A8", text = "Metrics are okay", points = 2},
    {id = "A9", text = "Metrics are not relevant to the project success or to measure the progress", points = 1},
  */

  if (A1) {
    ret.push(
      `Well done! The metrics you listed are good measures of the goals of your overall project and potential value generators. Coming up with relevant metrics is difficult, so we appreciate the thought you put into these metrics.`
    );
  }

  if (A10) {
    ret.push(
      `Nice work. You came up with several metrics that could be used in your project to measure it's progress. However, we recommend you spend more time thinking about how you could tailor these metrics more specifically to your project and its phase. There may be a specific activity or outcome for your project phase such as testing a new product development process that requires metrics more specific than what you have listed. `
    );
  }

  if (A2) {
    ret.push(
      `Unfortunately, most of the metrics you listed are not closely enough related to your overall project goals. We advise you to look back at the overall goals of your project and consider activities or outcomes throughout the project could lead to that goal. Go back and revise your metrics to ensure they capture those activities and outcomes more precisely.`
    );
  }

  if (A3) {
    ret.push(
      `You came up with more metrics than we asked for, which means you plenty of ideas about what to measure in your project! Keep in mind, too many metrics can add noise to how you measure success. We recommend focusing on the metrics that you feel really represent successful outcomes of your project. As the saying goes, measure what matters.`
    );
  }

  if (A4) {
    ret.push(
      `Thank you for submitting five metrics to measure success throughout your project. This is a good amount of metrics to start with measuring and determine how well they work. Too many metrics could mean you're measuring unimportant things and too few means you are not measuring enough. `
    );
  }

  if (A5) {
    ret.push(
      `You submitted less than five metrics to monitor your project success. We ask you to take the time to think of a few more metrics you can measure throughout your project.`
    );
  }

  if (A6) {
    ret.push(
      `You submitted too few metrics to monitor your project success. We ask you to take the time to think of a few more metrics you can measure throughout your project.`
    );
  }

  if (A9) {
    ret.push(
      `Some of your metrics did were not relevant to the project success or were not measuring the progress of the project. Even if they might be actionable in another setting, this provides very little value to your project. We advise you to look back at the overall goals of your project and consider activities or outcomes throughout the project could lead to that goal. Go back and revise your metrics to ensure they capture those activities and outcomes more precisely.`
    );
  }

  return ret;
};
