import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for uploading your adapted user journey assuming that your first idea was implemented.`
    );
  }

  if (!Z0 && A1 === false) {
    ret.push(`You did not submit your adapted user journey`);
  }

  return ret;
};
