import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8, A9, A13, A14, A15, A16, A17, A18, A19 } =
    getSelectedOptionsFlags(selectedOpts);

  /* OLd version
  {id = "#3", text = "----- ----- ----- ----- Pick one below----- ----- ----- ----- ----- ", points = 0},
    {id = "A4", text = "More than one Pattern chosen ", points = 0},
    {id = "A6", text = "Only one Pattern chosen ", points = 0},
    {id = "#4", text = "----- ----- ----- ----- Pick freely below----- ----- ----- ----- ----- ", points = 0},
    {id = "A1", text = "Pattern(s) from [Business Model Navigator](https://businessmodelnavigator.com/explore) is stated --> They have 60 patterns", points = 1},
    {id = "A2", text = "Rationale why this/these pattern were chosen is included", points = 2},
    {id = "A3", text = "Pattern(s) is/are well described/explained", points = 2},
    {id = "A5", text = "Participant misunderstood chosen Pattern", points = 0},
    {id = "A7", text = "Participant explained how to combine both patterns", points = 0},


    New version
    {id = "#3", text = "----- ----- ----- ----- Pick one below----- ----- ----- ----- ----- ", points = 0},
    {id = "A1", text = "Only one Pattern chosen", points = 0.5},
    {id = "A2", text = "Two Patterns chosen", points = 1},
    {id = "A3", text = "Three Patterns chosen", points = 1.5},
    {id = "A4", text = "Four or more Patterns chosen", points = 2},
    {id = "#7", text = "----- ----- ----- ----- Why is this the right pattern?----- ----- ----- ----- ----- ", points = 0},
    {id = "A5", text = "Very good rationale for these choices of patterns", points = 2},
    {id = "A6", text = "Good rationale for these choices of patterns", points = 1.5},
    {id = "A7", text = "Acceptable rationale for these choices of patterns", points = 1},
    {id = "A8", text = "Poor rationale for these choices of patterns", points = 0.5},
    {id = "A9", text = "Very poor rationale for these choices of patterns", points = 0},
    {id = "#6", text = "----- ----- ----- ----- How would you combine the patterns? ----- ----- ----- ----- ----- ", points = 0},
    {id = "A10", text = "Very good description of how to combine patterns", points = 2},
    {id = "A11", text = "Good description of how to combine patterns", points = 1.5},
    {id = "A12", text = "Acceptable description of how to combine patterns", points = 1},
    {id = "A13", text = "Poor description of how to combine patterns", points = 0.5},
    {id = "A14", text = "Very poor description of how to combine patterns", points = 0},
    {id = "#4", text = "----- ----- ----- ----- Pick freely below to generate critical feedback----- ----- ----- ----- ----- ", points = 0},
    {id = "A15", text = "Pattern(s) are not from [Business Model Navigator](https://businessmodelnavigator.com/explore)", points = -0.5},
    {id = "A16", text = "Participant misunderstood chosen Pattern", points = -0.5},
    {id = "A17", text = "Participant did not explain how/why to combine both patterns", points = -0.5},
    {id = "A18", text = "Not clear if patterns would fit into one Business Model ", points = -0.5},
    {id = "A19", text = "Patterns don't fit into one Business Model ", points = -0.75},
  */

  if (!Z0 && !Z2 && !Z2 && !A1 && !A15 && !A16 && !A17 && !A18 && !A19) {
    ret.push(
      `Well done! You have chosen relevant patterns, that can be combined into one valuable and viable business model.
More importantly, you have given good arguments as to why these patterns were picked. This shows your team's good understanding of these patterns and the challenge you are working on.`
    );
  }

  if (A1) {
    ret.push(
      `You have chosen a relevant pattern that can be be used to build a viable business model.
However, you have only shared one pattern and not more than one as asked.
Please make sure to go back to the Business Model Navigator and look for additional pattern you could incorporate in your business model before proceeding.`
    );
  }

  if (A3 || A4) {
    ret.push(`Good job finding more than just 2 business model patterns!
Even if you decide not to include all of them into your business model in the end, evaluating many patterns can help you build better business models in the future.`);
  }

  if (A8 || A9) {
    ret.push(`Why did you choose these business model patterns? Your rational was not clear enough.
Even if this is obvious to you, writing down why you think these patterns are the best ones to leverage key elements of your idea will help you assess if this is really the case.`);
  }

  if (A13 || A14) {
    ret.push(`How will you combine these patterns? Your explanation was not clear.
Please re-evaluate if these patterns are a good mix and if so, you should be able to explain how in a simple and convincing matter.`);
  }

  if (A15 && A1) {
    ret.push(`Furthermore, the pattern you selected is not from the business model navigator.
This is not necessarily a bad thing, but we ask you to name them properly in the future.
This will not only make it easier for you to remember them in the future, but it also helps us assess your answer better.`);
  }

  if (A15 && !A1) {
    ret.push(`Some of your Business Model Patterns don't seem to be from the Business Model Navigator.
This is not necessarily a bad thing, but we ask you to name them properly in the future.
This will not only make it easier for you to remember them in the future, but it also helps us assess your answer better.`);
  }

  if (A16 && A1) {
    ret.push(
      `It seems like you did not understand the pattern.
Please make sure to go over it once more and reach out to us if you need additional help.`
    );
  }

  if (A16 && !A1) {
    ret.push(
      `It seems like you did not understand some of the patterns you shared.
Please make sure to go over them once more and reach out to us if you need additional help.`
    );
  }

  if (A17 && !A1) {
    ret.push(
      `You did not explain how you will combine the patterns you've selected into one business model.
Please give this a thought before proceeding and if necessary, do not hesitate to reach out to us for help.`
    );
  }

  if ((A18 || A19) && !A1) {
    ret.push(
      `Are you sure that these pattern can be combined in on business model?
It seems to us that, as you have put it, this may not be the case.
Therefore, please give this a thought before continuing.`
    );
  }

  /* Old feedback
  if (A1 && A6 && A2) {
    ret.push(
      `Nice work! You have included a Business Model pattern from the Business Model Navigator and have clearly explained why you chose this pattern and how it leverages your business to create as much value as possible.
However, choosing the right business model is a hard task and is not something that can be decided once and for all. It is very likely that as you develop your business more and more, that you identify weaknesses in your current business model.
As such, it is very important to keep your mind open to future changes in this business model. We highly advise you to take a look at the BM navigator every now and then to refresh your knowledge on this topic!`
    );
  }

  if (A1 && A4 && A2) {
    ret.push(
      `Nice work! You have included more than one Business Model patterns from the Business Model Navigator and have clearly explained why you chose these pattern and how they leverage your business to create as much value as possible.
However, choosing the right business model is a hard task and is not something that can be decided once and for all. It is very likely that as you develop your business more and more, that you identify weaknesses in your current business models.
As such, it is very important to keep your mind open to future changes in your business models. We highly advise you to take a look at the BM navigator every now and then to refresh your knowledge on this topic!`
    );
  }

  if (A1 && A6 && A2 === false) {
    ret.push(
      `Nice work! You have included a possible Business Model pattern from the Business Model Navigator to develop your idea.
However, you have not clearly explained why you chose this pattern and how it leverages your business to create as much value as possible.
Furthermore, we would like you to keep in mind that choosing the right business model is a hard task and is not something that can be decided once and for all. It is very likely that as you develop your business more and more, that you identify weaknesses in your current business model.
As such, it is very important to keep your mind open to future changes in this business model. We highly advise you to take a look at the BM navigator every now and then to refresh your knowledge on this topic!`
    );
  }

  if (A1 && A4 && A2 === false) {
    ret.push(
      `Nice work! You have included more than one Business Model patterns from the Business Model Navigator that have the potential to move your idea forward.
However, you have not clearly explained why you chose these pattern and how they leverage your business to create as much value as possible.
Furthermore, we would like you to keep in mind that choosing the right business model is a hard task and is not something that can be decided once and for all. It is very likely that as you develop your business more and more, that you identify weaknesses in your current business models.
As such, it is very important to keep your mind open to future changes in your business models. We highly advise you to take a look at the BM navigator every now and then to refresh your knowledge on this topic!`
    );
  }

  if (A5) {
    ret.push(
      `However, are you sure that this business model is the correct one for your idea?
It seems to us that you did not fully understand the business model you stated above.
We ask you to take a better look at the business model navigator and go over this business model again, as well as look if there isn't another business model you could use to leverage your value proposition.`
    );
  }

  if (A5) {
    ret.push(
      `However, are you sure that this business model is the correct one for your idea?
It seems to us that you did not fully understand the business model you stated above.
We ask you to take a better look at the business model navigator and go over this business model again, as well as look if there isn't another business model you could use to leverage your value proposition.`
    );
  }

  if (A7 && A6) {
    ret.push(
      `Lastly, you also did a good job explaining how to combine all the patterns you have stated to extract the most of each of them.
Good job! `
    );
  }

  if (A7 === false && A4) {
    ret.push(
      `One last thing we would like to mention however, is that you should have explained better how you are planning to combine all the patterns you have stated to extract the most of each of them.
 It is essential that there is a synergy between all the elements of all the business model patterns you are planning to employ.
 Just because two business models fit your business individually, it does not mean that a combination of both will fit it as well. Therefore, we ask you to reflect on the patterns you have stated and decide if they are truly a good combination for your specific case.`
    );
  }

  */

  return ret;
};
