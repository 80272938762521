import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Great job on creating a low fidelity prototype for your idea! This approach allows for rapid experimentation and iteration, enabling you to quickly test and validate your concept. Your focus on creating a simple and easily understandable prototype demonstrates a strong understanding of the design process and the importance of visual communication. This approach will allow you to gather valuable feedback from users early in the development process, helping to ensure that the final solution meets their needs.`
    );
  }

  return ret;
};
