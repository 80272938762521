import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`
    );
  }

  if (Z1) {
    ret.push(`Great job identifying two potential improvements! Both ideas stated are clear, detailed, and seem to add a lot of value! `
    );
  }

  if (Z2) {
    ret.push(`Amazing job! Your submission went way beyond the scope of what we expected for this exercise. All of your improvements are very well explained and would certainly improve your interviewing skills even further. Well done!`
    );
  }

  return ret;
};