import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3 } = getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "#7", text = "------------------------ Pick one ------------------------", points = 0},
    {id = "A0", text = "No submission (Select this and only this if there is no answer)", points = 0},
    {id = "A1", text = "Included a good suggestion for reducing cost", points = 3},
    {id = "A2", text = "Included a poor or unclear suggestion for reducing cost", points = 1},
    {id = "A3", text = "Did not include suggestion for reducing cost", points = 0},
  */

if (A0) {
ret.push(
`You have not submitted any answer to this question.
Still, we would like to ask you to at least take a few minutes to reflect on this.`
);
}

if (A1) {
ret.push(
`Good job! Your suggestion for how to reduce the cost of your project is clear and makes sense. 
Even when you've aligned cost and value, roadblocks or organizational changes can come up that increase your cost or reduce your budget. It's good to already start thinking about how you can reduce costs while still delivering value with your project early on.`
);
}

if (A2) {
ret.push(
`Thank you for including a suggestion for how you might reduce the cost of your project. 
However, it is not clear to us how this proposed option could achieve that. This is important because, even when you've aligned cost and value, roadblocks or organizational changes can come up that increase your cost or reduce your budget. It's good to already start thinking about how you can reduce costs while still delivering value with your project early on.
In the future, we ask you to write out your answer more clearly so that we can evaluate your answers better and give you a more effective feedback in turn.`
);
}

if (A3) {
ret.push(
`You  did not include a suggestion of how to reduce your project cost if necessary. 
Even when you've aligned cost and value, roadblocks or organizational changes can come up that increase your cost or reduce your budget. We suggest you spend some time thinking about how you can reduce costs while still delivering value with your project early on.`
);
}


  return ret;
};
