import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, C0, C1, C2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (C0) {
    ret.push(`No feedback submitted`);
  }

  if (C1 && C2 === false) {
    ret.push(`Thank you for submitting this feedback.
However, we believe that you should have been a little bit more detailed to ensure that no information is lost.`);
  }

  if (C2) {
    ret.push(`Thank you for submitting the feedback received from your first user.
You have stated it very clearly.`);
  }

  return ret;
};
