import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B1, B2, B3, B4, B5, B6, B7, B8, B11 } =
    getSelectedOptionsFlags(selectedOpts);

  if (B3) {
    ret.push(`Thank you for stating the implications you expect this idea to have on your persona.
Still, you did not make it clear how this idea would affect your other personas.
In the future please make sure to evaluate how the ideas you keep might influence all your personas (and ideally key stakeholders as well).`);
  }

  if (B4) {
    ret.push(`Thank you for stating the implications you expect this idea to have on these two personas.
Still, you should have stated how this idea would affect at least three of the personas you have stated.
In the future please make sure to evaluate how the ideas you keep might influence all of your personas (and ideally key stakeholders as well). `);
  }

  if (B5) {
    ret.push(`Thank you for stating the implications you expect this idea to have on your personas.
Evaluating how the ideas you keep might influence all of your personas (and ideally key stakeholders as well) will help you realize if this idea is really worth pursuing and might show you some issues that will need to be addressed. `);
  }

  if (B1) {
    ret.push(`You stated how this idea would affect only one type of user, instead of using the specific personas you have craeted earlier.
Furthermore, you only submitted the implications you expect this idea to have on one user.
We strongly advise you to use your personas in the future as they represent dfferent customer segments, their behaviours and needs. Therefore, using them will help you evaluate this idea on a broader range of behaviour and needs (compared to a general user that is less detailed).
In the future please make sure to evaluate how the ideas you keep might influence all of your personas (and ideally key stakeholders as well).`);
  }

  if (B2) {
    ret.push(`While you did state how this idea would affect dfferent types of users, you did not use the specific personas you have craeted earlier.
We strongly advise you to use your personas in the future as they represent dfferent customer segments, their behaviours and needs. Therefore, using them will help you evaluate this idea on a broader range of behaviour and needs (compared to a general user that is less detailed).
In the future please make sure to evaluate how the ideas you keep might influence all of your personas (and ideally key stakeholders as well).`);
  }

  if (B6) {
    ret.push(`Your implications are not detailed enough.
It is important to think carefully about how this idea might affect the experience of each of your personas.
Doing this will allow you to extract more information and increase the chance that this idea adds real value to your business.`);
  }

  if (B7) {
    ret.push(`Your implications are not detailed enough.
It is important to think carefully about how this idea might affect the experience of each of your personas.
Doing this will allow you to extract more information and increase the chance that this idea adds real value to your business.`);
  }

  if (B8) {
    ret.push(`Your implications are quite detailed. Good job!
It is important to think carefully about how this idea might affect the experience of each of your personas.
Doing this will allow you to extract more information and increase the chance that this idea adds real value to your business.`);
  }

  if (B11) {
    ret.push(`Are you sure that the implications you have stated are a direct result of implementing this idea?
Based on what we read in your submission, this relationship is not clear.
Please reflect if this idea will really have the implications stated above.
In the future, please make sure to explain why a certain relationship is true. This will allow us to give you more effective feedback.`);
  }

  return ret;
};
