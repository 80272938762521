import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Amazing! You have identified multiple ways to generate revenue and all the ideas are innovative, different and show a good understanding of how your project is going to run. `
    );
  }

  if (Z2) {
    ret.push(
      `Exceptional! You have identified multiple ways to generate revenue, explained it in detail and all the ideas are innovative, different and show a deep understanding of how your project is going to run. `
    );
  }

  return ret;
};
