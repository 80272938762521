import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts, scoredPoints) => {
  const ret = [];
  const { Z0, Z1, D1, D2, D3, C2, C3 } = getSelectedOptionsFlags(selectedOpts);

  if (D1) {
    ret.push(`Once more, thank you for this additional file.`);
  }

  if (D2 && !C2) {
    ret.push(`Thank you for submitting this zoomed in picture of your learning notes.
This made it much easier for us to go through your learnings!`);
  }

  if (D2 && C2) {
    ret.push(`Thanks for sharing this additional zoomed in picture of your learning notes.
Again, this made it much easier for us to go through your learnings!`);
  }

  if (D3 && C3) {
    ret.push(
      `Thank you for sharing even more learning notes here. As mentioned above, we have already included these in our feedback in part b.`
    );
  }

  if (D3 && !C3) {
    ret.push(
      `Thank you for sharing more learning notes here. We have already included these in our feedback above.`
    );
  }

  return ret;
};
