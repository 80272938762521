import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3 } = getSelectedOptionsFlags(selectedOpts);

  if (A2 && A3 === false) {
    ret.push(
      `You have made it very clear that the user should speak their mind and not hesitate to give constructive feedback.

However, be careful not to go into too many details in your introduction. The goal here is to briefly state what it is about without teaching the participants how to use the prototype or telling them what to do. This will change his experience with the prototype and take away valuable learnings that you could have had.
      `
    );
  }

  if (A2 === false && A3) {
    ret.push(
      `You did not teach the participant exactly how they should use the prototype. This is very important as you want them to test it out by themselves. If you had shared too much information about the prototype, you could have made it harder for you to learn from his experience.

However, you did not make it very clear to the participant that he should not hesitate to speak his mind and give constructive feedback. This might seem unnecessary, but reaffirming this at the beginning of the session might make him more comfortable to do so.
      `
    );
  }

  if (A2 && A3) {
    ret.push(
      `You have made it very clear that the user should speak their mind and not hesitate to give constructive feedback.
Furthermore, you did not teach the participant exactly how they should use the prototype. This is very important as you want them to test it out by themselves. If you had shared too much information about the prototype, you could have made it harder for you to learn from his experience.
      `
    );
  }

  if (A2 === false && A3 === false && !Z0 && !Z1) {
    ret.push(
      `You did not make it very clear to the participant that he should not hesitate to speak his mind and give constructive feedback. This might seem unnecessary, but reaffirming this at the beginning of the session might make him more comfortable to do so.

Furthermore, you should be careful not to go into too many details in your introduction. The goal here is to briefly state what it is about without teaching the participants how to use the prototype or telling them what to do. This will change his experience with the prototype and take away valuable learnings that you could have had.`
    );
  }

  return ret;
};
