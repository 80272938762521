import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2 && A3 && A4 && A5) {
    ret.push(
      `You have submitted a good amount of questions, and all of them are open-ended, do not suggest or give hints about how to use the prototype and relate to your participant's life and work. These questions will certainly help you understand your user better without biasing the testing procedure.`
    );
  }

  if (A1 && A3 && (!A4 || !A1 || !A5)) {
    ret.push(
      `Your questions are open-ended and do not bias your test by explaining too much how to use the prototype or what you expect from your user.`
    );
  }

  if (A1 && !A3 && (!A4 || !A1 || !A5)) {
    ret.push(
      `All of your questions are open-ended.
This will make it easier for your user to steer the conversation and will help you to understand him and better.`
    );
  }

  if (!A1 && A3 && (!A4 || !A1 || !A5)) {
    ret.push(
      `Your questions do not bias your test by giving your participants too much information on how they should use the prototype or what to expect.`
    );
  }

  if (!A1 && !Z0 && !Z1) {
    ret.push(
      `Some of your questions are closed-ended.
Like in your interviewing process, asking open questions will stimulate the conversation and allow your participants to move the conversation towards topics and themes that interest them. This is why, whenever you are exploring and trying to discover insights or, like in this case, trying to get to know someone better, it is a good idea to avoid closed questions.`
    );
  }

  if (!A3 && !Z0 && !Z1) {
    ret.push(
      `Some of your questions might suggest your user how to use your prototype.
This, in turn, can bias your test since it might help solve some issues that you didn't realize yet. For example, imagine if your prototype is not easy to use. If you tell every participant that tests it exactly how to use it, you might not get the feedback that it is too complicated. This is why it is important to let the participants explore your prototype.`
    );
  }

  if (!A5 && !Z0 && !Z1) {
    ret.push(
      `You did not prepare enough questions.
Even if you think that you already have enough and won't have time to ask all of them, it is helpful to have several different questions ready.
This will allow you to go in any direction the user might think of and will let your test be more reproducible since you did not have to come up with questions you did not think of before.`
    );
  }

  return ret;
};
