import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for stating and explaining the phase of your project. Well done! We like that you referenced specific activities in your explanation that align with the project phase. Understanding the activities of each project phase will help you not only plan and manage your innovation project, but also come up with appropriate measures given the phase.`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for stating and explaining the phase of your project. Given your explanation, the project phase you chose makes sense. Good work. We recommend you consider the activities of each project phase to help you not only plan and manage your innovation project, but also come up with appropriate measures given the phase.`
    );
  }

  if (A3) {
    ret.push(
      `Thank you for stating the phase of your project! However, you did not include an explanation of why you chose that phase. We recommend you look back at the module content to consider the activities of each project phase. This help you not only plan and manage your innovation project, but also come up with appropriate measures given the phase.`
    );
  }

  if (A4) {
    ret.push(`No project phase submitted.`);
  }

  return ret;
};
