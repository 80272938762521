import { getWithRetry, postWithRetry } from "@sparkademy/app-common/utils/httpRetry";
import { User } from "@sparkademy/app-common/models/user";
import {
  GradingFeedbackMap,
  GradingScoresMap,
  GradingCriteriaAppliedMap,
  ExerciseTeam,
  Assignment,
} from "@sparkademy/app-common/models/assignment";
import { API_URL } from "@sparkademy/app-common/constants";

export class GradingService {
  static async lockForGrading(assignmentExerciseId: number, user: User) {
    return await postWithRetry(
      `${API_URL}/grading/${assignmentExerciseId}/lock?grader_id=${user.data.id}&token=${user.jwt}`
    );
  }

  static async saveGrading(
    assignmentExerciseId: number,
    feedback: GradingFeedbackMap,
    scores: GradingScoresMap,
    gradingStartAt: Date,
    criteriaApplied: GradingCriteriaAppliedMap,
    flag_comment: string,
    user: User
  ) {
    const payload = {
      feedback,
      scores,
      criteria_applied: criteriaApplied,
      grading_start: Math.round(gradingStartAt.getTime() / 1000),
      grader_id: user.data.id,
      flag_comment,
    };
    return await postWithRetry(
      `${API_URL}/grading/${assignmentExerciseId}/save?grader_id=${user.data.id}&token=${user.jwt}`,
      payload
    );
  }

  static exportUrl(cohortId: string, user: User): string {
    return `${API_URL}/grading/export/${cohortId}?token=${user.jwt}`;
  }

  static async getTeamsForUser(
    userId: string,
    moduleId: string,
    grader: User
  ): Promise<ExerciseTeam[]> {
    const resp = await getWithRetry(
      `${API_URL}/grading/teams/${moduleId}/${userId}?token=${grader.jwt}`
    );
    const json = (await resp.json()) as ExerciseTeam[];
    return json;
  }

  static async getModuleAssignment(
    userId: string,
    moduleId: string,
    retry: boolean | null = false,
    grader: User
  ): Promise<Assignment> {
    let url = `${API_URL}/grading/assignment/${moduleId}/${userId}?token=${grader.jwt}`;
    if (retry !== null) {
      url += `&retry=${retry ? 1 : 0}`;
    }

    const resp = await getWithRetry(url);

    const assignment = (await resp.json()) as Assignment;
    setQuestionsGradingInitialPoints(assignment);
    return assignment;
  }
}

function setQuestionsGradingInitialPoints(assignment: Assignment) {
  // add a virtual grading criteria set for initial points
  // this is a grading strategy to start with full points and deduct points for mistakes using other criteria sets
  // we don't show the initial points criteria set in the UI, but it's used to calculate the total points

  const exercises = assignment.exercises;
  exercises.forEach(exercise => {
    exercise.sections.forEach(section => {
      section.questions.forEach(question => {
        if (question.grading_initial_points > 0) {
          question.grading_criteria_sets.push({
            title: "Initial points",
            type: "single",
            options: [
              // use the question id as the grading id for initial points
              {
                grading_id: question.pk.toString(),
                points: question.grading_initial_points,
                text: "",
              },
            ],
          });
        }
      });
    });
  });
}

