import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const {
    Z0,
    Z1,
    Z2,
    Z3,
    A1,
    A2,
    A3,
    A4,
    A5,
    A6,
    A7,
    A8,
    A9,
    A10,
    A11,
    A12,
    A13,
    A14,
    A15,
    A16,
    A17,
    A18,
    A19,
    A25,
    A26,
  } = getSelectedOptionsFlags(selectedOpts);

  /*
    I removed the good stuff to simplify the feedback.

  if (A1 && A2 && A3 && A5 && A6 && A7 && A9 && A10 && A11) {
    ret.push(
      `Very well done!
Your agenda includes all the relevant and necessary information we would expect to find.
This will not only ensure that your participants are motivated and aligned, but it will also make it easier for you to conduct your ideation session later.
Great job!`
    );
  }

  if (A11) {
    ret.push(
      `Good job! You've included a section to talk about your desired outcome for all of your activities.
This is very important as it will help you align all participants and give them clarity about what exactly is expected from them.
This, in turn, can also boost their motivation since they know what to do and why it is relevant.`
    );
  }

  if (A12) {
    ret.push(
      `You've included a section to talk about your desired outcome for most of your activities.
However, it would have bene even better if you had included that for all activities.
This is very important as it will help you align all participants and give them clarity about what exactly is expected from them.
This, in turn, can also boost their motivation since they know what to do and why it is relevant.`
    );
  }

  if (A5) {
    ret.push(
      `You've remembered to include, among other things, a conclusion section to your agenda.
It is important to debrief your participants at the end of the session, as this might give you additional insights and help you learn and improve your hosting skills.`
    );
  }

  if (A3 && A4 === false) {
    ret.push(
      `You have included a good amount of breaks.
This is important as it will give your participants some time to rest and recharge every now and then. This, in turn, will make it easier to get their full attention during the brainstorming session.`
    );
  }
*/
  if (
    !Z0 &&
    !Z1 &&
    scoredPoints === 6 &&
    !(A1 && A2 && A5 && A6 && A9 && A10 && A3 && A7 && A11 && (A16 || A19))
  ) {
    ret.push(
      `Well done!
Your agenda includes all the relevant and necessary information we would expect to find.
This will not only ensure that your participants have a good overview of what is about to happen, but it will also make it easier for you to conduct your ideation session later.`
    );
  }

  if (
    !Z0 &&
    !Z1 &&
    scoredPoints < 6 &&
    scoredPoints > 5 &&
    !(A1 && A2 && A5 && A6 && A9 && A10 && A3 && A7 && A11 && (A16 || A19))
  ) {
    ret.push(
      `Thank you for you submission.
Your agenda includes most of the relevant aspects we expected to find. Good job.
However, There are some things that you could improve in your agenda. Therefore, please make sure so update your agenda according to the points stated below.`
    );
  }

  if (
    !Z0 &&
    !Z1 &&
    scoredPoints < 5.1 &&
    !(A1 && A2 && A5 && A6 && A9 && A10 && A3 && A7 && A11 && (A16 || A19))
  ) {
    ret.push(
      `Thank you for you submission.
Your agenda includes some of the relevant aspects we expected to find and we are happy to see that you have thought about your session.
However, there are some things that you could improve in your agenda. Therefore, please make sure so update your agenda according to the points stated below.`
    );
  }

  if (A13) {
    ret.push(
      `You did not include a section to talk about your desired outcome for all of your activities.
This is very important as it will help you align all participants and give them clarity about what exactly is expected from them.
So please ensure that all your activities have a desired outcome defined before you start your ideation session.`
    );
  }

  if (A3 === false && A4) {
    ret.push(
      `While you did include breaks in your agenda, you did not include enough of them.
It is important to give your participants enough time to rest and recharge every now and then. This, in turn, will make it easier to get their full attention during the brainstorming session. This is why we advise you to give your participants breaks of at least 10 minutes for every 90 minutes of work (ideally after the first 40 minutes).`
    );
  }

  if (A14) {
    ret.push(
      `You did not include a section to talk about your desired outcome for most of your activities.
This is very important as it will help you align all participants and give them clarity about what exactly is expected from them. This, in turn, can also boost their motivation since they know what to do and why it is relevant.  So please ensure that all your activities have a desired outcome defined before you start your ideation session.`
    );
  }

  if (A15) {
    ret.push(
      `You did not include a section to talk about your desired outcome for any of your activities.
This is very important as it will help you align all participants and give them clarity about what exactly is expected from them. This, in turn, can also boost their motivation since they know what to do and why it is relevant. So please ensure that all your activities have a desired outcome defined before you start your ideation session.`
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `There is no introduction included.
Whenever you are working with a group on something that may be new for them, it is always very important to start with a simple introduction where you explain what you will be going to do, what your goal is, and any other relevant information. Please make sure to include that before you start your ideation session.`
    );
  }

  if (!Z0 && !Z1 && A2 === false && A1 === false) {
    ret.push(
      `You did not reserve time to introduce your challenge, problem statement, persona and customer journey at the beginning of your session.
It is a good idea to reserve some time to review some of key insights and learnings from your user research at the beginning of your workshop.
Doing so will help align the expectations of your participants and ensure a better workflow throughout your brainstorming session.`
    );
  }

  if (!Z0 && !Z1 && A2 === false && A1) {
    ret.push(
      `You did not reserve time to introduce your challenge, problem statement, persona and customer journey at the beginning of your session (We know that you have included a general introduction, but we would still advise you to include time to introduce your challenge and key findings specifically).
It is a good idea to reserve some time to review some of key insights and learnings from your user research at the beginning of your workshop.
Doing so will help align the expectations of your participants and ensure a better workflow throughout your brainstorming session.`
    );
  }

  if (!Z0 && !Z1 && A3 === false && A4 === false) {
    ret.push(
      `You did not include breaks in your agenda.
It is important to include them since it will allow your participants to focus more during the work, as they know that a break will be coming soon. Furthermore, enough breaks will also give your participants some time to rest and recharge every now and then. This, in turn, will make it easier to get their full attention during the brainstorming session. Make sure to give your participants at least 10 minutes of breaks every 90 minutes of work.`
    );
  }

  if (!Z0 && !Z1 && A5 === false) {
    ret.push(
      `You did not include a conclusion section to your agenda.
It is important to debrief your participants at the end of the session, as this might give you additional insights and will also help you learn and improve your hosting skills.`
    );
  }

  if (!Z0 && !Z1 && A6 === false) {
    ret.push(
      `You did not include an energizer in your agenda.
Make sure to state which energizer(s) you will conduct with your participants in your agenda. Also include some information about what your desired outcome is from that activity.`
    );
  }

  if (!Z0 && !Z1 && A7 === false && A8 === false && !A26) {
    ret.push(
      `You haven't included any brainstorming method in your agenda.
The more brainstorming methods you include, the more ideas you will generate with your team. This is why we would advise you to pick at least two brainstorming methods.`
    );
  }

  if (!Z0 && !Z1 && A7 === false && A8) {
    ret.push(
      `You have only included one brainstorming method in your agenda.
The more brainstorming methods you include, the more ideas you will generate with your team. This is why we would advise you to pick at least two brainstorming methods for your ideation sessions in the future.`
    );
  }

  if (A18) {
    ret.push(
      `You have not made it clear which brainstorming methods you would like to run during your session.
In order to make the necessary preparations and feel ready to run your sessions you should define what brainstorming techniques you would like to do in which order.`
    );
  }

  if (A26) {
    ret.push(
      `You should separate the different brainstorming methods you are planning to use from your "Brainstorming" block.
Doing so will help you keep track of time and manage your workshop better. It also allows you to insert a brief break in between these different methods to ensure that your participants can tackle the problem with a fresh mindset once.
You will see that separating your brainstorming methods will give your participants a boost of creativity and motivation.`
    );
  }

  if (!Z0 && !Z1 && A9 === false) {
    ret.push(
      `You did not include an impact-effort analysis in your agenda.
The process of clustering and classifying ideas is essential for your ideation session and should always be included in it. The impact-effort analysis is a great team to provide structure to your clustering, but there are also alternative methods you can apply.`
    );
  }

  if (!Z0 && !Z1 && A10 === false) {
    ret.push(
      `You did not include the dot-voting method in your agenda.
The whole purpose of an ideation session is for your team to develop some new ideas and solutions to tackle a relevant problem you've identified earlier. Since you will generate many ideas in your session, it is important to include some sort of convergent process to identify the most promising and interesting ideas in the end. It does not necessarily have to be a dot-voting method, but please make sure to include something to help you reduce the number of ideas you will analyze further.`
    );
  }

  if (A17) {
    ret.push(
      `Also, we noticed how you are planning to do your constrained brainstorm before the silent brainstorm.
This is not a problem, but we advise you to revert the order.
Usually, silent brainstorms are a great starter as they allow your participants to write down all the obvious ideas (as well as putting them in the right mindset). Then, when he next brainstorming methods start, they can focus on new ideas (or build upon some of the ones they thought before).`
    );
  }

  if (A25) {
    ret.push(
      `You did not not include a start and end time for each activity (e.g., 9h00 - 9h15: "Activity 1").
This may seem unnecessary, but having these times clearly defined will make it much easier for you to manage the time end sure that you are on track. Otherwise you will have to constantly calculate when each activity should start/finish.`
    );
  }

  if (!(A1 && A2 && A5 && A6 && A9 && A10 && A3 && A7 && A11) && A16) {
    ret.push(
      `Lastly, your ideation session might be too long.
This is not a problem per se, but a great ideation session should be short and efficient.
You should try to keep all the elements mentioned in your current agenda, but reduce the time spent with each one of them.
For example, we advise you to spend at most 10-15 minutes for each type of brainstorming in your ideation. We know this feels like too little time, but you will see that it is more than enough as the number of ideas generated falls of quite rapidly after the first 3-5 minutes.
Keeping your session short and fast-paced will make it easier to keep participants motivated and engaged for the duration of your workshop.

One advice from us: It will be important to keep a good time management and to be able to stop a discussion early to stay on track.
Ideally, bring a timer and don't bee afraid to just say 'Time is up, let's move on!'.`
    );
  }

  if (A1 && A2 && A5 && A6 && A9 && A10 && A3 && A7 && A11 && A16) {
    ret.push(
      `You have prepared a good agenda and included all the relevant aspects we expected to find!
This will not only ensure that your participants have a good overview of what is about to happen, but it will also make it easier for you to conduct your ideation session later.

However, while your session is very well prepared, it might be too long.
This is not a problem per se, but a great ideation session should be short and efficient.
You should try to keep all the elements mentioned in your current agenda, but reduce the time spent with each one of them.
For example, we advise you to spend at most 10-15 minutes for each type of brainstorming in your ideation. We know this feels like too little time, but you will see that it is more than enough as the number of ideas generated falls of quite rapidly after the first 3-5 minutes.
Keeping your session short and fast-paced will make it easier to keep participants motivated and engaged for the duration of your workshop.

One advice from us: It will be important to keep a good time management and to be able to stop a discussion early to stay on track.
Ideally, bring a timer and don't bee afraid to just say 'Time is up, let's move on!'.`
    );
  }

  if (!(A1 && A2 && A5 && A6 && A9 && A10 && A3 && A7 && A11) && A19) {
    ret.push(
      `Lastly, your ideation session it is too long. This is not a problem per se, but a great ideation session should be short and efficient.

You should try to keep all the elements mentioned in your current agenda, but reduce the time spent with each one of them.
This is important as it is hard to keep participants motivated and engaged for so long.
For example, we advise you to spend at most 10-15 minutes for each type of brainstorming in your ideation. We know this feels like too little time, but you will see that it is more than enough as the number of ideas generated falls of quite rapidly after the first 3-5 minutes.
Keeping your session short and fast-paced will make it easier to keep participants motivated and engaged for the duration of your workshop.
It will be super important to keep a good time management and to be able to stop a discussion early to stay on track, but you will see that these reductions will make your session much more efficient.
Ideally, bring a timer and don't bee afraid to just say 'Time is up, let's move on!'.`
    );
  }

  if (A1 && A2 && A5 && A6 && A9 && A10 && A3 && A7 && A11 && A19) {
    ret.push(
      `You have prepared a good agenda and included all the relevant aspects we expected to find!
This will not only ensure that your participants have a good overview of what is about to happen, but it will also make it easier for you to conduct your ideation session later.

However, while your session is very well prepared, it might be too long. This is not a problem per se, but a great ideation session should be short and efficient.

You should try to keep all the elements mentioned in your current agenda, but reduce the time spent with each one of them.
This is important as it is hard to keep participants motivated and engaged for so long.
For example, we advise you to spend at most 10-15 minutes for each type of brainstorming in your ideation. We know this feels like too little time, but you will see that it is more than enough as the number of ideas generated falls of quite rapidly after the first 3-5 minutes.
Keeping your session short and fast-paced will make it easier to keep participants motivated and engaged for the duration of your workshop.
It will be super important to keep a good time management and to be able to stop a discussion early to stay on track, but you will see that these reductions will make your session much more efficient.
Ideally, bring a timer and don't bee afraid to just say 'Time is up, let's move on!'.`
    );
  }

  /*
  Alternative Version

    { id= "A1", text= "Introduction included", points= 0.5 },
    { id= "A2", text= "Challenge is explained (includes problem statement)", points= 0.5 },
    { id= "A5", text= "Has a conclusion", points= 0.5 },
    { id= "A6", text= "Energizer included", points= 0.5 },
    { id= "A9", text= "Impact-effort analysis included", points= 0.5 },
    { id= "A10", text= "Dot-voting included", points= 0.5 },
    {id = "#6", text = "----- ----- ----- ----- ----- Pick one below ----- ----- ----- ----- ", points = 0},
    { id= "A3", text= "Good amount of breaks are included (at least 10 min for ever 90 min of workshop)", points= 0.5 },
    { id= "A4", text= "Break is included but NOT in enough quantities (less than 10 min for ever 90 min of workshop)", points= 0.25 },
    {id = "#9", text = "----- ----- ----- ----- ----- Pick one below ----- ----- ----- ----- ", points = 0},
    { id= "A7", text= "Two+  brainstorming methods included (silent, constrained, herbstlaub, building on each other", points= 1 },
    { id= "A8", text= "Only one brainstorming method  included (silent, constrained, herbstlaub, building on each other)", points= 0.5 },
    {id = "#11", text = "----- ----- ----- ----- ----- Pick one below ----- ----- ----- ----- ", points = 0},
    { id= "A11", text= "Logical and desired outcome defined for every activity", points= 2 },
    { id= "A12", text= "Logical and desired outcome defined for most activities (> n-3)", points= 1.5 },
    { id= "A13", text= "Logical and desired outcome defined for some activites", points= 1 },
    { id= "A14", text= "Logical and desired outcome defined for only a few activities (< 3)", points= 0.5 },
    { id= "A15", text= "Logical and desired outcome defined not defined for any of the activities", points= 0 },
    {id = "#31", text = "----- ----- ----- ----- ----- Additional Stuff ----- ----- ----- ----- ", points = 0},
    { id= "A16", text= "Session is too long (more than 4 hours)", points= -0.5 },
    */

  return ret;
};
