import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { C1 } = getSelectedOptionsFlags(selectedOpts);

  if (C1) {
    ret.push("Thank you for sharing this file with us.");
  }

  return ret;
};
