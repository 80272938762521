import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, C1, C2, C3, C4, C5, C6, C7, C8 } = getSelectedOptionsFlags(selectedOpts);

  if (C1) {
    ret.push(`Thank you for this additional file.`);
  }

  if (C2) {
    ret.push(`Thank you for submitting this zoomed in picture of your learning notes.
This made it much easier for us to go through your learnings!`);
  }

  if (C3) {
    ret.push(
      `Thank you for sharing more learning notes here. We have already included these in our feedback above.`
    );
  }

  return ret;
};
