import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  if (A0 && A7 === false) {
    ret.push(
      `Well done! 
While you did not state any possible conflicts that may arise between the company's existing business model and your new business model, you did state relevant synergies and similarities. Understanding these will help you devise an effective strategy to manage both these business models in tandem.`
    );
  }

  if (A0 && A7) {
    ret.push(
      `Well done, while you did not state any possible conflicts that may arise between the company's existing business model and your new business model, you did identify one relevant area where both business models overlapp.
Ideally, you should now focus to look for more conflicts as understanding these will help you devise an effective strategy to manage both these business models in tandem.`
    );
  }

  if (A1 && A7 === false) {
    ret.push(
      `Well done, while you did not state any possible overlaps that may arise between the company's existing business model and your new business model, you did identify several conflicts that may arise. Understanding these will help you devise an effective strategy to manage both these business models in tandem.`
    );
  }

  if (A1 && A7) {
    ret.push(
      `Well done, while you did not state any possible overlaps that may arise between the company's existing business model and your new business model, you did identify several conflicts that may arise.
Ideally, you should now focus to look for more conflicts as understanding these will help you devise an effective strategy to manage both these business models in tandem.`
    );
  }

  if (A2) {
    ret.push(
      `Great job! Not only did you manage to find conflicting areas in both business models, you have also correctly identified areas where both business models overlapp. Understanding these will help you devise an effective strategy to manage both these business models in tandem`
    );
  }

  if (!Z0 && !Z1 && A0 === false && A1 === false && A2 === false) {
    ret.push(
      `You failed to state possible conflicts that may arise between the company's existing business model and your new business model. Consider the two business models and look at elements that might overlap or compete for resources. It's important to take note of these conflicts and understand them in order to devise a strategy to effectively manage the two business models in tandem.`
    );
  }

  if (A3) {
    ret.push(
      `In your description of the possible conflicts you identified, you referenced relevant elements of both of the business models. The elements of each of the business models are a great place to start to find a conflict that could affect how you will manage and operate the ventures.`
    );
  }

  if (A4) {
    ret.push(
      `In your description of the possible conflicts you identified, you did not reference relevant elements of both of the business models. You should start by considering the elements of each of the business models to identify possible conflicts because that will give you the most complete view of how the two business models may overlap or interplay.`
    );
  }

  if (A5) {
    ret.push(
      `You clearly described the conflicts you identified and demonstrated insight into how both of the business models work and may overlap. You did the work of investigating both business models to uncover important conflicts that may not be obvious without in-depth thought. However, it is these deeper conflicts that are the most important to consider because they will likely arise when you begin to implement your strategy.`
    );
  }

  if (A6) {
    ret.push(
      `You did not demonstrate sufficiently in-depth insight into how both of the business models work in the conflicts you stated. Consider further researching and investigating both business models to uncover important conflicts that may not be obvious without in-depth thought. It is these deeper conflicts that are the most important to consider because they will likely arise when you begin to implement your strategy.`
    );
  }

  return ret;
};
