import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Thank you for describing the additional business model you will be using for this exercise. With descriptions of the two business models you will be using, you can begin to imagine how they might fit together.`
    );
  }

  return ret;
};
