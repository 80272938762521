import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts, scoredPoints) => {
  const ret = [];
  const { Z0, Z1, D1, D2, D3 } = getSelectedOptionsFlags(selectedOpts);

  if (D1) {
    ret.push(
      `Thank you for you submission. You will find the feedback for your submission in part a).`
    );
  }

  return ret;
};
