import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Well done! You submitted a good number of strategic conversations for your workshop. `
    );
  }

  if (A2) {
    ret.push(
      `Defining your strategic conversations will help you to reach the desired outcomes one step at a time. The next step to plan the workshop is defining the open-run-close details of each strategic conversation. Keep up with the good work!`
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `You shared less than the three strategic conversations we asked for. Three is a good number to start a workshop. If you have less, this can have two reasons: You are missing a strategic conversation which is needed to reach your desired outcomes, or you can spit a strategic conversation into more sub-activities. `
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `To improve the planning of your workshop, go back to your desired outcomes and reflect whether you included all the strategic conversations necessary to reach them. `
    );
  }

  return ret;
};
