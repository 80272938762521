import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts, scoredPoints) => {
  const ret = [];
  const { Z0, Z1, D1, D2 } = getSelectedOptionsFlags(selectedOpts);

  if (D1) {
    ret.push(`Once more, the feedback to your fourth feedback is very similar to the one in part a.
Still, we are happy to see that you did more than just 2 interviews as asked.
Well done!`);
  }

  if (D2) {
    ret.push(`Thank you for submitting your fourth protocol here.
Again, it is very well prepared.
Good job!`);
  }

  return ret;
};
