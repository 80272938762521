import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for stating what outcomes and milestones you are aiming for. Having a clear deadline will stop you from wasting time, move quickly, and continue or kill your project early on.`
    );
  }

  if (A2) {
    ret.push(
      `We recommend you to add more details to your desired outcomes and milestones. This will help you to move on quickly and not fall into a analysis paralysis. Having clear deadlines will also stop you from wasting time, move quickly, and continue or kill your project early on.`
    );
  }

  if (A3) {
    ret.push(
      `Great work on defining clear activities for each sprint week and phase. With that knowledge, you can already get started to set up calendar blocker and invite stakeholder, experts, or users for research, ideation, feedback, or decision moments.`
    );
  }

  if (A4) {
    ret.push(
      `Try to be more specific on the activities you are planning. When will you do interviews? Experiments? Iterations?
With that knowledge, you can already get started to set up calendar blocker and invite stakeholder, experts, or users for research, ideation, feedback, or decision moments.`
    );
  }

  if (A5) {
    ret.push(
      `You also added a good decision criteria for each phase. Based on decision criteria you stated, you can decide to continue, pivot, or kill the project. `
    );
  }

  if (A6) {
    ret.push(
      `When will your go/no-go decisions happen and what are the criteria? Based on decision criteria you stated, you can decide to continue, pivot, or kill the project. Some criteria that should be included in every sprint are: Is your problem worth solving (regarding the need and your companies strategy)? Is your solution clearly solving the problem you identified previously? Did the business model testing satisfy the success criteria you set in advance?`
    );
  }

  return ret;
};
