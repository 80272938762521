import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2 && A3 && A4 && A5 && A6 && A7 && A8 && !A9) {
    ret.push(
      `Good job!
It was very interesting to read your reflection. Thank you for sharing! Just keep in mind that, while there is no right or wrong, reflecting on your interviews' outcome can be extremely useful as it helps you identify things that should be maintained or changed for the next time you conduct an interview.
We advise you to keep doing this after each interview you conduct in the future.`
    );
  }

  if (
    A1 &&
    A3 &&
    A5 &&
    A7 &&
    (A2 === false || A4 === false || A6 === false || A8 === false) &&
    !A9
  ) {
    ret.push(
      `Thank you for submitting four interesting aspects as requested. We hope that this reflection will help you prepare better learnings later and improve your interviewing skills even further.`
    );
  }

  if (
    Z0 === false &&
    Z1 === false &&
    Z2 === false &&
    (A1 === false || A3 === false || A5 === false || A7 === false) &&
    !A9
  ) {
    ret.push(
      `You did not submit four interesting aspects as asked. We would have liked to read more about your experience. Just keep in mind that, while there is no right or wrong, reflecting on your interviews' outcome can be extremely useful as it helps you identify things that should be maintained or changed for the next time you conduct an interview.`
    );
  }

  /*
    {id = "#6", text = "----- ----- ----- ----- Quantity ----- ----- ----- ----- ----- ", points = 0},
    { id= "A1", text= "More then 4 aspects submitted", points= 2 },
    { id= "A2", text= "4 aspects submitted", points= 2 },
    { id= "A3", text= "2 - 3 aspect submitted", points= 1 },
    { id= "A4", text= "1 aspect submitted", points= 0.5 },
    {id = "#7", text = "----- ----- ----- ----- Quality ----- ----- ----- ----- ----- ", points = 0},
    { id= "A5", text= "Good reflection", points= 2 },
    { id= "A6", text= "Okay reflection", points= 1 },
    { id= "A7", text= "poor reflection", points= 0.5 },
    {id = "#8", text = "----- ----- ----- ----- Additional stuff ----- ----- ----- ----- ----- ", points = 0},
    { id= "A8", text= "Included things that could have been improved", points= 0},
  */

  /* Alternative grading scheme for the criteria above

  if (A1) {
    ret.push(
      `Thank you for submitting even more aspects than those required in the exercise!
We appreciate you taking the time to do so and hope that reflecting on your experience has been insightful.`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for submitting several aspects that you have found interesting!
We appreciate you taking the time to do so and hope that reflecting on your experience has been insightful.`
    );
  }

  if (A3) {
    ret.push(
      `You did not submit as many aspects regarding your interviews as he have requested.
We asked to submit at least four to ensure that you reflect about the whole process so far (even if just a little).
Doing this can often lead to more learnings and insights and will help you develop your interviewing skills in the future.

If you feel like you haven't really reflected on this so far, we strongly recommend you take a few minutes to go over all the steps taken so far.`
    );
  }

  if (A4) {
    ret.push(
      `You submitted only one aspect related to your interviews.
We asked to submit at least four to ensure that you reflect about the whole process so far (even if just a little).
Doing this can often lead to more learnings and insights and will help you develop your interviewing skills in the future.

If you feel like you haven't really reflected on this so far, we strongly recommend you take a few minutes to go over all the steps taken so far.`
    );
  }

  if (A8) {
    ret.push(
      `Lastly, it seems like you included some potential improvements already.
We are happy to see that you are thinking about this already!`
    );
  }

  */

  if (
    (!A9 && A1 && A2 === false) ||
    (A3 && A4 === false) ||
    (A5 && A6 === false) ||
    (A7 && A8 === false)
  ) {
    ret.push(
      `Lastly, some of your answers are not detailed enough.
In the future, we ask you to give more detailed answers. This will ensure that your work is very clear and easy to follow.`
    );
  }

  if (A9) {
    ret.push(
      `Thank you for your submission.
The goal of this exercise was for you to reflect on your experience interviewing your users and write down at least four thing that were interesting or went well.
However, it seems to us that you have submitted some learnings made during the interviews instead.

This is okay and you can leave your submission like this, but we ask you to still reflect on your experience so far and think of things that went well in your interviews.
Doing this might help you keep certain behaviours that led to these good outcomes and will help you become better and better at interviewing.`
    );
  }

  if (A10) {
    ret.push(
      `Lastly, we also liked how wrote down different aspects for different interviews.
This is not only a better way to document your experience, but it will also help you remember each interview better in the future.
Well done!`
    );
  }
  return ret;
};
