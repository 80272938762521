import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`We were very pleased with your team's submission. You have developed a good variety of ideas and have explained all of them in a brief but effective manner. This is exactly what was expected. Great job!
Often we review projects where most solutions are simple variations of one core idea. Your team however has managed to select and design quite different solutions to your problem.
Furthermore, you have also done a great job evaluating the impact this idea would have on different stakeholders and sometimes even clarified how you might leverage these impacts.
Therefore, congratulations one more time on your team’s great work!`);
  }

  return ret;
};
