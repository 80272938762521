import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`);
  }

  if (Z1) {
    ret.push(`You have successfully broken down your idea into many clear and precise features.
Doing so will allow you to test and evaluate different features and discover which ones are more/less relevant for your users. This will then make it possible for you to design solutions that are simple and effective.
Well done!`);
  }

  return ret;
};
