import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Very well done!
Your problem statement is well stated and addresses a relevant need of your user.
Also, the scope of the statement is just right! This will help you focus on the most important need of your user while not being too constrained in your ideation session. This is important because it will allow you to think of several relevant solutions.`
    );
  }

  /*

  Commented this out just as a precaution, these conditions are in the 1.1.a.ts

  if (!Z0 && !Z1 && A0 === false) {
    ret.push(
      `No actor was mentioned. Please make sure to always include an actor or user in your problem statement. This is super important as the whole goal of design thinking is to really understand your user and create tailored solutions for him/her. `
    )
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `No need mentioned. To create a lot of value for your users, it is important to focus on a deep underlying need or issue they might have. This is the central part of your problem statement and should always be very clear. Therefore, please rewrite a new overarching question putting more emphasis on a relevant need identified in M1.`
    )
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `You did not include a relevant insight or learning in your problem statement. After doing research on your users, you must have learned something about their motivations and needs. Use this information now to create a more targeted problem statement that tackles these needs and frustrations. For this, we advise you to integrate your key finding into your problem statement.`
    )
  }
  */

  return ret;
};
