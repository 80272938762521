import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Great job! You stated an epicenter from your business model canvas and explained why it's interesting to you and your team. This is a useful way of reframing how you think about an element in your business model canvas that can spark business model innovation.`
    );
  }

  return ret;
};
