import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`After experimentation, there should be decision point with your team to discuss whether the preferred solution actually solves the problem you identified at the beginning of your sprint. Interested consumers are a great indicator, but you might be missing an even bigger potential if you are not solving the problem or challenge you started with.
I highly encourage you to discuss this question with your team to focus back on the original challenge and help you make a go/no-go decision.`);
  }

  return ret;
};
