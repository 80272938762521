import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push("Thank you taking the time to add an additional comment.");
  }
  
  return ret;
};
