import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Your testing procedure is very well designed to test your hypothesis. Additionally, it is also reproducible, which means that you are able to perform the same test under different conditions and should get similar answers from similar participants.`
    );
  }

  if (Z2) {
    ret.push(
      `Very well done!
Your testing procedure is very well designed to test your hypothesis and went beyond what we expected in this assignment. Additionally, it is also reproducible, which means that you are able to perform the same test under different conditions and should get similar answers from similar participants.`
    );
  }

  return ret;
};
