import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, B1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (B1) {
    ret.push(`Thank you for your answer once more.
Understanding how you perceived this program and how it affected your work habits is very valuable to us.
We appreciate you sharing your thoughts!`);
  }

  return ret;
};
