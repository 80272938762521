/** @jsx jsx */
import * as React from "react";
import { Box, jsx, Heading, ThemeProvider, Text } from "theme-ui";
import { themeNew } from "@sparkademy/app-common/materials/theme";
import { Layout } from "../components/Layout";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { Container } from "@sparkademy/app-common/elements/Container";
import { EvaluationItemCard } from "../components/EvaluationItem";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ButtonOutline } from "@sparkademy/app-common/elements/ButtonOutline";
import { useAssignmentAnswersContext } from "@sparkademy/app-common/contexts/assignment-answers-context";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { ArrowLinkButton } from "@sparkademy/app-common/elements/ArrowLink";
import { useGradingContext } from "../contexts/grading-context";
import { GradingService } from "../services/grading";
import { getAppliedCriteriaScore } from "../helpers";
import { FlagForReviewDialog } from "../components/FlagForReviewDialog";

// Page for evaluation of participant responses. Occurs before feedback page.
export const Evaluation: React.FC = () => {
  const { currentUser } = useSessionContext();
  const history = useHistory();
  const { modules: modulesInfo } = useContentContext();
  const { savedFeedback, fetchExerciseAnswers, clearFeedback, clearScores, clearLocalAnswers } =
    useAssignmentAnswersContext();
  const {
    currentSubmission,
    generateFeedbackData,
    fetchSubmission,
    gradingStartTime,
    clearEvaluationData,
    evaluations,
    fetchModuleAssignment,
    assignment,
    loading: loadingGradingData,
  } = useGradingContext();
  const { exercises } = assignment;
  const { assignmentExerciseId } = useParams<{ assignmentExerciseId: string }>();
  const [isSaving, setIsSaving] = React.useState(false);
  const [dialogToShow, setDialogToShow] = React.useState<string>("");

  React.useEffect(() => {
    if (!currentUser || !currentSubmission) {
      return;
    }
    fetchModuleAssignment(
      currentSubmission?.user_id,
      currentSubmission?.module_id,
      currentSubmission?.cohort_id,
      currentSubmission?.is_retry,
      currentUser
    );

    clearFeedback();
    clearScores();
  }, [currentSubmission, currentUser, fetchModuleAssignment, clearFeedback, clearScores]);

  React.useEffect(() => {
    if (!currentSubmission?.id) {
      return;
    }
    fetchExerciseAnswers(currentSubmission.id, currentUser!);
  }, [currentSubmission, currentUser, fetchExerciseAnswers]);

  React.useEffect(() => {
    if (
      assignmentExerciseId &&
      currentUser &&
      (!currentSubmission || currentSubmission?.id !== parseInt(assignmentExerciseId))
    ) {
      fetchSubmission(parseInt(assignmentExerciseId), currentUser);
    }
  }, [currentSubmission, assignmentExerciseId, currentUser, fetchSubmission]);

  if (loadingGradingData || isSaving) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  if (currentUser?.data.role !== "admin" && currentSubmission?.graded_at) {
    return (
      <Layout>
        <Box sx={{ display: "flex", justifyContent: "center", p: 10 }}>
          This submission has already been graded.
        </Box>
      </Layout>
    );
  }

  const currentExercise = exercises.find(e => e.id === currentSubmission?.exercise_id);
  const moduleInfo = modulesInfo.find(
    m => m.id.toLowerCase() === currentSubmission?.module_id.toLowerCase()
  );

  const flagSubmissionForReview = async (comment: string) => {
    // will save the grading with no scores so it gets flagged for review
    // this is for when the grader is unsure on how to continue and rather have an admin to finish it
    setIsSaving(true);

    try {
      if (currentSubmission && currentUser) {
        const criteriaApplied = getAppliedCriteriaScore(evaluations, currentExercise!);

        await GradingService.saveGrading(
          currentSubmission?.id,
          savedFeedback,
          {},
          gradingStartTime!,
          criteriaApplied,
          comment,
          currentUser
        );
        sessionStorage.clear();
        clearEvaluationData();
        clearFeedback();
        clearScores();

        history.push(`/grading/${currentSubmission.cohort_id}`);
      }
      setIsSaving(false);
    } catch (err) {
      window.alert(err);
      console.error(err);
      setIsSaving(false);
    }
  };

  const getFeedback = () => {
    if (!currentExercise) {
      console.error("no exercise found.");
      return;
    }

    generateFeedbackData(currentExercise);

    history.push(`/feedback/${currentSubmission?.id}`);
  };

  const learnerNameOrId =
    currentUser?.role === "admin" ? currentSubmission?.user_name : currentSubmission?.user_id;

  return (
    <ThemeProvider theme={themeNew}>
      <Layout>
        <Container
          sx={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {dialogToShow === "flagForReview" && (
            <FlagForReviewDialog
              onClose={() => setDialogToShow("")}
              onSubmit={(comment: string) => flagSubmissionForReview(comment)}
            />
          )}

          <Box
            sx={{
              mt: "80px",
              display: "flex",
              flexDirection: "column",
              width: "894px",
            }}
          >
            <ArrowLinkButton
              sx={{ fontSize: "20px" }}
              id="back-link"
              onClick={() => {
                clearLocalAnswers();
                history.goBack();
              }}
            >
              Back
            </ArrowLinkButton>

            <Heading
              as="h3"
              sx={{
                color: "new.secondary.grey",
                fontWeight: 700,
                fontSize: "20px",
                mt: 8,
              }}
            >
              {`Module ${moduleInfo?.index}`} | {currentExercise?.title}
            </Heading>

            <Heading
              as="h1"
              sx={{
                fontWeight: 700,
                fontSize: "24px",
                mt: 8,
              }}
            >
              {learnerNameOrId} | {currentSubmission?.cohort_id.toUpperCase()}
            </Heading>
          </Box>

          <Box
            sx={{
              width: "100vw",
              bg: "new.bg",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mt: "48px",
            }}
          >
            {currentSubmission?.flag_comment && currentSubmission.needs_grading_review && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  bg: "brand.yellowLight",
                  width: "894px",
                  mt: "48px",
                  p: 8,
                }}
              >
                <Text as="p" sx={{ fontWeight: 700, fontSize: "16px", mb: "32px" }}>
                  Flag Comment
                </Text>
                <Text as="pre" sx={{ whiteSpace: "pre-wrap" }}>
                  {currentSubmission.flag_comment}
                </Text>
              </Box>
            )}

            {currentExercise &&
              currentExercise.sections.map(s => <EvaluationItemCard key={s.id} section={s} />)}

            <Box
              sx={{
                my: 8,
                ...(currentUser?.role === "grader"
                  ? {
                      display: "flex",
                      justifyContent: "space-between",
                      width: "min(894px, 100%)",
                    }
                  : {}),
              }}
            >
              {currentUser?.role === "grader" && (
                <ButtonOutline
                  sx={{ fontSize: "20px" }}
                  onClick={() => setDialogToShow("flagForReview")}
                >
                  Flag for Review
                </ButtonOutline>
              )}
              <Button sx={{ fontSize: "20px" }} onClick={getFeedback}>
                Next
              </Button>
            </Box>
          </Box>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};
