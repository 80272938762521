import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { C1, C2, C3 } = getSelectedOptionsFlags(selectedOpts);

  if (C1 || C3) {
    ret.push(
      `Well done. Preparing ahead and blocking time for this program will make it much easier for you to work on your challenge later.`
    );
  }

  if (C2) {
    ret.push(
      `Well done. Preparing ahead and blocking time for this program will make it much easier for you to work on your challenge later.
However, we must inform you that based on our previous experiences with cohorts going through the same program, you should expect to need at least 5h per week to complete it successfully (with some needing up to 8h a week).
This is especially the case for the first module. For the following modules you will see that 3h a week may suffice.`
    );
  }

  return ret;
};
