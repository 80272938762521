import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Excellent work!
You have used relevant sources of inspiration and included a clear explanation of why they were valuable for your revised business model. Furthermore, you were thoughtful about how it was relevant to the different elements in your business model canvas such as user needs, the market environment, or the creation and capture of value.
It's also helpful if the inspiration you draw on, improves the business model in ways that are outside of the box.`
    );
  }

  return ret;
};
