import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A0) {
    ret.push(`We are sorry to hear that.
Our estimates come from working with previous cohorts and are re-assessed after every new wave.

One advise we would like to share is that you might be able to reduce the time needed for these assignments by doing your best to stick to our recommended time estimates. It is very easy to prolong a discussion or to spend too much time discussing some details in your persona or user journey. Being able to manage your team's time and shut down a discussion after the time is over is key. 

When it comes to design thinking it is often not worth to spend too much time in your first iteration.
You will learn much more by having many short feedback loops with users, than by investing a lot of time trying to get your deliverables perfect the first time.

Thank you again for your honest feedback.
If you are interested, we can also certainly have a brief meeting to discuss this in more detail.`);
  }

  if (A1) {
    ret.push(`Thank you for your comment. We highly appreciate it!`);
  }

  return ret;
};
