import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13, A14, A19, A20, A23 } =
    getSelectedOptionsFlags(selectedOpts);

  if (!Z0 && !Z1 && scoredPoints === 5) {
    ret.push(`Well done!
Your answer summarises all the key aspects of your idea.
Most of all, you have made it very clear who your target user is, what impact your solution should have on this user and what feature can cause this impact on your user.

Your goal now will be to design an experiment where you will validate the desirability of your idea with your users on the field.
We are looking forward to seeing how you will do that!`);
  }

  if (!Z0 && !Z1 && scoredPoints < 5 && scoredPoints > 3.9) {
    ret.push(`Your submission above is a good description of your idea, who it is meant for and what impact this idea should have on your users.
However, while you have done a good job on this assignment, there is one or two things that you could improve in your submission. `);
  }

  if (!Z0 && !Z1 && scoredPoints < 4 && scoredPoints > 2.9) {
    ret.push(`Your submission above is a good description of your idea, who it is meant for and what impact this idea should have on your users.
However, there are a few things that you could improve in your submission.`);
  }

  if (!Z0 && !Z1 && scoredPoints < 3) {
    ret.push(`Your submission above describes your idea, who it is meant for and what impact this idea should have on your users.
However, there are a few things that you could improve in your submission.`);
  }

  if (A4) {
    ret.push(`You did not describe or visualize your idea.
We know that you have done this in the previous assignments, but including it in this "Describe your Solution" sheet will allow you easily share and present your idea to others.
This is why we ask you to always include a clear description/visualization of your idea on this sheet.`);
  }

  if (A1) {
    ret.push(`It is not clear to us what exactly you meant by your key feature.
Next time, please make sure to write it more clearly so that we can understand and evaluate it better.`);
  }

  if (A2) {
    ret.push(`Who exactly is your target user?
Your answer does not make that very clear.
In the future, you should try to pinpoint a clear user segment that you are focusing on.`);
  }

  if (A3) {
    ret.push(`What impact are you trying to achieve in your users?
Are you trying to change a behavior? Improve an experience? Or something else entirely?
At the end of the day, your goal is to solve a relevant problem for your users. Try to pinpoint what exactly they will experience if your idea works.
Does your solution affect your users in the way you wanted them to be affected?`);
  }

  if (A5) {
    ret.push(`Your idea's name is not very marketable.
Very soon you will be out there validating your solution with real users. To make this experience more realistic it is important to name your solution properly.
This will give credibility to it and help you conduct better experiments.
Therefore, please take a few minutes and think of an interesting name you could give your solution. We are sure you can think of something!`);
  }

  if (A23) {
    ret.push(`Your key feature does not focus on the tangible benefit your idea will create to your user. Instead, it focuses too much on the underlying technology of your idea.
When defining key features, it is crucial to emphasize the practical outcomes and benefits for the user, rather than the underlying technology. Users typically prioritize the value a product provides over its technical aspects. For now, concentrate on determining whether this feature is truly desirable. Once you confirm this, you can then explore the necessary technology for its implementation (feasibility) later.`);
  }

  if (A6) {
    ret.push(`Is this feature really central to your idea?
From the description of your idea before, we think that this feature may not be the most relevant one.
Thus, please take a few minutes to re-evaluate what the core feature of your idea is.
One way you can do that is visualize how the user journey would be affected by your idea. Be very detailed and analyze what exact feature of your idea would make your user feel better.
This is most likely your key feature.`);
  }

  if (A7 && !A6  && !A23) {
    ret.push(`It seems to us that you did not identify the correct key feature.
Could it be that you are already thinking about the experiment you are going to conduct?
This might explain why you chose this key feature (as it is something you could use to validate desirability).
However, at this stage, you should focus solely on your idea. Therefore, please take a few minutes to re-evaluate what the core feature of your idea is.
One way you can do that is visualize how the user journey would be affected by your idea. Be very detailed and analyze what exact feature of your idea would make your user feel better.
This is most likely your key feature.`);
  }

  if (A12) {
    ret.push(`Your key feature seems to be your entire idea.
Instead of listing several features that could add value to your user, try to pinpoint the one thing that can really address the issues discovered in M1 (be as specific as you can).
Pinpointing this now will make it much easier for you to design and conduct your experiment later.`);
  }

  if (A13) {
    ret.push(`You have stated more than one Key Feature.
In the future, you should try to focus on only one feature for now.
Doing so will help you design your experiment to test this one key feature (is it really as important as you think it is?).
Even if you really believe there are multiple key features, you should try to focus on them one at a time. This will make your experiments more simple and reliable later!`);
  }

  if (A14) {
    ret.push(`Your key feature is not focused enough.
While we understand what you are going for, your key feature should be more specific. Having broad key-features will make it harder for you to assess if this feature is really central to your users or not.
Please try to break down your current key feature into smaller elements and then pick the one you believe is the most central to your solution. Doing so will help you evaluate your solution more effectively during your experiment later.`);
  }

  if (A10) {
    ret.push(`Are you sure that your key feature will cause your intended impact?
It seems to us that there is no real direct connection between the two.
Therefore, please go over your submission and make sure that your key feature really causes the impact you are going for.
You can do this by adapting your key feature, your intended impact or both.`);
  }

  if (A8) {
    ret.push(`Your target user segment is too broad.
Even if you really believe that all these users have this same issue, we advise you to always start with those users that feel this problem the most (Or are more likely to be early adopters). Focusing on them will make it much easier for you to test and develop your idea.
Therefore, please re-evaluate your target user group and try to focus more on the subset that is struggling the most with the problem you are addressing.`);
  }

  if (A9) {
    ret.push(`Your intended impact does not focus enough on your users.
What we mean by this is that your answer is too broad and does not really mention how exactly your idea will affect your users.
Will it change their behaviors? Will it allow them to do more of the things they like by saving them time?
When talking about impact, the best thing you could do is to take your persona and use it to visualize how exactly your solution will create value them.
For example: "Bob the builder will be able to... Which allows him..." or "Michelle the Manager won't have to ... anymore and can instead...".`);
  }

  if (A11 && !A9) {
    ret.push(`Your intended impact is too broad.
This makes it hard to evaluate if you have actually achieved your goal or not.
One thing you can do to evaluate if your intended impact is to broad is to ask yourself how you would measure this impact.
Another good approach is to be specific and state how your solution would affect your persona's behaviors (E.g., "My persona would stop... and he will..."). Make sure to use your persona and your User Discovery learnings here.
You can later (during your experiment) track these changes and use it to validate your idea.`);
  }

  if (A19) {
    ret.push(`First of all, you should pick one of your users and focus on them.
Different users will have different pain-points and will value different parts of your solutions differently.
To be able to validate your solution, you must therefore focus on one of them first.
Our advise is to always focus on the users that have the biggest pain-point.`);
  }

  if (A20) {
    ret.push(`To conclude:
Please make sure to be more specific about what problem you are trying to solve and who exactly your target user is.
It is crucial that you are very specific when defining these two factors. Doing so will allow you to focus on what exactly your solution can do for your users.

As it is right now, your solution is not focused enough to be validated effectively.`);
  }

  return ret;
};

/*
  {id = "A1", text = "key feature is not clear", points = -0.5},
  {id = "A2", text = "Target user is not clear", points = -0.5},
  {id = "A3", text = "Intended impact is not clear", points = -0.5},
  {id = "A4", text = "Idea not explained/visualized", points = -0.5},
  {id = "A5", text = "Solution name is bad (not something catchy or interesting)", points = -0.5},
  {id = "A6", text = "key feature is not central to the idea", points = -1},
  {id = "A7", text = "key feature is focusing on the experiment instead of the solution (e.g. KF is a checkbox)", points = -1},
  {id = "A8", text = "Target customer is too broad (e.g. all cyclists)", points = -0.5},
  {id = "A9", text = "Intended impact is only intermediary goal and not final goal", points = -1},
  {id = "A10", text = "Not clear how KeyFeature will cause intended impact", points = -0.5},
*/
