import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && !A2) {
    ret.push(
      `Thank you for uploading a screenshot of your matrix.
While you did upload a screenshot, we could not see how your metrics fall along each axis from actionable to vanity and leading to lagging. In order to evaluate your metrics relative to one another, you should plot them all along the same axis.`
    );
  }

  if (A1 && A2) {
    ret.push(
      `Thank you for uploading a screenshot of your matrix!
You have done a good job placing each metric along the 2x2 matrix to clearly indicate where it falls from actionable to vanity and leading to lagging.`
    );
  }

  if (!Z0 && !A3 && A1 && A2) {
    ret.push(
      `However, we could not see the axis labels and or the text of your metrics. We recommend you to write those more clearly and upload again with a higher resolution photo.`
    );
  }

  if (!Z0 && !A3 && A1 && !A2) {
    ret.push(
      `Furthermore, we could not see the axis labels and or the text of your metrics. We recommend you to write those more clearly and upload again with a higher resolution photo.`
    );
  }

  return ret;
};
