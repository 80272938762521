import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A0) {
    ret.push(`No submission`);
  }

  if (A1) {
    ret.push(
      `While you did mention a good place to perform your ideation session, you did not state well enough what has to be prepared for you to have a successful ideation session. Please make sure to write down all the things you will need to prepare beforehand so that your ideation session runs smoothly.`
    );
  }

  if (A2) {
    ret.push(
      `While you did mention a good tool to perform your ideation session virtually, you did not state well enough what has to be prepared for you to have a successful ideation session. Please make sure to write down all the things you will need to prepare beforehand so that your ideation session runs smoothly.`
    );
  }

  if (A3) {
    ret.push(
      `Very well done! You have chosen a good place to make your ideation session happen and have made all the necessary preparations for it. This will increase the probability that your ideation session will run smoothly.`
    );
  }

  if (A4) {
    ret.push(
      `Very well done! You have chosen a good digital tool to make your ideation session happen virtually and have made all the necessary preparations for it. This will increase the probability that your ideation session will run smoothly.`
    );
  }

  return ret;
};
