import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4 } = getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "A1", text = "He made clear what the idea is", points = 0.5},
    {id = "A2", text = "Target user stated", points = 0.5},
    {id = "A3", text = "Idea well explained", points = 0.5},
  */
  if (A1 && A2 && A3) {
    ret.push(
      `Great job!
You have made it very clear what your idea is all about, for whom you are developing it, and managed to explain it in a very clear way.`
    );
  }

  if (A1 && A2 && !A3) {
    ret.push(
      `Thank you for describing your idea.
You've done a good job explaining what your idea is all about and to whom you are designing it.
However, in order to improve it even further, you should try to be more detailed as well. Your submission was a bit too short.`
    );
  }

  if (A1 && !A2 && A3) {
    ret.push(`Thank you for describing your idea.
You've given a clear explanation to what your idea is all about.
However, you did not state exactly to whom you are developing this idea.
In the future, please make sure to pin-point your users, as this will help you address their needs better.`);
  }

  if (!A1 && A2 && A3) {
    ret.push(`Thank you for your answer.
While you have stated to whom you are developing your idea (who your user is), you did not make it very clear what your idea is.
Is it a product? Is it a service or an experience?
Try to pin down exactly what your idea is all about. This will help you convey your message and focus on your key issues.`);
  }

  if (A1 && !A2 && !A3) {
    ret.push(`Thank you for describing your idea.
However, you did not state exactly to whom you are developing this idea nor is your explanation very detailed.
In the future, please make sure to pin-point your users, as this will help you address their needs better.`);
  }

  if (!A1 && A2 && !A3) {
    ret.push(`Thank you for your answer.
While you have stated to whom you are developing your idea (who your user is), you did not make it very clear what your idea is.
Is it a product? Is it a service or an experience?
Try to pin down exactly what your idea is all about. This will help you convey your message and focus on your key issues.

Furthermore, your answer is also not detailed enough.
We ask you to describe your answers and progress better in the future.
This will not only help us give you better feedback, but it will also help you evaluate your progress better.`);
  }
  /*
  if (!A1 && !A2 && A3) {
    ret.push(``);
  }
*/
  if (!Z0 && !Z1 && !A1 && !A2) {
    ret.push(`Thank you for your answer.
However, you did not make it very clear what exactly your idea is.
Is it a product? Is it a service or an experience?
Try to pin down exactly what your idea is all about. This will help you convey your message and focus on your key issues.

Furthermore, you did not explicitly state your target user.
It is essential to know exactly who your user is. This is what will allow you to make tailored solutions that will add a lot of value for your users.

Please make sure to describe your solutions better in the future.`);
  }

  if (A4) {
    ret.push(`Thank you also for the level of detail in your answer!`);
  }

  /*
  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `You did not make it very clear what exactly your idea is.
Is it a product? Is it a service or an experience?
Try to pin down exactly what your idea is all about. This will help you convey your message and focus on your key issues.`
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `Thank you for describing your idea.
While your description was clear and detailed, you did not explicitly state your target user.
It is essential to know exactly who your user is. This is what will allow you to make tailored solutions that will add a lot of value for your users.`
    );
  }

  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `You were too brief in the explanation of your idea.
Please make sure to go through its key features and characteristics so that the idea is very clear not only to you, but also to those reading it. Doing this will help you find any issues with your idea and reflect on its effectiveness. `
    );
  }
*/
  return ret;
};
