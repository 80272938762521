import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, C1, C2, C3, C4, C5, C6, C7, C8, C9, C10, A3, A4, A14, A5, A6, B3, B4, B5, B6 } =
    getSelectedOptionsFlags(selectedOpts);

  if (C3 && (A3 || B3)) {
    ret.push(
      `Thank you for this last submission.
As mentioned earlier, we ask you to take time to reflect on the discussions you had about such interactions.`
    );
  }

  if (C3 && !(A3 || B3)) {
    ret.push(
      `Thank you for this last submission.
We ask you to take time to reflect on the discussions you had about such interactions.`
    );
  }

  if (C4 && (A4 || B4)) {
    ret.push(
      `Thank you for this last submission.
As mentioned earlier, we ask you to take time to reflect on the discussions you had about such interactions.`
    );
  }

  if (C4 && !(A4 || B4)) {
    ret.push(
      `Thank you for this last submission.
We ask you to take time to reflect on the discussions you had about such interactions.`
    );
  }

  if (C5) {
    ret.push(
      `Thank you for this last submission.
As mentioned earlier, we are happy to see how you took time to reflect on this topic as well.
Good job!`
    );
  }

  if (C6) {
    ret.push(
      `Thank you for this last submission.
As mentioned earlier, we are happy to see how you took time to reflect on this topic as well.
Great job!`
    );
  }

  return ret;
};
