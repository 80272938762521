import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, Z4 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `No submission. We would have loved to see what questions and insights you came up with! We can only advise you to try your best and submit all of your results. This will allow us to give you meaningful feedback and accelerate your learning process. The goal of this exercise was to check how aligned the members of your team are. There is no right or wrong answer, but we would expect that In some cases, you would all agree, and in others, you could disagree. The goal is then to understand those differences and make good use of them. Just make sure to share your information, listen to each other and cooperate. By doing that, you can reduce any friction that might arise and increase the benefits of having diversity in your team. This can leverage your team's informational diversity and help you achieve better solutions.`
    );
  }

  if (Z1) {
    ret.push(
      `Very well done! You have stated two very good questions regarding prejudices as well as given detailed answers and a good description of the similarities and differences to other answers in your team. Hopefully, this has made you realize how different cultures and backgrounds can lead to completely different interpretations for the same situation. Now it is important for you to use this information to improve your teamwork.`
    );
  }

  if (Z2) {
    ret.push(
      `Thank you for your submission.
As stated above, the goal of this exercise is to understand the different perspectives that exist in your team.
We hope you have found value in this short discussions and hope you will repeat them periodically as your team grows!`
    );
  }

  if (Z3) {
    ret.push(
      `Thank you for your submission.
As stated above, the goal of this exercise is to understand the different perspectives that exist in your team.
We hope you have found value in this short discussions and hope you will repeat them periodically as your team grows!`
    );
  }

  if (Z4) {
    ret.push(
      `Thank you for your submission.
As stated above, the goal of this exercise is to understand the different perspectives that exist in your team.
We hope you have found value in this short discussions and hope you will repeat them periodically as your team grows!`
    );
  }

  return ret;
};
