import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Fantastic job! Your revised business model shows thought and creativity in applying the insights you gained from the first two parts of this activity. You took advantage of the first two prompts that were ways to reframe your business model and incorporated new ideas for business model innovation.`
    );
  }

  return ret;
};
