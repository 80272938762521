import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);



  if (A1) {
    ret.push(
      `Great job! You have effectively captured the various stages your user goes through during their customer journey.`
    );
  }

  if (A2) {
    ret.push(
      `You only stated a few stages in the user journey. Are these the only phases of the experience the user goes through in the user journey? Try to cover all the experiences starting from when the user becomes aware of the product to sustaining their loyalty to you.`
    );
  }

  if (A3) {
    ret.push(
      `You have thoroughly covered each element (actions, goals, touchpoints, and emotions) in your user journey. This detailed analysis will help you quickly spot areas of need and opportunities for your user. `
    );
  }

  if (A4) {
    ret.push(
      `Good attempt at mapping the elements of your user’s journey. However, some elements seem to be missing which might prove to give valuable insights in identifying bottlenecks and areas of opportunities. We recommend you try to fill in every element in the template (except for the opportunities section). `
    );
  }

  if (A5) {
    ret.push(
      `The emotions you pointed out in the journey are also specific and relevant to the stages, activities and goals. This shows you have thought about the emotional experience as well. Although, it is important to validate assumptions about the emotions the user feels in the journey by talking to them directly in qualitative user research. `
    );
  }

  if (A6) {
    ret.push(
      `The emotions you listed in the journey map are not specific enough to relate well the actions and goals of your user in a given stage. We recommend considering the emotional journey more closely. The best way to do this is to ask users themselves in qualitative research studies like interviews and observation (which you will do in the next exercise).`
    );
  }

  return ret;
};
