import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Nice job! You have stated multiple assumptions to be validated from a quantitative perspective. By now you should be familiar with how to frame assumptions. `
    );
  }

  if (A2) {
    ret.push(
      `You have stated less number of assumptions. It is good to think of several assumptions. Gathering quantitative data can be time consuming, hence it is better to test multiple assumptions at once. `
    );
  }

  if (A3) {
    ret.push(
      `There is a clear connection between your assumptions and the core experience that you want to create for your user. Good job!`
    );
  }

  if (A4) {
    ret.push(
      `Some assumptions do not seem to be relevant to your experience.
Please reformulate the assumptions to make the connection with your experience more clear. `
    );
  }

  if (A5) {
    ret.push(`What is the connection between this assumption and your experience? Ideally, you would want your experience to be able to validate/invalidate  this assumption.
    Please go over your submission again and change them so that this connection is more clear.`);
  }

  return ret;
};
