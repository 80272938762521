import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No feedback`);
  }

  if (Z1) {
    ret.push(
      `Perfect submission! All of your personas are well described, contains several needs, behaviors, goals, and characteristics. This makes it so that they are very convincing and it becomes easier to step into their shoes and experience the world through their perspectives.
Ultimately this will help you create tailor-made products and services that generate a lot of value for your users by addressing their different needs and frustrations.`
    );
  }

  return ret;
};
