import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Perfect submission! Your persona was well described, contains several needs, behaviors, goals, and characteristics. This makes it so that your persona is very convincing, and it becomes easier to step into its shoes and experience the world through its perspective. Ultimately this will help you create tailor-made products and services that generate a lot of value for your users by addressing their needs and frustrations.`
    );
  }

  if (Z2) {
    ret.push(
      `Exceptional submission! Your submission went beyond the requirements that we required. Very well done! Your persona was well described, contains several needs, behaviors, goals, and characteristics. This makes it so that your persona is very convincing, and it becomes easier to step into its shoes and experience the world through its perspective. Ultimately this will help you create tailor-made products and services that generate a lot of value for your users by addressing their needs and frustrations.`
    );
  }

  return ret;
};
