import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, C1, C2, C3 } = getSelectedOptionsFlags(selectedOpts);

  if (C1) {
    ret.push(`Great job! You have made it very clear where you and your team will document the insights you identify during yoiur project.
Having such a structured approach and clear method will make it much easier for all of your team (and enterprise colleagues) to access this knowledge in the future, making it much more valuable.`);
  }

  if (C2) {
    ret.push(`Good job. You have stated where you will document the insights you and your team will develop as you progress. However, we believe that you should have been even more detailed about how exactly you will capture and store these insights.
Having such a structured approach and clear method will make it much easier for all of your team (and enterprise colleagues) to access this knowledge in the future, making it much more valuable.`);
  }

  if (C3) {
    ret.push(`You have not stated clearly where you will document the insights you and your team will develop as you progress. We believe that you should have been more detailed about how exactly you will capture and store these insights.
Having such a structured approach and clear method will make it much easier for all of your team (and enterprise colleagues) to access this knowledge in the future, making it much more valuable.`);
  }

  return ret;
};
