import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  /*
  if (true) {
    ret.push(`You will find your feedback below your submission for 3.4.b)`
    );
  }


  { id= "A1", text= "Improvement is clear, concise and works as a standalone sentence", points= 0.5 },
    { id= "A2", text= "Improvement is actually an improvement (clearly adds value)", points= 1 },
    { id= "A3", text= "Improvement is detailed/specific (they have pinpointed the issue)", points= 1 },
    { id= "A4", text= "Improvement is actionable (allows them to improve)", points= 1 },
    { id= "A5", text= "Good submission/reflection", points= 1 },

  */

  if (A1 && A3 && A4 && !A5 && !A7 && !A8) {
    ret.push(`Well done!
Your suggested improvement was not only very clear and easy to understand, but it is also detailed and actionable. This is important as it makes it clear for you, your team and anyone involved what exactly has to be improved and how you can do that.`);
  }

  if (A1 && !A3 && A4 && !A5 && !A7 && !A8) {
    ret.push(`Good job! Overall your improvement is well stated.
It is clear, easy to understand and actionable, which means that it lays out a clear plan that can be followed to be implemented.
However, your answer is not detailed enough. Your improvement should be at least one full sentence long.
Doing so will make it more likely that your improvement is implemented correctly and effectively.`);
  }

  if (A1 && A3 && !A4 && !A5 && !A7 && !A8) {
    ret.push(`Good job! Overall your improvement is well stated.
It is clear, easy to understand and specific, which means that it pinpoints the exact issue you want to tackle.
However, we feel like you could have made your suggestion a bit more actionable. Ideally, you should have laid out an action plan that can be followed to ensure that this improvement is implemented efficiently.
Doing so will make it more likely that your improvement will be well implemented.`);
  }

  if (!Z0 && !Z1 && !Z2 && !A3 && !A4 && !A5 && !A7 && !A8) {
    ret.push(`Your improvement is not detailed enough and lacks actionability.
In order to make the most of this exercise it is important that you take some minutes to reflect on this experience and come up with detailed and actionable improvements.
In the future, please make sure to elaborate more on your ideas.`);
  }

  if (A5) {
    ret.push(`Thank you for your answer.
However, it is not clear from your answer what exactly you would improve next time.
In the future, please make sure to give clear answers to such questions.
This will help you make more concrete learnings.`);
  }

  if (A7) {
    ret.push(`Thank you for your answer.
However, it seems like your improvement is only related to this program instead of your general interviewing skills and will not, unless you retake this module, help you in the future.
This is why we advise you to reflect more about this experience and try to make learnings that will help you in the future.`);
  }

  if (A8) {
    ret.push(`Thank you for your answer.
However, it is not clear why this would be an improvement.
As you interview more people in the future, please take time to reflect and describe possible improvements in more detail.
Doing so will help you assess how relevant this point really is and, ultimately, help you implement it as well.`);
  }

  return ret;
};
