import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
    const ret: string[] = [];
    const { D1, Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13, A14, A15, A16 } =
        getSelectedOptionsFlags(selectedOpts);

        /*
    {id = "#0", text = "----- ----- ----- ----- Internal stakeholders ----- ----- ----- ----- ----- ", points = 0},
    {id = "A0", text = "No stakeholders submitted", points = 0},
    {id = "A1", text = "Only one stakeholder", points = 0.1},
    {id = "A2", text = "2 - 3 stakeholders submitted", points = 0.3},
    {id = "A3", text = "4+ stakeholder submitted", points = 0.75},
    {id = "#1", text = "----- ----- ----- ----- Connected stakeholders ----- ----- ----- ----- ----- ", points = 0},
    {id = "A4", text = "No stakeholders submitted", points = 0},
    {id = "A5", text = "Only one stakeholder", points = 0.1},
    {id = "A6", text = "2 - 3 stakeholders submitted", points = 0.3},
    {id = "A7", text = "4+ stakeholder submitted", points = 0.75},
    {id = "#2", text = "----- ----- ----- ----- External stakeholders ----- ----- ----- ----- ----- ", points = 0},
    {id = "A8", text = "No stakeholders submitted", points = 0},
    {id = "A10", text = "Only one stakeholder", points = 0.1},
    {id = "A11", text = "2 - 3 stakeholders submitted", points = 0.3},
    {id = "A12", text = "4+ stakeholder submitted", points = 0.75},
    {id = "#4", text = "----- ----- ----- ----- For quality ----- ----- ----- ----- ----- ", points = 0},
    {id = "A17", text = "Detailed and well done", points = 1},
    {id = "A18", text = "okay", points = 0.5},
    {id = "A19", text = "below our expectations", points = 0},
    {id = "#3", text = "----- ----- ----- ----- Pick all that apply ----- ----- ----- ----- ----- ", points = 0},
    {id = "A9", text = "They did not specify real people as stakeholders (e.g 'Sales Managers' instead of Mark and Louise)", points = 0},
    {id = "A13", text = "They did not use all quadrants of the matrix to map their stakeholders", points = -1},
    {id = "A14", text = "They did not submit the power-interest matrix", points = -1},
    {id = "A15", text = "They did not submit the stakeholder map", points = -1},
    {id = "A16", text = "The answer was very detailed and well-thought", points = 0.75},

    */

    if (!Z0 && !Z1 && A14 &&!A15 && scoredPoints>0) {
        ret.push(
            `Thank you for sharing your stakeholder mapping.
Taking this step at the beginning of your projects will help you identify key stakeholders that you may need to keep informed, involve in your decisions and maybe even work with. 
Doing so will not only help you manage their expectations better, but it can also help you design better solutions and any resistance you may encounter in the future.`
        );
    }

    if (A14 && A15) {
        ret.push(
            `You did not submit neither your Stakeholder Map nor your Stakeholder Influence Matrix.
In the future, please make sure to follow the instructions of our assignments. This is the only way we can evaluate if you have understood the concepts of our program and are able to implement them correctly.`
        );
    }

    if (!Z0 && !Z1 && !A14 && A15 && scoredPoints>0) {
        ret.push(
            `Thank you for sharing your Stakeholder influence matrix.
Taking this step at the beginning of your projects will help you identify key stakeholders that you may need to keep informed, involve in your decisions and maybe even work with. 
Doing so will not only help you manage their expectations better, but it can also help you design better solutions and any resistance you may encounter in the future.`
        );
    }

    if (!Z0 && !Z1 && !A14 && !A15 && scoredPoints>0) {
      ret.push(
          `Thank you for sharing your Stakeholder Map and Influence Matrix.
Taking time to identify and evaluate your key stakeholders at the beginning of your projects will help you ensure that you keep the right people informed, and maybe even involved in your decisions. It will also help you understand whose feedback and suggestions you should pay more attention to.
Ultimately, this will not only help you manage your stakeholders expectations expectations better, but it can also help you design better solutions and reduce any resistance against your project that you may encounter in the future.`
      );
  }

    if (A0) {
        ret.push(
            `It seems that you haven't shared any internal stakeholders.
Please take some time to identify the relevant stakeholders and what interest and influence they have on your project.

Identifying and mapping your internal stakeholders early in the project is especially important because they typically have a strong level of involvement and interest. By assessing these stakeholders at the beginning of the project, you can determine who they are, what their needs are, and what concerns they may have. This information will enable you to address their needs and expectations early on and incorporate them into the project development, thus making it less likely that these key stakeholder pose issues down the line.`
        );
    }

    if (A1) {
        ret.push(`You have only identified one internal stakeholder.
Internal stakeholders are very relevant to your project as they are often directly affected by your project and can also have a huge impact on how your project develops!.
Identifying all key stakeholders early on can help you take their needs into perspective and avoid possible roadblocks in the future.
Therefore, we advise you to take even more time to think of more internal stakeholders before proceeding.`);
    }

    if (A2) {
        ret.push(
            `You have included an acceptable number of internal stakeholders.
However, we ask you to look for even more internal stakeholders in the future.
Identifying and mapping your internal stakeholders early in the project is especially important because they typically have a strong level of involvement and interest. By assessing these stakeholders at the beginning of the project, you can determine who they are, what their needs are, and what concerns they may have. This information will enable you to address their needs and expectations early on and incorporate them into the project development, thus making it less likely that these key stakeholder pose issues down the line.`
        );
    }

    if (A3) {
        ret.push(
            `You have included a good number of internal stakeholders.
Identifying and mapping your internal stakeholders early in the project is especially important because they typically have a strong level of involvement and interest. By assessing these stakeholders at the beginning of the project, you can determine who they are, what their needs are, and what concerns they may have. This information will enable you to address their needs and expectations early on and incorporate them into the project development, thus making it less likely that these key stakeholder pose issues down the line.      `
        );
    }

    if (A4) {
        ret.push(
          `No connected stakeholders submitted.
However, we hope that you at least took some time to think about all the relevant stakeholders and what interest and influence they have on your project. Connected stakeholders are also fundamental to your project as they can represent great bottlenecks and threats, but also opportunities.
Doing so will help you prioritize their desires and needs and facilitate a smooth development for your project.`
        );
      }
    
      if (A5) {
        ret.push(`You have identified only one connected stakeholder.
Connected stakeholders are also fundamental to your project as they can represent great bottlenecks and threats, but also opportunities.
Therefore, we advise you to take even more time to think of more connected stakeholders before proceeding.`);
      }
    
      if (A6) {
        ret.push(
          `You have included an acceptable number of connected stakeholders.
However, we advise you to take even more time to think of several connected stakeholders. Connected stakeholders are also fundamental to your project as they can represent great bottlenecks and threats, but also opportunities.
Doing so will help you prioritize their desires and needs and facilitate a smooth development for your project.`
    );
      }
    
      if (A7) {
        ret.push(
          `You have included a good number of connected stakeholders.
Connected stakeholders are also fundamental to your project as they can represent great bottlenecks and threats, but also opportunities.
Identifying them will help you prioritize their desires and needs and facilitate a smooth development for your project.`
        );
      }

      if (A8) {
        ret.push(
          `No external stakeholders submitted.
Still, we hope that you took some time to think about the these stakeholders and what interest and influence they may have on your project. 
While external stakeholders may often be less relevant than internal and connected stakeholders, they can sometimes still greatly affect the landscape in which you operate (I.e., by changing regulations, affecting public opinion or altering market trends).
Identifying relevant players at this level is therefore also quite important.`
        );
      }
    
      if (A10) {
        ret.push(`You have identified only one external stakeholder.
Still, we hope that you took some time to think about the these stakeholders and what interest and influence they may have on your project. 
While external stakeholders may often be less relevant than internal and connected stakeholders, they can sometimes still greatly affect the landscape in which you operate (I.e., by changing regulations, affecting public opinion or altering market trends).
Identifying relevant players at this level is therefore also quite important.`);
      }
    
      if (A11) {
        ret.push(
          `You have included an acceptable number of external stakeholders.
Still, we advise you to take time to think of more external stakeholders in your future projects.
While external stakeholders may often be less relevant than internal and connected stakeholders, they can sometimes still greatly affect the landscape in which you operate (I.e., by changing regulations, affecting public opinion or altering market trends).
Identifying relevant players at this level is therefore also quite important.`
    );
      }
    
      if (A12) {
        ret.push(
          `You have included a good number of external stakeholders.
While external stakeholders may often seem less relevant than internal and connected stakeholders, they can sometimes still greatly affect the landscape in which you operate (I.e., by changing regulations, affecting public opinion or altering market trends).
This is why we are happy to see that you have identified the relevant players at this level.`
        );
      }

    if (A13) {
        ret.push(`You did not use the whole space of your matrix.
Since this is a relative scale, we advise you to always spread out your entries as much as possible, as this will allow you to distinguish them better.
In your next exercises, please do your best to use the entire 2x2 Matrices.`);
    }


    if (A9) {
      ret.push(`One last comment: Please keep in mind that in a real project you should try to be more detailed.
So instead of saying "Sales Managers", try to really pinpoint who they are (e.g. Louise, Mark and Alex).
Your stakeholders should always be real people. This will allow you to "put a face to the name" and make it easier to identify their actual needs and pains.

Since this is not a real project and you can't know who these stakeholders are, these abstract terms like 'Managers' and 'Directors' will be fine for the purpose of this assignment.`);
  }

    return ret;
};
