import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for submitting a revised business model canvas!`);
  }

  if (A2) {
    ret.push(
      `Good work applying insights you gained from the first two parts of this activity in your revised business model canvas. The first two parts of this practical assignment were ways to reframe your business model to spark new ideas for business model innovation and reconfiguration. The ideas you came up with based on the first two prompts are a valuable inspiration in your revised business model.`
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `You did not include applications of the insights you gained from the first two parts of the activity in your revised business model canvas. The first two parts of this practical assignment were ways to reframe your business model to spark new ideas for business model innovation and reconfiguration. Return to these ideas and consider how you can incorporate them into your business model canvas.`
    );
  }

  if (A3) {
    ret.push(
      `Your revised business model canvas shows significant and creative changes in direction that have the potential to lead to business model innovation! You took advantage of the opportunity to rethink your business model in out-of-the-box ways that explore new opportunities. No matter how strong you consider your business model, in business model innovation, there is always room to reframe and think ahead to find unexpected solutions.`
    );
  }

  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `Your revised business model canvas did not show unexpected changes in direction, which does not mean that it is insufficient or bad. However, consider this activity as an opportunity to rethink your business model in out-of-the-box ways that explore new opportunities. No matter how strong you consider your original business model, there is always room to reframe and think ahead to find unexpected solutions.`
    );
  }

  if (A4) {
    ret.push(
      `However, it seems like you misinterpreted the meaning of "key activities".
These are not tasks that you have to perform to start this new business. Instead, these are the core activities your business will need to maintain a competitive advantage.
Please take a look at the lesson regarding the business model canvas (and its method cards) if this is still unclear.`
    );
  }

  if (A5) {
    ret.push(`No reference submitted.`);
  }

  if (A6) {
    ret.push(`Thank you for submitting your original business model canvas as a reference.`);
  }

  if (A7) {
    ret.push(`However, it seems that instead of using insights from both your business models (the original one and the adapted one) to create a more robust business model, you focused on the adapted one. This was not the purpose of this assignment.
The objective was for you to find weaknesses in your original business model by removing one of its key parts and then using the changes you made to overcome those weaknesses to create a new, revised, business model that is even better.
There is no need to change your canvas again, but for the future, please take this method into consideration when evaluating business models.
If this is still not clear to you, please do not hesitate to reach out to us at team@sparkademy.com.
    `);
  }

  return ret;
};
