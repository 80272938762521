import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, C1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (C1) {
    ret.push(
      `Thank you again for taking the time to reflect on your experience and sharing the outcomes with us!`
    );
  }

  return ret;
};
