import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`);
  }

  if (Z1) {
    ret.push(
      `Great job identifying two potential improvements!
Both improvements stated are clear, detailed, and will probably make your future interviews even better!
Well done! `
    );
  }

  if (Z2) {
    ret.push(
      `Amazing job!
Your submission went beyond the scope of what we expected for this exercise.
All of your improvements are very well explained and would certainly improve your interviewing skills even further.
Well done!`
    );
  }

  return ret;
};
