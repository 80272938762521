import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Very well done! Your problem statement is well stated and addresses a relevant need of your user. Also, the scope of the statement is just right! This will help you focus on the most important need of your user while not being too constrained in your ideation session. This is important because it will allow you to think of several relevant solutions.`
    );
  }

  return ret;
};
