import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`);
  }

  if (Z1) {
    ret.push(
      `Great job!
All of your learning notes include participant codes, present only one finding, and are in the right format (they are something you heard or observed during your interview and do not include your personal opinion).
This is super important as your learning notes should serve as a summary of your interviews. This will allow you to share and discuss your findings without any bias. `
    );
  }

  return ret;
};
