import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for stating your implementation plan. Thinking through how you will implement a chosen strategy for managing multiple business models is an important exercise in applying the concepts you learned about in this module to tackle one of the toughest challenges a company can take on.`
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `You did not state an implementation plan you can use for the strategy you chose in the previous question. Go back to the module and the method cards for insight into how you can design an effective implementation strategy that reduces risk over time and strengthens the business. Thinking through how you will implement a chosen strategy for managing multiple business models is an important exercise in applying the concepts you learned about in this module to tackle one of the toughest challenges a company can take on.`
    );
  }

  if (A2) {
    ret.push(
      `Good work creating ways to measure the success of your strategy in your implementation plan. It's important to set these measures to understand and communicate your goals as well as your progress towards them. Measures for success are a key part of the build, test, and implementation process of business model innovation.`
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `You did not include measures for the success of your strategy in your implementation plan. It's important to set these measures to understand and communicate your goals as well as your progress towards them. Return to the module to look at examples and use the method card to understand common measures for success for your chosen strategy. Measures for success are a key part of the build, test, and implementation process of business model innovation.`
    );
  }

  if (A3) {
    ret.push(
      `Your implementation plan is well thought out and realistic. You have demonstrated an understanding of the module content and applied it well in crafting your implementation plan so that you can follow through the build, test, and implement cycle and effectively manage both business models.`
    );
  }

  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `Your implementation plan could use further development to be realistically implemented at a company. It's important to be mindful of how you are reducing risk using the build, test, and implement cycle as well as to understand how you will allocate resources over time. Use the module or online resources to learn more about how companies implement similar strategies to manage multiple business models.`
    );
  }

  if (A4) {
    ret.push(
      `The measures for success you included in your implementation plan show a strong understanding of the concepts and draw on insights from the module. Good work applying what you've learned about business model innovation to the tough problem of managing 2+ business models!`
    );
  }

  if (!Z0 && !Z1 && A4 === false) {
    ret.push(
      `Make sure that the measures for success you include draw on concepts about business model innovation from the module. In order to have an innovative and successful strategy, you should follow the build, test, and implementation process of business model innovation.  Return to the module to look at examples and use the method card to understand common measures for success for your chosen strategy.`
    );
  }

  return ret;
};
