import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  if (A5) {
    ret.push(
      `You did not use your success criteria to confirm or reject your hypothesis.
The whole goal of this module is for you to develop a hypothesis based on an idea from M2 and then confirm or reject this hypothesis through a well-designed test. Therefore we ask you to take some time to reflect on the whole process so far and use the metrics you've developed earlier to decide if your hypothesis was correct or not.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A5 === false && A6) {
    ret.push(
      `We are happy to see that you managed to validate your hypothesis through your experiment.
Good job.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A5 === false && A7) {
    ret.push(
      `We are happy to see that you managed to reject your hypothesis through your experiment.
Successfully rejecting an idea might be just as valuable as confirming it. This will now help you decide your next step and pick another approach. Well done!`
    );
  }

  if (A1 && A2) {
    ret.push(
      `Thank you for stating your metrics from earlier and for answering if they were useful or not.
It is important to ask yourself this after completing tests. Doing so will help you evaluate better what metrics are relevant and which ones are not. In the long run, this will help you create better tests and learn more from them.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A1 && A2 === false) {
    ret.push(
      `Thank you for stating your metrics from earlier.
However, it is important to ask yourself if they were useful or not. Doing so will help you evaluate better what metrics are relevant and which ones are not. In the long run, this will help you create better tests and learn more from them.
Therefore, please think about the metrics you have defined at the beginning of M3 and ask yourself if you would pick the same metrics or not.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A1 === false && A2) {
    ret.push(
      `Good job. You have reflected on the metrics you stated before testing the prototype and stated if they were useful or not.
Doing so will help you evaluate better what metrics are relevant and which ones are not. In the long run, this will help you create better tests and learn more from them.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A1 === false && A2 === false) {
    ret.push(
      `You did not state your metrics from 2.5 and 2.5 again, nor did you mention if they were useful or not. Doing so will help you evaluate better what metrics are relevant and which ones are not. In the long run, this will help you create better tests and learn more from them.
Therefore, please think about the metrics you have defined at the beginning of M3 and ask yourself if you would pick the same metrics or not.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A3 === false) {
    ret.push(
      `None of your arguments were well supported by findings or quotes.
You have invested a lot of time in looking for such insights and findings. Now that you have them make sure to use them! Doing this will help you push an idea even if some people oppose it.`
    );
  }

  return ret;
};
