import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { B1, B2, B3 } = getSelectedOptionsFlags(selectedOpts);

  if (B1) {
    ret.push(`Moreover, you submitted a storyboard to illustrate your idea. Well done! Storyboards are powerful communication tools to show how an idea works and, importantly, the impact it can have on your user. We hope you continue using the tool  in the future!`);
  }

  if (B2) {
    ret.push(`Moreover, you submitted a storyboard to illustrate your idea. While this can be a powerful tool to communicate how an idea works, we suggest looking back at the story boarding method card to ensure you are getting the most value out of it. You could revise your story board to be more detailed and represent the effect the idea has on your user.`);
  }

  if (B3) {
    ret.push(`While it was not required for this assignment, we recommend also using a story board to represent your idea and how your user would experience it. Storyboards are powerful communication tools to show how an idea works and, importantly, the impact it can have on your user.`);
  }

  return ret;
};
