import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Good job! You have identified several options to transfer the selected risk. At this state of the process the goal is to get aware of all possible options before choosing. Even though your answer was sufficient for this assignment, never stop getting creative and thinking about additional solutions!`
    );
  }

  if (A2) {
    ret.push(
      `You have only identified one option to transfer the selected risk. Although this might be your preferred option, at this state of the process the goal is to get aware of all possible options before choosing. Try to get creative and think about additional solutions!`
    );
  }

  if (A4) {
    ret.push(
      `While you did come up with a good strategy to handle this risk, the goal in this exercise was for to find ways to shift the risk from your company to another party. By this we mean that you should, for now at least, not focus on reducing the probability of that risk happening or the damage it might cause.
Instead, you should ask yourself how you could find an agreement with another party so that they will take the damage in cas eit happens (E.g. via isnurance or hedging).
Later you will be able to compare all strategies you have identified and pick the ones you believe are more promising. But for now, please focus only on finding ways to pass this risk to another company.`
    );
  }

  return ret;
};
