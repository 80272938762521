import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, B0, B1, B2, B3, B4, B5, B6, B7 } = getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "#5", text = "----- ----- ----- ----- ----- Pick One ----- ----- ----- ----- ", points = 0},
    {id = "B0", text = "No submission", points = 0},
    {id = "B1", text = "Good feedback like in part a", points = 1.5},
    {id = "B2", text = "Good feeedback unlike part a", points = 1.5},
    {id = "B3", text = "Poor feedback like in part a", points = 0.5},
    {id = "B4", text = "Poor feedback unlike part a", points = 0.5},
  */

  if (B0) {
    ret.push(`No submission`);
  }

  if (B1) {
    ret.push(
      `Once more you have done a good job summarising and recording the feedback you have received.
Well done!`
    );
  }

  if (B2) {
    ret.push(
      `Unlike you submission in part a), this summary of the feedback you received is more detailed and valuable.
This will help you extract more learnings from this session and improve your idea.
Well done!`
    );
  }

  if (B3) {
    ret.push(
      `Just like in part a), the feedback recorded here is not detailed enough.
As you are developing a new solution tailored to your users' needs, it is crucial that you not only test your idea with users, but also make sure to make the most out of these tests.
Therefore, we advise you to prepare and record your feedback session better in the future.
This will help you make the most out of these tests.`
    );
  }

  if (B4) {
    ret.push(
      `Unlike your answer in part a), the feedback recorded here is less detailed.
As you are developing a new solution tailored to your users' needs, it is crucial that you not only test your idea with users, but also make sure to make the most out of these tests.
Therefore, we advise you to prepare and record your feedback session better in the future.
This will help you make the most out of these tests.`
    );
  }

  /* Old feedback
  if (B3) {
    ret.push(
      `We are happy to see that you have made several notes stating what worked, what could be improved, and open questions. It seems like this session was very fruitful!`
    );
  }

  if (!Z0 && !Z1 && B4 === false && !B3) {
    ret.push(
      `You did not include any notes regarding what worked. Was the whole prototype a failure?
Try to recall your session and think if there isn't at least one thing that went very well.`
    );
  }

  if (!Z0 && !Z1 && B5 === false && !B3) {
    ret.push(
      `Good job writing down several things that worked, open questions your user still has, and some new ideas on how to improve. This is a good sign that your feedback session was useful.

However, aren't there any improvements that you could make? Your submission does not include any issues or possible improvements. Please take some time to reflect again on the feedback session you had (if possible, go over your notes or recording) and think if there isn't anything you could do to improve the prototype in its next iteration according to this user.`
    );
  }

  if (!Z0 && !Z1 && B6 === false && !B3) {
    ret.push(
      `You have written down several comments regarding things that worked, possible improvements and suggestions from your test user. This is a good sign that your feedback session was effective.

However, weren't there any open questions after your feedback session? Maybe some functionality that was unclear or something that might be superfluous in your prototype.
You don't have to have open questions after your sessions, but it is very common that you may have an idea or hypothesis that still needs to be validated. And it is a good idea always to write those down, as they can help you build even better products. Therefore, we advise you to go over your notes/recordings and think if there is anything you might have missed.`
    );
  }

  if (!Z0 && !Z1 && B7 === false && !B3) {
    ret.push(
      `You have taken several notes regarding things that worked well during your feedback session, possible improvements for your idea/prototype and open questions that still must be addressed. This is a good sign that your feedback session was effective.

However, you did not write down any possible new features or ideas. This is not a crucial field to fill out, but it is common that people have new ideas when they are confronted with constructive feedback. To make sure that these are not lost after the feedback session, it is always a good idea to write them down immediately. Therefore, we would just like to remind you to pay attention to these ideas as they may disappear just as fast as they show up. `
    );
  }

  if (!Z0 && !Z1 && B1 === false) {
    ret.push(`No file uploaded`);
  }
  */

  return ret;
};
