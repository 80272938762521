import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A0, A1, A2, A3, A4, A5, A6 } = getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "#4", text = "------------------------ Pick all that apply ------------------------", points = 0},
    {id = "A1", text = "Materials and their cost mentioned ", points = 1},
    {id = "A2", text = "Acquired services and their cost mentioned", points = 1},
    {id = "A3", text = "Additional items included with their cost", points = 0},
    {id = "#5", text = "------------------------ Pick one ------------------------", points = 0},
    {id = "A4", text = "More than 6 items listed", points = 2},
    {id = "A5", text = "3 - 6 items listed ", points = 1},
    {id = "A6", text = "Less than 3 items listed", points = 0},
  */

if (A0) {
ret.push(
`You have not submitted any answer to this question.
Still, we would like to ask you to at least take a few minutes to reflect on this.`
);
}
  
if (A1) {
ret.push(
`Good work! Your answer demonstrates your understanding of the relationship between cost and value generated in your project. You gave a clear explanation as to why the cost is appropriate for the value generated. Being able to communicate the connection between value and cost to leadership will help get investment for your project.`
);
}

if (A2) {
ret.push(
`Good work. Your answer demonstrates your understanding of the relationship between cost and value generated in your project. You gave a clear explanation as to why the cost is not appropriate for the value generated. Being able to see a discrepancy between cost and value generated will help you not only make changes to how you budget the project, but also help you communicate the need for alignment to team members and leadership.`
);
}

if (A3) {
ret.push(
`Thank you for reflecting on the relationship between cost of your project and the value it generates. However, your answer was not detailed enough to communicate why or why not the value and cost of your project are aligned. Clearly communicating the connection between cost and value is vital to convince leadership to invest in your project. We recommend crafting a more thorough explanation.`
);
}

if (A4) {
ret.push(
`You have also mentioned several unforeseen costs that could come up during your project. You did a good job by thinking about these costs because no project goes exactly according to plan, and it is best to consider different scenarios, especially when it comes to cost. Well done!`
);
}

if (A5) {
ret.push(
`You have also mentioned one unforeseen costs that could come up during your project. While it's good you came up with this item, you could likely come up with more if you spend more time thinking about it. No project goes exactly according to plan, and it is best to consider different scenarios, especially when it comes to cost. `
);
}

if (A6) {
ret.push(
`You did not list any unforeseen costs that could come up during your project. Even if it seems you have accounted for everything in your original estimate, no project goes exactly according to plan. It is best to consider different scenarios and identify areas that could lead to unexpected costs to prepare yourself.`
);
}

  return ret;
};
