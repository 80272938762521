import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Thank you for defining the success criteria for your metrics. This will help you to assess whether your project is on track, is suceeding your expectations, or falling behind. All of your KPIs can be measured and assessed on a regular basis.`
    );
  }

  if (Z2) {
    ret.push(
      `Well done! Thank you for defining the success criteria for your metrics. This will help you to assess whether your project is on track, is suceeding your expectations, or falling behind. All of your KPIs can be measured and assessed on a regular basis.`
    );
  }

  return ret;
};
