import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A3) {
    ret.push(
      `Very well done!
We are happy to see how you did more than just what was required in this assignment!

Now, pease keep in mind that although we asked you to perform only two interviews of 30, in reality two interviews are not enough for an effective user research.
Depending on the project you are working on we would often suggest you to conduct at least 10 to 15 interviews with interesting stakeholders (normal users, extreme user, non-users and others).

Also, as you may have noticed, 30 minutes is not enough for a proper interview. For the purpose of this program, where the objective is to learn how to become more user-centric and not to come up with great solutions to a challenge, 30 minutes was enough.
But, in your future projects, we suggest you always schedule interviews of at least one hour.`
    );
  }

  if (A2) {
    ret.push(
      `Good job conducting these two interviews.
We hope you enjoyed them!

Now, pease keep in mind that although we asked you to perform only two interviews of 30, in reality two interviews are not enough for an effective user research.
Depending on the project you are working on we would often suggest you to conduct at least 10 to 15 interviews with interesting stakeholders (normal users, extreme user, non-users and others).

Also, as you may have noticed, 30 minutes is not enough for a proper interview. For the purpose of this program, where the objective is to learn how to become more user-centric and not to come up with great solutions to a challenge, 30 minutes was enough.
But, in your future projects, we suggest you always schedule interviews of at least one hour.`
    );
  }

  if (A0) {
    ret.push(`Thank you for letting us know that you were only able to conduct one interview.
Make sure to reach out to your colleagues and ask them to share their notes with you.
The findings made in these interviews are essential for the work you will do in the next modules.
Also, we strongly advise you to conduct some interviews in te following days. It is not only really interesting and fun to converse with someone you don't, but it is also a great way to practice this super relevant skill!

Also, pease keep in mind that although we asked you to perform only two interviews of 30, in reality two interviews are not enough for an effective user research.
Depending on the project you are working on we would often suggest you to conduct at least 10 to 15 interviews with interesting stakeholders (normal users, extreme user, non-users and others).

Lastly, as you may have noticed, 30 minutes is not enough for a proper interview. For the purpose of this program, where the objective is to learn how to become more user-centric and not to come up with great solutions to a challenge, 30 minutes was enough.
But, in your future projects, we suggest you always schedule interviews of at least one hour.`);
  }

  if (A1) {
    ret.push(
      `Only had time for one interview?Make sure to reach out to your colleagues and ask them to share their notes with you.
The findings made in these interviews are essential for the work you will do in the next modules.
Also, we strongly advise you to conduct some interviews in te following days. It is not only really interesting and fun to converse with someone you don't, but it is also a great way to practice this super relevant skill!

Also, pease keep in mind that although we asked you to perform only two interviews of 30, in reality two interviews are not enough for an effective user research.
Depending on the project you are working on we would often suggest you to conduct at least 10 to 15 interviews with interesting stakeholders (normal users, extreme user, non-users and others).

Lastly, as you may have noticed, 30 minutes is not enough for a proper interview. For the purpose of this program, where the objective is to learn how to become more user-centric and not to come up with great solutions to a challenge, 30 minutes was enough.
But, in your future projects, we suggest you always schedule interviews of at least one hour.`
    );
  }

  return ret;
};
