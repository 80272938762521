import * as React from "react";
import { Box, Heading, Text } from "theme-ui";
import { useHistory } from "react-router-dom";
import { ButtonGhost } from "@sparkademy/app-common/elements/ButtonGhost";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { ExerciseSubmission } from "@sparkademy/app-common/models/assignment";
import { ModuleId, ModuleOrderMap } from "@sparkademy/app-common/models/module";
import { dateFormatter } from "@sparkademy/app-common/utils/date-formatters";
import { useGradingContext } from "../contexts/grading-context";
import { GradingService } from "../services/grading";

const GradingTable: React.FC<{ assignmentSubmissions: ExerciseSubmission[]; readOnly: boolean }> =
  ({ assignmentSubmissions, readOnly }) => {
    const { setSubmission } = useGradingContext();
    const { currentUser } = useSessionContext();
    const history = useHistory();

    if (assignmentSubmissions.length === 0) {
      return <Text>There is no data to show.</Text>;
    }

    const gridProps = {
      display: "grid",
      gridTemplateColumns: "repeat(5, 1fr)",
    };

    const headerProps = {
      ...gridProps,
      p: "24px 0 18px 48px",
      color: "white",
      bg: "black",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      gridColumn: "1 / span 5",
    };

    const bodyProps = {
      ...gridProps,
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      gridColumn: "1 / span 5",
    };

    const getRowProps = (idx: number) => ({
      ...gridProps,
      gridColumn: "1 / span 5",
      p: "14px 0 13px 48px",
      bg: idx % 2 === 0 ? "white" : "new.secondary.lightGrey",
      borderBottomLeftRadius: idx === assignmentSubmissions.length - 1 ? "10px" : 0,
      borderBottomRightRadius: idx === assignmentSubmissions.length - 1 ? "10px" : 0,
    });

    const onSubmissionClicked = async (submission: ExerciseSubmission) => {
      setSubmission(submission);
      GradingService.lockForGrading(submission.id, currentUser!)
        .then(() => history.push(`/submission/${submission.id}`))
        .catch(() => window.alert("Exercise have already been taken for grading."));
    };

    return (
      <Box
        sx={{
          border: "1px solid",
          borderColor: "new.secondary.grey",
          borderRadius: "10px",
        }}
      >
        <Box sx={headerProps}>
          <Heading as="h4" sx={{ gridColumn: 1 }}>
            {currentUser?.role === "admin" ? "Name" : "User ID"}
          </Heading>
          <Heading as="h4" sx={{ gridColumn: 2 }}>
            Module
          </Heading>
          <Heading as="h4" sx={{ gridColumn: 3 }}>
            Exercise
          </Heading>
          <Heading as="h4" sx={{ gridColumn: "4 / span 2" }}>
            Date Submitted
          </Heading>
        </Box>

        <Box sx={bodyProps}>
          {assignmentSubmissions.map((as, idx) => (
            <Box key={idx} sx={getRowProps(idx)}>
              <span sx={{ gridColumn: 1 }}>
                {currentUser?.role === "admin" ? as.user_name : as.user_id}
              </span>
              <span sx={{ gridColumn: 2 }}>{as.module_id}</span>
              <span sx={{ gridColumn: 3 }}>{as.exercise_id}</span>
              <span sx={{ gridColumn: 4 }}>
                {as.submitted_at && dateFormatter.format(new Date(as.submitted_at))}
              </span>

              {(!readOnly ||
                as.grading_locked_by === currentUser?.data.id ||
                currentUser?.role === "admin") && (
                <ButtonGhost
                  sx={{
                    gridColumn: 5,
                    textAlign: "center",
                    fontSize: "16px",
                    p: 0,
                    minHeight: "auto",
                  }}
                  onClick={() => onSubmissionClicked(as)}
                >
                  Grade
                </ButtonGhost>
              )}
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

export const GradingTab: React.FC<{ assignmentSubmissions: ExerciseSubmission[] }> = ({
  assignmentSubmissions,
}) => {
  const gradingSubmissions = assignmentSubmissions.filter(as => !!as.grading_locked_by);
  const toBeGradedSubmissions = assignmentSubmissions.filter(
    as => !as.grading_locked_by && !as.graded_at
  );

  const assignmentCompare = (el1: ExerciseSubmission, el2: ExerciseSubmission) => {
    if (el1.module_id !== el2.module_id) {
      return ModuleOrderMap[el1.module_id as ModuleId] - ModuleOrderMap[el2.module_id as ModuleId];
    } else if (el1.exercise_id !== el2.exercise_id) {
      return parseInt(el1.exercise_id.split("ex")[1]) - parseInt(el2.exercise_id.split("ex")[1]);
    } else {
      return el1.submitted_at!.getTime() - el2.submitted_at!.getTime();
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          textAlign: "left",
          borderTop: "1px solid",
          borderTopColor: "new.secondary.grey",
          width: "100%",
        }}
      >
        <Heading sx={{ py: "48px" }} as="h3">
          To Grade
        </Heading>
        <GradingTable
          assignmentSubmissions={toBeGradedSubmissions.sort(assignmentCompare)}
          readOnly={false}
        />
      </Box>

      <Box
        sx={{
          mt: "48px",
          textAlign: "left",
          borderTop: "1px solid",
          borderTopColor: "new.secondary.grey",
          width: "100%",
        }}
      >
        <Heading sx={{ py: "48px" }} as="h3">
          Grade In Progress
        </Heading>
        <GradingTable assignmentSubmissions={gradingSubmissions} readOnly={true} />
      </Box>
    </React.Fragment>
  );
};
