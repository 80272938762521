import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push("Thank you for submitting your selected hypothesis.");
  }
  if (A1 && !A2) {
    ret.push(`We believe that your assumption could have been stated better.
Try to answer why (or why not) your user would like or use this product.
Focusing on the why might lead to a more relevant assumption`);
  }

  if (A1 && !A3) {
    ret.push(`Your test might not be well suited to test your assumption.
Please make sure to improve your test so that it helps you validate your key assumption.
Your test should be as simple as possible, but still allow your participants to interact with it.`);
  }

  if (A1 && !A4) {
    ret.push(`Are you sure that these are the best metrics to track during your test?
Please keep in mind that good criteria should be quantifiable, easy to measure and in line with your prototype and test.`);
  }

  if (A1 && !A5) {
    ret.push(`Your success criteria could be improved.
A good criteria should be clear and unique (not several different metrics).
Furthermore, it should allow you and your team to compare different performances and easily (in)validate your assumption.`);
  }

  return ret;
};
