import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Good job in implementing all the elements of a survey. All the questions are open ended which will make it easy for you to analyze and quantify the data. This will also ensure that the number of people that will fill out the survey will also be higher. Also, congratulations on framing a straight forward survey. You have avoided the use of jargon, advanced concepts, or abbreviations and made it easy for the participants to understand. All your questions are unbiased and non leading. This will give you true quantitative data which can be used. `
    );
  }

  return ret;
};
