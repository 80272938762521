import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `You stated and explained a strategy that is well suited to the conflicts and relatedness between your two business models. You demonstrated an understanding of the concepts presented in the modules and thorough thought into which strategy fits your business models the best. Determining how to manage multiple business models is a difficult task, you have done the groundwork of creating an effective strategy and you're ready to figure out how to implement it!`
    );
  }

  return ret;
};
