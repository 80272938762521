import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const {
    Z0,
    Z1,
    Z2,
    Z3,
    B1,
    B2,
    B3,
    B4,
    B5,
    B6,
    B7,
    B8,
    B9,
    B10,
    B11,
    B12,
    B13,
    B16,
    B17,
    B18,
    B19,
  } = getSelectedOptionsFlags(selectedOpts);

  if (B1) {
    ret.push(`Well done.
Your user journey includes a clear description of your persona's goals.
Even though this might have been taken straight from the description of your persona from earlier, it is important to state that again in your user journey as this will help contextualize your persona and assess better how it might experience your solution.`);
  }

  if (!Z0 && !Z1 && B1 === false) {
    ret.push(`Your user journey does not include a clear description of your persona's goals.
Even though this might have been taken straight from the description of your persona from earlier, it is important to state that again in your user journey as this will help contextualize your persona and assess better how it might experience your solution.`);
  }

  /*
  if (B1 && B2) {
    ret.push(`Well done.
Your user journey includes a clear description of the goals and and expectations of your persona.
Even though this was taken straight from the description of your persona from earlier, it is important to state that again in your user journey as this will help contextualize your persona and assess better how it might experience your solution.`);
  }

  if (B1 && !B2) {
    ret.push(`Good job.
You have clearly stated the relevant goals your persona has.
Even though this was taken straight from the description of your persona from earlier, it is important to state that again in your user journey as this will help contextualize your persona and assess better how it might experience your solution.
However, to do an even better job, it would have been best if you had included some of their expectations as well.
This can also be taken from the previous exercise and will help you create an even better piicture of your persona.`);
  }

  if (!B1 && B2) {
    ret.push(`Good job.
You have clearly stated the relevant expectations your persona has.
Even though this was taken straight from the description of your persona from earlier, it is important to state that again in your user journey as this will help contextualize your persona and assess better how it might experience your solution.
However, to do an even better job, it would have been best if you had included some of its relevant goals as well.
This can also be taken from the previous exercise and will help you create an even better piicture of your persona.`);
  }

  if (!Z0 && !Z1 && B1 === false && B2 === false) {
    ret.push(`Thank you for submitting this user journey.
While you have included several relevant informations, you did not include the relevant goals and expectations your persona has (relateed to your product).
Even though this can be  taken straight from the description of your persona from earlier, it is important to state that again in your user journey as this will help contextualize your persona and assess better how it might experience your solution.`);
  }

  if (B3) {
    ret.push(
      `Well done!
You have splitten your user journey into three main parts: "Pre-experience", "The Experience" and the "Post-experience".
While the experience itself is obvios and usually included, we are happy to see how you also included the prior and post experience.
Doing so will help you ensure that your solution covers all needs that may arise throughout the whole interaction with your customers and will also help you create more loyal clients as your relationship does not start only when they buy the service/product nor will it end after they purchase it.`
    );
  }

  if (B4) {
    ret.push(
      `Your pre-experience is missing.
It is very important to include not only the experience your user will have while using your product/service in your user journey, but also the pre-experience.
By this we mean everything that happened before the user interacted with you. What where the touchpoints, the needs and the furstrations?
Focusing on the touchpoints, how exactly did this person get to your solution? What might she have heard, seen or done?
Try to pin-point differnt actions that led her to your business.
Doing so might help you optimizie and streamline this process later.`
    );
  }

  if (B5) {
    ret.push(
      `Your main experience is missing.
Once someone acquires your product or service, what exactly happens to that user?
How does he use your product, what are the touchpoints that connect him with your business. What are the issues and emotions he may go through?
Try to think of the whole journey from receiving the product to unpacking and trying it out for the first time. It's important to really step into your personas shoes when doing that.
A senior citizen might have a much different experience unboxng and setting up their new phone when compared to a tech-savyy teenager.
As you can imagine, there is no correct answer here. The goal is for you to try to visualize the whole experience they have with your product so that you can personalize and optimize it.`
    );
  }

  if (B6) {
    ret.push(`Your post-experience is missing.
Once someone has bought your product or enjoyed yoour service, how do they proceed?
Are there any additional touchpoints that connect them to your business?
Are there any needs or frustrations they may encounter? Try to go into detail and think of thinks like, how will they rpair or dispose of your product?
Doing so will help you create a more wholesome experience for your users which might increase customer loyalty and brand awereness amongst others.`);
  }

  if (B8) {
    ret.push(`Well done.
You have included a good amount of touchpoints to visuallze how exactly this user will be notified, contacted or brought together with your business.`);
  }

  if (!Z0 && !Z1 && B8 === false) {
    ret.push(`You did not include enough touchpoints to your user journey.
AS these touchpoints summarise all the interactions your user will have with your business (word of mouth, webiste, referal, etc) these are very important.
Therefore, we adivse you to think strongly about each step of your user journey and think what additional interaction could be displayed.
Identifying them will help you and your team tailor your brand better to your users.`);
  }

  if (B9) {
    ret.push(
      `You have included and described well a good number of behaviors and thoughts your user may present during his user journey. Good job.`
    );
  }

  if (!Z0 && !Z1 && B9 === false) {
    ret.push(`Your user journey would have been better if you had putten more emphasis on the different behaviours and thoughts your user might have throughout his user journey.
Doing so will help you identify better any painpoints and habits your could use to personalize and improve that experience for this type of user.`);
  }
*/
  if (B11) {
    ret.push(
      `You have clearly highlighted the emotional experience of the persona thoughout its customer journey. Well done!`
    );
  }

  if (!Z0 && !Z1 && B11 === false) {
    ret.push(`You did not highlight the emotional experience of the persona well enough.
Try to really pinpoint what went well and what went poorly during all the steps of the journey.
Focus especially on the feelings and emotions the persona might feel during these steps.`);
  }
  /*
  if (B12) {
    ret.push(`Lastly, you did a good job including a space of opportunities.
This is very important as the ultimate goal of a user journey is to identify opportunities to improve your value proposition and identify other needs, behaviors and frustations previously unknown.
Therefore, it is crucial that you use all the insights generated during your user journey to come up with valuable and actionable opportunities for your team.`);
  }

  if (!Z0 && !Z1 && B12 === false) {
    ret.push(`You did not incliude a cler space of opportunities for you an your team.
This is very important as the ultimate goal of a user journey is to identify opportunities to improve your value proposition and identify other needs, behaviors and frustations previously unknown.
Therefore, it is crucial that you use all the insights generated during your user journey to come up with valuable and actionable opportunities for your team.`);
  }
*/

  /*
   */

  if (B16) {
    ret.push(
      `Great job! You have covered multiple experiences that your user will go through in their customer  journey. `
    );
  }

  if (B17) {
    ret.push(
      `Are these the only main experiences that the user will go through in the customer journey? Try to cover all the experiences starting from when the customer becomes aware of the product to sustaining their loyalty with you`
    );
  }

  if (B18) {
    ret.push(
      `You have stated only one experience that the user will go though. Try to cover all the experiences starting from when the customer becomes aware of the product to sustaining their loyalty with you`
    );
  }

  return ret;
};
