import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `We could not find any submission for this exercise. However, we hope that you at least took some time to think about all the relevant stakeholders and what interest and influence they have on your project. Doing so will help you prioritize their desires and needs and facilitate a smooth development for your project.`
    );
  }

  if (Z1) {
    ret.push(`Thank you for your great submission!

You have managed to identify several relevant stakeholders and estimated their level of power and interest reasonably. This is very important as this will help you prioritize their interests and concerns.
Very influential (high power) stakeholders should be closely involved in the decision-making process as you work on this project.
At the same time, very interested parties could be kept in the loop and notified of advancements, problems, and overall milestones as well as invited to interviews, workshops and experiments.

This prioritization is also very useful when different stakeholders have opposing opinions on how you should proceed with your project.
As a rule of thumb, it makes sense to look for a conciliatory solution. However, if you have to tend to one side, it is a good idea to address the most influential stakeholder's concerns first.

Lastly, you should also always consider the interplay between level of power and level of interest. An individual with a high power level will be even more relevant if he/she is also very interested.

Again, very well done, and keep up this good work!`);
  }

  return ret;
};
