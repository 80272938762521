import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  /*
  if (true) {
    ret.push(`You will find your feedback below your submission for 3.4.b)`
    );
  }


  { id= "A1", text= "Improvement is clear, concise and works as a standalone sentence", points= 0.5 },
    { id= "A2", text= "Improvement is actually an improvement (clearly adds value)", points= 1 },
    { id= "A3", text= "Improvement is detailed/specific (they have pinpointed the issue)", points= 1 },
    { id= "A4", text= "Improvement is actionable (allows them to improve)", points= 1 },
    { id= "A5", text= "Good submission/reflection", points= 1 },

  */

  if (A1 && A3 && A4) {
    ret.push(`Very well done!
Your first suggested improvement was not only very clear and easy to understand, but it is also  very detailed and actionable. This is very important as it makes it clear for you, your team and anyone involved what exactly has to be improved and how you can do that.`);
  }

  if (A1 && !A3 && A4) {
    ret.push(`Good job! Overall your first improvement is well stated.
It is clear, easy to understand and actionable, which means that it lays out a clear plan that can be followed to be implemented.
However, we feel like you could have been a little more specific when pinpointing the exact issue you want to tackle.
Doing so will make it more likely that your improvement is implemented correctly and effectively.`);
  }

  if (A1 && A3 && !A4) {
    ret.push(`Good job! Overall your first improvement is well stated.
It is clear, easy to understand and specific, which means that it pinpoints the exact issue you want to tackle.
However, we feel like you could have made your suggestion a bit more actionable. Ideally, you should have laid out an action plan that can be followed to ensure that this improvement is implemented efficiently.
Doing so will make it more likely that your improvement will be well implemented.`);
  }

  return ret;
};
