import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2 && A3) {
    ret.push(
      `Very well done!
You have made it very clear what your idea is all about, for whom you are developing it, and managed to explain it in a very clear way.`
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `You did not make it very clear what exactly your idea is. Is it a product? Is it a service or an experience? Try to pin down exactly what your idea is all about. This will help you convey your message and focus on your key issues.`
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `You did not state your target user. It is essential to know exactly who your user is. This is what will allow you to make tailored solutions that will add a lot of value for your users.`
    );
  }

  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `You were too brief in the explanation of your idea. Please make sure to go through its key features and characteristics so that the idea is very clear not only to you, but also to those reading it. Doing this will help you find any issues with your idea and reflect on its effectiveness. `
    );
  }

  return ret;
};
