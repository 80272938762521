import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13, A14, A15 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A5) {
    ret.push(
      `You have identified several weak points in your business model. Identifying and understanding where each inconsistency lies is an important step to evaluating and communicating where your business needs to grow and improve in order to reach its innovative potential.`
    );
  }

  if (A10) {
    ret.push(
      `You have only identified one weak point in your business model. Identifying and understanding where each inconsistency lies is an important step to evaluating and communicating where your business needs to grow and improve in order to reach its innovative potential. We strongly advise you to reflect on your business model, its stakeholder and its characteristics and look for more weak points.`
    );
  }

  if (!Z0 && !Z1 && A5 === false && A10 === false) {
    ret.push(
      `It seems that you did not identify any inconsistencies or weak points in your business model. Identifying and understanding where each inconsistency lies is an important step to evaluation and communicating where your business needs to grow and improve in order to reach its innovative potential.We strongly advise you to reflect on your business model, its stakeholder and its characteristics and look for several weak points. Doing this will help you mitigate them early on (which is much cheaper and easier)`
    );
  }

  if (A7 && A5) {
    ret.push(
      `You have provided great explanations as to why these in inconsistencies and weak-points could be relevant. Good job! 
Identifying and assessing your business model's possible weak-points early will allow you change and improve your business model while it is still easy and cheap to do so. The more you wait, the harder it will be to adapt your business model. So we are happy to see you have already done so!`
    );
  }

  if (A7 && A10) {
    ret.push(
      `You have provided a good explanation as to why this inconsistencies could be relevant. Good job!
Identifying and assessing your business model's possible weak-points early will allow you change and improve your business model while it is still easy and cheap to do so. The more you wait, the harder it will be to adapt your business model. So we are happy to see you have already done so!`
    );
  }

  if (A8) {
    ret.push(
      `Your explanation as to why these inconsistencies could be relevant should be better.
Identifying and assessing your business model's possible weak-points early will allow you change and improve your business model while it is still easy and cheap to do so. The more you wait, the harder it will be to adapt your business model. So we advise to spend a few more minutes working on this before proceeding.`
    );
  }

  if (A9) {
    ret.push(
      `You did not provide explanations as to why these inconsistencies are relevant. 
Identifying and assessing your business model's possible weak-points early will allow you change and improve your business model while it is still easy and cheap to do so. The more you wait, the harder it will be to adapt your business model. So we advise to spend a few more minutes working on this before proceeding.`
    );
  }

  if (A4 || A6) {
    ret.push(
      `Your answer was not detailed enough.
In the future, please write out your answers with more details. Doing so will help us understand, assess and evaluate your submissions better.

Identifying and assessing your business model's possible weak-points early will allow you change and improve your business model while it is still easy and cheap to do so. The more you wait, the harder it will be to adapt your business model. So we advise to spend a few more minutes working on this before proceeding.`
    );
  }

  return ret;
};
