import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, B0, B1, B2, B3, B4, B5, B6, B7, B8, B9, B10 } =
    getSelectedOptionsFlags(selectedOpts);

  if (B0) {
    ret.push(`No second improvement stated.`);
  }

  if (B10) {
    ret.push(
      `This answer has the same issues as the ones mentioned above. Please make sure to spend a few minutes to evaluate what exactly you could do differently to improve your interviews in the future.`
    );
  }

  if (B1 && B3 && B4 && !B5 && !B6 && !B7 && !B8 && !B9) {
    ret.push(`Well done!
Your suggested improvement was not only very clear and easy to understand, but it is also detailed and actionable. This is important as it makes it clear for you, your team and anyone involved what exactly has to be improved and how you can do that.`);
  }

  if (B1 && !B3 && B4 && !B5 && !B6 && !B7 && !B8 && !B9) {
    ret.push(`Good job! Overall your improvement is well stated.
It is clear and easy to understand and actionable, which means that it lays out a clear plan that can be followed to be implemented.
However, your answer is not detailed enough. Your improvement should be at least one full sentence long.
Doing so will make it more likely that your improvement is implemented correctly and effectively.`);
  }

  if (B1 && B3 && !B4 && !B5 && !B6 && !B7 && !B8 && !B9) {
    ret.push(`Good job! Overall your improvement is very well stated.
It is clear and easy to understand and specific, which means that it pinpoints the exact issue you want to tackle.
However, we feel like you could have made your suggestion a bit more actionable. Ideally, you should have laid out an action plan that can be followed to ensure that this improvement is implemented efficiently.
Doing so will make it more likely that your improvement will be well implemented.`);
  }

  if (!Z0 && !Z1 && !Z2 && !B0 && !B3 && !B4 && !B5 && !B6 && !B7 && !B8 && !B9 && !B10) {
    ret.push(`Your improvement is not detailed enough and lacks actionability.
In order to make the most of this exercise it is important that you take some minutes to reflect on this experience and come up with detailed and actionable improvements.
In the future, please make sure to elaborate more on your ideas.`);
  }

  if (B5 && !B6 && !B7 && !B8 && !B9) {
    ret.push(`Thank you for your answer.
However, it is still not clear what exactly you would improve next time.
In the future, please make sure to give clear answers to such questions.
This will help you make more concrete learnings.`);
  }

  if (B6 && !B9) {
    ret.push(`Your second improvement is similar to your first one mentioned above.
In order to find more actionable learnings we advise you to look for other aspects that could be improved.
Doing this will help you improve more after each interview conducted.`);
  }

  if (B7 && !B9) {
    ret.push(`Thank you for your answer.
However, it seems like your improvement is only related to this program instead of your general interviewing skills and will not, unless you retake this module, help you in the future.
This is why we advise you to reflect more about this experience and try to make learnings that will help you in the future.`);
  }

  if (B8 && !B9) {
    ret.push(`Thank you for your answer.
However, it is not clear why this would be an improvement.
As you interview more people in the future, please take time to reflect and describe possible improvements in more detail.
Doing so will help you assess how relevant this point really is and, ultimately, help you implement it as well.`);
  }

  if (B9) {
    ret.push(`Good job. This improvement, like the one above, is clear and actionable.
Just make sure to implement it in your next user research!`);
  }

  return ret;
};
