import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Your team came up with too few ideas during your ideation session.
Ideally, you would like to get at least 20 different ideas. Keep in mind that we are in a divergent stage right now. This means that you should aim for quantity and not quality.

Is it possible that your team was concerned with quality and did not mention several ideas because of that?
Or, are you sure that all of your participants were feeling comfortable and motivated?
Look back at the session you organized and think about ways to improve their collaboration and creativity. Check the course content and method cards for some suggestions.`
    );
  }

  if (A2) {
    ret.push(
      `Your team could have come up with more ideas during your ideation session.
Ideally, you would like to get at least 20 different ideas. Keep in mind that we are in a divergent stage right now. This means that you should aim for quantity and not quality.

Is it possible that your team was concerned with quality and did not mention several ideas because of that?
Or, are you sure that all of your participants were feeling comfortable and motivated?
Look back at the session you organized and think about ways to improve their collaboration and creativity. Check the course content and method cards for some suggestions.`
    );
  }

  if (A3) {
    ret.push(
      `Your team came up with a good number of ideas during your ideation session.
This is a sign that you've done a good job finding participants with different perspectives and managed to motivate and engage them well. `
    );
  }

  if (A4) {
    ret.push(
      `Your ideation session has generated several ideas!
This is a good sign that your preparation and execution were very good.
Great job!`
    );
  }

  return ret;
};
