import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A0 === false && A4 === false && A5 === false) {
    ret.push(`No picture uploaded.`);
  }

  if (A0 && A1 === false && A2 === false && A4 === false && A5 === false) {
    ret.push(`Thank you for uploading your cluster-wall`);
  }

  if (A1 === false && A0 && A4 === false && A5 === false) {
    ret.push(
      `While you did upload a picture of your wall, we could not identify your clusters' structure.
Ideally, we would have liked to see your learning notes (either in paper or digital form) clustered together by topic or finding. Ensuring that the clustering is very clear, will make it easier for your whole team (and even outsiders) to understand your process.`
    );
  }

  if (A0 && A1 && A2 && A4 === false && A5 === false) {
    ret.push(
      `Thank you for uploading a picture of your cluster wall.
The clustering of your findings is very clear, and we can see that the cluster titles and summaries were included directly on your wall. Well done!`
    );
  }

  if (A0 && A1 && A2 === false && A4 === false && A5 === false) {
    ret.push(
      `Thank you for uploading a picture of your cluster wall.
The clustering of your findings is very clear and logical. However, we could not see the cluster titles for each of your clusters. We recommend you to write those directly at your cluster wall (and next to the cluster itself) from now on.
This makes the final solution very clear for all your team and ensures that everyone agrees on the clusters and their summaries.`
    );
  }

  if (A4) {
    ret.push(`Thank you for submission.
However, it seems like you clustered your learnings by interview instead of clustering them by theme as requested.

How did you arrive at the themes stated in the exercise 5.1?
The idea of this clustering is for you to place similar learnings together and point out what theme they all have in common. This structuring will help you evaluate the data better and allow you to extract insights to all themes.
Otherwise, there is a chance that some relevant learnings/themes are forgotten.
This is why we ask you to always cluster your themes into different topics at this stage of your user research in the future.`);
  }

  if (A5) {
    ret.push(`Thank you for submission.
However, it seems like you clustered your learnings by team member instead of clustering them by theme as requested.

How did you arrive at the themes stated in the exercise 5.1?
The idea of this clustering is for you to place similar learnings together and point out what theme they all have in common. This structuring will help you evaluate the data better and allow you to extract insights to all themes.
Otherwise, there is a chance that some relevant learnings/themes are forgotten.
This is why we ask you to always cluster your themes into different topics at this stage of your user research in the future.`);
  }

  if (A6) {
    ret.push(`Lastly, we would like to point out that we were expecting more learning notes in your cluster wall.
Are these really all the findings extracted by all of your team members?

Please note that in the future, you should cluster all the findings from all of your interviews at this stage.
Yes, we know that this may entail moving many learning notes around, but the discussions that arise from this will help you ensure that all of your learnings are included here.
This will help you extract the most insights possible.`);
  }

  return ret;
};
