import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Good job identifying some ways to generate income through your innovation project during the course of the project. `
    );
  }

  if (A2) {
    ret.push(
      `You have thought of only one way of generating income. It is a good start, but think of multiple ways you can do this. This will help have multiple options open based on the course of the project. `
    );
  }

  if (A3) {
    ret.push(
      `You were not able to identify any possible income within the next 12 month. Although that might seems like the only choice you have, there are certainly opportunities to generate income even though your new idea is not final. You could run a pilot with your team, give out early versions for a very low price to get your first real customers, etc. Get creative, there is always a possibiity to gain value from a not yet final project!`
    );
  }

  if (A6) {
    ret.push(
      `You did not specify exactly how your project will earn this revenue. In order to generate more reliable estimates for your project's revenue it is important to break all of your cash flows down and identify how much each of them can generate and how this revenuee might change as your project matures.`
    );
  }

  if (A4 && (A1 || A2)) {
    ret.push(
      `The revenue streams you shared are innovative and show a good understanding of how your project is going to run.`
    );
  }

  if (A4 && A3) {
    ret.push(
      `However, the ideas you came up with are innovative, different and show a good understanding of how your project is going to run. Good job`
    );
  }

  if (A4 === false && (A1 || A2)) {
    ret.push(
      `On the other hand, you should put in a little more thought on making the ideas more innovative and adaptable to the project. We advise you to go over your Business Model Canvas and think of more ideas that affect different elements of your canvas.`
    );
  }

  if (A4 === false && (A3 || A6)) {
    ret.push(
      `Additionally, you should put in a little more thought on making the ideas more innovative and adaptable to the project. We advise you to go over your Business Model Canvas and think of more ideas that affect different elements of your canvas.
Try to think of at least one more innovative way to generate income.`
    );
  }

  if (A7) {
    ret.push(
      `Lastly, you did not give an estimate of your expected income. Even though this number might not be 100% accurate, it is important to try to come up with rough estimates.
Doing so will help you budget your project better and help you visualize any financing issues you might encounter earlier. `
    );
  }

  if (A6) {
    ret.push(
      `You did not specify exactly how your project will earn this revenue. In order to generate more reliable estimates for your project's revenue it is important to break all of your cash flows down and identify how much each of them can generate and how this revenuee might change as your project matures.`
    );
  }

  return ret;
};
