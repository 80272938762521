import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for stating the opportunity you would like to move forward with. Focusing on one opportunity to improve the customer journey  is a good way scope your work and pin point the impact you would like to have.`);
  }

  if (A2) {
    ret.push(`You have also explained why this opportunity is an important to improving the user experience by referencing the moment it takes place in the journey. Whether at the beginning, middle, or end, it can have an impact on your user.`);
  }

  if (A3) {
    ret.push(`However, you did not explain why this is an important opportunity to improve the user experience. It is unclear to use why this is a relevant area of the user journey to work on improving or the impact it would have on your user. Please provide an explanation in the future.`);
  }

  return ret;
};
