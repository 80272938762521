import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(` `);
  }

  return ret;
};
