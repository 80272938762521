import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Great report and summary, both from a visual and storytelling side, as well as the content.
As your initial problem statement, you state: How might we create dairy alternatives that meet
consumer expectations in taste and texture?
After your interviews and understanding your users more closely, you should be able to define a more targeted problem statement that helps your team to move into ideation. You did so well within your different Personas. Well done!`);
  }

  return ret;
};
