import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`The learning card is very similar to the stage-gate discovery you have in the report. The important question to answer is: Is there a clear problem to be solved and is the problem big enough?
You answered those questions in the report, but I recommend you to summarize them in an overview page, e.g. in the format of the learning card for your next project. Having the key information on one page makes it easier to update stakeholders than giving them a 50 page report, that they might not fully read and miss important information.`);
  }

  return ret;
};
