import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(`Well done!
We are happy to see how your experiment was fruitful and how your team managed to make so many learnings.

Regardless of whether you have validated or invalidated your hypothesis, the sheer amount of new learnings and insights made have already shown that this experiment was successful. Think of how much better you understand your user, their problem and your solution after this very short and cheap experiment.
The goal now is to keep testing, learning and improving your idea. This is what prototyping is all about!`);
  }

  return ret;
};
