import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A3 && !A6) {
    ret.push(`Thank you for stating several ideas as asked.
This helps us assess the breadth of thinking achieved in your ideation session and evaluate the quality of your session.`);
  }

  if (A2 && A3 === false && !A6) {
    ret.push(
      `Good job! You have presented and explained your favorite ideas very well. They are very clear and easy to understand.`
    );
  }

  if (A6 && A2 === false && A3 === false) {
    ret.push(`Well done. All your ideas are unique and original.
This seems to imply that your brainstorming session was very effective and helped your participants identify different solutions. `);
  }

  if (A0 && A6) {
    ret.push(
      `However, some of your ideas are not stated clearly.
Please make sure to explain them in a way that they are clear for everyone. Later you will present these ideas to people that did not participate in your ideation session. It is important that these people understand your ideas well so that their feedback is effective.`
    );
  }

  if (A0 && A6 === false) {
    ret.push(
      `Furthermore, some of your ideas are not stated clearly.
Please make sure to explain them in a way that they are clear for everyone. Later you will present these ideas to people that did not participate in your ideation session. It is important that these people understand your ideas well so that their feedback is effective.`
    );
  }

  if (A1 && A6) {
    ret.push(
      `However, some of your ideas could be stated more clearly.
Please make sure to explain them in a way that they are clear for everyone. Later you will present these ideas to people that did not participate in your ideation session. It is important that these people understand your ideas well so that their feedback is effective.`
    );
  }

  if (A1 && A6 === false) {
    ret.push(
      `Furthermore, some of your ideas could be stated more clearly.
Please make sure to explain them in a way that they are clear for everyone. Later you will present these ideas to people that did not participate in your ideation session. It is important that these people understand your ideas well so that their feedback is effective.`
    );
  }

  if (A4) {
    ret.push(`You did not submit 5 ideas as asked.
In the future we ask you to comply with all the criteria stated in the exercises.
This will make sure that you make the most out of each exercise and will help us evaluate all the criteria intended.`);
  }

  if (A5 === false && A6 === false && !Z1 && !Z2 && !Z0) {
    ret.push(`It seems like some of your ideas are very similar and overlap in a way or another.
This is not necessarily a bad thing, but it would have been better if you would state different solutions that try to create value by focusing on different needs/frustrations or by approaching the issue through different perspectives.`);
  }

  if (A6 && (A3 || A2)) {
    ret.push(`Thank you for stating several ideas as asked.
This helps us assess the breadth of thinking achieved in your ideation session and evaluate the quality of your session.

We are happy to see that your submitted ideas are all unique and original.
This seems to imply that your brainstorming session was very effective and helped your participants identify different solutions.  `);
  }

  return ret;
};
