import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const {
    C1,
    C2,
    C3,
    C4,
    C5,
    C6,
    C7,
    C8,
    C9,
    C10,
    C11,
    C12,
    C13,
    C14,
    C15,
    C16,
    C17,
    C18,
    C19,
    C20,
    C21,
    C22,
    C23,
    C24,
    C25,
    C26,
    C27,
  } = getSelectedOptionsFlags(selectedOpts);

  /*
{id = "#23", text = "----- ----- ----- ----- For the SAME FEEDBACK as previously (a or b) ----- ----- ----- ----- ----- ", points = 0},
{ id= "C10", text= "Good answer as in point a)", points= 1.25},
{ id= "C11", text= "Not constraining as in point a)", points= 0.75},
{ id= "C12", text= "Not 'Outside-of-the-box' as in point a)", points= 0.75},
{ id= "C13", text= "Again asks 'How would XYZ solve this problem' (e.g., "How would Elon Musk or Apple solve this problem?")", points= 1 },
{ id= "C14", text= "Again, not a constraint, but something stimulating (e.g., you have all the money in the world)", points= 1 },
{ id= "C15", text= "Again ss asking participants for answers. E.g 'How would you do it?'", points= 0.5},
{ id= "C16", text= "Again constraint points to a solution (How can we use VR to...)", points= 0.6},
{ id= "C17", text= "Again we don't understand the constraint", points= 0.3},
{id = "#32", text = "----- ----- ----- ----- For a DIFFERENT FEEDBACK (not presented in part a or b) ----- ----- ----- ----- ----- ", points = 0},
{ id= "C1", text= "Good constraint that pushes participants to think outside the box", points= 1.25},
{ id= "C2", text= "Is a constraint but does not push participants to think outside the box", points= 0.75 },
{ id= "C3", text= "Statement is not a constraint", points= 0.25 },
{ id= "C5", text= "How would XYZ solve this problem (e.g., "How would Elon Musk or Apple solve this problem?")", points= 1 },
{ id= "C6", text= "Not a constraint, but something stimulating (e.g., you have all the money in the world)", points= 1 },
{ id= "C7", text= "Is asking participants for answers. E.g 'How would you do it?'", points= 0.5},
{ id= "C8", text= "Constraint points to a solution (How can we use VR to...)", points= 0.6},
{ id= "C9", text= "We don't understand the constraint", points= 0.3},
*/

  if (C1) {
    ret.push(
      `Nice choice on the constraint! It is a good fit for the problem at hand and will help your participants focus on what really matters for your users!
Additionally, by narrowing down the possible solutions, it will help your participant to think outside the box and come up with fresh new ideas. Good job!`
    );
  }

  if (C2) {
    ret.push(
      `While your constraint does reduce take away some of the possible solutions to your problem, it does not really push your participants to think outside the box.
In order to get the most out of your constrained brainstorming you should try to find constraints that force your participants to think of alternative solutions that go beyond "normal" approaches.
This will foster innovation by pushing them to think outside the box.`
    );
  }

  if (C3) {
    /* Not sure if I should leave !A3 here */
    ret.push(
      `Thank you for your answer.
However, this is not really a constraint as it cannot be used to "eliminate" certain solutions.
In order to get the most out of your constrained brainstorming you should try to find constraints that force your participants to think of alternative solutions that go beyond "normal" approaches.
This will foster innovation by pushing them to think outside the box.`
    );
  }

  if (C5) {
    ret.push(
      `Thank you for stating the third constraint you would use to stimulate your participants.
Although this is not really a constraint (as it does not limit the ideas you can come up with) it can increase your participant's creativity by getting them to look at the problem through someone else's perspective.
You can therefore use this as your opening 'constraint', just make sure to also include actual constraints later.`
    );
  }

  if (C6) {
    ret.push(
      `Thank you for stating the third constraint you would use to stimulate your participants.
Although this is not really a constraint (as it does not limit the ideas you can come up with) it can increase your participant's creativity by removing obstacles and helping them think of alternative solutions.
You can therefore use this as your opening 'constraint', just make sure to also include actual constraints later.`
    );
  }

  if (C7) {
    ret.push(
      `Thank you for stating the third constraint you would use to stimulate your participants.
Unfortunately, we need to point out that this is not really a constraint, as it does not limit the solutions you can come up with. Instead, you are simply asking your participants for suggestions and answers to your problem.

This is not the goal of a constraint brainstorming. The goal is to create additional requirements to your solutions that exclude very common and obvious solutions. Thus pushing them to be creative and think outside the box.
A good way to come up with effective constraints is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible. This is a constraint you could use in your workshop!
For example, if many of your solutions require an app to function, your third constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (C8) {
    ret.push(
      `Thank you for stating the third constraint you would use to stimulate your participants.
However, while this constraint does limit the scope of the solutions your participants can come up with, it is limiting the breadth of possibilities towards one type solution. This in turn, might restrict your users too much and take away the possibility for alternative solutions to be discussed.
Therefore, we recommend you to use your constraints to push your participants to think creatively instead.`
    );
  }

  if (C9) {
    ret.push(
      `We are not sure if we understood your constraint correctly.
A constraint should be a clear requirement that restricts the type of solutions your participants can present.

In other words, the goal of a constrained brainstorm is to create additional requirements to your solutions that exclude very common and obvious solutions. Thus pushing them to be creative and think outside the box.
A good way to come up with effective constraints is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible. This is a constraint you could use in your workshop!
For example, if many of your solutions require an app to function, your third constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (C10) {
    ret.push(
      `Thanks for sharing your last constraint! Once more, it will definitely help your participants come up with unique solutions.
Well done!`
    );
  }

  if (C11) {
    ret.push(
      `Thank you for your last constraint.
However, once more it is not a proper constraint as it doesn't provide clear requirement for the ideas your participants may think of.
To maximize the benefits of constrained brainstorming, aim for constraints that challenge participants to think beyond typical solutions and foster innovation.`
    );
  }

  if (C12) {
    ret.push(
      `Thanks for your last constraint. However, once more it may not be pushing your participants to think creatively. To make the most of your session, try to come up with constraints that challenge participants to think beyond typical solutions.`
    );
  }

  if (C13) {
    ret.push(
      `Thanks for sharing the last constraint.
However, again it is not a proper constraint as it doesn't restrict the solutions that can be generated. It can enhance their creativity by prompting them to see the problem from a different perspective, just like the previous one.
Since you have already used a "stimulating constraint" in your earlier answer, please come up with an actual constraint for the workshop this time. Refer to the earlier feedback for an example.`
    );
  }

  if (C14) {
    ret.push(
      `Thanks for sharing your last constraint.
However, once more it is not a proper constraint as it doesn't restrict the solutions that can be generated. Instead, it may enhance creativity by removing obstacles and help focus on important aspects.
As you've already used a "stimulating constraint" before, please come up with an actual constraint for the workshop this time. Refer to earlier feedback for examples.`
    );
  }

  if (C15) {
    ret.push(`Thanks for sharing your final constraint.
However, again it doesn't restrict solutions and is more of a suggestion than a constraint. To achieve the goals of constrained brainstorming, establish additional requirements that eliminate typical and obvious answers and encourage more creative thinking.`);
  }

  if (C16) {
    ret.push(
      `Thank you for sharing your last constraint.
However, again you have used constraints to focus on one type of solution.
Like mentioned before, please do not use this constraint in your brainstorming session as pushes your participants to think of only one type of solution.
(Check our previous feedback for more details).`
    );
  }

  if (C17) {
    ret.push(`Once more we are not certain if we understood your constraint correctly.
Please check our previous feedback above to see what the goal of this assignment was.`);
  }

  return ret;
};
