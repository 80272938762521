import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(`Thank you for submitting five feedbacks as asked.
They are all very detailed and include relevant information that you should try to use to improve your idea even further.
Ideally, you should try to contact these users again after implementing some of the feedback received to see if your changes have fulfilled their needs and concerns.
Keep in mind that this is an iterative process and that it is imporatnt to constantly validate new learnings as you proceed.`);
  }

  return ret;
};
