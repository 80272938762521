import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Good job in stating the time duration of the experience. Knowing this will help you plan how you can create an impact on the user in that time frame. `
    );
  }

  if (Z2) {
    ret.push(
      `Even though you have mentioned a time frame, it seems too much. A user usually spends less time on a particular experience. Please rethink the experience you stated, consider splitting it into several parts, and redesigning it for this assignment.`
    );
  }

  return ret;
};
