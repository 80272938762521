import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Very well done! You have chosen very good success criteria that can be determined very well and is also strongly related to the key elements of your idea. This is very important as this metric will be the ultimate deciding factor to assess whether or not your hypothesis is true.`
    );
  }

  return ret;
};
