import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for the great submission.
We are happy to see how you and your team have reflected on this.
It is very important to constantly update the responsibilities, threats and issues in your team.
Doing so will make it less likely that issues result from not having clear responsibilities or inefficiencies arise from too much overlapping work. `
    );
  }

  if (A2) {
    ret.push(
      `Thank you for your good submission.
We are happy to see how you and your team have reflected on this.
It is very important to constantly update the responsibilities, threats and issues in your team.
Doing so will make it less likely that issues result from not having clear responsibilities or inefficiencies arise from too much overlapping work. `
    );
  }

  if (A3) {
    ret.push(
      `Thank you for your submission.
We advise you and your team to reflect more on this topic.
It is very important to constantly check the responsibilities, threats and issues in your team.
Doing so will make it less likely that issues result from not having clear responsibilities or inefficiencies arise from too much overlapping work. `
    );
  }

  if (A4) {
    ret.push(
      `Thank you for your submission.
We believe you and your team should reflect more on this topic.
It is very important to constantly check the responsibilities, threats and issues in your team.
Doing so will make it less likely that issues result from not having clear responsibilities or inefficiencies arise from too much overlapping work. `
    );
  }

  if (A5) {
    ret.push(
      `Thank you for your submission.
We believe that the overlap between your positions was too small.
If you really believe that this graphic represents your team well, then we recommend you try to integrate all the different roles more. Otherwise your team will work with little cooperation and this might lead to problems in the future.`
    );
  }

  return ret;
};
