import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);
  /*
    { id = "A1", text = "Says there has never been such a situation", points = 0 },
    { id = "A2", text = "The user gives at least one example of something that he would do differently", points = 1.5 },
    { id = "A3", text = "Great answer", points = 1.5 },
    */
  if (!Z0 && !Z1 && A1) {
    ret.push(
      `Even if you could not identify a situation in the prior exercise, it would still be a good practice for you to think of one situation, ist resulting pitfall and then a possible solution for that pitfall. Check our M4 method cards for some more information and insights.`
    );
  }

  if (!Z0 && !Z1 && !A1 && A2 === false && !A6) {
    ret.push(
      `It is not clear how you could avoid such a pitfall. As it is very easy to enter such a pitfall, one should always think of ways to avoid it. Try thinking of even more ways if possible. Try checking our method cards for some inspiration!`
    );
  }

  if (!A1 && A2) {
    ret.push(
      `Well done! You have given a good suggestion on how to avoid this pitfall in the future.
As it is very easy to enter such a pitfall, one should always think of ways to avoid it. Try thinking of even more ways if possible and don't forget to check our method cards for some inspiration.`
    );
  }

  if (A4) {
    ret.push(
      `While the situation you mentioned above was not caused by a decision-making pitfall, your suggestion here does help you avoid similar situations in the future.`
    );
  }

  if (A6) {
    ret.push(`Even if that pitfall was not ideal, we agree that this would help you avoid it.`);
  }

  if (true) {
    ret.push(`Before we let you go, we would like to congratulate you for completing the last practical assignment of this program's concluding module!
We know that this has been an intensive learning journey, but we hope it has been an enjoyable one, equipping you with valuable concepts and tools to enhance your innovation and user-centric approach in future endeavors!`);
  }

  if (A7) {
    ret.push(`To ensure you feel fully confident in applying the methods covered throughout the program, we're pleased to offer you an opportunity to schedule a one-on-one consultation with one of our experts. During this call, feel free to ask any questions related to the program, or seek advice on any projects you're currently working on. We will be sending you further details regarding this call via email in the coming days.

Once again, thank you for participating in this program, and congratulations on your outstanding achievement!`);
  }

  return ret;
};
