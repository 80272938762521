import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A7, A6 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && (!A2 || !A3 || !A4)) {
    ret.push(
      `Good job! You have included the potential sales and incomes you identified in the previous exercise. `
    );
  }

  if (A2 && (!A1 || !A3 || !A4)) {
    ret.push(
      `You have also added the needed investments to have your project running throughout the 12 month. `
    );
  }

  if (A3 && (!A1 || !A2 || !A4)) {
    ret.push(
      `Additionally, you have included the human resource costs that you worked on in exercise 1.`
    );
  }

  if (A4 && (!A1 || !A2 || !A3)) {
    ret.push(
      `And finally, next to the other elements of the cash flow forecast, you added the material and inventory purchases for the next twelve months of your project. `
    );
  }

  if (A1 && A2 && A3 && A4) {
    ret.push(
      `Great job! You created a good projected cash flow statement and filled in all the details a project at your state can give.`
    );
  }

  if (A6) {
    ret.push(
      `Unfortunately, you did not fill in the forecast for all of the upcoming months. Even though it might feel difficult, try to think ahead!`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A1 === false) {
    ret.push(
      `Thank you for submitting your cash flow forecast! Unfortunatly, you did not enter the sales or other forms of income within the forcast. You should have though about this information in exercise 1 of this assignment.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A2 === false) {
    ret.push(
      `In your forecast, you did not enter the needed investment to run your project for 12 month. This can usually be identified once you filled in all the other information. Alternatively, it might be that you get a fixed budget or investment at the project start that you have to stay within.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A3 === false) {
    ret.push(
      `Try to include more information about the human resource costs. How much does it cost to have your team working on the project?`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A4 === false) {
    ret.push(
      `Your forecast is lacking information about the material in inventory purchased. Look at exercise 1 again and use the information you came up with!`
    );
  }

  if (A7) {
    ret.push(
      `One thing , however, that is worth noting is that your projected cash flow for the first month is positive. This is not impossible, but unlikely as it will probably take your business some time to generate positive operating results since you are launching a new business.`
    );
  }

  return ret;
};
