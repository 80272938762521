import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, C1, C2, C3, C4, C5, C6, C7, C8 } = getSelectedOptionsFlags(selectedOpts);

  if (C1 ) {
    ret.push(``
    );
  }

  if (!Z0 && !Z1 && C1 === false) {
    ret.push(``
    );
  }

  if (C4 && (!C1 || !C5)) {
    ret.push(``
    );
  }

  return ret;
};
