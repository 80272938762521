import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A11, A12 } = getSelectedOptionsFlags(selectedOpts);

    if (A1) {
      ret.push(
        `Your survey is professional and appealing. You did well in including elements like a starting page with an introduction, an informed consent statement, and a closing page that thanks the participants. While these elements are not central to testing your assumptions, they improve the user experience of your survey, making participants more like to complete it and feel positively about it.`
      );
    }
  
    if (A2) {
      ret.push(
        `You included a couple of elements like a starting page with an introduction, an informed consent statement, and a closing page that thanks the participants. It would be best if you could include all of them. While these elements are not central to testing your assumptions, they improve the user experience of your survey, making participants more like to complete it and feel positively about it. `
      );
    }
  
    if (A3) {
      ret.push(`You did not include any elements like a starting page with an introduction, an informed consent statement, and a closing page that thanks the participants. While these elements are not central to testing your assumptions, they improve the user experience of your survey, making participants more like to complete it and feel positively about it. We recommend including these in your future surveys.`);
    }
  
    if (A4) {
      ret.push(
        `Nicely done framing a straightforward survey. You have avoided the use of jargon, advanced concepts, or abbreviations and made it easy for the participants to understand. All your questions are unbiased and non-leading. This will give you true quantitative data which can be used.`
      );
    }
  
    if (A5) {
      ret.push(
        `Some of your questions are not simple and easy to understand. Make sure you avoid the use of jargon, advanced concepts, or abbreviations and frame questions in a straightforward and non-lead way. This will give you more reliable data.`
      );
    }
  
    if (A6) {
      ret.push(
        `Good job at keeping most questions closed-ended. This will make it easy for you to analyze and quantify the data. The number of people that will fill out the survey will also be higher. `
      );
    }

    if (A7) {
      ret.push(
        `It looks like most of your questions are open-ended. This will make it harder for you to analyze and quantify the data. We recommend that 90% of the questions be closed ended for a survey. `
      );
    }

    if (A8) {
      ret.push(
        `However, the length of your survey might be too long. A good rule to remember is 30 secs per question for closed ended questions. To keep the survey not longer than 7-8 minutes, total of 15 questions or less should be suitable.`
      );
    }

    if (A9) {
      ret.push(
        `Good job in implementing all the elements of a survey. All the questions are closed-ended which will make it easy for you to analyze and quantify the data. This will also ensure that the number of people that will fill out the survey will also be higher. Also, congratulations on framing a straight forward survey. You have avoided the use of jargon, advanced concepts, or abbreviations and made it easy for the participants to understand. All your questions are unbiased and non-leading. This will give you true quantitative data which can be used.`
      );
    }

    if (A11||A12) {
      ret.push(
        `Before launching your survey, please go over these suggestions as we believe they might help you improve your survey:
- Clear and Concise Questions: Avoid jargon and keep questions brief.
- Logical Flow: Start with general questions, then move to specifics. Use branching logic.
- Limit Open-ended Questions: Use multiple-choice or scales for quicker responses.
- Ensure Anonymity and Privacy: Communicate confidentiality and comply with data regulations.
- Test Before Launching: Refine the survey with a test group before full release.`
      );
    }

  return ret;
};
