import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, E1, E2, E3, E4, E5, E6, E7, E8 } = getSelectedOptionsFlags(selectedOpts);

  if (E1 ) {
    ret.push(`Thank you for submitting your protocol here.`
    );
  }

  return ret;
};
