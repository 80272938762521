import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13, A14, A15 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A1 && A3 && A5 && A7 && A9) {
    ret.push(
      `Well done!
The main body of your interview guideline contains only excellent questions. They are all open-ended, non-leading, only ask for one thing at a time, do not ask for solutions, and are all clear and easy to understand.`
    );
  }

  if (Z0 === false && Z1 === false && scoredPoints < 5 && !(A1 && A3 && A5 && A7 && A9)) {
    ret.push(
      `Your core interview questions are, in general, very good. There are only some questions that could be changed or at least rephrased. You will find more details on what to improve below.`
    );
  }

  if ((A2 || (Z1 === false && A1 === false)) && Z0 === false) {
    ret.push(
      `The core section of your interview guideline is the most important part. It is where you will ask all the relevant questions to discover the interviewee's needs and pain points. You should make sure to write down a lot of questions. Even if you don't think you will have time to ask all of them, having several questions on standby will allow you to steer the interview towards a direction that suits you and can help when you do go through all of your main questions (otherwise you would have to end the interview or think of good questions on the spot). That is why we recommend you to write down at least ten good questions here, although more are even better.`
    );
  }

  if ((A4 || (Z1 === false && A3 === false)) && Z0 === false) {
    ret.push(
      `Not all of your questions are open-ended. Even if you have follow-up questions ready, you should, as a rule of thumb, avoid closed-ended questions. These questions will reduce the length of the answers you receive and make it harder for you to make many findings. Consequently, we recommend you to only prepare clear open-ended questions.`
    );
  }

  if ((A6 || (Z1 === false && A5 === false)) && Z0 === false) {
    ret.push(
      `Some of your questions are somewhat suggesting a certain answer or assuming a certain situation. It is super important that all the questions you ask are biased-free. This is one of the main reasons why we even prepare an interview guideline. To make sure that the questions we ask are not biased.
The goal of the user research is to extract the needs and pain points of your users. Leading questions can severely affect your findings and bias your results. Therefore, we recommend you to take a look at your questions and rewrite the ones that lead the user to answer in a certain way.
An example of such a question would be: Do you also dislike this feature? This can be transformed into: How do you feel about this feature? `
    );
  }

  if ((A8 || (Z1 === false && A7 === false)) && Z0 === false) {
    ret.push(
      `Some of your questions ask more than one thing. Questions that ask for several things are usually more complicated and can be harder to understand. This could give you poor answers, as maybe someone would have answered differently if he had understood all parts of the question. These questions, therefore, not only give results that are not reliable it also damages the flow of the conversation. A good interview should be very causal so that the participant doesn't think too much before he answers. Hard questions can remove the interviewees from that state. Thus, we advise you to break down these more complex questions into a few simple and clear ones.`
    );
  }

  if ((A10 || (Z1 === false && A9 === false)) && Z0 === false) {
    ret.push(
      `Some of your questions require your participants to imagine situations or suggest solutions to theoretical situations.
You can do this, but it is not an optimal approach as the answers you get are not very reliable and may give you a solution before you even fully understand the problem.
For example, you could ask a participant for their opinion and, depending on his mood or recent history, he might give you two completely different answers. Furthermore, the solution you may get might be ideal for this participant, but not for others. This is we advise you to identify only needs and pain points for now.
You will have plenty of time for brainstorming and testing solutions later!`
    );
  }

  if (A11 && scoredPoints >= 5) {
    ret.push(
      `Furthermore, we really liked the structure of your submission and how you added questions to each topic!
Well done!`
    );
  }

  if (A11 && scoredPoints < 5) {
    ret.push(
      `On the other hand, we really liked the structure of your submission and how you added questions to each topic!
Well done!`
    );
  }

  if (A14 && !A15) {
    ret.push(
      `We would like to point out that you did not really prepare any questions that can help you uncover relevant stories related to your challenge.
As a rule of thumb you should always try to ask some questions asking your interviewee to share a certain experience or situation. Doing so will help you uncover better their true feelings and help you identified the underlying reasons behind their thoughts and behaviors.`
    );
  }

  if (A15) {
    ret.push(
      `Most of all, we would like to point out that you did not really prepare any questions that can help you understand your users better. The main goal of User Discovery is help you to deeply understand your users, their needs and issues. This is what will allow you to create better solutions. Therefore, before conducting your interview, make sure so add questions that help you understand your users better.
Try to focus on their behaviours and use many follow-up questions to understand why they do and think what they do.
As a rule of thumb you should always try to ask some questions asking your interviewee to share a certain experience or situation. Doing so will help you uncover better their true feelings and help you identified the underlying reasons behind their thoughts and behaviors.`
    );
  }

  if (A12 && !A13 && A1 && A3 && A5 && A7 && A9) {
    ret.push(
      `Furthermore we liked how you prepared more questions than just what we have required.
You will probably not be able to ask all of them in a 30 minute session, but it will help you select the best questions for each interview session.
Good job!`
    );
  }

  if (A13 && !A12 && A1 && A3 && A5 && A7 && A9) {
    ret.push(
      `However we would like to point out how you did not include enough follow-up questions.
Doing so will allow you to dig deeper during your interviews by asking for details, stories, or further information.
Often, simply asking why?/why not? elicits more relevant information and helps to get closer to real needs and pain points. Having those follow-up questions prepared and on your interview guideline makes is easier to include them in the actual interview.`
    );
  }

  if (A13 && A12 && !(A1 && A3 && A5 && A7 && A9)) {
    ret.push(
      `Lastly, while we liked how you prepared more questions than just what was required, we would like to point out how you did not include enough follow-up questions.
Doing so will allow you to dig deeper during your interviews by asking for details, stories, or further information.
Often, simply asking why?/why not? elicits more relevant information and helps to get closer to real needs and pain points. Having those follow-up questions prepared and on your interview guideline makes is easier to include them in the actual interview.`
    );
  }

  if (A13 && !A12 && !(A1 && A3 && A5 && A7 && A9)) {
    ret.push(
      `Lastly, we would like to point out how you did not include enough follow-up questions.
Doing so will allow you to dig deeper during your interviews by asking for details, stories, or further information.
Often, simply asking why?/why not? elicits more relevant information and helps to get closer to real needs and pain points. Having those follow-up questions prepared and on your interview guideline makes is easier to include them in the actual interview.`
    );
  }

  if (A13 && A12) {
    ret.push(
      `Lastly, while we liked how you prepared more questions than just what was required, we would like to point out how you did not include enough follow-up questions.
Doing so will allow you to dig deeper during your interviews by asking for details, stories, or further information.
Often, simply asking why?/why not? elicits more relevant information and helps to get closer to real needs and pain points. Having those follow-up questions prepared and on your interview guideline makes is easier to include them in the actual interview.`
    );
  }

  return ret;
};
