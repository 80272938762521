import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2) {
    ret.push(
      `Thank you for describing the risk handling strategy you are choosing and for also providing an explanation on why you chose this strategy in particular. Being able to do so clearly will be helpful when you explain your strategy to your manager or team members in the future. `
    );
  }

  if (A1 && !Z0 && !A2) {
    ret.push(
      `Thank you for sharing the risk handling strategy you are choosing.
Unfortunately, you did not provide a good explanation as to why you are choosing this strategy. There is no general right or wrong, but to understand whether your decision is a good one, you must understand the reason behind it.`
    );
  }

  if (!Z0 && !A3 && A2) {
    ret.push(
      `In your answer, you did not mention any specific actions your would take when following this risk management strategy. We encourage you to be more specific when formulating your strategy, so you can communicate it clearly to your team.`
    );
  }

  if (!Z0 && !A3 && !A2) {
    ret.push(
      `Your answer would be improved with more detail and specificity on the risk handling strategy and actions you would take. You may need to take these actions at different points in the project, by monitoring different outcomes, or working with different stakeholders.`
    );
  }

  if (A3) {
    ret.push(
      `Furthermore, you have included actions involved in this risk handling strategy which gives you a good starting point to develop your de-risking strategy and demonstrates your understanding of the concept. Good work!`
    );
  }

  if (A5) {
    ret.push(
      `Your explanation shows that you understood the basics of risk handling. Even though there is some small potential for improvement, you delivered good results.`
    );
  }

  if (A6) {
    ret.push(
      `However, your explanation you stated lacks some understanding of risk handling in general. You can do better for sure! Re-watch the videos or have a look at the method cards again.`
    );
  }

  return ret;
};
