import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 === false) {
    ret.push(
      `From your submission, it is unclear what the overall goal of your project is? What do you want to reach? To communicate it clearer, it helps to share your goal with someone outside the team and see whether they understand what you want to reach.`
    );
  }

  if (A1 && A2) {
    ret.push(
      `Good work! You have stated a relevant overall goal for the project you are running and have provided a clear explanation on why it is the main goal in your opinion and how it aligns with your company's mission.
It is very important to keep this goal in mind as you move on so that all actions performed actually help you achieve that.`
    );
  }

  if (A2 === false && A1) {
    ret.push(
      `Thank you for sharing the overall goal and purpose of the project! Unfortunately, we were missing part of your answer. How is this goal important to the company and aligns with its mission?`
    );
  }

  return ret;
};
