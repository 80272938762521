import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `No submission.
Even if you did not have the time to submit an answer, you should look back at your own experiences and try to identify one situation in which your team entered one of the pitfalls found in the method cards. These are amplification effect, polarization effect, common information bias, anchoring effect, sunk-costs, confirming evidence, framing and estimating, and forecasting error. It may not be obvious, but it is unlikely that you have never been in such a situation.`
    );
  }

  if (Z1) {
    ret.push(
      `Great job identifying this pitfall and explaining how it came to be!
It is important to understand them and to keep them in mind anytime you are working in a team.
This might help you realize if you are falling in one of them and help you avoid that before any damage is made.`
    );
  }

  return ret;
};
