import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, C1, C2, C3, C4, C5, C6, C7, C8 } = getSelectedOptionsFlags(selectedOpts);

  if (!Z0 && !Z1 && C1 === false) {
    ret.push(`The descrition of your persona is missing.`);
  }

  if (C2) {
    ret.push(
      `The description of your persona is very good. You have managed to create a narrative that makes the reader feel like he knows this persona a little after reading your description. This is very good as it will make it easier for you and your team to step into your persona's shoes and identify needs, frustrations and possible solutions. Well done!`
    );
  }

  if (!Z0 && !Z1 && C2 === false) {
    ret.push(
      `The description of your persona is not very convincing. The goal of a good description is to create a narrative that will make the reader feel like he knows this persona a little after reading it. This is very important as it will make it easier for you and your team to step into your persona's shoes and identify needs, frustrations and possible solutions.`
    );
  }

  if (C3) {
    ret.push(
      `Good job naming so many different behaviors. This will improve your Personas identity and make it easier for you to understand their routine and step into their shoes.`
    );
  }

  if (!Z0 && !Z1 && C3 === false) {
    ret.push(
      `You did not state enough typical behaviors for your persona. Stating several behaviors you have identified during your user research will improve your Personas identity and make it easier for you to understand their routine and step into their shoes. This, in turn, will help you identify more needs and goals and consequently design better products and services. This is why we advise you to analyze your findings more M1 again and look for more typical behaviors that you could include in the description of your persona.`
    );
  }

  if (C4) {
    ret.push(
      `Good job stating these many needs! These should represent well the needs of the users you are focusing on. This is the most important part of your persona, as these are the issues you must tackle to create the most value for your users.`
    );
  }

  if (!Z0 && !Z1 && C4 === false) {
    ret.push(`You did not include enough needs in your submission and they could have been more detailed as well.
Needs can be interpreted as the requirements your product or service will have to address. It is, therefore, a good idea to think of several needs that might be relevant for your persona. Taking several needs into account will help you design more human-centered products and services.
Thus, it would be best if you tried to come up with at least three needs for your persona.`);
  }

  if (C5) {
    ret.push(
      `Good job naming so many different behaviors. This will improve your Personas identity and make it easier for you to understand their routine and step into their shoes.`
    );
  }

  if (!Z0 && !Z1 && C5 === false) {
    ret.push(
      `What motivates your persona? You did not provide enough goals and details to fully answer this question. Similarly to needs, deeply understanding the aspirations of your users will help you create more value for them. Ultimately this should be your biggest goal. Therefore, we recommend you take a look at the user research you have already performed and identify more goals and aspirations.`
    );
  }

  if (!Z0 && !Z1 && C7 === false) {
    ret.push(
      `Your submission is not very clear. Please make sure that in the future all your uploaded files are very clear and readable.`
    );
  }

  if (C8) {
    ret.push(`Well done.
The connection and relevance of your persona in relation to your research question is very clear.`);
  }

  if (!Z0 && !Z1 && C8 === false) {
    ret.push(`It seems to us that your persona does not fit your research question that well. Ideally, you should try to aggregate the most relevant traits discovered during your user reserach into these personas. They should represent relevant customer or stakeholder segments.
This will allow you to make the most of your personas later.`);
  }

  return ret;
};
