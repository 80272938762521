import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`);
  }

  if (Z1) {
    ret.push(`Very well done! You've managed to cluster all your findings very well!
You have generated a good number of clusters (Too many and you did not cluster enough, too few and you may lose some relevant information) and all of your cluster summaries include relevant information that clarifies what exactly was relevant about that topic. Well done!`);
  }

  return ret;
};
