import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts, scoredPoints) => {
  const ret = [];
  const { Z0, Z1, D0, D1, D2, D3, D4, D5, D6, D7, D8 } = getSelectedOptionsFlags(selectedOpts);

  if (D0) {
    ret.push(`No additional file uploaded`);
  }

  if (D3) {
    ret.push(
      `Well done! We are happy to see that you have made notes stating what worked, what could be improved, and open questions. It seems like this session was very fruitful!`
    );
  }

  if (!D0 && !Z0 && !Z1 && D4 === false && !D3 && D0 === false) {
    ret.push(
      `You did not include any notes regarding what worked. Was the whole prototype a failure? Try to recall your session and think if there isn't at least one thing that went very well.`
    );
  }

  if (!D0 && !Z0 && !Z1 && D5 === false && !D3 && D0 === false) {
    ret.push(
      `Good job writing down several things that worked, open questions your user still has, and some new ideas on how to improve. This is a good sign that your feedback session was useful.

However, aren't there any improvements that you could make? Your submission does not include any issues or possible improvements. Please take some time to reflect again on the feedback session you had (if possible, go over your notes or recording) and think if there isn't anything you could do to improve the prototype in its next iteration according to this user.`
    );
  }

  if (!D0 && !Z0 && !Z1 && D6 === false && !D3 && D0 === false) {
    ret.push(
      `Good job! You have written down several comments regarding things that worked, possible improvements and suggestions from your test user. This is a good sign that your feedback session was effective.

However, weren't there any open questions after your feedback session? Maybe some functionality that was unclear or something that might be superfluous in your prototype.
You don't have to have open questions after your sessions, but it is very common that you may have an idea or hypothesis that still needs to be validated. And it is a good idea always to write those down, as they can help you build even better products. Therefore, we advise you to go over your notes/recordings and think if there is anything you might have missed.`
    );
  }

  if (!D0 && !Z0 && !Z1 && D7 === false && !D3 && D0 === false) {
    ret.push(
      `Nicely done! You have taken several notes regarding things that worked well during your feedback session, possible improvements for your idea/prototype and open questions that still must be addressed. This is a good sign that your feedback session was effective.

However, you did not write down any possible new features or ideas. This is not a crucial field to fill out, but it is common that people have new ideas when they are confronted with constructive feedback. To make sure that these are not lost after the feedback session, it is always a good idea to write them down immediately. Therefore, we would just like to remind you to pay attention to these ideas as they may disappear just as fast as they show up. `
    );
  }

  if (!D0 && !Z0 && !Z1 && D8 === false) {
    ret.push(
      `Lastly, it would have been best if you gave more details and wrote down more learnings.
After a session like this it is important to extract all the learnings you can.
We believe it should be possible to identify more than just a few.
Still, all in all we were happy with your submission and hope that you found these sessions valuable.`
    );
  }

  return ret;
};
