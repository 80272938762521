import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`We’re glad you chose to conduct an interview to analyze your customer journey mapping! The interviewing technique lets you get more in-depth insights form a user on their experience, especially what they are thinking and feeling. However, you should be aware that sometimes users can say one thing and do another thing in reality. This is why, in a full-scale UX project, we would recommend trying to combine interviews with observations of a user experience.`);
  }

  if (A2) {
    ret.push(
      `We’re glad you chose to observe the user experience! Observation is one of the most reliable ways to understand the reality of an experience. Even if you have done an experience yourself, there are new insights you can gain or details to catch if you observe the experience from the outside. However, you should be aware that you might still have assumptions about what a user is thinking and feeling. This is why in a full-scale UX project, we would recommend combining observations with qualitative interviews.`
    );
  }

  if (A3) {
    ret.push(
      `Thank you for describing the user and the context of your research. You have done well in conveying the who, what and where of your qualitative journey analysis. `
    );
  }

  if (A4) {
    ret.push(
      `Thank you for describing the user and the context of your research. Although your description is not detailed enough, and it is not clear to us what your research was really like. Please share more detail next time to we can better evaluate your work.`
    );
  }

  if (A5) {
    ret.push(
      `However, you did not describe the user or context of your research. We ask you to do this to show the thought put into planning the research and to help us better evaluate your work. `
    );
  }

  return ret;
};
