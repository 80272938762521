import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2 && A3 && A4) {
    ret.push(`Great selection of participants! You have selected a good number of diverse participants and gave a good description of their backgrounds and specialties. Well done!
Having a diverse team will make it more likely for your team to perform well in such divergent tasks and come up with several (and possibly innovative) solutions.`);
  }

  if (!Z0 && !Z1 && !Z2 && A1 === false) {
    ret.push(
      `You did not state three additional participants for your ideation session as asked. We would recommend having at least four diverse participants (including yourself) as this can help your team come up with many new and different ideas. Having too few participants might reduce the number of ideas generated, and having too many might make it hard for all of them to participate.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A2 === false) {
    ret.push(
      `You have not stated your relationship to all of your participants. Doing this will help you assess if the participants you chose are a good fit.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A3 === false) {
    ret.push(
      `Your participants do not all have a clearly described background. Briefly stating their background will help you understand how diverse your team is. Participants with very similar fields of expertise, habits, and perspectives should be avoided. Ideally, you would like to invite participants that have very different views on the product or service.`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A4 === false) {
    ret.push(
      `Your team is not very diverse. To maximize the potential of your ideation session, you should strive to build a very diverse group with different perspectives on your product. Doing so is likely to increase the number of ideas generated.`
    );
  }

  return ret;
};
