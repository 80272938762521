import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13, A14, A15 } =
    getSelectedOptionsFlags(selectedOpts);

  /*
  {id = "#6", text = "----- ----- ----- ----- Pick all that apply ----- ----- ----- ----- ----- ", points = 0},
  {id = "A1", text = "Setting (place and time) is clear", points = 0.25},
  {id = "A2", text = "Duration below 2h", points = 0.25},
  {id = "A3", text = "Experience is realistic", points = 0.5},
  {id = "A4", text = "Experiment makes sense ", points = 0.5},
  {id = "A5", text = "Experiment is cheap", points = 0.5},
    #{id = "A6", text = "Experiment can be tested without too much work", points = 0.5},
  {id = "A7", text = "User gets the chance to make relevant decision", points = 1},
  {id = "A8", text = "There is a cost to the users decision (i.e. their time, money, attention, energy...)", points = 1},
  {id = "#7", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
  {id = "A10", text = "Experiment is very well described", points = 2},
  {id = "A11", text = "Experiment is well described", points = 1.5},
  {id = "A12", text = "Experiment is somewhat well described", points = 1},
  {id = "A13", text = "Experiment is poorly described", points = 0.5},
  {id = "A14", text = "Experiment is described very poorly", points = 0},
  {id = "#8", text = "----- ----- ----- ----- Additional Feedback ----- ----- ----- ----- ----- ", points = 0},
  {id = "A15", text = "Picture of props included", points = 0.5},
*/

  if (A1 && A2 && A5 && A7 && A8) {
    ret.push(`Well done!
Your experiment is well described and includes all the aspects necessary to evaluate the desirability of your idea.
You have picked a good setting and have managed to create an experience that is realistic enough so that your user's decisions can be used reliably to assess the value of your idea.
More importantly, you have done a good job putting yourself in a position where you can observe how your users react to your experiment.

Now it is time for you and your team to go out there and put this experiment to practice!`);
  }

  if (!Z0 && (!A1 || !A2 || !A5 || !A7 || !A8)) {
    ret.push(`Thank you for describing the experiment you are planning to run.
While it is well-thought and includes many of the elements we presented in the course, there are still some things that we would like to point out.`);
  }

  if (!Z0 && !A1) {
    ret.push(`The setting of your experiment is not clear.
Where and when exactly are you planning to conduct your test?
Thinking of the right time and place is very important to ensure that you will test your idea with the right people in the right setting.`);
  }

  if (!Z0 && !A2) {
    ret.push(`Your experiment may be too long.
This is not necessarily a problem, but to be efficient you should try to design your experiments so that they can be conducted in 2h or less.
Doing so will save you resources in case the idea is invalidated.`);
  }

  if (!Z0 && !A5 && !A15) {
    ret.push(`Your experiment seems to be unnecessarily expensive.
Right now we don't know if your idea has any merit to it. More likely than not, your experiment will show that you still need to adapt something (e.g. the idea itself, your target group, the experiment...).
Therefore, you should try to save as much money and time as possible because it is possible that this idea will be scrapped.`);
  }

  if (A15) {
    ret.push(`Your experiment is set in a way in which your users will use a prop to simulate your solution. This is a great way to get accurate feedback and learn a lot about your solution. This by itself is great!
However, to make sure that this experiment runs properly, it is important that this Role-play is designed well. And doing that is often not so simple and may require a lot of time.
Therefore, because we don't yet know if your idea has any merit to it, we usually advise participants to start with an even simpler (prop-less) experiment. 
More likely than not, your experiment will show that you still need to adapt something (e.g. the idea itself, your target group, the experiment...). Then, once you had this first (very cheap) trial, then you can prepare a second run with actual props.
Still, you can certainly proceed with your experiment as it is presented above.`);
  }

  if (A13 || A14) {
    ret.push(`Please describe your experiment better.
Many key details and features were described too briefly.
We understand that describing it in detail takes some time, but doing it well now will not only help you identify any issues in your design, but it will also make it much easier for you to conduct it later.
Therefore, please take some time to describe your experiment better before continuing.`);
  }

  if (!Z0 && !A7) {
    ret.push(`The user's decision point is missing.
The goal of your experiment should be to create a situation where you can observe your user decide whether or not to actually use your solution (or a simplified version of it).
Doing this instead of just asking whether or not your idea is valuable is key to determine reliably if your solution is desired.
Please go over your experiment and make sure to include something that makes your user decide whether or not to try it.`);
  }

  if (!Z0 && A7 && !A8) {
    ret.push(`While you did design your experiment in a way that pushes your user to decide whether or not to try out your solution (or your simulation of it), this decision has no cost attributed to it.
It can be something like making your user change its route, or take a few minutes, sign up for something or even spend money.
Including these costs will help you observe if this idea is really valuable enough for your users to justify these costs.
Therefore, please make sure to include some cost to your decision point to make it more relevant.`);
  }

  if (!Z0 && !A7 && !A8) {
    ret.push(`Furthermore, we would like to remind you that the decision point should have some cost attributed to it.
It can be something like making your user change its route, or take a few minutes, sign up for something or even spend money.
Including these costs will help you observe if this idea is really valuable enough for your users to justify these costs.
Therefore, please make sure to include some cost to your decision point to make it more relevant.`);
  }

  if (!Z0 && !Z1) {
    ret.push(`
Now, before you go run your experiment, you should know that one short experiment will not be enough to validate your entire solution. And this is okay. 
The idea behind these experiments is that they are a really fast and efficient way to learn a lot about your users. We start with our key features because, if, after a few simple experiments you still can not identify any user-niche that is interested in your solution, then you may need to think of an alternative solution.
However, should there be (at least some) interest for your solution, then you can run a second version of your experiment, this time focusing on something else (maybe another key feature you are uncertain about). The beauty here is that you only invest more time and energy if your solution is worth it.
At the same time, because you have not invested much time into your first experiments in the first place, even if you discover that there is no real need for it, you have likely learned a lot more about your users and, if you asked why enough, you may have new insights to design a better solution.
This iterative approach will eventually lead you the right solution and will allow you to validate its elements one a time.`);
  }

  return ret;
};
