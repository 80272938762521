import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A6 } = getSelectedOptionsFlags(selectedOpts);

  if (A6) {
    ret.push(
      `Unfortunately, you did not submit a data source for 5 key performance indicators as stated in the exercise.`
    );
  }

  if (scoredPoints < 3 && Z0 === false && Z1 === false) {
    ret.push(
      `Thank you for your submission. The data sources you have mentioned are a good starting point, but you should think about where your data will be coming from and how to collect it in more detail.
It is important that all your metrics can be reliably measured using the data sources you have mentioned. However, we feel like some of your KPI's can not be tracked well using only the data sources you have provided`
    );
  }

  if (scoredPoints > 2 && scoredPoints < 5 && Z0 === false && Z1 === false) {
    ret.push(
      `Thank you for your submission. Your considerations are already good. However, there are one or two KPIs for which you could think in more detail about your data source and collection.`
    );
  }

  if (scoredPoints === 5 && Z0 === false && Z1 === false) {
    ret.push(
      `Perfect! You have submitted a realistic data source for all 5 KPIs. This will allow you and your team to track these KPI's as you progress and make adjustments to your project if necessary.`
    );
  }

  return ret;
};
