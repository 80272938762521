import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  if (A4) {
    ret.push(
      `Good job! Your success criterion is easily measurable. This is very important as the whole point of such a metric is to be able to measure it and compare it to some critical value.`
    );
  }

  if (A2 && A4 === false) {
    ret.push(
      `You have stated several success metrics, and this can be problematic. The goal of a success metric a very clear measurable aspect that can be compared to some critical value. If the success metric passes this critical value, then the test is considered a success. Therefore, it is often a good idea to have very few success metrics so that the result is very clear. For example, assume you have six success criteria and that your test passed 5/6 of these with great success. Is this test considered a success? According to the definition, it isn't, but often some team members would disagree. This is why it is better to just have one criterion.`
    );
  }

  if (A2 && A4) {
    ret.push(
      `However, you have stated several success metrics, and this can be problematic. The goal of a success metric a very clear measurable aspect that can be compared to some critical value. If the success metric passes this critical value, then the test is considered a success. Therefore, it is often a good idea to have very few success metrics so that the result is very clear. For example, assume you have six success criteria and that your test passed 5/6 of these with great success. Is this test considered a success? According to the definition, it isn't, but often some team members would disagree. This is why it is better to just have one criterion.`
    );
  }

  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `Your success metric is not very clear. Since this is the most important metric, it is important that it is very clear what exactly the metric is. Please go over your submission and state your success metric again so that anyone reading it will know exactly what you mean.`
    );
  }

  if (!Z0 && !Z1 && A4 === false) {
    ret.push(
      `How will you measure that?
Since your success criteria is so important to determine whether a test was a success or not, it is crucial that this metric can be measured very consistently and easily. Therefore, we ask you to rethink if this is the best metric for your test and and adapt it so that it is very measurable.`
    );
  }

  if (!Z0 && !Z1 && A5 === false) {
    ret.push(
      `Since your success criteria will determine whether or not your hypothesis is true, it is important that these criteria are also strongly related to the core element of your idea. However, this does not seem to be the case here. This is why we advise you to rethink your success criteria and pick something that will address the core elements you have stated before.`
    );
  }

  return ret;
};
