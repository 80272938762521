import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13, A14, A15 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `You did a good job of identifying gaps between your key resources and activities. This is central to ensuring your company's resources are robust enough to perform all the activities necessary to deliver value. `
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `You did not address whether or not your key activities are lined up with your key resources. This is central to ensuring your company's resources are robust enough to perform all the activities necessary to deliver value. Take a look at your key resources and consider if they may fall short in some areas. How could you expand them to address this problem?`
    );
  }

  if (A2) {
    ret.push(
      `Nice work finding channels that can better fit your customers. Understanding how your channels do or do not fit your target customers can help you figure out ways to more effectively deliver value to your customers.`
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `You did not identify gaps between your channels and your customers. Understanding how your channels do or don't fit your target customers can help you figure out ways to more effectively deliver value to your customers. Go back to your canvas and consider if your channels are lined up with your customer needs as effectively as possible.`
    );
  }

  if (A3) {
    ret.push(
      `Good work identifying places where your business model failures to capture all the available value. This is very common, but knowing and expanding in these areas can enable growth and innovation.`
    );
  }

  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `You have failed to point out areas where your business model does not capture all the available value. Failing to capture value is a common problem and knowing these areas can enable expansion and innovation in your business.`
    );
  }

  if (A4) {
    ret.push(
      `Nice job identifying elements of the business model that are reinforcing one another and those that could be stronger. It's important to ensure that value creation and capture elements of your business model align for an efficient business that captures as much value as it creates.`
    );
  }

  if (!Z0 && !Z1 && A4 === false) {
    ret.push(
      `You did not comment on elements of the business model reinforcing other elements. Take another look at you business model to understand how well the value creation and capture functions in your business model are aligned.`
    );
  }

  if (A5) {
    ret.push(
      `You hav identified several weak points in your business model. Identifying and understanding where each inconsistency lies is an important step to evaluating and communicating where your business needs to grow and improve in order to reach its innovative potential.`
    );
  }

  if (A10) {
    ret.push(
      `You have only identified one weak point in your business model. Identifying and understanding where each inconsistency lies is an important step to evaluating and communicating where your business needs to grow and improve in order to reach its innovative potential. We strongly advise you to reflect on your business model, its stakeholder and its characteristics and look for more weak points.`
    );
  }

  if (!Z0 && !Z1 && A5 === false && A10 === false) {
    ret.push(
      `You did not identify inconsistencies or weak points in your business model. Identifying and understanding where each inconsistency lies is an important step to evaluation and communicating where your business needs to grow and improve in order to reach its innovative potential.We strongly advise you to reflect on your business model, its stakeholder and its characteristics and look for several weak points. Doing this will help you mitigate them early on (which is much cheaper and easier)`
    );
  }

  if (A6) {
    ret.push(
      ` The inconsistencies you have identified followed the guiding questions that were meant to guide you to important insights about your business model and how to improve it. Good job!`
    );
  }

  if (!Z0 && !Z1 && A6 === false) {
    ret.push(
      `The inconsistencies you have identified were not related to the guiding questions. Those questions were meant to guide you to important insights about your business model and how to improve it.`
    );
  }

  if (A7 && A5) {
    ret.push(
      `You have provided thorough explanations for each weak-point you identified. It's important that you understand the significance of each inconsistency in your business model in order to address them early one when changing them is easy and cheap..`
    );
  }

  if (A7 && A10) {
    ret.push(
      `You have provided a thorough explanation for the weak-point you identified. It's important that you understand the significance of each inconsistency in your business model in order to address them early one when changing them is easy and cheap.`
    );
  }

  if (A8) {
    ret.push(
      `You have provided only partial explanations for the weak-points you identified. It's important that you understand the significance of each inconsistency you identify in your business model in order to properly address each one.`
    );
  }

  if (A9) {
    ret.push(
      `You did not provide explanations for the weak-points you identified. It's important that you understand the significance of each inconsistency you identify in your business model in order to properly address each one.`
    );
  }

  return ret;
};
