import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`);
  }

  if (Z1) {
    ret.push(`Thank you for stating your team.
As you will see below, the feedback for this exercise is less actionable than the ones for the previous modules as there is no wrong or right way to discuss these question with your team.`);
  }

  return ret;
};
