import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13 } =
    getSelectedOptionsFlags(selectedOpts);

  /* Quantity */

  if (A13) {
    ret.push(
      `You have ended up with a rather small number of clusters.
However, this is probably the case because you and your team did not start with so many learning notes to begin with.
As a rule of thumb, each cluster can lead to one insight (although sometimes more). Clustering too much can therefore reduce the number of insights you will end up with. We advise you to look at all of your teams' learning notes again and think if they are not too clustered.
A good way to test if you have clustered too much is to try to look for large overlap between your clusters, if there is not a lot of overlap, you probably did not over-cluster (This is just an indicator of course and should not be your only metric).`
    );
  }

  if (A1 && A13 === false) {
    ret.push(`It seems to us that you may have clustered too much. There is no rule to define how many clusters one should make.
However, you should only make more clusters if they are really all very different and relevant. As soon as your clusters start to overlap, then you should stop. Otherwise, you have so many clusters that you end up not clustering at all.
Take a look at your clusters and reassess if they are all unique and relevant. Maybe you could break apart one or two and move those learning notes to another cluster.
Clustering your learning notes well is important because it reduces the amount of data you will work with in the next steps without losing any relevant information. Imagine having to go through all of your learnings notes every time you wanted to grasp your user needs and observations.`);
  }

  if (A2 || A3 || A4) {
    ret.push(`Well done, it seems to us like you have generated a good amount of clusters.
They are all relevant and unique which is a good indicator that you have clustered well (It is still possible that you have clustered too much, but since there is no large overlap between your clusters, you probably did not over-cluster).
These clusters still contain all the main findings but reduce the amount of data you are working with. This makes your next steps much easier (Imagine having to go through all of your learnings notes every time you wanted to grasp your user needs and observations).`);
  }

  if (A5 || A6) {
    ret.push(`Are you sure that these clusters you made summarize well all your main learnings?
As a rule of thumb, each cluster can lead to one insight (although sometimes more). Clustering too much can therefore reduce the number of insights you will end up with. We advise you to look at all of your teams' learning notes again and think if they are not too clustered.
A good way to test if you have clustered too much is to try to look for large overlap between your clusters, if there is not a lot of overlap, you probably did not over-cluster (This is just an indicator of course and should not be your only metric).`);
  }

  /* Summaries */

  if (A7) {
    ret.push(`What is very important however, is that your cluster summaries include very good summaries.
This may seem of little relevance now, but as you progress with your project you will forget most of the learnings you have made so far and having a good summary will ensure that nothing goes lost and will also help you understand your thought process in the future.
It is in this case similar to writing comments while you code. It may seem unnecessary now, but if you ever try to go over it in the future, these comments will be life saving!`);
  }

  if (A8) {
    ret.push(`What is also important however, is that your cluster summaries include good summaries.
This may seem of little relevance now, but as you progress with your project you will forget most of the learnings you have made so far and having a good summary will ensure that nothing goes lost and will also help you understand your thought process in the future.
It is in this case similar to writing comments while you code. It may seem unnecessary now, but if you ever try to go over it in the future, these comments will be life saving!
This is why we recommend you to make these summaries as clear and detailed as possible, as, right now, they are not detailed enough.`);
  }

  if (A9) {
    ret.push(`You did not include any summary or description to your summaries.
This may seem of little relevance now, but as you progress with your project you will forget most of the learnings you have made so far and having a good summary will ensure that nothing goes lost and will also help you understand your thought process in the future.
It is in this case similar to writing comments while you code. It may seem unnecessary now, but if you ever try to go over it in the future, these comments will be life saving!
This is why we urge you to write down these summaries as clear and detailed as possible while you are still working on the user research. `);
  }

  /* Additional stuff */

  if (A10) {
    ret.push(
      `Furthermore, your cluster summaries should be standalone statements that are clearly written and easy to understand. Don't forget that we will not work with the learning notes anymore. Therefore, it is important that your cluster and cluster summaries are clear and contain all of the key findings so that no relevant information is lost.`
    );
  }

  if (A11) {
    ret.push(
      `Additionally, some of your summaries are not detailed enough. We recommend you go over all of your clusters and evaluate if your summaries do summarize all the key learnings from each cluster.`
    );
  }

  if (A12) {
    ret.push(
      `Lastly, some of your clusters do not include summaries. These are very important to make sure that all the relevant findings are stored for the next steps of your research.`
    );
  }

  return ret;
};
