import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
const ret: string[] = [];
const {
Z0,
Z1,
Z2,
Z3,
A1,
A2,
A3,
A4,
A5,
A6,
A7,
A8,
A9,
A10,
A11,
A12,
A13,
A14,
A15,
A16,
A17,
A18,
A19,
A20,
A21,
A29,
A30,
A31,
A32,
} = getSelectedOptionsFlags(selectedOpts);


if (scoredPoints > 10.5) {
    ret.push(
    `Congratulations on your excellent work! Your questions are well-framed and include thoughtfully prepared follow-up questions for each topic. Additionally, all of your questions are open-ended, non-leading, and relevant to the topic at hand.
Keep up the good work by asking "why" in situations where you feel there may be more to explore in your interviewee's answers. This will help you gain a deeper understanding of the underlying reasons behind their behavior and actions.

Also, please keep in mind that it may not be possible to ask all of your questions (and their follow-up questions) in a 30 minute interview - And that's okay!
The point of preparing so many questions is to ensure that you will not run out of questions, and to allow you to be flexible and explore different topics that you may have not anticipated earlier.
Our advise is that you highlight your most important questions. These are the ones that you should always ask your participants. The others will depend on how the interview goes.`
    );
    }


if (
(A29 && (scoredPoints > 9.4)) 
||
(Z0 === false && Z1 === false && scoredPoints < 9.5 && !(A3 && A5 && A7 && A9) && !A17 && !A18 && !A19 && !A20 && !A30 && !A16 && !A2) 
||
(A1 && A3 && A5 && A7 && A9 && !A13 && !A14 && !A31 && !A32 && !A29)
) {
ret.push(
`Thank you for your answer.
The main body of your interview guideline contains is well prepared and contains good questions.
They are all open-ended, non-leading, only ask for one thing at a time, do not ask for solutions, and are all clear and easy to understand. Well done!

Congratulations on your excellent work! Your questions are well-framed and include thoughtfully prepared follow-up questions for each topic. Additionally, all of your questions are open-ended, non-leading, and relevant to the topic at hand.
Keep up the good work by asking "why" in situations where you feel there may be more to explore in your interviewee's answers. This will help you gain a deeper understanding of the underlying reasons behind their behavior and actions.

Also, please keep in mind that it may not be possible to ask all of your questions (and their follow-up questions) in a 30 minute interview - And that's okay!
The point of preparing so many questions is to ensure that you will not run out of questions, and to allow you to be flexible and explore different topics that you may have not anticipated earlier.
Our advise is that you highlight your most important questions. These are the ones that you should always ask your participants. The others will depend on how the interview goes.`
);
}

if (
(A29 && scoredPoints > 9.5) ||
(Z0 === false &&
Z1 === false &&
scoredPoints < 9.5 &&
!((A1 || A12 || A21) && A3 && A5 && A7 && A9))
) {
ret.push(
`Thank you for your answer.
Your interview questions are quite good as they are meet most of the requirements that we mention in the course.
However, there are some questions that could be changed or at least rephrased to help you make the most from your interviews. You will find more details on what to improve below.`
);
}

if ((A1 || A12 || A21) && A3 && A5 && A7 && A9 && (A13 || A14 || A31 || A32)) {
ret.push(
`Thank you for your answer.
The main body of your interview guideline contains is well prepared and contains good questions.
They are all open-ended, non-leading, only ask for one thing at a time, do not ask for solutions, and are all clear and easy to understand.
However, there are still some things that you should change to make the most from your interviews. You will find more details on what to improve your main questions below.`
);
}

if (A30) {
ret.push(
`You haven't really included 10 main questions (plus follow-ups). Instead, you have a few main questions and a few follow-ups that add up to roughly 10 questions. In order to ensure that you have a good selection of questions ready for your interview, please try to come up with more questions in the future. `
);
}

if ((A2 || A16) && Z1 === false && Z0 === false && !A30) {
ret.push(
`You did not share 10 main questions as asked.
The core section of your interview guideline is the most important part. It is where you will ask all the relevant questions to discover the interviewee's needs and pain points. You should make sure to write down a lot of questions. Even if you don't think you will have time to ask all of them, having several questions on standby will allow you to steer the interview towards a direction that suits you and can help when you do go through all of your main questions (otherwise you would have to end the interview or think of good questions on the spot).
That is why we recommend you to write down at least ten good questions here, although more are even better.`
);
}

if ((A6 || A18 || (Z1 === false && A5 === false)) && Z0 === false) {
ret.push(
`Some of the questions you prepared may suggest a certain answer or assume a certain situation.
It is super important that all the questions you ask are bias-free. This is one of the main reasons why we even prepare an interview guideline: to make sure that the way we frame our questions will not affect the answer we receive.

The goal of this user research is to extract the needs and pain points of your users. Leading questions can severely affect your findings and bias your results. Therefore, we recommend you to take a look at your questions and rewrite the ones that lead the user to answer in a certain way.
An example of such a question would be: "Do you also dislike this feature?" This can be transformed into: "How do you feel about this feature?".`
);
}

if ((A8 || A19 || (Z1 === false && A7 === false)) && Z0 === false && !Z1 && !Z2) {
ret.push(
`Some of your questions are asking for more than one thing at once.
Questions that ask for several things are usually more complicated and can be harder to understand. This could give you poor answers, as maybe someone would have answered differently if he had understood all parts of the question. These questions, therefore, not only give results that are not reliable it also damages the flow of the conversation.
A good interview should be very causal so that the participant doesn't think too much before he answers. Hard questions can remove the interviewees from that state. Thus, we advise you to break down these more complex questions into a few simple and clear ones.`
);
}

if ((A10 || A20 || (Z1 === false && A9 === false)) && Z0 === false && !Z1 && !Z2) {
ret.push(
`Some of your questions require your participants to imagine situations or suggest solutions to theoretical situations.
You can do this, but it is not an optimal approach as the answers you get are not very reliable and may give you a solution before you even fully understand the problem.
For example, you could ask a participant for their opinion and, depending on his mood or recent history, he might give you two completely different answers. Furthermore, the solution you may get might be ideal for this participant, but not for others. This is we advise you to identify only needs and pain points for now.
You will have plenty of time for brainstorming and testing solutions later!`
);
}

/* Removed this because it always feels useless and out of place A11: { id= "A11", text= "Questions clearly structured and organised by topic/theme", points= 0.5 },

if (A11) {
ret.push(
`Furthermore, we really liked the structure of your submission and how you added questions to each topic!
Well done!`
);
}

*/

if (A14 && !A30) {
ret.push(
`We would like to point out that you did not prepare enough questions that help you deeply understand your interviewee.
The main goal of this stage of the design thinking process is to understand your users, their needs and issues. This is what will allow you to create better solutions.
Therefore, before conducting your interview, make sure so add questions that help you uncover relevant stories related to your challenge where your users can share how they behaved or what they felt in those situations.
Once they start sharing that story, don't be afraid to ask why and dig deeper. Doing so will help you uncover better their true feelings and help you identified the underlying reasons behind their thoughts and behaviors.`
);
}

if (A31 && !A30) {
ret.push(
`We would also like to point out that you did not prepare enough questions that help you deeply understand your interviewee.
The main goal of this stage of the design thinking process is to understand your users, their needs and issues. This is what will allow you to create better solutions.
Therefore, before conducting your interview, make sure so add questions that help you uncover relevant stories related to your challenge where your users can share how they behaved or what they felt in those situations.
For example, ask them to share how they currently communicate with the R&D department. Or, if they have identified an unmet need or have a new idea, what do they do to with that info?
Once they start sharing that story, don't be afraid to ask why and dig deeper. Doing so will help you uncover better their true feelings and help you identified the underlying reasons behind their thoughts and behaviors.`
);
}

if (A32 && !A30) {
ret.push(
`We would also like to point out that you did not prepare enough questions that help you deeply understand your interviewee.
The main goal of this stage of the design thinking process is to understand your users, their needs and issues. This is what will allow you to create better solutions.
Therefore, before conducting your interview, make sure so add questions that help you uncover relevant stories related to your challenge where your users can share how they behaved or what they felt in those situations.
For example, ask them to share what they do once they arrive at their destination with their bicycle. Where do they leave their bike? Do they lock it? Do they worry about theft or rain? What about their helmets? Ask them to share this entire process and don't be afraid to dig deeper!
Doing so will help you uncover better their true feelings and help you identified the underlying reasons behind their thoughts and behaviors.`
);
}

if ((A12 || A21) && !A13 && A3 && A5 && A7 && A9 && !A30 && (scoredPoints < 10.5)) {
ret.push(
`Furthermore we liked how you prepared more questions than just what we have required.
You will probably not be able to ask all of them in a 30 minute session, but it will help you select the best questions for each interview session.
Good job!`
);
}

if (A13 && !A12 && !A21 && A3 && A5 && A7 && A9 && !A30) {
ret.push(
`You did not include enough follow-up questions.
Doing so will allow you to dig deeper during your interviews by asking for details, stories, or further information.
Often, simply asking why?/why not? elicits more relevant information and helps to get closer to real needs and pain points. Having those follow-up questions prepared and on your interview guideline makes is easier to include them in the actual interview.`
);
}

if ((A17 || (Z1 === false && A3 === false)) && Z0 === false) {
ret.push(
`Not all of your questions are open-ended. Even if you have follow-up questions ready, you should, as a rule of thumb, avoid closed-ended questions. These questions will reduce the length of the answers you receive and make it harder for you to make many findings. Consequently, we recommend you to only prepare clear open-ended questions.`
);
}

if (A29) {
ret.push(
`Additionally, you have often added suggested answers after your questions. While these can be used to follow-up, make sure to not give your suggestions straight away!
Instead, ask the original question first and wait. If you participant needs a few minutes to think, give them that time.
Only once they have finished their answers, you can mention some of the points in brackets and ask why he did not say this or that? This way, you can be sure that the answers your participants are not biased and being confronted with these follow-up questions might help your participants share less obvious insights.`
);
}

if (A13 && !A12 && !A21 && !(A1 && A3 && A5 && A7 && A9) && !A30) {
ret.push(
`Lastly, we would like to point out how you did not include enough follow-up questions.
Doing so will allow you to dig deeper during your interviews by asking for details, stories, or further information.
Often, simply asking why?/why not? elicits more relevant information and helps to get closer to real needs and pain points. Having those follow-up questions prepared and on your interview guideline makes is easier to include them in the actual interview.`
);
}

if (A13 && (A12 || A21) && !A30) {
ret.push(
`Lastly, while we liked how you prepared more questions than just what was required, we would like to point out how you did not include enough follow-up questions.
Doing so will allow you to dig deeper during your interviews by asking for details, stories, or further information.
Often, simply asking why?/why not? elicits more relevant information and helps to get closer to real needs and pain points. Having those follow-up questions prepared and on your interview guideline makes is easier to include them in the actual interview.`
);
}

return ret;
};
