import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts, scoredPoints) => {
  const ret = [];
  const { Z0, Z1, D1, D2, D3 } = getSelectedOptionsFlags(selectedOpts);

  if (D1) {
    ret.push(
      `Very well done! You have made it very clear how you and your team will communicate in between your scheduled meetings. As most of the tacit knowledge is usually shared informally outside of meetings, it is important to ensure that all members of your team are in constant communication even when there are no meetings. Thinking about a systematic approach for this now will make this process much easier ion the future.`
    );
  }

  if (D2) {
    ret.push(`Well done. You have stated how you and your team will communicate in between your scheduled meetings.
However, as most of the tacit knowledge is usually shared informally outside of meetings, it is important to ensure that all members of your team are in constant communication even when there are no meetings.
Therefore, we believe that you should have stated how this communication will take place with more details.
Thinking about a systematic approach for this now will make this process much easier ion the future. `);
  }

  if (D3) {
    ret.push(`You have not stated clearly how you and your team will communicate in between your scheduled meetings.
As most of the tacit knowledge is usually shared informally outside of meetings, it is important to ensure that all members of your team are in constant communication even when there are no meetings.
Therefore, we believe that you should have stated how this communication will take place with more details.
Thinking about a systematic approach for this now will make this process much easier ion the future.`);
  }

  return ret;
};
