import * as React from "react";
import { Box, Heading, Select, Text } from "theme-ui";
import { useHistory } from "react-router-dom";
import { Layout } from "../components/Layout";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { Container } from "@sparkademy/app-common/elements/Container";
import { ButtonGhost } from "@sparkademy/app-common/elements/ButtonGhost";
import { useAssignmentContext } from "@sparkademy/app-common/contexts/assignment-context";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { useGradingContext } from "../contexts/grading-context";
import { ExerciseSubmission } from "@sparkademy/app-common/models/assignment";
import { GradingService } from "../services/grading";
import { dateFormatter } from "@sparkademy/app-common/utils/date-formatters";

export const FlaggedSubmissions: React.FC = () => {
  const { currentUser } = useSessionContext();
  const hasGraderRole = currentUser?.data.role === "grader";

  const {
    loading: loadingSubmissions,
    assignmentSubmissions,
    fetchFlaggedSubmissions,
  } = useAssignmentContext();

  React.useEffect(() => {
    if (!currentUser) {
      return;
    }
    fetchFlaggedSubmissions(currentUser);
  }, [currentUser, fetchFlaggedSubmissions]);

  if (loadingSubmissions) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  if (hasGraderRole) {
    return (
      <Layout>
        <Box sx={{ display: "flex", justifyContent: "center", p: 10 }}>
          You don't have permission to access this page.
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          py: "80px",
        }}
      >
        <Table assignmentSubmissions={assignmentSubmissions} readOnly={false} />
      </Container>
    </Layout>
  );
};

const Table: React.FC<{ assignmentSubmissions: ExerciseSubmission[]; readOnly: boolean }> = ({
  assignmentSubmissions,
  readOnly,
}) => {
  const { setSubmission } = useGradingContext();
  const { currentUser } = useSessionContext();
  const history = useHistory();

  if (assignmentSubmissions.length === 0) {
    return <Text>There is no data to show.</Text>;
  }

  const NUMBER_OF_COLUMNS = 7;

  const gridProps = {
    display: "grid",
    gridTemplateColumns: `repeat(${NUMBER_OF_COLUMNS}, 1fr)`,
  };

  const headerProps = {
    ...gridProps,
    p: "24px 0 18px 48px",
    color: "white",
    bg: "black",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    gridColumn: `1 / span ${NUMBER_OF_COLUMNS}`,
  };

  const bodyProps = {
    ...gridProps,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    gridColumn: `1 / span ${NUMBER_OF_COLUMNS}`,
  };

  const getRowProps = (idx: number) => ({
    ...gridProps,
    gridColumn: `1 / span ${NUMBER_OF_COLUMNS}`,
    p: "14px 0 13px 48px",
    bg: idx % 2 === 0 ? "white" : "new.secondary.lightGrey",
    borderBottomLeftRadius: idx === assignmentSubmissions.length - 1 ? "10px" : 0,
    borderBottomRightRadius: idx === assignmentSubmissions.length - 1 ? "10px" : 0,
  });

  const onSubmissionClicked = async (submission: ExerciseSubmission) => {
    setSubmission(submission);
    GradingService.lockForGrading(submission.id, currentUser!)
      .then(() => history.push(`/submission/${submission.id}`))
      .catch(() => window.alert("Exercise have already been taken for grading."));
  };

  return (
    <Box
      sx={{
        border: "1px solid",
        borderColor: "new.secondary.grey",
        borderRadius: "10px",
        width: "100%",
      }}
    >
      <Box sx={headerProps}>
        <Heading as="h4" sx={{ gridColumn: 1 }}>
          Learner
        </Heading>
        <Heading as="h4" sx={{ gridColumn: 2 }}>
          Assignment
        </Heading>
        <Heading as="h4" sx={{ gridColumn: 3 }}>
          Cohort
        </Heading>
        <Heading as="h4" sx={{ gridColumn: 4 }}>
          Date Submitted
        </Heading>
        <Heading as="h4" sx={{ gridColumn: 5 }}>
          Grader
        </Heading>
        <Heading as="h4" sx={{ gridColumn: 6 }}>
          Score / Max Score
        </Heading>
      </Box>

      <Box sx={bodyProps}>
        {assignmentSubmissions.map((as, idx) => (
          <Box key={idx} sx={getRowProps(idx)}>
            <span sx={{ gridColumn: 1 }}>{as.user_name}</span>
            <span sx={{ gridColumn: 2 }}>{`${as.module_id}/${as.exercise_id}`}</span>
            <span sx={{ gridColumn: 3 }}>{as.cohort_id}</span>
            <span sx={{ gridColumn: 4 }}>
              {as.submitted_at && dateFormatter.format(new Date(as.submitted_at))}
            </span>
            <span sx={{ gridColumn: 5 }}>{as.grader_name}</span>
            <span sx={{ gridColumn: 6 }}>
              {getTotalScore(as.scores)}/{as.max_score}
            </span>

            {(!readOnly || as.grading_locked_by === currentUser?.data.id) && (
              <Box sx={{ gridColumn: 7, textAlign: "center" }}>
                <ButtonGhost
                  sx={{
                    textAlign: "center",
                    fontSize: "16px",
                    p: 0,
                    minHeight: "auto",
                  }}
                  onClick={() => onSubmissionClicked(as)}
                >
                  Review
                </ButtonGhost>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

function getTotalScore(scores: { [item: string]: number }): number {
  // sum only values from keys with a single dot (section totals)
  // other keys are subsections and should not be included in the sum
  const sum = Object.entries(scores)
    .filter(([key]) => key.split(".").length === 2)
    .reduce((acc, [_, value]) => acc + value, 0);

  return sum;
}

