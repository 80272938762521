import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A21, A22 } =
    getSelectedOptionsFlags(selectedOpts);

  /*
{ id= "A1", text= "Constraint stated", points= 0.25},
{ id= "A2", text= "Constraint is actually constraining (reduces the solution-space)", points= 0.5 },
{ id= "A3", text= "Constraint pushes participants to think outside the box", points= 0.5 },
{id = "#28", text = "----- For specific types of constraints (Pick only one of these boxes and nothing else!)----- ", points = 0},
{ id= "A5", text= "How would XYZ solve this problem (e.g., "How would Elon Musk or Apple solve this problem?")", points= 1 },
{ id= "A6", text= "Not a constraint, but something stimulating (e.g., you have all the money in the world)", points= 1 },
{ id= "A7", text= "Is asking participants for answers. E.g 'How would you do it?'", points= 0.5},
{ id= "A8", text= "Constraint points to a solution (How can we use VR to...)", points= 0.6},
{ id= "A9", text= "We don't understand the constraint", points= 0.3},
*/

  if (A1 && A2 && A3) {
    ret.push(
      `Nice choice on the constraint! It is a good fit for the problem at hand and correctly adds some additional requirements that must be met by your idea!
Additionally, by narrowing down the possible solutions, it will help your participant to think outside the box and come up with fresh new ideas. Good job!`
    );
  }

  if (A1 && A2 && !A3) {
    ret.push(
      `Thank you for stating your first constraint.
However, while it does take away some of the possible solutions to your problem, it does not really push your participants to think outside the box.
In order to get the most out of your constrained brainstorming you should try to find constraints that force your participants to think of alternative solutions that go beyond "normal" approaches.
This will foster innovation by pushing them to think outside the box.

A good way to accomplish this is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible.
For example, if many of your solutions require an app to function, your first constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (A1 && !A2 && !A3) {
    /* Not sure if I should leave !A3 here */
    ret.push(
      `Thank you for your answer.
However, this is not really a constraint as it cannot be used to "eliminate" certain solutions.
In order to get the most out of your constrained brainstorming you should try to find constraints that force your participants to think of alternative solutions that go beyond "normal" approaches.
This will foster innovation by pushing them to think outside the box.

A good way to accomplish this is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible.
For example, if many of your solutions require an app to function, your first constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (A5) {
    ret.push(
      `Thank you for stating the first constraint you would use to stimulate your participants.
Although this is not really a constraint (as it does not limit the ideas you can come up with) it can increase your participant's creativity by getting them to look at the problem through someone else's perspective.
You can therefore use this as your opening 'constraint', just make sure to also include actual constraints later.

A good way to come up with effective constraints is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible. This is a constraint you could use in your workshop!
For example, if many of your solutions require an app to function, your first constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (A6) {
    ret.push(
      `Thank you for stating the first constraint you would use to stimulate your participants.
Although this is not really a constraint (as it does not limit the ideas you can come up with) it can increase your participant's creativity by removing obstacles and helping them think of alternative solutions.
You can therefore use this as your opening 'constraint', just make sure to also include actual constraints later.

A good way to come up with effective constraints is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible. This is a constraint you could use in your workshop!
For example, if many of your solutions require an app to function, your first constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (A7) {
    ret.push(
      `Thank you for stating the first constraint you would use to stimulate your participants.
Unfortunately, we need to point out that this is not really a constraint, as it does not limit the solutions you can come up with. Instead, you are simply asking your participants for suggestions and answers to your problem.

This is not the goal of a constraint brainstorming. The goal is to create additional requirements to your solutions that exclude very common and obvious solutions. Thus pushing them to be creative and think outside the box.
A good way to come up with effective constraints is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible. This is a constraint you could use in your workshop!
For example, if many of your solutions require an app to function, your first constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (A8) {
    ret.push(
      `Thank you for stating the first constraint you would use to stimulate your participants.
However, while this constraint does limit the scope of the solutions your participants can come up with, it is limiting the breadth of possibilities towards one type solution. This in turn, might restrict your users too much and take away the possibility for alternative solutions to be discussed.
Therefore, we recommend you to use your constraints to push your participants to think creatively instead.

A good way to come up with effective constraints is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible. This is a constraint you could use in your workshop!
For example, if many of your solutions require an app to function, your first constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  if (A9) {
    ret.push(
      `We are not sure if we understood your constraint correctly.
A constraint should be a clear requirement that restricts the type of solutions your participants can present.

In other words, the goal of a constrained brainstorm is to create additional requirements to your solutions that exclude very common and obvious solutions. Thus pushing them to be creative and think outside the box.
A good way to come up with effective constraints is for you to try to think of a few solutions to the problem yourself and then look at what your new solutions have in common. Then, try to think of a constraint that would make these solutions impossible. This is a constraint you could use in your workshop!
For example, if many of your solutions require an app to function, your first constraint could be: "The solution cannot use electricity to work" or "The solution should not require an app". This will push your participants to think of new alternatives and might help them think of solutions they would not have thought of otherwise.`
    );
  }

  return ret;
};
