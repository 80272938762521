import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Thank you for creating a full storyline around your project. A storyline will help you to prepare presentations or a pitch and convince your audience to take action. The goal you are mentioning clearly aligns with the project topic. Your character and his situation and goals are well defined and convincing. Great job, your story connects to the target audience and involves the listener. The solution clearly connects the challenge, goal, and the added value in a coherent way. Your AHA moment is a convincing and memorable statement. And finally, you end up with a clear and actionable call to action to the audience. Well done!`
    );
  }

  return ret;
};
