import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Great job! Your introduction contains all the necessary aspects of a great intro. Most of all, you have made it very clear that the user should not hesitate to speak their mind, and secondly, you did not explain exactly how the participant should use the prototype or what to expect.
In combination, these will make sure that the test will be conducted without any bias from your questions and should give you several learnings.`
    );
  }

  return ret;
};
