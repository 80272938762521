import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(`Great job! You included all the necessary elements of a pitch, the user, his problem or challenge, a solution and its added value, why you are the right person to tackle. To have a lasting impression and move to action, you started with a bang and ended with a call to action.
Your pitch is coherent and convincing, easy to understand and avoids industry jargon. `);
  }

  return ret;
};
