import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, Z4, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);
  /*
    { id = "A1", text = "User states how it felt to give feedback", points = 0.5 },
    { id = "A2", text = "User named one thing that he would do differently", points = 1 },
    { id = "A3", text = "User answered whether or not the feedback he received as useful (incl. a reason is necessary after CAR_B2)", points = 0.5},
    { id = "A4", text = "Answer shows reflection and/or is very detailed and well worked", points = 1 },
    */

  if (!Z2 && !Z3 && !Z4 && !Z0 && !Z1 && scoredPoints > 1.5) {
    ret.push(
      `Great job!
It seems like you reflected well on this topic.
Hopefully, this has helped you better understand what effects feedback can have on both the giver and the receiver.`
    );
  }

  if (!Z2 && !Z3 && !Z4 && !Z0 && !Z1 && A1 === false) {
    ret.push(
      `How did it feel to give and receive feedback?
Taking these feelings into account before giving feedback will undoubtedly make the experience more comfortable and enjoyable for all parties. That is why we hope that the next time you provide or are given feedback, that you will take the time to reflect on how it felt and how you could improve that experience.`
    );
  }

  if (!Z2 && !Z3 && !Z4 && !Z0 && !Z1 && A2 === false) {
    ret.push(
      `Now that you have reflected on how it felt to give and receive feedback, you can also try to find possible improvements to give better feedback in the future. What could you change to create a better experience for all the parties involved?
Please take some time to reflect on this to make sure that you take the most out of this experience.`
    );
  }

  if (!Z2 && !Z3 && !Z4 && !Z0 && !Z1 && A3 === false) {
    ret.push(
      `Was the feedback you received useful? Why not? Or if it was, why was it?
Try to break down the feedback you received into elements and analyze which ones you should keep in a good feedback. Factors that will have a positive effect on your work in the future are especially important.`
    );
  }

  if (A5) {
    ret.push(
      `Regarding your issues giving "negative" feedback, one thing that often helps is to really think about what you want to achieve before giving feedback.
If your goal is really to help your colleague improve and learn, then you should find a way to state it in a positive and supportive manner. If, however, you feel like maybe your are using your feedback as an excuse to let out steam or complain about something that is not easy to change (e.g. criticise personality instead of behaviour) then you should not say anything.`
    );
  }

  return ret;
};
