import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Your agenda overview includes all elements of a good ideation session, from warm-up, setting the scene, and ideating using different techniques.
This is the agenda we would typically share with the participants. However, we highly recommend you to prepare a more detailed agenda for yourself when you are running such a workshop.

This should include the open-run-close principle for each activity, desired outcomes and necessary preparations and templates. This will make it easier for you to conduct your ideation session later.`);
  }

  return ret;
};
