import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, B1, B2, B3, B4, B5, B6 } = getSelectedOptionsFlags(selectedOpts);

  if (B1) {
    ret.push(
      `Well done! You have defined a very clear and reasonable value proposition for all the users you have identified earlier.`
    );
  }

  if (B2) {
    ret.push(
      `You have defined a clear and reasonable value proposition for all the users you have identified earlier. Good job.`
    );
  }

  if (B3) {
    ret.push(
      `Thank you for sharing the different value propositions you believe your business model serves to all of your users.`
    );
  }

  if (B4 || B5) {
    ret.push(
      `Thank you for sharing the different value propositions you believe your business model serves to all of your users.
However, we ask you to take a few minutes to re-evaluate these value propositions and define them better.
To prepare a effective and sustainable business model it is important to have very clear and well-defined value propositions to all of your users.
As it is right now, this is not the case.`
    );
  }

  if (B6) {
    ret.push(
      `However, we ask you to be more detailed in the future as defining your VPs clearly will make it much easier to ensure that your final business model addresses them effectively.`
    );
  }
  
  return ret;
};
