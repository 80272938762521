import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2) {
    ret.push(
      `Well done, you have made it very clear what it is that you would like to test in this second iteration.
As mentioned in the course, developing a working business idea is a dynamic task that needs constant feedback and re-iterations.
We are glad to see that you have used your past learnings to improve further!`
    );
  }

  if (A1 && !A2) {
    ret.push(
      `Good job. You have made it clear what it is that you want to test in your next iteration.
However, we believe that you should have described your next steps better.
Doing so will help you prepare better and ensure that you will learn more from this test.`
    );
  }

  if (A1 === false) {
    ret.push(
      `You did not make it very clear what it is that you would like to test in your second iteration.
In the future, please make sure to be detaled in your answers.
This will not only allow us to gve you better feedback, but by externalzing your ideas with more details, you may realize any issues or possible obstacles.`
    );
  }

  if (A5) {
    ret.push(`Lastly, we are glad to hear that you will try to validate the assumptions you coudln't validate before.
Make sure to develop relevant and easy to track metrics to do that!`);
  }

  /*
{id = "A1", text = "Makes it clear what he wants to test", points = 1},
{id = "A2", text = "Answer is detailed", points = 1},
{id = "#0", text = "----- ----- ----- ----- Pick One below ----- ----- ----- ----- ----- ", points = 0},
{id = "A3", text = "Answer shows reflection", points = 2},
{id = "A4", text = "Answer shows some reflection", points = 1},
{id = "#1", text = "----- ----- ----- ----- Additional feedback ----- ----- ----- ----- ----- ", points = 0},
{id = "A5", text = "Says there were assumptions he couldn't validate and will try to validate now", points = 0},

  */

  return ret;
};
