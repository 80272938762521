import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2) {
    ret.push(
      `Thank you for sharing your team members' names as well as stating where you found information on the current metrics used to measure indicators related to your company's innovationness.`
    );
  }

  if (A1 && !A2) {
    ret.push(`Thank you for sharing your team members' names.
However, we would have liked to see where you found information on the current metrics used to measure indicators related to your company's innovationness. `);
  }

  if (!A1 && A2) {
    ret.push(`Thank you for sharing where you found information on the current metrics used to measure indicators related to your company's innovationness.
However, it is not clear to us who you are working with. We will assume you are working alone and assign this grade only to you.
Please send us and email at team@sparkademy.com in case you would like to state your team members' names.`);
  }

  return ret;
};
