import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B0, B1, B2, B3, B4, B5, B6, B7, B8, B9, B10, B11, B12, B13, C13, A12 } =
    getSelectedOptionsFlags(selectedOpts);

  if (B0 && !B4 && !B5 && !B6 && !B13 && !B12) {
    ret.push(`No feedback submitted.`);
  }

  if (B1 && !B4 && !B5 && !B6 && !B13 && !B12) {
    ret.push(`Thank you for submitting this summary of the feedback you received from your second user.
However, we believe that you should have been a more detailed in your description to ensure that no information is lost.
Please, make sure to record your learnings better in the future. Especially those from direct user interactions. `);
  }

  if (B2 && !B4 && !B5 && !B6 && !B13 && !B12) {
    ret.push(`Thank you for submitting this summary of the feedback you received from your second user.
Your feedback is clear and concise and should help you summarize most of your learnings from this session.
However, ideally, you should try to include even more details and learnings from your user feedback sessions.`);
  }

  if (B3 && !B4 && !B5 && !B6 && !B13 && !B12) {
    ret.push(`Well done! You have recorded your learnings and summarized your feedback session very well.
The insights and comments shared by your users at this stage are vital for the further development of your ideas.
Now it will be your job to address these learnings before you start prototyping.`);
  }

  if (B12 && !B13 && !A12) {
    ret.push(`Thank you for submitting a piece of the feedback you received.
However, ideally, after talking to your participant for several minutes, we would expect you to make more learnings than just those included in this quote.

In the future, you should try to include more details and learnings from your user feedback sessions.
The insights and comments shared by your users at this stage are vital for the further development of your ideas.
This is why it is important to record and summarize them well.`);
  }

  if (A12 && B12 && !B13) {
    ret.push(`Thank you for sharing another excerpt from the feedback you received. 
We appreciate your effort, but we'd like to encourage you to include more quotes and insights from your feedback session. Gathering a diverse range of comments and perspectives at this stage is crucial for the continued development of your ideas. That's why it's essential to not only record but also summarize these valuable inputs effectively.`);
  }

  /*
  For repeated feedback
  */

  if (B4) {
    ret.push(`Like in your answer above, you did not describe and summarize your feedback session well enough.
Please try to be more detailed in the future.`);
  }

  if (B5) {
    ret.push(`You did a good job summarizing your learnings from this second feedback session.
As mentioned before, it is important now to use these learnings to improve your ideas before continuing your work.`);
  }

  if (B6) {
    ret.push(`You did a good job summarizing your learnings from this second feedback session.
However, as mentioned before, we advise to record your learnings in more detail in the future.
Doing so will allow you to revisit these findings in the future with no risk of forgetting something relevant.`);
  }

  if (B10 && !B11) {
    ret.push(`However, you have only submitted your feedback for one of your ideas, not both as requested.
As we still have not selected any of them, it is crucial to get feedback for all of your selected ideas for now.`);
  }

  if (B13 && C13) {
    ret.push(`Thank you for submitting this additional feedback.
Again, it would have been better if you had stated more than just one quote.`);
  }

  if (B13 && !C13) {
    ret.push(`Again, it would have been better if you had stated more than just one quote from your feedback session.
These sessions with your users are invaluable to the development of your idea.
Make sure to learn as much as possible from them. They are a great opportunity to learn more about the problem you are trying to solve and to recalibrate your assumptions and insights.`);
  }

  return ret;
};
