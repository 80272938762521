import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A4 === false) {
    ret.push(`Only one Idea Napkin submitted.`);
  }

  if (A2 && A5) {
    ret.push(
      `You have filled out all the necessary text fields for both your Idea Napkins. Well done! This will make it easier for you to explain your idea to other people.`
    );
  }

  if (A2 && !A4 && !A5) {
    ret.push(
      `You have filled out all the necessary text fields for your Idea Napkin. Well done! This will make it easier for you to explain your idea to other people. Next time please make sure to submit both napkins as asked.`
    );
  }

  if (A2 && A4 && !A5) {
    ret.push(
      `While you have filled out all the necessary text fields for your first Idea Napkin, your second napkin has some missing fields. Please ensure that all the relevant information (User, Value Proposition, Pains/Challanges, and Features/Functions/Resources are filled out). This will make it easier for you to explain your idea to other people.`
    );
  }

  if (!A2 && A5) {
    ret.push(
      `While you have filled out all the necessary text fields for your second Idea Napkin, your first napkin has some missing fields. Please ensure that all the relevant information (User, Value Proposition, Pains/Challanges, and Features/Functions/Resources are filled out). This will make it easier for you to explain your idea to other people.`
    );
  }

  if (!Z0 && !Z1 && A2 === false && A4 === false) {
    ret.push(
      `Your Idea Napkin has some missing text fields. Please ensure that all the relevant information (User, Value Proposition, Pains/Challanges, and Features/Functions/Resources) are filled out. This will make it easier for you to explain your idea to other people.`
    );
  }

  if (!Z0 && !Z1 && A2 === false && A5 === false && A4) {
    ret.push(
      `You have not filled out all the necessary text fields for both your Idea Napkins. Please ensure that all the relevant information (User, Value Proposition, Pains/Challanges, and Features/Functions/Resources) are filled out. This will make it easier for you to explain your ideas to other people.`
    );
  }

  if (A3 && A6) {
    ret.push(
      `Good job! Both your Idea Napkins have a visualization of your idea in the center. This will make it easier for other people to understand your ideas`
    );
  }

  if (A3 && A4 === false && A6 === false) {
    ret.push(
      `Good job! You have included a visualization for your idea in the center of your Idea Napkin. This will make it easier for other people to understand your idea.`
    );
  }

  if (A3 && A6 === false && A4) {
    ret.push(
      `Only your first idea has a visualization included in its Idea Napkin. Always try to include some simple sketch or picture that will help your users understand your idea better.`
    );
  }

  if (A3 === false && A6) {
    ret.push(
      `Only your second idea has a visualization included in its Idea Napkin. Always try to include some simple sketch or picture that will help your users understand your idea better. This might seem irrelevant, but sometimes a simple sketch can make a lot of difference!`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A3 === false && A4 === false) {
    ret.push(
      `Your Idea Napkin does not include a visualization of your idea. Always try to include some simple sketch or picture that will help your users understand your idea better. This might seem irrelevant, but sometimes a simple sketch can make a lot of difference!`
    );
  }

  if (!Z0 && !Z1 && !Z2 && A3 === false && A6 === false && A4) {
    ret.push(
      `None of your ideas have a visualization included in its Idea Napkin. Always try to include some simple sketch or picture that will help your users understand your idea better.`
    );
  }

  return ret;
};
