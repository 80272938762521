import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);
  /*
    { id= "A0", text= "No submission", points= 0 },
    { id= "A1", text= "Feedback for first idea stated", points= 0.5 },
    { id= "A2", text= "Feedback is well described and clear", points= 1 },
    { id= "A3", text= "Good submission", points= 1 },
*/
  if (A0) {
    ret.push(`No feedback submitted`);
  }

  if (A1 && A2 === false) {
    ret.push(`Thank you for submitting this feedback.
However, we believe that you should have been a little bit more detailed to ensure that no information is lost.`);
  }

  if (A2) {
    ret.push(`Thank you for submitting the feedback received from your first user.
You have stated it very clearly.`);
  }

  return ret;
};
