import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for taking the time and writing down your key takeaways. You have a great understanding of what has worked for you and what are the different ways to improve. With this information, you are also well aware of what the next steps are going to be for your project. `
    );
  }

  if (A2) {
    ret.push(
      `Thank you for taking the time and writing down your key takeaways. You have a good understanding of what has worked for you and what are the different ways to improve. With this information, you can come up with what the next steps are going to be for your project. `
    );
  }

  if (A3) {
    ret.push(
      `Thank you for writing down your key takeaways. Hopefully you have an understanding of what has worked for you and what are the different ways to improve. With this information, you can come up with what the next steps are going to be for your project. `
    );
  }

  return ret;
};
