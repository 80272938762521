import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A4) {
    ret.push(`Thank your for your answer!
However, we noticed how you did not state again which insight you have picked to answer the questions above.
We can still guess based on your argumentation, but in the future, we ask you to always make your answers very clear so that we can give you better and more effective feedback.
Below you will find the feedback for your answer:`);
  }

  if (A0) {
    ret.push(`No submission`);
  }

  if (A1) {
    ret.push(`Well done.
You have given very good arguments to explain how this insight is supported by evidence collected during your user research.
The evidence you gave is also direct evidence (quotes, findings, observations, etc), which makes your logic more credible. Good job!`);
  }

  if (A2) {
    ret.push(`Good job. While you did give good arguments to explain how this insight is supported by evidence collected during your user research, you did not give enough direct evidence to support that (quotes, findings, observations, etc).
Ideally, you could try to quote some of your participants or mention how often a certain need/issue/desire was mentioned during your interviews.
Doing so would give your arguments even more credibility!`);
  }

  if (A3) {
    ret.push(`We feel like you did not argument well enough to explain how this insight is supported by evidence collected during your user research.
Ideally, you should try to quote some of your participants or mention how often a certain need/issue/desire was mentioned during your interviews.
Doing so would give your arguments even more credibility!`);
  }

  /*

    { id= "A0", text= "No submission", points= 0 },
    { id= "A1", text= "Very good arguments with direct support (quotes, findings...)", points= 3 },
    { id= "A2", text= "Good arguments but no direct support ()", points= 1.5 },
    { id= "A3", text= "Bad argumetation with no support ()", points= 0.5 },

  */

  return ret;
};
