import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
const ret: string[] = [];
const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7} = getSelectedOptionsFlags(selectedOpts);


/*
{id = "#4", text = "-------------------------- Pick One ------------------------", points = 0},
{id = "A1", text = "Actions are relevant to the inconsistencies and industry maturity identified", points = 3},
{id = "A2", text = "Actions are SOMEWHAT relevant to the inconsistencies and industry maturity identified", points = 1},
{id = "A3", text = "Actions are NOT relevant to the inconsistencies and industry maturity identified", points = 0}, 
{id = "#3", text = "------------------------- Pick One -------------------------", points = 0},
{id = "A4", text = "Actions are well explained and justified", points = 2},
{id = "A5", text = "Actions are acceptably explained and justified", points = 1},
{id = "A6", text = "Actions are poorly explained or justified", points = 0},
*/

if (A1) {
ret.push(
`The actions you have outlined here seem to be relevant not only to the business model inconsistencies you have identified earlier, but also in the context of the maturity stage of the industry you are in. 
You have done a good job assessing and understanding the areas of growth for your business, and how you can move forward with this assignment and test and implement effective changes to your business model.
Very well done!`
);
}

if (A2) {
ret.push(
`The actions you have outlined here seem to be quite relevant not only to the business model inconsistencies you have identified earlier, but also in the context of the maturity stage of the industry you are in. 
Assessing and understanding the areas of growth for your business is a key skill that will help you test and implement effective changes to your business model later.`
);
}

if (A3) {
ret.push(
`The actions you have outlined here do not seem to be relevant to the business model inconsistencies you have identified earlier. 
Furthermore, we advise to evaluate better how you can include your business' industry maturity stage into your business strategy moving forward. 
Assessing and understanding the areas of growth for your business is a key skill that will help you test and implement effective changes to your business model later.`
);
}

if (A6) {
    ret.push(
    `However, we ask you to explain and describe any actions you are planning to take in more detail in the future.
It may seem unnecessary, as you may feel like you know exactly what you mean by them, but you will see that writing down very clear next steps is very beneficial.
Not only does it help you ensure that nothing is lost or forgotten, but it will also make it easer for you to understand what (and why) you did what you did, should you ever need to look back at your decisions.  `
    );
    }

return ret;
};
