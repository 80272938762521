import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push("Thank you taking the time to add an additional comment.");
  }

  if (A3) {
    ret.push(`Thank you for your comment. 
While we agree that it might have been very beneficial to start this program with this module (In fact, this used to be the case), we decided against that for two reasons.
First, when this was our first module, many participants felt like this program was not focused on design thinking and corporate innovation as promised. Their initial expectations were not met and this sometimes had lasting effects in their motivation to work on this program.
Second, Because this is also the shortest and least-intense module, it could make the User Discovery and Needfinding Module seem even harder than it already feels. We've noticed that starting with the most intense module and then slowly reducing the required effort for each subsequent module helps keep the participants of this program motivated and engaged.

Still, we hope that by holding this at the end we could give you the opportunity to reflect on your team experience and consider ways to carry these learnings into your future teams.`);
  }

  return ret;
};
