import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `You identified a good variety of different types of value your project can create and indicated if each is tangible or intangible. Well done coming up with these different types of value!  Thinking about these types of value is important for understanding how you can best account for value generated throughout the project. Especially if the value is intangible, you will likely need to design inventive ways of accounting and communicating the value, otherwise your project may risk being undervalued.`
    );
  }

  return ret;
};
