import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Great job summarizing your learnings and insights.
We are happy to see that this experiment was a success and that you have made so many learnings.`
    );
  }

  if (A2) {
    ret.push(
      `Great job summarizing your learnings and insights.
We are happy to see that this experiment was a success and that you have made so many learnings.`
    );
  }

  return ret;
};
