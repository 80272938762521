import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2 && A3) {
    ret.push(
      `Well done!
Your core element is well explained and is a good representation of your idea's main features. `
    );
  }

  if (A1 && A2) {
    ret.push(
      `Well done!
Your core element is a good representation of your idea's main features.
We are happy to see that you can abstract your idea and identify its key feature.`
    );
  }

  if (!Z0 && !Z1 && A3 === false && A2) {
    ret.push(
      `Are you sure that this element is central to your idea? The connection is not clear to us. Please reflect on your idea and decide if this really is the core element. If it is, please change your submission so that this connection is clear. If it is not, please adapt your idea or change your stated core element.`
    );
  }

  if (!Z0 && !Z1 && A3 === false && A2 === false) {
    ret.push(
      `How is this core element related to your idea? The connection is not clear to us.
Please reflect on your idea and decide if this really is the core element. If it is, please change your submission so that this connection is clear. If it is not, please adapt your idea or change your stated core element.`
    );
  }

  if (!Z0 && !Z1 && A4 === false && (A2 || A3)) {
    ret.push(
      `Are you sure that this is the core element of your idea?
The core element of your idea should represent its most essential feature. The thing that makes it valuable to your customers after you strip down all the other details.
Explaining what it is and why it is the core element will help you understand it better and help you evaluate if this is really its most essential feature.
Therefore, please go over your submission again and reflect if this is really the best "summary" of your ides. Then, state your core feature again, this time making sure that it is very clear why it is the case.`
    );
  }

  if (!Z0 && !Z1 && A2 === false && A3) {
    ret.push(
      `You did not explain why this is the core element of your idea.
This was not asked in the question and is not mandatory, but explaining why it is the core element will help you understand it better and help you evaluate if this is really its most essential feature.
Therefore, we advise you to always explain key parts of your submission in the future.`
    );
  }

  return ret;
};
