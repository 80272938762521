import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { BrowserRouter, Route } from "react-router-dom";
import { ThemeProvider } from "theme-ui";
import { theme } from "@sparkademy/app-common/materials/theme";
import { SessionContextProvider } from "@sparkademy/app-common/contexts/session-context";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <SessionContextProvider>
        <ThemeProvider theme={theme}>
          <Route path="/" component={App} />
        </ThemeProvider>
      </SessionContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
