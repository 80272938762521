import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission. `);
  }

  if (Z1) {
    ret.push(
      `Good job!
You have stated clear actions that you can take to improve your team work.
Now, you should work on making sure to implement these tasks on your day-to-day work.
Until these are natural for you, you will have to actively remind yourself of them.`
    );
  }

  return ret;
};
