import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13 } =
    getSelectedOptionsFlags(selectedOpts);

  /* Quantity */

  if (A1) {
    ret.push(`It seems to us that you may have too many clusters.
There is no rule to define how many clusters one should make, but you should only make more clusters if they are really all very different and relevant. As soon as your clusters start to overlap, then you should stop. Otherwise, you have so many clusters that you end up not clustering at all.
Take a look at your clusters and reassess if they are all unique and relevant. Maybe you could break apart one or two and move those learning notes to other clusters.
Clustering your learning notes well is important because it reduces the amount of data you will work with in the next steps without losing any relevant information. Imagine having to go through all of your learnings notes every time you wanted to grasp your user needs and observations.`);
  }

  if (A2 || A3 || A4) {
    ret.push(`You have generated a good amount of clusters and they are all relevant and unique. This is a good indicator that you have clustered well (It is still possible that you have clustered too much, but since there is no large overlap between your clusters, you probably did not over-cluster).
These clusters still contain all the main findings but reduce the amount of data you are working with. This makes your next steps much easier (Imagine having to go through all of your learnings notes every time you wanted to grasp your user needs and observations).`);
  }

  if (A5 || A6) {
    ret.push(`Are you sure that these clusters you made summarise all your main learnings? From the number of interviews you and your teammates did, we think you could have found more ways to cluster your learnings than what you shared here.
As a rule of thumb, each cluster can lead to one insight (although sometimes more). Therefore, having too few clusters can reduce the number of insights you will end up with. We advise you to look at all of your teams' cluster wall again and think if you cannot identify more, smaller clusters.
A good way to test if you have too many clusters is to try to look for overlaps between your clusters.`);
  }

  /* Summaries */

  if ((A7 || A8) && (A1 || A5 || A6)) {
    ret.push(`On the other hand, you have included good summaries to your clusters.
This may seem of little relevance now, but as you progress with your project you will forget most of the learnings you have made so far and having a good summary will ensure that nothing goes lost and will also help you understand your thought process in the future.
It is in this case similar to writing comments while you code. It may seem unnecessary now, but if you ever try to go over it in the future, these comments will be life saving!`);
  }

  if (A9 && (A1 || A5 || A6)) {
    ret.push(`Another thing we would like to point out is that your cluster summaries are not great.
This may seem of little relevance now, but as you progress with your project you will forget most of the learnings you have made so far and having a good summary will ensure that nothing goes lost and will also help you understand your thought process in the future.
It is in this case similar to writing comments while you code. It may seem unnecessary now, but if you ever try to go over it in the future, these comments will be life saving!
This is why we urge you to write down these summaries as clear and detailed as possible while you are still conducting your User Research.`);
  }

  if (A13 && (A1 || A5 || A6)) {
    ret.push(`Another thing we would like to point out is that you have not included a small summary to your clusters.
This may seem of little relevance now, but as you progress with your project you will forget most of the learnings you have made so far and having a good summary will ensure that nothing goes lost and will also help you understand your thought process in the future.
It is in this case similar to writing comments while you code. It may seem unnecessary now, but if you ever try to go over it in the future, these comments will be life saving!
This is why we urge you to write down these summaries as clear and detailed as possible while you are still conducting your User Research.`);
  }

  if ((A7 || A8) && !(A1 || A5 || A6)) {
    ret.push(`Furthermore, you have included good summaries to your clusters.
This may seem of little relevance now, but as you progress with your project you will forget most of the learnings you have made so far and having a good summary will ensure that nothing goes lost and will also help you understand your thought process in the future.
It is in this case similar to writing comments while you code. It may seem unnecessary now, but if you ever try to go over it in the future, these comments will be life saving!`);
  }

  if (A9 && !(A1 || A5 || A6)) {
    ret.push(`On the other hand, your cluster summaries are not great.
This may seem of little relevance now, but as you progress with your project you will forget most of the learnings you have made so far and having a good summary will ensure that nothing goes lost and will also help you understand your thought process in the future.
It is in this case similar to writing comments while you code. It may seem unnecessary now, but if you ever try to go over it in the future, these comments will be life saving!
This is why we urge you to write down these summaries as clear and detailed as possible while you are still conducting your User Research.`);
  }

  if (A13 && !(A1 || A5 || A6)) {
    ret.push(`On the other hand, you have included summaries to your clusters, but they could be slightly more detailed.
This may seem of little relevance now, but as you progress with your project you will forget most of the learnings you have made so far and having a good summary will ensure that nothing goes lost and will also help you understand your thought process in the future.
It is in this case similar to writing comments while you code. It may seem unnecessary now, but if you ever try to go over it in the future, these comments will be life saving!`);
  }

  /* Additional stuff */

  if (A10) {
    ret.push(
      `Furthermore, your cluster summaries should be standalone statements that are clearly written and easy to understand. Don't forget that we will not work with the learning notes anymore. Therefore, it is important that your cluster and cluster summaries are clear and contain all of the key findings so that no relevant information is lost.`
    );
  }

  if (A11) {
    ret.push(
      `Additionally, some of your summaries are not detailed enough. We recommend you go over all of your clusters and evaluate if your summaries do summarise all the key learnings from each cluster.`
    );
  }

  if (A12) {
    ret.push(
      `Lastly, some of your clusters do not include summaries. These are very important to make sure that all the relevant findings are stored for the next steps of your research.`
    );
  }

  return ret;
};
