import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, C1, C2, C3, C4, C5, C6, C7, C8 } = getSelectedOptionsFlags(selectedOpts);

  if (C1) {
    ret.push("Thank you for this additional file.");
  }

  if (C2 && !C3) {
    ret.push(
      `Thank you for submitting the list of all the topics you and your team have thought of.`
    );
  }

  if (C3 && !C2) {
    ret.push(
      `Thank you for sharing your assumptions mapped in relation to the topics mentioned above.`
    );
  }

  return ret;
};
