import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A0) {
    ret.push(
      `Thank you for your answer.
However, this is not really an Energizer.
Your idea may even help you conduct your session, but the idea of an Energizer is to activate your participants and put them into the right mindset.
Often, it might be best to start with a simple game or something similar.
For suggestions we advise you to take a look at the Energizers stated on our course once more.`
    );
  }

  if (A1) {
    ret.push(
      `Thank you for stating which Energizer you will use.
It seems to be a good fit for your participants and will help you motivate them and make sure that they are in a very active and creative state.`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for stating and describing which Energizer you will use.
It seems to be a good fit for your participants and will help you motivate them and make sure that they are in a very active and creative state.`
    );
  }

  if (A3) {
    ret.push(
      `Thank you for stating more than one Energizer you plan to use during your ideation session.
They seem to be a good fit for your participants and will help you motivate them and make sure that they are in an active and creative state.`
    );
  }

  if (A4) {
    ret.push(
      `Thank you for stating which Energizer you would use for both a virtual and for a in-person session.
We hope you will have the opportunity to test both!`
    );
  }

  if (A5) {
    ret.push(
      `Cool Energizer!
If you haven't yet, please also consider sharing this Energizer in the community! Your fellow learners will certainly appreciate it.`
    );
  }

  if (A6) {
    ret.push(
      `Thank you for not only stating and describing which Energizer you will use, but also sharing a brief overview of how your ideation session will look like!
The Energizer seems to be a great fit for your session and will help you motivate your participants and make sure that they are in an active and creative state.`
    );
  }

  return ret;
};
