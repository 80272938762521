import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for creating a full storyline around your project. A storyline will help you to prepare presentations or a pitch and convince your audience to take action.`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for creating a storyline around your project. A storyline will help you to prepare presentations or a pitch and convince your audience to take action. By adding more details to your storyline, your story will be more convincing an easier to share.`
    );
  }

  if (A3) {
    ret.push(
      `Thank you for submitting your storyline. A storyline will help you to prepare presentations or a pitch and convince your audience to take action. Congrats on starting this journey. To share your story and convince your audience, aim to fill out all the elements of the storytelling template. `
    );
  }

  if (A4) {
    ret.push(
      `The goal you are mentioning clearly aligns with the project topic. `
    );
  }

  if (A5) {
    ret.push(
      `Your character and his situation and goals are well defined and convincing. `
    );
  }

  if (A6) {
    ret.push(
      `Great job, your story connects to the target audience and involves the listener.`
    );
  }

  if (A7) {
    ret.push(
      `The solution clearly connects the challenge, goal, and the added value in a coherent way.`
    );
  }

  if (A8) {
    ret.push(
      `Your AHA moment is a convincing and memorable statement.`
    );
  }

  if (A9) {
    ret.push(
      `You end up with a clear and actionable call to action to the audience. Well done!`
    );
  }

  if (Z0 === false && Z1 === false && !A4) {
    ret.push(
      `It is not clear yet, how the goal you are sharing connects to the project. `
    );
  }

  if (Z0 === false && Z1 === false && !A5) {
    ret.push(
      `To improve your story, you can work on setting the scene and providing more context about the character and his goals.`
    );
  }

  if (Z0 === false && Z1 === false && !A6) {
    ret.push(
      `The more targeted your story is to your audience, the easier it is to convince them. Try to work on the common ground and involve the audience.`
    );
  }

  if (Z0 === false && Z1 === false && !A7) {
    ret.push(
      `Your solution does connects the challenge, goal, and the added value in a coherent way. This does not mean that you need to change the solution, but maybe the way you communicate it.`
    );
  }

  if (Z0 === false && Z1 === false && !A8) {
    ret.push(
      `Aim to include a memorable AHA moment in your story. You can try it out with anyone you know - do they remember your AHA moment a few hours after you shared it? If no, you probably need to reformulate it.`
    );
  }

  if (Z0 === false && Z1 === false && !A9) {
    ret.push(
      `A storyline behind a presentation or pitch can act as a tool to get things moving. Give your audience a clear call to action based on what you expect from them.`
    );
  }

  return ret;
};
