import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(` Your interview guideline is very well structured. We liked how you used learning goals to structure your interview. This is a very good approach that will help you cover all the relevant topics.

Now, let’s talk about your introduction.
You’ve done a good job introducing yourself and the topic of this interview as well as informing your interviewee of the length of the interview.
The only thing you could have done slightly better would be to emphasise more how there are no right or wrong answers and that any comment (positive or negative) will be helpful to you. We know that you have touched upon this, but making it even more clear might help your participant feel more at ease.
Otherwise your introduction is perfect, You have used a very clear and friendly language as well as included some good opening questions to start the conversation.

Looking at the core part of your interview: all of your questions are clear and well written.
They are not biased and follow a clear red line that starts with “easier topics” and move on to more demanding and revealing questions.
You have also done a good job including several follow-up questions and preparing for different answers with different questions. This is a great approach and should be kept in your future interview preparations.
There is one thing you could improve though, which is the depth of some of your questions.
Sometimes you ask about what is working well for them but not what is not working well. Understanding this is very important and can actually give you even more relevant information than understanding what is working (because we are focusing on the issues for now).
If you did not include that because you thought that it is a little too direct or revealing, you can try to ask this towards the end of your interview, when your interviewee is more at ease and is more comfortable with you.
Still, all in all your questions are really good. Well done!

To your conclusion:
Your conclusion is also good and includes all the key elements (thank you + final questions + future participation).
Th only thing you could improve here is to write out exactly what you would say instead of just describing it. (You did do that for the “thank you” and for  the ” final questions” but not for the future participation).
Final comment from us:
Overall a very well written interview guideline. Very well done!`);
  }

  return ret;
};
