import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No answer submitted.`);
  }

  if (Z1) {
    ret.push(
      `It seems like you reflected well on this topic.
Hopefully, this has helped you better understand what effects feedback can have on both the giver and the receiver.

One last tip we can give you is to always prepare your feedback sessions by thinking on what you would like to achieve .
If you can not clearly visualize how this feedback session will help your colleague and your organization, then it may be better not to say anything.`
    );
  }

  return ret;
};
