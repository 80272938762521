import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Nice work filling out this cost estimation sheet. You included a suitable number of people to run and grow the project along with an estimate of their cost and time involvement. You also included several materials and services and the costs for your project. Overall, you have covered all of the important items to consider in getting to an initial cost estimate.
Keep in mind, there will likely be unforeseen costs as your project progresses, so it is important to consider this room for error in a magnitude analysis.`
    );
  }

  if (Z2) {
    ret.push(
      `Perfect! You have listed all the people required to run and grow the project. You considered their value in terms of cost and the time commitment rquired by them. You have also included all the material, acquired services and considered other expenses that you expect in the next 12 months to run and grow the project in detail. You  covered all costs thoroughly and thought carefully about each resource involved. These costs will give you an estimate of the the total direct costs that will occur in your innovaton project in the first year. This is a great place to start when proposing a budget. Keep up the good work!`
    );
  }

  return ret;
};
