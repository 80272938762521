import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Great work! You have suggested strategies that are well aligned for your industry's lifecycle phase, and you provided strong justifications for why your strategy suggestions make sense and can be effective.`,
    )
  }

  return ret;
};
