import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for your thoughtful answer! We are glad to see how much you learned from the process. It’s great that you have clear ideas for improvements and how to move forward with your user journey. We hope that this helps you generate opportunities areas and new ideas for the final two exercises.`);
  }

  if (A2) {
    ret.push(
      `Thank you for your reflection. We are glad to see you learned from this process. It’s good that you could identify some opportunities for improvements, and we hope that you continue to reflect on what you learned from user research and testing your assumptions as you move forward with the user experience design process.`
    );
  }

  if (A3) {
    ret.push(
      `Thank you for your answer. However, we don’t understand what your key takeaways and learnings were from this process. We had hoped this exercise would inspire you to see opportunity areas or ways to improve. We recommend taking more time on your reflections in the future.`
    );
  }

  return ret;
};
