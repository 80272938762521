import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Wasn't there anything you learned through this experiment?
Maybe something about your target user and their behavior or something about your solution?
A good indicator that your test was well designed, is a very insightful experimentation.

If there really were no learnings, then it is likely that your test was not designed well enough.
In a real-world project you should then assess what went wrong and run an improved version of your experiment before continuing.`);
  }

  if (A2) {
    ret.push(`Are these really all the learnings you could make?
A good indicator that your experiment was well designed, is a very insightful experimentation.

If there really were no more learnings, then this is a sign that your experiment could have been designed better.
In a real-world project you should then assess what went wrong and run an improved version of your experiment before continuing.`);
  }

  if (A3) {
    ret.push(`Thank you for sharing the outcomes of your experiment.
It seems that your experiment was fruitful and allowed you to learn more about your user, their pain-point and your solution.`);
  }

  if (A7 && (A1 || A2)) {
    ret.push(`Furthermore, in the future we ask you to submit your answers in more detail.
This will not only help us assess better how well you understood and applied the concepts taught in this module, but it will also allow us to give you more effective feedback.`);
  }

  if (A7 && A3) {
    ret.push(`However, we ask you to submit your answers in more detail.
This will not only help us assess better how well you understood and applied the concepts taught in this module, but it will also allow us to give you more effective feedback. `);
  }

  if (A9 && (A1 || A2)) {
    ret.push(`On the other hand, we appreciate the level of detail employed in your answer.`);
  }

  if (A9 && A3) {
    ret.push(`Furthermore, we appreciate the level of detail employed in your answer.`);
  }

  if (A3) {
    ret.push(`To conclude, we would like to point out that, regardless of whether you have validated or invalidated your hypothesis, the sheer amount of new learnings and insights made have already shown that this experiment was successful. Think of how much better you understand your user, their problem and your solution after this very short and cheap experiment.
The goal now is to keep testing, learning and improving your idea. This is what prototyping is all about!`);
  }

  return ret;
};
