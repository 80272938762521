import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 || A2) {
    ret.push(`Thank you for stating one of the ideas generated in the last module that you would like to validate.
Its description is very clear and will help us evaluate the answers below.`);
  }

  if (A3) {
    ret.push(`Thank you for stating one of the ideas generated in the last module that you would like to validate.
Its description will help us evaluate the answers below.`);
  }

  if (A4 || A5) {
    ret.push(`Thank you for stating one of the ideas generated in the last module that you would like to validate.
However, it's description is not detailed enough.
We ask you to describe your solutions better in the future. This will help us understand and assess your answers better.`);
  }

  if (A6) {
    ret.push(`However, we would like to point out that it is better to focus on only one idea for now.
We understand that it may be hard to pick just one, but working on several ideas simultaneously will make it harder for you to focus on them in detail.
In general, it is better to pick one idea and validate or scrap before working on another.`);
  }

  if (A7) {
    ret.push(`However, we suggest that you narrow down your target audience to a specific subset of users who face the most challenges with this issue. By doing so, you can focus on their specific needs and create a more targeted and effective experiment. 
We recommend using the insights gathered during your user discovery to identify these early adopters and tailor your solution to their needs. This approach will increase the chances of success for your product and make it more appealing to your target audience.`);
  }

  return ret;
};
