import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Well done. You suggested good improvements of your company's current innovation metrics. `
    );
  }

  if (!Z0 && !Z1 && !Z2 && !A1) {
    ret.push(
      `You did not provide a suggestion on how to improve the current metrics. Have a look at your previous exercise again as well as the criteria of good KPIs. Are you sure the current metrics are predictive, actionable (not vanity metrics!), measurable, and relevant?`
    );
  }

  if (A2) {
    ret.push(
      `Additionaly, you shared new metrics that could be measured by your company to get a full picture about the innovation journey and success. `
    );
  }

  if (!Z0 && !Z1 && !Z2 && !A2) {
    ret.push(
      `Additionaly, you could have suggested new metrics in the areas you identified as uncovered in the previous exercise.`
    );
  }

  return ret;
};
