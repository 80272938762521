import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, B1, B2, B3 } = getSelectedOptionsFlags(selectedOpts);

  if (B1) {
    ret.push(
      `Thank you for making it very clear how you and your team will share knowledge amongst yourselves. Identifying a systematic approach for this now will make it less likely that any vital information is lost.`
    );
  }

  if (B2) {
    ret.push(`Thank you for making it clear how you and your team will share knowledge amongst yourselves. Identifying a systematic approach for this now will make it less likely that any vital information is lost.
In order to devise an even better system, we advise you to think more about the details of how you will share it.`);
  }

  if (B3) {
    ret.push(`You did not make it clear enough how you and your team will share knowledge amongst yourselves. Identifying a systematic approach for this now will make it less likely that any vital information is lost.
In order to devise a better system, we advise you to think more about the details of how you will share it.`);
  }

  return ret;
};
