/** @jsx jsx */
import { Box, Heading, jsx, Label, Text } from "theme-ui";
import * as React from "react";
import { Checkbox } from "@sparkademy/app-common/elements/Checkbox";
import { MarkdownContent } from "@sparkademy/app-common/components/assignment/MarkdownContent";
import { UploadedFileItem } from "@sparkademy/app-common/components/assignment/UploadedFileItem";
import {
  GradingCriteria,
  GradingCriterion,
  Question,
  ExerciseSection,
  UploadedFile,
} from "@sparkademy/app-common/models/assignment";
import { useAssignmentAnswersContext } from "@sparkademy/app-common/contexts/assignment-answers-context";
import { useGradingContext } from "../contexts/grading-context";

export const EvaluationItemCard: React.FC<{
  section: ExerciseSection;
}> = ({ section }) => {
  const { currentSubmission, setEvaluationData } = useGradingContext();
  const { savedCriteriaApplied } = useAssignmentAnswersContext();

  const alwaysCheckedOptions = section.questions
    .filter(q => q.grading_initial_points > 0)
    .map(q => q.pk.toString());

  const [selectedOptions, setSelectedOptions] = React.useState<string[]>(
    // select options that have grading initial points
    // see: src/services/grading.ts -> setQuestionsGradingInitialPoints
    alwaysCheckedOptions
  );

  React.useEffect(() => {
    if (!currentSubmission) return;

    const localData = sessionStorage.getItem(`${currentSubmission.id}-${section.id}`);
    let savedOpts: string[] = [];
    if (localData) {
      savedOpts = JSON.parse(localData) || [];
      setSelectedOptions(savedOpts);
      setEvaluationData(section.id, savedOpts);
    } else if (savedCriteriaApplied[section.id]) {
      // savedCriteriaApplied is of form: {"1.1":[{"A1":5}], "1.2": []}
      const criteriaApplied = [];
      for (let criterion of savedCriteriaApplied[section.id]) {
        for (let key in criterion) {
          criteriaApplied.push(key);
        }
      }

      setSelectedOptions(criteriaApplied);
      setEvaluationData(section.id, criteriaApplied);
    }
  }, [currentSubmission, section.id, setEvaluationData, savedCriteriaApplied]);

  const maximumPoints = section.questions.reduce((acc, question) => acc + question.points, 0);

  const allGradingCriteria = section.questions
    .flatMap(q => q.grading_criteria_sets.flatMap(set => set.options))
    .concat(section.grading_criteria_options || []);

  const currentPoints = allGradingCriteria.reduce(
    (acc, crit) => acc + (selectedOptions.includes(crit.grading_id) ? crit.points : 0),
    0
  );

  const checkOption = (optionId: string, group?: GradingCriteria) => {
    let newOpts;
    const mutuallyExclusiveOpts = ["Z0", "Z1", "Z2", "Z3", "Z4", "Z5", "Z6", "Z7", "Z8", "Z9"];

    const isPartiallyExclusiveOpt = group?.type === "single";

    if (mutuallyExclusiveOpts.includes(optionId)) {
      // chosen box is a mutually exclusive option so it gets checked alone
      newOpts = [optionId];
    } else if (selectedOptions.includes(optionId)) {
      // chosen box is already selected so we deselect it
      newOpts = selectedOptions
        .filter(opt => !mutuallyExclusiveOpts.includes(opt))
        .filter(opt => opt !== optionId);
    } else if (group && isPartiallyExclusiveOpt) {
      // chosen box is part of a mutually exclusive group of options
      newOpts = selectedOptions
        // remove Zn options and partially exclusive options A1.1, A1.2, etc.
        .filter(
          opt =>
            !mutuallyExclusiveOpts.includes(opt) && !group.options.find(o => o.grading_id === opt)
        )
        .concat(optionId);
    } else {
      newOpts = selectedOptions
        // remove Zn options from selected
        .filter(opt => !mutuallyExclusiveOpts.includes(opt))
        .concat(optionId);
    }

    // if there's no mutually exclusive options selected, make sure the initial options are selected
    if (!newOpts.some(opt => mutuallyExclusiveOpts.includes(opt))) {
      if (alwaysCheckedOptions.length > 0) {
        if (!newOpts.includes(alwaysCheckedOptions[0])) {
          newOpts = newOpts.concat(alwaysCheckedOptions);
        }
      }
    }

    setSelectedOptions(newOpts);
    setEvaluationData(section.id, newOpts);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        bg: "white",
        width: "894px",
        mt: "48px",
      }}
    >
      <Box sx={{ display: "flex", mt: "48px" }}>
        <Box sx={{ width: "100px", fontWeight: "bold", textAlign: "center" }}>{section.id}</Box>
        <Box sx={{ maxWidth: "700px", alignSelf: "center" }}>
          <MarkdownContent content={section.content} />
        </Box>
      </Box>

      <Box
        sx={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {section.questions.map(q => (
              <ItemGroup
                key={`${currentSubmission?.id}-${section.id}-${q.id}`}
                question={q}
                selectedOptions={selectedOptions}
                checkOption={checkOption}
              />
            ))}

            <Box
              sx={{
                pr: 10,
                pl: "100px",
                pb: "48px",
                bg: "new.secondary.lightGrey",
              }}
            >
              <Text sx={{ fontSize: "16px", fontWeight: "bold", mb: "8px" }}>Quick Options</Text>
              {section.grading_criteria_options &&
                section.grading_criteria_options.map(gc => (
                  <GradingCriteriaOption
                    key={gc.grading_id}
                    option={gc}
                    selectedOptions={selectedOptions}
                    checkOption={checkOption}
                  />
                ))}
            </Box>

            <Box sx={{ my: 8, ml: "auto", textAlign: "right", pr: 10 }}>
              <Box sx={{ fontWeight: "bold", my: 4 }}>Points</Box>
              <Box sx={{ ml: "auto" }}>
                {currentPoints}/{maximumPoints} points
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const GradingCriteriaOptionGroup: React.FC<{
  group: GradingCriteria;
  selectedOptions: string[];
  checkOption: (id: string, group?: GradingCriteria) => void;
}> = ({ group, selectedOptions, checkOption }) => {
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "25px",
        alignItems: "center",
        py: "10px",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Text sx={{ textAlign: "center", width: "100%", fontWeight: "bold", my: "10px" }}>
          <u>{group.title}</u>
        </Text>
        {group.options.map(option => (
          <GradingCriteriaOption
            key={option.grading_id}
            option={option}
            group={group}
            selectedOptions={selectedOptions}
            checkOption={checkOption}
          />
        ))}
      </Box>
    </Box>
  );
};

export const GradingCriteriaOption: React.FC<{
  option: GradingCriterion;
  group?: GradingCriteria;
  selectedOptions: string[];
  checkOption: (id: string, group?: GradingCriteria) => void;
}> = ({ option, group, selectedOptions, checkOption }) => {
  return (
    <Box
      sx={{
        display: "flex",
        borderBottom: "1px solid",
        borderBottomColor: "new.secondary.grey",
        minHeight: "25px",
        alignItems: "center",
        py: "10px",
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Label sx={{ cursor: "pointer" }}>
          <Text sx={{ alignSelf: "center", width: "100%" }}>
            {option.grading_id}: {option.text} - {option.points} point(s)
          </Text>
          <Box sx={{ ml: "auto", pt: "10px" }}>
            <Checkbox
              sx={{ ml: "15px" }}
              type="checkbox"
              checked={selectedOptions.includes(option.grading_id)}
              onChange={() => checkOption(option.grading_id, group)}
            />
          </Box>
        </Label>
      </Box>
    </Box>
  );
};

export const ItemGroup: React.FC<{
  question: Question;
  selectedOptions: string[];
  checkOption: (id: string) => void;
}> = ({ question, selectedOptions, checkOption }) => {
  const { savedAnswers } = useAssignmentAnswersContext();
  const participantAnswer = savedAnswers.get(question.id);
  const [, , questionLetter] = question.id.split(".");

  return (
    <Box sx={{ width: "100%", bg: "white" }}>
      <Box sx={{ pl: "100px", my: "48px" }}>
        {question.title && (
          <Heading as="h3" sx={{ mb: 8 }}>{`${questionLetter}. ${question.title}`}</Heading>
        )}
        {question.grading_tip && (
          <Text as="p" sx={{ mb: 6, fontStyle: "italic", fontSize: "14px" }}>
            {question.grading_tip}
          </Text>
        )}

        {question.type === "text" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text as="p" sx={{ fontWeight: 700, fontSize: "16px", mb: "32px" }}>
              Participant’s Response
            </Text>
            <Text
              as="pre"
              sx={{
                fontFamily: "inherit",
                fontSize: "16px",
                wordBreak: "break-word",
                whiteSpace: "pre-wrap",
              }}
            >
              {participantAnswer || "[BLANK]"}
            </Text>
          </Box>
        )}

        {question.type === "file" &&
          ((participantAnswer as UploadedFile)?.file_url ? (
            <UploadedFileItem file={participantAnswer as UploadedFile} forGrading={true} />
          ) : (
            <Text>[No file uploaded]</Text>
          ))}
      </Box>

      <Box
        sx={{
          width: "100%",
          py: "48px",
          bg: "new.secondary.lightGrey",
        }}
      >
        <Box sx={{ pr: 10, pl: "100px" }}>
          <Text sx={{ fontSize: "16px", fontWeight: "bold", mb: "8px" }}>Grading Criteria</Text>
          {question.grading_criteria_sets
            .filter(gc => gc.title !== "Initial points")
            .map((gc, idx) => (
              <GradingCriteriaOptionGroup
                key={idx}
                group={gc}
                selectedOptions={selectedOptions}
                checkOption={checkOption}
              />
            ))}
        </Box>
      </Box>
    </Box>
  );
};
