import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for sharing the board, it looks like a very valuable resource for further teamwork! As mentioned above, please also critically reflect on each of the methods you used. Would you use those templates the exact same way, or would you make changes?
    And overall, how could you make the team-building phase even more effective?`);
  }

  return ret;
};
