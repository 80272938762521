import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Great work! Your answer shows that you though about various returns and valuable outcomes of your project, tangible as well as non-tangible ones. Your argumentation is well formulated and convinced us.`
    );
  }

  return ret;
};
