import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, B1, B2, A2, A3, A4, A5, A6, A7, F8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (B1) {
    ret.push(`Thank you for uploading this additional persona!
In your future projects you should certainly try to identify more than one. However, to keep this course a bit simpler, we ask you to pick and focus on one of your personas for the next exercises.`);
  }

  if (B2) {
    ret.push(
      `Thank you for uploading this additional information supporting your persona's characteristics!`
    );
  }

  return ret;
};
