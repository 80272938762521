import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for stating a strategy you can use to manage the two business models. Identifying a relevant strategy demonstrates consideration of possible conflicts between the two businesses as well as how they are strategically related to one another. With a strategy chosen, you can move forward in testing and implementing your two business models.`
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `You did not state a strategy you can use to manage the two business models. Go to the Managing 2+ Business Models method cards and follow the steps to identify a strategy relevant to the amount of conflict and strategic relatedness between your business models. With a strategy chosen, you can move forward in testing and implementing your two business models.`
    );
  }

  if (A2) {
    ret.push(
      `The strategy you chose is well suited to the conflicts you identified between your two business models. Your choice demonstrated knowledge of the concepts for managing 2+ business models as well as the thought about which strategy can best address the conflicts present. Choosing a relevant strategy is the foundation of how you will move forward in managing your business models.`
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `The strategy you chose is not well suited to the conflicts you identified between your business models in the previous question. Go back to the module that covers managing 2+ business models and follow the steps in the corresponding method card to get a better understanding of how to address conflicts between business models through one of the four strategies described. Choosing a relevant strategy is the foundation of how you will move forward in managing your business models.`
    );
  }

  if (A3) {
    ret.push(
      `You have provided a clear explanation of why the strategy you chose is relevant and well suited to the conflicts and relatedness between your two business models. You demonstrated an understanding of the concepts presented in the modules and thorough thought into which strategy fits your business models the best. Determining how to manage multiple business models is a difficult task, and you have done the groundwork of creating an effective strategy, and you're ready to figure out how to implement it!`
    );
  }

  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `You did not provide a clear explanation of why the strategy you chose is well suited to the conflicts and relatedness between your two business models. Using the framework provided in the Managing 2+ Business Models method card, discuss how the strategy you chose effectively addresses the conflicts between your business model while taking into consideration how they are strategically related. Determining how to manage multiple business models is a difficult task, it requires thorough thought to choose the right strategy and to figure out how to implement it`
    );
  }

  return ret;
};
