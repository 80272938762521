import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for the submission! You have identified a variety of different risks you may encounter while developing your project further. You can never be sure that you included every possible risk around your project, but you probably detected the most urgent ones.`
    );
  }

  if (A3 && A4) {
    ret.push(
      `You did a good job plotting the risks you have identified in your risk evaluation matrix. Your assessment of likelihood and impact seem to fit the mentioned risks very well.`
    );
  }

  if (A2) {
    ret.push(
      `Thank you for your submission. You have identified some risks you may encounter while developing your project further. Even though you can never be sure that you included every possible risk around your project, there are probably some more risks involved in your project! Have a look at the method card risk identification again and thik about risks in all the different areas: market, technical, timing, cost, price, quality. `
    );
  }

  if (A3) {
    ret.push(
      `However, you have only identified a few different risks that are relevant for your project. There are certainly more risks involved in your project that should be addressed or at least considered for now.
Have a look at the method card risk identification again and thik about risks in all the different areas (market, technical, timing, cost, price, quality) that you could at to your matrix. Thinkng about this right now might help you identify less obvios risks now, when you still have time and resources to avoid or at least reduce its impact. `
    );
  }

  if (A1 && A4) {
    ret.push(
      `Furthermore, you did a good job plotting those risks in the risk evaluation matrix. Your assessment of likelihood and impact seem to fit the mentioned risks very well.`
    );
  }

  if (A3 && A4) {
    ret.push(
      `Furthermore, you did a good job plotting those risks in the risk evaluation matrix. Your assessment of likelihood and impact seem to fit the mentioned risks very well.`
    );
  }

  if (A5) {
    ret.push(
      `Additionaly, you set a protection target. This will help you to decide which risks need a handling strategy most urgently, and which ones you tolerate for now. `
    );
  }

  if (!Z0 && !Z1 && A4 === false) {
    ret.push(
      `You did not plot the risks you identified in the risk evaluation matrix. By plotting them in the matrix, you think about both the probability and the potential impact of the risk. This is crucial to identify with risks are most urgent to be handled.`
    );
  }

  if (!Z0 && !Z1 && A5 === false) {
    ret.push(
      `You did not set a protection target within your evaluation matrix. This target helps you to differentiate between risks you tolerate, and relevant risks you develop a risk handling strategy for.`
    );
  }

  return ret;
};
