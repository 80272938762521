import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(`Well done.
You have clearly stated which problem you are trying to address and what impact your idea should have on your users.
Later, when you are designing your experiment, it is vital that you keep this in mind.`);
  }

  return ret;
};
