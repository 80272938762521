import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);
  /*
    {id = "A1", text = "Assumption submitted", points = 0.5},
    {id = "A2", text = "Assumption relates well to the core function mentioned before", points = 1},
    {id = "A3", text = "Assumption is formulated correctly (is an assumption and not just a word, a topic or statement)", points = 1},
    {id = "A4", text = "More than one assumption submitted", points = 0.5},
    {id = "A5", text = "Assumption(s) is relevant", points = 0.5},
    {id = "A6", text = "Assumption(s) is superficial/too simple ('The user will like the product' or 'The user will use the product' )", points = -0.5},
*/

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(`No assumptions submitted.`);
  }

  /* Singular */

  if (A8 && !A4) {
    ret.push(
      `Your assumption not vey valuable to you. Instead of just assuming that your user finds your idea valuable and would use it, you should try to answer why this is the case.
Trying to understand why your user likes your solution will lead you to more valuable assumptions.`
    );
  }

  if (A2 && A3 && A4 === false && A5 && !A6 && !A8) {
    ret.push(
      `Your assumption is well stated!
It is formulated correctly, relevant and well connected to your core function stated earlier.
Well done!

However, you could have identified more relevant assumptions.`
    );
  }

  if (A2 && A3 && A4 === false && !A5 && !A6) {
    ret.push(
      `There is a clear connection between your assumption and the core element of your idea stated earlier.
Furthermore, your assumption is also well stated and relevant to your idea. Well done!

However, it would have been even better if had identified more relevant assumptions.`
    );
  }

  if (A2 && !A3 && A4 === false && !A6 && A8) {
    ret.push(
      `Still, there is a clear connection between your assumption and the core element of your idea stated earlier.`
    );
  }

  if (A2 && !A3 && A4 === false && !A6 && !A8) {
    ret.push(
      `There is a clear connection between your assumption and the core element of your idea stated earlier.`
    );
  }

  if (!Z0 && !Z1 && A2 === false && A1 && A4 === false && !A6) {
    ret.push(
      `What is the connection between this assumption and your idea? Ideally, you would want your idea to internalize this assumption. For example, let's pretend that your assumption is: "People would use their bicycle more often if they were not afraid of getting it stolen." and your idea is to tackle this issue by developing a tracker that is integrated into their bicycles and automatically calls the police if a bike is moved without your phone in the proximity. In this case, the core element of your idea is the automatic call to the police in case of theft. The connection between the assumption and your core element is very obvious.
Please go over your submission again and change them so that this connection is more precise (like in the example above).`
    );
  }

  if (!Z0 && !Z1 && A3 === false && A1 && A4 === false && !A6) {
    ret.push(
      `However, your assumption is not formulated correctly.
An assumption is a statement (not a question or quote) that should always infer a relationship between two objects.
Additionally, this relationship should not be a certainty but only probable. For example, "I like big houses" does connect two objects (Me and big houses), but it is not an assumption as this is my opinion, and at least for me, my opinion is correct.
On the other hand, the statement "People like big houses" is a valid assumption since it connects two objects and is not a certainty.
Hence, please go over your submission and change it into a valid assumption. This is the assumption you should try to validate through your test below.`
    );
  }

  if (!Z0 && !Z1 && A6 && A4 === false) {
    ret.push(
      `Your assumption is too superficial for it to be relevant to your test.
For example, the assumption "The client will use or like this solution" is certainly well formulated and connected to your idea. But it does not help you identify key attributes that must be validated through your test.
In this case, you should dig deeper and ask why would they like or use it. Answering this question (with an assumption), will allow you evaluate what you believe is really central to your idea and help you test that.`
    );
  }

  /* Plural */

  if (A8 && A4) {
    ret.push(
      `One of your assumptions is 'the user will desire or use your product'.
This assumption is not vey valuable to you. Instead, you should try to answer why this is the case.
Trying to understand why your user likes your solution will lead you to more valuable assumptions.`
    );
  }

  if (A2 && A3 && A4 && !A6 && !A8) {
    ret.push(
      `There is a clear connection between your assumptions and the core element of your idea stated earlier.
Furthermore, your assumptions are also well stated and relevant to your idea. Well done!`
    );
  }

  if (A2 && !A3 && A4 && !A6 && A8) {
    ret.push(
      `Still, there is a clear connection between your assumptions and the core element of your idea stated earlier.`
    );
  }

  if (A2 && !A3 && A4 && !A6 && !A8) {
    ret.push(
      `There is a clear connection between your assumptions and the core element of your idea stated earlier.`
    );
  }

  if (!Z0 && !Z1 && A2 === false && A1 && A4 && !A6) {
    ret.push(
      `What is the connection between these assumptions and your idea? Ideally, you would want your idea to internalize these assumptions.
For example, let's pretend that one of your assumptions is: "People would use their bicycle more often if they were not afraid of getting it stolen." and your idea is to tackle this issue by developing a tracker that is integrated into their bicycles and automatically calls the police if a bike is moved without your phone in the proximity. In this case, the core element of your idea is the automatic call to the police in case of theft. The connection between the assumption and your core element is very obvious.

Please go over your submission again and change them so that this connection is more precise (like in the example above).`
    );
  }

  if (!Z0 && !Z1 && A3 === false && A1 && A4 && !A6) {
    ret.push(
      `However, some of your assumptions are not formulated correctly.
An assumption should always infer a relationship between two objects. Additionally, this relationship should not be a certainty but only probable. For example, "I like big houses" does connect two objects (Me and big houses), but it is not an assumption as this is my opinion, and at least for me, my opinion is correct. On the other hand, the statement "People like big houses" is a valid assumption since it connects two objects and is not a certainty.
Hence, please go over your submission and change it into a valid assumption.`
    );
  }

  if (!Z0 && !Z1 && A6 && A4) {
    ret.push(
      `Some of your assumptions are too superficial for them to be relevant to your test.
For example, the assumption "The client will use or like this solution" is certainly well formulated and connected to your idea. But it does not help you identify key attributes that must be validated through your test.
In this case, you should dig deeper and ask why would they like or use it. Answering this question (with an assumption), will allow you evaluate what you believe is really central to your idea and help you test that.`
    );
  }

  if (A7) {
    ret.push(
      `We also liked how you already stated your full hypothesis in such a clear way.
Great job!`
    );
  }

  /* This is a feedback that is missing! The assumption they picked is superficial (The user will like the product or they will buy the product)

Is this really the main assumption you want to test?
Maybe you should ask yourself 'why' your persona would buy this product.
Once you identify a possible reason, you should validate this assumption during your tests.

*/

  return ret;
};
