import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for your comments!
We are happy to hear how you enjoyed this experience and has made so many learnings! We hope that you will enjoy the next modules even more.`);
  }

  if (A2) {
    ret.push(`Thank you for sharing your thoughts about this whole experience!
We really appreciate you taking the time to explain what you did not enjoy so much.
We have taken your comments to heart and will try to act on them so that future cohorts have a better learning journey with us.
We truly hope that you will enjoy the next modules more and thank you again for your help and honesty!`);
  }

  if (A3) {
    ret.push(`Thank you for sharing your thoughts about this whole experience!
We really appreciate you taking the time to explain both what you enjoyed as well as what you did not enjoy so much.
We have taken your comments to heart and will try to act on them so that future cohorts have a better learning journey with us.
We truly hope that you will enjoy the next modules more and thank you again for your help and honesty!`);
  }

  if (A7) {
    ret.push(`We would also like to point out a few things regarding the length of this module:
We know that this is an issue (especially with module 1) and are working to reframe some lectures and adapt the Practical assignment to make this module more digestible.
However, we would like to point out a few things that you can implement to make the next modules easier to go through:
- Be efficient. Every time your team discusses something or collaborates on an assignment, it is very easy to just get involved in these discussions and prolong them unnecessarily. It is important that you try to stick to the time we recommend for a certain assignment. Ideally, set a timer before every meeting and every assignment and make it clear to your team that once the time is up you will move on.
- Be organized. Start early and do your homework. Prepare agendas to your team meetings and try to meet them as early as possible.
- Be scrappy. Design Thinking is an iterative methodology. You will not get everything right the first time and that is okay. With this in mind, don't spend too much time on things that have not been validated yet.`);
  }

  if (A8) {
    ret.push(`Regarding the challenges of extracting and clustering your learnings: We understand that clustering the learning notes into similar themes can be challenging, especially when some statements can fall into various themes (It does get better with practice though!). 
However, you should know that the objective of this clustering method is not to identify the best clusters (there is no perfect clustering process). Instead, the main goal of this step is to allow your team to share your individual findings and align on what your key learnings as a team are. `);
  }

  if (A9) {
    ret.push(`It seems like one of the big issues was the lack of information on GreenMoves.
We certainly understand your position and know that this makes it harder to start this challenge with such a broad case. However, we decided to keep the case as is, as giving too much information early on might reduce the scope of possibilities your team could explore.
We know that this forces you to make many assumptions early on, but we believe that this is better than presenting a challenge that already points to a certain solution.
We will take your comments into our next team meeting and discuss whether or not we should give more information on GreenMoves.
Of course, in a real world project you would have (hopefully) much more information on your challenge.`);
  }

  if (A10) {
    ret.push(`Regarding the interview transcripts, one thing you can try to make it more efficient is always record your interviews (video or sound) and use that to generate a word-to-word transcript using programs like Trint.
Later you can then briefly go over the transcript and extract all the learnings you can find. Just don't forget to add comments about things you observed as well (as these are not in the transcript).`);
  }

  if (scoredPoints > 0) {
    ret.push(
      `Lastly, thank you for your critical reflection and congratulations on completing your first (and hardest) module!`
    );
  }

  return ret;
};
