import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `You did not identify the industry in which your business will take place (E.g. Textile industry, Finance, Consulting, Pharma and so on). Understanding your industry is essential for your business model health check as different industries have different characteristics. Therefore, we highly recommend you to think about which industries you are in.`
    );
  }

  if (Z1) {
    ret.push(
      `Thank you for stating your industry! Understanding your industry is essential for your business model health check as different industries have different characteristics.`
    );
  }

  return ret;
};
