import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(`Thank you for submitting a sketch of your idea. This approach allows for rapid experimentation and iteration, enabling you to quickly test and validate your concept. It's important to focus  creating a simple and easily understandable visual prototype to communicate your idea. This approach will allow you to gather valuable feedback from users early in the development process, helping to ensure that the final solution meets their needs.`);
  }

  if (A2) {
    ret.push(`While it's great that you have created a visual representation for your idea to improve the user experience, there may be some areas for improvement. Low fidelity prototypes are a valuable tool for testing and validating concepts, but it's important to ensure that they illustrate your idea and how it works. Consider refining your prototype to better reflect your idea and how it works in the user experience. This will help you better communicate and get feedback from users.`);
  }

  if (A3) {
    ret.push(`You did not visualize your idea. When we use the word “sketch” or “visual” we do mean a real sketch of the idea on pen and paper or finding a representative image that captures the idea. While describing the idea in words is also an important skill, actually sketching out an idea is the easiest and quickest way to communicate the idea. Visual communication is an essential part of prototyping and validating ideas, so we recommend doing taking advantage of it in your innovation work.`);
  }

  return ret;
};
