import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Congratulations on your excellent work! Your questions are well-framed and include thoughtfully prepared follow-up questions for each topic. Additionally, all of your questions are open-ended, non-leading, and relevant to the topic at hand.
Keep up the good work by asking "why" in situations where you feel there may be more to explore in your interviewee's answers. This will help you gain a deeper understanding of the underlying reasons behind their behavior and actions.`
    );
  }

  return ret;
};
