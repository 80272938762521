import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank you for your submission! After deciding on the scope and challenge of your sprint, the format is going to decide your time invested, the schedule, as well as your plans and deliverables.`
    );
  }

  if (A2) {
    ret.push(
      `Well done! Picking a format that suits the project and the situation your team is in, is essential. The reason you stated is clear and convincing.`
    );
  }

  if (A3) {
    ret.push(
      `Picking a format that suits the project and the situation your team is in, is essential. The reason you stated is understandable. You could support is even more with some additional argumentation.`
    );
  }

  if (A4) {
    ret.push(
      `Picking a format that suits the project and the situation your team is in, is essential. From your answer, it is not clear why your chosen format is gonna fit best. Try to think about the reason behind your choice, before you make it final!`
    );
  }

  return ret;
};
