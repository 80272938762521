import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Well done! You have submitted a good number of "should-have" criteria. Your criteria are clearly formulated and convincing, and will help you to align with your team and leadership, and share a vision throughout the sprint. `
    );
  }

  return ret;
};
