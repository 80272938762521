import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, C0, C1, C2, C3, C4, C5, C6, C7, C8, C9, C10, C11, C12, C13, C14 } =
    getSelectedOptionsFlags(selectedOpts);

  if (C0 && !C4 && !C5 && !C6 && !C7 && !C13 && !C14) {
    ret.push(`No feedback submitted.`);
  }

  if (C1 && !C4 && !C5 && !C6 && !C7 && !C13 && !C14) {
    ret.push(`Thank you for submitting this summary of the feedback you received from your third user.
However, we believe that you should have been a more detailed in your description to ensure that no information is lost.
Please, make sure to record your learnings better in the future. Especially those from direct user interactions. `);
  }

  if (C2 && !C4 && !C5 && !C6 && !C7 && !C13 && !C14) {
    ret.push(`Thank you for submitting this summary of the feedback you received from your third user.
Your feedback is clear and concise and should help you summarize most of your learnings from this session.
However, ideally, you should try to include even more details and learnings from your user feedback sessions.`);
  }

  if (C3 && !C4 && !C5 && !C6 && !C7 && !C13 && !C14) {
    ret.push(`Well done! You have recorded your learnings and summarized your feedback session very well.
The insights and comments shared by your users at this stage are vital for the further development of your ideas.
Now it will be your job to address these learnings before you start prototyping.`);
  }

  /*
  For repeated feedback
  */

  if (C4) {
    ret.push(`Like we mentioned in our feedback for part a), your answer should have been more detailed.
Right now this may seem unnecessary, but keeping a good record of these sessions will help you ensure no information is lost as your project progresses.`);
  }

  if (C5) {
    ret.push(`You once more did a good job summarizing your learnings from this third feedback session.
Well done!`);
  }

  if (C6) {
    ret.push(`Like in your answer for part b), you did not describe and summarize your feedback session well enough.
Please try to be more detailed in the future.`);
  }

  if (C7) {
    ret.push(`You once more did a good job summarizing your learnings from this third feedback session.
well done!`);
  }

  if (C10) {
    ret.push(`However, you have only submitted your feedback for one of your ideas, not both as requested.
As we still have not selected any of them, it is crucial to get feedback for all of your selected ideas for now.`);
  }

  if (C12 && !C13 && !C14) {
    ret.push(`Thank you for submitting a piece of the feedback you received from your first user.
However, ideally, after talking to your participant for several minutes, we would expect you to make more learnings than just those included in this quote.

In the future, you should try to include more details and learnings from your user feedback sessions.
The insights and comments shared by your users at this stage are vital for the further development of your ideas.
This is why it is important to record and summarize them well.`);
  }

  if (C13) {
    ret.push(`Again, it would have been better if you had stated more than just one quote from your feedback session.
These sessions with your users are invaluable to the development of your idea.
Make sure to learn as much as possible from them. They are a great opportunity to learn more about the problem you are trying to solve and to recalibrate your assumptions and insights.`);
  }

  if (C14) {
    ret.push(`You did a good job summarizing your learnings from this third feedback session.
However, as mentioned before, we advise to record your learnings in more detail in the future.
Doing so will allow you to revisit these findings in the future with no risk of forgetting something relevant.`);
  }

  return ret;
};
