import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { D1, Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A0) {
    ret.push(`Thank you for sharing when and how you contacted your peers!
We are happy to hear your team is already in contact!`);
  }

  if (A1) {
    ret.push(`Thank you for sharing when you contacted your colleagues!
We are happy to hear your team is already in contact!.`);
  }

  if (A2) {
    ret.push(`Thank you for sharing how you contacted your colleagues (i.e., which channels you used).
We are happy to hear your team is already in contact!`);
  }

  if (A3) {
    ret.push(`Thank you for your answer.
However you did not share when and how you contacted your peers as asked.
For now this is not a problem, but please make sure to answer our questions in detail in the future.
This will allow us to give you a better feedback.`);
  }

  /*
  {id = "A0", text = "Time and channel submitted", points = 0},
  {id = "A1", text = "Only time submitted", points = 0},
  {id = "A2", text = "Only channel submitted", points = 0},
  {id = "A3", text = "Neither submitted", points = 0},
*/

  return ret;
};
