import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Good job! You have identified several options to reduce the selected risk. At this state of the process the goal is to get aware of all possible options before choosing. Even though your answer was sufficient for this assignment, never stop getting creative and thinking about additional solutions!`
    );
  }

  if (A2) {
    ret.push(
      `You have only identified one option to reduce the selected risk. Although this might be your preferred option, at this state of the process the goal is to get aware of all possible options before choosing. Try to get creative and think about additional solutions!`
    );
  }

  if (A4) {
    ret.push(
      `While you did come up with a good strategy to handle this risk, the goal in this exercise was for you to find ways to reduce the impact of your selected risk. By this we mean that you should, for now at least, not focus on reducing the probability of that risk happening or finding ways to transfer that risk to another party.
      Instead, you should ask yourself what you could do to minimize the impact if that did happen. Later you will be able to compare all strategies you have identified and pick the ones you believe are more promising. But for now, please focus only on reducing the impact.`
    );
  }

  return ret;
};
