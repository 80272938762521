import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A6, A7, A8, A9, A10, A11, A12, A13, A14, A15, A16 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A13) {
    ret.push(
      `Good job! All of your learning notes are objective and do not contain your personal opinion. This is important as your learning notes should summarize all the key findings from your qualitative research. Keeping them impartial will help you ensure that your findings are not biased before coming up with relevant insights.`
    );
  }

  if (A6) {
    ret.push(
      `You have included a participant code in all your learning notes. Good job! This is less important when you only conduct one or two interviews as you can usually remember which finding is from which interview. However, if you have more interviews (what you should for a more in-depth user research), then it is important since you want to keep track of who said what in case you have some follow-up questions. Additionally, it also makes it easier to share your notes if you use a code instead of the full name since this removes all confidential information. So good job doing that!`
    );
  }

  if (A9) {
    ret.push(
      `Well done! All your learning notes include only one finding per note. This is very important as it makes it easier to cluster and organize them later. Additionally, this keeps your notes short and clear so that the learnings can be shared more easily. Good job!`
    );
  }

  if (A10) {
    ret.push(
      `Some of your learning notes include more than one finding per note. This is not necessarily wrong, however, having more than one finding per note makes it harder to cluster them later and reduces the clarity of the note. It is much easier to share ideas that are very clear and short. Therefore, we recommend you to pay attention in the future so that all of your notes include only one finding.`
    );
  }

  if (A11) {
    ret.push(
      `Some of your learning notes include more than one finding per note. This is not necessarily wrong, however, having more than one finding per note makes it harder to cluster them later and reduces the clarity of the note. It is much easier to share ideas that are very clear and short. Therefore, we recommend you to pay attention in the future so that all of your notes include only one finding.`
    );
  }

  if (A12) {
    ret.push(
      `Some of your learning notes include more than one finding per note. This is not necessarily wrong, however, having more than one finding per note makes it harder to cluster them later and reduces the clarity of the note. It is much easier to share ideas that are very clear and short. Therefore, we recommend you to pay attention in the future so that all of your notes include only one finding.`
    );
  }

  if (A14) {
    ret.push(
      `Some of your learning notes are not objective representations of your interviews. A learning note should include only one finding (something that you heard or observed during your qualitative research). This finding can usually be a quote, an observation, a question, or any other factual statement. For the next time, you should try to keep all of your learning notes impartial.`
    );
  }

  if (A15) {
    ret.push(
      `Some of your learning notes are not objective representations of your interviews. A learning note should include only one finding (something that you heard or observed during your qualitative research). This finding can usually be a quote, an observation, a question, or any other factual statement. For the next time, you should try to keep all of your learning notes impartial.`
    );
  }

  if (A16) {
    ret.push(
      `Your learning notes are not objective representations of your interviews. A learning note should include only one finding (something that you heard or observed during your qualitative research). This finding can usually be a quote, an observation, a question, or any other factual statement. For the next time, you should try to keep all of your learning notes impartial.`
    );
  }

  if (A7) {
    ret.push(
      `A participant code is not included in some of your learning notes. This is less important when you only conduct one or two interviews as you can usually remember which finding is from which interview. However, if you have more interviews (what you should for a more in-depth user research), then it is important since you want to keep track of who said what in case you have some follow-up questions. Additionally, it also makes it easier to share your notes if you use a code instead of the full name since this removes all confidential information. Therefore, we recommend you add participant codes to all of your learning notes in the future.`
    );
  }

  if (A8) {
    ret.push(
      `A participant code is not included in any of your learning notes. This is less important when you only conduct one or two interviews as you can usually remember which finding is from which interview. However, if you have more interviews (what should be the case for a more in-depth user research), then it is important since you want to keep track of who said what in case you have some follow-up questions. Additionally, it also makes it easier to share your notes if you use a code instead of the full name since this removes all confidential information. Therefore, we recommend you add participant codes to your learning notes in the future.`
    );
  }

  return ret;
};
