import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Well done. Your constraints are all very well stated and seem very stimulating.
  This is useful because it might force your participants to find new solutions they may not have mentioned otherwise. `
    );
  }

  if (Z2) {
    ret.push(
      `It seems to us that you did not understand what we meant by constraint.
A good constraint should be a statement that reduces the set of possible solutions.
For example, something like "the solution must be good" is not a good constraint because it does not force you to solve any additional issues.
On the other hand, something like "The solution can not weigh more than 2kg" is an excellent constraint because it reduces the number of possible solutions and forces your team to solve an additional issue that might affect some of your users.`
    );
  }

  return ret;
};
