import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const {
    Z0,
    Z1,
    Z2,
    Z3,
    B0,
    B1,
    B2,
    B3,
    B4,
    B5,
    B6,
    B7,
    B8,
    B9,
    B10,
    B11,
    B12,
    B13,
    B16,
    B17,
    B18,
    B19,
    B20,
    B21,
    B22,
    B23,
    B24,
  } = getSelectedOptionsFlags(selectedOpts);

  if (B19 && !B0 && !B3) {
    ret.push(
      `It seems like you did not name the stages of your user journey.
Please make sure to name them so that it is more clear what they are all about.
This will help you define better when each phase starts and ends, and what your user might do in each phase.
Below you will find the feedback for the rest of your user journey.`
    );
  }

  if (B0 && !B3) {
    ret.push(
      `Well done!
You have uploaded a detailed user journey focusing on your persona submitted earlier.
It is well structured and focuses on all the main pain points and experiences of your user.
Doing this will help you and your team visualize very well how users experience your solution and will help you identify and address relevant points.

Furthermore, while we have asked you to create only one persona and one user journey, in a more comprehensive study you would create multiple personas and create a user journey for each of these personas.
This would help you cover different needs and frustrations and make it more likely that your final solution addresses different types of users and covers the needs of a higher share of the market.`
    );
  }

  if (B3) {
    ret.push(
      `Thank you for sharing this answer.
However, while your answer includes many of the elements we expected to see in a customer journey, it is not a visualization of your user's experience as expected.
We advise you to take a look at the [user journeys shared by your peers on our community](https://community.sparkademy.com/c/inventive-problem-solving-idea-evaluation/) and to use our template in the future.
Very briefly, the objective of a customer journey is to breakdown the experience of your users into many steps and evaluate these individual steps and use them to assess the high- and lowlights of their experience.
This is a great tool to identify ways in which you can create value for your users.

If this concept is still unclear to you, please revisit our material on it and don't hesitate to reach out to us with questions!`
    );
  }

  if (B1 && !B0 && !B3) {
    ret.push(`Your user journey includes a clear description of your persona's goals.
Even though this might have been taken straight from the description of your persona from earlier, it is important to state that again in your user journey as this will help contextualize your persona and assess better how it might experience your solution.`);
  }

  if (B20 && !B0 && !B3) {
    ret.push(
      `You divided your user journey into the three stages 'pre-experience', 'experience' and 'post-experience'.
While this is not wrong, these are only very general stages that can often be exemplified better for a specific project. Furthermore, if you break down your journey into more stages, you may be able to identify more touch-points and experiences.
This is why we advise you to describe the stages of your user journey in more details and, ideally, also define more than just these three steps.`
    );
  }

  if (!Z0 && !Z1 && B1 === false && !B0 && !B3) {
    ret.push(`Your user journey does not include a clear description of your persona's goals.
Even though this might have been taken straight from the description of your persona from earlier, it is important to state that again in your user journey as this will help contextualize your persona and assess better how it might experience your solution.`);
  }

  if (B24 && !B3) {
    ret.push(
      `Also, we are happy to see how you drew the line of emotions for two different experiences depending on how it starts! This is a great approach and is something you should keep an eye out for your future projects.`
    );
  }

  if (!B1 && B11 && !B0 && !B3) {
    ret.push(
      `On the other hand, you have done a good job highlighting the emotional experience of the persona throughout its customer journey.`
    );
  }

  if (B1 && B11 === false && !B0 && !B3) {
    ret.push(`However, you did not highlight the emotional experience of the persona well enough.
Try to really pinpoint what went well and what went poorly during all the steps of the journey.
Focus especially on the feelings and emotions the persona might feel during these steps.`);
  }

  if (!Z0 && !Z1 && !B1 && B11 === false && !B0 && !B3) {
    ret.push(`Furthermore, you did not highlight the emotional experience of the persona well enough.
Try to really pinpoint what went well and what went poorly during all the steps of the journey.
Focus especially on the feelings and emotions the persona might feel during these steps.`);
  }

  if ((B16 || B23) && B11 && !B20 && !B0 && !B3) {
    ret.push(
      `You have also done a good job covering multiple experiences that your user will go through in their customer journey.
This is relevant as it might help you identify more ways to create value for your user. `
    );
  }

  if ((B16 || B23) && !B11 && !B20 && !B0 && !B3) {
    ret.push(
      `On the other hand, one thing you did well was covering multiple experiences that your user will go through in their customer journey.
This is relevant as it might help you identify more ways to create value for your user. `
    );
  }

  if ((B17 || B18) && !B20 && !B0 && !B3) {
    ret.push(
      `In the future you should try do add more stages to your user journey. The more stages you identify, or the more granular your analysis, the easier it will be to get a good understanding of their current experience.
You should try to cover all the experiences starting from when the customer becomes aware of the product to sustaining their loyalty to your solution and brand.`
    );
  }

  if (B21 && !B22 && !B0 && !B3) {
    ret.push(
      `However, there is one thing missing in your user journey.
There is no clear line representing the low- and highlights of your user journey.
Including this feature will make it much easier to visualize their current experience and will help you identify what parts of the user experience should be improved or maintained.`
    );
  }

  if (!B21 && B22 && !B0 && !B3) {
    ret.push(
      `However, there is one thing you should improve in your user journey.
You should try to add more information to each one of your phases.
Try to think of many goals if possible and make sure to describe well how your user experiences each phase of their journey.`
    );
  }

  if (B21 && B22 && !B0 && !B3) {
    ret.push(
      `However, there two things you could improve in your user journey.
First, there is no clear line representing the low- and highlights of your user journey.
Including this feature will make it much easier to visualize their current experience and will help you identify what parts of the user experience should be improved or maintained.

Secondly, you should try to add more information to each one of your phases.
Try to think of many goals if possible and make sure to describe well how your user experiences each phase of their journey.`
    );
  }

  if (!Z0 && Z1 === false && !B0 && !B3) {
    ret.push(
      `
Lastly, we would like to remind that, while we only asked you to create one persona and one user journey, in a more comprehensive study you should create multiple personas and create a user journey for each of these personas.
This will help you cover different needs and frustrations and make it more likely that your final solution addresses different types of users and covers the needs of a higher share of the market.`
    );
  }

  return ret;
};
