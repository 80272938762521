import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts, scoredPoints) => {
    const ret = [];
    const { Z0, Z1, D1 } = getSelectedOptionsFlags(selectedOpts);

  if (D1) {
    ret.push("Thank you for submitting your protocol here."
    );
  }

  return ret;
};
