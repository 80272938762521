import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission.`
    );
  }

  if (Z1) {
    ret.push(`Great job! Your conclusion section includes all the relevant parts of a good interview guideline. Don't forget to keep paying attention even after the interview is "officially" done, as it is possible that the participants might feel more at ease and remember one or two more things.`
    );
  }

  return ret;
};