import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Good job! The task you designed is very clear and effective.
It does not tell the participant too much, which allows him to explore it by himself, and gives him a good opportunity to interact with the prototype. This will, in turn, increase the number of learnings you make and improve the chance that you will get constructive feedback from him. `
    );
  }

  if (Z2) {
    ret.push(
      `Very well done!
The task you designed is very clear and effective and went beyond the scope of what we expected in this exercise.
It does not tell the participant too much, which allows him to explore it by himself, and gives him a good opportunity to interact with the prototype. This will, in turn, increase the number of learnings you make and improve the chance that you will get constructive feedback from him. Very well done!`
    );
  }

  return ret;
};
