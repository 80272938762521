import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Good work! The solutions you included are creative and relevant to addressing the problems that may arise with removing the core in your business model. By rethinking your business model in this way, you were able to see solutions that can address major potential barriers in your business. These solutions can be used not just to address the problems if they arise, but also as inspiration for a business model reconfiguration.`
    );
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `The solutions you included were not relevant to the potential problems you identified in the previous question. In brainstorming solutions with your group, write down every idea you come up with to address the problems, especially the out-of-the-box solutions, and from there you can consider which solutions that are the most relevant. By rethinking your business model in this way, you were able to see solutions that can address major potential barriers in your business. These solutions can be used not just to address the problems if they arise, but also as inspiration for a business model reconfiguration.`
    );
  }

  if (A2 && !A1) {
    ret.push(
      `Good work including solutions that have the potential to effectively address the problems you identified in the previous question. Your answer shows thought and knowledge of effective business solutions targeted at potential problems with removing the core. Coming up with solutions like these not only equips you to deal with roadblocks that can arise for your business but also to apply your knowledge and solutions to business model innovation.`
    );
  }

  if (A2 && A1) {
    ret.push(
      `Additionally, good work including solutions that have the potential to effectively address the problems you identified in the previous question. Your answer shows thought and knowledge of effective business solutions targeted at potential problems with removing the core. Coming up with solutions like these not only equips you to deal with roadblocks that can arise for your business but also to apply your knowledge and solutions to business model innovation.`
    );
  }

  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `The solutions you included do not show much potential in effectively solving the problems you identified in the previous question. Consider returning to the module content or online sources like the Business Model Navigator to get inspiration for proven solutions to common business model problems. Researching effective and targeted solutions for these problems not only equips you to deal with roadblocks that can arise for your business but also to apply your knowledge and solutions to further business model innovation.`
    );
  }

  if (A3 && !A1 && !A2) {
    ret.push(
      `Great job explaining your solutions in relation to how they support the other elements in your business model canvas and ultimately can support the creation, delivery, and capture in your business model. You demonstrated an understanding of how to ensure that solutions to potential problems can bolster the overall strength of your business model.`
    );
  }

  if (A3 && (A1 || A2)) {
    ret.push(
      `Furthermore, great job explaining your solutions in relation to how they support the other elements in your business model canvas and ultimately can support the creation, delivery, and capture in your business model. You demonstrated an understanding of how to ensure that solutions to potential problems can bolster the overall strength of your business model.`
    );
  }

  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `You did not include an explanation for your solutions that referenced how they can support other elements of your business model canvas or further contribute to the creation, delivery, and capture of value. Return to your solutions and consider if and how they can support other elements on your business model canvas or the overall creation and capture of value. It's important to evaluate your solutions on these criteria to know not only if they can address the potential problems you identified, but also if they can bolster the overall strength of your business model.`
    );
  }

  if (A4) {
    ret.push(
      `Your solution is not detailed enough.
In these situations you should always try to come up with an actionable plan that allows you to perform clear steps that help you validate/test your proposed solution.
Being too broad now will not only make this harder, but it may even cause you to lose momentum.`
    );
  }

  if (A5) {
    ret.push(
      `Your solution is interesting, but it may be somewhat inefficient.
Isn't there a more efficient way to move forward that requires less time and resources?
Remember, for now we are still working mostly with assumptions. This means that many of the assumptions you are building your solution around might be incorrect.
Because of that, the less resources you use to validate your ideas, the smaller the risk of your project.`
    );
  }

  return ret;
};
