import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A0, A1, A2, A3, A4, A5, A6, A7, A8 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A5) {
    ret.push(`Thank your for your answer!
However, you did not state which insight you have picked to answer the questions above.
We can still guess based on your argumentation, but in the future, we ask you to always make your answers very clear so that we can give you better and more effective feedback.
Below you will find our feedback to your answers:`);
  }

  if (A0) {
    ret.push(`No submission`);
  }

  if (A1) {
    ret.push(`You have clearly shown that this insight is supported by the evidence collected during your user research.
More importantly, you have done so by including direct findings from your research. Well done!
We advise you to keep using hard evidence every time you share relevant information with your team or company in the future.
Doing so will give your hypotheses more weight and make it easier for you to share these insights.`);
  }

  if (A2) {
    ret.push(`Thank you for explaining how your user research supports your insight. However, we recommend you to include more direct evidence (quotes, findings, observations, etc) to support your findings in the future.
Ideally, you could try to quote some of your participants or mention how often a certain need/issue/desire was mentioned during your interviews.
Doing so will give your hypotheses more weight and make it easier for you to share these insights.`);
  }

  if (A3) {
    ret.push(`Thank you for explaining how your user research supports your insight. However, your answer was not very convincing.
We recommend you to include more direct evidence (quotes, findings, observations, etc) to support your findings in the future. Ideally, you could try to quote some of your participants or mention how often a certain need/issue/desire was mentioned during your interviews.
Doing so will give your insights more weight and make it easier for you to share these them (and get people to believe them).`);
  }

  if (A4) {
    ret.push(`We feel like you did not argument well enough to explain how this insight is supported by evidence collected during your user research.
Ideally, you should try to quote some of your participants or mention how often a certain need/issue/desire was mentioned during your interviews.
Doing so will give your insight more credibility, thus making it easier to share and spread.`);
  }

  /*

    { id= "A0", text= "No submission", points= 0 },
    { id= "A1", text= "Very good arguments with direct support (quotes, findings...)", points= 3 },
    { id= "A2", text= "Good arguments but no direct support ()", points= 1.5 },
    { id= "A3", text= "Bad argumetation with no support ()", points= 0.5 },

  */

  return ret;
};
