import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7 } = getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "A1", text = "Only one success metric stated (ideal case)", points = 1},
    {id = "A2", text = "More than one success metric stated. Pick between A1 and A2. Not both!", points = 0.5},
    {id = "A3", text = "Metric is very clear ", points = 1},
    {id = "A4", text = "Metric can be measured very well", points = 1},
    {id = "A5", text = "Metric is strongly related to the core element of the idea", points = 0.5},
    {id = "#9", text = "----- ----- Alternative Feedback (cancels all other feedback, use points with questions above) ----- ----- ----- ", points = 0},
    {id = "A6", text = "Success criteria requires idea to be implemented (e.g. number of solutions sold)", points = -1},
    {id = "A7", text = "Criteria is only 'Would they use it?'. Nothing to compare it to or to make it less valuable (e.g. attributing a cost or opportunity cost of not using competitor)", points = -1},
  */
  if (A4 && !A6 && !A7) {
    ret.push(
      `Your success criterion is easily measurable.
This is very important as the whole point of such a metric is to be able to measure it and compare it to some critical value.`
    );
  }

  if (A2 && A4 === false && !A6 && !A7) {
    ret.push(
      `You have stated several success metrics, and this can be problematic.
The goal of a success metric a very clear measurable aspect that can be compared to some critical value. If the success metric passes this critical value, then the test is considered a success. Therefore, it is often a good idea to have very few success metrics so that the result is very clear. For example, assume you have six success criteria and that your test passed 5/6 of these with great success. Is this test considered a success? According to the definition, it isn't, but often some team members would disagree. This is why it is better to just have one criterion.`
    );
  }

  if (A2 && A4 && !A6 && !A7) {
    ret.push(
      `However, you have stated several success metrics, and this can be problematic.
The goal of a success metric a very clear measurable aspect that can be compared to some critical value. If the success metric passes this critical value, then the test is considered a success. Therefore, it is often a good idea to have very few success metrics so that the result is very clear. For example, assume you have six success criteria and that your test passed 5/6 of these with great success. Is this test considered a success? According to the definition, it isn't, but often some team members would disagree. This is why it is better to just have one criterion.`
    );
  }

  if (!Z0 && !Z1 && A3 === false && !A6 && !A7) {
    ret.push(
      `Your success metric is not very clear.
Since this is the most important metric, it is important that it is very clear what exactly the metric is. Please go over your submission and state your success metric again so that anyone reading it will know exactly what you mean.`
    );
  }

  if (!Z0 && !Z1 && A4 === false && !A6 && !A7) {
    ret.push(
      `Measuring your success criteria is a bit too hard.
Since your success criteria is so important to determine whether a test was a success or not, it is crucial that this metric can be measured very consistently and easily. Therefore, we ask you to rethink if this is the best metric for your test and change your submission so that it is very measurable.`
    );
  }

  if (!Z0 && !Z1 && A5 === false && !A6 && !A7 && !A7) {
    ret.push(
      `Since your success criteria will determine whether or not your hypothesis is true, it is important that these criteria are also strongly related to the core assumption you are trying to validate. However, this does not seem to be the case here.
This is why we advise you to rethink your success criteria and pick something that will address the core elements you have stated before.`
    );
  }

  if (A6) {
    ret.push(
      `Your success criteria is only effective if you have already developed and rolled-out your solution.
However, the goal of this assignment is to test the most relevant assumptions behind your solution (the ones that must be true for your idea to be valuable) before you actually commit to this solution.
This will save you and your team a lot of time and resources in case you realise that your idea is not as good as you originally thought.

Therefore, please make sure to rethink your test and your success criteria so that you can validate your idea before committing too many resources into it.`
    );
  }

  if (A7) {
    ret.push(
      `Your whole success criterion is based on whether or not your users would use your idea.
However, your test does not associate any real cost to your idea.
So why would they say no?
Your test and criteria would be much more effective if, for example, you would compare your solution to something they already use (or could use) and ask them if they would start to use your idea.
You could also come up with a price they would have to pay to se your service, but just offering something that they will probably like 'for free' will give you results that you will not be able to reproduce once you introduce some sort of cost to your solution.`
    );
  }

  return ret;
};
