import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10, A11, A12, A13 } =
    getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Good job listing multiple actions you can take to address inconsistencies in your business model. It's important to have several courses of action, so that you can experiment to find one that works best.`
    );
  }

  if (A2) {
    ret.push(
      `You have only listed one action to address inconsistencies in your business model. In order to experiment and find an effective course of action to improve your business model, you should brainstorm a few more.`
    );
  }

  if (A3) {
    ret.push(
      `You did not list any actions you can take to address the inconsistencies in your business model.  It's important to have several courses of action, so that you can experiment to find one that works best.`
    );
  }

  if (A4) {
    ret.push(
      `Great job addressing the inconsistencies you hav stated in the previous question with all of the actions you brainstormed. Addressing inconsistencies and weak points in the business model is the best place to start in thinking about a course of action to improve your business.`
    );
  }

  if (A5) {
    ret.push(
      `You hav only partially addressed the inconsistencies you stated in the previous question with the actions you brainstormed. Addressing inconsistencies and weak points in the business model is the best place to start in thinking about a course of action to improve your business. `
    );
  }

  if (A6) {
    ret.push(
      `You did not address the inconsistencies you stated in the previous question with the actions you submitted. Addressing inconsistencies and weak points in the business model is the best place to start in thinking about a course of action to improve your business. Go back to your answer to question 1 and brainstorm how you can specifically address those issues in the business model.`
    );
  }

  if (A7) {
    ret.push(
      `Nice job incorporating team and collaborative activities into your course of action ideas. Collaboration and getting insights from your team are key to ensuring the changes you make to your business model will be effective.`
    );
  }

  if (A8) {
    ret.push(
      `Furthermore, you have included team and collaborative activities into you course of action ideas. Well done! Collaborating and getting insights from your team are key to ensuring the changes you make to your business model will be effective. `
    );
  }

  if (A9) {
    ret.push(
      `You did not include team and collaborative activities into you course of action ideas. We encourage you to think of how you can incorporate collaboration and getting insights from your team because they are key to ensuring the changes you make to your business model will be effective. Return to the method kit for collaborative activity ideas.`
    );
  }

  if (A10) {
    ret.push(
      `Well done including actions that use business model innovation techniques to address the inconsistencies in your business model. Business model innovation methods are proven ways to create not only a more effective but also innovative business to deliver and capture value.`
    );
  }

  return ret;
};
