import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3 } = getSelectedOptionsFlags(selectedOpts);
  /*
  {id = "Z1", text = "Perfect submission (Mix of constructive and positive feedback)", points = 10},
  {id = "Z2", text = "Perfect submission (Only positive feedback)", points = 10},
  {id = "Z3", text = "Perfect submission (Only constructive feedback)", points = 10},
  */
  if (Z0) {
    ret.push(
      `Giving feedback properly is essential to ensure good team dynamics. Even if you didn't have time for that right now, you should always remember the critical aspects of constructive feedback. Don't forget to check the method cards for a short refreshment every now and then.`
    );
  }

  if (Z1) {
    ret.push(
      `Perfect!
All of the feedbacks you have submitted are very well written!
This is essential as being able to give great feedback will not only help your colleagues improve even further, but can also strengthen your relationship and trust.
You have submitted a good mix of positive and constructive feedbacks.
What is very important, is that your constructive feedback always starts with something positive and is specific and actionable.
This will make it easier for your colleague to accept your suggestion and will make it clear and easy for him/her to improve.`
    );
  }

  if (Z2) {
    ret.push(
      `Good job!
All of the feedbacks you have submitted are well written!
This is essential as being able to give great feedback will not only help your colleagues improve even further, but can also strengthen your relationship and trust.

However, you have submitted only positive feedbacks.
This is not wrong, but it is important to know how to give constructive criticism to yoour team mates. This is why we asked you to submit constructive feedback as well.
As a refresher, we would like to point out that constructive feedback should always start with something positive and be specific and actionable.
This will make it easier for your colleague to accept your suggestion and will make it clear and easy for him/her to improve.`
    );
  }

  if (Z3) {
    ret.push(
      `Perfect!
All of the feedbacks you have submitted are very well written!
This is essential as being able to give great feedback will not only help your colleagues improve even further, but can also strengthen your relationship and trust.
We appreciate how all the feedback you have submitted is constructive and helps your colleague improve.
What is very important, is that your constructive feedback always starts with something positive and is specific and actionable.
This will make it easier for your colleague to accept your suggestion and will make it clear and easy for him/her to improve.`
    );
  }

  return ret;
};
