import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A2) {
    ret.push(
      `Good job! Your suggested change makes a lot of sense and shows that you have reflected on the feedback you have received`
    );
  }

  if (!Z0 && A1 === false) {
    ret.push(
      `Your submission is not very clear. Please try to describe what you would change in a simple way so that anyone you might explain this to can effortlessly understand it.`
    );
  }

  if (!Z0 && A2 === false) {
    ret.push(
      `Why you would want to change that? Reading the feedback you have received, this does not seem like a central issue that has to be addressed. Are you sure that this is the most important change you should pursue? If yes, then try to restate it so that it is very clear why that is the case. `
    );
  }

  return ret;
};
