import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Nice work. You have clearly considered your opportunity space  and come up with several ideas for how to improve the user experience.`
    );
  }

  if (A2) {
    ret.push(
      `You did not list as many ideas as we asked you to. This makes it hard for us to assess the creativity of the solutions you came up with while considering your opportunity space.`
    );
  }

  if (A3) {
    ret.push(
      `Additionally, your ideas are out of the box and show creative thinking in the ideation process. Well done!`
    );
  }

  if (A4) {
    ret.push(
      `However, your ideas are not very out of the box, and thus not representative of a creative ideation process. Remember not to filter your wild ideas. If you find it useful, go back to the Ideation methods from module 2 and use some of those to spark creative ideas.`
    );
  }

  return ret;
};
