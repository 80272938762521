/** @jsx jsx */
import * as React from "react";
import { Box, Heading, jsx, ThemeProvider } from "theme-ui";
import { themeNew } from "@sparkademy/app-common/materials/theme";
import { Layout } from "../components/Layout";
import { Container } from "@sparkademy/app-common/elements/Container";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "@sparkademy/app-common/elements/Button";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { useAssignmentContext } from "@sparkademy/app-common/contexts/assignment-context";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { useGradingContext } from "../contexts/grading-context";
import { TeamSubmitConfirmationDialog } from "../components/TeamSubmitConfirmationDialog";
import { SubmitConfirmationDialog } from "../components/SubmitConfirmationDialog";
import { GradingService } from "../services/grading";
import { ArrowLinkButton } from "@sparkademy/app-common/elements/ArrowLink";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";
import { ExercisePart } from "@sparkademy/app-common/components/assignment/ExercisePart";
import { useAssignmentAnswersContext } from "@sparkademy/app-common/contexts/assignment-answers-context";
import {
  Exercise,
  ExerciseStatusType,
  GradingCriteriaAppliedMap,
} from "@sparkademy/app-common/models/assignment";
import { getAppliedCriteriaScore } from "../helpers";

// Page for giving feedback to participants.
// Appears after evaluation page.
export const Feedback: React.FC = () => {
  const { currentUser } = useSessionContext();
  const { modules: modulesInfo } = useContentContext();
  const { assignmentStatus } = useAssignmentContext();
  const {
    currentSubmission,
    fetchSubmission,
    evaluations,
    clearEvaluationData,
    gradingStartTime,
    teams,
    fetchTeamsForUser,
    fetchModuleAssignment,
    assignment,
    loading: loadingGradingData,
    generateFeedbackData,
  } = useGradingContext();
  const {
    fetchExerciseAnswers,
    savedAnswers,
    savedFeedback,
    savedScores,
    clearFeedback,
    clearScores,
  } = useAssignmentAnswersContext();
  const { exercises } = assignment;
  const history = useHistory();
  const { assignmentExerciseId } = useParams<{ assignmentExerciseId: string }>();

  const exerciseStatus = assignmentStatus.find(
    as => as.exercise_id === currentSubmission?.exercise_id
  );
  const readonly = exerciseStatus?.status === ExerciseStatusType.GRADED;

  const currentExercise = exercises.find(e => e.id === currentSubmission?.exercise_id);
  const moduleInfo = modulesInfo.find(m => m.id.toLowerCase() === currentSubmission?.module_id);
  const isTeamSubmission = currentExercise?.type === "team";
  const currentTeam = currentExercise ? teams[currentExercise.id] : null;

  const [isSaving, setIsSaving] = React.useState(false);
  const [dialogToShow, setDialogToShow] = React.useState<string>("");

  React.useEffect(() => {
    if (!currentUser || !currentSubmission) {
      return;
    }
    fetchModuleAssignment(
      currentSubmission?.user_id,
      currentSubmission?.module_id,
      currentSubmission?.cohort_id,
      currentSubmission?.is_retry,
      currentUser
    );
  }, [currentSubmission, currentUser, fetchModuleAssignment]);

  React.useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = true;
  };

  React.useEffect(() => {
    if (!currentUser || !currentSubmission) {
      return;
    }
    fetchExerciseAnswers(currentSubmission.id, currentUser);
  }, [currentSubmission, currentUser, fetchExerciseAnswers]);

  React.useEffect(() => {
    if (
      assignmentExerciseId &&
      currentUser &&
      (!currentSubmission || currentSubmission?.id !== parseInt(assignmentExerciseId))
    ) {
      fetchSubmission(parseInt(assignmentExerciseId), currentUser);
    }
  }, [currentSubmission, assignmentExerciseId, currentUser, fetchSubmission]);

  React.useEffect(() => {
    // persist saved feedback to session storage on component unmount, eg.: clicking the back button
    return () => {
      if (Object.keys(savedFeedback).length > 0 && currentSubmission) {
        sessionStorage.setItem(`${currentSubmission.id}-feedback`, JSON.stringify(savedFeedback));
      }
    };
  }, [savedFeedback, currentSubmission]);

  React.useEffect(() => {
    if (!currentUser || !currentSubmission) {
      return;
    }
    fetchTeamsForUser(currentSubmission.user_id, currentSubmission.module_id, currentUser!);
  }, [currentSubmission, currentUser, fetchTeamsForUser]);

  React.useEffect(() => {
    if (Object.keys(savedFeedback).length === 0 && currentExercise) {
      generateFeedbackData(currentExercise);
    }
  }, [generateFeedbackData, currentExercise, savedFeedback]);

  const onSave = async () => {
    setIsSaving(true);

    try {
      if (currentSubmission && currentUser) {
        const criteriaApplied = getAppliedCriteriaScore(evaluations, currentExercise!);

        await GradingService.saveGrading(
          currentSubmission?.id,
          savedFeedback,
          savedScores,
          gradingStartTime!,
          criteriaApplied,
          "",
          currentUser
        );
        sessionStorage.clear();
        clearEvaluationData();
        clearFeedback();
        clearScores();

        if (currentSubmission.needs_grading_review) {
          history.push("/flagged");
        } else {
          history.push(`/grading/${currentSubmission.cohort_id}`);
        }
      }
      setIsSaving(false);
    } catch (err) {
      window.alert(err);
      console.error(err);
      setIsSaving(false);
    }
  };

  if (
    loadingGradingData ||
    isSaving ||
    savedAnswers.size === 0 ||
    !currentSubmission ||
    currentSubmission.id !== parseInt(assignmentExerciseId)
  ) {
    console.log("showing loader because either is true", {
      loadingGradingData,
      isSaving,
      savedAnswersSizeIsZero: savedAnswers.size,
      noCurrentSubmission: !currentSubmission,
      currentSubmissionIdMismatch: currentSubmission?.id !== parseInt(assignmentExerciseId),
    });

    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  return (
    <ThemeProvider theme={themeNew}>
      <Layout sx={{ bg: "new.primary.white" }}>
        <Container
          sx={{
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {dialogToShow === "teamSubmitConfirmation" && (
            <TeamSubmitConfirmationDialog
              onClose={() => setDialogToShow("")}
              onSubmit={onSave}
              team={currentTeam!}
            />
          )}
          {dialogToShow === "submitConfirmation" && (
            <SubmitConfirmationDialog onClose={() => setDialogToShow("")} onSubmit={onSave} />
          )}

          <Box
            sx={{
              mt: "80px",
              display: "flex",
              flexDirection: "column",
              width: "894px",
            }}
          >
            <ArrowLinkButton
              sx={{ fontSize: "20px" }}
              id="back-link"
              onClick={() => history.goBack()}
            >
              Back
            </ArrowLinkButton>

            <Heading
              as="h3"
              sx={{
                color: "new.secondary.grey",
                fontWeight: 700,
                fontSize: "20px",
                mt: 8,
              }}
            >
              {`Module ${moduleInfo?.index}`} | {currentExercise?.title}
            </Heading>

            <Heading
              as="h1"
              sx={{
                fontWeight: 700,
                fontSize: "24px",
                mt: 8,
              }}
            >
              {currentSubmission?.user_name} | {currentSubmission?.cohort_id.toUpperCase()}
            </Heading>
          </Box>

          <Box
            sx={{
              pb: "16px",
              borderTop: "1px solid",
              borderColor: "new.secondary.grey",
              borderBottom: "1px solid",
              borderBottomColor: "new.secondary.grey",
              width: "894px",
              mt: 8,
            }}
          >
            <Heading as="h2" sx={{ py: 8, fontWeight: 700 }}>
              Participant’s View
            </Heading>
            <Box>
              {currentExercise?.sections.map(section => (
                <ExercisePart key={`${currentSubmission?.id}-${section.id}`} section={section} />
              ))}
            </Box>
          </Box>

          <Box sx={{ display: "flex", my: 8, width: "894px" }}>
            <Button
              sx={{
                fontSize: "20px",
                marginLeft: "auto",
              }}
              onClick={() =>
                isTeamSubmission && currentTeam
                  ? setDialogToShow("teamSubmitConfirmation")
                  : setDialogToShow("submitConfirmation")
              }
              disabled={readonly}
            >
              Send Feedback
            </Button>
          </Box>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};
