import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { B1, A2, A3, A4, A5, A6, A7, A8, Z0, Z1, Z2, A9 } = getSelectedOptionsFlags(selectedOpts);

  if (B1) {
    ret.push(`That's great to hear! It sounds like you did a great job during the team-building phase. We usually recommend the first week of a sprint to be fully focused on team building and alignment, and making sure everyone has the same understanding of your challenge.
    This is never wasted time, but a valuable foundation for the rest of the three months. `);
  }

  return ret;
};
