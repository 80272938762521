import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
    const ret: string[] = [];
    const { Z0, Z1, B1, B2, B3, B4, B5, B6, B7, B8, B9, B10, A1, A2, A3, A4, A5, A6 } =
        getSelectedOptionsFlags(selectedOpts);

    /*
    { id = "A1", text = "Reflection is about prejudices", points = 0.5 },
    { id = "A2", text = "Shares at least one learning", points = 0.5 },
    { id = "#1", text = "----- ----- ----- PICK ONE FROM THE OPTIONS BELOW ----- ----- -----", points = 0},
    { id = "A3", text = "Reflection is not detailed enough (Bad)", points = 0 },
    { id = "A4", text = "Reflection is somewhat detailed (Bad)", points = 1 },
    { id = "A5", text = "Reflection is quite detailed (okay)", points = 2 },
    { id = "A6", text = "Reflection is very detailed (good)", points = 3 },
  */

    if (B3 && (A3||A4)) {
        ret.push(
            `As in the previous exercise above, your reflection was quite brief.
We advise you to take some time to reflect on the discussion you had about this topic and see if there are any additional findings you can extract.

As mentioned before, the key take-away you should get from this exercise is that good communication is extremely important to leverage the diversity in your teams.`
        );
    }

    if (B3 && !A3 && !A4) {
        ret.push(
            `Differently than in the previous exercise above, this reflection was rather short. 
From the briefness of your answer, it seems to us that you may have invested little time looking back at this discussion. If this was really the case, please take a few more minutes to reflect on what was discussed.
As mentioned before, the key take-away you should get from this exercise is that good communication is extremely important to leverage the diversity in your teams.`
        );
    }

    if (B4 && (A3||A4)) {
        ret.push(
            `As in the previous exercise above, this reflection was rather short. 
This is not a problem per se, however, please just make sure to actually take some time to reflect actively on this conversation you had with your team.
As mentioned before, the key take-away you should get from this exercise is that good communication is extremely important to leverage the diversity in your teams.`
        );
    }

    if (B4 && !A3 && !A4) {
        ret.push(
            `Differently than in the previous exercise above, this reflection was rather short. 
From the briefness of your answer, it seems to us that you may have invested little time looking back at this discussion. If this was really the case, please take a few more minutes to reflect on what was discussed.
As mentioned before, the key take-away you should get from this exercise is that good communication is extremely important to leverage the diversity in your teams.`
        );
    }

    if (B5 && (A5 || A6)) {
        ret.push(
            `As in the previous exercise above, your reflection about this second topic was quite detailed.
We are glad to see how you took some time to reflect on the discussion you had about this topic and extracted these many learnings.

As mentioned before, the key take-away you should get from this exercise is that good communication is extremely important to leverage the diversity in your teams.`
        );
    }

    if (B5 && !A5 && !A6) {
        ret.push(
            `Differently than in the previous exercise above, this reflection was quite detailed.
We are glad to see how you took some time to reflect on the discussion you had about this topic and extracted these many learnings.

As mentioned before, the key take-away you should get from this exercise is that good communication is extremely important to leverage the diversity in your teams.`
        );
    }

    if (B6 && (A6 || A5)) {
        ret.push(
            `As in the previous exercise above, your reflection about this second topic was very detailed.
We are glad to see how you took some time to reflect on the discussion you had about this topic and extracted these many learnings.

As mentioned before, the key take-away you should get from this exercise is that good communication is extremely important to leverage the diversity in your teams.`
        );
    }

    if (B6 && !A6 && !A5) {
        ret.push(
            `Differently than in the previous exercise above, your reflection about this second topic was very detailed.
We are glad to see how you took some time to reflect on the discussion you had about this topic and extracted these many learnings.

As mentioned before, the key take-away you should get from this exercise is that good communication is extremely important to leverage the diversity in your teams.`
        );
    }

    return ret;
};
