import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (!Z0 && !Z1 && !A1 && !A7 && !A8 && !A9) {
    ret.push(`How exactly are you going to reduce the costs?
Your approach was not clear enough to us.`);
  }

  if (A7 && !A8) {
    ret.push(`Are you sure that this is not possible?.
Even if your experiment was already very efficient, there may be ways to reduce its costs further.
One good approach to do this is to focus on what exactly you are trying to verify and focus only on that.
It is likely that your experiment will have to change significantly, but it is possible.`);
  }

  if ((A3 || A4) && !A7 && !A8 && !A9) {
    ret.push(`Are you sure that this would help you reduce your costs by half?
Having read your answer, this is not clear to us.
Therefore, please make sure to rethink if this is really the best strategy to reduce your costs significantly.`);
  }

  if ((A5 || A6) && !A7 && !A8 && !A9) {
    ret.push(`Thank you for your answer.
Your approach makes sense and we agree that it should be able to reduce your experiment's cost significantly.`);
  }

  if (A8) {
    ret.push(`How was your overall satisfaction with this experiment? (we know that this wasn't asked)
We feel like you should be satisfied with it because you have made several important learnings with very little cost and time put in.
I am saying this because you now want to increase the experiment length and add more people which could double the experiment cost (time, work-hours, etc...). Often, it is better to run two simple and cheap experiments like the one you did than one with more resources.

There will be a time where you should employ more people and resources in your experiments. But for now, our suggestion is to keep it as simple as possible. You will learn more by running many small experiments than a few bigger ones.`);
  }

  if (A9) {
    ret.push(`Thank you for your answer.
Your approach makes sense and we agree that it should be able to reduce your experiment's costs.
However, please keep in mind that while running your experiment digitally might help you reduce costs, it will also significantly reduce your ability to empathize with users and learn more about their real needs and pains.
Therefore, we advise you to keep running in-person tests at the beginning your prototyping phase (at least until you are absolutely certain about who your early-adopters are and what your solution should be).`);
  }

  if (A10) {
    ret.push(`Thank you for your answer.
We agree that this experiment has already been very cost efficient. However, we still would like to advise you to always ask yourself if there was something you could have done differently to make your experiment even more efficient. Often you will identify some simple ways in which you could have been more efficient.`);
  }

  if (!Z0 && !Z1) {
    ret.push(`For the future, we advise you to always do this simple exercise after every experiment you run.
Often you will identify some simple ways in which you could have been more efficient.`);
  }



  return ret;
};
