import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { A1, A2, A3, A4, A5, A6, A7, A8, A9, A10 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Your understanding of the connection between the user experience and emotions highlights your commitment to creating a positive impact for the customer. The focus on creating an emotional shift demonstrates a deep understanding of the customer and their needs, and will lead to more effective and meaningful solutions. Your idea has the potential to drive innovation and create a truly impactful user experience. Well done! `
    );
  }

  if (A2) {
    ret.push(
      `While your idea to improve the user experience is a good start, it does not make sense to us exactly how it will work. It's important to make sure your idea is well thought out and can be effectively executed. If your idea is not fully explained or does not make sense in terms of how it would work to improve the customer experience, it may be difficult to assess its potential impact and feasibility. `
    );
  }

  if (A3) {
    ret.push(
      `While your idea to improve the user experience is a good start, it's important to consider the emotional impact it will have on the customer. Providing an explanation of how your idea will create a desired emotional shift will help to ensure that the solution is impactful and meets the needs of the customer. By considering the emotional experience of the customer, you will be better equipped to create a solution that truly drives innovation and meets the desired goals.`
    );
  }

  return ret;
};
