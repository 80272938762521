import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A6, A7, A8, A4, A5 } = getSelectedOptionsFlags(selectedOpts);

  if (scoredPoints > 5 && scoredPoints < 10) {
    ret.push(
      `Well done! You aimed to avoid the usage of vanity metrics and tried to focus on metrics that will help you to make educated decisions throughout the project. `
    );
  }

  if (scoredPoints > 9) {
    ret.push(
      `Good job! Your metrics are predictive, actionable, and relevant for your project. You have successfully avoided the usage of vanity metrics and instead focused on metrics that will help you to make educated decisions throughout the project.`
    );
  }

  if (A6) {
    ret.push(
      `Unfortunately, many of your metrics are not actionable and you will have a hard time making use of them. Try to think about situations in which the metrics will become useful for decision making. How can you reformulate them to be even more useful? `
    );
  }

  if (A7) {
    ret.push(
      `We were asking you to provide 5 KPIs to track your project. You submission did not fullfill this. `
    );
  }

  if (A8) {
    ret.push(
      `Some of your metrics were not relevant to the project success or were not measuring the progress of the project. Even if they might be actionable in another setting, this provides very little value to your project. `
    );
  }

  return ret;
};
