import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Very well done! You have reflected well on your experience and used your metrics to evaluate the success of your hypothesis. Great job!`
    );
  }

  if (Z2) {
    ret.push(
      `Exceptional submission! We really appreciate the time you took to answer all the questions and are very happy to see how you reflected on and used your metrics to evaluate your assumptions and validate your hypothesis. Very well done!`
    );
  }

  return ret;
};
