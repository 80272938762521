import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A1, A2, A3, A4, A5, A6, A7, A8, A9 } = getSelectedOptionsFlags(selectedOpts);
  /*
    { id = "#1", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id = "A1", text = "Participant gives no definition", points = 0 },
    { id = "A2", text = "Participant gives wrong definition", points = 0.5 },
    { id = "A3", text = "Participant gives correct definition", points = 1 },
    { id = "#2", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id = "A4", text = "Participant gives no example", points = 0 },
    { id = "A5", text = "Participant gives one bad example", points = 0.5 },
    { id = "A6", text = "Participant gives one correct example", points = 1 },
  */

  if (A7) {
    ret.push(
      `Thank you for explaining what you understand as tacit information.
Even though your answer included some of the elements we were hoping to hear, we would like to remind that one of the key aspects of tacit information is the fact that it is hard to share.
So please keep this in mind in the future.`
    );
  }

  if (A1 && !A6) {
    ret.push(
      `Thank you for your answer. However, it seems like you did not define clearly what tacit information is.
As this is a relevant topic, we ask you to go over our material in the course once more.
It is important to understand this topic well, as it is the source of many issues in teams.`
    );
  }

  if (A2) {
    ret.push(`Thank you for explaining what you understand as tacit information.
Even though your answer included some of the elements we were hoping to hear, we would still like to share an alternative definition.
A better definition of tacit information would be all the information that one person has, that is not written anywhere and cannot be shared easily. It is in their heads and is hard to write down.`);
  }

  if (A3) {
    ret.push(`You have given and clear and correct definition of tacit information.
We are happy to see that this concept is clear to you.`);
  }

  if (A1 && A6) {
    ret.push(
      `Thank you for your answer. However, while you did give an example of tacit information, it seems like you did not define clearly what tacit information is.
As this is a relevant topic, we ask you to go over our material in the course once more.
It is important to understand this topic well, as it is the source of many issues in teams.`
    );
  }

  if (A4 && (A1 || A2 || A7)) {
    ret.push(`Furthermore, you did not give any example of something that could be characterized as tacit information.`);
  }

  if (A4 && A6) {
    ret.push(`
On the other hand, you did not give any example of something that could be characterized as tacit information.`);
  }

  if (A5 && (A1 || A2 || A7)) {
    ret.push(`Furthermore, while you have stated an example of tacit information, this is not an ideal example as depending on how you interpret it, it is not tacit.
A better example would be how to deal with specific clients or how to get things done in a certain team.
You may have learned how to talk to a client or a team through experience and context. There is no book someone can read or protocol they can follow that will allow them to do these things as good as you. It's hard to explain exactly all those things you've learned to someone new (e.g., what language to use, how formal to be, all the little things that each clients likes and dislikes). You can give an idea, but describing it in detail is very hard.`);
  }

  if (A5 && A3) {
    ret.push(`However, while you have stated an example of tacit information, this is not an ideal example as depending on how you interpret it, it is not tacit.
A better example would be how to deal with specific clients or how to get things done in a certain team.
You may have learned how to talk to a client or a team through experience and context. There is no book someone can read or protocol they can follow that will allow them to do these things as good as you. It's hard to explain exactly all those things you've learned to someone new (e.g., what language to use, how formal to be, all the little things that each clients likes and dislikes). You can give an idea, but describing it in detail is very hard.`);
  }

  if (A6 && (A2 || A7)) {
    ret.push(
      `On the other hand, you have given a good example of an information that could be considered tacit.`
    );
  }

  if (A6 && A3) {
    ret.push(
      `Furthermore, you have given a good example of an information that could be considered tacit.`
    );
  }

  /*
    if (!Z0 && !Z1 && A1 === false && A2) {
    ret.push(
      `Thank you for stating how tacit information is shared in your company. However, how do you personally share tacit information with your team? Do you try to write it down? Do you hold meetings? Sharing tacit information with your team effectively can improve your team's productivity. Therefore it is vital to find ways to do that. For inspiration and more details, you can take a look at our M4 method cards.`
    );
  }

  if (!Z0 && !Z1 && A1 && A2 === false) {
    ret.push(
      `Thank you for sharing how you share tacit information with your team. However, does your company set some protocol or official procedure to transfer tacit knowledge? If so, which one? If not, how would you implement that? Sharing tacit information with your team effectively can improve your team productivity. Therefore, it is important to find ways to do that. For inspiration and more details, you can take a look at our M4 method cards.`
    );
  }

  if (!Z0 && !Z1 && A1 && A2) {
    ret.push(
      `Perfect! It seems like you reflected on this topic quite a bit! Transferring tacit knowledge is probably one of the hardest parts to achieve a good team dynamic, so understanding its challenges and possible benefits will undoubtedly help your career. We are happy to see that you are on the right path for that!`
    );
  }

  if (!Z0 && !Z1 && A1 === false && A2 === false) {
    ret.push(
      `You did not make it clear to us if there are any official methods put in place by your employer to help you share tacit information nor how you, personally, likes to share tacit information.
Sharing tacit (implicit) information is one of the most challenging topics when it comes to corporate communication. We hope that even if you did not have time to submit an answer here, that you managed to reflect on how you currently share it and how you could improve that. Don't forget that you can check our method cards for some ideas!`
    );
  }
  */
  return ret;
};
