import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  /*
    {id = "A1", text = "Task is clear", points = 1},
    {id = "A2", text = "User gets to interact with the prototype", points = 1},
    {id = "A3", text = "Some questions to be asked during task prepared", points = 1},
    {id = "A4", text = "All questions are open ended", points = 0.5},
    {id = "A6", text = "Leaves room for exploration. (Does not explain everything)", points = 1},
    {id = "A7", text = "Task is well designed (addresses the core element, good time commitment, etc)", points = 2},
    {id = "#0", text = "----- PICK ONE OF THE TWO BELOW ----- ----- ----- ----- ----- ----- ----- ----- ", points = 0},
    {id = "A5", text = "Questions do not ask for imagination or sugestions (GOOD) (e.g. they should focus on real issues and experiencies. Not to imagine something or hypothetical situations)", points = 1},
    {id = "A8", text = "Task asks participants to imagine something (BAD)", points = 0},
  */

  if (A2 && A6 && !A8) {
    ret.push(
      `The task you designed gives the user an opportunity to explore and interact well with your prototype.
This is important as the more he explores it, the more likely it is that he might find some issues and the more complete his feedback will be. Well done!`
    );
  }

  if (A2 && !A6 && !A8) {
    ret.push(
      `The task you designed gives the user an opportunity to interact well with your prototype.
This is important as the more he explores it, the more likely it is that he might find some issues and the more complete his feedback will be. Well done!`
    );
  }

  /*
  if (A7) {
    ret.push(
      `Well done.
You have designed your task very well. This might increase the number of learnings you make and improve the chance that you will get constructive feedback from him. `
    );
  }
*/
  if (A2 === false && !Z0 && !Z1 && !Z2 && !A8) {
    ret.push(
      `The task you designed does not give the user a good opportunity to interact with your prototype.
This is important as the more he explores it, the more likely it is that he might find some issues and the more complete his feedback will be. Therefore before you test it,  make sure to adapt your task so that it maximizes the functionalities and time spent with the prototype.`
    );
  }

  if (A4 === false && !Z0 && !Z1 && !Z2 && !A8 && A3) {
    ret.push(
      `Some of the questions you have stated are closed-ended.
This might restrict the flow of the conversation and give the participant less freedom to express himself. Please make sure to change all your question so that they are either open or have an open follow-up question.`
    );
  }

  if (A6 === false && !Z0 && !Z1 && !Z2 && !A8) {
    ret.push(
      `Your task does not leave enough room for exploration.
The goal of this task is to bring your user in contact with your prototype and allow him to explore it. Therefore, he should ideally not receive too much help or instructions as this might reduce the efficacy of your test.`
    );
  }

  if (A8) {
    ret.push(
      `Your task asks your users to imagine a certain experience and asks them for feedback on that.
We can understand why this idea is appealing, however, asking users to imagine a situation and use the feedback gathered from this test to decide on your next steps is not a good idea.
First, it is very hard to really imagine an experience like that.
Secondly, their answers will depend heavily on past experiences and the context of the user right now.

Therefore, we advise you not to use these types of testing in the future.
Instead, try to get them to give feedback on something tangible. Ideally, ask them to participate in a mock project with new team members and try to discover feelings and issues during this experience.`
    );
  }

  return ret;
};
