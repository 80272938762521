import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, B1, B2, B3, B4, B5 } = getSelectedOptionsFlags(selectedOpts);
  /*
    { id = "B1", text = "Did he offer a solution so that this won’t happen in the future?", points = 1 },
    { id = "B2", text = "Solution makes sense", points = 1 },
    { id = "B3", text = "Answer is detailed", points = 1 },
    */
  if (!Z0 && !Z1 && !Z2 && B1 === false && !B4 && !B5) {
    ret.push(`The solution you shared is not very clear.
Please make sure to submit more detailed answers in the future, so that we can assess better your understanding on this topic and give you better feedback.`);
  }

  if (B1 && !B2 && !B4 && !B5) {
    ret.push(`Thank you for stating a solution you believe might be effective to avoid the issue above from happening again.
However, are you sure that this is really the best approach?
We ask you to go over the problem you had one more time and try to think of alternative solutions that may be more effective or easy to implement.`);
  }

  if (B1 && B2 && !B3 && !B4 && !B5) {
    ret.push(`Thank you for stating this solution to the problem stated earlier.
Your solution seems to makes sense and should help you avoid it in the future.
We only ask you to describe better your answers in the future. This helps us understand better your ideas and give you more effective feedback.`);
  }

  if (B1 && B2 && B3 && !B4 && !B5) {
    ret.push(`Thank you for stating this solution to the problem stated earlier.
Your solution is well described and should help you avoid that problem in the future.`);
  }

  if (B4) {
    ret.push(
      `Even though the situation described in part a) was not caused by a poor sharing of tacit information, we agree that your suggestion here could have mitigated the problem above.`
    );
  }
  return ret;
};
