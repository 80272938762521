import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts, scoredPoints) => {
  const ret = [];
  const { Z0, Z1, D0, D1, D2, D3, D4, D5, D6, D7 } = getSelectedOptionsFlags(selectedOpts);
  /*
    {id = "D1", text = "Good additional feedback", points = 0.5},
    {id = "D2", text = "Poor additional feedback", points = 0.1},
  */

  if (D1 || D2) {
    ret.push(`Thank you for this additional feedback.
We are happy to see how you are validating you hypothesis with more than just the required number of users!`);
  }

  return ret;
};
