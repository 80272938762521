import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const {
    Z0,
    Z1,
    A0,
    A1,
    A2,
    A3,
    A4,
    A5,
    A6,
    A7,
    A8,
    A9,
    A10,
    A11,
    A12,
    A13,
    A14,
    A15,
    A16,
    A17,
    A18,
    A19,
    A20,
    A21,
    A22,
    A23,
    A24,
    A25,
    A26,
    A27,
  } = getSelectedOptionsFlags(selectedOpts);

  if (scoredPoints === 5 && !A24 && !A25 && !A26 && !Z0) {
    ret.push(`Well done!
You have done a good job evaluating your experiment.
It correctly included information on whether your user, your solution, your problem and the experiment itself was well suited.
Evaluating this information is crucial when deciding your next steps.`);
  }

  if (A26) {
    ret.push(`Thank you for sharing the costs of your experiment.
Doing so will not only help you keep track of your costs, but it might also help you identify things that could be cut or made cheaper in your future experiments.

However, a great evaluation should not only include information on whether your user, your solution, your problem and the experiment itself was well suited, but it should also be able to back up your position on findings and observations from your experiment.
Because of that, while you have done a good job evaluating your experiment, we advise you to, in the future, define more clearly why each of the elements above (user, problem, solution, etc) has been validated or not.

Evaluating your experiment critically after each prototyping round will help you ensure that the learnings you make are correct and supported by sound evidence.
This in turn will not only help you document your work better (Can be helpful if you ever need to revisit something later). but it will also allow you to double-check your reasoning and ensure that you are taking the right decisions for the right reasons.`);
  }

  if (scoredPoints >= 4 && scoredPoints < 5 && !A24 && !A25 && !A26 && !Z0 && !A27) {
    ret.push(`A great evaluation should not only include information on whether your user, your solution, your problem and the experiment itself was well suited, but it should also be able to back up your position on findings and observations from your experiment.
All in all, you have done a good job evaluating your experiment.
However, there are one or two things you could improve. These are depicted below:`);
  }

  if (scoredPoints < 4 && !A24 && !A25 && !A26 && !Z0 && !A27) {
    ret.push(`A great evaluation should not only include information on whether your user, your solution, your problem and the experiment itself was well suited, but it should also be able to back up your position on findings and observations from your experiment.
All in all, you have done a good job evaluating your experiment.
However, there are a few things you could improve. These are depicted below:`);
  }

  if (A27) {
    ret.push(`What experiment did you run? With how many people? For how long? What worked and what did not work?
From your answer it does not seem like you ran any experiment. 
The idea of this exercise was to help you pick one element of your idea that you believe to be central and design a very simple experiment (ideally one where you can interact directly with your target users) and validate all the elements above (problem, solution, user and the idea itself).
Below you will find a more detailed feedback on all these elements:`);
  }

  /* Cost */

  if (!Z0 && !A1 && !A2) {
    ret.push(`You did not really say much about the cost of your experiment.
How much time went into preparing, conducting and evaluating your experiment? Was it necessary to buy or rent something?
It is important to include these questions when evaluating your experiments.
Remember, anyone can validate an idea with a large budget. The key is to that with very little budget.`);
  }

  if (!Z0 && !A1 && A2) {
    ret.push(`While you did state how much time went into preparing, conducting and evaluating your experiment, you did not say anything about its monetary cost.
It is important to include this information as well when evaluating your experiments.
Doing so will not only help you keep track of your costs, but it might also help you identify things that could be cut or made cheaper.`);
  }

  if (!Z0 && A1 && !A2) {
    ret.push(`While you did state how much it cost to run your experiment, you did not say anything about how much time went into preparing, conducting and evaluating your experiment.
It is important to include this information as well when evaluating your experiments.
Doing so will not only help you assess the true cost of your experiment, but it might also help you identify ways to be more efficient.`);
  }

  if (A1 && A2) {
    ret.push(`Thank you for sharing the direct (props, rents, expenses) and indirect costs (Time) of your experiment.
Doing so will not only help you keep track of your costs, but it might also help you identify things that could be cut or made cheaper in your future experiments.`);
  }

  /* Alternative Feedback */

  if (A24) {
    ret.push(`Regarding the unsuitability of your experiment, we agree that it makes it hard to determine with certainty if your problem, solution and user have been validated.
Still, we advise you to use your experiment to interact and learn as much as possible with your users.
Often, by simply talking to them (and asking why) you are able to uncover more insights about your challenge.`);
  }

  if (A25) {
    ret.push(`Regarding the unsuitability of your experiment, we agree that it makes it hard to determine with certainty if your problem, solution and user have been validated.
Still, we advise you to use your experiment to interact and learn as much as possible with your users.
Often, by simply talking to them (and asking why) you are able to uncover more insights about your challenge.
Furthermore, we ask to be more detailed in your future answers. Taking the time to critically evaluate your experiment is something you should not skip.`);
  }

  /* Problem */
  if (A4) {
    ret.push(`Did your experiment validate the problem you are trying to solve?
Discovering if the problem you have been focusing on is really as significant as you believe, is crucial.
We cannot emphasize enough how important it is to validate whether or not this is a real pain-point for your users.
If it isn't, then you should go back to your module one findings and look for a more significant pain-point.
If, on the other hand, it is clear that this is a real problem for your users, then you should be happy to be on thee right track!
Therefore, please take some time to evaluate if your problem was confirmed.`);
  }

  if (A5) {
    ret.push(
      `You have not given enough information in your arguments to explain why your problem has been validated.
Discovering if the problem you have been focusing on is really as significant as you believe, is crucial.
We cannot emphasize enough how important it is to validate whether or not this is a real pain-point for your users.
If it isn't, then you should go back to your module one findings and look for a more significant pain-point.
If, on the other hand, it is clear that this is a real problem for your users, then you should be happy to be on thee right track!
Therefore, please take some time to evaluate if your problem was confirmed.`
    );
  }

  if (A7) {
    ret.push(
      `You have not given enough information in your arguments to explain why your problem has been invalidated.
Discovering if the problem you have been focusing on is really as significant as you believe, is crucial.
We cannot emphasize enough how important it is to validate whether or not this is a real pain-point for your users.
If it isn't, then you should go back to your module one findings and look for a more significant pain-point.
If, on the other hand, it is clear that this is a real problem for your users, then you should be happy to be on thee right track!
Therefore, please take some time to evaluate if your problem was confirmed.`
    );
  }

  if (A9) {
    ret.push(`Did your experiment validate whether or not your are targeting the right users?
From your answer above it is not clear to us if this was the case.
Since this is such an important finding (knowing who to focus on), we suggest you take some time to evaluate if your target users were confirmed or not.
Having a clear user group will help you focus on their pain-points and validate elements of your solution more easily.`);
  }

  if (A10) {
    ret.push(
      `You did not make it clear enough why you think that this experiment has validated your target user.
Since this is such an important finding (knowing who to focus on), we suggest you take some time to evaluate if your target users were confirmed or not.
Having a clear user group will help you focus on their pain-points and validate elements of your solution more easily.`
    );
  }

  if (A12) {
    ret.push(
      `You did not make it clear enough why you think that this experiment has shown that you have been focusing on the wrong target user.
Since this is such an important finding (knowing who to focus on), we suggest you take some time to evaluate if your target users were confirmed or not.
Having a clear user group will help you focus on their pain-points and validate elements of your solution more easily.`
    );
  }

  /* Solution */
  if (A14 && (A4 || A9)) {
    ret.push(`Again you did not really use this chance to validate whether or not you are working on the right solution.
In the future, please make sure to validate not only your problem and target user, but also whether or not you are working on the right solution.
It is very possible that the problem you have identified for a certain target audience is real and relevant, but that the solution you came up with is not.
This is why it is important to validate it as often as possible.`);
  }

  if (A14 && !A4 && !A9 && !A24 && !A25 && !A26 && !Z0) {
    ret.push(`You did not really use this chance to validate whether or not you are working on the right solution.
In the future, please make sure to validate not only your problem and target user, but also whether or not you are working on the right solution.
It is very possible that the problem you have identified for a certain target audience is real and relevant, but that the solution you came up with is not.
This is why it is important to validate it as often as possible.`);
  }

  if (A15) {
    ret.push(`You said that this experiment confirmed that your solution is promising, but based on your arguments alone, this was not clear to us.
Please make sure to evaluate your experiment again before making the decision of sticking to your solution.`);
  }

  if (A17) {
    ret.push(`You said that this experiment confirmed that your solution is not promising, but based on your arguments alone, this was not clear to us.
Please make sure to evaluate your experiment again before making the decision of changing your solution.`);
  }

  /* Experiment */
  if (A19) {
    ret.push(`Was this experiment suitable to validate your problem, user and solution?
Your answers does not make this clear.`);
  }

  if (A20) {
    ret.push(`You have stated that your experiment was successful and well suited to validate your solution.
However, you have not made it very clear why this is the case.
Therefore, please spend a few minutes discussing in your team if this is really the case.
You should try to base your answer on tangible results and observations from your experiment.`);
  }

  if (A22) {
    ret.push(`You have stated that your experiment was unsuccessful and not well suited to validate your solution.
However, you have not made it very clear why this is the case.
Therefore, please spend a few minutes discussing in your team if this is really the case.
You should try to base your answer on tangible results and observations from your experiment.`);
  }

  if (scoredPoints > 0 && !A26 && !Z0) {
    ret.push(`For the future: Evaluating your experiment critically after each prototyping round will help you ensure that the learnings you make are correct and supported by sound evidence.
This in turn will not only help you document your work better (Can be helpful if you ever need to revisit something later). but it will also allow you to double-check your reasoning and ensure that you are taking the right decisions for the right reasons.`);
  }

  return ret;
};
