import * as React from "react";
import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@sparkademy/app-common/elements/Alert";
import { Box, Text, Button as BaseButton } from "theme-ui";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as TeamFaces } from "@sparkademy/app-common/materials/illustrations/team-faces.svg";
import { ButtonOutline } from "@sparkademy/app-common/elements/ButtonOutline";
import { TeamDisplayBox } from "@sparkademy/app-common/components/assignment/TeamDisplayBox";
import { ExerciseTeam } from "@sparkademy/app-common/models/assignment";
import { ReactComponent as Close } from "@sparkademy/app-common/materials/icons/close.svg";
import VisuallyHidden from "@reach/visually-hidden";

export const TeamSubmitConfirmationDialog: React.FC<{
  team: ExerciseTeam;
  onClose: () => void;
  onSubmit: () => void;
}> = ({ onClose, onSubmit, team }) => {
  const cancelRef = React.useRef<HTMLButtonElement>();
  const leader = team.members.find(m => m.is_leader);
  const others = team.members.filter(m => !m.is_leader);

  return (
    <AlertDialogOverlay
      // @ts-ignore
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogContent>
        <Box sx={{ maxWidth: "600px" }}>
          <AlertDialogLabel
            style={{ height: "32px", width: "100%", position: "sticky", top: "32px" }}
          >
            <BaseButton
              sx={{
                all: "unset",
                fontFamily: "inherit",
                float: "right",
                color: "primary",
                cursor: "pointer",
                bg: "transparent",
              }}
              onClick={onClose}
            >
              <VisuallyHidden>Close</VisuallyHidden>
              <Close width={32} height={32} aria-hidden="true" focusable="false" />
            </BaseButton>
          </AlertDialogLabel>
          <AlertDialogDescription>
            <Box
              sx={{
                margin: "auto",
                width: 140,
                height: 140,
                svg: {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <TeamFaces />
            </Box>
            <Text
              as="p"
              sx={{ my: "32px", textAlign: "center", fontWeight: 700, fontSize: "25px" }}
            >
              Submit Feedback?
            </Text>
            <Text as="p" sx={{ textAlign: "center", fontSize: "16px", mb: "24px" }}>
              All members will receive the same evaluation and feedback. Please ensure all feedback
              is correct before continuing.
            </Text>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <TeamDisplayBox
                label="Team Leader"
                text={`${leader?.first_name} ${leader?.last_name} `}
              />
              <Box sx={{ mt: "16px" }} />
              <TeamDisplayBox
                label="Team Members"
                text={others.map(m => `${m.first_name} ${m.last_name}`).join(", ")}
              />
            </Box>
          </AlertDialogDescription>
        </Box>
        <Box sx={{ mt: 11 }} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: "8px",
          }}
        >
          <Button type="submit" onClick={() => onSubmit()} sx={{ fontSize: "20px" }}>
            Submit to team
          </Button>
        </Box>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
};
