import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Well done! You have chosen relevant patterns, that can be combined into one valuable and viable business model.
More importantly, you have given good arguments as to why these patterns were picked. This shows your team's good understanding of these patterns and the challenge you are working on.`
    );
  }

  if (Z2) {
    ret.push(
      `Excellent job! You have chosen several relevant patterns, that can be combined into one valuable and viable business model.
More importantly, you have given good arguments as to why these patterns were picked. This shows your team's good understanding of these patterns and the challenge you are working on.
Good work!`
    );
  }

  return ret;
};
