import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts, scoredPoints) => {
  const ret = [];
  const { Z0, Z1, D0, D1, D2, D3 } = getSelectedOptionsFlags(selectedOpts);

  if (D1) {
    ret.push(`Thank you for submitting this file.
You will find the feedback for each type of stakeholder in the parts 1.1.a, 1.1.b and 1.1.c`);
  }

  if (D2 && !D3) {
    ret.push(`Thank you for submitting your stakeholder map as well!`);
  }

  if (D3 && !D2) {
    ret.push(
      `Thank you for submitting a interest vs. influence matrix of your stakeholders as well!`
    );
  }

  if (D3 && D2) {
    ret.push(`Thank you for submitting both a stakeholder map and a interest vs. influence matrix of your stakeholders as well!
We are happy to see how your team collaborated to create these files and hope that you have found this process insightful.`);
  }

  return ret;
};
