import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission. We hope that, while you did not state how many assumptions you came up with, you still managed to perform the assignment and write down several assumptions.`
    );
  }

  return ret;
};