import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No Submission.`);
  }

  if (Z1) {
    ret.push(
      `Great job! You created a good projected cash flow statement and filled in all the details a project at your state can give.`
    );
  }

  if (Z2) {
    ret.push(
      `Amazing job! You created a great projected cash flow statement and filled in all the details a project at your state can give.`
    );
  }

  return ret;
};
