import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `You did not state the industry lifecycle your business is in. This is a very important information that will help you set priorities and develop best strategies. Please take some time to reflect about your business and what lifecycle it is in.`
    );
  }

  return ret;
};
