import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, C1, C2, C3, C4, C5, C6, C7, C8 } = getSelectedOptionsFlags(selectedOpts);
  /*
    {id = "#16", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "C1", text= "Changes are not clear", points= 0 },
    { id= "C2", text= "Changes are clear", points= 0.5 },
    { id= "C3", text= "Changes are very clear", points= 1 },
    {id = "#17", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "C4", text= "Changes made the user journey more valuable/complete/realistic/detailed", points= 1.5 },
    { id= "C5", text= "Changes did not improve the User Journy", points= 1.5 },
    {id = "#18", text = "----- ----- ----- ----- Pick one ----- ----- ----- ----- ----- ", points = 0},
    { id= "C6", text= "Good submission", points= 1 },
    { id= "C7", text= "Very good submission", points= 2 },
*/
  if (C1) {
    ret.push(`Thank you for your submission.
However, the changes stated are not very clear.
Please read your submission again and reflect on the overall effects your ideas might have on your user journey.
Also, we ask that in the future you give more detailed answers as this will allow us to give you better feedback. `);
  }

  if (C2) {
    ret.push(`Thank you for your submission.
The changes stated are clear, but in order to make the most out of this assignment you should invest more time to reflect on how these ideas might affect your user journey.`);
  }

  if (C3) {
    ret.push(`Well done!
The changes stated are very clear and well described.`);
  }

  if (C4) {
    ret.push(
      `More importantly, the changes described explain well how your idea will affect your old user journey and create a better experience for your users. Well done!`
    );
  }

  if (C5) {
    ret.push(`From what we read in your submission, it is not clear how these changes will improve your value proposition and give your users a better experience.
Please reflect on your submission and double check how much value these changes bring to your users.
An idea should only be pursued if it clearly adds more value to your users (or to your business in some other way).`);
  }

  if (C6) {
    ret.push(
      `Thank you for your submission.
We would expect you and your team to think of more touchpoints or at least visualise the user journey with more clarity after the exercises above.
Of course, this is not a must, but we advise you to reflect and maybe even create a new User journey with more phases and details. This can help you discover valuable opportunities later.`
    );
  }

  return ret;
};
