import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(
      `No submission. The overarching question is central to your user research. All the following steps will be based on it. Therefore, it is very important that you consider your stakeholders, the relevant topics for your challenge, and come up with a relevant question that you would like to work with. Don't forget this question is not final. As you progress and learn more, you might (and should) adjust it to reflect better the needs of your participants.`
    );
  }

  if (Z1) {
    ret.push(
      `Great work! Your overarching research question is clearly defined and effectively addresses the key assumptions and themes of your study. This question will serve as an important guide throughout your user needs research, but remember to remain flexible and adapt it as new insights and information are gained through your participant interactions.

In the next module, you'll take all the insights you've gathered during the first module and use them to define a problem statement which will specify what problem or pain point you will try to solve for a particular user identified in your user research.`
    );
  }

  return ret;
};
