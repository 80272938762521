import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1 } = getSelectedOptionsFlags(selectedOpts);

  if (Z0) {
    ret.push(`No submission`);
  }

  if (Z1) {
    ret.push(
      `Excellent work! You identified several new and creative directions for your business model based on your chosen epicenter. Your ideas demonstrated a depth of knowledge and breadth of thinking. These ideas that come from reframing your business model can act as valuable inspiration for business model reconfiguration and innovation. Having multiple directions can help you chose the most appropriate one for your goals at a given time in your business model innovation process.`
    );
  }

  return ret;
};
