import { getSelectedOptionsFlags } from "../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Thank your for describing the workshop you are planning. This helps us to evaluate and give feedback on the following exercises. `
    );
  }

  if (!Z0 && !Z1 && !A2) {
    ret.push(
      `You could have added some more details, e.g. at what time during your sprint this workshop is taking place, to give even more context. `
    );
  }

  if (A3) {
    ret.push(
      `Thank you for sharing the desired outputs of your workshop! This is always the first step you should start with when planning a workshop.`
    );
  }

  if (A4) {
    ret.push(
      `Well done! The outcomes you are planning for are realistic to be reached within the workshop you are planning.`
    );
  }

  if (A7) {
    ret.push(
      `Please re-evaluate the outputs and results you are aiming for. It might be that the scope of a single workshop is not sufficient to reach those outcomes. Maybe you can plan several workshops instead or extend the duration.`
    );
  }

  return ret;
};
