import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1) {
    ret.push(
      `Amazing! Your invitation clearly states why you want this sprint to happen and empathizes its relevance.`
    );
  }

  if (A2) {
    ret.push(
      `The invite creates a sense of urgency. The receipant will clearly know why the sprint has to happen now.`
    );
  }

  if (A3) {
    ret.push(
      `Why you? A receipant will clearly now why he and the team are the right ones to tackle this challenge.`
    );
  }

  if (A4) {
    ret.push(`Overall, the invitation ins motivating and inspiring. Well done!`);
  }

  if (!Z0 && !Z1 && A1 === false) {
    ret.push(
      `Why is this sprint relevant and important for the company? In your invitation, you should make the reason of the sprint even more clear. `
    );
  }
  if (!Z0 && !Z1 && A2 === false) {
    ret.push(
      `Why does this sprint has to happen now? Aim to create a greater sense of urgency in your invitation!`
    );
  }
  if (!Z0 && !Z1 && A3 === false) {
    ret.push(
      `As a receipant of this invitation, why am I, or why is the team, the perfect team for the challenge? By giving everyone a feeling of being valuable for the project, you can set a high level of motivation before the sprint even starts!`
    );
  }
  if (!Z0 && !Z1 && A4 === false) {
    ret.push(
      `Overall, you could think about adding a motivating or inspiring element to the invitation, for example by adding a small story, a picture, a small brain teaser etc., or by simply improving the wording.`
    );
  }
  return ret;
};
