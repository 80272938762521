import { getSelectedOptionsFlags } from "../../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A1 && A2) {
    ret.push(
      `Great job! You discussed whether the metrics your company defined are relevant and which areas of innovation metrics should be covered (additionaly).
      As a recap, a good innovation strategy should cover and measure the following areas: external metrics (e.g. partnerships, clients, customers), internal metrics with impact on growth, revenue and people, as well as metrics with an impact on the company innovation culture. `
    );
  }

  if (A1 && !A2) {
    ret.push(
      `Thank you for your submission. You discussed whether the metrics your company defined are relevant for an innovation strategy. However, you did not assess which areas of innovation metrics should be covered (additionaly). As a recap, a good innovation strategy should cover and measure the following areas: external metrics (e.g. partnerships, clients, customers), internal metrics with impact on growth, revenue and people, as well as metrics with an impact on the company innovation culture. `
    );
  }

  if (!A1 && !A2) {
    ret.push(
      `Great job! You discussed which areas of innovation metrics should be covered (additionaly). As a recap, a good innovation strategy should cover and measure the following areas: external metrics (e.g. partnerships, clients, customers), internal metrics with impact on growth, revenue and people, as well as metrics with an impact on the company innovation culture.
However, your answer does not assess the relevance of the metrics you found.  Even if all areas are covered, the metric might still measue something which is  not relevant for the big picture.`
    );
  }

  if (!A1 && A2) {
    ret.push(`Good job. You have correctly mentioned different innovation-relevant areas of your company that are not being tracked with the current metrics being used.
However, you should have also explained more carefully why the metrics you shared in 4.2 are relevant and effective, or why you think this is not the case. `);
  }

  return ret;
};
