/** @jsx jsx */
import { jsx, Label, Text, Box } from "theme-ui";
import * as React from "react";
import { Container } from "@sparkademy/app-common/elements/Container";
import { ButtonGhostAnchor } from "@sparkademy/app-common/elements/ButtonGhost";
import { Checkbox } from "@sparkademy/app-common/elements/Checkbox";
import { useAtom } from "jotai";
import { useChatGPTAtom } from "@sparkademy/app-common/stores/grading";
import { useGradingContext } from "../contexts/grading-context";

export const Footer: React.FC = () => {
  const [useChatGPT, enableChatGPT] = useAtom(useChatGPTAtom);
  const { assignment } = useGradingContext();
  // only show the chatGPT checkbox if the assignment module is not a_innovationfinance or a_projectwork
  const canUseChatGPT =
    assignment.module_id !== "a_innovationfinance" && assignment.module_id !== "a_projectwork";

  return (
    <footer
      sx={{
        bg: "new.primary.black",
        color: "new.primary.white",
        minHeight: 50,
        py: 4,
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: ["column", "row"],
          alignItems: ["center", "flex-start"],
          justifyContent: "center",
        }}
      >
        {canUseChatGPT && (
          <Label sx={{ cursor: "pointer" }}>
            <Box sx={{ pt: "10px" }}>
              <Checkbox
                sx={{}}
                type="checkbox"
                checked={useChatGPT}
                onChange={() => enableChatGPT(!useChatGPT)}
              />
            </Box>
            <Text sx={{ alignSelf: "center", width: "100%" }}>Use chatGPT</Text>
          </Label>
        )}

        <ButtonGhostAnchor
          href="https://www.sparkademy.com/"
          target="_blank"
          rel="noopened noreferrer"
          sx={{
            px: 7,
            color: "inherit",
            fontSize: "14px",
          }}
        >
          {new Date().getFullYear()} Sparkademy AG
        </ButtonGhostAnchor>
      </Container>
    </footer>
  );
};
