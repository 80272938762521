import { getSelectedOptionsFlags } from "../../../../helpers";

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, Z2, Z3, A1, A2, A3, A4, A5, A6, A7, A8 } = getSelectedOptionsFlags(selectedOpts);

  if (A6 && (A1 || A2)) {
    ret.push(`Well done. Even though you did not conduct as many interviews as we would recommend, you have given a good explanation as to why.
In general, we highly recommend you and your team to interview as many users, non-users and extreme-users as possible. Doing so will allow you and your team to truly understand their most common issues and frustrations and eliminate findings that only appeared very rarely.`);
  }

  if (A7 && (A1 || A2)) {
    ret.push(`While we appreciate your explanation, we do not think that your argumentation was good enough to justify holding so few interviews.
In general, we highly recommend you and your team to interview as many users, non-users and extreme-users as possible. Doing so will allow you and your team to truly understand their most common issues and frustrations and eliminate findings that only appeared very rarely.
It may seem like a lot of work now (and it may as well be), but truly understanding your users is instrumental when designing better products and services.
Investing more time now might improve the insights generated by the end of the user research and therefore reduce the number of iterations of your idea later on. Thus saving you more time in the future while increasing the value of your idea.`);
  }

  if (A8 && (A1 || A2)) {
    ret.push(`You have conducted a low number of interviews and did not explain why that was the case. This will make it harder for you and your team to understand your users and their needs and frustrations.
In general, we highly recommend you and your team to interview as many users, non-users and extreme-users as possible. Doing so will allow you and your team to truly understand their most common issues and frustrations and eliminate findings that only appeared very rarely.
It may seem like a lot of work now (and it may as well be), but truly understanding your users is instrumental when designing better products and services.
Investing more time now might improve the insights generated by the end of the user research and therefore reduce the number of iterations of your idea later on. Thus saving you more time in the future while increasing the value of your idea.`);
  }

  if (A3) {
    ret.push(`You have conducted a good number of interviews! This will help you understand your users and their needs and frustrations much better. Good job.
In general, we highly recommend you and your team to interview as many users, non-users and extreme-users as possible. Doing so will allow you and your team to truly understand their most common issues and frustrations and eliminate findings that only appeared very rarely.
It may seem like a lot of work now (and it may as well be), but truly understanding your users is instrumental when designing better products and services.
Investing more time now might improve the insights generated by the end of the user research and therefore reduce the number of iterations of your idea later on. Thus saving you more time in the future while increasing the value of your idea.`);
  }

  if (A4) {
    ret.push(`Very well done! You have conducted a great number of interviews! This will help you understand your users and their needs and frustrations much better. Good job.
In general, we highly recommend you and your team to interview as many users, non-users and extreme-users as possible. Doing so will allow you and your team to truly understand their most common issues and frustrations and eliminate findings that only appeared very rarely.
It may seem like a lot of work now (and it may as well be), but truly understanding your users is instrumental when designing better products and services.
Investing more time now might improve the insights generated by the end of the user research and therefore reduce the number of iterations of your idea later on. Thus saving you more time in the future while increasing the value of your idea.`);
  }

  return ret;
};
