import { getSelectedOptionsFlags } from "../../../helpers";
// import _ from 'lodash'

export const buildFeedback = (selectedOpts: string[], scoredPoints: number): string[] => {
  const ret: string[] = [];
  const { Z0, Z1, A0, A1, A2, A3, A4, A5 } = getSelectedOptionsFlags(selectedOpts);
  /*
    { id = "A0", text = "Situation described", points = 0.5 },
    { id = "A1", text = "Situation described is correct (happened due to tacit information not being shared)", points = 1 },
    { id = "A2", text = "Situation described is incorrect (happened due to tacit information not being shared)", points = 0.5 },
    { id = "A3", text = "Influences on work stated", points = 1 },
    */
  if (!Z0 && !Z1 && A0 === false && !A4 && !A5) {
    ret.push(
      `You did not state a clear situation where you had issues in your team due to the lack of tacit information sharing.
Even if it looks like this has never happened before, it is likely that in some occasions a problem that has happened (may it be a delay, miscommunication or maybe even a malfunction), happened partly because somewhere along the chain of command some piece of tacit information was not transferred properly.
Try to look back and see if you can identify one such example. Maybe taking a look at our M4 method cards might help you.`
    );
  }

  if (!Z0 && !Z1 && A0 && A2 && A3 && !A4 && !A5) {
    ret.push(
      `The example you stated is not really an issue where tacit information wasn't shared properly. Tacit information is all the knowledge you acquired through your personal and professional experience that is not stored anywhere and is hard to share.
A better example of an issue that took place due to tacit information not being shared properly could be: 
John, a seasoned key account manager, has an extensive tacit understanding of a major client's preferences, pain points, and business culture. His insights, built over years of interaction, enable him to manage the client account exceptionally well. However, when he retires and passes the account to his replacement, Lisa, not all of this tacit knowledge can be effectively conveyed. Lisa struggles to establish the same rapport with the client and to anticipate their needs as effectively, leading to the client's dissatisfaction and potential loss of business. 

Now, take a look at the example you submitted and think if this as really an issue with tacit information. If unsure, try to pin down exactly what piece of information was the root cause of that situation.`
    );
  }
  /* I REMOVED THIS BECAUSE I THINK IT IS NOT NECESSARY (ITS INCLUDED IN THE THE SECOND FEEDBACK BELOW). MAYBE I'M WRONG AND THIS MUST BE INCLUDED BACK.
  if (!Z0 && !Z1 && A0 && A2 && A3 === false && !A4 && !A5) {
    ret.push(
      `While you managed to find an example where tacit information was not transferred properly, you did not state clearly what effect this had on your work.`
    );
  }
*/
  if (!Z0 && !Z1 && A0 && A1 && A3 && !A4 && !A5) {
    ret.push(
      `Good job. You have not only correctly identified a situation where a problem emerged because tacit information was not shared properly, but you have also described well the effect this had on your work.`
    );
  }

  if (!Z0 && !Z1 && A0 && A2 && A3 === false && !A4 && !A5) {
    ret.push(
      `Thank you for sharing a situation you believe a problem emerged because of someone failed to share tacit information properly. However, are you sure that this was an issue with the sharing of tacit information and not just explicit information?
It seems to us that the underlying cause here was something else.
Additionally, you did not really explain what effects this had on your work.
In the future, please make sure to give more detailed answers. Furthermore, you may want to visit our topic on tacit information before proceeding.`
    );
  }

  if (A4) {
    ret.push(
      `The situation you described here fits your definition of tacit information.
However, since your definition is not fully accurate, this situation is also not a good example of a problem that happened due to tacit information not being shared properly.`
    );
  }

  if (A5) {
    ret.push(
      `Even though your definition of tacit information was not fully accurate, this situation is a good example of a problem that happened due to tacit information being shared improperly.
Well done.`
    );
  }

  if (!Z0 && !Z1 && !A3 && !A4 && !A5) {
    ret.push(
      `You did not share what the consequences of this situation was. Thinking about this would have helped realize the importance of sharing tacit information properly.
Therefore, please take a minute to reflect on it before continued. `
    );
  }

  return ret;
};
